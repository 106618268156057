import { Grid, InputLabel, MenuItem, Select } from '@material-ui/core'
import { IOProps } from 'react-compose-io'
import { Controller } from 'react-hook-form'
import { TRANSFORMACAO_INFO } from '../../../constants/transformacao'
import { FormFieldsProps } from '../../../types/regraApiType'
import { TooltipComponent } from '../../Tooltip/Tooltip'
import { FieldsTipoParametro } from '../FieldsTipoParametro'
import { stylesFieldForms } from '../styles'
import { FieldsAritmeticaIO } from './FieldsAritmetica.IO'

export type FieldsAritmeticaProps = {
  indexItensRegraForm: number
  itensCampo: FormFieldsProps[]
  cabecalhos: string[]
}

export function FieldsAritmeticaView({ _io, cabecalhos, indexItensRegraForm, itensCampo }: IOProps<FieldsAritmeticaIO, FieldsAritmeticaProps>) {
  const { FormControl, FormGroup } = stylesFieldForms

  return (
    <>
      {_io.acaoSelecionada[indexItensRegraForm]?.operacaoId === 'AritmeticaOperacao' && (
        <>
          <Grid item sm={12} md={2}>
            <FormGroup>
              <Controller
                render={({ field }) => (
                  <FormControl>
                    <InputLabel>Operação</InputLabel>
                    <Select {...field} label="Operação">
                      {itensCampo
                        .filter((item) => item.operacaoId === _io.acaoSelecionada[indexItensRegraForm]?.operacaoId)[0]
                        ?.operadores.map((item: string, index: number) => (
                          <MenuItem key={index} value={item}>
                            {item}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                )}
                name={`acoes.${indexItensRegraForm}.operador` as `acoes.0.operador`}
                control={_io.control}
              />
              <TooltipComponent texto={TRANSFORMACAO_INFO.acao.aritmetica.replace('{0}', _io.acaoSelecionada[indexItensRegraForm].operador)} />
            </FormGroup>
          </Grid>
          <FieldsTipoParametro
            itensCampo={itensCampo}
            control={_io.control}
            cabecalhos={cabecalhos}
            indexItensRegraForm={indexItensRegraForm}
            itensRegraTransformacaoForm={_io.acaoSelecionada}
          />
        </>
      )}
    </>
  )
}
