import { Collapse, Grid, IconButton, TableBody, TableHead, TableRow } from '@material-ui/core'
import { IOProps } from 'react-compose-io'
import { cabecalhoControleComissao } from '../../../../types/tabelaTypes'
import { formateDate, formateHoras, formateCurrency, validarTexto } from '../../../../utils/stringUtils'
import { HistoricoInadimplenciaIO } from './HistoricoInadimplencia.IO'
import { Tipografia } from '../../../../components/Tipografia/index'
import { corChip, corLetraChip, customTheme } from '../../../../styles/customTheme'
import { ObterPedidosInadimplenciaResponse } from '../../../../types/controleInadimplenciaTypes'
import { ChipClassificacaoPerda } from '../../../../components/ChipClassificacaoPerda/ChipClassificacaoPerda'
import { Check } from '../../../../assets/icons/Check'

export type HistoricoInadimplenciaProps = {
  item: ObterPedidosInadimplenciaResponse
  historicoAberto: boolean
  isAdministrador: boolean
  handleAprovarSugestao: (pedidoMkt: string, classificacaoSugerida: number) => void
}

export function HistoricoInadimplenciaView({
  _io,
  item,
  historicoAberto,
  isAdministrador,
  handleAprovarSugestao
}: IOProps<HistoricoInadimplenciaIO, HistoricoInadimplenciaProps>) {
  const {
    TableRowHistorico,
    TableCellHistorico,
    ContainerHistorico,
    ContainerDetalheObservacao,
    TabelaTitulo,
    TituloDescricao,
    TipografiaHistorico,
    CelulaCabecalho,
    CelulaTabela,
    ContainerFlex
  } = _io.styles

  return (
    <TableRowHistorico>
      <TableCellHistorico colSpan={cabecalhoControleComissao.length}>
        <Collapse in={historicoAberto} timeout="auto" unmountOnExit>
          <TituloDescricao variant="subtitle1" fontWeight="600">
            Detalhes
          </TituloDescricao>
          <ContainerHistorico>
            <Grid container spacing={2} direction="column">
              <Grid item>
                <Tipografia fontWeight="600" colorText={customTheme.colors.cinza08} variant="subtitle1">
                  {`Títulos referentes ao pedido ${item.pedidoMkt} `}
                </Tipografia>
                {item.titulos.length > 0 ? (
                  <TabelaTitulo>
                    <TableHead>
                      <TableRow>
                        <CelulaCabecalho>Número do título</CelulaCabecalho>
                        <CelulaCabecalho>Valor inadimplente</CelulaCabecalho>
                        <CelulaCabecalho>Data Emissão</CelulaCabecalho>
                        <CelulaCabecalho>Data de vencimento</CelulaCabecalho>
                        <CelulaCabecalho>Refaturado</CelulaCabecalho>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {item.titulos.map((titulo, tituloId) => (
                        <TableRow key={tituloId}>
                          <CelulaTabela>{titulo.numTitulo}</CelulaTabela>
                          <CelulaTabela>{formateCurrency(titulo.saldo)}</CelulaTabela>
                          <CelulaTabela isdata>{formateDate(titulo.dataEmissao)?.toString()}</CelulaTabela>
                          <CelulaTabela isdata>{formateDate(titulo.dataVencimento)?.toString()}</CelulaTabela>
                          <CelulaTabela>{validarTexto(titulo.refaturado, '-')}</CelulaTabela>
                        </TableRow>
                      ))}
                    </TableBody>
                  </TabelaTitulo>
                ) : (
                  <TipografiaHistorico fontWeight="400" colorText={customTheme.colors.grey21} variant="subtitle1" margintop="24px">
                    Títulos já liquidados.
                  </TipografiaHistorico>
                )}
              </Grid>
              <Grid item>
                <TipografiaHistorico marginbottom="24px" fontWeight="500" colorText={customTheme.colors.cinza08} variant="subtitle1">
                  Observações
                </TipografiaHistorico>
                {item.observacoes.length > 0 ? (
                  <>
                    {item.observacoes.map((historico, index) => (
                      <ContainerDetalheObservacao key={index}>
                        {historico.observacao.startsWith('Classificação sugerida:') && (
                          <>
                            <Tipografia fontWeight="500" colorText={customTheme.colors.grey21} variant="subtitle1">
                              {_io.getObservacao(historico.observacao)}
                            </Tipografia>
                            <ContainerFlex>
                              <Tipografia fontWeight="500" colorText={customTheme.colors.azul04} variant="subtitle1">
                                Classificação sugerida:
                              </Tipografia>
                              <ChipClassificacaoPerda
                                label={_io.getClassificacaoSugerida(historico.observacao)}
                                bgcolor={corChip.get(_io.getClassificacaoSugerida(historico.observacao))}
                                fontcolor={corLetraChip.get(_io.getClassificacaoSugerida(historico.observacao))}
                              />
                              {_io.getPrimeiraClassificacaoSugerida(index) && (
                                <>
                                  {!item.aprovado && isAdministrador && (
                                    <IconButton onClick={() => handleAprovarSugestao(item.pedidoMkt, item.classificacaoId)}>
                                      <Check color={customTheme.colors.azul04} />
                                    </IconButton>
                                  )}
                                  {!item.aprovado && !isAdministrador && (
                                    <Tipografia fontWeight="500" colorText={customTheme.colors.grey21} variant="subtitle1">
                                      {' - Aguardando Aprovação'}
                                    </Tipografia>
                                  )}
                                </>
                              )}
                            </ContainerFlex>
                          </>
                        )}

                        {historico.observacao.startsWith('Classificação aprovada.') && (
                          <>
                            <Tipografia fontWeight="500" colorText={customTheme.colors.grey21} variant="subtitle1">
                              {_io.getObservacao(historico.observacao)}
                            </Tipografia>
                            <Tipografia fontWeight="500" colorText={customTheme.colors.verde06} variant="subtitle1">
                              Classificação aprovada.
                            </Tipografia>
                          </>
                        )}

                        {!historico.observacao.startsWith('Classificação aprovada.') && !historico.observacao.startsWith('Classificação sugerida:') && (
                          <Tipografia fontWeight="500" colorText={customTheme.colors.grey21} variant="subtitle1">
                            {historico.observacao}
                          </Tipografia>
                        )}

                        <TipografiaHistorico margintop="12px" variant="body2">
                          {`${formateDate(historico.data)} às ${formateHoras(historico.data)} | Usuário: ${historico.usuario}`}
                        </TipografiaHistorico>
                      </ContainerDetalheObservacao>
                    ))}
                  </>
                ) : (
                  <Tipografia fontWeight="400" colorText={customTheme.colors.grey21} variant="subtitle1">
                    Este pedido não possui nenhuma observação registrada.
                  </Tipografia>
                )}
              </Grid>
            </Grid>
          </ContainerHistorico>
        </Collapse>
      </TableCellHistorico>
    </TableRowHistorico>
  )
}
