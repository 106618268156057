import { Grid, Table, TableBody, TableContainer, Typography } from '@material-ui/core'
import { IOProps } from 'react-compose-io'
import { EnhancedTableHead } from '../../components/EnhancedTableHead'
import { cabecalhoConfiguracoesGerais } from '../../types/tabelaTypes'
import { ItemConfiguracoesGerais } from './components/ItemConfiguracoesGerais/index'
import { ModalConfiguracoesGerais } from './components/ModalConfiguracoesGerais/index'
import { ConfiguracoesGeraisIO } from './ConfiguracoesGerais.IO'
import { Botao } from './../../components/Botao/index'

export type ConfiguracoesGeraisProps = {}

export function ConfiguracoesGeraisView({ _io }: IOProps<ConfiguracoesGeraisIO, ConfiguracoesGeraisProps>) {
  const { Container } = _io.styles

  return (
    <>
      <Container>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="h6">Configurações Gerais</Typography>
          </Grid>
          <Grid item>
            <Botao textoBotao={'Cadastrar'} cor="primaria" estilo="contained" onClick={_io.handleAbrirModal} />
          </Grid>
        </Grid>
        <TableContainer>
          <Table>
            <EnhancedTableHead alignCenter={true} order={_io.ordem} onRequestSort={_io.onRequestSort} headCells={cabecalhoConfiguracoesGerais} />

            <TableBody>
              {_io.configuracoesLista.length > 0 ? (
                _io.configuracoesLista.map((row, index) => <ItemConfiguracoesGerais key={index} linhaConfiguracaoGeral={row} />)
              ) : (
                <ItemConfiguracoesGerais key={_io.linhaPadrao.siglaSistema} linhaConfiguracaoGeral={_io.linhaPadrao} />
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>

      <ModalConfiguracoesGerais abrirModal={_io.abrirModal} handleFecharModal={_io.handleFecharModal} handleSalvarModal={_io.handleSalvarConfiguracoesGerais} />
    </>
  )
}
