import { Box, FormControl, Grid, InputLabel, MenuItem, Select } from '@material-ui/core'
import { IOProps } from 'react-compose-io'
import { Controller } from 'react-hook-form'
import ConfirmarDialog from '../../../../components/ConfirmarDialog/ConfirmarDialog'
import { ControleComissao, ControleComissaoStatusForm, ControleComissaoStatus, ControleComissaoStatusMap } from '../../../../types/controleComissaoTypes'
import { ModalControleComissaoStatusIO } from './ModalControleComissaoStatus.IO'

export type ModalControleComissaoStatusProps = {
  isModalAberto: boolean
  setAbrirModal: (value: boolean | ((prevVar: boolean) => boolean)) => void
  dadosControleComissao: ControleComissao
  handleConfirmarEdicao: (dataFormulario: ControleComissaoStatusForm) => Promise<void>
}

export default function ModalControleComissaoStatusView({
  _io,
  isModalAberto,
  setAbrirModal,
  dadosControleComissao,
  handleConfirmarEdicao
}: IOProps<ModalControleComissaoStatusIO, ModalControleComissaoStatusProps>) {
  const { Container, Typography } = _io.styles

  return (
    <ConfirmarDialog
      open={isModalAberto}
      setOpen={setAbrirModal}
      confirmarButtonLabel={'Confirmar'}
      cancelarButtonLabel={'Cancelar'}
      handleConfirm={_io.handleSubmit(handleConfirmarEdicao)}
    >
      <Box>
        <Typography>
          Para qual status deseja mudar o incidente, refente ao ciclo <b>#{dadosControleComissao.cicloId}</b> do parceiro{' '}
          <b>{dadosControleComissao.parceiro?.descricao}</b>?
        </Typography>
      </Box>
      <form>
        <Container>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Controller
                render={({ field }) => (
                  <FormControl variant="outlined" size="small" fullWidth>
                    <InputLabel>Status</InputLabel>
                    <Select {...field} label="Status">
                      <MenuItem value={ControleComissaoStatus.Concluido}>{ControleComissaoStatusMap.get(ControleComissaoStatus.Concluido)}</MenuItem>
                      <MenuItem value={ControleComissaoStatus.EmAndamento}>{ControleComissaoStatusMap.get(ControleComissaoStatus.EmAndamento)}</MenuItem>
                      <MenuItem value={ControleComissaoStatus.Pendente}>{ControleComissaoStatusMap.get(ControleComissaoStatus.Pendente)}</MenuItem>
                    </Select>
                  </FormControl>
                )}
                name="status"
                control={_io.control}
              />
            </Grid>
          </Grid>
        </Container>
      </form>
    </ConfirmarDialog>
  )
}
