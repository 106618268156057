import { useState } from 'react'
import { historicoRegularizacaoStyles } from './HistoricoRegularizacao.styles'
import { HistoricoRegularizacaoProps } from './HistoricoRegularizacao.View'

export function historicoRegularizacaoIO({ buscarHistorico, valorSetTimeOut }: HistoricoRegularizacaoProps) {
  const [pagina, setPagina] = useState<number>(0)
  const [linhasPorPagina, setLinhasPorPagina] = useState<number>(5)

  const onMudancaPagina = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, novaPagina: number) => {
    setPagina(novaPagina)
    clearTimeout(valorSetTimeOut)
    buscarHistorico({ pageNumber: novaPagina + 1, pageSize: linhasPorPagina })
  }

  const onMudancaLinhasPorPagina = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPagina(0)
    setLinhasPorPagina(parseInt(event.target.value, 10))
    clearTimeout(valorSetTimeOut)
    buscarHistorico({ pageNumber: 1, pageSize: parseInt(event.target.value, 10) })
  }

  return {
    styles: historicoRegularizacaoStyles,
    rowsPerPageOptions: [5, 10, 25],
    onMudancaPagina,
    onMudancaLinhasPorPagina,
    pagina,
    linhasPorPagina
  }
}

export type HistoricoRegularizacaoIO = ReturnType<typeof historicoRegularizacaoIO>
