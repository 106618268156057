import { useState } from 'react'
import { toast } from 'react-toastify'
import cicloPedidosManager from '../../../../services/api/cicloPedidos/cicloPedidosManager'
import { base64FileToCsvArray, csvArrayToFile, getExtensaoArquivo } from '../../../../utils/uploadFileUtils'
import { uploadPlanilhaCicloPedidoStyles } from './UploadPlanilhaCicloPedido.style'
import { UploadPlanilhaCicloPedidoProps } from './UploadPlanilhaCicloPedido.View'

export function uploadPlanilhaCicloPedidoIO({ setCarregando, dataUpload, parceiroSelecionado, atualizarDivergencias }: UploadPlanilhaCicloPedidoProps) {
  const [dropVisivel, setDropVisivel] = useState(false)
  const [uploadSucesso, setUploadSucesso] = useState(false)
  const [arquivo, setArquivo] = useState<FormData>()
  const [nomeArquivo, setNomeArquivo] = useState('')
  const [tamanhoArquivo, setTamanhoArquivo] = useState<number>()

  const onArquivoAceito = (arquivosPlanilhaDivergencias: File[]) => {
    setDropVisivel(true)

    arquivosPlanilhaDivergencias.forEach((planilhaDivergencias: File) => {
      setNomeArquivo(planilhaDivergencias.name)
      setTamanhoArquivo(planilhaDivergencias.size)

      const fileExtension = getExtensaoArquivo(planilhaDivergencias.name)
      const reader = new FileReader()

      if (fileExtension != undefined) {
        reader.onloadend = () => {
          const fileArray: any[] = base64FileToCsvArray(reader.result as string)
          const contentType = 'text/csv'
          const novoArquivo = csvArrayToFile(fileArray, planilhaDivergencias.name, contentType)

          setUploadSucesso(true)

          const formData: FormData = new FormData()
          formData.append('file', novoArquivo, planilhaDivergencias.name)

          setArquivo(formData)
        }
      }

      reader.readAsDataURL(planilhaDivergencias)
    })
  }

  const handleCancelar = () => {
    setDropVisivel(false)
    setUploadSucesso(false)
    setArquivo(undefined)
  }

  const handleConfirmar = () => {
    if (arquivo) {
      setCarregando(true)
      handleCancelar()
      cicloPedidosManager
        .gerarDivergencias(parceiroSelecionado, arquivo)
        .then(() => {
          atualizarDivergencias()
        })
        .catch((error) => {
          setCarregando(false)
          toast.error(error.message)
        })
    }
  }

  return {
    styles: uploadPlanilhaCicloPedidoStyles,
    onArquivoAceito,
    dropVisivel,
    nomeArquivo,
    tamanhoArquivo,
    uploadSucesso,
    handleCancelar,
    handleConfirmar,
    dataUpload
  }
}

export type UploadPlanilhaCicloPedidoIO = ReturnType<typeof uploadPlanilhaCicloPedidoIO>
