import { stylesFieldForms } from './../styles'
import { FieldsTipoParametroProps } from './FieldsTipoParametro.View'

export function fieldsTipoParametroIO({ isCondicao, indexItensRegraForm }: FieldsTipoParametroProps) {
  const verificarCondicao = (campo: string) => {
    return isCondicao
      ? (`condicoes.${indexItensRegraForm}.parametro.${campo}` as `string.0.string.string`)
      : (`acoes.${indexItensRegraForm}.parametro.${campo}` as `string.0.string.string`)
  }

  return {
    styles: stylesFieldForms,
    verificarCondicao
  }
}

export type FieldsTipoParametroIO = ReturnType<typeof fieldsTipoParametroIO>
