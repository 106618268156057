import { FormControl, Grid, GridSize, InputLabel, MenuItem, Select, TextField } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import { IOProps } from 'react-compose-io'
import { Controller } from 'react-hook-form'
import { FormularioTipoLancamentoParceiroIO } from './FormularioTipoLancamentoParceiro.IO'
import { Botao } from './../Botao/index'

export type FormularioTipoLancamentoParceiroProps = {
  isClosed?: () => void
  cadastrarTipoLancamentoParceiro: (item: any) => void
  isModal?: boolean
  dadosLinha?: any
  larguraDescricao: GridSize
  larguraSiglaContabilizacao: GridSize
}

export function FormularioTipoLancamentoParceiroView({
  _io,
  isModal,
  larguraSiglaContabilizacao,
  larguraDescricao
}: IOProps<FormularioTipoLancamentoParceiroIO, FormularioTipoLancamentoParceiroProps>) {
  return (
    <form>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={larguraDescricao} lg={larguraDescricao}>
          <Controller
            render={({ field }) => (
              <TextField
                required
                {..._io.register('descricao', { required: true })}
                {...field}
                label="Descrição do tipo de lançamento"
                variant="outlined"
                size="small"
                error={_io.errors?.descricao ? true : false}
                helperText={_io.errors.descricao?.message}
                fullWidth
              />
            )}
            name="descricao"
            control={_io.control}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={larguraDescricao} lg={larguraSiglaContabilizacao}>
          <Controller
            render={({ field }) => (
              <FormControl variant="outlined" size="small" fullWidth required>
                <InputLabel id="tipoContabilizacaoId-label">Tipo de contabilizacao</InputLabel>
                <Select
                  {..._io.register('tipoContabilizacaoId', { required: true })}
                  {...field}
                  labelId="tipoContabilizacaoId-label"
                  label="Tipo de contabilizacao"
                  error={_io.errors?.tipoContabilizacaoId ? true : false}
                >
                  <MenuItem value={`F`}>Fixo</MenuItem>
                  <MenuItem value={`V`}>Variável</MenuItem>
                </Select>
              </FormControl>
            )}
            name="tipoContabilizacaoId"
            control={_io.control}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={larguraDescricao} lg={larguraSiglaContabilizacao}>
          <Controller
            render={({ field }) => (
              <TextField
                required
                {..._io.register('sigla', { required: true })}
                {...field}
                label="Sigla Histórico Padrão"
                variant="outlined"
                size="small"
                fullWidth
                error={_io.errors?.sigla ? true : false}
                helperText={_io.errors.sigla?.message}
                inputProps={{ maxLength: 5 }}
              />
            )}
            name="sigla"
            control={_io.control}
          />
        </Grid>
        {isModal ? (
          <Grid item container justifyContent="center" spacing={2}>
            <Grid item>
              <Botao textoBotao="Cancelar" estilo="text" cor="primaria" onClick={_io.limparComponente} />
            </Grid>
            <Grid item>
              <Botao textoBotao="Salvar" estilo="rounded" cor="primaria" onClick={_io.handleSubmit(_io.salvarTipoLancamentoParceiro)} width="114px" />
            </Grid>
          </Grid>
        ) : (
          <Grid item xs={12} sm={12} md={3} lg={2}>
            <Botao startIcon={<AddIcon />} textoBotao="Adicionar" estilo="rounded" cor="primaria" onClick={_io.salvarTipoLancamentoParceiro} />
          </Grid>
        )}
      </Grid>
    </form>
  )
}
