import { IOProps } from 'react-compose-io'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import DivergenteIcone from '../../../../assets/icons/divergentes-icon.svg'
import { PedidosDivergentesResumoIO } from './PedidosDivergentesResumo.IO'
import Chart from 'react-apexcharts'
import { TooltipComponent } from '../../../../components/Tooltip/Tooltip'
import { Collapse, Grid, IconButton } from '@material-ui/core'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import { customTheme } from '../../../../styles/customTheme'
import { DivergentesResponse } from '../../../../types/cicloPedidosTypes'
import { PAGES } from './../../../../router/routes'
import { Botao } from './../../../../components/Botao/index'
import { BotaoIconeExportar } from '../../../../components/BotaoIconeExportar/BotaoIconeExportar'

export type PedidosDivergentesResumoProps = {
  divergencias: DivergentesResponse
  parceiroSelecionado: number
}

export function PedidosDivergentesResumoView({ _io, divergencias, parceiroSelecionado }: IOProps<PedidosDivergentesResumoIO, PedidosDivergentesResumoProps>) {
  const { BoxUploadPlanilha, TituloGrafico, ContainerTituloGrafico, DividerHorizontal, DividerVertical, GridCollapse, BoxResumo, Tipografia } = _io.styles

  return (
    <BoxUploadPlanilha>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <ContainerTituloGrafico>
            <TituloGrafico fontWeight="500" fontSize={customTheme.fontSize[18]} colorText={customTheme.colors.grey23}>
              Pedidos com status divergente
            </TituloGrafico>
            <TooltipComponent
              texto={`A tabela abaixo mostra quantos pedidos estão com status diferente do nosso status de tracking.`}
              Icone={HelpOutlineIcon}
              color={'action'}
            />
          </ContainerTituloGrafico>
        </Grid>
        <Grid item>
          <BotaoIconeExportar url={divergencias.urlArquivo} />
        </Grid>
      </Grid>

      <Grid container spacing={2} justifyContent="space-evenly">
        <Grid item>
          <Chart options={_io.options} series={_io.series} type="bar" width="500" height="230" />
        </Grid>
        <Grid item>
          <BoxResumo>
            <Grid container direction="column" justifyContent="center" alignItems="center">
              <Grid item>
                <img src={DivergenteIcone} />
              </Grid>
              <Grid item>
                <Tipografia fontWeight="500">Total de pedidos divergentes</Tipografia>
              </Grid>
              <Grid item>
                <Tipografia fontWeight="600" variant="h5">
                  {divergencias.total.toLocaleString('pt-BR')}
                </Tipografia>
              </Grid>
              <Grid item>
                <Collapse in={_io.abrirDivergentes} timeout="auto" unmountOnExit>
                  <DividerHorizontal />
                  <Grid container justifyContent="space-evenly" spacing={2}>
                    <GridCollapse item>
                      <Tipografia fontWeight="500">Total no gráfico</Tipografia>
                      <Tipografia fontWeight="600" variant="h5">
                        {divergencias.totalGrafico?.toLocaleString('pt-BR')}
                      </Tipografia>
                    </GridCollapse>
                    <Grid item>
                      <DividerVertical />
                    </Grid>
                    <GridCollapse item>
                      <Tipografia fontWeight="500">Total antigos</Tipografia>
                      <Tipografia fontWeight="600" variant="h5">
                        {divergencias.totalAntigos?.toLocaleString('pt-BR')}
                      </Tipografia>
                    </GridCollapse>
                  </Grid>
                </Collapse>
              </Grid>
              <Grid item>
                <IconButton size="small" onClick={() => _io.setAbrirDivergentes(!_io.abrirDivergentes)}>
                  {_io.abrirDivergentes ? <KeyboardArrowUpIcon fontSize="large" /> : <KeyboardArrowDownIcon fontSize="large" />}
                </IconButton>
              </Grid>
            </Grid>
          </BoxResumo>
          <Botao
            onClick={() => _io.router.push(PAGES.detalhesCicloPedidoParceiro(parceiroSelecionado))}
            textoBotao="Visualizar detalhes"
            cor="primaria"
            estilo="contained"
            width="361px"
          />
        </Grid>
      </Grid>
    </BoxUploadPlanilha>
  )
}
