import { FormControl, Grid, InputLabel, MenuItem, Select } from '@material-ui/core'
import React from 'react'
import { IOProps } from 'react-compose-io'
import { Control, Controller, DeepMap, FieldError } from 'react-hook-form'
import { TipoLancamentoParceiro } from '../../../../types/parceiroApiTypes'
import { DadosTipoLancamentoIO } from './DadosTipoLancamento.IO'
import { Botao } from './../../../../components/Botao/index'

export type DadosTipoLancamentoProps = {
  tipoLancamento?: TipoLancamentoParceiro[]
  handleClose?: () => void
  handleSubmitForm?: () => void
  controlForm?: Control<TipoLancamentoParceiro>
  handleChangeForm?: (
    event: React.ChangeEvent<{
      name?: string
      value: unknown
    }>
  ) => void
  handleErros?: DeepMap<TipoLancamentoParceiro, FieldError>
}

function DadosTipoLancamentoView({
  controlForm,
  handleChangeForm,
  handleClose,
  handleSubmitForm,
  tipoLancamento
}: IOProps<DadosTipoLancamentoIO, DadosTipoLancamentoProps>) {
  return (
    <>
      <form onSubmit={handleSubmitForm} className="form">
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={8}>
            <Controller
              render={({ field }) => (
                <FormControl variant="outlined" size="small" fullWidth>
                  <InputLabel>Tipo Lancamento Parceiro</InputLabel>
                  <Select
                    {...field}
                    labelId="tipoLancamentoParceiroId"
                    id="tipoLancamentoParceiroId"
                    label="Tipo Lancamento Parceiro"
                    onChange={handleChangeForm}
                  >
                    {tipoLancamento?.map((item: TipoLancamentoParceiro) => (
                      <MenuItem key={item?.tipoLancamentoParceiroId} value={item.descricao || ''}>
                        {item.descricao || ''}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
              name="descricao"
              control={controlForm}
            />
          </Grid>
          <Grid item xs={12} sm={4} container direction="row" justifyContent="flex-end" spacing={2}>
            <Grid item>
              <Botao textoBotao="Cancelar" onClick={handleClose} estilo="text" cor="primaria" />
            </Grid>
            <Grid item>
              <Botao textoBotao="Confirmar" estilo="rounded" cor="primaria" type="submit" />
            </Grid>
          </Grid>
        </Grid>
      </form>
    </>
  )
}
export default DadosTipoLancamentoView
