import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { ORDEM_CRESCENTE } from '../../constants/utilConstants'
import parceiroManager from '../../services/api/parceiro/parceiroManager'
import { loadingReset, loadingUpdate } from '../../store/actions/loadingAction'
import { Parceiro } from '../../types/parceiroApiTypes'
import { Order } from '../../types/tabelaTypes'
import { handleRequestSort, tratarMudancaLinhaPorPagina, tratarMudancaPagina } from '../../utils/tabelaUtils'
import { parceirosStyles } from './Parceiros.style'

export function parceirosIO() {
  const dispatch = useDispatch()
  const [abaId, setAbaId] = useState(0)
  const [ordem, setOrdem] = useState<Order>(ORDEM_CRESCENTE)
  const [ordemBy, setOrdemBy] = useState<keyof never>('parceiroId')
  const [pagina, setPagina] = useState(0)
  const [linhasPorPagina, setLinhasPorPagina] = useState(5)
  const [parceiroList, setParceiroList] = useState<Parceiro[]>([])

  useEffect(() => {
    const isFiltroParceirosAtivos = () => {
      if (abaId == 0) return true
      if (abaId == 1) return false

      return null
    }

    async function fetchData() {
      const response: Parceiro[] = await parceiroManager.buscarDadosParceiros(isFiltroParceirosAtivos())
      setParceiroList(response)
      dispatch(loadingReset())
    }

    dispatch(loadingUpdate(true))
    fetchData()
  }, [dispatch, abaId])

  const alterarAba = (event: React.ChangeEvent<{}>, abaSelecionada: number) => {
    setAbaId(abaSelecionada)
  }

  return {
    styles: parceirosStyles,
    parceiroList,
    abaId,
    alterarAba,
    opcoesLinhasPorPaginas: [5, 10, 25],
    onMudancaPagina: (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, novaPagina: number) => {
      tratarMudancaPagina(event, novaPagina, setPagina)
    },
    onMudancaLinhasPorPagina: (event: React.ChangeEvent<HTMLInputElement>) => {
      tratarMudancaLinhaPorPagina(event, setPagina, setLinhasPorPagina)
    },
    onRequestSort: handleRequestSort(ordemBy, ordem, setOrdem, setOrdemBy),
    ordem,
    pagina,
    ordemBy,
    linhasPorPagina
  }
}

export type ParceirosIO = ReturnType<typeof parceirosIO>
