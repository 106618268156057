import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import msgConstants from '../../../../constants/msgConstants'
import ciclosManager from '../../../../services/api/ciclos/ciclosManager'
import { IOpsVinculadas, TotalCiclo } from '../../../../types/ciclosApiType'
import { vincularOPSStyles } from './VincularOPS.style'
import { VincularOPSProps } from './VincularOPS.View'

export function vincularOPSIO({ cicloId, setEnableConfirmar, setOpsSucesso }: VincularOPSProps) {
  const [openOPS, setOpenOPS] = useState(false)
  const [ops, setOps] = useState<IOpsVinculadas[]>([])
  const [loading, setLoading] = useState(false)
  const [total, setTotal] = useState<number>()
  const [totalOps, setTotalOps] = useState<number>()
  const [diferenca, setDiferenca] = useState<number>()

  useEffect(() => {
    if (totalOps && (total || total === 0)) {
      const subt = total - totalOps
      setDiferenca(subt)
      setOpsSucesso(true)
      setEnableConfirmar(true)
    }
  }, [setDiferenca, setOpsSucesso, setEnableConfirmar, totalOps, total])

  useEffect(() => {
    const buscarOpsVinculadas = async () => {
      setLoading(true)
      await ciclosManager
        .buscarOpsVinculadasM(cicloId)
        .then((response) => {
          if (response) {
            setOps(response)
          }
        })
        .catch(() => {
          toast.error(msgConstants.OPS.buscar)
        })
        .finally(() => {
          setLoading(false)
        })
    }
    buscarOpsVinculadas()
  }, [cicloId, total])

  const atualizaOP = async () => {
    setLoading(true)
    await ciclosManager
      .buscarOpsVinculadasM(cicloId)
      .then((response) => {
        setOps(response)
      })
      .catch(() => {
        toast.error(msgConstants.OPS.erroBuscar)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    const buscarTotalCiclo = async () => {
      await ciclosManager
        .buscarTotalCiclo(cicloId)
        .then((response: TotalCiclo) => {
          setTotal(response.totalCiclo)
        })
        .catch(() => {
          toast.error(msgConstants.OPS.erroBuscarTotal)
        })
    }

    buscarTotalCiclo()
  }, [cicloId])

  useEffect(() => {
    const valoresOPs: number[] | undefined = []
    if (ops) {
      ops.forEach((item) => {
        if (item?.valorPagamento) {
          valoresOPs.push(item?.valorPagamento)
        }

        const soma = valoresOPs.reduce((totalValoresOPs: number, numero: number) => totalValoresOPs + numero, 0)

        if (soma) {
          setTotalOps(soma)
        }
      })
    }
  }, [ops])

  function abrirModalOPS() {
    setOpenOPS(true)
  }

  function fecharModalOPS() {
    setOpenOPS(false)
  }

  return {
    styles: vincularOPSStyles,
    atualizaOP,
    cicloId,
    openOPS,
    fecharModalOPS,
    total,
    abrirModalOPS,
    loading,
    ops,
    totalOps,
    diferenca
  }
}
export type VincularOPSIO = ReturnType<typeof vincularOPSIO>
