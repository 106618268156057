import { Checkbox, TableCell, TableRow } from '@material-ui/core'
import { IOProps } from 'react-compose-io'
import { OPPorPedidoResponse, TituloPorPedidoResponse } from '../../types/regularizacaoOPsTitulosTypes'
import { cabecalhoOrdemPagamento, cabecalhoRegularizacaoTitulo } from '../../types/tabelaTypes'
import { formateCurrency, formateDate } from '../../utils/stringUtils'
import { InformacoesIniciaisPedido } from './components/InformacoesIniciaisPedido'
import { ModalRegularizacaoCiclos } from './components/ModalRegularizacaoCiclos'
import { TabelaRegularizacaoEfetivacao } from './components/TabelaRegularizacaoEfetivacao/index'
import { RegularizacaoCiclosEfetivacaoIO } from './RegularizacaoCiclosEfetivacao.IO'
import { Tipografia } from './../../components/Tipografia/index'
import { Botao } from './../../components/Botao/index'

export type RegularizacaoCiclosEfetivacaoProps = {}

export function RegularizacaoCiclosEfetivacaoView({ _io }: IOProps<RegularizacaoCiclosEfetivacaoIO, RegularizacaoCiclosEfetivacaoProps>) {
  const { Container, ContainerTabela } = _io.styles

  function linhaTitulo(props: { item: TituloPorPedidoResponse }) {
    const { item } = props
    const isItemSelected = _io.isSelectedTitulo(item)
    const labelId = `enhanced-table-checkbox-${item}`

    return (
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            onChange={(event) => _io.handleCheckboxTitulo(event, item)}
            aria-checked={isItemSelected}
            tabIndex={-1}
            key={item.tituloId}
            checked={isItemSelected}
            inputProps={{ 'aria-labelledby': labelId }}
          />
        </TableCell>
        <TableCell>{item.pedidoEfacil}</TableCell>
        <TableCell>{item.tituloId}</TableCell>
        <TableCell>{item.parcela}</TableCell>
        <TableCell>{formateDate(item.dataEmissao)}</TableCell>
        <TableCell>{formateDate(item.dataVencimento)}</TableCell>
        <TableCell>{formateCurrency(item.saldo)}</TableCell>
      </TableRow>
    )
  }

  function linhaOrdemPagamento(props: { item: OPPorPedidoResponse }) {
    const { item } = props
    const isItemSelected = _io.isSelectedOps(item)
    const labelId = `enhanced-table-checkbox-${item.ordemPagamentoId}`

    return (
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            onChange={(event) => _io.handleCheckboxOps(event, item)}
            aria-checked={isItemSelected}
            tabIndex={-1}
            key={item.ordemPagamentoId}
            checked={isItemSelected}
            inputProps={{ 'aria-labelledby': labelId }}
          />
        </TableCell>
        <TableCell>{item.ordemPagamentoId}</TableCell>
        <TableCell>{item.banco}</TableCell>
        <TableCell>{item.emitente}</TableCell>
        <TableCell>{item.descricaoPagamento}</TableCell>
        <TableCell>{formateDate(item.dataEmissao)}</TableCell>
        <TableCell>{formateCurrency(item.valor)}</TableCell>
      </TableRow>
    )
  }

  return (
    <>
      <Tipografia variant="h5">Regularização de ciclos - Pendências</Tipografia>
      <Container>
        <Tipografia variant="h6">{`Pedido ${_io.regularizacaoCiclos.pedido}`}</Tipografia>
        <InformacoesIniciaisPedido informacoesPedido={_io.regularizacaoCiclos} />
        <TabelaRegularizacaoEfetivacao
          informacoesTabela={_io.regularizacaoCiclos.titulos}
          handleSelectAllClick={_io.handleCheckboxTodosTitulo}
          tituloTabela={'Títulos'}
          cabecalhoTabela={cabecalhoRegularizacaoTitulo}
          valorTotalTabela={_io.valorTotalTitulosSelecionados}
          linhaTabela={linhaTitulo}
          totalSelecionados={_io.titulosSelecionados.length}
        />
        <TabelaRegularizacaoEfetivacao
          informacoesTabela={_io.regularizacaoCiclos.ops}
          handleSelectAllClick={_io.handleCheckboxTodosOps}
          tituloTabela={'Ordens de pagamento'}
          cabecalhoTabela={cabecalhoOrdemPagamento}
          valorTotalTabela={_io.valorTotalOPsSelecionadas}
          linhaTabela={linhaOrdemPagamento}
          totalSelecionados={_io.opsSelecionados.length}
        />
        <ContainerTabela>
          <Botao textoBotao={'Limpar'} cor="primaria" estilo="outlined" onClick={_io.limparTodosCheckbox} />
          <Botao
            textoBotao={'Regularizar com OP'}
            cor="primaria"
            estilo="contained"
            type="submit"
            disabled={_io.bloquearBotaoConfirmarEfetivacao}
            onClick={_io.handleAvaliarCondicoesRegularizacao}
          />
        </ContainerTabela>
      </Container>
      <ModalRegularizacaoCiclos
        handleConfirmarEfetivacao={_io.handleConfirmarEfetivacao}
        isShow={_io.abrirModalEfetivacao}
        setOpen={_io.setAbrirModalEfetivacao}
        valorTotalOPsSelecionadas={_io.valorTotalOPsSelecionadas}
        valorTotalTitulosSelecionados={_io.valorTotalTitulosSelecionados}
        sequencias={_io.sequencias}
      />
    </>
  )
}
