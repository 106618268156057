import { ResponsePaginado } from '../../../types/genericoType'
import { HistoricoRegularizacao, ValidacaoPlanilhaResponse } from '../../../types/regularizacaoTypes'
import { Paginacao } from '../../../types/tabelaTypes'
import RegularizacaoPerdasService from './regularizacaoPerdasService'

const efetivarRegularizacaoPerdas = async (arquivoId: string): Promise<boolean> => RegularizacaoPerdasService.efetivarRegularizacaoPerdas(arquivoId)

const validarPlanilhaRegularizacaoPerdas = async (arquivo: FormData): Promise<ValidacaoPlanilhaResponse> =>
  RegularizacaoPerdasService.validarPlanilhaRegularizacaoPerdas(arquivo)

const buscarHistoricoRegularizacaoPerdas = async (paginacao: Paginacao): Promise<ResponsePaginado<HistoricoRegularizacao>> =>
  RegularizacaoPerdasService.buscarHistoricoRegularizacaoPerdas(paginacao)

export default {
  buscarHistoricoRegularizacaoPerdas,
  validarPlanilhaRegularizacaoPerdas,
  efetivarRegularizacaoPerdas
}
