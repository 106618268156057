import { DeepMap, FieldError } from 'react-hook-form'
import { BuscarTitulosForm } from '../../../../types/titulosTypes'
import { formatarData } from '../../../../utils/stringUtils'
import { cabecalhoListaParceirosStyles } from './CabecalhoListaParceiros.style'

export function cabecalhoListaParceirosIO() {
  const verificarErros = (handleErros?: DeepMap<BuscarTitulosForm, FieldError>, erro?: FieldError) => {
    return handleErros != undefined && erro != undefined
  }

  return {
    styles: cabecalhoListaParceirosStyles,
    verificarErros,
    dataAtualPadrao: String(formatarData(new Date().toISOString(), '-'))
  }
}

export type CabecalhoListaParceirosIO = ReturnType<typeof cabecalhoListaParceirosIO>
