/* eslint-disable sonarjs/cognitive-complexity */
import { yupResolver } from '@hookform/resolvers/yup'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import * as yup from 'yup'
import msgConstants from '../../constants/msgConstants'
import { ORDEM_DECRESCENTE } from '../../constants/utilConstants'
import { LancamentoState } from '../../interfaces/interfaces'
import TipoLancamentoManager from '../../services/api/tipoLancamento/tipoLancamentoManager'
import { atualizarSequencias, desativarDesativacao, desativarEdicao } from '../../store/actions/lancamentoAction'
import { loadingReset, loadingUpdate } from '../../store/actions/loadingAction'
import { Order } from '../../types/tabelaTypes'
import {
  Config,
  defaultFormularioTipoLancamento,
  defaultHistoricoPadrao,
  FormularioTipoLancamento,
  HistoricoPadrao,
  semSequencia,
  Sequencias,
  TipoLancamento,
  TipoLancamentoDataApi,
  TiposLancamentosData
} from '../../types/tipoLancamentoApiTypes'
import { estaVazio } from '../../utils/stringUtils'
import { handleRequestSort, tratarMudancaLinhaPorPagina, tratarMudancaPagina } from '../../utils/tabelaUtils'
import { tiposDeLancamentosStyles } from './TiposDeLancamentos.style'

const schema = yup.object().shape({
  descricao: yup.string().required(msgConstants.USO_GENERICO.campo_obrigatorio),
  sequencia: yup.string().required(msgConstants.USO_GENERICO.campo_obrigatorio),
  referencia: yup.string().required(msgConstants.USO_GENERICO.campo_obrigatorio)
})

function ordernarLancamentoIndex(tipoLancamentoAnterior: TipoLancamento, tipoLancamentoPosterior: TipoLancamento) {
  if (tipoLancamentoAnterior.status > tipoLancamentoPosterior.status) {
    return 1
  }
  if (tipoLancamentoAnterior.status < tipoLancamentoPosterior.status) {
    return -1
  }

  return 0
}

export function tiposDeLancamentosIO() {
  const dispatch = useDispatch()
  const [tipoLancamento, setTipoLancamento] = useState<TipoLancamento>(defaultFormularioTipoLancamento)
  const [lancamentos, setLancamentos] = useState<TipoLancamento[]>([])
  const [lancamentosAux, setLancamentosAux] = useState<TipoLancamento[]>([])
  const [estadosItensTabela, setEstadosItensTabela] = useState<boolean[]>([])
  const [ordem, setOrdem] = useState<Order>(ORDEM_DECRESCENTE)
  const [ordemBy, setOrdemBy] = useState<keyof never>('id')
  const [linhasPorPagina, setLinhasPorPagina] = useState(5)
  const [pagina, setPagina] = useState(0)
  const [modal, setModal] = useState(false)
  const [filtro, setFiltro] = useState<unknown>('')
  const [configurarTipoLancamento, setConfigurarTipoLancamento] = useState<Config>()
  const [tiposLancamentos, setTiposLancamentos] = useState<TiposLancamentosData[]>([])
  const [sequencia, setSequencia] = useState<Sequencias[]>([])
  const [historicoPadrao, setHistoricoPadrao] = useState<HistoricoPadrao>()
  const { isChange } = useSelector((state: LancamentoState) => state.lancamento.change)
  const { isDesativado } = useSelector((state: LancamentoState) => state.lancamento.desativar)
  const { lancamentoObj } = useSelector((state: LancamentoState) => state.lancamento)
  const ordenarPeloStatus = () => {
    const ordenacao = [...lancamentosAux].sort(ordernarLancamentoIndex)
    setLancamentos(ordenacao)
  }

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors }
  } = useForm<FormularioTipoLancamento>({
    resolver: yupResolver(schema),
    defaultValues: {
      descricao: '',
      sequencia: '',
      conta: '',
      natureza: '',
      tipo: '',
      referencia: ''
    }
  })

  useEffect(() => {
    dispatch(loadingUpdate(true))
    buscarTiposLancamentoEFacil()
    buscarConfiguracaoAtiva()
  }, [])

  async function fetchSequencias() {
    const response: Sequencias[] = await TipoLancamentoManager.buscarSequencias('')
    response.push(semSequencia)

    setSequencia(response)
    dispatch(atualizarSequencias(sequencia))
    dispatch(loadingReset())
  }

  async function buscarTiposLancamentoEFacil() {
    const response: TiposLancamentosData[] = await TipoLancamentoManager.buscarTiposLancamentos()
    setTiposLancamentos(response)
    fetchSequencias()
  }

  async function buscarConfiguracaoAtiva() {
    const response: Config = await TipoLancamentoManager.buscarConfigAtiva()
    setConfigurarTipoLancamento(response)
  }

  const salvarNovoTipoLancamento = async (data: TipoLancamentoDataApi) => {
    await TipoLancamentoManager.inserirTipoLancamento(data)
      .then(() => {
        buscarTiposLancamentoEFacil()
        setModal(false)
        setTipoLancamento({ ...tipoLancamento, descricao: '', sequencia: '', conta: '', natureza: '', tipo: '', referencia: '' })
        toast.success(msgConstants.LANCAMENTO_ASSOCIACAO.sucessoCadastro)
      })
      .catch(() => {
        toast.error(msgConstants.LANCAMENTO_ASSOCIACAO.erroCadastro)
      })
  }

  async function obterHistoricoPadraoSequencia(sequenciaId: number | string) {
    await TipoLancamentoManager.obterHistoricoPadraoSequencia(sequenciaId as number)
      .then((response: HistoricoPadrao) => {
        setHistoricoPadrao(response)
      })
      .catch(() => {
        toast.error(msgConstants.TIPO_LANCAMENTO_PARCEIRO.buscar_historico_sequencia_erro)
      })
  }

  const handleFecharModal = () => {
    setModal(false)
  }

  const handleSalvarTipoLancamento = () => {
    const newTipoLancamento: TipoLancamentoDataApi = {
      descricao: tipoLancamento.descricao,
      sequenciaId: tipoLancamento.sequencia == semSequencia.sequencia ? null : Number(tipoLancamento.sequencia.split(' ')[0]),
      ativo: true,
      tipoReferenciaId: definirReferenciaId(tipoLancamento.referencia)
    }

    setModal(false)
    setValue('descricao', '')
    setValue('sequencia', '')
    setValue('conta', '')
    setValue('natureza', '')
    setValue('tipo', '')
    setValue('referencia', '')

    salvarNovoTipoLancamento(newTipoLancamento)
  }

  const handleSelecionarSequencia = (sequenciaItem: Sequencias) => {
    setValue('sequencia', sequenciaItem.sequencia ?? '')
    setValue('conta', '' + sequenciaItem.contaContabilId ?? '')
    setValue('natureza', sequenciaItem.tipoNaturezaContabil ?? '')
    setValue('tipo', sequenciaItem.tipoAgrupamentoContabil ?? '')
  }

  const definirReferenciaId = (referencia: string) => {
    if (referencia === 'Venda') return 'V'
    else if (referencia === 'Frete') return 'F'
    else if (referencia === 'Comissão') return 'C'
    else return 'O'
  }

  const definirReferencia = (referenciaId: string) => {
    if (referenciaId === 'V') return 'Venda'
    else if (referenciaId === 'F') return 'Frete'
    else if (referenciaId === 'C') return 'Comissão'
    else return 'Outro'
  }

  const submeterFormularioTipoLancamento = (data: FormularioTipoLancamento) => {
    setTipoLancamento({
      ...tipoLancamento,
      descricao: data.descricao,
      sequencia: data.sequencia,
      conta: data.conta,
      natureza: data.natureza,
      tipo: data.tipo,
      referencia: data.referencia
    })

    if (data.sequencia !== semSequencia.sequencia) obterHistoricoPadraoSequencia(parseInt(data.sequencia.split(' ')[0]))
    else {
      setHistoricoPadrao(defaultHistoricoPadrao)
    }

    setModal(true)
  }

  const alterarTipoLancamento = async (data: TipoLancamentoDataApi, lancamentoId: number | undefined) => {
    await TipoLancamentoManager.alterarTipoLancamento(data, lancamentoId)
      .then(() => {
        buscarTiposLancamentoEFacil()
        setModal(false)
        setTipoLancamento({ ...tipoLancamento, descricao: '', sequencia: '', conta: '', natureza: '', tipo: '' })
        toast.success(msgConstants.LANCAMENTO_ASSOCIACAO.sucessoEditar)
      })
      .catch(() => {
        toast.success(msgConstants.LANCAMENTO_ASSOCIACAO.erroEditar)
      })
  }

  useEffect(() => {
    if (isChange) {
      const tipoLancamentoAlterar: TipoLancamentoDataApi = {
        descricao: lancamentoObj.descricao,
        sequenciaId: estaVazio(lancamentoObj.sequencia) ? null : Number(lancamentoObj.sequencia.split(' ')[0]),
        ativo: lancamentoObj.status === 'Ativo' ? true : false,
        tipoReferenciaId: definirReferenciaId(lancamentoObj.referencia)
      }
      alterarTipoLancamento(tipoLancamentoAlterar, lancamentoObj.id)
      dispatch(desativarEdicao())
    }

    if (isDesativado) {
      const tipoLancamentoDesativar: TipoLancamentoDataApi = {
        descricao: lancamentoObj.descricao,
        sequenciaId: estaVazio(lancamentoObj.sequencia) ? null : Number(lancamentoObj.sequencia.split(' ')[0]),
        ativo: false,
        tipoReferenciaId: definirReferenciaId(lancamentoObj.referencia)
      }

      alterarTipoLancamento(tipoLancamentoDesativar, lancamentoObj.id)
      dispatch(desativarDesativacao())
    }
  }, [alterarTipoLancamento, isChange, isDesativado, lancamentoObj])

  const toggleEstadoItemTabela = (index: number) => {
    const estadosItens = [...estadosItensTabela]
    estadosItens[index] = !estadosItens[index]

    setEstadosItensTabela(estadosItens)
  }

  const tratarMudancaFiltroSelecionado = (event: React.ChangeEvent<{ name?: string; value: unknown | string }>) => {
    const { value } = event.target
    setFiltro(value)
  }

  useEffect(() => {
    const lancamentosResponse: TipoLancamento[] = []
    if (sequencia.length != 0) {
      tiposLancamentos.forEach((item: TiposLancamentosData) => {
        const adicionarSequenciaTipoLancamento: TipoLancamento = {
          conta: sequencia
            .filter((sequenciaConta) => sequenciaConta.numeroSequencia == Number(item.sequencia) && sequenciaConta.contaContabilId != '-')
            .map((selectSequencia) => selectSequencia.contaContabilId)
            .toString(),
          descricao: item.nome,
          natureza: sequencia
            .filter((sequenciaNatureza) => sequenciaNatureza.numeroSequencia == Number(item.sequencia) && sequenciaNatureza.contaContabilId != '-')
            .map((selectSequencia) => selectSequencia.tipoNaturezaContabil)
            .toString(),
          tipo: sequencia
            .filter((sequenciaTipo) => sequenciaTipo.numeroSequencia == Number(item.sequencia) && sequenciaTipo.contaContabilId != '-')
            .map((selectSequencia) => selectSequencia.tipoAgrupamentoContabil)
            .toString(),
          sequencia: sequencia
            .filter((sequenciaId) => sequenciaId.numeroSequencia == Number(item.sequencia) && sequenciaId.contaContabilId != '-')
            .map((selectSequencia) => selectSequencia.sequencia)
            .toString(),
          status: item.ativo ? 'Ativo' : 'Desativado',
          id: item.tipoLancamentosId,
          dataAlteracao: item.dataAlteracao,
          dataCadastro: item.dataCadastro,
          nomeAlteracao: item.nomeAlteracao,
          nomeCriacao: item.nomeCriacao,
          referencia: definirReferencia(item.tipoReferenciaId)
        }

        lancamentosResponse.push(adicionarSequenciaTipoLancamento)
      })

      if (lancamentosResponse.length) {
        setLancamentosAux(lancamentosResponse)
      }
    }
  }, [tiposLancamentos, sequencia])

  useEffect(() => {
    if (filtro === 'a') {
      let itens = lancamentosAux
      itens = itens.filter((filtrarLancamentoA: TipoLancamento) => filtrarLancamentoA.status == 'Ativo')
      setLancamentos(itens)
    } else if (filtro === 'd') {
      let itens = lancamentosAux
      itens = itens.filter((filtrarLancamentoD: TipoLancamento) => filtrarLancamentoD.status == 'Desativado')
      setLancamentos(itens)
    } else if (filtro === '' || filtro === 't') {
      ordenarPeloStatus()
    }
    setPagina(0)
  }, [filtro, lancamentosAux])

  useEffect(() => {
    const sequencias = sequencia
    if (lancamentosAux.length) {
      setFiltro('')
      ordenarPeloStatus()
    }
    dispatch(atualizarSequencias(sequencias))
  }, [dispatch, lancamentosAux, sequencia])

  return {
    styles: tiposDeLancamentosStyles,
    modal,
    handleFecharModal,
    handleSalvarTipoLancamento,
    tipoLancamento,
    configurarTipoLancamento,
    handleSubmit,
    control,
    errors,
    submeterFormularioTipoLancamento,
    handleSelecionarSequencia,
    filtro,
    lancamentos,
    onMudancaPagina: (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
      tratarMudancaPagina(event, newPage, setPagina)
    },
    onMudancaLinhasPorPagina: (event: React.ChangeEvent<HTMLInputElement>) => {
      tratarMudancaLinhaPorPagina(event, setPagina, setLinhasPorPagina)
    },
    onRequestSort: handleRequestSort(ordemBy, ordem, setOrdem, setOrdemBy),
    linhasPorPaginaOpcao: [5, 10, 25],
    ordem,
    pagina,
    ordemBy,
    setOrdem,
    setOrdemBy,
    linhasPorPagina,
    estadosItensTabela,
    historicoPadrao,
    setHistoricoPadrao,
    obterHistoricoPadraoSequencia,
    setEstadosItensTabela,
    tratarMudancaFiltroSelecionado,
    toggleEstadoItemTabela
  }
}

export type TiposDeLancamentosIO = ReturnType<typeof tiposDeLancamentosIO>
