import Box from '@material-ui/core/Box'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const editarItemTabelaStyles = {
  Link: styled(Link)`
    text-decoration: none;
    color: inherit;
  `,
  BoxMenu: styled(Box)`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
  `
}
