import { IOProps } from 'react-compose-io'
import { TitulosAbertos } from './componentes/TitulosAbertos/index'
import { TitulosGerados } from './componentes/TitulosGerados/index'
import { TitulosLiquidados } from './componentes/TitulosLiquidados/index'
import { ControleTitulosIO } from './ControleTitulos.IO'
export type ControleTitulosProps = {}

export function ControleTitulosView({ _io }: IOProps<ControleTitulosIO, ControleTitulosProps>) {
  return (
    <>
      {_io.dropDownTitulos == 'Em aberto' && (
        <TitulosAbertos
          controleFormulario={_io.control}
          carregarBotao={_io.carregarBotao}
          handleSubmite={_io.handleSubmit}
          setValue={_io.setValue}
          dataPeriodoAlterada={_io.dataPeriodoAlterada}
          dataComparacaoAlterada={_io.dataComparacaoAlterada}
          dataTituloComparacao={_io.dataFinalAplicada}
          dataTituloPeriodo={_io.dataInicialAplicada}
          setCarregarBotao={_io.setCarregarBotao}
          setDataTituloComparacao={_io.setDataFinalAplicada}
          setDataTituloPeriodo={_io.setDataInicialAplicada}
          gerarRelatorioTitulosAbertos={_io.gerarRelatorioAbertos}
          erroPreenchimento={_io.errors}
        />
      )}
      {_io.dropDownTitulos == 'Liquidados' && (
        <TitulosLiquidados
          controleFormulario={_io.control}
          carregarBotao={_io.carregarBotao}
          handleSubmite={_io.handleSubmit}
          setValue={_io.setValue}
          dataInicialAlteradaFormulario={_io.dataPeriodoAlterada}
          dataFinalAlteradaFormulario={_io.dataComparacaoAlterada}
          dataFinalTituloLiquidado={_io.dataFinalAplicada}
          dataInicialTituloLiquidado={_io.dataInicialAplicada}
          setCarregarBotao={_io.setCarregarBotao}
          setDataInicialTituloLiquidado={_io.setDataInicialAplicada}
          setDataFinalTituloLiquidado={_io.setDataFinalAplicada}
          gerarRelatorio={_io.gerarRelatorioLiquidados}
          erroPreenchimento={_io.errors}
        />
      )}
      {_io.dropDownTitulos == 'Gerados' && (
        <TitulosGerados
          controleFormulario={_io.control}
          carregarBotao={_io.carregarBotao}
          handleSubmite={_io.handleSubmit}
          setValue={_io.setValue}
          dataInicialTitulosGeradosAlterada={_io.dataPeriodoAlterada}
          dataFinalTitulosGeradosAlterada={_io.dataComparacaoAlterada}
          dataFinalTitulosGerados={_io.dataFinalAplicada}
          dataInicialTitulosGerados={_io.dataInicialAplicada}
          setCarregarBotao={_io.setCarregarBotao}
          setDataFinalTitulosGerados={_io.setDataFinalAplicada}
          setDataInicialTitulosGerados={_io.setDataInicialAplicada}
          gerarRelatorioTitulosGerados={_io.gerarRelatorioGerados}
          erroPreenchimento={_io.errors}
        />
      )}
    </>
  )
}
