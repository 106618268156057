import styled from 'styled-components'
import { customTheme } from '../../styles/customTheme'

export const controleComissaoStyles = {
  Container: styled.div`
    margin: 10px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: ${customTheme.colors.white01};
    padding: 24px;
    border-radius: 8px;
  `,
  ContainerLinha: styled.div`
    display: flex;
    flex-direction: row;
  `
}
