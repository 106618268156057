import React from 'react'
import { ORDEM_CRESCENTE, ORDEM_DECRESCENTE } from '../constants/utilConstants'
import { Order } from '../types/tabelaTypes'

export const handleRequestSort = (
  orderBy: string | number | symbol,
  order: Order,
  setOrder: React.Dispatch<React.SetStateAction<Order>>,
  setOrderBy: React.Dispatch<React.SetStateAction<string | number | symbol>>
) => {
  return (event: React.MouseEvent<unknown>, property: keyof any) => {
    const isAsc = orderBy === property && order === ORDEM_CRESCENTE
    setOrder(isAsc ? ORDEM_DECRESCENTE : ORDEM_CRESCENTE)
    setOrderBy(property)
  }
}

export function ordenarCabecalho(array: any[], comparator: (a: any, b: any) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [any, number])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

export const tratarMudancaPagina = (event: unknown, newPage: number, setPage: React.Dispatch<React.SetStateAction<number>>) => {
  setPage(newPage)
}

export const tratarMudancaLinhaPorPagina = (
  event: React.ChangeEvent<HTMLInputElement>,
  setPage: React.Dispatch<React.SetStateAction<number>>,
  setRowsPerPage: React.Dispatch<React.SetStateAction<number>>,
  setSearchValue?: React.Dispatch<React.SetStateAction<string>>
) => {
  if (setSearchValue) setSearchValue('')
  setPage(0)
  setRowsPerPage(parseInt(event.target.value, 10))
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

export function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return order === ORDEM_DECRESCENTE ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy)
}

export const linhaSelecionadaTipoString = (linhaErro: any, arrayLinhaErro: any[]) => arrayLinhaErro.indexOf(linhaErro) !== -1

export const handleSelecionarCheckboxTabela = (name: any, setSelecionado: React.Dispatch<React.SetStateAction<any[]>>, selecionado: any[]) => {
  const indexSelecionado = selecionado.indexOf(name)

  if (indexSelecionado === -1) {
    setSelecionado(selecionado.concat(name))
    return
  }
  if (indexSelecionado === 0) {
    setSelecionado(selecionado.slice(1))
    return
  }
  if (indexSelecionado === selecionado.length - 1) {
    setSelecionado(selecionado.slice(0, -1))
    return
  }
  if (indexSelecionado > 0) {
    setSelecionado(selecionado.slice(0, indexSelecionado).concat(selecionado.slice(indexSelecionado + 1)))
    return
  }
  setSelecionado([])
}

export const linhaTabelaClicavelNovaGuia = (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>, urlDestino: string, router: any) => {
  if (event.metaKey || event.ctrlKey) {
    const win = window.open(urlDestino, '_blank')
    win?.focus()
  } else {
    router.push(urlDestino)
  }
}
