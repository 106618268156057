import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { loadingReset, loadingUpdate } from '../../../../store/actions/loadingAction'
import { RegraAssociacao } from '../../../../types/associacaoApiTypes'
import { ItemAssociacao } from '../ItemAssociacao'
import AssociacaoManager from '../../../../services/api/associacao/associacaoManager'
import { PAGES } from '../../../../router/routes'
import { tabAssociacaoStyles } from './TabAssociacao.styles'

export function tabAssociacaoIO() {
  const dispatch = useDispatch()
  const router = useHistory()
  const { parceiroId } = useParams<{ parceiroId: string }>()
  const [regrasList, setRegrasList] = useState<RegraAssociacao[]>([])

  const buscarRegrasAssociacao = useCallback(async () => {
    dispatch(loadingUpdate(true))
    const response: RegraAssociacao[] = await AssociacaoManager.buscarRegras(parceiroId)

    setRegrasList(response)
    dispatch(loadingReset())
  }, [dispatch, parceiroId])

  useEffect(() => {
    buscarRegrasAssociacao()
  }, [buscarRegrasAssociacao])

  const handleClick = () => {
    router.push(PAGES.associacaoCadastro(parceiroId))
  }

  function linhaTabela(props: { item: any; index: number }) {
    const { item, index } = props

    return <ItemAssociacao key={index} row={item} index={index} atualizarDados={buscarRegrasAssociacao} />
  }

  return {
    styles: tabAssociacaoStyles,
    regrasList,
    handleClick,
    linhaTabela
  }
}

export type TabAssociacaoIO = ReturnType<typeof tabAssociacaoIO>
