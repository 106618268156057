import { ChangeEvent, useState } from 'react'
import { popoverFiltroClassificacoesStyles } from './PopoverFiltroClassificacoes.styles'
import { PopoverFiltroClassificacoesProps } from './PopoverFiltroClassificacoes.View'
import { CheckListItem } from '../CheckListItem'
import { ClassificacaoDebito } from '../../types/debitosTypes'

export function popoverFiltroClassificacoesIO({ handleCheckClassificacao, isClassificacaoChecked }: PopoverFiltroClassificacoesProps) {
  const [filtroClassificacoesInput, setFiltroClassificacoesInput] = useState('')
  const AGUARDANDO_APROVACAO = 'Aguardando aprovação'

  const handleChangeClassificacoesInput = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFiltroClassificacoesInput(e.target.value)
  }

  const filtrarClassificacoes = (classificacao: ClassificacaoDebito) => classificacao.name.toUpperCase().includes(filtroClassificacoesInput.toUpperCase())

  const renderParceirosFiltradas = (classificacao: ClassificacaoDebito) => (
    <CheckListItem
      key={classificacao.value}
      id={classificacao.name}
      texto={classificacao.name}
      handleCheck={() => handleCheckClassificacao(classificacao.name)}
      isChecked={isClassificacaoChecked(classificacao.name)}
      isChip
    />
  )

  const isAguardandoAprovacaoFiltrado = AGUARDANDO_APROVACAO.toUpperCase().includes(filtroClassificacoesInput.toUpperCase())

  return {
    styles: popoverFiltroClassificacoesStyles,
    handleChangeClassificacoesInput,
    filtrarClassificacoes,
    renderParceirosFiltradas,
    isAguardandoAprovacaoFiltrado,
    AGUARDANDO_APROVACAO
  }
}

export type PopoverFiltroClassificacoesIO = ReturnType<typeof popoverFiltroClassificacoesIO>
