import { Grid } from '@material-ui/core'
import { IOProps } from 'react-compose-io'
import ErroInformacao from '../../assets/icons/error_outline.svg'
import { InformacoesModalRegularizacaoErroIO } from './InformacoesModalRegularizacaoErro.IO'

export type InformacoesModalRegularizacaoErroProps = {
  mensagemErroUploadPlanilha: string
}

export function InformacoesModalRegularizacaoErroView({
  _io,
  mensagemErroUploadPlanilha
}: IOProps<InformacoesModalRegularizacaoErroIO, InformacoesModalRegularizacaoErroProps>) {
  const { IconInfo, ContainerErro, ContainerListaModal, ContainerInformacoesTexto, MensagemErro, TituloContainerErro } = _io.styles
  const quebrarParagrafoFrases = (mensagemErro: string) => {
    return mensagemErro.split('°').map((valor, indexValor) => <div key={indexValor}>{valor}</div>)
  }

  return (
    <ContainerErro>
      <Grid item xs={1} sm={1} md={1} lg={1}>
        <IconInfo src={ErroInformacao} />
      </Grid>
      <ContainerListaModal>
        <TituloContainerErro>Erro ao importar planilha</TituloContainerErro>
        <ContainerInformacoesTexto>
          <MensagemErro>{quebrarParagrafoFrases(mensagemErroUploadPlanilha)}</MensagemErro>
        </ContainerInformacoesTexto>
      </ContainerListaModal>
    </ContainerErro>
  )
}
