import { containerAvatarStyles } from './ContainerAvatar.styles'

type ContainerAvatarProps = {
  avatarSrc: string
  avatarDescricao: string
  tamanhoImagem?: number
}

export const ContainerAvatar = ({ avatarSrc, avatarDescricao, tamanhoImagem }: ContainerAvatarProps) => {
  const { ContainerLogo, DescricaoAvatar, Dividers, Avatar } = containerAvatarStyles

  return (
    <ContainerLogo>
      <Avatar src={avatarSrc} tamanhoimagem={tamanhoImagem} />
      <DescricaoAvatar>{avatarDescricao}</DescricaoAvatar>
      {!tamanhoImagem && <Dividers />}
    </ContainerLogo>
  )
}
