import { Typography, Box } from '@material-ui/core'
import styled from 'styled-components'

export const modalRegularizacaoCiclosStyles = {
  Container: styled.div`
    text-align: justify;
  `,
  Typography: styled(Typography).attrs({
    variant: 'subtitle1'
  })`
    text-align: justify;
  `,
  Box: styled(Box)`
    margin-bottom: 16px;
  `
}
