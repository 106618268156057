import { CardContent, Grid } from '@material-ui/core'
import { IOProps } from 'react-compose-io'
import { FormProvider } from 'react-hook-form'
import { CustomActionsButtons } from '../../../../components/CustomActionsButtons'
import { FormularioRegra } from '../../../../components/CustomFormControl/FormularioRegra/index'
import { CustomSimpleTable } from '../../../../components/CustomSimpleTable'
import Modal from '../../../../components/Modal/Modal'
import { PreVisualizarParametro } from '../../../../components/PreVisualizarParametro'
import { TRANSFORMACAO, TRANSFORMACAO_MODAL } from '../../../../constants/transformacao'
import { UploadPlanilha } from '../UploadPlanilha/index'
import { Container, Main, Subtitle, Upload } from './RegraTransformacaoForm.styles'
import { RegraTransformacaoFormIO } from './RegraTransformacaoForm.IO'
import { RegraTransformacao } from '../../../../types/transformacaoApiTypes'
import { Tipografia } from '../../../../components/Tipografia'

export type RegraTransformacaoFormProps = {
  regraTransformacaoEditar?: RegraTransformacao
}

export default function RegraTransformacaoFormView({ _io }: IOProps<RegraTransformacaoFormIO, RegraTransformacaoFormProps>) {
  return (
    <>
      <Main>
        <Tipografia variant="h5">{TRANSFORMACAO.titulo}</Tipografia>
        <Container>
          <CardContent>
            <Tipografia variant="h6">{TRANSFORMACAO.cartaoTitulo}</Tipografia>
            <Subtitle>{TRANSFORMACAO.cartaoSubtitulo}</Subtitle>
            <Upload>
              <UploadPlanilha setArquivoId={_io.setArquivoId} />
            </Upload>
          </CardContent>
        </Container>
        <Container>
          <CardContent>
            <Tipografia variant="h6">{_io.regraId ? TRANSFORMACAO.formularioTituloEditarRegra : TRANSFORMACAO.formularioTituloNovaRegra}</Tipografia>
            <Subtitle>{TRANSFORMACAO.formularioSubtitulo}</Subtitle>
            <FormProvider {..._io.parametrosForm}>
              <form onSubmit={_io.parametrosForm.handleSubmit(_io.enviarDadosFormulario)}>
                <FormularioRegra
                  isEdicao={_io.regraId ? true : false}
                  mudarStatusParaAtivo={_io.mudarStatusParaAtivo}
                  ativo={_io.switchAtivo}
                  condicaoLista={_io.condicaoList}
                  acaoLista={_io.acaoList}
                  grupoRegraLista={_io.grupoRegraList}
                  cabecalhosPlanilha={_io.fieldDado}
                />
                <CustomActionsButtons
                  hasPreview
                  hasCancel
                  hasSave
                  hasSaveContinue={!_io.regraId}
                  handlePreview={_io.handlePreview}
                  handleCancelar={_io.handleCancelar}
                  handleSave={() => _io.setSave(false)}
                  handleSaveContinue={() => _io.setSave(true)}
                />
              </form>
            </FormProvider>
          </CardContent>
        </Container>
        <Modal
          title={TRANSFORMACAO_MODAL.titulo}
          openModal={_io.openModal}
          fullWidth={false}
          maxWidth={false}
          onClose={_io.handleCloseModal}
          onAction={_io.handleCloseModal}
          hiddenButton
        >
          <Grid item sm={12}>
            <CustomSimpleTable cabecalhos={['Identificador Pedido', 'Loja', 'Tipo Lançamento', 'Lançamento', 'Valor', 'Parcela']}>
              {_io.preVisualizar != undefined ? <PreVisualizarParametro row={_io.preVisualizar} /> : null}
            </CustomSimpleTable>
          </Grid>
        </Modal>
      </Main>
    </>
  )
}
