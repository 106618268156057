import {
  ResponseDebitos,
  DebitosPerdasEfetivados,
  ClassificacaoDebito,
  AtualizarPedidoDebitoRequest,
  ObterPedidosDebitosQuery,
  ObterPedidosDebitosResponse,
  ResponsePedidosDebitoDetalhado,
  EfetivarDebitoPerdaRequest
} from '../../../types/debitosTypes'
import { PossiveisTratativas, ResumoPedidosPerdasClassificacao } from '../../../types/perdasTypes'
import controleDebitos from './debitosService'

const buscarControleDebitos = async (): Promise<ResponseDebitos> => controleDebitos.buscarControleDebitos()

const gerarDadosControleDebitos = async (): Promise<boolean> => controleDebitos.gerarDadosControleDebitos()

const buscarHistoricoDebitosEfetivados = async (): Promise<DebitosPerdasEfetivados[]> => controleDebitos.buscarHistoricoDebitosEfetivados()

const buscarClassificacoesDebitos = async (): Promise<ClassificacaoDebito[]> => controleDebitos.buscarClassificacoesDebitos()

const atualizarClassificacaoPedidoDebito = async (body: AtualizarPedidoDebitoRequest[]): Promise<boolean> =>
  controleDebitos.atualizarClassificacaoPedidoDebito(body)

const buscarPedidosDebitos = async (query: ObterPedidosDebitosQuery): Promise<ResponsePedidosDebitoDetalhado<ObterPedidosDebitosResponse>> =>
  controleDebitos.buscarPedidosDebitos(query)

const buscarPossiveisTratativasDebitos = async (): Promise<PossiveisTratativas> => controleDebitos.buscarPossiveisTratativasDebitos()

const buscarResumoPedidosClassificadosDebitos = async (): Promise<ResumoPedidosPerdasClassificacao[]> =>
  controleDebitos.buscarResumoPedidosClassificadosDebitos()

const efetivarDebitoPerda = async (body: EfetivarDebitoPerdaRequest): Promise<boolean> => controleDebitos.efetivarDebitoPerda(body)

export default {
  buscarControleDebitos,
  gerarDadosControleDebitos,
  buscarHistoricoDebitosEfetivados,
  buscarClassificacoesDebitos,
  atualizarClassificacaoPedidoDebito,
  buscarPedidosDebitos,
  buscarPossiveisTratativasDebitos,
  buscarResumoPedidosClassificadosDebitos,
  efetivarDebitoPerda
}
