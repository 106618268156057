import { Sequencias, TipoLancamento } from '../../types/tipoLancamentoApiTypes'
import {
  DESATIVAR_LANCAMENTO,
  DESATIVAR_LANCAMENTO_EFETIVADO,
  LANCAMENTO_UPDATE,
  LANCAMENTO_UPDATE_DISABLE,
  LANCAMENTO_UPDATE_TRIGGER,
  MODAL_DESATIVAR_OPEN,
  MODAL_OPEN,
  UPDATE_SEQUENCIAS
} from '../actions/lancamentoAction'

interface Modal {
  open: boolean
}

interface Change {
  isChange: boolean
}

interface Desativar {
  isDesativado: boolean
}

interface SequenciaRedux {
  sequencias: Sequencias[]
}

interface Action {
  type: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: any
}

export interface TipoLancamentoRedux {
  lancamentoObj: TipoLancamento
  modalEditar: Modal
  modalDesativar: Modal
  change: Change
  desativar: Desativar
  sequencia: SequenciaRedux
}

const initialState: TipoLancamentoRedux = {
  lancamentoObj: {
    id: undefined,
    descricao: '',
    sequencia: '',
    conta: '',
    natureza: '',
    tipo: '',
    status: '',
    dataAlteracao: null,
    dataCadastro: null,
    nomeCriacao: '',
    nomeAlteracao: '',
    referencia: ''
  },
  modalEditar: {
    open: false
  },
  modalDesativar: {
    open: false
  },
  change: {
    isChange: false
  },
  desativar: {
    isDesativado: false
  },
  sequencia: {
    sequencias: []
  }
}

const lancamentoReducer = (state: TipoLancamentoRedux = initialState, action: Action) => {
  const actualState = state
  switch (action.type) {
    case LANCAMENTO_UPDATE:
      Object.assign(actualState.lancamentoObj, action.payload)
      return { ...state, ...actualState }
    case MODAL_OPEN:
      actualState.modalEditar.open = action.payload
      return { ...state, ...actualState }
    case MODAL_DESATIVAR_OPEN:
      actualState.modalDesativar.open = action.payload
      return { ...state, ...actualState }
    case LANCAMENTO_UPDATE_TRIGGER:
      actualState.change.isChange = true
      return { ...state, ...actualState }
    case LANCAMENTO_UPDATE_DISABLE:
      actualState.change.isChange = false
      return { ...state, ...actualState }
    case DESATIVAR_LANCAMENTO:
      actualState.desativar.isDesativado = true
      return { ...state, ...actualState }
    case DESATIVAR_LANCAMENTO_EFETIVADO:
      actualState.desativar.isDesativado = false
      return { ...state, ...actualState }
    case UPDATE_SEQUENCIAS:
      actualState.sequencia.sequencias = action.payload
      return { ...state, ...actualState }
    default:
      return state
  }
}

export default lancamentoReducer
