import { ResumoPedidosPerdasClassificacao, ClassificacoesMensais, DadosClassificacoesMensais } from '../types/perdasTypes'
import { DadosMeses } from './tratarDadosMesesUtils'

interface ClassificacaoPorMes {
  classificacao: string
  quantidade: number
  valor: number
  mes: number
  ano: number
}

export interface DadosGraficoDonut {
  valor: number[]
  classificacoes: string[]
  quantidade: number[]
}

export function unirParceirosPedidosClassificados(pedidos: ResumoPedidosPerdasClassificacao[]): ClassificacoesMensais[] {
  let classificacoesMensais = pedidos?.reduce(
    (acc: ClassificacoesMensais[], parceiroAtual: ResumoPedidosPerdasClassificacao) => [...acc, ...parceiroAtual.classificacoesMensais],
    []
  )

  classificacoesMensais = unirClassificacoesMensaisPorMes(classificacoesMensais)

  return classificacoesMensais
}

function unirClassificacoesMensaisPorMes(classificacoesMensais: ClassificacoesMensais[]): ClassificacoesMensais[] {
  return classificacoesMensais.reduce((acumulador, classificacoesDoMes) => {
    const { ano, mes, dados } = classificacoesDoMes

    const mesJaAgrupadoIndex = acumulador.findIndex((resultado) => resultado.ano === ano && resultado.mes === mes)

    if (mesJaAgrupadoIndex != -1) {
      acumulador[mesJaAgrupadoIndex] = somarQuantidadeValorAoMes(dados, acumulador[mesJaAgrupadoIndex])
    } else {
      const novoMes = criarNovoMesAoAgrupamento(ano, mes, dados)
      acumulador.push(novoMes)
    }

    return acumulador
  }, [] as ClassificacoesMensais[])
}

function somarQuantidadeValorAoMes(classificacoesNoMes: DadosClassificacoesMensais[], mesExistenteNoAgrupamento: ClassificacoesMensais): ClassificacoesMensais {
  return classificacoesNoMes.reduce((acumulador, classificacaoMes) => {
    const { quantidade, valor, classificacao } = classificacaoMes
    const classificacaoExistenteNoMes = acumulador.dados.find((dadoClassificacaoMensal) => dadoClassificacaoMensal.classificacao === classificacao)

    if (classificacaoExistenteNoMes) {
      classificacaoExistenteNoMes.quantidade += quantidade
      classificacaoExistenteNoMes.valor += valor
    } else {
      acumulador.dados.push({ quantidade, valor, classificacao })
    }

    return acumulador
  }, mesExistenteNoAgrupamento)
}

function criarNovoMesAoAgrupamento(ano: number, mes: number, dados: DadosClassificacoesMensais[]) {
  const novoMes: ClassificacoesMensais = {
    ano,
    mes,
    dados: []
  }

  const dadosMesAdicionado = dados.reduce((acumulador, dado) => {
    const { quantidade, valor, classificacao } = dado
    acumulador.push({ quantidade, valor, classificacao })
    return acumulador
  }, [] as DadosClassificacoesMensais[])

  novoMes.dados = dadosMesAdicionado

  return novoMes
}

export function filtrarDadosSemClassificacao(classificacoesMensais: ClassificacoesMensais[]): DadosMeses[] {
  return classificacoesMensais.reduce((acumulador, item) => {
    const { ano, mes, dados } = item

    const dadosSemClassificacao = dados.filter((dado) => dado.classificacao === 'Sem Classificacao')

    if (dadosSemClassificacao.length > 0) {
      const quantidadeTotal = somarQuantidadeTotal(dadosSemClassificacao)
      const valorTotal = somarValorTotal(dadosSemClassificacao)

      acumulador.push({
        mes,
        ano,
        quantidade: quantidadeTotal,
        valor: valorTotal
      })
    }

    return acumulador
  }, [] as DadosMeses[])
}

export function filtrarDadosClassificados(classificacoesMensais: ClassificacoesMensais[]): DadosMeses[] {
  return classificacoesMensais.reduce((acumulador, item) => {
    const { ano, mes, dados } = item

    const dadosComClassificacao = dados.filter((dado) => dado.classificacao !== 'Sem Classificacao')

    if (dadosComClassificacao.length > 0) {
      const quantidadeSomada = somarQuantidadeTotal(dadosComClassificacao)
      const valorSomado = somarValorTotal(dadosComClassificacao)

      acumulador.push({
        mes,
        ano,
        quantidade: quantidadeSomada,
        valor: valorSomado
      })
    }

    return acumulador
  }, [] as DadosMeses[])
}

function somarValorTotal(dadosSemClassificacao: DadosClassificacoesMensais[]) {
  return dadosSemClassificacao.reduce((total, dado) => total + dado.valor, 0)
}

function somarQuantidadeTotal(dadosSemClassificacao: DadosClassificacoesMensais[]) {
  return dadosSemClassificacao.reduce((total, dado) => total + dado.quantidade, 0)
}

export function agruparClassificacaoPorMes(dados: ClassificacoesMensais[]): ClassificacaoPorMes[] {
  const listaFiltrada: ClassificacaoPorMes[] = []

  for (const item of dados) {
    const { ano, mes, dados: classificacoes } = item

    for (const classificacao of classificacoes) {
      const { valor, quantidade, classificacao: classificacaoAtual } = classificacao

      const objetoExistente = listaFiltrada.find((objeto) => objeto.classificacao === classificacaoAtual && objeto.mes === mes && objeto.ano === ano)

      if (objetoExistente) {
        objetoExistente.quantidade += quantidade
        objetoExistente.valor += valor
      } else {
        if (classificacaoAtual !== 'Sem Classificacao')
          listaFiltrada.push({
            classificacao: classificacaoAtual,
            quantidade,
            valor,
            mes,
            ano
          })
      }
    }
  }

  return listaFiltrada
}

function obterUltimosMeses(quantidadeDeMesesAnteriores: number): number[] {
  const dataAtual = new Date()
  const meses = []

  for (let i = quantidadeDeMesesAnteriores - 1; i >= 0; i--) {
    const mes = dataAtual.getMonth() - i
    const ano = dataAtual.getFullYear()
    meses.push((mes < 0 ? 12 + mes : mes) + 1 + ano * 100)
  }

  return meses
}

export function gerarDadosGraficoDonuts(
  classificacoesAgrupadasPorMes: ClassificacaoPorMes[],
  quantidadeDeMesesAnterioresSelecionados: number,
  indexMesSelecionado?: number
): DadosGraficoDonut {
  let ultimosMeses = obterUltimosMeses(quantidadeDeMesesAnterioresSelecionados)

  if (indexMesSelecionado != undefined) ultimosMeses = [ultimosMeses[indexMesSelecionado]]

  const resultado: DadosGraficoDonut = {
    valor: [],
    classificacoes: [],
    quantidade: []
  }

  const classificacoes: { [key: string]: { valor: number; quantidade: number } } = {}

  for (const classificacoesDoMes of classificacoesAgrupadasPorMes) {
    const { classificacao, quantidade, valor, mes, ano } = classificacoesDoMes
    const mesAno = mes + ano * 100

    if (ultimosMeses.includes(mesAno)) {
      if (!classificacoes[classificacao]) {
        classificacoes[classificacao] = { valor: 0, quantidade: 0 }
      }

      classificacoes[classificacao].valor += valor
      classificacoes[classificacao].quantidade += quantidade

      if (!resultado.classificacoes.includes(classificacao)) {
        resultado.classificacoes.push(classificacao)
      }
    }
  }

  for (const classificacao of resultado.classificacoes) {
    resultado.valor.push(classificacoes[classificacao].valor)
    resultado.quantidade.push(classificacoes[classificacao].quantidade)
  }

  return resultado
}

export function transformarQuantidadeEmPorcentagem(quantidades: number[]): number[] {
  const quantidadeTotalClassificados = quantidades.reduce((total, quantidade) => total + quantidade, 0)

  quantidades = quantidades.map((quantidade) => (quantidade * 100) / quantidadeTotalClassificados)

  return quantidades
}
