import { Box, Stepper, Typography, StepLabel } from '@material-ui/core'
import styled from 'styled-components'
import { CheckCircleOutline, HighlightOff } from '@material-ui/icons'
import { customTheme } from '../../../../styles/customTheme'

export const historicoChamadoStyles = {
  BoxStatus: styled(Box)`
    padding: 3px 0px;
    border-radius: 4px;
    text-align: -webkit-center;
    margin-left: 30px;
    background-color: ${(props: { background: string; color: string }) => props.background};
    color: ${(props: { background: string; color: string }) => props.color};
  `,
  Stepper: styled(Stepper).attrs({
    orientation: 'vertical'
  })`
    padding: 10px 0px;
  `,
  EdicaoIcon: styled.img`
    margin: 0px 4px;

    :hover {
      cursor: pointer;
      opacity: 0.75;
    }
  `,
  EdicaoDesabilitadoIcon: styled.img`
    margin: 0px 4px;
  `,
  CheckCircleOutline: styled(CheckCircleOutline).attrs({
    color: 'primary'
  })`
    margin: 0px 2px;
    color: ${customTheme.colors.azul06};
    :hover {
      cursor: pointer;
      opacity: 0.75;
    }
  `,
  HighlightOff: styled(HighlightOff)`
    color: ${customTheme.colors.grey02};
    margin: 0px 2px;

    :hover {
      cursor: pointer;
      opacity: 0.75;
    }
  `,
  HistoricoLabel: styled(Typography).attrs({
    variant: 'body2'
  })`
    color: ${customTheme.colors.grey03};
  `,
  TypographyChamado: styled.div`
    text-align: center;
    display: flex;
  `,
  StepLabel: styled(StepLabel)`
    .MuiStepLabel-iconContainer {
      color: ${customTheme.colors.grey02};
    }

    &:not(.MuiStepLabel-root.Mui-disabled) {
      .MuiStepLabel-iconContainer {
        color: ${customTheme.colors.azul06};
      }
    }
  `
}
