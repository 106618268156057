import { CollapseInadimplenciaItemIO } from './CollapseInadimplenciaItem.IO'
import { IOProps } from 'react-compose-io'
import { Box, Collapse, Table, TableBody, TableCell, TableRow } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'
import { Tipografia } from './../../../../components/Tipografia/index'
import { customTheme } from '../../../../styles/customTheme'
import { InadimplenciasAgrupadas } from '../../../../types/controleInadimplenciaTypes'
import { formateCurrency } from '../../../../utils/stringUtils'
import { Dispatch, SetStateAction } from 'react'

export type CollapseInadimplenciaItemProps = {
  inadimplenciasPorAno: InadimplenciasAgrupadas
  setControleCollapse: Dispatch<SetStateAction<number[]>>
  controleCollapse: number[]
}

export function CollapseInadimplenciaItemView({ _io, inadimplenciasPorAno }: IOProps<CollapseInadimplenciaItemIO, CollapseInadimplenciaItemProps>) {
  const { TableCellIcon, TableCellDetalhes, IconButton } = _io.styles

  return (
    <>
      <TableRow>
        <TableCellIcon align="left">
          <IconButton onClick={() => _io.handleToogleDetalhes()}>{!_io.isDetalhesOpen ? <AddIcon /> : <RemoveIcon />}</IconButton>
          <Tipografia colorText={customTheme.colors.grey21} fontWeight="500">
            {inadimplenciasPorAno.ano}
          </Tipografia>
        </TableCellIcon>
        <TableCell align="right">
          <Tipografia colorText={customTheme.colors.grey21} fontWeight="500">
            {formateCurrency(inadimplenciasPorAno.valorTotal)}
          </Tipografia>
          <Tipografia colorText={customTheme.colors.orange04} fontWeight="400" variant="body2">
            {`${inadimplenciasPorAno.quantidadeTotal.toLocaleString('pt-BR')} títulos`}
          </Tipografia>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCellDetalhes colSpan={2}>
          <Collapse in={_io.isDetalhesOpen} timeout="auto" unmountOnExit>
            <Box>
              <Table size="small">
                <TableBody>
                  {inadimplenciasPorAno.dadosMeses
                    .filter((x) => x.quantidadeTotal != 0)
                    .map((inadimplenciasMensal) => (
                      <TableRow key={inadimplenciasMensal.mes}>
                        <TableCellDetalhes align="right">{inadimplenciasMensal.mes}</TableCellDetalhes>
                        <TableCellDetalhes align="right">
                          <Tipografia colorText={customTheme.colors.grey21} fontWeight="500">
                            {formateCurrency(inadimplenciasMensal.valorTotal)}
                          </Tipografia>
                          <Tipografia colorText={customTheme.colors.grey22} fontWeight="400" variant="body2">
                            {`${inadimplenciasMensal.quantidadeTotal.toLocaleString('pt-BR')} títulos`}
                          </Tipografia>
                        </TableCellDetalhes>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCellDetalhes>
      </TableRow>
    </>
  )
}
