import { TableRow } from '@material-ui/core'
import { IOProps } from 'react-compose-io'
import { Tabela } from '../../../../components/Tabela'
import { DetalhesPedidoInternalizado, ResumoPedidosInternalizacao } from '../../../../types/pedidosTypes'
import { cabecalhoPedidoInternalizacao } from '../../../../types/tabelaTypes'
import { formateCurrency, formateDate, validarTexto } from '../../../../utils/stringUtils'
import { TabelaInternalizacaoIO } from './TabelaInternalizacao.IO'

export type TabelaInternalizacaoProps = {
  pedidoInternalizado?: ResumoPedidosInternalizacao | null
}

export function TabelaInternalizacaoView({ _io, pedidoInternalizado }: IOProps<TabelaInternalizacaoIO, TabelaInternalizacaoProps>) {
  const { ContainerTable, TableCell } = _io.styles
  function linhaTabela(props: { item: DetalhesPedidoInternalizado }) {
    const { item } = props

    return (
      <TableRow>
        <TableCell corletra={item.statusLancamento}>{validarTexto('' + item.pedidoId, '-')}</TableCell>
        <TableCell corletra={item.statusLancamento}>{item.lancamentoParceiro}</TableCell>
        <TableCell corletra={item.statusLancamento}>{validarTexto(item.novoTipoLancamentoParceiro, '-')}</TableCell>
        <TableCell corletra={item.statusLancamento}>{`#${item.cicloId}`}</TableCell>
        <TableCell corletra={item.statusLancamento}>{formateDate(item.dataProcessamento)}</TableCell>
        <TableCell corletra={item.statusLancamento}>{formateCurrency(item.valor)}</TableCell>
        <TableCell corletra={item.statusLancamento}>{item.parcela}</TableCell>
      </TableRow>
    )
  }

  return (
    <>
      {pedidoInternalizado && (
        <ContainerTable>
          <Tabela
            cabecalho={cabecalhoPedidoInternalizacao}
            itensLista={pedidoInternalizado?.detalhes}
            LinhaTabela={linhaTabela}
            orderCampoPadrao={'pedidoId'}
            rowsPerPageOptions={[5, 10, 25]}
            linhasPorPaginaPadrao={5}
            maxHeight={'none'}
            alinharTextoCentro={true}
          />
        </ContainerTable>
      )}
    </>
  )
}
