import { useForm } from 'react-hook-form'
import { modalClassificarPedidosStyles } from './ModalClassificarPedidos.styles'
import { ModalClassificarPedidosProps } from './ModalClassificarPedidos.View'
import { useEffect, useState } from 'react'

export function modalClassificarPedidosIO({ isClosed, handleConfirmar, classificacaoSugerida, isShow }: ModalClassificarPedidosProps) {
  const [confirmarDesabilitado, setConfirmarDesabilitado] = useState(false)
  const { handleSubmit, control, setValue, register, watch } = useForm<{ observacao: string; classificacao: number }>({
    defaultValues: {
      observacao: '',
      classificacao: 0
    }
  })

  const fecharModal = () => {
    isClosed()
    setValue('observacao', '')
    setValue('classificacao', 0)
  }

  const observacaoPreenchida = watch('observacao')
  const classificacaoPreenchida = watch('classificacao')

  useEffect(() => {
    if (observacaoPreenchida || classificacaoPreenchida != 0) {
      setConfirmarDesabilitado(false)
      return
    }
    setConfirmarDesabilitado(true)
  }, [observacaoPreenchida, classificacaoPreenchida])

  useEffect(() => {
    if (classificacaoSugerida && isShow) {
      setValue('classificacao', classificacaoSugerida)
    }
  }, [isShow])

  const submit = (data: { observacao: string; classificacao: number }) => {
    handleConfirmar(data)
    fecharModal()
  }

  return {
    styles: modalClassificarPedidosStyles,
    control,
    handleSubmit,
    fecharModal,
    submit,
    setValue,
    register,
    confirmarDesabilitado,
    observacaoPreenchida
  }
}

export type ModalClassificarPedidosIO = ReturnType<typeof modalClassificarPedidosIO>
