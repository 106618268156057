import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import msgConstants from '../../../../constants/msgConstants'
import configuracoesManager from '../../../../services/api/configuracoes/configuracoesManager'
import { loadingReset, loadingUpdate } from '../../../../store/actions/loadingAction'
import { Evento, Fato, ParametroContabel, Sistemas, UnidadeNegocio } from '../../../../types/configuracoesApiType'
import { modalConfiguracoesGeraisStyles } from './ModalConfiguracoesGerais.style'
import { ModalConfiguracoesGeraisProps } from './ModalConfiguracoesGerais.View'

export function modalConfiguracoesGeraisIO({ handleSalvarModal }: ModalConfiguracoesGeraisProps) {
  const dispatch = useDispatch()
  const { register, handleSubmit, watch, setValue } = useForm<{
    SiglaSistema: string
    UnidadeNegocioId: number
    EventoId: number
    FatoId: number
  }>()

  const { SiglaSistema, UnidadeNegocioId, EventoId, FatoId } = watch()

  const [siglaSistemaLista, setSiglaSistemaLista] = React.useState<Sistemas[]>([])
  const [unidadeLista, setUnidadeLista] = React.useState<UnidadeNegocio[]>([])
  const [eventoLista, setEventoLista] = React.useState<Evento[]>([])
  const [fatoLista, setFatoLista] = React.useState<Fato[]>([])

  useEffect(() => {
    register('SiglaSistema')
    register('UnidadeNegocioId')
    register('EventoId')
    register('FatoId')

    async function buscarListaSiglas() {
      const response: Sistemas[] = await configuracoesManager.buscarSistemas()
      setSiglaSistemaLista(response)
      dispatch(loadingReset())
    }
    dispatch(loadingUpdate(true))
    buscarListaSiglas()
  }, [])

  useEffect(() => {
    async function buscarListaUnidades() {
      const response: UnidadeNegocio[] = await configuracoesManager.buscarUnidadesNegocios(SiglaSistema)
      setUnidadeLista(response)
      dispatch(loadingReset())
    }
    if (SiglaSistema != undefined) {
      dispatch(loadingUpdate(true))
      buscarListaUnidades()
    }
  }, [SiglaSistema])

  useEffect(() => {
    async function buscarListaUnidades() {
      const response: Evento[] = await configuracoesManager.buscarEventos(SiglaSistema, UnidadeNegocioId)
      setEventoLista(response)
      dispatch(loadingReset())
    }
    if (SiglaSistema != undefined && UnidadeNegocioId != undefined) {
      dispatch(loadingUpdate(true))
      buscarListaUnidades()
    }
  }, [UnidadeNegocioId])

  useEffect(() => {
    async function buscarListaUnidades() {
      const response: Fato[] = await configuracoesManager.buscarFatos(SiglaSistema, UnidadeNegocioId, EventoId)
      setFatoLista(response)
      dispatch(loadingReset())
    }
    if (SiglaSistema != undefined && UnidadeNegocioId != undefined && EventoId != undefined) {
      dispatch(loadingUpdate(true))
      buscarListaUnidades()
    }
  }, [EventoId])

  const cadastrarNovaConfiguracao = async (data: ParametroContabel) => {
    dispatch(loadingUpdate(true))
    await configuracoesManager
      .cadastrarConfiguracoes(data)
      .then(() => {
        dispatch(loadingReset())
        toast.success(msgConstants.USO_GENERICO.cadastro_sucesso)
        handleSalvarModal()
      })
      .catch((error: Error) => {
        dispatch(loadingReset())
        toast.error(error.message)
      })
  }

  return {
    styles: modalConfiguracoesGeraisStyles,
    register,
    handleSubmit,
    FatoId,
    fatoLista,
    siglaSistemaLista,
    unidadeLista,
    eventoLista,
    setValue,
    SiglaSistema,
    UnidadeNegocioId,
    EventoId,
    cadastrarNovaConfiguracao
  }
}
export type ModalConfiguracoesGeraisIO = ReturnType<typeof modalConfiguracoesGeraisIO>
