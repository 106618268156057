import { Grid, InputLabel, MenuItem, Select } from '@material-ui/core'
import { IOProps } from 'react-compose-io'
import { Controller } from 'react-hook-form'
import { TRANSFORMACAO_INFO, TRANSFORMACAO_LABELS_ACAO } from '../../../constants/transformacao'
import { FormFieldsProps } from '../../../types/regraApiType'
import { TooltipComponent } from '../../Tooltip/Tooltip'
import { FieldsSeparadorIO } from './FieldsSeparador.IO'

export type FieldsSeparadorProps = {
  indexItensRegraForm: number
  itensCampo: FormFieldsProps[]
  cabecalhos: string[]
}

const FieldsSeparadorView = ({ _io, indexItensRegraForm, itensCampo, cabecalhos }: IOProps<FieldsSeparadorIO, FieldsSeparadorProps>) => {
  const { FormControl, FormGroup } = _io.styles

  return (
    <>
      {_io.acaoSelecionada[indexItensRegraForm]?.operacaoId === 'SeparadorOperacao' && (
        <>
          <Grid item xs={12} sm={12} md={3}>
            <Controller
              render={({ field }) => (
                <FormControl>
                  <InputLabel>{TRANSFORMACAO_LABELS_ACAO.dado}</InputLabel>
                  <Select {...field} label={TRANSFORMACAO_LABELS_ACAO.dado}>
                    {cabecalhos.map((cabecalho: string, cabecalhoIndex: number) => (
                      <MenuItem key={cabecalhoIndex} value={cabecalho}>
                        {cabecalho}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
              name={`acoes.${indexItensRegraForm}.dado` as `acoes.0.dado`}
              control={_io.control}
            />
          </Grid>
          <Grid item sm={12} md={2}>
            <FormGroup>
              <Controller
                render={({ field }) => (
                  <FormControl>
                    <InputLabel>Operador</InputLabel>
                    <Select {...field} label="Operador">
                      {itensCampo
                        .filter((item) => item.operacaoId === _io.acaoSelecionada[indexItensRegraForm]?.operacaoId)[0]
                        ?.operadores.map((operadores: string, operadoresIndex: React.Key) => (
                          <MenuItem key={operadoresIndex} value={operadores}>
                            {operadores}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                )}
                name={`acoes.${indexItensRegraForm}.operador` as `acoes.0.operador`}
                control={_io.control}
              />
              <TooltipComponent texto={TRANSFORMACAO_INFO.acao.separador} />
            </FormGroup>
          </Grid>
        </>
      )}
    </>
  )
}

export { FieldsSeparadorView }
