import styled from 'styled-components'

export const adicionarChamadoFormStyles = {
  Container: styled.div`
    padding-bottom: 10px;
  `,
  ContainerButtons: styled.div`
    display: flex;
    justify-content: end;
    gap: 12px;
    padding: 10px 0px;
  `
}
