import { ContaContabil, RelatorioConciliacaoFiltros } from '../../../types/relatorioConciliacaoTypes'
import RelatorioConciliacao from './relatorioConciliacaoService'

const exportarRelatorioConciliacao = async (filtros?: RelatorioConciliacaoFiltros, arquivo?: FormData | null): Promise<string> =>
  RelatorioConciliacao.exportarRelatorioConciliacao(filtros, arquivo)

const buscarContaContabil = async (): Promise<ContaContabil[]> => RelatorioConciliacao.buscarContaContabil()

export default {
  exportarRelatorioConciliacao,
  buscarContaContabil
}
