import { Avatar, Select, FormControl } from '@material-ui/core'
import styled from 'styled-components'

export const modalFiltroParceiroStyles = {
  Avatar: styled(Avatar)`
    .MuiAvatar-img {
      height: 40px;
      width: 40px;
      object-position: top;
      object-fit: none;
    }
  `,
  MenuItemContainer: styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
  `,
  Select: styled(Select)`
    height: 61px;
    width: 390px;
  `,
  FormControl: styled(FormControl)`
    margin-top: 12px;
  `
}
