import { cabecalhoInadimplenciaStyles } from './CabecalhoInadimplencia.style'
import { CabecalhoInadimplenciaProps } from './CabecalhoInadimplencia.View'
import { useCallback, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import msgConstants from '../../../../constants/msgConstants'
import { Parceiro } from '../../../../types/parceiroApiTypes'
import parceiroManager from '../../../../services/api/parceiro/parceiroManager'
import { useForm } from 'react-hook-form'

export function cabecalhoInadimplenciaIO({ handleClickParceiro, setMesesSelecionado }: CabecalhoInadimplenciaProps) {
  const [abrirModalFiltroParceiros, setAbrirModalFiltroParceiros] = useState(false)
  const [parceiros, setParceiros] = useState<Parceiro[]>([])

  const { control, watch } = useForm<{ meses: 3 | 6 }>({
    defaultValues: {
      meses: 6
    }
  })

  useEffect(() => {
    obterParceirosAtivos()
  }, [])

  const obterParceirosAtivos = useCallback(async () => {
    await parceiroManager
      .buscarDadosParceiros(true)
      .then((response: Parceiro[]) => {
        setParceiros(response)
      })
      .catch(() => {
        toast.error(msgConstants.USO_GENERICO.erroBusca)
      })
  }, [])

  function handleFecharChip() {
    handleClickParceiro(0)
  }

  const dropDownMes = watch('meses')

  useEffect(() => {
    setMesesSelecionado(dropDownMes)
  }, [dropDownMes])

  return {
    styles: cabecalhoInadimplenciaStyles,
    abrirModalFiltroParceiros,
    setAbrirModalFiltroParceiros,
    parceiros,
    handleFecharChip,
    control
  }
}

export type CabecalhoInadimplenciaIO = ReturnType<typeof cabecalhoInadimplenciaIO>
