import { Typography } from '@material-ui/core'
import styled from 'styled-components'
import { ContainerSombra } from '../../components/ContainerSombra'

export const tiposDeLancamentosStyles = {
  ContainerBranco: styled(ContainerSombra)`
    padding: 24px;
  `,
  Subtitulo: styled(Typography)`
    margin-top: 8px;
    margin-bottom: 24px;
  `,
  ContainerPaper: styled(ContainerSombra)`
    margin-top: 24px;
    padding: 24px;
  `
}
