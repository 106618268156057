import { Acao, Condicao } from '../../../types/regraApiType'
import {
  GrupoRegra,
  GrupoRegraCadastro,
  PreviewItemTransformacao,
  PriorizacaoRegras,
  RegraTransformacao,
  RegraTransformacaoForm
} from '../../../types/transformacaoApiTypes'
import instance from '../../axiosInstance'

const buscarCondicoes = async (): Promise<Condicao[]> => {
  const URL_BUSCAR_CONDICOES = '/Transformacao/Regras/Operacoes/Condicoes'
  const response = await instance.conectarGet<Condicao[]>(URL_BUSCAR_CONDICOES)
  return response.data
}

const buscarAcoes = async (): Promise<Acao[]> => {
  const URL_BUSCAR_ACOES = '/Transformacao/Regras/Operacoes/Acoes'
  const response = await instance.conectarGet<Acao[]>(URL_BUSCAR_ACOES)
  return response.data
}

const cadastrarRegra = async (body: RegraTransformacaoForm, parceiroId?: string | number): Promise<string> => {
  const URL_CADASTRAR_REGRA = `/Transformacao/Parceiros/${parceiroId}/Regras`
  const response = await instance.conectarPost<string>(URL_CADASTRAR_REGRA, body)
  return response.data
}

const editarRegra = async (body: RegraTransformacaoForm, parceiroId?: string | number, regraId?: string | number): Promise<string> => {
  const URL_EDITAR_REGRA = `/Transformacao/Parceiros/${parceiroId}/Regras/${regraId}`
  const response = await instance.conectarPut<string>(URL_EDITAR_REGRA, body)
  return response.data
}

const aplicarTransformacao = async (parceiroId: number, arquivoId: string): Promise<string> => {
  const URL_APLICAR_TRANSFORMACAO = `/Transformacao/Parceiros/${parceiroId}/Arquivo/${arquivoId}/Aplicar`
  const response = await instance.conectarGet<string>(URL_APLICAR_TRANSFORMACAO)
  return response.data
}

const buscarRegras = async (parceiroId?: string | number): Promise<RegraTransformacao[]> => {
  const URL_BUSCAR_REGRAS = `/Transformacao/Parceiros/${parceiroId}/Regras`
  const response = await instance.conectarGet<RegraTransformacao[]>(URL_BUSCAR_REGRAS)
  return response.data
}

const buscarRegra = async (parceiroId?: string | number, regraId?: string | number): Promise<RegraTransformacao> => {
  const URL_BUSCAR_REGRA = `/Transformacao/Parceiros/${parceiroId}/Regras/${regraId}`
  const response = await instance.conectarGet<RegraTransformacao>(URL_BUSCAR_REGRA)
  return response.data
}

const enviarArquivo = async (body: string[]): Promise<string> => {
  const URL_ENVIAR_ARQUIVO = `/Arquivo`
  const response = await instance.conectarPost<string>(URL_ENVIAR_ARQUIVO, body)
  return response.data
}

const previewRegra = async (body: RegraTransformacaoForm, arquivoId?: string | number): Promise<PreviewItemTransformacao> => {
  const URL_BUSCAR_PREVIEW = `/Transformacao/Arquivos/${arquivoId}/Preview`
  const response = await instance.conectarPost<PreviewItemTransformacao>(URL_BUSCAR_PREVIEW, body)
  return response.data
}

const transformarPlanilha = async (cicloId: string): Promise<string> => {
  const URL_TRANSFORMAR_PLANILHA = `/Transformacao/Ciclos/${cicloId}`
  const response = await instance.conectarPost<string>(URL_TRANSFORMAR_PLANILHA)
  return response.data
}

const tratarPlanilha = async (cicloId: string, numerosLinhas: number[] | string[], tipoTratamento: string): Promise<string> => {
  let queryLinhas = ''
  numerosLinhas.forEach((linha: string | number) => {
    queryLinhas += `numerosLinhas=${linha}&`
  })
  const URL_TRATAR_PLANILHA = `/Transformacao/Ciclos/${cicloId}/Tratar?${queryLinhas}tipoTratamento=${tipoTratamento}`
  const response = await instance.conectarPost<string>(URL_TRATAR_PLANILHA)
  return response.data
}

const previewRegraCiclo = async (body: RegraTransformacaoForm, cicloId: string, numeroLinha: number | string): Promise<PreviewItemTransformacao> => {
  const URL_TRATAR_PLANILHA = `/Transformacao/Ciclos/${cicloId}/Preview?numeroLinha=${numeroLinha}`
  const response = await instance.conectarPost<PreviewItemTransformacao>(URL_TRATAR_PLANILHA, body)
  return response.data
}

const cadastrarGrupoRegra = async (body: GrupoRegraCadastro, parceiroId: string | number): Promise<string> => {
  const URL_CADASTRAR_GRUPO_REGRA = `/Transformacao/Parceiros/${parceiroId}/Grupos`
  const response = await instance.conectarPost<string>(URL_CADASTRAR_GRUPO_REGRA, body)
  return response.data
}

const editarGrupoRegra = async (body: GrupoRegra, parceiroId: string | number): Promise<string> => {
  const URL_EDITAR_GRUPO_REGRA = `/Transformacao/Parceiros/${parceiroId}/Grupos/${body.grupoId}`
  const response = await instance.conectarPut<string>(URL_EDITAR_GRUPO_REGRA, body)
  return response.data
}

const buscarGruposRegras = async (parceiroId: string | number): Promise<GrupoRegra[]> => {
  const URL_BUSCAR_GRUPOS_REGRAS = `/Transformacao/Parceiros/${parceiroId}/Grupos`
  const response = await instance.conectarGet<GrupoRegra[]>(URL_BUSCAR_GRUPOS_REGRAS)
  return response.data
}

const editarTabelaErroProcessamento = async (
  cicloId: string,
  itemLancamento: string[],
  indexEditado: number
): Promise<{ cicloId: string; itemLancamento: string[]; indexEditado: number }> => {
  const URL_EDITAR_ERRO_PROCESSAMENTO = `/Transformacao/Ciclos/${cicloId}/Editar/${indexEditado}`
  const response = await instance.conectarPut<{ cicloId: string; itemLancamento: string[]; indexEditado: number }>(
    URL_EDITAR_ERRO_PROCESSAMENTO,
    itemLancamento
  )

  return response.data
}

const reordenarPrioridade = async (parceiroId: number | string, body: PriorizacaoRegras[]): Promise<boolean> => {
  const URL_REORDENAR_PRIORIDADE = `/Transformacao/Parceiros/${parceiroId}/RegrasPrioridade`
  const response = await instance.conectarPut<boolean>(URL_REORDENAR_PRIORIDADE, body)
  return response.data
}

export default {
  buscarCondicoes,
  buscarAcoes,
  cadastrarRegra,
  editarRegra,
  aplicarTransformacao,
  buscarRegras,
  enviarArquivo,
  previewRegra,
  transformarPlanilha,
  tratarPlanilha,
  previewRegraCiclo,
  cadastrarGrupoRegra,
  editarGrupoRegra,
  buscarGruposRegras,
  editarTabelaErroProcessamento,
  reordenarPrioridade,
  buscarRegra
}
