import { Box } from '@material-ui/core'
import styled from 'styled-components'

export const tabTransformacaoStyles = {
  Container: styled(Box)`
    padding: 24px;
  `,
  ContainerForm: styled(Box)`
    padding: 32px 0px;
  `,
  ImageIcon: styled.img`
    :hover {
      cursor: pointer;
    }

    height: 32px;
  `
}
