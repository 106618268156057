import { Box, Typography } from '@material-ui/core'
import { IOProps } from 'react-compose-io'
import { formateCurrency } from '../../utils/stringUtils'
import { BoxValoresIO } from './BoxValores.IO'

export type BoxValoresProps = {
  campoTexto: string
  valor: number | string
  possuiCor?: boolean
  possuiErro?: boolean
  valorCor?: boolean
}

const BoxValoresView = ({ _io, campoTexto, valor, possuiErro, valorCor, possuiCor }: IOProps<BoxValoresIO, BoxValoresProps>) => {
  const { CustomBox, CustomTypography, MarginBox } = _io.styles

  return (
    <Box>
      <Typography>{campoTexto}</Typography>
      <CustomBox possuierro={possuiErro ? 1 : 0} possuicor={possuiCor ? 1 : 0}>
        <MarginBox>
          {valorCor ? <CustomTypography cor={valor}>{formateCurrency(valor)}</CustomTypography> : <Typography>{formateCurrency(valor)}</Typography>}
        </MarginBox>
      </CustomBox>
    </Box>
  )
}

export default BoxValoresView
