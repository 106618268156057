import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { PAGES } from '../../router/routes'
import parceiroManager from '../../services/api/parceiro/parceiroManager'
import { loadingReset, loadingUpdate } from '../../store/actions/loadingAction'
import { Parceiro } from '../../types/parceiroApiTypes'
import { informacaoParceiroStyles } from './InformacaoParceiro.styles'

export function informacaoParceiroIO() {
  const rotas = useHistory()
  const dispatch = useDispatch()
  const [parceiro, setParceiro] = useState<Parceiro>()
  const { parceiroId } = useParams<{ parceiroId: string }>()

  const editarInformacoesParceiro = () => {
    if (parceiroId) {
      rotas.push(PAGES.editarParceiroId(parceiroId))
    }
  }

  useEffect(() => {
    async function buscarParceiro(buscarParceiroId: string) {
      const response: Parceiro = await parceiroManager.buscarDadoParceiro(buscarParceiroId)
      setParceiro(response)
      dispatch(loadingReset())
    }

    dispatch(loadingUpdate(true))
    buscarParceiro(parceiroId)
  }, [dispatch, parceiroId])

  return {
    styles: informacaoParceiroStyles,
    editarInformacoesParceiro,
    parceiroId,
    parceiro
  }
}

export type InformacaoParceiroIO = ReturnType<typeof informacaoParceiroIO>
