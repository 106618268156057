import { Grid, Table, TableBody, TableContainer, TablePagination } from '@material-ui/core'
import { IOProps } from 'react-compose-io'
import DataEmpty from '../../../../components/DataEmpty/DataEmpty'
import { EnhancedTableHead } from '../../../../components/EnhancedTableHead'
import { ComponentePaginacao } from '../../../../components/Tabela/ComponentePaginacao/index'
import { HeadCell } from '../../../../interfaces/interfaces'
import { getComparator, ordenarCabecalho } from '../../../../utils/tabelaUtils'
import { AbaTabelaIO } from './AbaTabela.IO'

export type AbaTabelaProps = {
  listItem: any[]
  headCells: HeadCell[]
  LinhaTabela: (props: { item: any; index: number }) => JSX.Element
  orderId: string
}

export default function AbaTabelaView({ _io, listItem, LinhaTabela, headCells }: IOProps<AbaTabelaIO, AbaTabelaProps>) {
  return (
    <Grid item sm={12}>
      {listItem && listItem.length > 0 ? (
        <>
          <TableContainer>
            <Table aria-label="collapsible table">
              <EnhancedTableHead headCells={headCells} order={_io.order} orderBy={_io.orderBy} onRequestSort={_io.onRequestSort} alignCenter={true} />
              <TableBody>
                {ordenarCabecalho(listItem, getComparator(_io.order, _io.orderBy))
                  .slice(_io.page * _io.rowsPerPage, _io.page * _io.rowsPerPage + _io.rowsPerPage)
                  .map((item, index) => {
                    return <LinhaTabela key={index} item={item} index={index} />
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={_io.rowsPerPageOptions}
            component="div"
            count={listItem.length}
            rowsPerPage={_io.rowsPerPage}
            page={_io.page}
            onPageChange={_io.onPageChange}
            onRowsPerPageChange={_io.onRowsPerPageChange}
            ActionsComponent={ComponentePaginacao}
          />
        </>
      ) : (
        <DataEmpty descricao="Nenhum item cadastrado!" />
      )}
    </Grid>
  )
}
