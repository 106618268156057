import { Grid } from '@material-ui/core'
import { IOProps } from 'react-compose-io'
import { ContainerAvatar } from '../../../../components/ContainerAvatar/index'
import { PAGES } from '../../../../router/routes'
import { RegularizacaoCicloParceiro } from '../../../../types/regularizacaoOPsTitulosTypes'
import { ItemCicloPendenteLista } from '../ItemCicloPendenteLista/index'
import { ItemListaRegularizacaoIO } from './ItemListaRegularizacao.IO'

export type ItemListaRegularizacaoProps = {
  valorCiclo: RegularizacaoCicloParceiro
}

export function ItemListaRegularizacaoView({ _io, valorCiclo }: IOProps<ItemListaRegularizacaoIO, ItemListaRegularizacaoProps>) {
  const { ContainerPedidos, TituloLista, SubtituloLista, Link } = _io.styles

  return (
    <Grid container spacing={2} direction="row">
      <Grid item xs={2}>
        <Link to={PAGES.informacaoId(null, valorCiclo.parceiroResumo.parceiroId.toString(), null, null)}>
          <ContainerAvatar avatarDescricao={valorCiclo.parceiroResumo.parceiroNome} avatarSrc={valorCiclo.parceiroResumo.logoParceiroUrl} />
        </Link>
      </Grid>
      <ContainerPedidos xs={2}>
        <TituloLista>Pedidos pendentes</TituloLista>
        <SubtituloLista>{valorCiclo.totalPedidosPendentesParceiro.toLocaleString('pt-BR')}</SubtituloLista>
      </ContainerPedidos>
      <Grid item xs={8}>
        <ItemCicloPendenteLista valorCiclo={valorCiclo} />
      </Grid>
    </Grid>
  )
}
