import { GraficoValorQuantidadeIO } from './GraficoValorQuantidade.IO'
import { Grid } from '@material-ui/core'
import { IOProps } from 'react-compose-io'
import { BoxValor } from './../../BoxValor/index'
import { GraficoBarras } from './../../GraficoBarras/index'
import CifraoIcone from '../../../assets/icons/cifrao.svg'
import CuboIcone from '../../../assets/icons/cubo.svg'
import { formateCurrency } from '../../../utils/stringUtils'
import { corBarra } from '../../../utils/debitoGraficos'
import { TIPO_VALOR_GRAFICO } from '../../../constants/utilConstants'
import { Botao } from './../../Botao/index'

export type GraficoValorQuantidadeProps = {
  tituloGrafico: string
  valoresMensais: number[]
  legendaMeses: string[][]
  legendaEixoY: string
  divisorLegenda: number
  descricaoValor: string
  valor: number
  descricaoQuantidade: string
  quantidade: number
  width?: string
  handleVerMaisDetalhesPedidoBotao?: () => void
  tipoPadrao?: boolean
  corPadrao?: ({ dataPointIndex }: { dataPointIndex: number; value: number }) => string
}

export function GraficoValorQuantidadeView({
  _io,
  tituloGrafico,
  valoresMensais,
  legendaMeses,
  legendaEixoY,
  divisorLegenda,
  descricaoValor,
  valor,
  descricaoQuantidade,
  quantidade,
  width,
  handleVerMaisDetalhesPedidoBotao,
  tipoPadrao = true,
  corPadrao
}: IOProps<GraficoValorQuantidadeIO, GraficoValorQuantidadeProps>) {
  const { GridWidthLimitada, GridContainer } = _io.styles

  return (
    <GridContainer container spacing={2} direction="row" justifyContent="center" alignContent="center" width={width}>
      <GridWidthLimitada item sm={7} md={7} lg={6}>
        <GraficoBarras
          tituloGrafico={tituloGrafico}
          dadosColunas={valoresMensais}
          descricaoColunas={legendaMeses}
          legendaEixoY={legendaEixoY}
          valorDivididoLegenda={divisorLegenda}
          corBarra={corPadrao ?? corBarra}
          tipoValor={tipoPadrao ? TIPO_VALOR_GRAFICO.MOEDA : TIPO_VALOR_GRAFICO.UNIDADE}
        />
      </GridWidthLimitada>
      <GridWidthLimitada item container justifyContent="center" alignContent="center" direction="column" spacing={2} sm={12} md={4} lg={6}>
        <Grid item>
          <BoxValor icone={CifraoIcone} descricao={descricaoValor} valor={formateCurrency(valor)}></BoxValor>
        </Grid>
        <Grid item>
          <BoxValor icone={CuboIcone} descricao={descricaoQuantidade} valor={quantidade.toLocaleString('pt-BR')} />
        </Grid>
        {handleVerMaisDetalhesPedidoBotao && (
          <Grid item>
            <Botao
              cor="primaria"
              estilo="contained"
              type="submit"
              textoBotao="Ver detalhes dos pedidos"
              onClick={handleVerMaisDetalhesPedidoBotao}
              width="360px"
              tamanho="grande"
            />
          </Grid>
        )}
      </GridWidthLimitada>
    </GridContainer>
  )
}
