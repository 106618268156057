import { IOProps } from 'react-compose-io'
import { FormFieldsProps } from '../../../types/regraApiType'
import { FieldsTipoParametro } from '../FieldsTipoParametro'
import { FieldsSubstituirIO } from './FieldsSubstituir.IO'

export type FieldsSubstituirProps = {
  indexItensRegraForm: number
  itensCampo: FormFieldsProps[]
  cabecalhos: string[]
}

export function FieldsSubstituirView({ _io, indexItensRegraForm, itensCampo, cabecalhos }: IOProps<FieldsSubstituirIO, FieldsSubstituirProps>) {
  return (
    <>
      {_io.acaoSelecionada[indexItensRegraForm]?.operacaoId === 'SubstituirOperacao' && (
        <FieldsTipoParametro
          itensCampo={itensCampo}
          control={_io.control}
          cabecalhos={cabecalhos}
          indexItensRegraForm={indexItensRegraForm}
          itensRegraTransformacaoForm={_io.acaoSelecionada}
        />
      )}
    </>
  )
}
