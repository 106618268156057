import { Typography } from '@material-ui/core'
import styled from 'styled-components'

export const legendaStyles = {
  ContainerTextoLegenda: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: start;
  `,
  TextoLegenda: styled(Typography)`
    color: ${(props: { cor: string }) => props.cor};
  `,
  CirculoLegenda: styled.div`
    border-radius: 100%;
    height: 15px !important;
    width: 15px !important;
    margin-right: 10px;
    background-color: ${(props: { cor: string }) => props.cor};
  `
}
