import styled from 'styled-components'
import { ContainerSombra } from '../../components/ContainerSombra'
import { Grid } from '@material-ui/core'

export const controleClassificacoesStyles = {
  Container: styled(ContainerSombra).attrs((cor: { cor: string }) => cor)`
    padding: 24px;
    border-color: ${(props) => props.cor};
    border-style: solid;
    border-width: 1px;
  `,
  ContainerGridGraficos: styled(Grid)`
    padding-top: 24px;

    > div > div {
      padding: 24px;
    }

    > div > div > p {
      margin-bottom: 1.5rem;
    }
  `
}
