import { ListItemIcon } from '@material-ui/core'
import styled from 'styled-components'
import { customTheme } from '../../styles/customTheme'
import AppMenuItemComponent from '../AppMenuItemComponent/AppMenuItemComponent'

export const appMenuItemStyles = {
  AppMenuItemComponent: styled(AppMenuItemComponent)`
    color: ${customTheme.colors.grey05};
    font-weight: 500;

    &.active {
      background: ${customTheme.colors.blue01};
      border-left: 3px solid ${customTheme.colors.blue06};

      .MuiTypography-root {
        color: ${customTheme.colors.blue06};
      }

      .MuiListItemIcon-root {
        color: ${customTheme.colors.blue06};
      }
    }
  `,
  ListItemIcon: styled(ListItemIcon)`
    color: ${customTheme.colors.grey05};
  `
}
