import { ApexOptions } from 'apexcharts'
import { customTheme } from '../../../../styles/customTheme'
import { formateCurrency, formateDate } from '../../../../utils/stringUtils'
import { graficoBarraSemanasStyles } from './GraficoBarraSemanas.style'
import { GraficoBarraSemanasProps } from './GraficoBarraSemanas.View'

export function graficoBarraSemanasIO({ listaTitulosParceiros }: GraficoBarraSemanasProps) {
  const listaTitulosTodosParceiros = listaTitulosParceiros ? listaTitulosParceiros : []
  const valorPorcentagemParceiros = listaTitulosTodosParceiros.map((valorPorcentagem) => valorPorcentagem.liquidados.valor)
  const quantidade = listaTitulosTodosParceiros.map((valorPorcentagem) => valorPorcentagem.liquidados.quantidade)
  const legendaGrafico = listaTitulosTodosParceiros.map((legenda) => {
    return [`Semana ${legenda.numero}`, formateDate(legenda.dataInicio), formateDate(legenda.dataFim)]
  })

  return {
    styles: graficoBarraSemanasStyles,
    series: [
      {
        name: 'Valor',
        data: valorPorcentagemParceiros
      }
    ],
    options: {
      chart: {
        type: 'bar',
        stacked: false,
        redrawOnWindowResize: false,
        toolbar: {
          show: false,
          tools: {
            download: false
          }
        },
        animations: {
          enabled: true
        }
      },
      plotOptions: {
        bar: {
          columnWidth: '90%',
          distributed: true
        }
      },
      legend: {
        show: false
      },
      xaxis: {
        categories: legendaGrafico
      },
      yaxis: {
        labels: {
          show: false
        }
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        y: {
          formatter: function (val: number) {
            return 'R$ ' + val
          }
        }
      },
      dataLabels: {
        enabled: true,
        formatter: (val: number, opt: any) => {
          return `${quantidade[opt.dataPointIndex].toLocaleString('pt-BR')} - ${formateCurrency(val)}`
        },
        offsetY: 200,
        style: {
          fontSize: '14px',
          position: 'top',
          colors: [`${customTheme.colors.black}`]
        }
      }
    } as ApexOptions
  }
}

export type GraficoBarraSemanasIO = ReturnType<typeof graficoBarraSemanasIO>
