import { Box, Collapse, Menu, MenuItem } from '@material-ui/core'
import EditOutlinedIcon from '@material-ui/icons/EditOutlined'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import ToggleOffIcon from '@material-ui/icons/ToggleOff'
import { Dispatch, ReactNode, SetStateAction } from 'react'
import { IOProps } from 'react-compose-io'
import IconeButton from '../../../../components/IconeButton/IconeButton'
import { HistoricoPadrao, TipoLancamento } from '../../../../types/tipoLancamentoApiTypes'
import { formateDate, validarTexto } from '../../../../utils/stringUtils'
import { ModalDesativar } from '../ModalDesativar/index'
import { ModalEditar } from '../ModalEditar/index'
import { ItemLancamentoIO } from './ItemLancamento.IO'

export type ItemLancamentoProps = {
  linhaTipoLancamento: TipoLancamento
  visualizarTabela: boolean
  visualizarHistorico: (index: number) => void
  itemLancamentoIndex: number
  historicoPadrao: HistoricoPadrao | undefined
  setHistoricoPadrao: Dispatch<SetStateAction<HistoricoPadrao | undefined>>
  obterHistoricoPadraoSequencia: (sequenciaId: number | string) => Promise<void>
}

export function ItemLancamentoView({ _io, linhaTipoLancamento, visualizarTabela, historicoPadrao }: IOProps<ItemLancamentoIO, ItemLancamentoProps>) {
  const {
    TituloTabela,
    Container,
    BotaoMenu,
    LinhaTabela,
    LinhaCentro,
    ContainerTabela,
    Linha,
    SubtituloTabela,
    SubtituloHistorico,
    LinhaEsquerda,
    BoxMenu
  } = _io.styles

  const getItemMenu = (title: string, icon: ReactNode, callback: (param: TipoLancamento) => void, item: TipoLancamento) => (
    <MenuItem key={title} onClick={() => callback(item)}>
      <BoxMenu>
        <BotaoMenu>{icon}</BotaoMenu>
        <Box>{title}</Box>
      </BoxMenu>
    </MenuItem>
  )

  const getMenu = (item: TipoLancamento) => {
    return [getItemMenu('Editar', <EditOutlinedIcon />, _io.editarLancamento, item), getItemMenu('Desativar', <ToggleOffIcon />, _io.desativarLancamento, item)]
  }

  const historicoDetalhe = (title: string, data: Date | null, nome: string, itemLancamento?: Date | null, sigla?: string) => {
    const detalhesHistorico = (
      <>
        <SubtituloTabela>{title}</SubtituloTabela>
        <SubtituloHistorico>
          {formateDate(data, true)} - {nome ?? '--'}
        </SubtituloHistorico>
      </>
    )

    if (itemLancamento) {
      return detalhesHistorico
    }

    if (sigla == 'Desativado') {
      return detalhesHistorico
    }
    return null
  }

  return (
    <>
      <ModalEditar
        aria-label="modal"
        configurarTipoLancamento={_io.configurarItemLancamento}
        historicoPadrao={historicoPadrao}
        abrirModal={_io.abrirModal}
        setAbrirModal={_io.setAbrirModal}
      />
      <ModalDesativar aria-label="modal" />
      <LinhaTabela corLinha={visualizarTabela}>
        <LinhaEsquerda onClick={_io.expandirCelulaTabela}>{validarTexto('' + linhaTipoLancamento.id, '--')}</LinhaEsquerda>
        <LinhaEsquerda onClick={_io.expandirCelulaTabela}>{validarTexto(linhaTipoLancamento.descricao, '--')}</LinhaEsquerda>
        <LinhaEsquerda onClick={_io.expandirCelulaTabela}>{validarTexto(linhaTipoLancamento.sequencia, '--')}</LinhaEsquerda>
        <LinhaEsquerda onClick={_io.expandirCelulaTabela}>{validarTexto(linhaTipoLancamento.conta, '--')}</LinhaEsquerda>
        <LinhaEsquerda onClick={_io.expandirCelulaTabela}>{validarTexto(linhaTipoLancamento.natureza, '--')}</LinhaEsquerda>
        <LinhaEsquerda onClick={_io.expandirCelulaTabela}>{validarTexto(linhaTipoLancamento.tipo, '--')}</LinhaEsquerda>
        <LinhaCentro onClick={_io.expandirCelulaTabela}>
          <ContainerTabela>{validarTexto(linhaTipoLancamento.status, '--')}</ContainerTabela>
        </LinhaCentro>
        <LinhaCentro>
          <Container>
            <IconeButton color="inherit" descricao="ações" icone={<MoreVertIcon />} acao={_io.expandirHistorico} />
            <Menu id="simple-menu" anchorEl={_io.expandirHistoricoTabela} keepMounted open={Boolean(_io.expandirHistoricoTabela)} onClose={_io.fecharHistorico}>
              {getMenu(linhaTipoLancamento).map((itemMenu: ReactNode) => {
                return itemMenu
              })}
            </Menu>
          </Container>
        </LinhaCentro>
      </LinhaTabela>
      <LinhaTabela corLinha={visualizarTabela}>
        <Linha colSpan={8}>
          <Collapse in={visualizarTabela}>
            <TituloTabela>Historico</TituloTabela>
            {historicoDetalhe('Desativou Lançamento', linhaTipoLancamento?.dataAlteracao, linhaTipoLancamento.nomeAlteracao, null, linhaTipoLancamento.status)}
            {historicoDetalhe('Editou Sequência', linhaTipoLancamento?.dataAlteracao, linhaTipoLancamento.nomeAlteracao, linhaTipoLancamento.dataAlteracao)}
            {historicoDetalhe('Cadastrou Lançamento', linhaTipoLancamento?.dataCadastro, linhaTipoLancamento.nomeCriacao, linhaTipoLancamento.dataCadastro)}
          </Collapse>
        </Linha>
      </LinhaTabela>
    </>
  )
}
