import { ApexOptions } from 'apexcharts'
import { corLetraChip, corMes } from '../../styles/customTheme'
import { graficoDonutStyles } from './GraficoDonut.style'
import { GraficoDonutProps } from './GraficoDonut.View'

export function graficoDonutIO({ dadosColunas, legendaGrafico, tipoGrafico, ocultarLegenda = false }: GraficoDonutProps) {
  return {
    styles: graficoDonutStyles,
    options: {
      chart: {
        type: 'donut',
        toolbar: {
          show: false,
          tools: {
            download: false
          }
        },
        animations: {
          enabled: true
        },
        redrawOnWindowResize: false
      },
      legend: {
        show: !ocultarLegenda,
        position: 'right',
        fontFamily: 'Rubik, Helvetica, sans-serif',
        formatter(legendName, { seriesIndex }: any) {
          return legendName + ` (${dadosColunas[seriesIndex].toFixed(2)}%)`
        }
      },
      yaxis: {
        labels: {
          formatter: function (value: number) {
            return value.toFixed(2) + '%'
          },
          style: {
            fontSize: '16px',
            fontFamily: 'Rubik, Helvetica, sans-serif'
          }
        }
      },
      fill: {
        opacity: 1
      },
      dataLabels: {
        enabled: false
      },
      labels: legendaGrafico,
      colors: [
        function ({ seriesIndex }: any) {
          if (tipoGrafico === 'mes') {
            if (corMes.get(legendaGrafico[seriesIndex])) {
              return corMes.get(legendaGrafico[seriesIndex])
            }
          } else {
            if (corLetraChip.get(legendaGrafico[seriesIndex])) {
              return corLetraChip.get(legendaGrafico[seriesIndex])
            }
          }
        }
      ]
    } as ApexOptions,
    series: dadosColunas
  }
}

export type GraficoDonutIO = ReturnType<typeof graficoDonutIO>
