import { DialogActions, DialogContent, DialogProps, IconButton } from '@material-ui/core'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import { CancelOutlined, ArrowBack } from '@material-ui/icons'
import React from 'react'
import { customDialogStyles } from './CustomDialog.style'

interface CustomDialogProps extends DialogProps {
  titulo: string
  id: string
  children: React.ReactNode
  habilitarAcoes?: boolean
  actions?: React.ReactNode
  fecharHabilitado?: boolean
  handleVoltar?: () => void
  fecharModal?: () => void
}

export const CustomDialog = ({ id, titulo, children, habilitarAcoes, actions, fecharModal, fecharHabilitado, handleVoltar, ...rest }: CustomDialogProps) => {
  const { Box, Dialog } = customDialogStyles

  return (
    <Dialog keepMounted {...rest} onClose={(event, reason) => reason !== 'backdropClick' && fecharModal}>
      <Box>
        {handleVoltar && (
          <IconButton onClick={handleVoltar}>
            <ArrowBack />
          </IconButton>
        )}
        <MuiDialogTitle id={id}>{titulo}</MuiDialogTitle>
        {fecharHabilitado && (
          <IconButton color="primary" onClick={fecharModal}>
            <CancelOutlined />
          </IconButton>
        )}
      </Box>
      <DialogContent>{children}</DialogContent>
      {habilitarAcoes && <DialogActions>{actions}</DialogActions>}
    </Dialog>
  )
}
