import { Box, TableCell, TableRow } from '@material-ui/core'
import styled from 'styled-components'

export const itemParceiroStyles = {
  Box: styled(Box)`
    border-radius: 5px;
    padding: 8px;
  `,
  TableCell: styled(TableCell).attrs({ align: 'center' })``,
  TableRow: styled(TableRow).attrs({
    hover: true,
    tabIndex: -1
  })`
    cursor: pointer;
  `
}
