import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import { DragDropContext, DroppableProvided } from 'react-beautiful-dnd'
import { IOProps } from 'react-compose-io'
import { TabelaArrastavelIO } from './TabelaArrastavel.IO'
import { Dispatch, SetStateAction } from 'react'
import { StrictModeDroppable } from '../StrictModeDroppable/StrictModeDroppable'

export type TabelaArrastavelProps = {
  itensTabela: any[]
  setItensTabela: Dispatch<SetStateAction<any[]>>
  colunasTabela: string[]
  LinhaTabela: (props: { item: any; index: number }) => JSX.Element
  itemKey: string
}

export default function TabelaArrastavelView({ _io, colunasTabela, itensTabela, LinhaTabela, itemKey }: IOProps<TabelaArrastavelIO, TabelaArrastavelProps>) {
  const { TableContainer } = _io.styles

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {colunasTabela.map((coluna: string) => (
              <TableCell key={coluna}>{coluna}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <DragDropContext onDragEnd={_io.handleDragEnd}>
          <StrictModeDroppable droppableId="droppable" direction="vertical">
            {(droppableProvided: DroppableProvided) => (
              <TableBody ref={droppableProvided.innerRef} {...droppableProvided.droppableProps}>
                {itensTabela.map((item: any, index: number) => (
                  <LinhaTabela item={item} key={item[itemKey]} index={index} />
                ))}
                {droppableProvided.placeholder}
              </TableBody>
            )}
          </StrictModeDroppable>
        </DragDropContext>
      </Table>
    </TableContainer>
  )
}
