import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import msgConstants from '../../constants/msgConstants'
import RegularizacaoOPsManager from '../../services/api/regularizacaoOPs/regularizacaoOPsManager'
import { loadingReset, loadingUpdate } from '../../store/actions/loadingAction'
import { ResponsePaginado, StatusProcessamento } from '../../types/genericoType'
import {
  HistoricoRegularizacao,
  ValidacaoPlanilhaResponse,
  valorDefaultHistoricoRegularizacao,
  valorDefaultModalValidado
} from '../../types/regularizacaoTypes'
import { Paginacao } from '../../types/tabelaTypes'
import { regularizacaoOPsStyle } from './RegularizacaoOPs.style'

export function regularizacaoOPsIO() {
  const [historicosRegularizacao, setHistoricosRegularizacao] = useState<ResponsePaginado<HistoricoRegularizacao>>(valorDefaultHistoricoRegularizacao)
  const [abrirModalValidarPlanilha, setAbrirModalValidarPlanilha] = useState<boolean>(false)
  const [informacaoValidadaModal, setInformacaoValidadaModal] = useState<ValidacaoPlanilhaResponse>(valorDefaultModalValidado)
  const [mensagemErroUploadPlanilha, setMensagemErroUploadPlanilha] = useState<string>('')
  const [arquivoId, setArquivoId] = useState<string>('')
  const dispatch = useDispatch()
  const [valorSetTimeOut, setValorSetTimeOut] = useState<number>(0)
  const history = useHistory()

  useEffect(() => {
    atualizarHistoricoRegularizacaoOPs({ pageNumber: 1, pageSize: 5 })
  }, [])

  useEffect(() => {
    return history.listen(() => {
      clearTimeout(valorSetTimeOut)
    })
  }, [valorSetTimeOut])

  const validarPlanilha = (arquivo?: FormData) => {
    if (arquivo) {
      dispatch(loadingUpdate(true))
      setMensagemErroUploadPlanilha('')
      RegularizacaoOPsManager.validarPlanilhaRegularizacaoOPs(arquivo)
        .then((response: ValidacaoPlanilhaResponse) => {
          setInformacaoValidadaModal(response)
        })
        .catch((e: Error) => {
          setMensagemErroUploadPlanilha(e.message)
        })
        .finally(() => {
          dispatch(loadingReset())
          setAbrirModalValidarPlanilha(true)
        })
    } else {
      toast.warning(msgConstants.REGULARIZACAO.inserirArquivo)
    }
  }

  const handleFecharModalValidarPlanilha = () => {
    setAbrirModalValidarPlanilha(false)
  }

  const handleAbrirModalValidarPlanilha = () => {
    setAbrirModalValidarPlanilha(true)
  }

  async function efetivarRegularizacaoOPs() {
    dispatch(loadingUpdate(true))
    RegularizacaoOPsManager.efetivarRegularizacaoOPs(arquivoId)
      .then(() => {
        setAbrirModalValidarPlanilha(false)
        atualizarHistoricoRegularizacaoOPs({ pageNumber: 1, pageSize: 5 })
      })
      .catch((e: Error) => {
        toast.error(e.message)
      })
      .finally(() => {
        dispatch(loadingReset())
      })
  }

  async function atualizarHistoricoRegularizacaoOPs(paginacao: Paginacao, desativarCarregamentoTela?: boolean) {
    !desativarCarregamentoTela && dispatch(loadingUpdate(true))

    RegularizacaoOPsManager.buscarHistoricoRegularizacaoOPs({ pageNumber: paginacao.pageNumber, pageSize: paginacao.pageSize })
      .then((response: ResponsePaginado<HistoricoRegularizacao>) => {
        const statusRelatorio = response.itens.map((processamento) => processamento.processamento.status)
        setHistoricosRegularizacao(response)
        if (statusRelatorio !== null && statusRelatorio.includes(StatusProcessamento.PROCESSANDO)) {
          const atualizarStatus = setTimeout(() => {
            atualizarHistoricoRegularizacaoOPs(
              {
                pageNumber: paginacao.pageNumber,
                pageSize: paginacao.pageSize
              },
              true
            )
          }, 10000)
          setValorSetTimeOut(Number(atualizarStatus))
        }
      })
      .catch(() => {
        toast.error(msgConstants.REGULARIZACAO.erroAoBuscarPendencias)
      })
      .finally(() => {
        !desativarCarregamentoTela && dispatch(loadingReset())
      })
  }

  return {
    styles: regularizacaoOPsStyle,
    validarPlanilha,
    historicosRegularizacao,
    totalLinhas: historicosRegularizacao.totalItens,
    handleFecharModalValidarPlanilha,
    handleAbrirModalValidarPlanilha,
    abrirModalValidarPlanilha,
    informacaoValidadaModal,
    mensagemErroUploadPlanilha,
    atualizarHistoricoRegularizacaoOPs,
    efetivarRegularizacaoOPs,
    setArquivoId,
    valorSetTimeOut
  }
}

export type RegularizacaoOPsIO = ReturnType<typeof regularizacaoOPsIO>
