import { IOProps } from 'react-compose-io'
import { DetalheControleComissaoPedidoIO } from './DetalheControleComissaoPedidoIO'
import { TabelaDetalheControleComissaoPedido } from '../../../../components/TabelaDetalheControleComissaoPedido'
import { DetalheControleComissaoPedidoResponse } from '../../../../types/controleComissaoTypes'
import { Collapse } from '@material-ui/core'

export type DetalheControleComissaoPedidoProps = {
  detalhesControleComissaoPedido: DetalheControleComissaoPedidoResponse[]
  pedidoAberto: boolean
  controleComissaoPedidoChave: string
}

export function DetalheControleComissaoPedidoView({
  _io,
  detalhesControleComissaoPedido,
  pedidoAberto,
  controleComissaoPedidoChave
}: IOProps<DetalheControleComissaoPedidoIO, DetalheControleComissaoPedidoProps>) {
  const { TableRow, TableCell, TituloDescricao, ContainerGrid } = _io.styles

  return (
    <TableRow>
      <TableCell colSpan={1000}>
        <Collapse in={pedidoAberto} timeout="auto" unmountOnExit>
          <ContainerGrid>
            <TituloDescricao fontWeight="500" variant="h6">
              Detalhes do Pedido
            </TituloDescricao>
            <TabelaDetalheControleComissaoPedido
              key={controleComissaoPedidoChave}
              checklistsSelecionados={_io.checkListSelecionados}
              cabecalhoTabela={_io.cabecalhosTabela}
            >
              {detalhesControleComissaoPedido && detalhesControleComissaoPedido.map(_io.renderLinhasTabela)}
            </TabelaDetalheControleComissaoPedido>
          </ContainerGrid>
        </Collapse>
      </TableCell>
    </TableRow>
  )
}
