import { Box, CardContent } from '@material-ui/core'
import styled from 'styled-components'
import { ContainerSombra } from '../../../../components/ContainerSombra'
import { customTheme } from '../../../../styles/customTheme'

export const novaAssociacaoStyles = {
  Container: styled(ContainerSombra)`
    margin-top: 25px;
  `,
  Label: styled.label`
    color: ${customTheme.colors.grey04};
    font-size: ${customTheme.fontSize[18]};
  `,
  ContainerBox: styled(Box)`
    border-radius: 5px;
    padding: 40px;
    margin: 8px;
    border: 1px solid ${customTheme.colors.grey02};
    margin-top: 24px;
    width: 100%;
  `,
  CardContent: styled(CardContent)`
    padding: 24px;
  `
}
