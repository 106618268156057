import { useCallback, useEffect, useState } from 'react'
import { controleHistoricoPerdasStyles } from './ControleHistoricoPerdas.styles'
import debitosManager from '../../services/api/debitos/debitosManager'
import { toast } from 'react-toastify'
import msgConstants from '../../constants/msgConstants'
import { DadosMeses, gerarDadosGrafico, unirPerdas } from './../../utils/tratarDadosMesesUtils'
import { DebitosPerdasEfetivados } from '../../types/debitosTypes'
import { formateCurrency } from '../../utils/stringUtils'
import { DadosGraficoPerdas, PerdasEfetivadas } from '../../types/perdasTypes'
import controleInadimplenciaManager from '../../services/api/controleInadimplencia/controleInadimplenciaManager'
import { TipoPerda } from './ControleHistoricoPerdas.View'
import { InadimplenciaPerdasEfetivadas } from '../../types/controleInadimplenciaTypes'

export function controleHistoricoPerdasIO() {
  const [historicoPerdasDebitos, setHistoricoPerdasDebitos] = useState<DebitosPerdasEfetivados[]>([])
  const [historicoPerdasInadimplencia, setHistoricoPerdasInadimplencia] = useState<InadimplenciaPerdasEfetivadas[]>([])
  const [parceiroSelecionado, setParceiroSelecionado] = useState(0)
  const [mesesSelecionado, setMesesSelecionado] = useState<6 | 12 | 1>(6)

  useEffect(() => {
    obterControleHistoricoPerdasDebitos()
    obterControleHistoricoPerdasInadimplencia()
  }, [])

  const obterControleHistoricoPerdasDebitos = useCallback(async () => {
    await debitosManager
      .buscarHistoricoDebitosEfetivados()
      .then((response: DebitosPerdasEfetivados[]) => {
        setHistoricoPerdasDebitos(response)
      })
      .catch((error) => {
        toast.error(msgConstants.USO_GENERICO.erroBusca, error)
      })
  }, [])

  const obterControleHistoricoPerdasInadimplencia = useCallback(async () => {
    await controleInadimplenciaManager
      .buscarHistoricoInadimplenciaEfetivadas()
      .then((response: InadimplenciaPerdasEfetivadas[]) => {
        setHistoricoPerdasInadimplencia(response)
      })
      .catch((error) => {
        toast.error(msgConstants.USO_GENERICO.erroBusca, error)
      })
  }, [])

  function handleFecharChip() {
    setParceiroSelecionado(0)
  }

  function filtrarPerdas(historicoPerdas: PerdasEfetivadas[]) {
    let perdasMeses: DadosMeses[] = []
    if (parceiroSelecionado !== 0) {
      const perdasfiltradasPorParceiro = historicoPerdas?.filter((parceiro) => parceiro.parceiroId === parceiroSelecionado)
      perdasMeses = perdasfiltradasPorParceiro.length > 0 ? perdasfiltradasPorParceiro[0].dados : []
    } else {
      perdasMeses = unirPerdas(historicoPerdas)
    }
    return perdasMeses
  }

  function selecionarUrlRelatorio(tipoPerda: TipoPerda) {
    if (mesesSelecionado === 6 && parceiroSelecionado === 0) {
      return tipoPerda == 'DEBITO'
        ? process.env.REACT_APP_PLANILHA_HISTORICO_PERDAS_DEBITO_6_MESES
        : process.env.REACT_APP_PLANILHA_HISTORICO_PERDAS_INADIMPLENCIA_6_MESES
    }
    if (mesesSelecionado === 12 && parceiroSelecionado === 0) {
      return tipoPerda == 'DEBITO'
        ? process.env.REACT_APP_PLANILHA_HISTORICO_PERDAS_DEBITO_12_MESES
        : process.env.REACT_APP_PLANILHA_HISTORICO_PERDAS_INADIMPLENCIA_12_MESES
    }
    return ''
  }

  function quantidadeTotal(dadosGraficoPerdas: DadosGraficoPerdas) {
    return dadosGraficoPerdas.quantidade.reduce((total, quantidade) => total + quantidade, 0).toLocaleString('pt-BR')
  }

  function valorTotal(dadosGraficoPerdas: DadosGraficoPerdas) {
    return formateCurrency(dadosGraficoPerdas.valor.reduce((total, valor) => total + valor, 0))
  }

  const dadosGraficoDebito = gerarDadosGrafico(filtrarPerdas(historicoPerdasDebitos), mesesSelecionado)
  const dadosGraficoInadimplencia = gerarDadosGrafico(filtrarPerdas(historicoPerdasInadimplencia), mesesSelecionado)

  function handleClickParceiro(parceiroId: number) {
    setParceiroSelecionado(parceiroId)
  }

  return {
    styles: controleHistoricoPerdasStyles,
    historicoPerdasDebitos,
    historicoPerdasInadimplencia,
    handleClickParceiro,
    parceiroSelecionado,
    handleFecharChip,
    mesesSelecionado,
    setMesesSelecionado,
    selecionarUrlRelatorio,
    dadosGraficoDebito: dadosGraficoDebito,
    dadosGraficoInadimplencia: dadosGraficoInadimplencia,
    valorTotalDebitos: valorTotal(dadosGraficoDebito),
    quantidadeTotalDebitos: quantidadeTotal(dadosGraficoDebito),
    valorTotalInadimplencia: valorTotal(dadosGraficoInadimplencia),
    quantidadeTotalInadimplencia: quantidadeTotal(dadosGraficoInadimplencia)
  }
}

export type ControleHistoricoPerdasIO = ReturnType<typeof controleHistoricoPerdasIO>
