import { customTheme } from '../../../../styles/customTheme'
import { pedidosDivergentesResumoStyles } from './PedidosDivergentesResumo.style'
import { ApexOptions } from 'apexcharts'
import { useState } from 'react'
import { PedidosDivergentesResumoProps } from './PedidosDivergentesResumo.View'
import { useHistory } from 'react-router-dom'

export function pedidosDivergentesResumoIO({ divergencias }: PedidosDivergentesResumoProps) {
  const [abrirDivergentes, setAbrirDivergentes] = useState(false)
  const router = useHistory()

  const gerarDatasLegendaY = () => {
    const datasLegendaY: string[][] = []

    divergencias.valoresSemanais &&
      divergencias.valoresSemanais.forEach((divergenciaSemanal) => {
        const legenda: string[] = []
        const dataInicio = new Date(divergenciaSemanal.dataInicio).getDate()
        const dataFim = new Date(divergenciaSemanal.dataFim).getDate()

        if (divergenciaSemanal.mesInicio === divergenciaSemanal.mesFim) {
          legenda.push(`${dataInicio} a ${dataFim}`)
          legenda.push(`de ${divergenciaSemanal.mesInicio}`)
        } else {
          legenda.push(`${dataInicio} de ${divergenciaSemanal.mesInicio}`)
          legenda.push(`a ${dataFim} de ${divergenciaSemanal.mesFim}`)
        }

        datasLegendaY.push(legenda)
      })

    return datasLegendaY
  }

  return {
    styles: pedidosDivergentesResumoStyles,
    abrirDivergentes,
    setAbrirDivergentes,
    router,
    options: {
      chart: {
        type: 'bar',
        toolbar: {
          show: false,
          tools: {
            download: false
          }
        },
        animations: {
          enabled: true
        },
        redrawOnWindowResize: false
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          columnWidth: '45%'
        }
      },
      legend: {
        show: false
      },
      xaxis: {
        categories: gerarDatasLegendaY(),
        labels: {
          style: {
            fontSize: '16px',
            fontFamily: customTheme.fontFamily.rubik400
          }
        }
      },
      yaxis: {
        labels: {
          formatter: function (value: number) {
            return value.toLocaleString('pt-BR')
          },
          style: {
            fontSize: '16px',
            fontFamily: customTheme.fontFamily.rubik400
          }
        }
      },
      fill: {
        opacity: 1
      },
      colors: [
        function () {
          return customTheme.colors.orange03
        }
      ],
      tooltip: {
        y: {
          formatter: function (val: number) {
            return val.toLocaleString('pt-BR')
          }
        }
      },
      dataLabels: {
        enabled: false
      }
    } as ApexOptions,
    series: [
      {
        name: '',
        data: divergencias.valoresSemanais ? divergencias.valoresSemanais.map((x) => x.quantidade) : [0]
      }
    ]
  }
}

export type PedidosDivergentesResumoIO = ReturnType<typeof pedidosDivergentesResumoIO>
