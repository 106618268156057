import { IOProps } from 'react-compose-io'
import { PopoverFiltroClassificacoesIO } from './PopoverFiltroClassificacoes.IO'
import { TextField } from '@material-ui/core'
import { SinoIcon } from './../../assets/icons/SinoIcon'
import { ClassificacaoDebito } from '../../types/debitosTypes'
import { CheckListItem } from '../CheckListItem'

export type PopoverFiltroClassificacoesProps = {
  popoverAberto: null | HTMLElement
  handleClosePopover: () => void
  classificacoes: ClassificacaoDebito[]
  isClassificacaoChecked: (classificacao: string) => boolean
  handleCheckClassificacao: (classificacao: string) => void
}

export function PopoverFiltroClassificacoesView({
  _io,
  popoverAberto,
  handleClosePopover,
  classificacoes,
  isClassificacaoChecked,
  handleCheckClassificacao
}: IOProps<PopoverFiltroClassificacoesIO, PopoverFiltroClassificacoesProps>) {
  const { ContainerPopover, Popover } = _io.styles

  return (
    <Popover
      keepMounted
      anchorEl={popoverAberto}
      open={Boolean(popoverAberto)}
      onClose={handleClosePopover}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
    >
      <ContainerPopover>
        <TextField onChange={_io.handleChangeClassificacoesInput} label="Buscar classificação" variant="outlined" size="small" fullWidth />
        {classificacoes.filter(_io.filtrarClassificacoes).map(_io.renderParceirosFiltradas)}
        <div>
          {_io.isAguardandoAprovacaoFiltrado && (
            <CheckListItem
              key={_io.AGUARDANDO_APROVACAO}
              id={_io.AGUARDANDO_APROVACAO}
              texto={_io.AGUARDANDO_APROVACAO}
              handleCheck={() => handleCheckClassificacao(_io.AGUARDANDO_APROVACAO)}
              isChecked={isClassificacaoChecked(_io.AGUARDANDO_APROVACAO)}
              Icon={() => <SinoIcon />}
            />
          )}
        </div>
        <div>
          <CheckListItem
            key={'Vazio'}
            id={'Vazio'}
            texto={'Vazio'}
            handleCheck={() => handleCheckClassificacao('Vazio')}
            isChecked={isClassificacaoChecked('Vazio')}
          />
        </div>
      </ContainerPopover>
    </Popover>
  )
}
