import { Grid } from '@material-ui/core'
import styled from 'styled-components'

export const GridCheckListStyles = {
  GridItem: styled(Grid).attrs({
    item: true
  })`
    margin-left: 20px;
    white-space: nowrap;
  `
}
