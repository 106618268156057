import { Grid, InputLabel, MenuItem, Select } from '@material-ui/core'
import { IOProps } from 'react-compose-io'
import { Control, Controller, DeepMap, FieldError } from 'react-hook-form'
import { FormularioTipoLancamento, Sequencias } from '../../../../types/tipoLancamentoApiTypes'
import { DadosTiposLancamentosIO } from './DadosTiposLancamentos.IO'
import { Botao } from './../../../../components/Botao/index'

export type DadosTiposLancamentosProps = {
  lancamento?: string
  telaMenor: boolean
  modaAtivo?: boolean
  fecharModal?: unknown
  submeterTipoLancamento?: () => void
  controleFormulario?: Control<FormularioTipoLancamento>
  clicarSequenciaFormulario?: (sequenciaItem: Sequencias) => void
  erroPreenchimento?: DeepMap<FormularioTipoLancamento, FieldError>
}

export function DadosTiposLancamentosView({
  _io,
  telaMenor,
  submeterTipoLancamento,
  controleFormulario,
  clicarSequenciaFormulario,
  erroPreenchimento
}: IOProps<DadosTiposLancamentosIO, DadosTiposLancamentosProps>) {
  const { Texto, FormularioControle, TextoErroPreenchimento } = _io.styles

  return (
    <form onSubmit={submeterTipoLancamento}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4} md={4} lg={telaMenor ? 2 : 4}>
          <Controller
            render={({ field }) => <Texto {...field} label="Descrição" error={_io.verificarErros(erroPreenchimento, erroPreenchimento?.descricao)} />}
            name="descricao"
            control={controleFormulario}
          />
          {erroPreenchimento != undefined && erroPreenchimento.descricao && (
            <TextoErroPreenchimento>{erroPreenchimento.descricao.message}</TextoErroPreenchimento>
          )}
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={telaMenor ? 2 : 4}>
          <Controller
            render={({ field }) => (
              <FormularioControle error={_io.verificarErros(erroPreenchimento, erroPreenchimento?.referencia)}>
                <InputLabel>Referência</InputLabel>
                <Select {...field} labelId="referenciaId" id="referencia" label="Referência">
                  <MenuItem key={'V'} value={'Venda'}>
                    {'Venda'}
                  </MenuItem>
                  <MenuItem key={'C'} value={'Comissão'}>
                    {'Comissão'}
                  </MenuItem>
                  <MenuItem key={'F'} value={'Frete'}>
                    {'Frete'}
                  </MenuItem>
                  <MenuItem key={'O'} value={'Outro'}>
                    {'Outro'}
                  </MenuItem>
                </Select>
              </FormularioControle>
            )}
            name="referencia"
            control={controleFormulario}
          />
          {erroPreenchimento != undefined && erroPreenchimento.referencia && (
            <TextoErroPreenchimento>{erroPreenchimento.referencia.message}</TextoErroPreenchimento>
          )}
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={telaMenor ? 2 : 4}>
          <Controller
            render={({ field }) => (
              <FormularioControle variant="outlined" size="small" fullWidth error={_io.verificarErros(erroPreenchimento, erroPreenchimento?.sequencia)}>
                <InputLabel id="sequenciaId">Sequencia</InputLabel>
                <Select {...field} labelId="sequenciaId" id="sequencia" label="Sequencia">
                  {_io.sequencias?.map((item: Sequencias) => (
                    <MenuItem key={item?.sequencia} value={item.sequencia || ''} onClick={() => clicarSequenciaFormulario && clicarSequenciaFormulario(item)}>
                      {item.sequencia || ''}
                    </MenuItem>
                  ))}
                </Select>
              </FormularioControle>
            )}
            name="sequencia"
            control={controleFormulario}
          />
          {erroPreenchimento != undefined && erroPreenchimento.sequencia && (
            <TextoErroPreenchimento>{erroPreenchimento.sequencia.message}</TextoErroPreenchimento>
          )}
        </Grid>
        <Grid item xs={12} sm={4} md={3} lg={telaMenor ? 2 : 4}>
          <Controller render={({ field }) => <Texto {...field} label="N° Conta" disabled />} name="conta" control={controleFormulario} />
        </Grid>
        <Grid item xs={12} sm={4} md={3} lg={telaMenor ? 2 : 4}>
          <Controller render={({ field }) => <Texto {...field} label="Natureza" disabled />} name="natureza" control={controleFormulario} />
        </Grid>
        <Grid item xs={12} sm={4} md={3} lg={telaMenor ? 1 : 4}>
          <Controller render={({ field }) => <Texto {...field} label="Tipo" disabled />} name="tipo" control={controleFormulario} />
        </Grid>
        {telaMenor ? (
          <Grid item xs={12} sm={3} md={1} lg={1}>
            <Botao onClick={submeterTipoLancamento} textoBotao={'Adicionar'} cor="primaria" estilo="contained" />
          </Grid>
        ) : null}
      </Grid>
    </form>
  )
}
