import { Backdrop, Fade } from '@material-ui/core'
import { IOProps } from 'react-compose-io'
import { TipoLancamentoParceiro } from '../../../../types/parceiroApiTypes'
import { DadosTipoLancamento } from '../DadosTipoLancamento/index'
import { ModalAdicionarTipoLancamento } from './ModalAdicionarTipoLancamento.IO'

export type ModalAdicionarTipoLancamentoProps = {
  tipoLancamentoArray: TipoLancamentoParceiro[]
  fecharModal: () => void
  abrirModal: boolean
  submeterTipoLancamento: (e: TipoLancamentoParceiro) => void
  tituloModal: string
}

export function ModalAdicionarTipoLancamentoView({
  _io,
  fecharModal,
  abrirModal,
  tipoLancamentoArray,
  tituloModal
}: IOProps<ModalAdicionarTipoLancamento, ModalAdicionarTipoLancamentoProps>) {
  const { ModalContainer, Paper, BoxContainer, TextSpan, Close, BoxColorBlue } = _io.styles
  return (
    <div>
      <ModalContainer
        open={abrirModal}
        onClose={fecharModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={abrirModal}>
          <Paper>
            <BoxContainer>
              <TextSpan>{tituloModal}</TextSpan>
              <Close onClick={fecharModal} />
            </BoxContainer>
            <BoxColorBlue>
              <DadosTipoLancamento
                tipoLancamento={tipoLancamentoArray}
                handleClose={fecharModal}
                handleSubmitForm={_io.handleSubmit(_io.submeterTipoLancamentoModal)}
                controlForm={_io.control}
                handleChangeForm={_io.handleMudarTipoLancamentoSelect}
                handleErros={_io.errors}
              />
            </BoxColorBlue>
          </Paper>
        </Fade>
      </ModalContainer>
    </div>
  )
}
