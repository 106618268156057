import { useCallback, useEffect, useState } from 'react'
import { ResponseDebitos } from '../../types/debitosTypes'
import { controleDebitosStyles } from './ControleDebitos.styles'
import controleDebitosManager from '../../services/api/debitos/debitosManager'
import { toast } from 'react-toastify'
import msgConstants from '../../constants/msgConstants'
import { useHistory } from 'react-router-dom'
import { StatusProcessamento } from '../../types/genericoType'
import { Transportadora } from '../../types/transportadoras'
import PolegarPositivoIcon from '../../assets/icons/polegarPositivoIcone.svg'
import PolegarNegarivoIcon from '../../assets/icons/polegarNegativoIcone.svg'
import { SombraTipo } from '../../components/ContainerSombra'

export function controleDebitosIO() {
  const [controleDebitosResponse, setControleDebitosResponse] = useState<ResponseDebitos>({} as ResponseDebitos)
  const [parceiroTransportadoras, setParceiroTransportadoras] = useState<Transportadora[]>([])
  const [parceiroSelecionado, setParceiroSelecionado] = useState(0)
  const [valorSetTimeOut, setValorSetTimeOut] = useState<number>(0)
  const [carregando, setCarregando] = useState(false)
  const history = useHistory()

  useEffect(() => {
    obterControleDebitos()
  }, [])

  useEffect(() => {
    return history.listen(() => {
      clearTimeout(valorSetTimeOut)
    })
  }, [valorSetTimeOut])

  const obterControleDebitos = useCallback(async () => {
    setCarregando(true)
    await controleDebitosManager
      .buscarControleDebitos()
      .then((response: ResponseDebitos) => {
        const status = response.processamento.status
        setControleDebitosResponse(response)

        if (status === StatusProcessamento.PROCESSANDO) {
          const atualizarStatus = setTimeout(() => {
            obterControleDebitos()
          }, 10000)
          setValorSetTimeOut(Number(atualizarStatus))
        } else {
          clearTimeout(valorSetTimeOut)
          setCarregando(false)
        }

        if (status === StatusProcessamento.NOVO) {
          toast.error(msgConstants.REGULARIZACAO.erroAoBuscarPendencias)
        }
      })
      .catch(() => {
        toast.error(msgConstants.USO_GENERICO.erroBusca)
      })
  }, [controleDebitosResponse])

  function gerarDadosControleDebitos() {
    setCarregando(true)
    controleDebitosManager
      .gerarDadosControleDebitos()
      .then(() => {
        obterControleDebitos()
      })
      .catch((e: Error) => {
        toast.error(e.message)
      })
  }

  function handleFecharChip() {
    setParceiroSelecionado(0)
    setParceiroTransportadoras([])
  }

  const resumosRepasse =
    parceiroSelecionado === 0
      ? controleDebitosResponse.resumosRepasse?.resumoDebitosTotal
      : controleDebitosResponse.resumosRepasse?.resumosDebitosPorParceiro.filter((x) => x.parceiroId === parceiroSelecionado)[0]

  const resumosDebitoInicial =
    parceiroSelecionado === 0
      ? controleDebitosResponse.resumosDebitoInicial?.resumoDebitosTotal
      : controleDebitosResponse.resumosDebitoInicial?.resumosDebitosPorParceiro.filter((x) => x.parceiroId === parceiroSelecionado)[0]

  const resumosDebitoInicialPorcentagem =
    parceiroSelecionado === 0
      ? controleDebitosResponse.resumosDebitoInicial
      : controleDebitosResponse.resumosDebitoInicial?.resumosDebitosPorParceiro.filter((x) => x.parceiroId === parceiroSelecionado)[0]

  const resumoDebitosParceiroTransportadora = controleDebitosResponse.resumosDebitoInicial?.resumosDebitosPorParceiro.filter(
    (x) => x.parceiroId === parceiroSelecionado
  )[0]

  const resumosDebitoFinal =
    parceiroSelecionado === 0
      ? controleDebitosResponse.resumoDebitoFinal?.resumoDebitosTotal
      : controleDebitosResponse.resumoDebitoFinal?.resumosDebitosPorParceiro.filter((x) => x.parceiroId === parceiroSelecionado)[0]

  const resumosDebitoFinalPorcentagem =
    parceiroSelecionado === 0
      ? controleDebitosResponse.resumoDebitoFinal
      : controleDebitosResponse.resumoDebitoFinal?.resumosDebitosPorParceiro.filter((x) => x.parceiroId === parceiroSelecionado)[0]

  const resumosRecuperacao =
    parceiroSelecionado === 0
      ? controleDebitosResponse.recuperacao
      : controleDebitosResponse.recuperacao?.resumosDebitosPorParceiro.filter((x) => x.parceiroId === parceiroSelecionado)[0]

  function iconeDebitoFinalAcumulado(): { sombra: SombraTipo; icon: string } {
    if (controleDebitosResponse.resumoDebitoFinal?.porcentagemTotalAcumulada > controleDebitosResponse.resumoDebitoFinal?.meta)
      return { sombra: 'tipo2', icon: PolegarNegarivoIcon }

    return { sombra: 'tipo3', icon: PolegarPositivoIcon }
  }

  function handleClickParceiro(parceiroId: number) {
    setParceiroSelecionado(parceiroId)
  }

  return {
    styles: controleDebitosStyles,
    controleDebitosResponse,
    handleClickParceiro,
    parceiroSelecionado,
    setParceiroTransportadoras,
    gerarDadosControleDebitos,
    carregando,
    parceiroTransportadoras,
    handleFecharChip,
    history,
    iconeDebitoFinalAcumulado,
    resumosDebitoInicial,
    resumosRepasse,
    resumosDebitoInicialPorcentagem,
    resumoDebitosParceiroTransportadora,
    resumosDebitoFinalPorcentagem,
    resumosDebitoFinal,
    resumosRecuperacao
  }
}

export type ControleDebitosIO = ReturnType<typeof controleDebitosIO>
