import { Box, Modal, Typography } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import styled from 'styled-components'
import { customTheme } from '../../../../styles/customTheme'

export const modalAddContaStyles = {
  ModalContainer: styled(Modal).attrs({
    ariaLabelledby: 'transition-modal-title'
  })`
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
  `,
  BoxContainer: styled(Box)`
    padding: 16px;
    display: flex;
    justify-content: space-between;
  `,
  Paper: styled.div`
    width: 80%;
    background-color: ${customTheme.colors.white01};
  `,
  TextSpan: styled(Typography).attrs({
    variant: 'h6'
  })`
    font-weight: 900;
    color: ${customTheme.colors.grey04};
  `,
  Close: styled(CloseIcon)`
    cursor: pointer;
  `,
  BoxColorBlue: styled(Box)`
    padding: 19px;
  `
}
