import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import { IOProps } from 'react-compose-io'
import { CustomBreadcrumbsIO } from './CustomBreadcrumbs.IO'
import { Tipografia } from './../Tipografia/index'
import { customTheme } from './../../styles/customTheme'

export const CustomBreadcrumbsView = ({ _io }: IOProps<CustomBreadcrumbsIO, any>) => {
  const { Breadcrumbs, Link } = _io.styles

  return (
    <>
      <Breadcrumbs separator={<ChevronRightIcon />}>
        {_io.rotas.map((rota: { name: string; path: string }, index: number) => {
          return index + 1 === _io.rotas.length ? (
            <div key={index}>
              <Tipografia variant="subtitle1" colorText={customTheme.colors.blue16}>
                {rota.name}
              </Tipografia>
            </div>
          ) : (
            <Link key={index} to={rota.path}>
              {rota.name}
            </Link>
          )
        })}
      </Breadcrumbs>
    </>
  )
}
