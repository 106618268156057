import { ResponsePaginado } from '../../../types/genericoType'
import { GerarRelatorioOPsEmAbertoResponse, ObterOPsEmAbertoQuery, OpsEmAberto, OpsEmAbertoResumo } from '../../../types/regularizacaoOpsTypes'
import { HistoricoRegularizacao, ValidacaoPlanilhaResponse } from '../../../types/regularizacaoTypes'
import { Paginacao } from '../../../types/tabelaTypes'
import { concatenarFiltrosQuery } from '../../../utils/stringUtils'
import api from '../../axiosInstance'

const efetivarRegularizacaoOPs = async (arquivoId: string): Promise<boolean> => {
  const URL_REGULARIZAR_OPS = `RegularizacaoOps/Efetivar/${arquivoId}`
  const response = await api.conectarPost<boolean>(URL_REGULARIZAR_OPS)
  return response.data
}

const validarPlanilhaRegularizacaoOPs = async (arquivo: FormData): Promise<ValidacaoPlanilhaResponse> => {
  const URL_VALIDAR_PLANILHA = `/RegularizacaoOps/ValidarOps`
  const response = await api.conectarPost<ValidacaoPlanilhaResponse>(URL_VALIDAR_PLANILHA, arquivo, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })

  return response.data
}

const buscarHistoricoRegularizacaoOPs = async (paginacao: Paginacao): Promise<ResponsePaginado<HistoricoRegularizacao>> => {
  const URL_BUSCAR_HISTORICO = `/RegularizacaoOps/Historico${concatenarFiltrosQuery({
    pageNumber: paginacao.pageNumber,
    pageSize: paginacao.pageSize,
    orderField: 'historico.dataReferencia',
    orderBy: 'desc'
  })}`
  const response = await api.conectarGet<ResponsePaginado<HistoricoRegularizacao>>(URL_BUSCAR_HISTORICO)
  return response.data
}

const buscarOPsEmAberto = async (paginacao: Paginacao, query: ObterOPsEmAbertoQuery): Promise<ResponsePaginado<OpsEmAberto>> => {
  const URL_BUSCAR_OPS_EM_ABERTO = `/RegularizacaoOps/Obter${concatenarFiltrosQuery({
    pageNumber: paginacao.pageNumber,
    pageSize: paginacao.pageSize,
    orderField: paginacao.orderField,
    orderBy: paginacao.orderBy,
    dataFimEmissao: query.dataFimEmissao,
    dataInicioEmissao: query.dataInicioEmissao,
    parceiroId: query.parceiroId
  })}`
  const response = await api.conectarGet<ResponsePaginado<OpsEmAberto>>(URL_BUSCAR_OPS_EM_ABERTO)
  return response.data
}

const buscarOPsEmAbertoResumo = async (): Promise<OpsEmAbertoResumo> => {
  const URL_BUSCAR_OPS_EM_ABERTO_RESUMO = `/RegularizacaoOps/Obter/Resumo`
  const response = await api.conectarGet<OpsEmAbertoResumo>(URL_BUSCAR_OPS_EM_ABERTO_RESUMO)
  return response.data
}

const gerarRelatorioOPsEmAberto = async (query: ObterOPsEmAbertoQuery): Promise<GerarRelatorioOPsEmAbertoResponse> => {
  const URL_BUSCAR_OPS_EM_ABERTO = `/RegularizacaoOps/GerarRelatorioOpsEmAberto${concatenarFiltrosQuery({
    dataFimEmissao: query.dataFimEmissao,
    dataInicioEmissao: query.dataInicioEmissao,
    parceiroId: query.parceiroId
  })}`
  const response = await api.conectarPost<GerarRelatorioOPsEmAbertoResponse>(URL_BUSCAR_OPS_EM_ABERTO)
  return response.data
}

export default {
  buscarHistoricoRegularizacaoOPs,
  validarPlanilhaRegularizacaoOPs,
  efetivarRegularizacaoOPs,
  buscarOPsEmAberto,
  buscarOPsEmAbertoResumo,
  gerarRelatorioOPsEmAberto
}
