import { yupResolver } from '@hookform/resolvers/yup'
import React from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { FormValuesSequencias } from '../../../../types/iniciarNovoCicloTypes'
import { modalAddContaStyles } from './ModalAddConta.style'
import { ModalAddContaProps } from './ModalAddConta.View'

export function modalAddContaIO({ submeterValoresSequencias }: ModalAddContaProps) {
  const defaultValues = {
    sequenciaId: '',
    conta: '',
    natureza: '',
    tipo: '',
    numeroSequenciaId: 1
  }
  const schema = yup.object().shape({
    numeroSequenciaId: yup.number().required()
  })
  const {
    handleSubmit,
    control,
    setValue,
    reset,
    formState: { errors }
  } = useForm<FormValuesSequencias>({
    resolver: yupResolver(schema),
    defaultValues
  })

  const onSubmit = (data: FormValuesSequencias) => {
    submeterValoresSequencias(data)
    reset()
  }

  const handleChangeForm = (event: React.ChangeEvent<{ name?: string | number; value: unknown | number | string }>) => {
    const { value } = event.target
    const valorTipoA = String(value).split(' ')[2] == 'A' ? 'Analítico' : 'Sintético'
    const naturezaValorD = String(value).split(' ')[1] == 'D' ? 'Débito' : 'Crédito'

    setValue('numeroSequenciaId', Number(value))
    setValue('sequenciaId', typeof value === 'string' ? value : '')
    setValue('conta', typeof value === 'string' ? value.split(' ')[3] : '')
    setValue('natureza', typeof value === 'string' ? naturezaValorD : '')
    setValue('tipo', typeof value === 'string' ? valorTipoA : '')
  }

  return {
    styles: modalAddContaStyles,
    handleSubmit,
    control,
    handleChangeForm,
    onSubmit,
    errors
  }
}

export type ModalAddContaIO = ReturnType<typeof modalAddContaIO>
