import { Backdrop, Box, Paper, TextField } from '@material-ui/core'
import styled from 'styled-components'
import { ContainerSombra } from '../../../../components/ContainerSombra'
import { customTheme } from '../../../../styles/customTheme'

export const cardExpansivelStyles = {
  Paper: styled(ContainerSombra)`
    padding: 20px;
    margin: 5px;
    margin-bottom: 25px;
  `,
  TagStyled: styled(Box).attrs((props: { color: string; bgcolor: string }) => props)`
    color: ${(props) => props.color};
    background: ${(props) => props.bgcolor};
    display: flex;
    align-items: center;
    height: 23px;
    padding: 20px;
    border-radius: 5px;
    margin: 4px 0px 4px 4px;
  `,
  DividerVertical: styled.div`
    width: 1px;
    height: 80px;
    background: ${customTheme.colors.grey04};
  `,
  TabelaContainer: styled(Paper)`
    position: relative;
    box-shadow: none;
  `,
  LimitedBackdrop: styled(Backdrop)`
    z-index: 1;
    position: absolute;
    border-radius: 4px;
  `,
  InputNumerico: styled(TextField)`
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type='number'] {
      -moz-appearance: textfield;
    }
  `
}
