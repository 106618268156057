import { CircularProgress, Grid, Typography } from '@material-ui/core'
import { IOProps } from 'react-compose-io'
import { BoxValores } from '../../../../components/BoxValores/index'
import DataEmpty from '../../../../components/DataEmpty/DataEmpty'
import { ModalBuscarOPS } from '../../components/ModalBuscarOPS/index'
import { TabelaOPS } from '../TabelaOPS/index'
import { VincularOPSIO } from './VincularOPS.IO'
import { Botao } from './../../../../components/Botao/index'

export type VincularOPSProps = {
  cicloId: string
  setEnableConfirmar: React.Dispatch<React.SetStateAction<boolean>>
  setOpsSucesso(success: boolean): void
}

function VincularOPSView({ _io }: IOProps<VincularOPSIO, VincularOPSProps>) {
  const { ContainerFlex, ContainerAdicionarOPs, Container } = _io.styles

  const opsMenorZero =
    _io.ops.length > 0 ? <TabelaOPS data={_io.ops} cicloId={_io.cicloId} atualizarLista={_io.atualizaOP} /> : <DataEmpty descricao="Nenhuma OP inserida" />

  return (
    <Container>
      <ModalBuscarOPS
        atualizarLista={_io.atualizaOP}
        cicloId={_io.cicloId}
        inserirOp={_io.openOPS}
        fecharModal={_io.fecharModalOPS}
        totalRecebidaCiclo={_io.total}
      />

      <Grid container justifyContent="space-between">
        <Grid item>
          <Typography variant="h6">Vincular OP&apos;s</Typography>
        </Grid>
        <Grid item>
          <Botao onClick={_io.abrirModalOPS} textoBotao="Inserir OP" cor="primaria" estilo="contained" width="150px" />
        </Grid>
      </Grid>
      {!_io.loading ? (
        <ContainerAdicionarOPs>
          <Typography>Adicione as OP`s que serão vinculadas a este ciclo</Typography>
          <Container>{opsMenorZero}</Container>
          {_io.ops.length > 0 && (
            <Grid container spacing={2}>
              <Grid item>
                <BoxValores campoTexto="Total de Op's" valor={_io.totalOps ?? 0} possuiCor={false} />
              </Grid>
              <Grid item>
                <BoxValores campoTexto="Total recebido do ciclo" valor={_io.total ?? 0} possuiCor />
              </Grid>
              <Grid item>
                <BoxValores campoTexto="Diferença Recebido x OP" valor={_io.diferenca ?? 0} possuiCor={false} />
              </Grid>
            </Grid>
          )}
        </ContainerAdicionarOPs>
      ) : (
        <ContainerFlex>
          <CircularProgress />
        </ContainerFlex>
      )}
    </Container>
  )
}
export default VincularOPSView
