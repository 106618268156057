import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import msgConstants from '../../../../constants/msgConstants'
import parceiroManager from '../../../../services/api/parceiro/parceiroManager'
import { loadingReset, loadingUpdate } from '../../../../store/actions/loadingAction'
import { TipoLancamentoParceiro } from '../../../../types/parceiroApiTypes'
import { itemConfiguracoesGeraisStyles } from '../../../ConfiguracoesGerais/components/ItemConfiguracoesGerais/ItemConfiguracoesGerais.style'
import { ItemTipoLancamentoProps } from './ItemTipoLancamento.View'

export function itemTipoLancamentoIO({ parceiroId, atualizarDados }: ItemTipoLancamentoProps) {
  const dispatch = useDispatch()
  const [abrirModalEditar, setAbrirModalEditar] = useState(false)
  const [ativarIconeAncora, setAtivarIconeAncora] = useState<null | HTMLElement>(null)

  const handleAtivarIconeAncora = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAtivarIconeAncora(event.currentTarget)
  }

  const handleFecharIconeAncora = () => {
    setAtivarIconeAncora(null)
  }

  function abrirModalEdicao() {
    setAbrirModalEditar(true)
  }

  function fecharModalEdicao() {
    setAbrirModalEditar(false)
  }

  const handleAtivarIconeAncoraModalEdicao = () => {
    setAtivarIconeAncora(null)
    abrirModalEdicao()
  }

  const salvarEdicaoTipoLancamentoParceiro = (item: TipoLancamentoParceiro) => {
    dispatch(loadingUpdate(true))
    setAtivarIconeAncora(null)
    const dataEdit = { ...item }
    dataEdit.ativo = !dataEdit.ativo

    parceiroManager
      .editarTipolancamentoParceiros(parceiroId, item.tipoLancamentoParceiroId, dataEdit)
      .then(() => {
        toast.success(msgConstants.USO_GENERICO.edicao_sucesso)
        atualizarDados()
      })
      .catch((error: Error) => {
        toast.error(error.message)
      })
      .finally(() => {
        dispatch(loadingReset())
      })
  }

  const getTipoContabilizacaoId = (tipo?: string) => (tipo === 'F' ? 'Fixo' : 'Variável')

  return {
    styles: itemConfiguracoesGeraisStyles,
    abrirModalEditar,
    fecharModalEdicao,
    getTipoContabilizacaoId,
    handleAtivarIconeAncoraModalEdicao,
    ativarIconeAncora,
    handleAtivarIconeAncora,
    handleFecharIconeAncora,
    salvarEdicaoTipoLancamentoParceiro
  }
}
export type ItemTipoLancamentoIO = ReturnType<typeof itemTipoLancamentoIO>
