import { Grid, Typography } from '@material-ui/core'
import styled from 'styled-components'
import { customTheme } from '../../styles/customTheme'

export const itensRegularizacaoContaResumoStyles = {
  ContainerContasResumo: styled(Grid).attrs((props: { corFundo: string }) => props)`
    background: ${(props) => props.corFundo};
    border-radius: 4px;
    margin-bottom: 8px;
    padding: 4px 10px;
    display: flex;
  `,
  ValorRotulo: styled(Typography).attrs({
    variant: 'subtitle2'
  })`
    font-weight: 500;
    color: ${customTheme.colors.grey09};
    margin: 0 5px;
  `,
  ValorTexto: styled(Typography).attrs({
    variant: 'body2'
  })`
    font-style: normal;
    font-weight: 400;
  `,
  ContainerContaResumo: styled(Grid).attrs({
    item: true,
    xs: 12,
    sm: 12,
    md: 6,
    lg: 6
  })`
    display: flex;
    align-items: center;
    text-align: start;
  `,
  ContainerInformacoesValor: styled(Grid).attrs({
    item: true,
    xs: 12,
    sm: 12,
    md: 3,
    lg: 3
  })`
    display: flex;
    align-items: center;
  `
}
