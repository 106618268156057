import { combineReducers } from 'redux'
import lancamentoReducer from '../reducers/lancamentoReducer'
import loadingReducer from './LoadingReducer'

const rootReducer = combineReducers({
  lancamento: lancamentoReducer,
  loading: loadingReducer
})

export default rootReducer

export type RootState = ReturnType<typeof rootReducer>
