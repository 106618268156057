import { IOProps } from 'react-compose-io'
import { TransitoBoxIO } from './TransitoBox.IO'
import AtendimentoIcon from '../../../../assets/icons/atendimentoIcone.svg'
import LogisticaIcon from '../../../../assets/icons/logisticaIcone.svg'
import LogisticaParceiroIcon from '../../../../assets/icons/logisticaParceiro.svg'
import { CabecalhoExportarRelatorio } from '../../../../components/CabecalhoExportarRelatorio'
import { GraficoValorQuantidade } from './../../../../components/Debitos/GraficoValorQuantidade/index'
import { CabecalhoIconeExportarRelatorio } from '../../../../components/CabecalhoIconeExportarRelatorio'
import { ResumoDebitosParceiroResponse, ResumoDebitosGeralResponse } from '../../../../types/debitosTypes'
import { legendaMesGrafico, valoresGrafico } from '../../../../utils/debitoGraficos'
import { VALOR_ESCALA_GRAFICO } from '../../../../constants/utilConstants'
import { INADIMPLENCIA } from '../../../../types/controleInadimplenciaTypes'

export type TransitoBoxProps = {
  urlRelatorio: string
  atendimento: ResumoDebitosParceiroResponse | ResumoDebitosGeralResponse
  logisticaMartins: ResumoDebitosParceiroResponse | ResumoDebitosGeralResponse
  logisticaParceiro: ResumoDebitosParceiroResponse | ResumoDebitosGeralResponse
  parceiroId: number
}

export function TransitoBoxView({ _io, urlRelatorio, atendimento, logisticaMartins, logisticaParceiro }: IOProps<TransitoBoxIO, TransitoBoxProps>) {
  const { Box } = _io.styles

  return (
    <Box>
      <CabecalhoExportarRelatorio
        url={urlRelatorio}
        tituloCabecalho={'Em trânsito'}
        textoTooltip={`O painel Em Trânsito aponta débitos que estão em análise e tem possibilidade
                        de recuperação pela área responsável.`}
      />

      <Box maxwidth="1000px">
        <CabecalhoIconeExportarRelatorio url={atendimento.urlRelatorio} icone={AtendimentoIcon} descricao={'Backoffice'} />
        <GraficoValorQuantidade
          tituloGrafico={`Valor mensal em trânsito - backoffice`}
          valoresMensais={valoresGrafico(atendimento.valoresMensais)}
          legendaMeses={legendaMesGrafico(atendimento.valoresMensais)}
          legendaEixoY="Milhares"
          divisorLegenda={VALOR_ESCALA_GRAFICO.MIL}
          descricaoValor={`Valor total em trânsito`}
          valor={atendimento.valor}
          descricaoQuantidade={`Quantidade total de pedidos em trânsito `}
          quantidade={atendimento.quantidade}
          width="calc(100% + 64px)"
          handleVerMaisDetalhesPedidoBotao={() => _io.handleVerMaisDetalhesPedidoBotao(INADIMPLENCIA.BACKOFFICE)}
        />
      </Box>

      <Box maxwidth="1000px">
        <CabecalhoIconeExportarRelatorio url={logisticaMartins.urlRelatorio} icone={LogisticaIcon} descricao={'Logística - Frota própria ou terceirizada'} />
        <GraficoValorQuantidade
          tituloGrafico={`Valor mensal em trânsito - logística`}
          valoresMensais={valoresGrafico(logisticaMartins.valoresMensais, 'martins')}
          legendaMeses={legendaMesGrafico(logisticaMartins.valoresMensais)}
          legendaEixoY="Milhares"
          divisorLegenda={VALOR_ESCALA_GRAFICO.MIL}
          descricaoValor={`Valor total em trânsito`}
          valor={logisticaMartins.valorTransportadoraMartins}
          descricaoQuantidade={`Quantidade total de pedidos em trânsito`}
          quantidade={logisticaMartins.quantidadeTransportadoraMartins}
          width="calc(100% + 64px)"
          handleVerMaisDetalhesPedidoBotao={() => _io.handleVerMaisDetalhesPedidoBotao(INADIMPLENCIA.EM_TRANSITO_LOGISTICA_MARTINS)}
        />
      </Box>

      {logisticaParceiro.quantidadeTransportadoraParceira > 0 && (
        <Box maxwidth="1000px">
          <CabecalhoIconeExportarRelatorio
            url={logisticaParceiro.urlRelatorio}
            icone={LogisticaParceiroIcon}
            descricao={'Logística - Transportadoras dos parceiros'}
          />
          <GraficoValorQuantidade
            tituloGrafico={`Valor mensal em trânsito - logística`}
            valoresMensais={valoresGrafico(logisticaParceiro.valoresMensais, 'parceiro')}
            legendaMeses={legendaMesGrafico(logisticaParceiro.valoresMensais)}
            legendaEixoY="Milhares"
            divisorLegenda={VALOR_ESCALA_GRAFICO.MIL}
            descricaoValor={`Valor total em trânsito`}
            valor={logisticaParceiro.valorTransportadoraParceira}
            descricaoQuantidade={`Quantidade total de pedidos em trânsito`}
            quantidade={logisticaParceiro.quantidadeTransportadoraParceira}
            width="calc(100% + 64px)"
            handleVerMaisDetalhesPedidoBotao={() => _io.handleVerMaisDetalhesPedidoBotao(INADIMPLENCIA.EM_TRANSITO_LOGISTICA_PARCEIRO)}
          />
        </Box>
      )}
    </Box>
  )
}
