import { useForm } from 'react-hook-form'
import { relatorioConciliacaoStyles } from './RelatorioConciliacao.styles'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useEffect, useState } from 'react'
import { ContaContabil, RelatorioConciliacaoFiltros } from '../../types/relatorioConciliacaoTypes'
import { NuvemUpload } from '../../assets/icons/nuvemUpl'
import { customTheme } from './../../styles/customTheme'
import { Check } from '../../assets/icons/Check'
import RelatorioConciliacaoManager from '../../services/api/relatorioConciliacao/relatorioConciliacaoManager'
import { toast } from 'react-toastify'
import { ExportIcon } from '../../assets/icons/ExportIcon'
import { Parceiro } from '../../types/parceiroApiTypes'
import parceiroManager from '../../services/api/parceiro/parceiroManager'

const schema = yup.object({
  dataFim: yup.string().test('valorMinimo', `Data final deve ser maior que a inicial.`, function (dataFim, form) {
    const dataInicial = '' + form.parent.dataInicio
    const dataFinal = '' + dataFim

    if ((dataFim != '' && form.parent.dataInicio == '') || (dataFim == '' && form.parent.dataInicio != '')) return false

    if (form.parent.dataInicio == '' || dataFim == '') return true

    return new Date(dataInicial) <= new Date(dataFinal)
  })
})

export function relatorioConciliacaoIO() {
  const [filtrosPreenchidos, setFiltrosPreenchidos] = useState(true)
  const [habilitarBotaoUploadPlanilha, setHabilitarBotaoUploadPlanilha] = useState(true)
  const [carregarEmissaoRelatorio, setCarregarEmissaoRelatorio] = useState<boolean>(false)
  const corIconeFiltroPreenchido = habilitarBotaoUploadPlanilha ? customTheme.colors.cinza04 : customTheme.colors.azul06
  const [arquivo, setArquivo] = useState<FormData | null>()
  const [contaContabil, setContaContabil] = useState<ContaContabil[]>([])
  const [parceiros, setParceiros] = useState<Parceiro[]>([])
  const isArquivo = Boolean(arquivo)
  const corBotaoExportarRelatorio = filtrosPreenchidos ? ExportIcon({ color: customTheme.colors.cinza04 }) : ExportIcon({ color: customTheme.colors.white01 })

  const {
    handleSubmit,
    control,
    formState: { errors },
    register,
    setValue,
    watch
  } = useForm<RelatorioConciliacaoFiltros>({
    defaultValues: {
      pedidoMKT: '',
      dataInicio: '',
      dataFim: '',
      contaContabilId: 0
    },
    resolver: yupResolver(schema)
  })

  const dataInicioPreenchida = watch('dataInicio')
  const dataFimPreenchida = watch('dataFim')
  const contaPreenchida = watch('contaContabilId')
  const pedidoMktPreenchido = watch('pedidoMKT')
  const parceiroIdPreenchido = watch('parceiroId')
  const datasPreenchidas = dataInicioPreenchida && dataFimPreenchida
  const arquivoValido = arquivo ? arquivo : new FormData()

  useEffect(() => {
    habilitarBotoes()
  }, [datasPreenchidas, contaPreenchida, pedidoMktPreenchido, parceiroIdPreenchido, isArquivo])

  async function buscarContasContabeis() {
    const contasContabeisResponse: ContaContabil[] = await RelatorioConciliacaoManager.buscarContaContabil()
    setContaContabil(contasContabeisResponse)
  }

  async function buscarParceiros() {
    const parceirosResponse: Parceiro[] = await parceiroManager.buscarDadosParceiros(true)
    setParceiros(parceirosResponse)
  }

  useEffect(() => {
    buscarContasContabeis()
    buscarParceiros()
  }, [])

  const handleSelecionarArquivo = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const arquivoAdicionado: File = event.target.files[0]
      const formData = new FormData()
      formData.append('file', arquivoAdicionado)
      setArquivo(formData)
    }
  }

  const handleExportarRelatorio = (urlRelatorio: string) => {
    const exportarRelatorio = document.createElement('a')
    exportarRelatorio.download = `download.txt`
    exportarRelatorio.href = urlRelatorio
    exportarRelatorio.click()
  }

  const handleAplicar = async (data: RelatorioConciliacaoFiltros) => {
    setCarregarEmissaoRelatorio(true)

    await RelatorioConciliacaoManager.exportarRelatorioConciliacao(data, arquivoValido)
      .then((response: string) => {
        handleExportarRelatorio(response)
        handleCancelarUpload()
      })
      .catch((e: Error) => {
        toast.error(e.message)
        setCarregarEmissaoRelatorio(false)
      })
      .finally(() => {
        setCarregarEmissaoRelatorio(false)
      })
  }

  const habilitarBotoes = () => {
    setHabilitarBotaoUploadPlanilha(true)
    setFiltrosPreenchidos(true)
    if (isDoisFiltrosPreenchidos(contaPreenchida, pedidoMktPreenchido, isArquivo, datasPreenchidas, parceiroIdPreenchido)) {
      setHabilitarBotaoUploadPlanilha(false)
      setFiltrosPreenchidos(false)
      return
    }
    if (isUmFiltroPreenchido(pedidoMktPreenchido, contaPreenchida, datasPreenchidas, parceiroIdPreenchido) || isArquivo) {
      setHabilitarBotaoUploadPlanilha(false)
    }
  }

  const handleLimparFiltro = () => {
    setFiltrosPreenchidos(true)
    setValue('contaContabilId', 0)
    setValue('dataFim', '')
    setValue('dataInicio', '')
    setValue('pedidoMKT', '')
    setCarregarEmissaoRelatorio(false)
  }

  const handleCancelarUpload = () => {
    handleLimparFiltro()
    setHabilitarBotaoUploadPlanilha(true)
    setArquivo(undefined)
    const inputUploadArquivoElement = <HTMLInputElement>document.getElementById('contained-button-file')
    if (inputUploadArquivoElement) {
      inputUploadArquivoElement.value = ''
    }
  }

  return {
    styles: relatorioConciliacaoStyles,
    iconeRelatorioImportado: isArquivo ? Check({ color: customTheme.colors.verde05 }) : NuvemUpload({ color: corIconeFiltroPreenchido }),
    inputFileOptions: '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
    carregarEmissaoRelatorio,
    corBotaoExportarRelatorio,
    register,
    setValue,
    errors,
    handleSubmit,
    control,
    handleLimparFiltro,
    handleAplicar,
    handleCancelarUpload,
    handleSelecionarArquivo,
    isArquivo,
    habilitarBotaoUploadPlanilha,
    filtrosPreenchidos,
    contaContabil,
    parceiros
  }
}
export type RelatorioConciliacaoIO = ReturnType<typeof relatorioConciliacaoIO>

function isUmFiltroPreenchido(pedidoMktPreenchido?: string, contaPreenchida?: number, datasPreenchidas?: string, parceiroIdPreenchido?: number) {
  return datasPreenchidas || pedidoMktPreenchido || contaPreenchida || parceiroIdPreenchido
}

function isDoisFiltrosPreenchidos(
  contaPreenchida?: number,
  pedidoMktPreenchido?: string,
  uploadPlanilha?: boolean,
  datasPreenchidas?: string,
  parceiroIdPreenchido?: number
) {
  const dataObrigatoria = datasPreenchidas && (contaPreenchida || pedidoMktPreenchido || uploadPlanilha || parceiroIdPreenchido)
  const pedidoMktObrigatorio = pedidoMktPreenchido && (contaPreenchida || datasPreenchidas || uploadPlanilha || parceiroIdPreenchido)
  const contaObrigatoria = contaPreenchida && (pedidoMktPreenchido || datasPreenchidas || uploadPlanilha || parceiroIdPreenchido)
  const parceiroIdObrigatorio = parceiroIdPreenchido && (pedidoMktPreenchido || datasPreenchidas || contaObrigatoria || uploadPlanilha)

  return dataObrigatoria || pedidoMktObrigatorio || contaObrigatoria || parceiroIdObrigatorio
}
