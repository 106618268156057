import { CabecalhoDebitosIO } from './CabecalhoDebitos.IO'
import { Grid } from '@material-ui/core'
import { IOProps } from 'react-compose-io'
import { Tipografia } from '../../Tipografia'
import { corParceiroId, customTheme } from '../../../styles/customTheme'
import CloseIcon from '@material-ui/icons/Close'
import RefreshIcon from '@material-ui/icons/Refresh'
import FilterIcon from '../../../assets/icons/filter-icon-branco.svg'
import { Dispatch } from 'react'
import { Transportadora } from '../../../types/transportadoras'
import { ModalFiltroParceiro } from '../../ModalFiltroParceiro'
import { Botao } from './../../Botao/index'
import { formateDate, formateHoras } from '../../../utils/stringUtils'

export type CabecalhoDebitosProps = {
  parceiroSelecionado: number
  desabilitarBotoes: boolean
  handleAtualizar: () => void
  handleClickParceiro: (parceiroId: number) => void
  setParceiroTransportadoras: Dispatch<Transportadora[]>
  dataUltimaAtualizacao: string
}

export function CabecalhoDebitosView({
  _io,
  parceiroSelecionado,
  desabilitarBotoes,
  handleAtualizar,
  setParceiroTransportadoras,
  handleClickParceiro,
  dataUltimaAtualizacao
}: IOProps<CabecalhoDebitosIO, CabecalhoDebitosProps>) {
  const { Avatar, Chip, ContainerButtons, ContainerFormTitulo } = _io.styles

  return (
    <>
      <Grid container spacing={2} justifyContent="space-between" alignItems="center">
        <ContainerFormTitulo>
          <Tipografia variant="h6">Controle de Débitos</Tipografia>
          {parceiroSelecionado !== 0 && _io.parceiros.length > 0 ? (
            <Chip
              icon={<Avatar src={_io.parceiros.filter((x) => x.parceiroId === parceiroSelecionado)[0].logoUrl} />}
              label={_io.parceiros.filter((x) => x.parceiroId === parceiroSelecionado)[0].razaoSocial}
              onDelete={() => _io.handleFecharChip()}
              deleteIcon={<CloseIcon />}
              variant="outlined"
              cor={corParceiroId.get(parceiroSelecionado)}
            />
          ) : null}
        </ContainerFormTitulo>
        <Grid item>
          <ContainerButtons>
            <Botao
              disabled={desabilitarBotoes}
              textoBotao={'Atualizar dados'}
              cor="primaria"
              type="submit"
              estilo="outlined"
              startIcon={<RefreshIcon />}
              width="230px"
              onClick={handleAtualizar}
            />
            <Botao
              disabled={desabilitarBotoes}
              textoBotao="Filtrar por parceiro"
              cor="primaria"
              estilo="contained"
              type="submit"
              width="230px"
              startIcon={<img src={FilterIcon} />}
              onClick={() => _io.setAbrirModalFiltroParceiros(true)}
            />
          </ContainerButtons>
          <Tipografia variant="body2" colorText={customTheme.colors.cinza07}>
            {`Última atualização: ${formateDate(dataUltimaAtualizacao)} às ${formateHoras(dataUltimaAtualizacao)}`}
          </Tipografia>
        </Grid>
      </Grid>
      <ModalFiltroParceiro
        descricaoModal={`O controle de débitos pode ser feito por parceiro.`}
        isShow={_io.abrirModalFiltroParceiros}
        isClosed={() => _io.setAbrirModalFiltroParceiros(false)}
        handleClickParceiro={handleClickParceiro}
        setParceiroTransportadoras={setParceiroTransportadoras}
        parceiros={_io.parceiros}
      />
    </>
  )
}
