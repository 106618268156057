import Avatar from '@material-ui/core/Avatar'
import Chip from '@material-ui/core/Chip'
import styled from 'styled-components'
import { customTheme } from '../../../../styles/customTheme'

export const cabecalhoHistoricoStyles = {
  ContainerFormTitulo: styled.div`
    display: flex;
    align-items: center;
  `,
  Avatar: styled(Avatar)`
    .MuiAvatar-img {
      height: 40px;
      width: 40px;
      object-position: top;
      object-fit: none;
    }
  `,
  Chip: styled(Chip).attrs((cor: { cor: string }) => cor)`
    margin-left: 16px;
    height: 48px;
    color: ${customTheme.colors.black};
    border-color: ${(props) => props.cor};
    font-size: ${customTheme.fontSize[16]};
    border-radius: 50px;
  `,
  Form: styled.form`
    margin-left: 24px;
    div {
      color: ${customTheme.colors.grey11};
    }
  `
}
