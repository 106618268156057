import { IOProps } from 'react-compose-io'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { Grid, IconButton, Typography, FormControl, MenuItem, Select } from '@material-ui/core'
import { customTheme } from './../../styles/customTheme'
import { Tipografia } from './../../components/Tipografia/index'
import { Botao } from '../../components/Botao'
import { ControleComissaoPedidoIO } from './ControleComissaoPedido.IO'
import { SelecionarParceiro } from '../../components/SelecionarParceiro'
import RefreshIcon from '@material-ui/icons/Refresh'
import { Controller } from 'react-hook-form'
import { CabecalhoExportarRelatorio } from '../../components/CabecalhoExportarRelatorio'
import { VALOR_ESCALA_GRAFICO } from '../../constants/utilConstants'
import { GraficoValorQuantidadeMultiplo } from '../../components/GraficoValorQuantidadeMultiplo'

export type ControleComissaoPedidoProps = {}

export function ControleComissaoPedidoView({ _io }: IOProps<ControleComissaoPedidoIO, ControleComissaoPedidoProps>) {
  const { Container, Avatar, CabecalhoContainer, ContainerButtons, Form, Box } = _io.styles

  return (
    <>
      <Typography variant="h5">Controle de comissao de pedido</Typography>
      {_io.parceiroSelecionado == 0 ? (
        <SelecionarParceiro
          handleConfirmar={_io.handleConfirmar}
          parceiros={_io.parceiros}
          subtitulo="Selecione o parceiro para visualizar o Controle de comissao de pedido."
        />
      ) : (
        <Container>
          <Grid container alignItems="center" justifyContent="space-between" spacing={2}>
            <Grid item>
              <CabecalhoContainer>
                <IconButton onClick={_io.handleIconeVoltar}>
                  <ArrowBackIcon color="disabled" />
                </IconButton>
                <Avatar src={_io.parceiroLogo} />
                <Tipografia variant="h6" fontWeight="500" colorText={customTheme.colors.grey23}>
                  {_io.parceiroNome}
                </Tipografia>
                <Form>
                  <Controller
                    render={({ field }) => (
                      <FormControl variant="standard" size="small" fullWidth>
                        <Select {...field} label="meses">
                          <MenuItem value={3}>Últimos 3 meses</MenuItem>
                          <MenuItem value={6}>Últimos 6 meses</MenuItem>
                        </Select>
                      </FormControl>
                    )}
                    name="meses"
                    control={_io.control}
                  />
                </Form>
              </CabecalhoContainer>
            </Grid>
            <ContainerButtons>
              <Grid item>
                <Botao
                  textoBotao="Atualizar dados"
                  cor="primaria"
                  type="submit"
                  estilo="outlined"
                  startIcon={<RefreshIcon />}
                  width="280px"
                  onClick={_io.handleAtualizarParceiro}
                />
                <Tipografia variant="body2" colorText={customTheme.colors.cinza07}>
                  {`Última atualização: ${_io.dataAtualizacao}`}
                </Tipografia>
              </Grid>
            </ContainerButtons>
          </Grid>
          <Box>
            <CabecalhoExportarRelatorio
              url={_io.urlRelatorio}
              textoTooltip={`Quantidades e Valores dos pedidos com divergencia de comissão ao decorer dos meses`}
              tituloCabecalho={'Resumo dos valores divergentes das comissões'}
            />
            <GraficoValorQuantidadeMultiplo
              valoresMensais={_io.dadosGrafico.valor}
              quantidadesMensais={_io.dadosGrafico.quantidade}
              legendaEixoYValor="Mil"
              legendaEixoYQuantidade="Quantidade"
              legendaMeses={_io.dadosGrafico.nomeMeses}
              descricaoValor={`Total dos valores de comissao`}
              valor={_io.resumoValor}
              descricaoQuantidade={`Quantidade total de pedidos com divergencia de comissao`}
              quantidade={_io.resumoQuatidade}
              handleVerMaisDetalhesPedidoBotao={_io.handleVerMaisDetalhesPedidoBotao}
            />
          </Box>
        </Container>
      )}
    </>
  )
}
