import { Typography } from '@material-ui/core'
import styled from 'styled-components'
import { ContainerSombra } from '../ContainerSombra'

export const historicoRegularizacaoStyles = {
  TextoTabela: styled(Typography).attrs({
    variant: 'h6'
  })`
    margin-bottom: 12px;
  `,
  ContainerTabela: styled(ContainerSombra)`
    padding: 24px;
    margin-top: 24px;
  `
}
