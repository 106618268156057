import { IOProps } from 'react-compose-io'
import { Semana } from '../../../../types/titulosTypes'
import { GraficoBarraSemanasIO } from './GraficoBarraSemanas.IO'
import Chart from 'react-apexcharts'
import { Tipografia } from './../../../../components/Tipografia/index'

export type GraficoBarraSemanasProps = {
  listaTitulosParceiros: Semana[]
}

export function GraficoBarraSemanasView({ _io }: IOProps<GraficoBarraSemanasIO, GraficoBarraSemanasProps>) {
  const { ContainerGrafico, Container } = _io.styles
  return (
    <Container>
      <Tipografia variant="h6">Por semana</Tipografia>
      <ContainerGrafico>
        <Chart options={_io.options} series={_io.series} type="bar" height="200" width="800" />
      </ContainerGrafico>
    </Container>
  )
}
