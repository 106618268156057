import { Avatar, Divider, Typography } from '@material-ui/core'
import styled from 'styled-components'
import { customTheme } from '../../styles/customTheme'

export const containerAvatarStyles = {
  DescricaoAvatar: styled(Typography).attrs({
    variant: 'subtitle2'
  })`
    margin-left: 9px;
    color: ${customTheme.colors.black};
    width: 150px;
  `,
  ContainerLogo: styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    height: 100%;
    gap: 4px;
    cursor: pointer;
  `,
  Dividers: styled(Divider).attrs({
    orientation: 'vertical',
    style: {
      border: `1px solid ${customTheme.colors.grey14}`,
      height: '100%'
    }
  })``,
  Avatar: styled(Avatar).attrs((avatarProps: { tamanhoimagem?: number }) => avatarProps)`
    width: ${(props) => props.tamanhoimagem}px;
    height: ${(props) => props.tamanhoimagem}px;
  `
}
