import { Table, TableBody, TablePagination } from '@material-ui/core'
import { IOProps } from 'react-compose-io'
import { HeadCell } from '../../interfaces/interfaces'
import { customTheme } from '../../styles/customTheme'
import { Order, Paginacao } from '../../types/tabelaTypes'
import { getComparator, ordenarCabecalho } from '../../utils/tabelaUtils'
import { EnhancedTableHead } from '../EnhancedTableHead'
import { TabelaIO } from './Tabela.IO'
import { ComponentePaginacao } from './ComponentePaginacao/index'

export type TabelaProps = {
  cabecalho: HeadCell[]
  itensLista: any[]
  LinhaTabela: (props: { item: any }) => JSX.Element
  orderCampoPadrao: string
  rowsPerPageOptions: number[]
  linhasPorPaginaPadrao: number
  maxHeight?: string
  alinharTextoCentro: boolean
  buscarItensTabela?: (paginacao: Paginacao) => void
  totalItens?: number
  semPaginacao?: boolean
  isCheckbox?: boolean
  handleSelectAllClick?: (event: React.ChangeEvent<HTMLInputElement>) => void
  totalSelecionados?: number
  corLetraCabecalho?: string
  ordenacaoPadrao?: Order
}

export default function TabelaView({
  _io,
  cabecalho,
  itensLista,
  LinhaTabela,
  rowsPerPageOptions,
  maxHeight,
  alinharTextoCentro,
  buscarItensTabela,
  totalItens,
  semPaginacao,
  handleSelectAllClick,
  totalSelecionados,
  isCheckbox,
  corLetraCabecalho
}: IOProps<TabelaIO, TabelaProps>) {
  const { TableContainer } = _io.styles

  return (
    <>
      <TableContainer maxheight={maxHeight}>
        <Table aria-labelledby="tableTitle" aria-label="enhanced table">
          <EnhancedTableHead
            alignCenter={alinharTextoCentro}
            headCells={cabecalho}
            order={_io.ordemBy}
            orderBy={_io.ordemCampo}
            onRequestSort={_io.onRequestSort}
            isCheckbox={isCheckbox}
            onSelectAllClick={handleSelectAllClick}
            numSelected={totalSelecionados}
            rowCount={totalItens || 0}
            colorCheckbox={customTheme.colors.grey08}
            corTextoCabecalho={corLetraCabecalho}
          />
          {buscarItensTabela ? (
            <TableBody>
              {itensLista.map((item: any, index) => {
                return <LinhaTabela item={item} key={index} />
              })}
            </TableBody>
          ) : (
            <TableBody>
              {ordenarCabecalho(itensLista, getComparator(_io.ordemBy, _io.ordemCampo))
                .slice(_io.pagina * _io.linhasPorPagina, _io.pagina * _io.linhasPorPagina + _io.linhasPorPagina)
                .map((item: any, index) => {
                  return <LinhaTabela item={item} key={index} />
                })}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      {!semPaginacao && (
        <TablePagination
          component={'div'}
          rowsPerPageOptions={rowsPerPageOptions}
          count={totalItens ? totalItens : itensLista.length}
          rowsPerPage={_io.linhasPorPagina}
          page={_io.pagina}
          onPageChange={_io.onMudancaPagina}
          onRowsPerPageChange={_io.onMudancaLinhasPorPagina}
          ActionsComponent={ComponentePaginacao}
        />
      )}
    </>
  )
}
