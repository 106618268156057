import { Chip } from '@material-ui/core'
import styled from 'styled-components'
import { handleHexToRgba } from '../../utils/stringUtils'
import { customTheme } from '../../styles/customTheme'

export const ChipClassificacaoPerda = styled(Chip).attrs((props: { bgcolor?: string; fontcolor: string }) => props)`
  background: ${(props) => (props.bgcolor ? props.bgcolor : handleHexToRgba(0.3, customTheme.colors.verde07))};
  color: ${(props) => (props.fontcolor ? props.fontcolor : customTheme.colors.verde07)};
  font-weight: 500;
  align-items: center;
  width: auto;
  height: 25px;
  font-weight: 500;
  font-size: ${customTheme.fontSize[10]};
  padding: 2px 4px;
  white-space: nowrap;
`
