import { Grid } from '@material-ui/core'
import { IOProps } from 'react-compose-io'
import { cabecalhoTipoLancamentoParceiro } from '../../../../types/tabelaTypes'
import { AbaTabela } from '../AbaTabela'
import { ModalTipoLancamentoParceiro } from '../ModalTipoLancamentoParceiro/index'
import { TabTipoLancamentoParceiroIO } from './TabTipoLancamentoParceiro.IO'
import { Botao } from './../../../../components/Botao/index'

export type TabTipoLancamentoParceiroProps = {}

export default function TabTipoLancamentoParceiroView({ _io }: IOProps<TabTipoLancamentoParceiroIO, TabTipoLancamentoParceiroProps>) {
  const { Container } = _io.styles

  return (
    <>
      <Container>
        <Grid container direction="row" justifyContent="flex-end" spacing={2}>
          <Grid item>
            <Botao textoBotao={'Adicionar'} onClick={_io.abrirModalCadastro} cor="primaria" estilo="contained" />
          </Grid>
        </Grid>
        <AbaTabela orderId={'tipoLancamentoId'} listItem={_io.tipoLancamentoList} headCells={cabecalhoTipoLancamentoParceiro} LinhaTabela={_io.linhaTabela} />
      </Container>
      <ModalTipoLancamentoParceiro
        atualizarDados={_io.atualizarDados}
        isShow={_io.openCadastro}
        isClosed={_io.fecharModalCadastro}
        tituloModal={'Adicionar tipo de lançamento parceiro'}
      />
    </>
  )
}
