import { Grid } from '@material-ui/core'
import { IOProps } from 'react-compose-io'
import { cabecalhoParametrizacaoStatus } from '../../../../types/tabelaTypes'
import { AbaTabela } from '../AbaTabela'
import { TabStatusIO } from './TabStatus.IO'
import { ModalTipoStatus } from './../ModalTipoStatus/index'
import { Botao } from './../../../../components/Botao/index'
import { ObterListaStatusParceiroResponse } from '../../../../types/parceiroApiTypes'
import { ItemStatus } from '../ItemStatus'

export type TabStatusProps = {}

export default function TabStatusView({ _io }: IOProps<TabStatusIO, TabStatusProps>) {
  const { Container, DivTab } = _io.styles

  function linhaTabela(props: { item: ObterListaStatusParceiroResponse; index: number }) {
    const { item, index } = props

    return (
      <ItemStatus
        parceiroId={_io.parceiroId}
        atualizarDados={_io.atualizarDados}
        linhaItemStatusParceiro={item}
        indexLinhaItemStatus={index}
        statusTrackingList={_io.statusTrackingList}
        statusEfacilList={_io.statusEfacilList}
      />
    )
  }

  return (
    <Container>
      <ModalTipoStatus
        atualizarDados={_io.atualizarDados}
        isShow={_io.openCadastro}
        isClosed={_io.fecharModalCadastro}
        statusTrackingList={_io.statusTrackingList}
        statusEfacilList={_io.statusEfacilList}
      />
      <DivTab>
        <Grid container direction="row" spacing={2}>
          <Grid container item sm={12} justifyContent="flex-end">
            <Botao textoBotao={'Adicionar'} cor="primaria" estilo="contained" onClick={_io.abrirModalCadastro} />
          </Grid>
          <AbaTabela orderId={''} listItem={_io.listaStatusParceiro} headCells={cabecalhoParametrizacaoStatus} LinhaTabela={linhaTabela}></AbaTabela>
        </Grid>
      </DivTab>
    </Container>
  )
}
