import { Grid, InputLabel, MenuItem, Select } from '@material-ui/core'
import { IOProps } from 'react-compose-io'
import { Controller } from 'react-hook-form'
import { TRANSFORMACAO_INFO } from '../../../constants/transformacao'
import { FormFieldsProps } from '../../../types/regraApiType'
import { TooltipComponent } from '../../Tooltip/Tooltip'
import { FieldsTipoParametro } from '../FieldsTipoParametro'
import { FieldsConcatenarIO } from './FieldsConcatenar.IO'

export type FieldsConcatenarProps = {
  indexItensRegraForm: number
  itensCampo: FormFieldsProps[]
  cabecalhos: string[]
}

const FieldsConcatenarView = ({ _io, cabecalhos, indexItensRegraForm, itensCampo }: IOProps<FieldsConcatenarIO, FieldsConcatenarProps>) => {
  const { FormControl, FormGroup } = _io.styles

  return (
    <>
      {_io.acaoSelecionada[indexItensRegraForm]?.operacaoId === 'ConcatenarOperacao' && (
        <>
          <Grid item sm={12} md={2}>
            <FormGroup>
              <Controller
                render={({ field }) => (
                  <FormControl>
                    <InputLabel>Operador</InputLabel>
                    <Select {...field} label="Operador">
                      {itensCampo
                        .filter((campoConcatenar) => campoConcatenar.operacaoId === _io.acaoSelecionada[indexItensRegraForm]?.operacaoId)[0]
                        ?.operadores.map((concatenarOperadores: string, concatenarOperadoresIndex: React.Key) => (
                          <MenuItem key={concatenarOperadoresIndex} value={concatenarOperadores}>
                            {concatenarOperadores}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                )}
                name={`acoes.${indexItensRegraForm}.operador` as `acoes.0.operador`}
                control={_io.control}
              />
              <TooltipComponent texto={TRANSFORMACAO_INFO.acao.concatenar} />
            </FormGroup>
          </Grid>
          <FieldsTipoParametro
            itensCampo={itensCampo}
            control={_io.control}
            cabecalhos={cabecalhos}
            indexItensRegraForm={indexItensRegraForm}
            itensRegraTransformacaoForm={_io.acaoSelecionada}
          />
        </>
      )}
    </>
  )
}

export { FieldsConcatenarView }
