import { TableCell, TableRow } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import { PreviewItemTransformacao } from '../../types/transformacaoApiTypes'
import { validarTexto } from '../../utils/stringUtils'

const useRowStyles = makeStyles(() => ({
  root: {
    '& > *': {
      borderBottom: 'unset'
    }
  }
}))

const PreVisualizarParametro = (props: { row: PreviewItemTransformacao }) => {
  const classes = useRowStyles()

  const { row } = props

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell align="center">{validarTexto(row?.identificadorPedido, '-')}</TableCell>
        <TableCell align="center">{validarTexto(row?.loja, '-')}</TableCell>
        <TableCell align="center">{validarTexto(row?.tipoLancamento, '-')}</TableCell>
        <TableCell align="center">{validarTexto(row?.lancamento, '-')}</TableCell>
        <TableCell align="center">{validarTexto(row?.valor, '-')}</TableCell>
        <TableCell align="center">{validarTexto(row?.parcela?.toString(), '-')}</TableCell>
      </TableRow>
    </React.Fragment>
  )
}

export { PreVisualizarParametro }
