import { Typography } from '@material-ui/core'
import styled from 'styled-components'
import { customTheme } from '../../styles/customTheme'

type CorPorcentagem = {
  corTexto?: string
  corIcone?: string
  fonteMargemTituloGerado?: string
}

export const cardValorQuantidadeStyles = {
  Container: styled.div`
    background: ${customTheme.colors.white05};
    border-radius: 8px;
    padding: 8px 11px;
    height: 100%;
  `,
  TituloCard: styled(Typography).attrs({
    variant: 'subtitle1'
  })`
    display: flex;
    font-style: normal;
    font-weight: 500;
    color: ${customTheme.colors.grey04};
    margin-bottom: ${({ fonteMargemTituloGerado }: CorPorcentagem) => fonteMargemTituloGerado};
  `,
  ContainerTextoCard: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 3px 11px;
    height: 50px;
  `,
  ContainerTituloCard: styled.div`
    display: flex;
    justify-content: start;
    align-items: center;
  `,
  ContainerSubtitulo: styled.div`
    display: flex;
    direction: column;
    align-items: center;
    flex-direction: row;
  `,
  Dividers: styled.div`
    background: ${customTheme.colors.grey12};
    height: 100%;
    width: 0.1%;
  `,
  ValorTotal: styled.text`
    font-family: ${customTheme.fontFamily.rubik400};
    font-style: normal;
    font-weight: 400;
    font-size: ${({ fonteMargemTituloGerado }: CorPorcentagem) => fonteMargemTituloGerado};
    color: ${customTheme.colors.black};
  `,
  TituloValor: styled(Typography).attrs({
    variant: 'body1'
  })`
    font-style: normal;
    font-weight: 400;
    color: ${({ corTexto }: CorPorcentagem) => corTexto};
  `,
  SubtituloTexto: styled(Typography).attrs({
    variant: 'caption'
  })`
    font-style: normal;
    font-weight: 400;
    color: ${customTheme.colors.grey12};
    line-height: 14px;
    margin-left: 4px;
  `,
  Img: styled.img`
    width: 35px;
    height: 35px;
    background: ${({ corIcone }: CorPorcentagem) => corIcone};
    border-radius: 100%;
    margin-right: 4px;
  `,
  ValorData: styled(Typography).attrs({
    variant: 'caption'
  })`
    font-weight: 400;
    line-height: 23px;
    color: ${customTheme.colors.grey03};
    margin-left: 8px;
  `,
  CotainerColuna: styled.div`
    display: flex;
    direction: column;
    align-items: start;
    flex-direction: column;
  `,
  SubtituloItem: styled(Typography).attrs({
    variant: 'caption'
  })`
    font-style: normal;
    font-weight: 400;
    line-height: 23px;
    color: ${customTheme.colors.black};
  `
}
