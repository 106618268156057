import { Checkbox, FormControlLabel } from '@material-ui/core'
import { GridCheckListStyles } from './GridCheckList.styles'

export type GridCheckListProps = {
  valorId: string | number
  handleCheckList: (event: React.ChangeEvent<HTMLInputElement>) => void
  valorChecked: boolean
  label: string | undefined
}

export const GridCheckList = ({ valorId, handleCheckList, valorChecked, label }: GridCheckListProps) => {
  const { GridItem } = GridCheckListStyles

  return (
    <GridItem>
      <FormControlLabel control={<Checkbox value={valorId} onChange={handleCheckList} checked={valorChecked} />} label={label} />
    </GridItem>
  )
}
