import { Grid, InputLabel, MenuItem, Select } from '@material-ui/core'
import { IOProps } from 'react-compose-io'
import { Controller } from 'react-hook-form'
import { TRANSFORMACAO_INFO, TRANSFORMACAO_LABELS_ACAO } from '../../../constants/transformacao'
import { FormFieldsProps } from '../../../types/regraApiType'
import { TooltipComponent } from '../../Tooltip/Tooltip'
import { FieldsConsiderarIO } from './FieldsConsiderar.IO'

export type FieldsConsiderarProps = {
  indexItensRegraForm: number
  itensCampo: FormFieldsProps[]
  cabecalhos: string[]
}

export function FieldsConsiderarView({ _io, cabecalhos, indexItensRegraForm }: IOProps<FieldsConsiderarIO, FieldsConsiderarProps>) {
  const { FormControl, FormGroup } = _io.styles

  return (
    <>
      {_io.acaoSelecionada[indexItensRegraForm]?.operacaoId === 'ConsiderarOperacao' && (
        <Grid item xs={12} sm={12} md={3}>
          <FormGroup>
            <Controller
              render={({ field }) => (
                <FormControl>
                  <InputLabel>{TRANSFORMACAO_LABELS_ACAO.dado}</InputLabel>
                  <Select {...field} label={TRANSFORMACAO_LABELS_ACAO.dado}>
                    {cabecalhos.map((cabecalho: string, cabecalhoIndex: number) => (
                      <MenuItem key={cabecalhoIndex} value={cabecalho}>
                        {cabecalho}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
              name={`acoes.${indexItensRegraForm}.dado` as `acoes.0.dado`}
              control={_io.control}
            />
            <TooltipComponent texto={TRANSFORMACAO_INFO.acao.considerar} />
          </FormGroup>
        </Grid>
      )}
    </>
  )
}
