import { CircularProgress, Tooltip } from '@material-ui/core'
import { Theme } from '@material-ui/core/styles'
import { withStyles } from '@material-ui/styles'
import styled from 'styled-components'
import { customTheme } from '../../styles/customTheme'

export const downloadRelatorioStyles = {
  margin: 0,
  padding: 0,
  Icone: styled.img`
    :hover {
      cursor: pointer;
    }
    margin: 0 3px;
    color: 'blue';
  `,
  IconeCarregamento: styled(CircularProgress).attrs({
    style: {
      color: 'blue',
      borderRadius: '1px solid blue'
    },
    size: '18px'
  })``,
  TooltipElement: withStyles((theme: Theme) => ({
    tooltip: {
      backgroundColor: customTheme.colors.grey01,
      borderRadius: 12,
      color: customTheme.colors.grey04,
      boxShadow: theme.shadows[1],
      padding: theme.spacing(1, 2),
      marginLeft: theme.spacing(15),
      marginTop: -50,
      fontSize: `${customTheme.fontSize[12]}`,
      maxWidth: 'none'
    }
  }))(Tooltip)
}
