import { DeepMap, FieldError } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { FormularioTipoLancamento } from '../../../../types/tipoLancamentoApiTypes'
import { dadosTiposLancamentosStyles } from './DadosTiposLancamentos.style'

export function dadosTiposLancamentosIO() {
  const { sequencias } = useSelector((state: any) => state.lancamento.sequencia)

  const verificarErros = (handleErros?: DeepMap<FormularioTipoLancamento, FieldError>, erro?: FieldError) => {
    return handleErros != undefined && erro != undefined
  }

  return {
    styles: dadosTiposLancamentosStyles,
    verificarErros,
    sequencias
  }
}
export type DadosTiposLancamentosIO = ReturnType<typeof dadosTiposLancamentosIO>
