import { Tooltip, IconButton } from '@material-ui/core'
import { Theme } from '@material-ui/core/styles'
import { withStyles } from '@material-ui/styles'
import styled from 'styled-components'
import { customTheme } from '../../styles/customTheme'

export const styles = {
  IconInfo: styled.img`
    :hover {
      cursor: pointer;
    }
    margin: 0 3px;
  `,
  TooltipElement: withStyles((theme: Theme) => ({
    tooltip: {
      backgroundColor: customTheme.colors.grey18,
      borderRadius: 12,
      color: customTheme.colors.grey19,
      boxShadow: theme.shadows[1],
      padding: theme.spacing(1, 2),
      marginLeft: theme.spacing(15),
      fontSize: `${customTheme.fontSize[12]}`
    }
  }))(Tooltip),
  BotaoIcone: styled(IconButton)`
    padding: 0px;
    margin-left: 8px;
  `
}
