import { IOProps } from 'react-compose-io'
import { RecuperacaoBoxIO } from './RecuperacaoBox.IO'
import { Grid } from '@material-ui/core'
import RostoFelizIcon from '../../../../assets/icons/rostoFelizIcone.svg'
import { CabecalhoExportarRelatorio } from '../../../../components/CabecalhoExportarRelatorio'
import { Tipografia } from '../../../../components/Tipografia'
import { customTheme } from '../../../../styles/customTheme'
import { GraficoValorQuantidade } from './../../../../components/Debitos/GraficoValorQuantidade/index'
import { RecuperacaoValoresPorParceiro, ResumoDebitosGeralResponse, ResumoMensalPorcentagemResponse } from '../../../../types/debitosTypes'
import { legendaMesGrafico, valoresGrafico } from '../../../../utils/debitoGraficos'
import { porcentagem } from '../../../../utils/stringUtils'
import { VALOR_ESCALA_GRAFICO } from '../../../../constants/utilConstants'

export type RecuperacaoBoxProps = {
  totalPorcentagem: number
  totalEmTransito: number
  maiorRecuperacao: ResumoMensalPorcentagemResponse
  menorRecuperacao: ResumoMensalPorcentagemResponse
  recuperacaoGrafico: RecuperacaoValoresPorParceiro | ResumoDebitosGeralResponse
}

export function RecuperacaoBoxView({
  _io,
  recuperacaoGrafico,
  totalPorcentagem,
  maiorRecuperacao,
  menorRecuperacao
}: IOProps<RecuperacaoBoxIO, RecuperacaoBoxProps>) {
  const { Box, Image, GridItem, TipografiaMes, Divider } = _io.styles

  return (
    <Box>
      <CabecalhoExportarRelatorio
        url={recuperacaoGrafico.urlRelatorio}
        tituloCabecalho={'Recuperação'}
        textoTooltip={`O painel de recuperação aponta o quanto conseguimos recuperar dos débitos 
                               identificados no repasse financeiro ao longo dos últimos 6 meses.`}
      />

      <Box width="fit-content" maxwidth="924px">
        <Grid container alignItems="center" justifyContent="center" direction="row" spacing={2}>
          <GridItem item>
            <Image src={RostoFelizIcon} />
            <Grid>
              <Tipografia fontWeight="700" variant="subtitle1" colorText={customTheme.colors.grey04} lineHeight={'1.25'}>
                Recuperação total
              </Tipografia>
              <Tipografia fontWeight="500" variant="subtitle1" colorText={customTheme.colors.grey04} lineHeight={'1.25'}>
                nos últimos 6 meses
              </Tipografia>
              <Tipografia fontWeight="600" variant="h4" colorText={customTheme.colors.grey09}>
                {porcentagem(totalPorcentagem)}
              </Tipografia>
            </Grid>
          </GridItem>
          <Divider orientation="vertical" variant="middle" />
          <GridItem item>
            <Grid>
              <Tipografia fontWeight="700" variant="subtitle1" colorText={customTheme.colors.grey04} lineHeight={'1.25'}>
                Maior recuperação
              </Tipografia>
              <Tipografia fontWeight="500" variant="subtitle1" colorText={customTheme.colors.grey04} lineHeight={'1.25'}>
                dos últimos 6 meses
              </Tipografia>
              <Tipografia fontWeight="600" variant="h4" colorText={customTheme.colors.grey09}>
                {porcentagem(maiorRecuperacao.valor)}
              </Tipografia>
            </Grid>
            <TipografiaMes fontWeight="700" variant="subtitle1" colorText={customTheme.colors.grey09}>
              {maiorRecuperacao.mes.toUpperCase()}
            </TipografiaMes>
          </GridItem>
          <Divider orientation="vertical" variant="middle" />
          <GridItem item>
            <Grid>
              <Tipografia fontWeight="700" variant="subtitle1" colorText={customTheme.colors.grey04} lineHeight={'1.25'}>
                Menor recuperação
              </Tipografia>
              <Tipografia fontWeight="500" variant="subtitle1" colorText={customTheme.colors.grey04} lineHeight={'1.25'}>
                dos últimos 6 meses
              </Tipografia>
              <Tipografia fontWeight="600" variant="h4" colorText={customTheme.colors.grey09}>
                {porcentagem(menorRecuperacao.valor)}
              </Tipografia>
            </Grid>
            <TipografiaMes fontWeight="700" variant="subtitle1" colorText={customTheme.colors.grey09}>
              {menorRecuperacao.mes.toUpperCase()}
            </TipografiaMes>
          </GridItem>
        </Grid>
      </Box>

      <GraficoValorQuantidade
        tituloGrafico={`Valor mensal de recuperação`}
        valoresMensais={valoresGrafico(recuperacaoGrafico.valoresMensais)}
        legendaMeses={legendaMesGrafico(recuperacaoGrafico.valoresMensais)}
        legendaEixoY="Milhares"
        divisorLegenda={VALOR_ESCALA_GRAFICO.MIL}
        descricaoValor={`Valor total recuperado`}
        valor={recuperacaoGrafico.valor}
        descricaoQuantidade={`Quantidade total de pedidos recuperados`}
        quantidade={recuperacaoGrafico.quantidade}
        width="calc(100% + 64px)"
      />
    </Box>
  )
}
