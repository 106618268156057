import { IOProps } from 'react-compose-io'
import { Parceiro } from '../../../../types/parceiroApiTypes'
import { formateDate } from '../../../../utils/stringUtils'
import { ItemParceiroIO } from './ItemParceiro.IO'

export type ItemParceiroProps = {
  parceiro: Parceiro
}

export function ItemParceiroView({ _io, parceiro }: IOProps<ItemParceiroIO, ItemParceiroProps>) {
  const { Box, TableCell, TableRow } = _io.styles

  return (
    <TableRow onClick={(event) => _io.getParceiroAtivo(event, parceiro)} key={parceiro.parceiroId}>
      <TableCell>{parceiro.parceiroId ?? '--'}</TableCell>
      <TableCell>{parceiro.razaoSocial ?? '--'}</TableCell>
      <TableCell>{parceiro.nomeFantasia ?? '--'}</TableCell>
      <TableCell>{formateDate(parceiro.ultimoRepasse) == ' - ' ? 'Não foi realizado' : formateDate(parceiro?.ultimoRepasse)}</TableCell>
      <TableCell>{formateDate(parceiro.proximoRepasse) == ' - ' ? 'Sem previsão' : formateDate(parceiro?.proximoRepasse)}</TableCell>
      <TableCell>
        <Box>{parceiro.status ?? '--'}</Box>
      </TableCell>
    </TableRow>
  )
}
