import { IOProps } from 'react-compose-io'
import { ControleInadimplenciasIO } from './ControleInadimplencias.IO'
import { corParceiroId, customTheme } from '../../styles/customTheme'
import { Grid } from '@material-ui/core'
import { CabecalhoInadimplencia } from './components/CabecalhoInadimplencia'
import { GraficoBarras } from '../../components/GraficoBarras'
import { CollapseInadimplenciaTable } from './components/CollapseInadimplenciaTable/index'
import { ResumoInadimplencia } from './components/ResumoInadimplencia'
import CifraoIcone from '../../assets/icons/cifrao.svg'
import CuboIcone from '../../assets/icons/cubo.svg'
import { formateCurrency } from '../../utils/stringUtils'
import { StatusProcessamento } from '../../types/genericoType'
import { CabecalhoExportarRelatorio } from './../../components/CabecalhoExportarRelatorio'
import { Tipografia } from './../../components/Tipografia/index'
import { PAGES } from '../../router/routes'
import { Botao } from './../../components/Botao/index'
import { EmTransitoInadimplencia } from './components/EmTransitoInadimplencia/index'
import { SemOcorrenciaInadimplencia } from './components/SemOcorrenciaInadimplencia/index'
import { INADIMPLENCIA, TipoAnomaliaEnum } from '../../types/controleInadimplenciaTypes'
import { TIPO_VALOR_GRAFICO, VALOR_ESCALA_GRAFICO } from '../../constants/utilConstants'

export type ControleInadimplenciasProps = {}

export function ControleInadimplenciasView({ _io }: IOProps<ControleInadimplenciasIO, ControleInadimplenciasProps>) {
  const { Box, Container, CircularProgress, GridContainer } = _io.styles

  const Carregamento = _io.carregando && (
    <Grid container justifyContent="center">
      <CircularProgress />
    </Grid>
  )

  return (
    <>
      <Tipografia variant="h5">Inadimplência</Tipografia>
      <Container cor={corParceiroId.get(_io.parceiroSelecionado)}>
        <CabecalhoInadimplencia
          parceiroSelecionado={_io.parceiroSelecionado}
          desabilitarBotoes={_io.carregando}
          handleAtualizar={() => _io.gerarDadosControleInadimplencias()}
          handleClickParceiro={_io.handleClickParceiro}
          setMesesSelecionado={_io.setMesesSelecionado}
          dataUltimaAtualizacao={_io.controleInadimplenciasResponse.dataAlteracao}
        />
        {_io.controleInadimplenciasResponse?.processamento?.status === StatusProcessamento.PROCESSANDO && <>{Carregamento}</>}
        {_io.controleInadimplenciasResponse?.processamento?.status === StatusProcessamento.CONCLUIDO && (
          <>
            <Box>
              <CabecalhoExportarRelatorio
                url={_io.relatoriosInadimplencias.filter((x) => x.tipo === TipoAnomaliaEnum.Total)[0]?.urlRelatorio}
                textoTooltip={`Dados sobre os títulos que estão inadimplentes e precisam de análise. `}
                tituloCabecalho={'Valor de inadimplência'}
              />
              <GridContainer container justifyContent="space-around">
                <Grid item>
                  <GraficoBarras
                    tituloGrafico=""
                    descricaoColunas={_io.dadosGrafico.nomeMeses}
                    dadosColunas={_io.dadosGrafico.valor}
                    legendaEixoY="Milhares (R$)"
                    valorDivididoLegenda={VALOR_ESCALA_GRAFICO.MIL}
                    corBarra={() => customTheme.colors.orange04}
                    height="220px"
                    width="502px"
                    tipoValor={TIPO_VALOR_GRAFICO.MOEDA}
                  />
                </Grid>
                <Grid item>
                  <CollapseInadimplenciaTable inadimplencias={_io.inadimplenciasFiltradas} />
                  <Botao
                    textoBotao="Ver mais detalhes"
                    estilo="contained"
                    cor="primaria"
                    onClick={() => _io.history.push(PAGES.detalheInadimplencia(_io.parceiroSelecionado, INADIMPLENCIA.TOTAL))}
                    width="285px"
                    margin="32px 0 0 0"
                  />
                </Grid>
              </GridContainer>
              <ResumoInadimplencia
                icone={CifraoIcone}
                titulo="Análise de valores (R$)"
                totalTitulosUltimosMeses={formateCurrency(_io.dadosGrafico.valor.reduce((total, valor) => total + valor, 0))}
                totalTitulos={formateCurrency(_io.inadimplenciasFiltradas.reduce((total, dado) => total + dado.valor, 0))}
                valorTotal={_io.dadosGrafico.valor.reduce((total, valor) => total + valor, 0)}
                mesesSelecionado={_io.mesesSelecionado}
                valoresGrafico={_io.dadosGrafico.valor}
                legendaGrafico={_io.dadosGrafico.nomeMeses}
              />
              <ResumoInadimplencia
                icone={CuboIcone}
                titulo="Análise quantitativa (unid.)"
                totalTitulosUltimosMeses={_io.dadosGrafico.quantidade.reduce((total, quantidade) => total + quantidade, 0).toLocaleString('pt-BR')}
                totalTitulos={_io.inadimplenciasFiltradas.reduce((total, dado) => total + dado.quantidade, 0).toLocaleString('pt-BR')}
                valorTotal={_io.dadosGrafico.quantidade.reduce((total, quantidade) => total + quantidade, 0)}
                mesesSelecionado={_io.mesesSelecionado}
                valoresGrafico={_io.dadosGrafico.quantidade}
                legendaGrafico={_io.dadosGrafico.nomeMeses}
              />
            </Box>

            <EmTransitoInadimplencia
              inadimplenciasAtendimento={_io.inadimplenciasFiltradasEmTransitoAtendimento}
              inadimplenciasLogisticaMartins={_io.inadimplenciasFiltradasEmTransitoLogisticaMartins}
              inadimplenciasLogisticaParceiro={_io.inadimplenciasFiltradasEmTransitoLogisticaParceiro}
              mesesSelecionados={_io.mesesSelecionado}
              relatoriosInadimplencia={_io.relatoriosInadimplencias}
              parceiroSelecionado={_io.parceiroSelecionado}
            />

            <SemOcorrenciaInadimplencia
              inadimplenciasEmAnalise={_io.inadimplenciasFiltradasSemOcorrenciaEmAnalise}
              inadimplenciasPerda={_io.inadimplenciasFiltradasSemOcorrenciaPerda}
              mesesSelecionados={_io.mesesSelecionado}
              relatoriosInadimplencia={_io.relatoriosInadimplencias}
              parceiroSelecionado={_io.parceiroSelecionado}
            />
          </>
        )}
      </Container>
    </>
  )
}
