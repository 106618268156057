import { InputAdornment, InputLabel, TableRow } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import { ChangeEventHandler } from 'react'
import { IOProps } from 'react-compose-io'
import { DownloadRelatorio } from '../../../../components/DownloadRelatorio/index'
import Modal from '../../../../components/Modal/Modal'
import { Tabela } from '../../../../components/Tabela'
import { Detalhes } from '../../../../types/conciliacaoApiTypes'
import { cabecalhoVisualizarContabilizacaoModal } from '../../../../types/tabelaTypes'
import { formateCurrency } from '../../../../utils/stringUtils'
import { CabecalhoTabelaContabilizacaoIO } from './CabecalhoTabelaContabilizacao.IO'
import { Tipografia } from './../../../../components/Tipografia/index'

export type CabecalhoTabelaContabilizacaoProps = {
  buscarValores: string
  setBuscarValores: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
  searchEnter: () => void
  abrirModal: boolean
  handleFecharModal: () => void
  contabilizacaoList: Detalhes[]
  gerarRelatorio: () => void
  status: number
  url: string
}

export function CabecalhoTabelaContabilizacaoView({
  _io,
  buscarValores,
  setBuscarValores,
  searchEnter,
  abrirModal,
  handleFecharModal,
  contabilizacaoList,
  gerarRelatorio,
  url
}: IOProps<CabecalhoTabelaContabilizacaoIO, CabecalhoTabelaContabilizacaoProps>) {
  const { CelulaTabela, InputFiltrar, FormularioControleInput, ContainerCabecalhoContabilizacao, ContainerBotao, ContainerLinha } = _io.styles
  function linhaTabela(props: { item: Detalhes }) {
    const { item } = props
    return (
      <TableRow>
        <CelulaTabela>{item.historicoPadrao}</CelulaTabela>
        <CelulaTabela>{item.tipoLancamento}</CelulaTabela>
        <CelulaTabela>{item.sequenciaContabil}</CelulaTabela>
        <CelulaTabela>{formateCurrency(item.valor)}</CelulaTabela>
        <CelulaTabela>{item.parcela}</CelulaTabela>
      </TableRow>
    )
  }
  return (
    <>
      <ContainerCabecalhoContabilizacao>
        <Tipografia variant="h6">Contabilização</Tipografia>
        <ContainerLinha>
          <FormularioControleInput>
            <InputLabel htmlFor="filled-search">Buscar</InputLabel>
            <InputFiltrar
              id="filled-search"
              value={buscarValores}
              onChange={setBuscarValores}
              onKeyPress={_io.handleKeyEnter}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon onClick={searchEnter} />
                </InputAdornment>
              }
              labelWidth={60}
            />
          </FormularioControleInput>
          <ContainerBotao>
            <DownloadRelatorio
              relatorioDisponivel={_io.relatorioDisponivel}
              iconeCarregandoRelatorio={_io.carregandoRelatorio}
              textoTooltip={'Exportar'}
              gerarRelatorio={() => {
                gerarRelatorio()
              }}
              url={url}
            />
          </ContainerBotao>
        </ContainerLinha>
      </ContainerCabecalhoContabilizacao>
      <Modal onAction={handleFecharModal} title="Resultado" openModal={abrirModal} fullWidth={false} maxWidth={false} onClose={handleFecharModal} hiddenButton>
        {contabilizacaoList != null && contabilizacaoList != undefined && (
          <Tabela
            cabecalho={cabecalhoVisualizarContabilizacaoModal}
            itensLista={contabilizacaoList}
            LinhaTabela={linhaTabela}
            linhasPorPaginaPadrao={5}
            orderCampoPadrao=""
            rowsPerPageOptions={[5, 10, 25]}
            alinharTextoCentro={false}
          />
        )}
      </Modal>
    </>
  )
}
