import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { ABAS_DETALHES_PARCEIROS, PAGES } from '../../router/routes'
import { parametrosRepasseStyles } from './ParametrosRepasse.styles'

export function parametrosRepasseIO() {
  const rotas = useHistory()
  const { parceiroId, abaIndex } = useParams<{ parceiroId: string; abaIndex: string }>()
  const [aba, setAba] = React.useState(getAbaIndex(abaIndex))

  const handleChange = (event: React.ChangeEvent<{}>, novaAba: number) => {
    rotas.push(PAGES.parametrosRepasse(parceiroId, getAbaURL(novaAba)))
    setAba(novaAba)
  }

  function getAbaIndex(abaUrl: string) {
    switch (abaUrl) {
      case ABAS_DETALHES_PARCEIROS.transformacao:
        return 1
      case ABAS_DETALHES_PARCEIROS.associar:
        return 2
      case ABAS_DETALHES_PARCEIROS.gruposRegras:
        return 3
      default:
        return 0
    }
  }

  function getAbaURL(abaId: number) {
    switch (abaId) {
      case 1:
        return ABAS_DETALHES_PARCEIROS.transformacao
      case 2:
        return ABAS_DETALHES_PARCEIROS.associar
      case 3:
        return ABAS_DETALHES_PARCEIROS.gruposRegras
      default:
        return ABAS_DETALHES_PARCEIROS.tiposLancamentos
    }
  }

  return {
    styles: parametrosRepasseStyles,
    handleChange,
    aba
  }
}

export type ParametrosRepasseIO = ReturnType<typeof parametrosRepasseIO>
