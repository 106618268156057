import { useState, useEffect } from 'react'
import { appMenuStyles } from './AppMenu.style'
import loginManager from '../../services/api/login/loginManager'
import { DRAWER_LIST } from '../../constants/menu'

export function appMenuIO() {
  const [menusPerfil, setMenusPerfil] = useState<string[]>([])

  useEffect(() => {
    obterMenusPerfil()
  }, [])

  const obterMenusPerfil = async () => {
    const menus = await loginManager.obterAcessosMenu()
    setMenusPerfil(menus)
  }

  const menusFiltradosPorAcesso = DRAWER_LIST.filter((x) => menusPerfil.includes(x.name))

  return {
    styles: appMenuStyles,
    menusFiltradosPorAcesso
  }
}
export type AppMenuIO = ReturnType<typeof appMenuIO>
