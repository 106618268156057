import { Toolbar } from '@material-ui/core'
import styled from 'styled-components'
import { customTheme } from '../../../../styles/customTheme'

export const headerTabelaResultadosOPSStyles = {
  ContainerBotao: styled.div`
    display: flex;
    flex-direction: row;
    gap: 12px;
  `,
  Container: styled(Toolbar).attrs((props: { numSelect: boolean }) => props)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: ${customTheme.colors.black};
    background-color: ${(props) => (props.numSelect ? customTheme.colors.blue00 : customTheme.colors.white01)};
    padding: 15px;
    border-radius: 8px;
  `
}
