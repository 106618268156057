import { Grid, Typography } from '@material-ui/core'

interface IDataEmptyProps {
  descricao: string
}

const DataEmpty = ({ descricao }: IDataEmptyProps) => (
  <Grid container justifyContent="center">
    <Typography variant="h6">{descricao}</Typography>
  </Grid>
)

export default DataEmpty
