import { Grid } from '@material-ui/core'
import styled from 'styled-components'
import { Tipografia } from './../Tipografia/index'

export const cabecalhoIconeExportarRelatorioStyles = {
  GridIconText: styled(Grid)`
    display: flex;
    align-items: center;

    img + h6 {
      margin-left: 10px;
    }
  `,
  Texto: styled(Tipografia)`
    margin-left: 12px;
  `
}
