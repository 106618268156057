import { Grid, Typography } from '@material-ui/core'
import styled from 'styled-components'
import { customTheme } from '../../styles/customTheme'

export const informacoesModalRegularizacaoSucessoStyles = {
  ContainerListaModal: styled(Grid).attrs((avatarProps: { alturaLista: string }) => avatarProps)`
    height: ${(props) => props.alturaLista};
    overflow: auto;
    text-align: start;
    padding-right: 8px;
  `,
  ContainerInformacoes: styled(Grid).attrs({
    item: true,
    xs: 12,
    sm: 12,
    md: 12,
    lg: 12
  })`
    background: ${customTheme.colors.white05};
    border-radius: 4px;
    text-align: start;
    display: flex;
    justify-content: flex-start;
    align-items: start;
    margin: 12px 0;
    padding: 12px;
  `,
  TituloInformacoes: styled(Typography).attrs({
    variant: 'subtitle2'
  })`
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    color: ${customTheme.colors.grey04};
  `,
  Informacao: styled(Typography).attrs({
    variant: 'h6'
  })`
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    color: ${customTheme.colors.grey09};
  `,
  ContainerItensInformacoes: styled.div`
    display: flex;
    align-items: start;
    flex-direction: column;
    margin-right: 15px;
  `,
  SubtituloInformacoes: styled(Typography).attrs({
    variant: 'caption'
  })`
    font-style: normal;
    font-weight: 400;
    line-height: 23px;
    color: ${customTheme.colors.black};
  `
}
