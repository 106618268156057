import { IconButton, TableCell } from '@material-ui/core'
import { tabelaControleComissaoPedidoStyles } from './TabelaControleComissaoPedido.styles'
import { CabecalhoTabelaControleComissaoPedido, TabelaControleComissaoPedidoProps } from './TabelaControleComissaoPedido.View'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'

export function tabelaControleComissaoPedidoIO({ checklistsSelecionados }: TabelaControleComissaoPedidoProps) {
  const { CelulaComFiltro } = tabelaControleComissaoPedidoStyles

  const renderCabecalho = ({ descricao, abrirPopover }: CabecalhoTabelaControleComissaoPedido) => {
    return (
      <TableCell key={descricao} align="center">
        {abrirPopover ? (
          <CelulaComFiltro>
            {descricao}
            <IconButton size="small" onClick={abrirPopover}>
              <KeyboardArrowDownIcon color="primary" />
            </IconButton>
          </CelulaComFiltro>
        ) : (
          descricao
        )}
      </TableCell>
    )
  }

  return {
    styles: tabelaControleComissaoPedidoStyles,
    renderCabecalho,
    checkListTotalChecked: checklistsSelecionados.length > 0,
    checkListTotalIndeterminado: checklistsSelecionados.length === 0
  }
}

export type TabelaControleComissaoPedidoIO = ReturnType<typeof tabelaControleComissaoPedidoIO>
