import { Typography } from '@material-ui/core'
import styled from 'styled-components'
import { customTheme } from '../../../../styles/customTheme'

export const graficoBarraDiasStyles = {
  Container: styled.div`
    border-radius: 8px;
    background: ${customTheme.colors.white05};
    display: flex;
    flex-direction: column;
    align-items: start;
  `,
  Conteudo: styled.div`
    padding: 16px;
  `,
  TituloContainer: styled(Typography).attrs({
    variant: 'body1'
  })`
    color: ${customTheme.colors.grey04};
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    margin: 10px 5px 0 10px;
  `
}
