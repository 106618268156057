import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import * as yup from 'yup'
import msgConstants from '../../constants/msgConstants'
import TitulosManager from '../../services/api/titulos/titulosManager'
import { BuscarTitulosForm } from '../../types/titulosTypes'
import { dataInicialMes, formatarData } from '../../utils/stringUtils'
import { controleTitulosStyles } from './ControleTitulos.styles'

export function controleTitulosIO() {
  const dataAtualPadrao = String(formatarData(new Date().toISOString(), '-'))
  const dataMesAnoAtual = dataInicialMes()
  const [carregarBotao, setCarregarBotao] = useState<boolean>(false)
  const [dataInicialAplicada, setDataInicialAplicada] = useState('')
  const [dataFinalAplicada, setDataFinalAplicada] = useState('')

  const defaultValues = {
    dataPeriodo: '',
    dataComparacao: '',
    titulos: 'Em aberto'
  }

  const schema = yup.object().shape({
    dataPeriodo: yup.string().required(msgConstants.USO_GENERICO.campo_obrigatorio),
    titulos: yup.string(),
    dataComparacao: yup
      .string()
      .when('titulos', {
        is: 'Liquidados',
        then: yup.string().required(msgConstants.USO_GENERICO.campo_obrigatorio)
      })
      .when('titulos', {
        is: 'Gerados',
        then: yup.string().required(msgConstants.USO_GENERICO.campo_obrigatorio)
      })
  })

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors }
  } = useForm<BuscarTitulosForm>({
    defaultValues,
    resolver: yupResolver(schema)
  })

  const dataPeriodoAlterada = watch('dataPeriodo')
  const dataComparacaoAlterada = watch('dataComparacao')
  const dropDownTitulos = watch('titulos')

  useEffect(() => {
    if (dropDownTitulos == 'Em aberto') {
      setValue('dataPeriodo', dataAtualPadrao)
      setValue('dataComparacao', '')
    }
    if (dropDownTitulos == 'Liquidados') {
      setValue('dataPeriodo', dataMesAnoAtual)
      setValue('dataComparacao', dataAtualPadrao)
    }
    if (dropDownTitulos == 'Gerados') {
      setValue('dataPeriodo', dataMesAnoAtual)
      setValue('dataComparacao', dataAtualPadrao)
    }
    setCarregarBotao(false)
  }, [dropDownTitulos, setValue, dataAtualPadrao, dataMesAnoAtual])

  const gerarRelatorioAbertos = async (buscarRelatorio: () => void, dataPesquisada: string, parceiroId?: number) => {
    await TitulosManager.exportarTitulosAbertos(dataPesquisada, parceiroId)
      .then(() => {
        buscarRelatorio()
      })
      .catch((e: Error) => {
        toast.error(msgConstants.USO_GENERICO.erroExportar)
        toast.error(e.message)
      })
  }

  const gerarRelatorioLiquidados = async (buscarRelatorio: () => void, dataInicio: string, parceiroId?: number, dataFim?: string) => {
    await TitulosManager.exportarTitulosLiquidados(dataInicio, parceiroId, dataFim)
      .then(() => {
        buscarRelatorio()
      })
      .catch((e: Error) => {
        toast.error(msgConstants.TITULOS.erroRelatorio)
        toast.error(e.message)
      })
  }

  const gerarRelatorioGerados = async (buscarRelatorio: () => void, dataInicio: string, parceiroId?: number, dataFim?: string) => {
    await TitulosManager.exportarTitulosGerados(dataInicio, parceiroId, dataFim)
      .then(() => {
        buscarRelatorio()
      })
      .catch((e: Error) => {
        toast.error(msgConstants.USO_GENERICO.erroExportar)
        toast.error(e.message)
      })
  }

  return {
    styles: controleTitulosStyles,
    control,
    setValue,
    setCarregarBotao,
    dropDownTitulos,
    dataPeriodoAlterada,
    dataComparacaoAlterada,
    handleSubmit,
    carregarBotao,
    gerarRelatorioAbertos,
    gerarRelatorioLiquidados,
    errors,
    dataInicialAplicada,
    setDataInicialAplicada,
    dataFinalAplicada,
    setDataFinalAplicada,
    gerarRelatorioGerados
  }
}

export type ControleTitulosIO = ReturnType<typeof controleTitulosIO>
