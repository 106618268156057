import { TableCell } from '@material-ui/core'
import styled from 'styled-components'
import { ContainerSombra } from '../../../../components/ContainerSombra'

export const tabelaErroProcessamentoStyles = {
  ContainerSombra: styled(ContainerSombra)`
    overflow: auto;
    display: inline-grid;
  `,
  TableCell: styled(TableCell)`
    white-space: nowrap;
  `
}
