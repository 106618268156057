import { Divider, Grid } from '@material-ui/core'
import styled from 'styled-components'
import { ContainerSombra } from '../../../../components/ContainerSombra'
import { Tipografia } from './../../../../components/Tipografia/index'

export const pedidosDivergentesResumoStyles = {
  BoxUploadPlanilha: styled(ContainerSombra)`
    margin-top: 24px;
    padding: 28px;
  `,
  BoxResumo: styled(ContainerSombra)`
    padding: 28px 40px;
    width: 361px;
    margin-bottom: 16px;
  `,
  TituloGrafico: styled(Tipografia)`
    margin-left: 8px;
  `,
  ContainerTituloGrafico: styled.div`
    display: flex;
    align-items: center;
  `,
  DividerHorizontal: styled(Divider)`
    margin: 8px;
  `,
  DividerVertical: styled(Divider)`
    height: 65px;
    width: 1px;
  `,
  GridCollapse: styled(Grid)`
    text-align: center;
    width: 140px;
  `,
  Tipografia: styled(Tipografia)`
    margin: 0;
  `
}
