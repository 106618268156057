import Divider from '@material-ui/core/Divider'
import styled from 'styled-components'
import { ContainerSombra } from '../../../../components/ContainerSombra'

export const resumoInadimplenciaStyles = {
  Box: styled(ContainerSombra)`
    padding: 16px;
    margin: 32px 24px 0px 24px;
  `,
  Imagem: styled.img`
    width: 108px;
    height: 108px;
  `,
  DividerHorizontal: styled(Divider)`
    width: 300px;
  `,
  DividerVertical: styled(Divider)`
    margin-top: 24px;
    height: 274px;
    width: 1px;
  `
}
