import styled from 'styled-components'
import { customTheme } from '../../styles/customTheme'

export const tabelaEditavelStyles = {
  Container: styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: end;
  `,
  estiloCabecalho: {
    backgroundColor: `${customTheme.colors.red00}`,
    color: `${customTheme.colors.grey09}`,
    textAlign: 'center',
    padding: '16px',
    fontFamily: `${customTheme.fontFamily.rubik500}`,
    fontWeight: 500,
    fontSize: `${customTheme.fontSize[12]}`,
    lineHeight: '20px'
  }
}
