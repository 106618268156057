import { Box, Typography } from '@material-ui/core'
import styled from 'styled-components'
import { customTheme } from '../../styles/customTheme'

export const boxValoresStyles = {
  CustomBox: styled(Box).attrs((props: { possuierro: number; possuicor: number }) => props)`
    background-color: ${(props) => {
      if (props.possuierro == 1) return customTheme.colors.red00
      if (props.possuicor == 1) return customTheme.colors.blue01
      else return customTheme.colors.grey01
    }};
    border-radius: 5px;
    height: 40px;
    width: 200px;
    display: flex;
    align-items: center;
  `,
  CustomTypography: styled(Typography).attrs((props: { cor: string | number }) => props)`
    color: ${(props) => {
      if (Number(props.cor) > 0) return customTheme.colors.blue05
      else if (Number(props.cor) < 0) return customTheme.colors.red06
      else return customTheme.colors.grey04
    }};
  `,
  MarginBox: styled(Box)`
    margin-left: 24px;
  `
}
