import styled, { css } from 'styled-components'
import { customTheme } from '../../../../styles/customTheme'
import CancelIcon from '@material-ui/icons/Cancel'

interface IObjectKeys {
  [key: string]: string | number
}

interface Collors extends IObjectKeys {
  default: string
  error: string
  success: string
}

const messageColors: Collors = {
  default: `${customTheme.colors.grey14}`,
  error: `${customTheme.colors.red04}`,
  success: `${customTheme.colors.green08}`
}

const dragActive = css`
  border-color: ${customTheme.colors.green08};
`

const dragReject = css`
  border-color: ${customTheme.colors.red04};
`

export const uploadPlanilhaStyles = {
  Container: styled.div`
    width: 100%;
    .filepond--credits {
      display: none;
    }
  `,
  DropContainer: styled.div.attrs({
    className: 'dropzone'
  })`
    border: 1px dashed #ddd;
    padding: 2%;
    background-color: ${customTheme.colors.white06};
    border-radius: 10px;
    cursor: pointer;
    transition: height 0.2s ease;
    margin-bottom: 5px;
    ${(props: any) => props.isDragActive && dragActive};
    ${(props: any) => props.isDragReject && dragReject};
  `,
  DropSuccess: styled.div.attrs({
    className: 'dropzone-end'
  })`
    padding: 1%;
    background-color: ${customTheme.colors.grey08};
    border-radius: 10px;
    transition: height 0.2s ease;
    cursor: auto;
  `,
  SpanTitle: styled.p`
    color: ${customTheme.colors.white01};
    font-size: ${customTheme.fontSize[12]};
    text-overflow: ellipsis;
    margin: 0px;
  `,
  SpanSize: styled.p`
    color: ${customTheme.colors.white01};
    font-size: ${customTheme.fontSize[10]};
    text-overflow: ellipsis;
    margin: 0px;
  `,
  UploadMessage: styled.p<{ type?: string }>`
    display: flex;
    color: ${(props: any) => messageColors[props.type || 'default']};
    justify-content: center;
    align-items: center;
    padding: 15px 0;
  `,
  LineUploadIndicador: styled.div`
    display: grid;
    align-items: center;
    justify-content: flex-end;
  `,
  LineProgress: styled.div`
    display: flex;
    justify-content: flex-end;
  `,
  IconCancelar: styled(CancelIcon)`
    color: ${customTheme.colors.white01};
    cursor: pointer;
  `
}
