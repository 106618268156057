import { CircularProgress, Grid } from '@material-ui/core'
import styled from 'styled-components'
import { ContainerSombra } from '../../components/ContainerSombra'

export const controleInadimplenciasStyles = {
  Container: styled(ContainerSombra).attrs((cor: { cor: string }) => cor)`
    padding: 24px;
    border-color: ${(props) => props.cor};
    border-style: solid;
    border-width: 1px;
  `,
  CircularProgress: styled(CircularProgress)`
    margin-top: 24px;
  `,
  Box: styled(ContainerSombra)`
    padding: 16px;
    margin-top: 32px;
  `,
  GridContainer: styled(Grid)`
    margin-top: 16px;
  `
}
