import { IOProps } from 'react-compose-io'
import { MoedaColoridaIO } from './MoedaColorida.IO'

export type MoedaColoridaProps = {
  valor: number
}

export function MoedaColoridaView({ _io }: IOProps<MoedaColoridaIO, MoedaColoridaProps>) {
  return (
    <>
      <span style={{ color: _io.cor }}>{_io.sinal}</span>
      <span>{_io.valorFormatado}</span>
    </>
  )
}
