import {
  EfetivarOPsTitulosRequest,
  ObterOpsTituloPorPedidoResponse,
  RegularizacaoPendenteForm,
  ResponseRegularizacaoPendente,
  ValorCiclosPendentes
} from '../../../types/regularizacaoOPsTitulosTypes'
import RegularizacaoOPsTitulosService from './regularizacaoOPsTitulosService'

const buscarCiclosPendentes = async (dataInicio: string, dataFim: string): Promise<ValorCiclosPendentes> =>
  RegularizacaoOPsTitulosService.buscarResumoRegularizacaoCiclos(dataInicio, dataFim)

const buscarEfetivarRegularizacaoCiclos = async (idPedido: string, clienteId?: string): Promise<ObterOpsTituloPorPedidoResponse> =>
  RegularizacaoOPsTitulosService.buscarEfetivarRegularizacaoCiclos(idPedido, clienteId)

const efetivarRegularizacaoCiclos = async (dataFormulario: EfetivarOPsTitulosRequest): Promise<boolean> =>
  RegularizacaoOPsTitulosService.efetivarRegularizacaoCiclos(dataFormulario)

const buscarRegularizacoesPendentes = async (controleComissaoPaginadoRequest: RegularizacaoPendenteForm): Promise<ResponseRegularizacaoPendente> =>
  RegularizacaoOPsTitulosService.buscarRegularizacoesPendentes(controleComissaoPaginadoRequest)

export default {
  buscarCiclosPendentes,
  buscarRegularizacoesPendentes,
  buscarEfetivarRegularizacaoCiclos,
  efetivarRegularizacaoCiclos
}
