import Grid from '@material-ui/core/Grid'
import styled from 'styled-components'
import { ContainerSombra } from '../../../../components/ContainerSombra'

export const dadosHistoricoStyles = {
  Box: styled(ContainerSombra)`
    padding: 16px;
    margin: 32px 24px 0px 24px;
  `,
  Imagem: styled.img`
    width: 108px;
    height: 108px;
  `,
  GridContainer: styled(Grid)`
    margin-top: 16px;
  `
}
