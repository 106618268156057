import { useForm, useWatch } from 'react-hook-form'
import { selecionarParceiroStyles } from './SelecionarParceiro.style'

export function selecionarParceiroIO() {
  const { handleSubmit, control } = useForm<{ parceiroId: number }>({})

  const parceiroIdSelecionado = useWatch({
    name: 'parceiroId',
    control
  })

  return {
    styles: selecionarParceiroStyles,
    control,
    handleSubmit,
    parceiroIdSelecionado
  }
}

export type SelecionarParceiroIO = ReturnType<typeof selecionarParceiroIO>
