import { ptBR } from '@material-ui/core/locale'
import { createTheme } from '@material-ui/core/styles'

type StatusPalette = {
  blue: React.CSSProperties['color']
  green: React.CSSProperties['color']
  orange: React.CSSProperties['color']
  purple: React.CSSProperties['color']
  yellow: React.CSSProperties['color']
}

type FeedbackPaltte = {
  error: React.CSSProperties['color']
  warning: React.CSSProperties['color']
  success: React.CSSProperties['color']
}

type IconsPalette = {
  default: React.CSSProperties['color']
}

type InformacoesCargaPalette = {
  orange: React.CSSProperties['color']
  blue: React.CSSProperties['color']
  green: React.CSSProperties['color']
}

type InputTextPalette = {
  primary: React.CSSProperties['color']
  secondary: React.CSSProperties['color']
}

type ChipPalette = {
  main: React.CSSProperties['color']
}
declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    status: StatusPalette
    icons: IconsPalette
    feedback: FeedbackPaltte
    informacoesCargaPalette: InformacoesCargaPalette
    inputTextPalette: InputTextPalette
    chipPalette: ChipPalette
  }
  interface PaletteOptions {
    status: StatusPalette
    icons: IconsPalette
    feedback: FeedbackPaltte
    informacoesCargaPalette: InformacoesCargaPalette
    inputTextPalette: InputTextPalette
    chipPalette: ChipPalette
  }
}

// Create a theme instance.
const theme = createTheme(
  {
    palette: {
      primary: {
        main: '#10A4E6'
      },
      secondary: {
        main: '#004282'
      },
      error: {
        main: '#E73A33'
      },
      warning: {
        main: '#FBC436'
      },
      success: {
        main: '#3EB96F'
      },
      info: {
        main: '#5BA2FF'
      },
      background: {
        default: '#FFFFFF'
      },
      text: {
        primary: '#404040',
        secondary: '#6D7384',
        disabled: '#919090'
      },
      status: {
        blue: '#86BAFF',
        green: '#8EDF91',
        orange: '#FFB876',
        purple: '#BB86FF',
        yellow: '#FFD976'
      },
      icons: {
        default: '#A7ADBF'
      },
      feedback: {
        error: '#FAD8D6',
        warning: '#FEF3D7',
        success: '#D8F1E2'
      },
      informacoesCargaPalette: {
        orange: '#FBB371',
        blue: '#80B7FF',
        green: '#8EDBB2'
      },
      inputTextPalette: {
        primary: '#919090',
        secondary: '#4E4B59'
      },
      chipPalette: {
        main: '#E9F6FF'
      }
    },
    typography: {
      fontFamily: 'Rubik',
      button: {
        fontWeight: 500
      },
      h5: {
        fontWeight: 500,
        color: '#19202D',
        marginTop: '24px',
        marginBottom: '16px'
      },
      h6: {
        fontWeight: 500,
        color: '#19202D'
      },
      subtitle1: {
        color: '#3A4050'
      }
    }
  },
  ptBR
)

export default theme
