import { ORDEM_CRESCENTE } from '../../constants/utilConstants'
import { HeadCell } from '../../interfaces/interfaces'
import { enhancedTableHeadStyles } from './EnhancedTableHead.style'
import { EnhancedTableProps } from './EnhancedTableHead.View'

export function enhancedTableHeadIO({ onRequestSort, order, orderBy }: EnhancedTableProps) {
  const createSortHandler = (property: keyof any) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property)
  }

  function ordenacaoPadrao(headCell: HeadCell): 'desc' | 'asc' | undefined {
    return orderBy === headCell.id ? order : ORDEM_CRESCENTE
  }

  return {
    styles: enhancedTableHeadStyles,
    createSortHandler,
    ordenacaoPadrao
  }
}

export type EnhancedTableHeadIO = ReturnType<typeof enhancedTableHeadIO>
