import { Avatar, Chip } from '@material-ui/core'
import styled from 'styled-components'
import { customTheme } from '../../../styles/customTheme'

export const cabecalhoDebitosStyles = {
  Avatar: styled(Avatar)`
    .MuiAvatar-img {
      height: 40px;
      width: 40px;
      object-position: top;
      object-fit: none;
    }
  `,
  Chip: styled(Chip).attrs((cor: { cor: string }) => cor)`
    margin-left: 16px;
    height: 48px;
    color: ${customTheme.colors.black};
    border-color: ${(props) => props.cor};
    font-size: ${customTheme.fontSize[16]};
    border-radius: 50px;
  `,
  ContainerButtons: styled.div`
    display: flex;
    gap: 8px;
    margin-bottom: 8px;
  `,
  ContainerFormTitulo: styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 22px;
  `
}
