import { Typography } from '@material-ui/core'
import styled from 'styled-components'
import { ContainerSombra } from '../../components/ContainerSombra'

export const controleDebitosRecebiveisStyles = {
  Titulo: styled(Typography).attrs({ variant: 'h5' })`
    padding: 16px 0px;
    font-weight: 500;
  `,
  Container: styled(ContainerSombra).attrs((cor: { cor: string }) => cor)`
    padding: 24px;
    border-color: ${(props) => props.cor};
    border-style: solid;
    border-width: 1px;
  `
}
