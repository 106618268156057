import { Popover } from '@material-ui/core'
import styled from 'styled-components'

export const popoverFiltroPedidoMktStyles = {
  Popover: styled(Popover)`
    .MuiPaper-rounded {
      border-radius: 10px;
    }
  `,
  ContainerPopoverPedidoMkt: styled.div`
    padding: 12px;
    overflow: hidden;
    width: 300px;
  `
}
