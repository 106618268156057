import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import parceiroManager from '../../services/api/parceiro/parceiroManager'
import { loadingReset, loadingUpdate } from '../../store/actions/loadingAction'
import { RegraAssociacaoForm, RegraAssociacao } from '../../types/associacaoApiTypes'
import AssociacaoManager from '../../services/api/associacao/associacaoManager'
import { TipoLancamentoParceiro } from '../../types/parceiroApiTypes'
import { converterToAcaoAssociacaoForm, converterToCondicaoAssociacaoForm } from '../../utils/regraFormsUtils'
import { FormAssociacao } from './components/FormAssociacao/index'
import { toast } from 'react-toastify'
import msgConstants from '../../constants/msgConstants'

export const Associcao = () => {
  const { parceiroId, regraId } = useParams<{ parceiroId: string; regraId: string }>()
  const [tipoLancamentoList, setTipoLancamentoList] = useState<TipoLancamentoParceiro[]>([])
  const [regraAssociacaoEditar, setRegraAssociacaoEditar] = useState<RegraAssociacaoForm>()
  const dispatch = useDispatch()

  useEffect(() => {
    const fetchData = async () => {
      const response: TipoLancamentoParceiro[] = await parceiroManager.buscarTipoLancamentoParceiro(parceiroId)
      setTipoLancamentoList(response)
    }
    fetchData()
  }, [parceiroId])

  useEffect(() => {
    const buscarRegraAssociacao = async () => {
      if (parceiroId && regraId) {
        dispatch(loadingUpdate(true))
        AssociacaoManager.buscarRegra(parceiroId, regraId)
          .then((response: RegraAssociacao) => {
            setRegraAssociacaoEditar({
              ...response,
              acoes: converterToAcaoAssociacaoForm(response.acoes),
              condicoes: converterToCondicaoAssociacaoForm(response.condicoes)
            })
          })
          .catch(() => toast.error(msgConstants.USO_GENERICO.erroBusca))
          .finally(() => dispatch(loadingReset()))
      }
    }

    buscarRegraAssociacao()
  }, [dispatch, parceiroId, regraId])

  return (
    <>
      {regraId ? (
        <>
          {regraAssociacaoEditar && (
            <FormAssociacao
              formularioEdicao={true}
              tituloFormulario={'Editar nova associação'}
              tipoLancamento={tipoLancamentoList}
              regraAssociacaoEditar={regraAssociacaoEditar}
            />
          )}
        </>
      ) : (
        <FormAssociacao formularioEdicao={false} tituloFormulario={'Adicionar nova associação'} tipoLancamento={tipoLancamentoList} />
      )}
    </>
  )
}
