// eslint-disable-next-line max-len
import { CabecalhoTabelaDetalheControleComissaoPedido } from '../../../../components/TabelaDetalheControleComissaoPedido/TabelaDetalheControleComissaoPedido.View'
import { DetalheControleComissaoPedidoStyles } from './DetalheControleComissaoPedido.styles'
import { DetalheControleComissaoPedidoResponse } from '../../../../types/controleComissaoTypes'
import { ItemDetalheControleComissaoPedido } from '../ItemDetalheControleComissaoPedido'
import { deDetalheControleComissaoPedidoParaChave } from '../../../../utils/controleComissaoUtils'
import { useConciliacaoContext } from '../../../../contexts/conciliacao-context'

const cabecalhosTabela: CabecalhoTabelaDetalheControleComissaoPedido[] = [
  {
    descricao: 'SKU'
  },
  {
    descricao: 'Parcela'
  },
  {
    descricao: 'Data de Processamento do Ciclo'
  },
  {
    descricao: 'Nº do Ciclo'
  },
  {
    descricao: 'Valor Recebido'
  },
  {
    descricao: 'Tipo de Lançamento do parceiro'
  },
  {
    descricao: 'Comissão Cobrada'
  },
  {
    descricao: 'Comissão Devida'
  },
  {
    descricao: 'Diferença'
  },
  {
    descricao: 'Classificação sa Análise'
  }
]

export function detalheControleComissaoPedidoIO() {
  const { checkListSelecionados, handleClickCheckList } = useConciliacaoContext()
  const renderLinhasTabela = (detalheControleComissaoPedido: DetalheControleComissaoPedidoResponse) => {
    const chave: string = deDetalheControleComissaoPedidoParaChave(detalheControleComissaoPedido)
    return (
      <ItemDetalheControleComissaoPedido
        key={chave}
        checkListSelecionados={checkListSelecionados}
        item={detalheControleComissaoPedido}
        handleClickCheckList={handleClickCheckList}
        chave={chave}
      />
    )
  }

  return {
    styles: DetalheControleComissaoPedidoStyles,
    cabecalhosTabela,
    renderLinhasTabela,
    checkListSelecionados
  }
}

export type DetalheControleComissaoPedidoIO = ReturnType<typeof detalheControleComissaoPedidoIO>
