import { Typography } from '@material-ui/core'
import { Variant } from '@material-ui/core/styles/createTypography'
import styled from 'styled-components'
import { customTheme } from '../../styles/customTheme'

export const cabecalhoExportarRelatorioStyles = {
  Titulo: styled(Typography)`
    display: flex;
    align-items: center;
    font-size: ${(props: { variant?: Variant }) => (props.variant ? '' : customTheme.fontSize[18])};
    font-weight: 500;
    color: ${customTheme.colors.cinza09};
  `
}
