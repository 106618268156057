export const VAZIO = ''

export const ESPACO = ' '

export const TIPO_PARAMETRO = {
  criterio: 1,
  coluna: 2
}

export const ORDEM_CRESCENTE = 'asc'
export const ORDEM_DECRESCENTE = 'desc'

export const MESES = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro']
export const MESES_ABREVIADOS = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez']

export const TIPO_VALOR_GRAFICO: { MOEDA: 'moeda'; UNIDADE: 'unidade' } = {
  MOEDA: 'moeda',
  UNIDADE: 'unidade'
}

export const VALOR_ESCALA_GRAFICO = {
  UM: 1,
  CEM: 100,
  MIL: 1000,
  MILHAO: 1000000
}

export const MESES_ABREVIADOS_2_MESES = new Map<string, string>([
  ['Jan', 'Janeiro'],
  ['Fev', 'Fevereiro'],
  ['Mar', 'Março'],
  ['Abr', 'Abril'],
  ['Mai', 'Maio'],
  ['Jun', 'Junho'],
  ['Jul', 'Julho'],
  ['Ago', 'Agosto'],
  ['Set', 'Setembro'],
  ['Out', 'Outubro'],
  ['Nov', 'Novembro'],
  ['Dez', 'Dezembro']
])
