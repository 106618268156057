import { TextField } from '@material-ui/core'
import styled from 'styled-components'
import { ContainerSombra } from '../../components/ContainerSombra'

export const detalhesCicloPedidoStyles = {
  Container: styled(ContainerSombra)`
    flex-grow: 1;
    padding: 24px;
    margin-top: 16px;
  `,
  BoxTabela: styled(ContainerSombra)`
    padding: 20px 24px;
    margin-top: 16px;
  `,
  ContainerFormDataButton: styled.div`
    gap: 8px;
    display: flex;
    align-items: center;
    margin-bottom: 40px;
  `,
  TextFieldDataFim: styled(TextField)`
    .MuiFormHelperText-root {
      position: absolute;
      margin-top: 40px;
    }
  `
}
