import ListItem from '@material-ui/core/ListItem'
import React, { forwardRef } from 'react'
import { NavLink, NavLinkProps } from 'react-router-dom'

export interface AppMenuItemComponentProps {
  className?: string
  link?: string | null
  onClick?: (event: React.MouseEvent<HTMLElement>) => void
}

const AppMenuItemComponent: React.FC<React.PropsWithChildren<AppMenuItemComponentProps>> = (props) => {
  const { className, onClick, link, children } = props

  if (!link || typeof link !== 'string') {
    return (
      <ListItem button className={className} onClick={onClick}>
        {children}
      </ListItem>
    )
  }

  return (
    <ListItem
      button
      className={className}
      component={forwardRef((elementosMenu: NavLinkProps, ref: any) => (
        <NavLink exact {...elementosMenu} innerRef={ref} />
      ))}
      to={link}
    >
      {children}
    </ListItem>
  )
}

export default AppMenuItemComponent
