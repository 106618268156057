import { useState } from 'react'
import { DetalheControleComissaoPedidoResponse } from '../types/controleComissaoTypes'
import { deDetalheControleComissaoPedidoParaChave, handleSelecao } from '../utils/controleComissaoUtils'

export default function useComissaoPedido() {
  const [checkListSelecionados, setCheckListSelecionados] = useState<string[]>([])
  const [checkListExternoSelecionados, setCheckListExternoSelecionados] = useState<string[]>([])

  function handleClickCheckList(chave: string) {
    const exteriorChave = chave.match('[^/]+(/[^/]+)?') || ''
    if (checkListExternoSelecionados.includes(exteriorChave[0])) {
      setCheckListExternoSelecionados(checkListExternoSelecionados.filter((item) => item !== exteriorChave[0]))
    }
    handleSelecao<string>(chave, setCheckListSelecionados)
  }

  function handleCheckClickListExterior(detalhes: DetalheControleComissaoPedidoResponse[], chave: string) {
    const chaves = detalhes.map(deDetalheControleComissaoPedidoParaChave)

    setCheckListExternoSelecionados((listaExterior) => {
      if (listaExterior.includes(chave)) {
        setCheckListSelecionados(checkListSelecionados.filter((key) => !chaves.includes(key)))
        return listaExterior.filter((item) => item !== chave)
      } else {
        setCheckListSelecionados(Array.from(new Set([...checkListSelecionados, ...chaves])))
        return [...listaExterior, chave]
      }
    })
  }

  function handleCleanAllCheckList() {
    setCheckListSelecionados([])
    setCheckListExternoSelecionados([])
  }

  function isCheckListExteriorSelecionado(chave: string) {
    return checkListExternoSelecionados.includes(chave)
  }

  function isCheckListSelecionado(chave: string) {
    return checkListSelecionados.includes(chave)
  }

  return {
    checkListSelecionados,
    handleClickCheckList,
    handleCheckClickListExterior,
    handleCleanAllCheckList,
    isCheckListSelecionado,
    isCheckListExteriorSelecionado
  }
}
