import { BotaoProps } from './Botao.View'
import { botaoStyles } from './Botao.styles'

export function botaoIO({ cor }: BotaoProps) {
  return {
    styles: botaoStyles,
    corBotao: cor ? cor : 'primaria'
  }
}
export type BotaoIO = ReturnType<typeof botaoIO>
