import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import React from 'react'
import { CircularProgressbar } from 'react-circular-progressbar'
import { IOProps } from 'react-compose-io'
import Dropzone from 'react-dropzone'
import { validarTexto } from '../../../../utils/stringUtils'
import { UploadPlanilhaIO } from './UploadPlanilha.IO'

export type UploadPlanilhaProps = {
  setArquivoId: any
}

export function UploadPlanilhaView({ _io }: IOProps<UploadPlanilhaIO, UploadPlanilhaProps>) {
  const { UploadMessage, Container, DropContainer, DropSuccess, SpanSize, SpanTitle, LineUploadIndicador, LineProgress, IconCancelar } = _io.styles

  const renderizarMensagemDrag = (isDragActive: any) => {
    if (!isDragActive) {
      return (
        <UploadMessage>
          Arraste & solte a planilha parceiro ou <span className="filepond--label-action">&nbsp;clique para pesquisar</span>
        </UploadMessage>
      )
    }

    return <UploadMessage>Solte o arquivo aqui!</UploadMessage>
  }

  return (
    <Container>
      <Dropzone
        maxFiles={1}
        accept={['.csv', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel']}
        onDropAccepted={_io.handleSubmit}
      >
        {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
          <DropContainer {...getRootProps()} isDragActive={isDragActive} isDragReject={isDragReject}>
            {!_io.visible && (
              <>
                <input placeholder="Upload" {...getInputProps()} />
                {renderizarMensagemDrag(isDragActive)}
              </>
            )}

            {_io.visible && (
              <DropSuccess>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={6} sm={6}>
                    <SpanTitle>{validarTexto(_io.nomeArquivo, 'Nome arquivo')}</SpanTitle>
                    <SpanSize>{validarTexto('' + _io.tamanhoDoArquivo, 'Tamanho arquivo')}</SpanSize>
                  </Grid>
                  <Grid item xs={5} sm={5}>
                    <LineUploadIndicador>
                      {_io.envioArquivos && (
                        <>
                          <SpanTitle>Carregando {_io.progress < 100 ? `${_io.progress}%` : 'Completo'}</SpanTitle>
                          <SpanSize>Clique para carragar outro arquivo</SpanSize>
                        </>
                      )}
                      {_io.erroUpload && (
                        <>
                          <SpanTitle>Falha no carregamento</SpanTitle>
                          <SpanSize>Clique para tentar novamente</SpanSize>
                        </>
                      )}
                    </LineUploadIndicador>
                  </Grid>
                  <Grid item xs={1} sm={1}>
                    <LineProgress>
                      {_io.carregandoEnvioArquivos ? <CircularProgress size={24} variant="indeterminate" /> : <></>}
                      {!_io.complete ? (
                        _io.envioArquivos && (
                          <CircularProgressbar value={_io.progress} styles={{ root: { width: 24 }, path: { stroke: '#7159c1' } }} strokeWidth={10} />
                        )
                      ) : (
                        <IconCancelar onClick={_io.handleReset} />
                      )}
                    </LineProgress>
                  </Grid>
                </Grid>
              </DropSuccess>
            )}
          </DropContainer>
        )}
      </Dropzone>
      {_io.arquivos?.length > 0 ? (
        <TableContainer component={Paper} style={{ overflow: 'auto', maxHeight: 500 }}>
          <Table stickyHeader aria-label="simple table" size="small">
            <TableHead>
              <TableRow>
                {_io.cabecalho.map(
                  (
                    cabecalhoUpload?: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null,
                    cabecalhoPlanilhaIndex?: React.Key | null
                  ) => (
                    <TableCell align="center" key={cabecalhoPlanilhaIndex} size="small">
                      {cabecalhoUpload}
                    </TableCell>
                  )
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {_io.linhasCarregadas.map((linhaTabela, linhaTabelaIndex) => (
                <TableRow key={linhaTabelaIndex}>
                  {_io.cabecalho.map((item: string | number, indexCabecalho: number) => (
                    <TableCell key={indexCabecalho} component="th" scope="row" size="small">
                      {'' + linhaTabela[indexCabecalho]}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
              <TableRow id="sentinela">
                {_io.loading && (
                  <TableCell colSpan={10} size="medium" align="center">
                    <CircularProgress disableShrink />
                  </TableCell>
                )}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      ) : null}
    </Container>
  )
}
