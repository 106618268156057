import { Grid } from '@material-ui/core'
import styled from 'styled-components'
import { ContainerSombra } from '../ContainerSombra'

export const boxValorStyles = {
  ContainerSombra: styled(ContainerSombra).attrs((props: { heigth?: string; width?: string }) => props)`
    padding: 16px;
    width: ${(props) => (props.width ? props.width : '360px')};
    height: ${(props) => props.heigth};
    display: flex;
    align-items: center;
  `,
  GridText: styled(Grid).attrs((props: { width?: string }) => props)`
    width: ${(props) => (props.width ? props.width : '230px')};
  `,
  Imagem: styled.img`
    padding: 8px;
  `
}
