import { useState } from 'react'
import { uploadPlanilhaRegularizacaoStyle } from './UploadPlanilhaRegularizacao.styles'
import { UploadPlanilhaRegularizacaoProps } from './UploadPlanilhaRegularizacao.View'

export function uploadPlanilhaRegularizacaoIO({ validarPlanilha, setArquivoId }: UploadPlanilhaRegularizacaoProps) {
  const [dropVisivel, setDropVisivel] = useState(false)
  const [uploadSucesso, setUploadSucesso] = useState(false)
  const [arquivo, setArquivo] = useState<FormData>()
  const [nomeArquivo, setNomeArquivo] = useState('')
  const [tamanhoArquivo, setTamanhoArquivo] = useState<number>()

  const onArquivoAceito = (arquivosPlanilhaRegularizacao: File[]) => {
    setDropVisivel(true)

    arquivosPlanilhaRegularizacao.forEach((planilhaRegularizacao: File) => {
      setNomeArquivo(planilhaRegularizacao.name)
      setTamanhoArquivo(planilhaRegularizacao.size)

      setUploadSucesso(true)

      const formData: FormData = new FormData()
      formData.append('file', planilhaRegularizacao, planilhaRegularizacao.name)

      setArquivo(formData)
      setArquivoId(planilhaRegularizacao.name)
    })
  }

  const handleCancelar = () => {
    setDropVisivel(false)
    setUploadSucesso(false)
    setArquivo(undefined)
  }

  const handleConfirmar = () => {
    validarPlanilha(arquivo)
    handleCancelar()
  }

  return {
    styles: uploadPlanilhaRegularizacaoStyle,
    onArquivoAceito,
    dropVisivel,
    uploadSucesso,
    nomeArquivo,
    tamanhoArquivo,
    handleCancelar,
    handleConfirmar
  }
}

export type UploadPlanilhaRegularizacaoIO = ReturnType<typeof uploadPlanilhaRegularizacaoIO>
