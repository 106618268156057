import { CabecalhoIconeExportarRelatorioIO } from './CabecalhoIconeExportarRelatorio.IO'
import { Grid } from '@material-ui/core'
import SomatorioTotalIcone from '../../assets/icons/somatorio_total.svg'
import { IOProps } from 'react-compose-io'
import { customTheme } from './../../styles/customTheme'
import { BotaoIconeExportar } from '../BotaoIconeExportar/BotaoIconeExportar'

export type CabecalhoIconeExportarRelatorioProps = {
  url: string
  icone: string
  descricao?: string
}

export function CabecalhoIconeExportarRelatorioView({
  _io,
  url,
  icone,
  descricao
}: IOProps<CabecalhoIconeExportarRelatorioIO, CabecalhoIconeExportarRelatorioProps>) {
  const { GridIconText, Texto } = _io.styles

  return (
    <Grid container spacing={2} justifyContent="space-between" alignItems={descricao ? 'center' : 'flex-start'}>
      <GridIconText item>
        <img src={icone} />
        {descricao && (
          <Texto fontWeight="500" fontSize={customTheme.fontSize[18]} colorText={customTheme.colors.grey23}>
            {descricao}
          </Texto>
        )}
      </GridIconText>
      <Grid item>{icone !== SomatorioTotalIcone && <BotaoIconeExportar url={url} />}</Grid>
    </Grid>
  )
}
