import { FormControl, TextField, Typography } from '@material-ui/core'
import styled from 'styled-components'
import { customTheme } from '../../../../styles/customTheme'

export const dadosTiposLancamentosStyles = {
  Texto: styled(TextField).attrs({
    variant: 'outlined',
    size: 'small',
    fullWidth: true
  })``,
  FormularioControle: styled(FormControl).attrs({
    variant: 'outlined',
    size: 'small',
    fullWidth: true
  })``,
  TextoErroPreenchimento: styled(Typography).attrs({
    variant: 'caption'
  })`
    color: ${customTheme.colors.red06};
  `
}
