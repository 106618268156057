import { CabecalhoExportarRelatorioIO } from './CabecalhoExportarRelatorio.IO'
import { Grid } from '@material-ui/core'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import { IOProps } from 'react-compose-io'
import { TooltipComponent } from '../Tooltip/Tooltip'
import { Variant } from '@material-ui/core/styles/createTypography'
import { BotaoIconeExportar } from '../BotaoIconeExportar/BotaoIconeExportar'

export type CabecalhoExportarRelatorioProps = {
  url: string
  tituloCabecalho: string
  textoTooltip?: string
  fontSize?: Variant
}

export function CabecalhoExportarRelatorioView({
  _io,
  url,
  textoTooltip,
  tituloCabecalho,
  fontSize
}: IOProps<CabecalhoExportarRelatorioIO, CabecalhoExportarRelatorioProps>) {
  const { Titulo } = _io.styles

  return (
    <Grid container spacing={2} justifyContent="space-between" alignItems="center">
      <Grid item>
        <Titulo variant={fontSize}>
          {tituloCabecalho}
          {textoTooltip && <TooltipComponent texto={textoTooltip} Icone={HelpOutlineIcon} color={'action'} />}
        </Titulo>
      </Grid>
      <Grid item>{url != '' && <BotaoIconeExportar url={url} />}</Grid>
    </Grid>
  )
}
