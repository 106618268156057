import { TextField } from '@material-ui/core'
import { Theme, withStyles } from '@material-ui/core/styles'

export default withStyles((theme: Theme) => ({
  root: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        // Cor de borda para o input
        borderColor: theme.palette.inputTextPalette.primary
      },
      '&:not(.Mui-error):not(.Mui-disabled):hover fieldset': {
        // Cor de hover para o input
        borderColor: theme.palette.primary.main
      },
      '& .Mui-focused fieldset': {
        // Cor padrão para input focado
        color: theme.palette.inputTextPalette.secondary
      }
    },
    '& .MuiInputLabel-root': {
      '&:not(.Mui-error)': {
        // Cor padrão para a label do input
        color: theme.palette.inputTextPalette.primary
      },
      '&.Mui-disabled': {
        // Cor padrão para label input desabilitado
        color: theme.palette.text.primary
      },
      '&.Mui-focused': {
        // Cor padrão para label input focado
        color: theme.palette.inputTextPalette.secondary
      }
    },
    '& .MuiInputBase-root': {
      // Cor padrão para o texto inserido no input
      color: theme.palette.text.primary
    },
    '& .MuiInputBase-root.Mui-disabled': {
      color: theme.palette.text.disabled
    }
  }
}))(TextField)
