import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import msgConstants from '../../../../constants/msgConstants'
import parceiroManager from '../../../../services/api/parceiro/parceiroManager'
import { loadingReset, loadingUpdate } from '../../../../store/actions/loadingAction'
import { ITipoLancamentoParceiroForm, TipoLancamentoParceiro } from '../../../../types/parceiroApiTypes'
import { ModalTipoLancamentoParceiroProps } from './ModalTipoLancamentoParceiro.View'

export function modalTipoLancamentoParceiroIO({ isClosed, atualizarDados, dadosLinha }: ModalTipoLancamentoParceiroProps) {
  const { parceiroId } = useParams<{ parceiroId: string }>()

  const dispatch = useDispatch()

  async function editarTipoLancamento(tipoLancamentoParceiroForm: ITipoLancamentoParceiroForm, editarDadosLinha: TipoLancamentoParceiro) {
    editarDadosLinha.descricao = tipoLancamentoParceiroForm.descricao
    editarDadosLinha.sigla = tipoLancamentoParceiroForm.sigla
    editarDadosLinha.tipoContabilizacaoId = tipoLancamentoParceiroForm.tipoContabilizacaoId

    dispatch(loadingUpdate(true))
    await parceiroManager
      .editarTipolancamentoParceiros(parceiroId, editarDadosLinha?.tipoLancamentoParceiroId, editarDadosLinha)
      .then(() => {
        toast.success(msgConstants.USO_GENERICO.edicao_sucesso)
        dispatch(loadingReset())
        atualizarDados()
      })
      .catch((error: Error) => {
        toast.error(error.message)
      })
      .finally(() => {
        isClosed()
        dispatch(loadingReset())
      })
  }

  async function adicionarTipoLancamento(item: ITipoLancamentoParceiroForm) {
    dispatch(loadingUpdate(true))
    await parceiroManager
      .cadastrarTipolancamentoParceiros(parceiroId, item)
      .then(() => {
        toast.success(msgConstants.USO_GENERICO.cadastro_sucesso)
        atualizarDados()
      })
      .catch((error: Error) => {
        toast.error(error.message)
      })
      .finally(() => {
        isClosed()
        dispatch(loadingReset())
      })
  }

  return {
    editarTipoLancamento,
    dadosLinha,
    salvarTipoLancamentoParceiro: (tipoLancamento: ITipoLancamentoParceiroForm) => {
      dadosLinha ? editarTipoLancamento(tipoLancamento, dadosLinha) : adicionarTipoLancamento(tipoLancamento)
    }
  }
}

export type ModalTipoLancamentoParceiroIO = ReturnType<typeof modalTipoLancamentoParceiroIO>
