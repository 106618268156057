import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import msgConstants from '../../../../constants/msgConstants'
import { IniciarNovoCicloForm } from '../../../../types/ciclosApiType'
import { modalNovoCicloStyles } from './ModalNovoCiclo.styles'
import { ModalNovoCicloProps } from './ModalNovoCiclo.View'

export function modalNovoCicloIO({ isClosed, handleConfirmar }: ModalNovoCicloProps) {
  const schema = yup
    .object({
      dataInicialParceiro: yup.string().required(msgConstants.USO_GENERICO.campo_obrigatorio),
      dataFinalPrevistaParceiro: yup
        .string()
        .required(msgConstants.USO_GENERICO.campo_obrigatorio)
        .test('valorMinimo', "O campo 'Data final do ciclo' deve ser maior ou igual que 'Data inicial do ciclo'", function (dataFinalPrevistaParceiro, form) {
          const dataInicial = '' + form.parent.dataInicialParceiro
          const dataFinal = '' + dataFinalPrevistaParceiro
          return new Date(dataInicial) <= new Date(dataFinal)
        })
    })
    .required()

  const {
    handleSubmit,
    control,
    formState: { errors },
    register,
    setValue
  } = useForm<IniciarNovoCicloForm>({
    defaultValues: {
      dataInicialParceiro: '',
      dataFinalPrevistaParceiro: '',
      comentariosCicloParceiro: ''
    },
    resolver: yupResolver(schema)
  })

  const fecharModal = () => {
    isClosed()
    setValue('dataInicialParceiro', '')
    setValue('dataFinalPrevistaParceiro', '')
    setValue('comentariosCicloParceiro', '')
  }

  const submit = (data: IniciarNovoCicloForm) => {
    handleConfirmar(data)
  }

  return {
    styles: modalNovoCicloStyles,
    schema,
    register,
    control,
    handleSubmit,
    errors,
    fecharModal,
    submit
  }
}

export type ModalNovoCicloIO = ReturnType<typeof modalNovoCicloIO>
