import { Grid } from '@material-ui/core'
import { IOProps } from 'react-compose-io'
import { Control, DeepMap, FieldError, UseFormHandleSubmit, UseFormSetValue } from 'react-hook-form'
import { CardValorQuantidade } from '../../../../components/CardValorQuantidade'
import { BuscarTitulosForm, CardTitulos, CardTitulosParceiro } from '../../../../types/titulosTypes'
import { valorMenorZero } from '../../../../utils/stringUtils'
import { CabecalhoListaParceiros } from '../CabecalhoListaParceiros/index'
import { GraficoDonut } from '../GraficoDonut/index'
import { ListaTitulosParceiros } from '../ListaTitulosParceiros/index'
import { TitulosAbertosIO } from './TitulosAbertos.IO'

export type TitulosAbertosProps = {
  setDataTituloComparacao: React.Dispatch<React.SetStateAction<string>>
  dataTituloComparacao: string
  setDataTituloPeriodo: React.Dispatch<React.SetStateAction<string>>
  dataTituloPeriodo: string
  dataPeriodoAlterada: string
  dataComparacaoAlterada: string
  setCarregarBotao: React.Dispatch<React.SetStateAction<boolean>>
  carregarBotao: boolean
  controleFormulario: Control<BuscarTitulosForm>
  setValue: UseFormSetValue<BuscarTitulosForm>
  handleSubmite: UseFormHandleSubmit<BuscarTitulosForm>
  gerarRelatorioTitulosAbertos: (buscarRelatorio: () => void, dataPesquisada: string, parceiroId?: number) => Promise<void>
  erroPreenchimento: DeepMap<BuscarTitulosForm, FieldError>
}

export function TitulosAbertosView({
  _io,
  carregarBotao,
  controleFormulario,
  handleSubmite,
  dataTituloComparacao,
  dataTituloPeriodo,
  erroPreenchimento
}: IOProps<TitulosAbertosIO, TitulosAbertosProps>) {
  const { ContainerCabecalho, Container } = _io.styles

  return (
    <>
      <CabecalhoListaParceiros
        controleFormulario={controleFormulario}
        limparFiltro={_io.limparFiltroPesquisa}
        buscarTitulos={handleSubmite(_io.handleBuscarTitulos)}
        atualizarTitulos={_io.atualizarTitulos}
        botaoCarregando={carregarBotao}
        tituloTela="Títulos abertos"
        titulosAbertos={true}
        tituloDataFinal="Comparar com:"
        tituloDataInicial="Período:"
        erroPreenchimento={erroPreenchimento}
        componenteId="titulosAbertos"
        dataUltimaAtualizacaoAbertos={_io.titulosPeriodo.createdAt}
      />
      {_io.titulosPeriodo.resumoGeral && _io.titulosPeriodo.resumoParceiros.length > 0 && (
        <div id="titulosAbertos">
          <ContainerCabecalho>
            <Grid container spacing={2}>
              {_io.titulosPeriodo.resumoGeral &&
                _io.mapearCardResumoGeral(CardTitulos.fromAberto(_io.titulosPeriodo.resumoGeral)).map((titulosGeral, indexTitulosGeral) => (
                  <Grid item xs={4} key={indexTitulosGeral}>
                    <CardValorQuantidade
                      textoTooltip={titulosGeral.tooltip}
                      tituloCard={titulosGeral.titulo}
                      valorTotal={titulosGeral.valor}
                      exibirBotaoExportar={titulosGeral.primeiroCard}
                      quantidade={titulosGeral.quantidade}
                      quantidadeTexto={'Qtd. de títulos'}
                      porcentagem={valorMenorZero(Number(titulosGeral.porcentagem), `${titulosGeral.porcentagem}`, `+ ${titulosGeral.porcentagem}`)}
                      dataPeriodo={_io.existeTitulosFiltrados ? `(${dataTituloPeriodo})` : ''}
                      valorPesquisado={_io.existeTitulosFiltrados}
                      dataComparacao={dataTituloComparacao}
                      exportarRelatorioParceiro={() => {
                        _io.exportarRelatorioPeriodo(dataTituloPeriodo)
                      }}
                      status={titulosGeral.status}
                      url={titulosGeral.url}
                      somenteQuantidade={false}
                    />
                  </Grid>
                ))}
              {_io.existeTitulosFiltrados &&
                _io.titulosComparacao.resumoGeral &&
                _io.mapearCardResumoGeral(CardTitulos.fromAberto(_io.titulosComparacao.resumoGeral)).map((tituloComparacao, indexTitulosComparacao) => (
                  <Grid item xs={4} key={indexTitulosComparacao}>
                    <CardValorQuantidade
                      textoTooltip={tituloComparacao.tooltip}
                      tituloCard={tituloComparacao.titulo}
                      valorTotal={tituloComparacao.valor}
                      quantidade={tituloComparacao.quantidade}
                      quantidadeTexto={'Qtd. de títulos'}
                      dataPeriodo={`(${dataTituloComparacao})`}
                      valorPesquisado={false}
                      dataComparacao={dataTituloComparacao}
                      exibirBotaoExportar={tituloComparacao.primeiroCard}
                      exportarRelatorioParceiro={() => {
                        _io.exportarRelatorioComparacao(dataTituloComparacao)
                      }}
                      status={tituloComparacao.status}
                      url={tituloComparacao.url}
                      somenteQuantidade={false}
                    />
                  </Grid>
                ))}
            </Grid>
          </ContainerCabecalho>
          <Container>
            <Grid container spacing={2}>
              <Grid item md={12} lg={8}>
                <ListaTitulosParceiros
                  tituloCard="Títulos em aberto por parceiros"
                  dataFinalFormulario={dataTituloComparacao}
                  dataInicialFormulario={dataTituloPeriodo || _io.dataAtualPadrao}
                  valorPesquisado={_io.existeTitulosFiltrados}
                  listaParceiros={_io.titulosPeriodo.resumoParceiros?.map((resumo) => CardTitulosParceiro.fromAberto(resumo))}
                  listaValorPesquisado={_io.titulosComparacao.resumoParceiros?.map((resumo) => CardTitulosParceiro.fromAberto(resumo))}
                  exportarRelatorioPeriodo={_io.exportarRelatorioPeriodo}
                  exportarRelatorioComparacao={_io.exportarRelatorioComparacao}
                  titulosAbertos={true}
                />
              </Grid>
              <Grid item md={12} lg={4}>
                <GraficoDonut
                  dataGrafico={_io.existeTitulosFiltrados ? `(${dataTituloPeriodo})` : ''}
                  listaTitulosParceiros={_io.titulosPeriodo.resumoParceiros?.map((resumo) => CardTitulosParceiro.fromAberto(resumo))}
                  textoValorTotal="Títulos abertos"
                />
                {_io.existeTitulosFiltrados && (
                  <GraficoDonut
                    textoValorTotal="Títulos abertos"
                    dataGrafico={`(${dataTituloComparacao})`}
                    listaTitulosParceiros={_io.titulosComparacao.resumoParceiros?.map((resumo) => CardTitulosParceiro.fromAberto(resumo))}
                  />
                )}
              </Grid>
            </Grid>
          </Container>
        </div>
      )}
    </>
  )
}
