import styled from 'styled-components'
import { ContainerSombra } from '../../../../components/ContainerSombra'

export const opsEmAbertoPorParceiroStyle = {
  Container: styled(ContainerSombra)`
    padding: 24px;
    margin: 8px;
  `,
  ContainerValorQuantidade: styled.div`
    margin: 24px 0px;
    display: flex;
    align-items: center;
    flex-direction: column;
  `
}
