import { Grid, MenuItem, FormControl, InputLabel, TableRow, Typography } from '@material-ui/core'
import { IOProps } from 'react-compose-io'
import { Controller } from 'react-hook-form'
import { CustomDialog } from '../../../../components/CustomDialog/CustomDialog'
import { customTheme } from '../../../../styles/customTheme'
import { ModalPriorizarIO } from './ModalPriorizar.IO'
import { GrupoRegra, RegraTransformacao } from '../../../../types/transformacaoApiTypes'
import { TabelaArrastavel } from '../../../../components/TabelaArrastavel'
import { Draggable, DraggableStateSnapshot, DraggableProvided } from 'react-beautiful-dnd'
import ReorderIcon from '@material-ui/icons/Reorder'
import { Botao } from '../../../../components/Botao'

export type ModalPriorizarProps = {
  isShow: boolean
  isClosed: () => void
  atualizarDados: () => void
  campos: string[]
  regrasList: RegraTransformacao[]
  parceiroId: string
}

export default function ModalPriorizarView({ _io, isShow, campos }: IOProps<ModalPriorizarIO, ModalPriorizarProps>) {
  const { Label, Chip, TableCell, GridContainer, Paper, MobileStepperView, Select } = _io.styles

  function linhaTabela(props: { item: RegraTransformacao; index: number }) {
    const { item, index } = props

    return (
      <Draggable key={item.regraId} draggableId={item.regraId.toString()} index={index}>
        {(draggableProvided: DraggableProvided, snapshot: DraggableStateSnapshot) => {
          return (
            <TableRow
              ref={draggableProvided.innerRef}
              {...draggableProvided.draggableProps}
              style={{
                ...draggableProvided.draggableProps.style,
                background: snapshot.isDragging ? customTheme.colors.grey17 : 'none'
              }}
            >
              <TableCell>
                <div {...draggableProvided.dragHandleProps}>
                  <ReorderIcon />
                </div>
              </TableCell>
              <TableCell>{item.nomeRegra}</TableCell>
              <TableCell>{item.campo}</TableCell>
              <TableCell>
                {item.grupos.map((grupo: GrupoRegra) => {
                  return <Chip key={grupo.grupoId} label={grupo.nome} />
                })}
              </TableCell>
              <TableCell>{item.ativo ? 'Ativo' : 'Inativo'}</TableCell>
            </TableRow>
          )
        }}
      </Draggable>
    )
  }

  return (
    <CustomDialog
      open={isShow}
      onClose={_io.fecharModal}
      id="modal-priorizar"
      titulo={_io.regrasFiltradas.length == 0 ? 'Filtrar' : 'Reordenar'}
      habilitarAcoes
      fecharHabilitado
      fecharModal={_io.fecharModal}
      handleVoltar={_io.regrasFiltradas.length == 0 ? undefined : _io.voltarPriorizacao}
      maxWidth={'lg'}
      actions={
        <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center">
          {_io.regrasFiltradas.length == 0 ? (
            <>
              <Grid item>
                <Botao textoBotao="Cancelar" estilo="text" cor="primaria" onClick={_io.fecharModal} />
              </Grid>
              <Grid item>
                <Botao textoBotao="Avançar" estilo="rounded" cor="primaria" onClick={_io.handleSubmit(_io.avancarPriorizacao)} />
              </Grid>
            </>
          ) : (
            <>
              <Grid item>
                <Botao textoBotao="Voltar" estilo="text" cor="primaria" onClick={_io.voltarPriorizacao} width="104px" />
              </Grid>
              <Grid item>
                <Botao textoBotao="Confirmar" estilo="rounded" cor="primaria" onClick={_io.handleSubmit(_io.atualizarPriorizacao)} />
              </Grid>
            </>
          )}
        </Grid>
      }
    >
      {_io.regrasFiltradas.length == 0 ? (
        <form>
          <Label variant="body1">Selecione o campo das regras que serão reordenadas.</Label>

          <Controller
            render={({ field }) => (
              <FormControl variant="outlined" size="small" fullWidth required>
                <InputLabel>Campo</InputLabel>
                <Select {...field} label="Campo" variant="outlined" error={_io.errors?.campo ? true : false}>
                  {campos.map((campo) => (
                    <MenuItem value={campo} key={campo}>
                      {campo}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            name="campo"
            control={_io.control}
          />
        </form>
      ) : (
        <>
          <Label variant="body1">Agora, organize as regras por ordem de prioridade.</Label>

          <Paper>
            <TabelaArrastavel
              itensTabela={_io.regrasFiltradas}
              setItensTabela={_io.setRegrasFiltradas}
              colunasTabela={[' ', 'Nome da parâmetro', 'Campo', 'Grupo', 'Status']}
              LinhaTabela={linhaTabela}
              itemKey="regraId"
            />
          </Paper>
        </>
      )}

      <Grid container justifyContent="center">
        <GridContainer container item sm={12}>
          <Grid item sm={6}>
            <Typography variant="body2">Filtrar</Typography>
          </Grid>
          <Grid item sm={6}>
            <Typography variant="body2">Reordenar</Typography>
          </Grid>
          <Grid item sm={12}>
            <MobileStepperView
              variant="progress"
              steps={5}
              position="static"
              activeStep={_io.progresso}
              nextButton={<Typography></Typography>}
              backButton={<Typography></Typography>}
            />
          </Grid>
        </GridContainer>
      </Grid>
    </CustomDialog>
  )
}
