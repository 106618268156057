import { Grid } from '@material-ui/core'
import styled from 'styled-components'
import { ContainerSombra } from '../../components/ContainerSombra'

type TamanhoImagem = {
  width: string
  height: string
}

export const loginStyles = {
  ContainerForm: styled(ContainerSombra)`
    width: 448px;
    padding: 38px;
  `,
  Img: styled.img`
    width: ${({ width }: TamanhoImagem) => width};
    height: ${({ height }: TamanhoImagem) => height};
  `,
  GridContainer: styled(Grid)`
    height: 100vh;
  `,
  GridHeader: styled(Grid)`
    margin-top: 16px;
  `
}
