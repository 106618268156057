import { IOProps } from 'react-compose-io'
import { OpsEmAbertoPorParceiroIO } from './OpsEmAbertoPorParceiro.IO'
import { Tipografia } from '../../../../components/Tipografia'
import Grid from '@material-ui/core/Grid/Grid'
import { Avatar, Divider } from '@material-ui/core'
import { customTheme } from './../../../../styles/customTheme'
import { formateCurrency } from '../../../../utils/stringUtils'
import { OpsEmAbertoResumoPorParceiro } from '../../../../types/regularizacaoOpsTypes'

export function OpsEmAbertoPorParceiroView({
  _io,
  valorTotal,
  quantidadeTotal,
  parceiro,
  urlLogo
}: IOProps<OpsEmAbertoPorParceiroIO, OpsEmAbertoResumoPorParceiro>) {
  const { Container, ContainerValorQuantidade } = _io.styles

  return (
    <Container>
      <Grid container spacing={2} alignItems="center" justifyContent="center">
        <Grid item>
          <Avatar src={urlLogo} />
        </Grid>
        <Grid item>
          <Tipografia fontSize={customTheme.fontSize[18]} fontWeight="500">
            {parceiro}
          </Tipografia>
        </Grid>
      </Grid>

      <Grid container justifyContent="space-evenly" spacing={2}>
        <Grid item>
          <ContainerValorQuantidade>
            <Tipografia fontSize={customTheme.fontSize[14]}>{"Valor total das OP's"}</Tipografia>
            <Tipografia fontSize={customTheme.fontSize[20]} fontWeight="500">
              {formateCurrency(valorTotal)}
            </Tipografia>
          </ContainerValorQuantidade>
        </Grid>
        <Grid item>
          <Divider orientation="vertical" />
        </Grid>
        <Grid item>
          <ContainerValorQuantidade>
            <Tipografia fontSize={customTheme.fontSize[14]}>{"Quantidade total das OP's"}</Tipografia>
            <Tipografia fontSize={customTheme.fontSize[20]} fontWeight="500">
              {quantidadeTotal.toLocaleString('pt-BR')}
            </Tipografia>
          </ContainerValorQuantidade>
        </Grid>
      </Grid>
    </Container>
  )
}
