import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import msgConstants from '../../../../constants/msgConstants'
import { useConciliacaoContext } from '../../../../contexts/conciliacao-context'
import { PAGES } from '../../../../router/routes'
import ciclosManager from '../../../../services/api/ciclos/ciclosManager'
import parceiroManager from '../../../../services/api/parceiro/parceiroManager'
import { loadingReset, loadingUpdate } from '../../../../store/actions/loadingAction'
import { arquivoTemp, CicloStatus } from '../../../../types/ciclosApiType'
import { Parceiro } from '../../../../types/parceiroApiTypes'
import { CicloEtapaEnum } from '../../../../utils/enum'
import { base64FileToCsvArray, csvArrayToFile, getExtensaoArquivo } from '../../../../utils/uploadFileUtils'
import { stylesCicloInformacao } from './CicloInformacao.style'
import { CicloInformacaoProps } from './CicloInformacao.View'

export function cicloInformacaoIO({ cicloId, parceiroId, opsSucesso, statusCiclo, updateStatus, setEnableConfirmar }: CicloInformacaoProps) {
  const { cicloDados } = useConciliacaoContext()
  const [uploadButton, setUploadButton] = useState<boolean>(false)
  const [cicloEfetivado, setCicloEfetivado] = useState<boolean>(false)
  const [habilitarAtualizar, setHabilitarAtualizar] = useState<boolean>(false)
  const [parceiro, setParceiro] = useState<Parceiro>()
  const [modalCancelamentoAberto, setModalCancelamentoAberto] = useState(false)
  const dispatch = useDispatch()
  const router = useHistory()

  const checkButton = useCallback(() => {
    let ultimoHistorico: CicloStatus

    if (statusCiclo.conciliacao.length > 0) {
      ultimoHistorico = statusCiclo.conciliacao[0]
    } else if (statusCiclo.validacao.length > 0) {
      ultimoHistorico = statusCiclo.validacao[0]
    } else if (statusCiclo.ops.length > 0) {
      ultimoHistorico = statusCiclo.ops[0]
    } else {
      ultimoHistorico = statusCiclo.internalizacao[0]
      ultimoHistorico.statusId === CicloEtapaEnum.aguardandoUploadPlanilha || ultimoHistorico.statusId === CicloEtapaEnum.uploadPlanilhaErros
        ? setUploadButton(true)
        : setUploadButton(false)
    }

    if (ultimoHistorico.statusTipo == 'SUCCESS' || opsSucesso === true) {
      setEnableConfirmar(true)
      if (ultimoHistorico.statusId === CicloEtapaEnum.conciliacaoFinanceiraSucesso || ultimoHistorico.statusId === CicloEtapaEnum.conciliacaoLoteEfetivado) {
        setCicloEfetivado(true)
      }
    }

    if (
      ultimoHistorico.statusId === CicloEtapaEnum.validacaoConciliacaoCicloSucesso ||
      ultimoHistorico.statusId === CicloEtapaEnum.validacaoConciliacaoCicloErros ||
      ultimoHistorico.statusId === CicloEtapaEnum.validacaoConciliacaoCicloErroInesperado ||
      ultimoHistorico.statusId === CicloEtapaEnum.conciliacaoFinanceiraErros
    )
      setHabilitarAtualizar(true)
    else setHabilitarAtualizar(false)
  }, [opsSucesso, setEnableConfirmar, statusCiclo.conciliacao, statusCiclo.internalizacao, statusCiclo.ops, statusCiclo.validacao])

  useEffect(() => {
    async function buscarParceiro() {
      await parceiroManager
        .buscarDadoParceiro(parceiroId)
        .then((response: Parceiro) => {
          setParceiro(response)
        })
        .catch(() => {
          toast.error(msgConstants.INICIAR_CICLO.parceiro_inexistente)
          router.push(PAGES.ciclos)
        })
        .finally(() => {
          checkButton()
        })
    }

    if (parceiroId > 0) buscarParceiro()
  }, [checkButton, parceiroId, router, statusCiclo])

  const arquivoSelecionado = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const arquivo: File = event.target.files[0]
      const arquivoExtensao = getExtensaoArquivo(arquivo.name)

      const arquivoReader = new FileReader()

      if (arquivoExtensao != undefined) {
        arquivoReader.onloadend = () => {
          const arquivoArray: any[] = base64FileToCsvArray(arquivoReader.result as string)

          const contentType = 'text/csv'
          const novoArquivo = csvArrayToFile(arquivoArray, arquivo.name, contentType)

          dispatch(loadingUpdate(true))
          ciclosManager
            .uploadArquivoTemp(arquivoExtensao.name, cicloId)
            .then((responseTemp: arquivoTemp) => {
              ciclosManager
                .uploadArquivoCiclo(responseTemp.url, novoArquivo, arquivoExtensao.value)
                .then(() => {
                  ciclosManager
                    .transicionarCiclo({ etapaCicloId: 'I', statusCicloId: 100 }, cicloId)
                    .then(() => {
                      updateStatus()
                      toast.success(msgConstants.ARQUIVO.processando)
                    })
                    .catch(() => {
                      toast.error(msgConstants.CICLO_FLUXO.erroTransicaoEtapa)
                    })
                })
                .catch(() => {
                  toast.error(msgConstants.ARQUIVO.erroUpload)
                })
                .finally(() => {
                  dispatch(loadingReset())
                })
            })
            .catch(() => {
              toast.error(msgConstants.ARQUIVO.erroUpload)
              dispatch(loadingReset())
            })
        }
        arquivoReader.readAsDataURL(arquivo)
      } else {
        toast.error(msgConstants.ARQUIVO.extensaoInvalida)
      }
    }
  }

  const cancelarCiclo = () => {
    ciclosManager
      .deletarCiclo(cicloId)
      .then(() => {
        setModalCancelamentoAberto(false)
        toast.success(msgConstants.CICLO_FLUXO.cicloCancelado)
        router.push(PAGES.ciclos)
      })
      .catch(() => {
        toast.error(msgConstants.CICLO_FLUXO.cicloCanceladoErro)
      })
  }

  return {
    styles: stylesCicloInformacao,
    cicloDados,
    cicloEfetivado,
    parceiro,
    modalCancelamentoAberto,
    setModalCancelamentoAberto,
    cancelarCiclo,
    arquivoSelecionado,
    uploadButton,
    habilitarAtualizar,
    inputFileOptions: '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
  }
}

export type CicloInformacaoIO = ReturnType<typeof cicloInformacaoIO>
