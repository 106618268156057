import React from 'react'
import { parametrosCicloPedidoStyles } from './ParametrosCicloPedido.styles'

export function parametrosCicloPedidoIO() {
  const [aba, setAba] = React.useState(0)

  const handleChange = (event: React.ChangeEvent<{}>, novaAba: number) => {
    setAba(novaAba)
  }

  return {
    styles: parametrosCicloPedidoStyles,
    handleChange,
    aba
  }
}

export type ParametrosCicloPedidoIO = ReturnType<typeof parametrosCicloPedidoIO>
