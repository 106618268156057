import { BaseClassificacao, Processamento } from './genericoType'
import { PerdasEfetivadas, ResponsePaginadoCargo } from './perdasTypes'
import { Paginacao } from './tabelaTypes'

export const DEBITO = {
  FINAL: 'Final'
}

export type ResponseDebitos = {
  dataAtualizacao: string
  processamento: Processamento
  resumosDebitoInicial: ResumosDebitoInicial
  resumosRepasse: ResumosRepasse
  resumoDebitoFinal: ResumoDebitoFinal
  emTransito: EmTransito
  semOcorrencia: SemOcorrencia
  recuperacao: Recuperacao
}

export type ResumosDebitoInicial = {
  porcentagemTotalAcumulada: number
  porcentagemTotalAcumuladaTransportadoraMartins: number
  porcentagemTotalAcumuladaTransportadoraParceira: number
  resumoDebitosTotal: ResumoDebitosGeralResponse
  resumoPorcentagens: ResumoPorcentagemGeralResponse
  resumoPorcentagensTransportadoraMartins: ResumoPorcentagemGeralResponse
  resumoPorcentagensTransportadoraParceira: ResumoPorcentagemGeralResponse
  resumoPorcentagensAcumulados: ResumoPorcentagemGeralResponse
  resumoPorcentagensAcumuladosTransportadoraMartins: ResumoPorcentagemGeralResponse
  resumoPorcentagensAcumuladosTransportadoraParceira: ResumoPorcentagemGeralResponse
  resumosDebitosPorParceiro: ResumoDebitosParceiroPorcentagemResponse[]
}

type ResumosRepasse = {
  resumoDebitosTotal: ResumoDebitosGeralResponse
  resumosDebitosPorParceiro: ResumoDebitosParceiroResponse[]
}

export type ResumoDebitoFinal = {
  meta: number
  porcentagemTotalAcumulada: number
  resumoDebitosTotal: ResumoDebitosGeralResponse
  resumosDebitosPorParceiro: ResumoDebitosParceiroPorcentagemResponse[]
  resumoPorcentagens: ResumoPorcentagemGeralResponse
  resumoPorcentagensAcumulados: ResumoPorcentagemGeralResponse
}

export type EmTransito = {
  urlRelatorio: URLRelatorio
  atendimento: ResumoDebitoGeralEParceiro
  logisticaMartins: ResumoDebitoGeralEParceiro
  logisticaParceiro: ResumoDebitoGeralEParceiro
}

export type SemOcorrencia = {
  urlRelatorio: URLRelatorio
  perdas: ResumoDebitoGeralEParceiro
  emAnalise: ResumoDebitoGeralEParceiro
}

export type ResumoDebitoGeralEParceiro = {
  resumoDebitosTotal: ResumoDebitosGeralResponse
  resumosDebitosPorParceiro: ResumoDebitosParceiroResponse[]
}

export type Recuperacao = {
  urlRelatorio: string
  totalPorcentagem: number
  totalEmTransito: number
  maiorRecuperacao: ResumoMensalPorcentagemResponse
  menorRecuperacao: ResumoMensalPorcentagemResponse
  resumoDebitosTotal: ResumoDebitosGeralResponse
  resumosDebitosPorParceiro: RecuperacaoValoresPorParceiro[]
}

export interface RecuperacaoValoresPorParceiro extends ResumoDebitosParceiroPorcentagemResponse {
  totalPorcentagem: number
  totalEmTransito: number
  maiorRecuperacao: ResumoMensalPorcentagemResponse
  menorRecuperacao: ResumoMensalPorcentagemResponse
}

export type ResumoMensalPorcentagemResponse = {
  valor: number
  mes: string
  ano: number
}

export type ResumoDebitosGeralResponse = {
  quantidade: number
  quantidadeTransportadoraMartins: number
  quantidadeTransportadoraParceira: number
  urlRelatorio: string
  urlRelatorioTransportadoraMartins: string
  urlRelatorioTransportadoraParceira: string
  valor: number
  valorTransportadoraMartins: number
  valorTransportadoraParceira: number
  valoresMensais: ValoresMensais[]
}

export type ValoresMensais = {
  ano: number
  mes: string
  quantidade: number
  quantidadeTransportadoraMartins: number
  quantidadeTransportadoraParceira: number
  valor: number
  valorTransportadoraMartins: number
  valorTransportadoraParceira: number
}

type ResumoPorcentagemGeralResponse = {
  porcentagensMensais: PorcentagensMensais[]
}

export type PorcentagensMensais = {
  valor: number
  mes: string
  ano: number
}

type URLRelatorio = {
  urlRelatorioGeral: string
  urlRelatoriosParceiros: urlRelatorioParceiro[]
}

type urlRelatorioParceiro = {
  parceiroId: number
  urlRelatorio: string
}

export interface ResumoDebitosParceiroPorcentagemResponse extends ResumoDebitosGeralResponse, ResumoDebitoPorcentagens {
  parceiroId: number
}

type ResumoDebitoPorcentagens = Omit<ResumosDebitoInicial, 'resumoDebitosTotal' | 'resumosDebitosPorParceiro'>

export interface ResumoDebitosParceiroResponse extends ResumoDebitosGeralResponse {
  parceiroId: number
}

export type DebitosPerdasEfetivados = PerdasEfetivadas

export type ClassificacaoDebito = BaseClassificacao

export type AtualizarPedidoDebitoRequest = {
  classificacao: number
  observacao: string
  pedidoPlanilha: string
}

export type ObterPedidosDebitosResponse = {
  dataLancamento: string
  pedidoMkt: string
  valorDebito: number
  tratativa: string
  parceiro: string
  observacoes: PedidosDebitoObservacao[]
  classificacao: string
  classificacaoId: number
  aprovado: boolean
}

export interface ResponsePedidosDebitoDetalhado<T> extends ResponsePaginadoCargo<T> {
  processamentoDebitoPerda: ProcessamentoDebitoPerda
}

type ProcessamentoDebitoPerda = {
  createdAt: string
  dataAtualizacao: string
  id: string
  mensagemErro: string
  processamento: Processamento
}

type PedidosDebitoObservacao = {
  observacao: string
  usuario: string
  data: string
}

export interface ObterPedidosDebitosQuery extends Paginacao, ObterPedidosDebitosFiltros {}

export type ObterPedidosDebitosFiltros = {
  dataLancamentoFim: string
  dataLancamentoInicio: string
  possivelTratativa: string[]
  pedidoMkt: string
  parceiroId: number[]
  classificacao: string[]
}

export type EfetivarDebitoPerdaRequest = {
  mesSelecionado: number
  anoSelecionado: number
}
