import { TableRow } from '@material-ui/core'
import styled from 'styled-components'
import { ContainerSombra } from '../../components/ContainerSombra'

export const regularizacaoCiclosInformacoesStyles = {
  ContainerTable: styled(ContainerSombra)`
    padding: 24px;
    margin-top: 24px;
  `,
  TableRow: styled(TableRow)`
    cursor: pointer;
  `
}
