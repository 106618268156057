import { IOProps } from 'react-compose-io'
import { TabelaEditavel } from '../../../../components/TabelaEditavel/index'
import { customTheme } from '../../../../styles/customTheme'
import { ModalAddConta } from '../ModalAddConta/index'
import { ModalAdicionarTipoLancamento } from '../ModalAdicionarTipoLancamento/index'
import { LancamentoVariaveisIO } from './LancamentoVariaveis.IO'
import { Tipografia } from '../../../../components/Tipografia'
import { Botao } from '../../../../components/Botao'
export type LancamentoVariaveisProps = {
  cicloId: string
  setEnableConfirmar: React.Dispatch<React.SetStateAction<boolean>>
  parceiroId: number
}

function LancamentoVariaveisView({ _io }: IOProps<LancamentoVariaveisIO, LancamentoVariaveisProps>) {
  const { ContainerBotao, Container, GridHeader, GridLine, SubtitleTable } = _io.style

  return (
    <>
      <ModalAddConta
        abrirModal={_io.abrirModal}
        fecharModal={_io.handleCloseModal}
        sequencias={_io.arraySequencias}
        submeterValoresSequencias={_io.handleChangeSequencia}
        tituloModal="Indicar conta contábil"
      />
      <ModalAdicionarTipoLancamento
        abrirModal={_io.modalTipoLancamento}
        fecharModal={_io.handleFecharModalTipoLancamento}
        tipoLancamentoArray={_io.tipoLancamentoLista}
        submeterTipoLancamento={_io.handleTipoLancamentoChange}
        tituloModal="Tipo de lançamento"
      />
      <Container>
        <GridHeader>
          <GridLine>
            <Tipografia colorText={customTheme.colors.grey09} fontWeight="500" variant="subtitle1">
              Tratar Lançamentos Variáveis
            </Tipografia>
            <ContainerBotao>
              <Botao onClick={_io.handleModalTipoLancamento} textoBotao={'Alterar tipo lançamento'} cor="erro" estilo="outlined" disabled />
              <Botao onClick={_io.handleModal} textoBotao={'Indicar conta'} cor="erro" estilo="contained" disabled={_io.checkBoxDesabilitado} />
            </ContainerBotao>
          </GridLine>
        </GridHeader>
        <SubtitleTable>Indique as contas contábeis dos lançamentos variáveis:</SubtitleTable>
        <TabelaEditavel
          lancamentoNaoAssociado={_io.linhasLancamentosVariaveis}
          setCheckboxSelecionado={_io.setCheckboxSelecionado}
          pagina={_io.pagina}
          opcoesNumerosPaginacao={_io.rowsPerPageOptions}
          totalLinhas={_io.totalLinhas}
          onMudancaLinhasPorPagina={_io.onMudancaLinhasPorPagina}
          onMudancaPorPagina={_io.onMudancaPagina}
          textoFiltro={_io.tiposLancamentosFiltro}
          onOrdernarTabela={_io.ordernarTabelaEditavel}
          editarLancamentoVariaveisItem={_io.editarLancamentoVariaveisItem}
          onFiltrarCaixaSelecao={_io.onFiltroSelecionado}
        />
      </Container>
    </>
  )
}
export default LancamentoVariaveisView
