import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import msgConstants from '../../constants/msgConstants'
import { ORDEM_CRESCENTE } from '../../constants/utilConstants'
import { default as conciliacaoManager, default as validacaoManager } from '../../services/api/conciliacao/conciliacaoManager'
import { loadingReset, loadingUpdate } from '../../store/actions/loadingAction'
import { Contabilizacao, DetalhesContabilizacao, ValoresContabilizacao } from '../../types/conciliacaoApiTypes'
import { Order } from '../../types/tabelaTypes'
import { handleRequestSort, tratarMudancaLinhaPorPagina, tratarMudancaPagina } from '../../utils/tabelaUtils'
import visualizarContabilizacaoStyle from './VisualizarContabilizacao.style'

export function visualizarContabilizacaoIO() {
  const dispatch = useDispatch()

  const [ordem, setOrdem] = useState<Order>(ORDEM_CRESCENTE)
  const [ordemBy, setOrdemBy] = useState<keyof never>('historicoPadrao')
  const [pagina, setPagina] = useState(0)
  const [linhasPorPagina, setLinhasPorPagina] = useState(5)
  const { cicloId, tipoLancamento, sequenciaId } = useParams<{ cicloId: string; tipoLancamento: string; sequenciaId: string }>()
  const [buscarValores, setBuscarValores] = useState('')
  const [detalhesContabilizacaoList, setDetalhesContabilizacaoList] = useState<DetalhesContabilizacao>()
  const [contabilizacao, setContabilizacao] = useState<Contabilizacao>()
  const [totalLinhas, setTotalLinhas] = useState(0)

  const searchEnter = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key == 'Enter') {
      setPagina(0)
      buscarContabilizacaoDetalhes(buscarValores)
    }
  }

  async function buscarContabilizacaoDetalhes(filter?: string) {
    validacaoManager
      .buscarDetalhesContabilizacoes(cicloId, {
        lancamento: tipoLancamento,
        sequenciaId: sequenciaId,
        pageNumber: pagina + 1,
        pageSize: linhasPorPagina,
        filtro: filter
      })
      .then((response: DetalhesContabilizacao) => {
        setDetalhesContabilizacaoList(response)
        setTotalLinhas(response.total)
      })
      .catch(() => {
        toast.error(msgConstants.BUSCAR_DETALHES_CONTABILIZACAO_ERRO)
      })
      .finally(() => {
        dispatch(loadingReset())
      })
  }

  useEffect(() => {
    async function buscarContabilizacoes(lancamento?: string, valorSequenciaId?: string) {
      conciliacaoManager
        .buscarContabilizacoes(cicloId, lancamento, valorSequenciaId)
        .then((response: ValoresContabilizacao) => {
          setContabilizacao(response.contabilizacoes[0])
        })
        .catch(() => {
          toast.error(msgConstants.BUSCAR_TABELA_CONTABILIZACAO_ERRO)
        })
        .finally(() => {
          dispatch(loadingReset())
        })
    }
    buscarContabilizacoes(tipoLancamento, sequenciaId)
  }, [cicloId, dispatch, sequenciaId, tipoLancamento])

  useEffect(() => {
    dispatch(loadingUpdate(true))
    buscarContabilizacaoDetalhes(buscarValores)
  }, [pagina, linhasPorPagina])

  return {
    styles: visualizarContabilizacaoStyle,
    contabilizacao,
    buscarValores,
    setBuscarValores,
    detalhesContabilizacaoList,
    searchEnter,
    ordem,
    setOrdem,
    ordemBy,
    setOrdemBy,
    setLinhasPorPagina,
    totalLinhas,
    pagina,
    linhasPorPagina,
    setPagina,
    onMudancaPagina: (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
      tratarMudancaPagina(event, newPage, setPagina)
    },
    onMudancaLinhasPorPagina: (event: React.ChangeEvent<HTMLInputElement>) => {
      tratarMudancaLinhaPorPagina(event, setPagina, setLinhasPorPagina, setBuscarValores)
    },
    onRequestSort: handleRequestSort(ordemBy, ordem, setOrdem, setOrdemBy),
    linhasPorPaginaOpcao: [5, 10, 25]
  }
}

export type VisualizarContabilizacaoIO = ReturnType<typeof visualizarContabilizacaoIO>
