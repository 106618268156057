import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import msgConstants from '../../../../constants/msgConstants'
import TitulosManager from '../../../../services/api/titulos/titulosManager'
import { loadingReset, loadingUpdate } from '../../../../store/actions/loadingAction'
import { StatusProcessamento } from '../../../../types/genericoType'
import { BuscarTitulosForm, CardTitulos, ValorLiquidados } from '../../../../types/titulosTypes'
import { dataInicialMes, getDataAtualFormatada } from '../../../../utils/stringUtils'
import { titulosLiquidadosStyles } from './TitulosLiquidados.styles'
import { TitulosLiquidadosProps } from './TitulosLiquidados.View'

export function titulosLiquidadosIO({
  dataInicialAlteradaFormulario,
  dataFinalTituloLiquidado,
  dataInicialTituloLiquidado,
  setDataFinalTituloLiquidado,
  setDataInicialTituloLiquidado,
  dataFinalAlteradaFormulario,
  carregarBotao,
  gerarRelatorio,
  setCarregarBotao
}: TitulosLiquidadosProps) {
  const dispatch = useDispatch()
  const [tituloLiquidado, setTituloLiquidado] = useState<ValorLiquidados>({} as ValorLiquidados)
  const dataInicialMesAtual = dataInicialMes()
  const diaAtual = getDataAtualFormatada()
  const [valorSetTimeOut, setValorSetTimeOut] = useState<number>(0)

  useEffect(() => {
    estadoInicialTela()
  }, [dataInicialMesAtual, diaAtual, dispatch])

  useEffect(() => {
    buscaConstanteTitulos()
  }, [tituloLiquidado])

  const buscarTitulosLiquidados = async (
    dataInicial: string,
    dataFinal: string,
    setDataInicial: React.Dispatch<React.SetStateAction<string>>,
    setDataFinal: React.Dispatch<React.SetStateAction<string>>,
    setTitulos: React.Dispatch<React.SetStateAction<ValorLiquidados>>,
    dataInicialAlterada: string,
    dataFinalAlterada: string
  ) => {
    await TitulosManager.buscarTitulosLiquidados(dataInicial, dataFinal)
      .then((response: ValorLiquidados) => {
        if (response.processamento == undefined || response.processamento.status == StatusProcessamento.PROCESSANDO) {
          const atualizarStatus = setTimeout(() => {
            buscarTitulosLiquidados(dataInicial, dataFinal, setDataInicial, setDataFinal, setTitulos, dataInicialAlterada, dataFinalAlterada)
          }, 10000)
          setValorSetTimeOut(Number(atualizarStatus))
        } else {
          setCarregarBotao(false)
          setDataInicial(dataInicialAlterada)
          setDataFinal(dataFinalAlterada)
          setTitulos(response)
        }
      })
      .catch((e: Error) => {
        toast.error(msgConstants.TITULOS.erroTitulos)
        toast.error(e.message)
        setCarregarBotao(false)
      })
  }

  const buscaConstanteTitulos = () => {
    if (tituloLiquidado.resumoGeral) {
      const statusResumoGeral = tituloLiquidado.resumoGeral.processamentoRelatorio?.status
      const statusResumoParceiros = tituloLiquidado.resumoParceiros.map((parceiro) => parceiro.processamentoRelatorio?.status)

      if (statusResumoGeral == StatusProcessamento.PROCESSANDO || statusResumoParceiros.includes(StatusProcessamento.PROCESSANDO)) {
        clearTimeout(valorSetTimeOut)
        const atualizarStatus = setTimeout(() => {
          buscarTitulosLiquidados(
            dataInicialTituloLiquidado,
            dataFinalTituloLiquidado,
            setDataInicialTituloLiquidado,
            setDataFinalTituloLiquidado,
            setTituloLiquidado,
            dataInicialAlteradaFormulario,
            dataFinalAlteradaFormulario
          )
        }, 10000)
        setValorSetTimeOut(Number(atualizarStatus))
      }
    }
  }

  const estadoInicialTela = () => {
    dispatch(loadingUpdate(true))
    TitulosManager.buscarTitulosLiquidados(dataInicialMesAtual, diaAtual)
      .then((response: ValorLiquidados) => {
        setarRetorno(response)
      })
      .catch((e: Error) => {
        toast.error(msgConstants.TITULOS.erroTitulos + e.message)
      })
      .finally(() => {
        dispatch(loadingReset())
      })
  }

  const atualizarDadosDataAtual = () => {
    dispatch(loadingUpdate(true))
    TitulosManager.buscarTitulosLiquidados(diaAtual, diaAtual, true)
      .then((response: ValorLiquidados) => {
        setarRetorno(response)
      })
      .catch((e: Error) => {
        toast.error(msgConstants.TITULOS.erroTitulos + e.message)
      })
      .finally(() => {
        dispatch(loadingReset())
      })
  }

  function setarRetorno(response: ValorLiquidados) {
    setTituloLiquidado(response)
    setDataInicialTituloLiquidado(dataInicialMesAtual)
    setDataFinalTituloLiquidado(diaAtual)
  }

  const handleBuscarTitulos = async (data: BuscarTitulosForm) => {
    clearTimeout(valorSetTimeOut)
    if (data.dataPeriodo !== dataInicialTituloLiquidado || data.dataComparacao !== dataFinalTituloLiquidado) {
      setCarregarBotao(true)
      buscarTitulosLiquidados(
        data.dataPeriodo,
        data.dataComparacao,
        setDataInicialTituloLiquidado,
        setDataFinalTituloLiquidado,
        setTituloLiquidado,
        dataInicialAlteradaFormulario,
        dataFinalAlteradaFormulario
      )
    }
  }

  const mapearResumoGeralLiquidado = (cardTitulos: CardTitulos) => {
    if (cardTitulos.informacoes) {
      return (
        cardTitulos.informacoes &&
        cardTitulos.informacoes.map((informacao, index) => {
          return {
            tooltip: 'Fazer download do relatório dos títulos liquidados',
            titulo: informacao.nome,
            valor: informacao.valor,
            quantidade: informacao.quantidade,
            primeiroCard: index == 0 ? true : false,
            status: cardTitulos.processamentoRelatorio?.status,
            url: cardTitulos?.urlRelatorio
          }
        })
      )
    }
    return []
  }

  const exportarRelatorioPeriodo = (dataInicio: string, parceiroId?: number, dataFim?: string) => {
    return gerarRelatorio(
      () =>
        buscarTitulosLiquidados(
          dataInicialTituloLiquidado,
          dataFinalTituloLiquidado,
          setDataInicialTituloLiquidado,
          setDataFinalTituloLiquidado,
          setTituloLiquidado,
          dataInicialAlteradaFormulario,
          dataFinalAlteradaFormulario
        ),
      dataInicio,
      parceiroId,
      dataFim
    )
  }

  return {
    styles: titulosLiquidadosStyles,
    handleBuscarTitulos,
    carregarBotao,
    tituloLiquidado,
    mapearResumoGeralLiquidado,
    exportarRelatorioPeriodo,
    dataFinalTituloLiquidado,
    dataInicialTituloLiquidado,
    atualizarDadosDataAtual
  }
}

export type TitulosLiquidadosIO = ReturnType<typeof titulosLiquidadosIO>
