import { IOProps } from 'react-compose-io'
import { CustomDrawerIO } from './CustomDrawer.IO'
import { AppMenu } from './../AppMenu/index'

export type CustomDrawerProps = {}

export function CustomDrawerView({ _io }: IOProps<CustomDrawerIO, CustomDrawerProps>) {
  const { Drawer } = _io.styles

  return (
    <Drawer
      variant={_io.isLargeScreen ? 'permanent' : 'temporary'}
      open={!_io.isLargeScreen && _io.isOpened ? true : false}
      onClose={() => _io.toggleIsOpened(!_io.isOpened)}
      isopen={_io.isOpened ? 1 : 0}
    >
      <AppMenu />
    </Drawer>
  )
}
