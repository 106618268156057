import styled from 'styled-components'
import { customTheme } from '../../styles/customTheme'

export const inputPesquisaStyles = {
  Container: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 211px;
    height: 40px;
    background: ${customTheme.colors.white01};
    border: 0.5px solid ${customTheme.colors.grey04};
    border-radius: 4px;
    padding: 10px;
  `,
  InputSearch: styled.input`
    background: none;
    border: none;
    outline: none;
    ::placeholder {
      font-family: ${customTheme.fontFamily.rubik400};
      color: ${customTheme.colors.grey04};
      font-style: normal;
      font-size: ${customTheme.fontSize[14]};
      line-height: 23px;
      background: none;
      border: none;
    }
  `,
  ContainerInput: styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 10px;
  `
}
