import { yupResolver } from '@hookform/resolvers/yup'
import { ChangeEvent, useEffect } from 'react'
import { DeepMap, FieldError, useForm, useWatch } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import * as yup from 'yup'
import msgConstants from '../../../../constants/msgConstants'
import parceiroManager from '../../../../services/api/parceiro/parceiroManager'
import { loadingReset, loadingUpdate } from '../../../../store/actions/loadingAction'
import { ObterListaStatusParceiroResponse, StatusEfacilRequest, StatusParceiroRequest } from '../../../../types/parceiroApiTypes'
import { StatusEfacil } from '../../../../types/statusEfacilTypes'
import { StatusTracking } from '../../../../types/statusTrackingTypes'
import { modalTipoStatusStyles } from './ModalTipoStatus.styles'
import { ModalTipoStatusProps } from './ModalTipoStatus.View'

export function modalTipoStatusIO({ atualizarDados, isClosed, formularioPreenchido, statusTrackingList, statusEfacilList }: ModalTipoStatusProps) {
  const { parceiroId } = useParams<{ parceiroId: string }>()

  const handleVerificarOcorrenciaToggle = (event: ChangeEvent<HTMLInputElement>) => {
    setValue('verificarOcorrencia', event.target.checked)
  }

  const schema = yup.object({
    nomeStatusParceiro: yup.string().required(msgConstants.USO_GENERICO.campo_obrigatorio),
    tipoStatus: yup.string().required(msgConstants.USO_GENERICO.campo_obrigatorio),
    listaStatusEfacil: yup.array().when('tipoStatus', {
      is: 'efacil',
      then: yup.array().min(1, msgConstants.USO_GENERICO.campo_obrigatorio)
    }),
    listaStatusTracking: yup.array().when('tipoStatus', {
      is: 'tracking',
      then: yup.array().min(1, msgConstants.USO_GENERICO.campo_obrigatorio)
    })
  })

  const dispatch = useDispatch()

  const {
    handleSubmit,
    control,
    formState: { errors },
    register,
    setValue,
    getValues,
    clearErrors
  } = useForm<StatusParceiroRequest>({
    defaultValues: {
      nomeStatusParceiro: '',
      listaStatusTracking: [],
      listaStatusEfacil: [],
      tipoStatus: '',
      verificarOcorrencia: false
    },
    resolver: yupResolver(schema)
  })

  const tipoStatusSelecionado = useWatch({
    name: 'tipoStatus',
    control
  })

  useEffect(() => {
    if (formularioPreenchido) {
      if (getValues('tipoStatus') == 'tracking') {
        setValue('listaStatusTracking', formularioPreenchido.listaStatusTracking)
        setValue('listaStatusEfacil', [])
      } else {
        setValue('listaStatusEfacil', formularioPreenchido.listaStatusEfacil)
        setValue('listaStatusTracking', [])
      }
    } else {
      setValue('listaStatusTracking', [])
      setValue('listaStatusEfacil', [])
    }
  }, [setValue, tipoStatusSelecionado, formularioPreenchido, getValues])

  useEffect(() => {
    if (formularioPreenchido) {
      setValue('tipoStatus', PreencherTipoStatus(formularioPreenchido))
      setValue('nomeStatusParceiro', formularioPreenchido.nomeStatusParceiro)
      setValue('verificarOcorrencia', formularioPreenchido.verificarOcorrencia)
    }
  }, [formularioPreenchido, setValue])

  function salvarStatus(dataForm: StatusParceiroRequest) {
    dispatch(loadingUpdate(true))
    formularioPreenchido ? editarStatus(dataForm, formularioPreenchido) : cadastrarStatus(dataForm)
  }

  async function cadastrarStatus(dataForm: StatusParceiroRequest) {
    dispatch(loadingUpdate(true))

    await parceiroManager
      .cadastrarStatusParceiro(parceiroId, dataForm)
      .then(() => {
        toast.success(msgConstants.USO_GENERICO.cadastro_sucesso)
      })
      .catch(() => {
        toast.error(msgConstants.USO_GENERICO.cadastro_erro)
      })
      .finally(() => {
        finalizarCadastro()
      })
  }

  async function editarStatus(dataForm: StatusParceiroRequest, dataFormCadastrado: ObterListaStatusParceiroResponse) {
    dataForm.ativo = dataFormCadastrado.ativo

    await parceiroManager
      .editarStatusParceiro(parceiroId, dataFormCadastrado?.id, dataForm)
      .then(() => {
        toast.success(msgConstants.USO_GENERICO.edicao_sucesso)
      })
      .catch((error: Error) => {
        toast.error(msgConstants.USO_GENERICO.edicao_erro + '\n' + error.message)
      })
      .finally(() => {
        finalizarCadastro()
      })
  }

  function finalizarCadastro() {
    fecharModal()
    atualizarDados()
    dispatch(loadingReset())
  }

  const fecharModal = () => {
    isClosed()
    clearErrors('tipoStatus')
    clearErrors('nomeStatusParceiro')
    clearErrors('listaStatusTracking')
    clearErrors('listaStatusEfacil')

    if (formularioPreenchido == undefined) {
      setValue('nomeStatusParceiro', '')
      setValue('tipoStatus', '')
      setValue('listaStatusTracking', [])
      setValue('listaStatusEfacil', [])
    } else {
      setValue('listaStatusTracking', formularioPreenchido.listaStatusTracking)
      setValue('listaStatusEfacil', formularioPreenchido.listaStatusEfacil)
    }
  }

  const valorAutocompleteTracking = () => {
    const valorPadrao = statusTrackingList.map((statusTracking) => {
      if (getValues('listaStatusTracking').some((statusTrackingPreenchido) => statusTrackingPreenchido.codigo == statusTracking.value)) return statusTracking
      else return {} as StatusTracking
    })

    return valorPadrao.filter((x) => x.name !== undefined)
  }

  const valorAutocompleteEfacil = () => {
    const valorPadrao = statusEfacilList.map((statusEfacil) => {
      if (getValues('listaStatusEfacil').some((statusEfacilPreenchido) => statusEfacilPreenchido.codigo == statusEfacil.value)) return statusEfacil
      else return {} as StatusEfacil
    })

    return valorPadrao.filter((x) => x.name !== undefined)
  }

  return {
    styles: modalTipoStatusStyles,
    schema,
    register,
    control,
    salvarStatus,
    handleSubmit,
    errors,
    fecharModal,
    setValue,
    getValues,
    valorAutocompleteTracking,
    valorAutocompleteEfacil,
    tipoStatusSelecionado,
    handleVerificarOcorrenciaToggle,
    verificarOcorrenciaAtivoValorPadrao: getDefaultVerificarOcorrencia(formularioPreenchido),
    tipoStatusError: verificarErros(errors?.tipoStatus),
    listaStatusTrackingError: verificarErros(errors?.listaStatusTracking),
    listaStatusEfacilError: verificarErros(errors?.listaStatusEfacil)
  }
}

export type ModalTipoStatusIO = ReturnType<typeof modalTipoStatusIO>

function PreencherTipoStatus(formularioPreenchido: ObterListaStatusParceiroResponse): string | undefined {
  return formularioPreenchido.listaStatusTracking.length > 0 ? 'tracking' : 'efacil'
}

function verificarErros(errors: FieldError | (DeepMap<StatusEfacilRequest, FieldError> | undefined)[] | undefined) {
  return errors ? true : false
}

function getDefaultVerificarOcorrencia(formularioPreenchido: ObterListaStatusParceiroResponse | undefined): boolean {
  return formularioPreenchido ? formularioPreenchido.verificarOcorrencia : false
}
