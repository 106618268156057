import styled from 'styled-components'
import { ContainerSombra } from '../../../../components/ContainerSombra'

export const titulosLiquidadosStyles = {
  CardCabecalho: styled(ContainerSombra)`
    margin-bottom: 19px;
    padding: 9px;
  `,
  ContainerBranco: styled(ContainerSombra)`
    padding: 0 8px;
    margin-top: 29px;
  `
}
