import { yupResolver } from '@hookform/resolvers/yup'
import React from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { TipoLancamentoParceiro } from '../../../../types/parceiroApiTypes'
import { modalAdicionarTipoLancamentoStyles } from './ModalAdicionarTipoLancamento.style'
import { ModalAdicionarTipoLancamentoProps } from './ModalAdicionarTipoLancamento.View'

export function modalAdicionarTipoLancamento({ submeterTipoLancamento }: ModalAdicionarTipoLancamentoProps) {
  const defaultValues = {
    descricao: ''
  }

  const schema = yup.object().shape({
    descricao: yup.string().required()
  })

  const {
    handleSubmit,
    control,
    setValue,
    reset,
    formState: { errors }
  } = useForm<TipoLancamentoParceiro>({
    resolver: yupResolver(schema),
    defaultValues
  })

  const submeterTipoLancamentoModal = (data: TipoLancamentoParceiro) => {
    submeterTipoLancamento(data)
    reset()
  }

  const handleMudarTipoLancamentoSelect = (event: React.ChangeEvent<{ name?: string | number; value: unknown | string | number }>) => {
    const { value } = event.target
    setValue('descricao', String(value))
  }

  return {
    styles: modalAdicionarTipoLancamentoStyles,
    handleSubmit,
    control,
    errors,
    handleMudarTipoLancamentoSelect,
    submeterTipoLancamentoModal
  }
}

export type ModalAdicionarTipoLancamento = ReturnType<typeof modalAdicionarTipoLancamento>
