import { Backdrop, CircularProgress, CssBaseline, MuiThemeProvider } from '@material-ui/core'
import 'filepond/dist/filepond.min.css'
import { useSelector } from 'react-redux'
import 'react-toastify/dist/ReactToastify.css'
import { ConciliacaoProvider } from './contexts/conciliacao-context'
import { LoadingState } from './interfaces/interfaces'
import { EstiloGlobal } from './styles/global'
import { Router } from './router'
import './styles/global.css'
import theme from './styles/theme'
import { ToastStyled } from './styles/Toast'

function App() {
  const { show } = useSelector((state: LoadingState) => state.loading)

  return (
    <MuiThemeProvider theme={theme}>
      <EstiloGlobal />
      <ConciliacaoProvider>
        <CssBaseline />
        <Router />
        <ToastStyled position="top-right" autoClose={6000} hideProgressBar={false} newestOnTop={false} draggable={false} closeOnClick pauseOnHover />
        <Backdrop open={show} style={{ zIndex: 1300 }}>
          <CircularProgress color="primary" />
        </Backdrop>
      </ConciliacaoProvider>
    </MuiThemeProvider>
  )
}

export default App
