import { Dialog, DialogTitle, Box, DialogActions } from '@material-ui/core'
import styled from 'styled-components'
import { customTheme } from '../../styles/customTheme'
import CloseIcon from '@material-ui/icons/Close'

export const confirmarDialogStyles = {
  CloseIcon: styled(CloseIcon)`
    cursor: pointer;
  `,
  Dialog: styled(Dialog)`
    color: ${customTheme.colors.grey01};
  `,
  DialogWithOutTitle: styled(DialogTitle)`
    padding: 5px 5px 0px 5px;
  `,
  Box: styled(Box)`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
  BoxSemTitulo: styled(Box)`
    display: flex;
    justify-content: flex-end;
  `,
  DialogActions: styled(DialogActions)`
    justify-content: center;
    margin-bottom: 15px;
  `
}
