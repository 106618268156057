import {
  ITipoLancamentoParceiroForm,
  Parceiro,
  ParceiroRequest,
  TipoLancamentoParceiro,
  ParametrizacaoCampos,
  ParametrizacaoCamposParceiro,
  ObterListaStatusParceiroResponse,
  StatusParceiroRequest
} from '../../../types/parceiroApiTypes'
import api from '../../axiosInstance'

const URL_PARCEIROS = `/Parceiros`

const buscarDadosParceiros = async (ativos: boolean | null): Promise<Parceiro[]> => {
  let URL_BUSCAR_DADOS_PARCEIROS = `${URL_PARCEIROS}`

  if (ativos != null) {
    URL_BUSCAR_DADOS_PARCEIROS = `${URL_BUSCAR_DADOS_PARCEIROS}?ativos=${ativos}`
  }

  const response = await api.conectarGet<Parceiro[]>(URL_BUSCAR_DADOS_PARCEIROS)
  return response.data
}

const buscarDadoParceiro = async (parceiroId?: string | number): Promise<Parceiro> => {
  const URL_BUSCAR_DADOS_PARCEIROS = `${URL_PARCEIROS}/${parceiroId}`
  const response = await api.conectarGet<Parceiro>(URL_BUSCAR_DADOS_PARCEIROS)
  return response.data
}

const cadastrarDadosParceiros = async (body: ParceiroRequest): Promise<void> => {
  await api.conectarPut<void>(`${URL_PARCEIROS}/${body.parceiroId}`, body)
}

const cadastrarTipolancamentoParceiros = async (
  parceiroId: string | number | undefined,
  body: ITipoLancamentoParceiroForm
): Promise<ITipoLancamentoParceiroForm> => {
  const response = await api.conectarPost<ITipoLancamentoParceiroForm>(`${URL_PARCEIROS}/${parceiroId}/TiposLancamentos`, body)
  return response.data
}

const editarTipolancamentoParceiros = async (
  parceiroId: string | number | undefined,
  tipoLancamentoId: number,
  body: TipoLancamentoParceiro
): Promise<void> => {
  await api.conectarPut<void>(`${URL_PARCEIROS}/${parceiroId}/TiposLancamentos/${tipoLancamentoId}`, body)
}

const buscarTipoLancamentoParceiro = async (parceiroId?: string | number, ativo?: boolean, tipoContabilizacao?: string): Promise<TipoLancamentoParceiro[]> => {
  const isAtivo = ativo != undefined ? `/&ativo=${ativo}` : ``
  const isTipoContabilizacao = tipoContabilizacao ? `&tipoContabilizacao=${tipoContabilizacao}` : ''

  const URL_BUSCAR_TIPO_LANCAMENTO_PARCEIRO = `${URL_PARCEIROS}/${parceiroId}/TiposLancamentos${isAtivo}${isTipoContabilizacao}`

  const response = await api.conectarGet<TipoLancamentoParceiro[]>(URL_BUSCAR_TIPO_LANCAMENTO_PARCEIRO)
  return response.data
}

const buscarParametrosCicloPedido = async (parceiroId: string | number): Promise<ParametrizacaoCamposParceiro> => {
  const URL_BUSCAR_DADOS_PARCEIROS = `${URL_PARCEIROS}/${parceiroId}/CicloPedido/ParametrizacaoCampos`
  const response = await api.conectarGet<ParametrizacaoCamposParceiro>(URL_BUSCAR_DADOS_PARCEIROS)
  return response.data
}

const editarParametrosCicloPedido = async (parceiroId: string | number, body: ParametrizacaoCampos): Promise<boolean> => {
  const URL_BUSCAR_DADOS_PARCEIROS = `${URL_PARCEIROS}/${parceiroId}/CicloPedido/ParametrizacaoCampos`
  const response = await api.conectarPut<boolean>(URL_BUSCAR_DADOS_PARCEIROS, body)
  return response.data
}

const listarStatusParceiro = async (parceiroId: string | number): Promise<ObterListaStatusParceiroResponse[]> => {
  const URL_BUSCAR_LISTA_STATUS = `${URL_PARCEIROS}/${parceiroId}/CicloPedido/Status`
  const response = await api.conectarGet<ObterListaStatusParceiroResponse[]>(URL_BUSCAR_LISTA_STATUS)
  return response.data
}

const cadastrarStatusParceiro = async (parceiroId: string | number, body: StatusParceiroRequest): Promise<boolean> => {
  const URL_CRIAR_DADOS_PARCEIROS = `${URL_PARCEIROS}/${parceiroId}/CicloPedido/Status`
  const response = await api.conectarPost<boolean>(URL_CRIAR_DADOS_PARCEIROS, body)
  return response.data
}

const editarStatusParceiro = async (parceiroId: string | number, statusId: number, body: StatusParceiroRequest): Promise<boolean> => {
  const URL_EDITAR_DADOS_PARCEIROS = `${URL_PARCEIROS}/${parceiroId}/CicloPedido/Status/${statusId}`
  const response = await api.conectarPut<boolean>(URL_EDITAR_DADOS_PARCEIROS, body)
  return response.data
}

export default {
  buscarDadosParceiros,
  buscarDadoParceiro,
  cadastrarDadosParceiros,
  cadastrarTipolancamentoParceiros,
  editarTipolancamentoParceiros,
  buscarTipoLancamentoParceiro,
  buscarParametrosCicloPedido,
  editarParametrosCicloPedido,
  listarStatusParceiro,
  cadastrarStatusParceiro,
  editarStatusParceiro
}
