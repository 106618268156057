import { useFieldArray, useFormContext, useWatch } from 'react-hook-form'
import { TIPO_PARAMETRO } from '../../../constants/utilConstants'
import { stylesFieldForms } from './../styles'

export function fieldsCondicaoIO() {
  const { control } = useFormContext()

  const condicoes = useFieldArray({
    name: 'condicoes',
    control
  })

  const condicaoSelecionada = useWatch({
    name: 'condicoes',
    control
  })

  return {
    styles: stylesFieldForms,
    control,
    condicoes,
    condicaoSelecionada,
    adicionarCondicao: () =>
      condicoes.append({
        operacaoId: null,
        dado: '',
        operador: '',
        parametro: {
          parametro: '',
          tipoParametro: TIPO_PARAMETRO.criterio
        },
        tipoCondicao: 'E'
      })
  }
}

export type FieldsCondicaoIO = ReturnType<typeof fieldsCondicaoIO>
