import { ParceiroResumo } from './genericoType'
import { Paginacao } from './tabelaTypes'

export type PeriodoPendenciasRegularizacaoCicloForm = {
  dataFinal: string
  dataInicial: string
}

type PedidosPendentesCiclo = {
  totalOpsPendentes: number
  cicloId: number
}

export type RegularizacaoCicloParceiro = {
  parceiroResumo: ParceiroResumo
  totalPedidosPendentesParceiro: number
  ciclosPendentesParceiros: PedidosPendentesCiclo[]
}

export type ValorCiclosPendentes = {
  totalCiclosPendentes: number
  totalOPsPendentes: number
  valorTotalOPsPendentes: number
  totalPedidosPendentes: number
  valorTotalPedidosPendentes: number
  resumoParceiros: RegularizacaoCicloParceiro[]
}

export interface RegularizacaoPendenteForm extends Paginacao {
  pedidoPlanilhaId?: string
  clienteId?: string
  parceiroId?: string
  cicloId?: string
}

export type DetalhesRegularizacaoPendente = {
  ciclo: number
  parceiro: number
  pedidoMkp: string
  clienteId: number
  parcela: number
  valorSaldo: number
  dataPagamento: string
}

export type ResponseRegularizacaoPendente = {
  itens: DetalhesRegularizacaoPendente[]
  pageNumber: number
  pageSize: number
  totalItens: number
  totalPages: number
}

export const valorPadraoRegularizacaoPendencia = {
  itens: [],
  pageNumber: 1,
  pageSize: 25,
  totalItens: 0,
  totalPages: 0
}

export type ObterOpsTituloPorPedidoResponse = {
  pedido: string
  parceiro: ParceiroResumo
  cliente: string
  codigoCliente: number
  valorSaldo: number
  titulos: TituloPorPedidoResponse[]
  ops: OPPorPedidoResponse[]
}

export type TituloPorPedidoResponse = {
  pedidoEfacil: number
  tituloId: number
  parcela: number
  filialId: number
  codigoCliente: number
  dataEmissao: string
  dataVencimento: string
  saldo: number
  tipoTitulo: number
}

export type OPPorPedidoResponse = {
  operacaoOrdemPagamentoId: number
  ordemPagamentoId: number
  banco: number
  emitente: string
  descricaoPagamento: string
  dataEmissao: string
  valor: number
}

export type RegularizacaoEfetivacaoCicloForm = {
  sequenciaId: number
  acao: number
}

export type EfetivarOPsTitulosRequest = {
  sequenciaId: number
  ops: EfetivarOPsRequest[]
  titulos: EfetivarTitulosRequest[]
}

export type EfetivarTitulosRequest = {
  tituloId: number
  tipoTitulo: number
  filialId: number
  codigoCliente: number
  dataEmissao: string
}

export type EfetivarOPsRequest = {
  ordemPagamentoId: number
  bancoId: number
  operacaoOrdemPagamentoId: number
  dataEmissao: string
}

export const valorInicialRegularizacaoCiclos: ObterOpsTituloPorPedidoResponse = {
  cliente: '',
  codigoCliente: 0,
  ops: [],
  parceiro: {
    logoParceiroUrl: '',
    parceiroId: 0,
    parceiroNome: ''
  },
  pedido: '',
  titulos: [],
  valorSaldo: 0
}

export type OrdemPagamentoRequest = {
  ordemPagamentoId: number
  bancoId: number
  operacaoOrdemPagamentoId: number
  dataEmissao: string
}

export type OrdemPagamentoBuscarResponse = {
  ordemPagamentoId: number
  emitente: string
  bancoId: number
  valorPagamento: number
  dataEmissao: string
  descricao: string
  cicloId: string
  operacaoOrdemPagamentoId: number
}

export type VincularOrdemPagamentoResponse = {
  opsChaves: OrdemPagamentoRequest[]
}
