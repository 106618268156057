import {
  arquivoTemp,
  Ciclos,
  CicloStatusGeral,
  CicloTabela,
  ErrosProcessamento,
  IFiltroOps,
  IniciarNovoCicloForm,
  IOpsVinculadas,
  ItemLancamentoNaoAssociado,
  LancamentosVariaveisApi,
  novoCiclo,
  ParceiroCiclo,
  TotalCiclo,
  TransicionarCiclo
} from '../../../types/ciclosApiType'
import { ResponsePaginado } from '../../../types/genericoType'
import { Order, Paginacao } from '../../../types/tabelaTypes'
import { concatenarFiltrosQuery } from '../../../utils/stringUtils'
import api from '../../axiosInstance'
import { CicloPorParceiroForm } from './../../../types/ciclosApiType'

const URL_PARAMETROS = `/Ciclos/Parceiros`

const buscarCiclos = async (): Promise<ParceiroCiclo[]> => {
  const URL_BUSCAR_CICLOS = `${URL_PARAMETROS}`
  const response = await api.conectarGet<ParceiroCiclo[]>(URL_BUSCAR_CICLOS)
  return response.data
}

const buscarCicloParceiro = async (parceiroId: string | number, paginacao: CicloPorParceiroForm): Promise<ResponsePaginado<Ciclos>> => {
  const URL_BUSCAR_CICLOS = `${URL_PARAMETROS}/${parceiroId}${concatenarFiltrosQuery({
    pageNumber: paginacao.pageNumber,
    pageSize: paginacao.pageSize,
    orderField: paginacao.orderField,
    orderBy: paginacao.orderBy,
    cicloId: paginacao.cicloId
  })}`
  const response = await api.conectarGet<ResponsePaginado<Ciclos>>(URL_BUSCAR_CICLOS)
  return response.data
}

const iniciarCiclo = async (parceiroId: string | number, body: IniciarNovoCicloForm): Promise<novoCiclo> => {
  const URL_INICIAR_CICLO = `${URL_PARAMETROS}/${parceiroId}`
  const response = await api.conectarPost<novoCiclo>(URL_INICIAR_CICLO, body)
  return response.data
}

const deletarCiclo = async (cicloId?: string | number): Promise<void> => {
  const URL_DELETAR_CICLO = `Ciclos/${cicloId}`
  const response = await api.conectarDelete<void>(URL_DELETAR_CICLO)
  return response.data
}

const buscarTabelaCiclo = async (paginacao: Paginacao, cicloId?: string | number, search?: string): Promise<CicloTabela> => {
  let URL_BUSCAR_TABELA_CICLO = `Ciclos/${cicloId}/Tabela${concatenarFiltrosQuery(paginacao)}`

  if (search) {
    URL_BUSCAR_TABELA_CICLO = URL_BUSCAR_TABELA_CICLO + `&search=${search}`
  }

  const response = await api.conectarGet<CicloTabela>(URL_BUSCAR_TABELA_CICLO)
  return response.data
}

const buscarErrosProcessamento = async (cicloId: string, pageNumber: string | number, pageSize: string | number): Promise<ErrosProcessamento> => {
  const URL_BUSCAR_ERROS_PROCESSAMENTO = `Ciclos/${cicloId}/Erros/Processamento?pageNumber=${pageNumber}&pageSize=${pageSize}`
  const response = await api.conectarGet<ErrosProcessamento>(URL_BUSCAR_ERROS_PROCESSAMENTO)
  return response.data
}

const buscarStatusCiclo = async (cicloId?: string | number): Promise<CicloStatusGeral> => {
  const URL_BUSCAR_STATUS_CICLO = `Ciclos/${cicloId}/Historico`
  const response = await api.conectarGet<CicloStatusGeral>(URL_BUSCAR_STATUS_CICLO)
  return response.data
}

const uploadArquivoTemp = async (fileType: string, cicloId?: string | number): Promise<arquivoTemp> => {
  const URL_ENV_UPLOAD = process.env.REACT_APP_BASE_URL?.substring(0, process.env.REACT_APP_BASE_URL.length - 7)

  let URL_UPLOAD_TEMPORARIO = `${URL_ENV_UPLOAD}/uploadspreadsheet/s3url?fileType=${fileType}`
  if (cicloId) {
    URL_UPLOAD_TEMPORARIO = `${URL_ENV_UPLOAD}/uploadspreadsheet/s3url?ciclo=${cicloId}&fileType=${fileType}`
  }
  const response = await api.conectarGet<arquivoTemp>(URL_UPLOAD_TEMPORARIO)
  return response.data
}

const uploadArquivoCiclo = async (url: string, file: any, type: string): Promise<string> => {
  const response = await api.conectarPut<string>(url, file, {
    headers: {
      'Content-Type': type
    }
  })
  return response.data
}

const buscarLancamentosVariaveis = async (
  cicloId: string,
  numeroPagina: string | number,
  tamanhoPagina: string | number,
  ordernarPor?: Order,
  filtro?: string[],
  ordemCampo?: string
): Promise<LancamentosVariaveisApi> => {
  ordemCampo = ordemCampo == 'sequencia' ? 'sequenciaContabilId' : ordemCampo

  let filtros = ''
  filtro?.forEach((filtroSelecionado) => {
    filtros = filtros + `&filters=${filtroSelecionado}`
  })

  const isOrdemCampo = ordemCampo && ordernarPor ? `&orderBy=${ordernarPor}&orderField=${ordemCampo}` : ''
  const FILTRO_LANCAMENTO = `/Ciclos/${cicloId}/Erros/Variaveis?pageNumber=${numeroPagina}&pageSize=${tamanhoPagina}${filtros}${isOrdemCampo}`
  const response = await api.conectarGet<LancamentosVariaveisApi>(FILTRO_LANCAMENTO)
  return response.data
}

const editarItemLancamentoVariaveis = async (cicloId: string, objectApi: ItemLancamentoNaoAssociado[]): Promise<ItemLancamentoNaoAssociado[]> => {
  const URL_BUSCAR_SALVAR_LANCAMENTOS = `/Ciclos/${cicloId}/Variaveis`
  const response = await api.conectarPut<ItemLancamentoNaoAssociado[]>(URL_BUSCAR_SALVAR_LANCAMENTOS, objectApi)
  return response.data
}

const verificarItensVariaveis = async (cicloId: string): Promise<boolean> => {
  const URL_BUSCAR_TRATAMENTOS_ITENS_VARIAVEIS = `/Ciclos/${cicloId}/Variaveis/Tratados`
  const response = await api.conectarGet<boolean>(URL_BUSCAR_TRATAMENTOS_ITENS_VARIAVEIS)
  return response.data
}

const buscarLancamentoNaoAssociadoService = async (
  cicloId: string | number,
  pageNumber: string | number,
  pageSize: string | number
): Promise<LancamentosVariaveisApi> => {
  const URL_LANCAMENTO_NAO_ASSOCIADO = `Ciclos/${cicloId}/Erros/Associacao?pageNumber=${pageNumber}&pageSize=${pageSize}`

  const response = await api.conectarGet<LancamentosVariaveisApi>(URL_LANCAMENTO_NAO_ASSOCIADO)
  return response.data
}

const tratarLancamentoNaoAssociadoService = async (cicloId: string | number, numeroLinha: number, tipoTratamento: string): Promise<LancamentosVariaveisApi> => {
  const URL_LANCAMENTO_NAO_ASSOCIADO = `Associacao/Ciclos/${cicloId}/Tratar?numeroLinha=${numeroLinha}&tipoTratamento=${tipoTratamento}`
  const response = await api.conectarPost<LancamentosVariaveisApi>(URL_LANCAMENTO_NAO_ASSOCIADO)
  return response.data
}

const concluirInternalizacao = async (cicloId?: string | number): Promise<string> => {
  const URL_CONCLUIR_CICLO = `Internalizacao/Ciclo/${cicloId}`
  const response = await api.conectarPost<string>(URL_CONCLUIR_CICLO)
  return response.data
}

const buscarOps = async (
  ordemPagamentoId?: number,
  dataInicio?: string,
  dataFim?: string,
  emitente?: string,
  bancoId?: number,
  valor?: number
): Promise<IFiltroOps> => {
  // eslint-disable-next-line max-len
  const URL_BUSCAR_OPS = `OrdensPagamentos?ordemPagamentoId=${ordemPagamentoId}&dataInicio=${dataInicio}&dataFim=${dataFim}&emitente=${emitente}&bancoId=${bancoId}&valor=${valor}`
  const response = await api.conectarGet<IFiltroOps>(URL_BUSCAR_OPS, {
    params: {}
  })
  return response.data
}

const buscarOpsVinculadas = async (cicloId?: string | number): Promise<IOpsVinculadas[]> => {
  const URL_BUSCAR_OPS_VINCULADAS = `OrdensPagamentos/Ciclos/${cicloId}`
  const response = await api.conectarGet<IOpsVinculadas[]>(URL_BUSCAR_OPS_VINCULADAS)
  return response.data
}

const buscarTotalCiclo = async (cicloId?: string | number): Promise<TotalCiclo> => {
  const URL_BUSCAR_TOTAL_CICLO = `Ciclos/${cicloId}/Total`
  const response = await api.conectarGet<TotalCiclo>(URL_BUSCAR_TOTAL_CICLO)
  return response.data
}

const cadastrarOps = async (cicloId?: string | number): Promise<string> => {
  const URL_CADASTRAR_OPS = `OrdensPagamentos/Ciclos/${cicloId}`
  const response = await api.conectarPost<string>(URL_CADASTRAR_OPS)
  return response.data
}

const transicionarCiclo = async (transicao: TransicionarCiclo, cicloId?: string | number): Promise<string> => {
  const URL_CADASTRAR_OPS = `Ciclos/${cicloId}/Historico`
  const response = await api.conectarPost<string>(URL_CADASTRAR_OPS, transicao)
  return response.data
}

const obterLancamentosDinstintos = async (cicloId: string): Promise<string[]> => {
  const URL_TIPO_LANCAMENTO_DISTINTO = `Ciclos/${cicloId}/Erros/TiposLancamentosDistintos`
  const response = await api.conectarGet<string[]>(URL_TIPO_LANCAMENTO_DISTINTO)
  return response.data
}

export default {
  buscarCiclos,
  buscarCicloParceiro,
  buscarTabelaCiclo,
  buscarErrosProcessamento,
  buscarStatusCiclo,
  buscarLancamentosVariaveis,
  editarItemLancamentoVariaveis,
  verificarItensVariaveis,
  uploadArquivoTemp,
  uploadArquivoCiclo,
  iniciarCiclo,
  deletarCiclo,
  buscarLancamentoNaoAssociadoService,
  tratarLancamentoNaoAssociadoService,
  concluirInternalizacao,
  buscarOps,
  buscarOpsVinculadas,
  cadastrarOps,
  transicionarCiclo,
  buscarTotalCiclo,
  obterLancamentosDinstintos
}
