import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import msgConstants from '../../../../constants/msgConstants'
import { ORDEM_CRESCENTE } from '../../../../constants/utilConstants'
import { useConciliacaoContext } from '../../../../contexts/conciliacao-context'
import { PAGES } from '../../../../router/routes'
import ciclosManager from '../../../../services/api/ciclos/ciclosManager'
import TransformacaoManager from '../../../../services/api/transformacao/transformacaoManager'
import { loadingReset, loadingUpdate } from '../../../../store/actions/loadingAction'
import { ErrosProcessamento } from '../../../../types/ciclosApiType'
import { Order } from '../../../../types/tabelaTypes'
import { erroProcessamentoStyles } from './ErroProcessamento.style'
import { ErroProcessamentoProps } from './ErroProcessamento.View'

export function erroProcessamentoIO({ cicloId, parceiroId, setHabilitarConfirmar }: ErroProcessamentoProps) {
  const dispatch = useDispatch()
  const [erroProcessamentoLista, setErroProcessamentoLista] = useState<ErrosProcessamento>()
  const [pagina, setPagina] = useState(0)
  const [linhasPorPagina, setLinhasPorPagina] = useState(10)
  const [abrirModal, setAbrirModal] = useState(false)
  const { selectedsErroProcessamento, setSelectedsErroProcessamento } = useConciliacaoContext()
  const router = useHistory()
  const [loading, setLoading] = useState<boolean>(true)
  const [ordem, setOrdem] = useState<Order>(ORDEM_CRESCENTE)
  const [ordemBy, setOrdemBy] = useState<number>(-1)

  async function buscarErrosProcessamento() {
    setLoading(true)
    ciclosManager
      .buscarErrosProcessamento(cicloId, pagina + 1, linhasPorPagina)
      .then((response: ErrosProcessamento) => {
        setErroProcessamentoLista(response)
        setHabilitarConfirmar(response.isLinhasTratadas)
      })
      .catch(() => {
        toast.error(msgConstants.ARQUIVO.erroProcessamento)
      })
      .finally(() => {
        setLoading(false)
        dispatch(loadingReset())
        setSelectedsErroProcessamento([])
      })
    dispatch(loadingUpdate(true))
  }

  useEffect(() => {
    buscarErrosProcessamento()
  }, [cicloId, dispatch, pagina, linhasPorPagina])

  const ignorarErrosProcessamento = async () => {
    dispatch(loadingUpdate(true))

    await TransformacaoManager.tratarPlanilha(cicloId, selectedsErroProcessamento, 'I')
      .then(() => {
        buscarErrosProcessamento()
        setAbrirModal(false)
        toast.success(msgConstants.INICIAR_CICLO.sucesso_ignorar_regra)
      })
      .catch(() => {
        toast.error(msgConstants.INICIAR_CICLO.erro_ignorar_regra)
      })
      .finally(() => {
        dispatch(loadingReset())
      })
  }

  const desfazerProcessamento = async () => {
    dispatch(loadingUpdate(true))

    await TransformacaoManager.tratarPlanilha(cicloId, selectedsErroProcessamento, 'D')
      .then(() => {
        toast.success(msgConstants.INICIAR_CICLO.sucesso_ignorar_regra)
        buscarErrosProcessamento()
      })
      .catch(() => {
        toast.error(msgConstants.INICIAR_CICLO.erro_ignorar_regra)
      })
      .finally(() => {
        dispatch(loadingReset())
      })
  }

  function handleAdicionar() {
    router.push(PAGES.adicionarParametro(cicloId, parceiroId))
  }

  function verificarSelecao(): boolean {
    let isDisable = false
    if (erroProcessamentoLista && selectedsErroProcessamento.length > 0) {
      const posicaoControle = erroProcessamentoLista.itens[0].length - 2
      selectedsErroProcessamento.forEach((selectedErro: number) => {
        const erroProcessamentoEncontrado = erroProcessamentoLista.itens.find(
          (erroProcessamento) => Number(erroProcessamento[posicaoControle + 1]) === selectedErro
        )

        if (erroProcessamentoEncontrado === undefined) {
          isDisable = true
        }
      })
    } else {
      isDisable = true
    }

    return isDisable
  }

  const editarErroProcessamento = async (itemLancamento: string[], indexEditado: number) => {
    TransformacaoManager.editarTabelaErroProcessamento(cicloId, itemLancamento, indexEditado)
      .then(() => {
        TransformacaoManager.tratarPlanilha(cicloId, [indexEditado], 'I').then(() => {
          buscarErrosProcessamento()
        })
        toast.success(msgConstants.INICIAR_CICLO.sucesso_editar_planilha)
      })
      .catch(() => {
        toast.error(msgConstants.INICIAR_CICLO.erro_editar_planilha)
      })
      .finally(() => {
        dispatch(loadingReset())
      })
  }

  return {
    styles: erroProcessamentoStyles,
    desfazerProcessamento,
    verificarSelecao: verificarSelecao(),
    abrirModal,
    handleAdicionar,
    isAbrirModal: () => {
      setAbrirModal(true)
    },
    setAbrirModal,
    loading,
    erroProcessamentoLista,
    pagina,
    ignorarErrosProcessamento,
    textoModalErroProcessamento: 'Tem certeza que deseja ignorar os erros de processamento selecionados?',
    linhasPorPagina,
    setSelectedsErroProcessamento,
    opcoesNumerosPaginacao: [10, 25, 50, 100],
    totalLinhas: erroProcessamentoLista?.totalItens,
    onMudancaLinhasPorPagina: (numeroLinhaPorPagina: number) => {
      setPagina(0)
      setLinhasPorPagina(numeroLinhaPorPagina)
    },
    onMudancaPagina: (novaPagina: number, tamanhoPagina: number) => {
      setLinhasPorPagina(tamanhoPagina)
      setPagina(novaPagina)
    },
    ordernarTabelaEditavel: (colunaSelecionada: number, tipoOrdenacao: Order) => {
      setOrdem(tipoOrdenacao)
      setOrdemBy(colunaSelecionada)
    },
    editarErroProcessamento,
    ordem,
    ordemBy
  }
}
export type ErroProcessamentoIO = ReturnType<typeof erroProcessamentoIO>
