import { FormControl, FormControlLabel, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField } from '@material-ui/core'
import { IOProps } from 'react-compose-io'
import { Controller } from 'react-hook-form'
import { ModalTipoStatusIO } from './ModalTipoStatus.IO'
import { CustomDialog } from '../../../../components/CustomDialog/CustomDialog'
import { Autocomplete } from '@material-ui/lab'
import { StatusTracking } from '../../../../types/statusTrackingTypes'
import { ObterListaStatusParceiroResponse } from '../../../../types/parceiroApiTypes'
import { Botao } from './../../../../components/Botao/index'
import { StatusEfacil } from '../../../../types/statusEfacilTypes'
import { customTheme } from './../../../../styles/customTheme'

export type ModalTipoStatusProps = {
  isShow: boolean
  isClosed: () => void
  atualizarDados: () => void
  formularioPreenchido?: ObterListaStatusParceiroResponse
  statusTrackingList: StatusTracking[]
  statusEfacilList: StatusEfacil[]
}

export default function ModalTipoStatusView({ _io, isShow, statusTrackingList, statusEfacilList }: IOProps<ModalTipoStatusIO, ModalTipoStatusProps>) {
  const { Form, Chip, Switch } = _io.styles

  return (
    <CustomDialog
      maxWidth="xs"
      open={isShow}
      onClose={_io.fecharModal}
      id="modal-add-tipo-lancamento"
      titulo="Adicionar tipo de status do parceiro"
      habilitarAcoes
      fecharHabilitado
      fecharModal={_io.fecharModal}
      actions={
        <Grid container justifyContent="center" spacing={2}>
          <Grid item>
            <Botao textoBotao="Cancelar" cor="primaria" estilo="text" onClick={_io.fecharModal} width="116px" />
          </Grid>
          <Grid item>
            <Botao textoBotao="Salvar" cor="primaria" onClick={_io.handleSubmit(_io.salvarStatus)} estilo="rounded" width="116px" />
          </Grid>
        </Grid>
      }
    >
      <Form>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Controller
              render={({ field }) => (
                <TextField
                  required
                  {..._io.register('nomeStatusParceiro', { required: true })}
                  {...field}
                  label="Status do parceiro"
                  variant="outlined"
                  size="small"
                  error={_io.errors?.nomeStatusParceiro ? true : false}
                  helperText={_io.errors.nomeStatusParceiro?.message}
                  fullWidth
                />
              )}
              name="nomeStatusParceiro"
              control={_io.control}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Controller
              render={({ field }) => (
                <FormControl variant="outlined" size="small" fullWidth required>
                  <InputLabel error={_io.tipoStatusError}>Tipo de status</InputLabel>

                  <Select {...field} label="Tipo de status">
                    <MenuItem value="tracking">Tracking</MenuItem>
                    <MenuItem value="efacil">e-Fácil</MenuItem>
                  </Select>

                  {!!_io.errors?.tipoStatus && <FormHelperText error>{_io.errors.tipoStatus?.message}</FormHelperText>}
                </FormControl>
              )}
              name={'tipoStatus'}
              control={_io.control}
            />
          </Grid>
          {_io.getValues('tipoStatus') === 'tracking' && (
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Controller
                render={({ field }) => (
                  <Autocomplete
                    multiple
                    options={statusTrackingList}
                    getOptionLabel={(option: StatusTracking) => option.name}
                    filterSelectedOptions
                    value={_io.valorAutocompleteTracking()}
                    onChange={(option, value) =>
                      _io.setValue(
                        'listaStatusTracking',
                        value.map((item) => ({
                          codigo: item.value
                        }))
                      )
                    }
                    size="small"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        {...field}
                        variant="outlined"
                        label="Status do tracking"
                        size="small"
                        required
                        {..._io.register('listaStatusTracking', { required: true })}
                        error={_io.listaStatusTrackingError}
                        helperText={(_io.errors.listaStatusTracking as any)?.message}
                      />
                    )}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          colorfont={customTheme.colors.primary}
                          background={customTheme.colors.blue01}
                          key={option.value}
                          label={option.name}
                          size="small"
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                  />
                )}
                name="listaStatusTracking"
                control={_io.control}
              />
            </Grid>
          )}
          {_io.getValues('tipoStatus') === 'efacil' && (
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Controller
                render={({ field }) => (
                  <Autocomplete
                    multiple
                    options={statusEfacilList}
                    getOptionLabel={(option: StatusEfacil) => option.value + ' - ' + option.name}
                    filterSelectedOptions
                    value={_io.valorAutocompleteEfacil()}
                    onChange={(option, value) =>
                      _io.setValue(
                        'listaStatusEfacil',
                        value.map((item) => ({
                          codigo: item.value
                        }))
                      )
                    }
                    size="small"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        {...field}
                        variant="outlined"
                        label="Status e-Fácil"
                        size="small"
                        required
                        {..._io.register('listaStatusEfacil', { required: true })}
                        error={_io.listaStatusEfacilError}
                        helperText={(_io.errors.listaStatusEfacil as any)?.message}
                      />
                    )}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          colorfont={customTheme.colors.laranja08}
                          background={customTheme.colors.laranja01}
                          key={option.value}
                          label={option.name}
                          size="small"
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                  />
                )}
                name="listaStatusEfacil"
                control={_io.control}
              />
            </Grid>
          )}
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Controller
              name="verificarOcorrencia"
              control={_io.control}
              render={({ field }) => (
                <FormControlLabel
                  control={<Switch {...field} defaultChecked={_io.verificarOcorrenciaAtivoValorPadrao} onChange={_io.handleVerificarOcorrenciaToggle} />}
                  label={'Ativar consulta de ocorrências SAC/0800?'}
                  labelPlacement="start"
                />
              )}
            />
          </Grid>
        </Grid>
      </Form>
    </CustomDialog>
  )
}
