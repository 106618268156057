import { useHistory } from 'react-router-dom'
import { gerarDadosGrafico } from '../../../../utils/tratarDadosMesesUtils'
import { formateCurrency } from '../../../../utils/stringUtils'
import { inadimplenciaAnomaliaStyles } from './InadimplenciaAnomalia.style'
import { InadimplenciaAnomaliaProps } from './InadimplenciaAnomalia.View'

export function inadimplenciaAnomaliaIO({ dadosMensais, mesesSelecionados }: InadimplenciaAnomaliaProps) {
  const history = useHistory()

  function getNomeMesComMaiorValor(data: { valor: number[]; nomeMeses: string[][] }) {
    const maxValue = Math.max(...data.valor)
    const index = data.valor.indexOf(maxValue)
    return data.nomeMeses[index][0].toLocaleUpperCase()
  }

  return {
    styles: inadimplenciaAnomaliaStyles,
    history,
    dadosGrafico: gerarDadosGrafico(dadosMensais, mesesSelecionados),
    quantidadeTotal: dadosMensais.reduce((total, dado) => total + dado.quantidade, 0).toLocaleString('pt-BR'),
    valorTotal: formateCurrency(dadosMensais.reduce((total, dado) => total + dado.valor, 0)),
    getNomeMesComMaiorValor
  }
}

export type InadimplenciaAnomaliaIO = ReturnType<typeof inadimplenciaAnomaliaIO>
