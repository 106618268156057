import { FormControl, TextField, Grid } from '@material-ui/core'
import styled from 'styled-components'

export const cadastrarNovoParceiroStyles = {
  ContainerForm: styled.form`
    padding: 15px 0px;
  `,
  TextoFormularioInativo: styled(TextField).attrs({
    variant: 'outlined',
    size: 'small',
    fullWidth: true,
    disabled: true
  })``,
  TextoFormulario: styled(TextField).attrs({
    variant: 'outlined',
    size: 'small',
    fullWidth: true
  })``,
  FormularioControle: styled(FormControl).attrs({
    variant: 'outlined',
    size: 'small',
    fullWidth: true
  })``,
  GridButtons: styled(Grid)`
    padding: 8px;
  `
}
