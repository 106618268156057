import { Fade, Modal, Paper } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import styled from 'styled-components'
import { customTheme } from '../../../../styles/customTheme'

export const modalDesativarStyles = {
  FadeContainer: styled(Fade)`
    background: ${customTheme.colors.white01};
    border-radius: '8px';
  `,
  TextoSpan: styled.span`
    font-weight: 900;
    padding: 5px;
  `,
  TextoSimplesSpan: styled.span`
    margin: 5px 30px;
  `,
  Paper: styled(Paper)`
    padding: 16px;
  `,
  CloseIcon: styled(CloseIcon)`
    cursor: 'pointer';
  `,
  Container: styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 8px 0px;
  `,
  ContainerModal: styled(Modal)`
    display: flex;
    align-items: center;
    justify-content: center;
  `
}
