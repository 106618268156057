import { Box, FormControlLabel, Grid, InputLabel, MenuItem, Switch, TextField } from '@material-ui/core'
import { ChangeEvent } from 'react'
import { IOProps } from 'react-compose-io'
import { Controller } from 'react-hook-form'
import ReactSelect from 'react-select'
import { TRANSFORMACAO, TRANSFORMACAO_INFO } from '../../../constants/transformacao'
import { GrupoRegra } from '../../../types/transformacaoApiTypes'
import { TooltipComponent } from '../../Tooltip/Tooltip'
import { FieldsDescricaoIO } from './FieldsDescricao.IO'

export type FieldsDescricaoProps = {
  isEdicao?: boolean
  ativo?: boolean
  mudarStatusAtivo?: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void
  grupoRegraLista: GrupoRegra[]
}

export function FieldsDescricaoView({ _io, ativo, mudarStatusAtivo, isEdicao }: IOProps<FieldsDescricaoIO, FieldsDescricaoProps>) {
  const { FormControl, FormGroup, ReactSelectStyles, Fieldset, FormLabel, SelectPadrao } = _io.styles

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={3}>
        <FormGroup>
          <Controller
            render={({ field }) => <TextField fullWidth {...field} size="small" label="Título da regra" variant="outlined" />}
            name="nomeRegra"
            control={_io.control}
          />
          <TooltipComponent texto={TRANSFORMACAO_INFO.tituloRegra} />
        </FormGroup>
      </Grid>
      <Grid item xs={12} sm={12} md={3}>
        <FormGroup>
          <Controller
            render={({ field }) => (
              <FormControl>
                <ReactSelect styles={ReactSelectStyles} isClearable isMulti {...field} options={_io.gruposRegraListSelect} placeholder="Grupo de Regras" />
              </FormControl>
            )}
            name="grupos"
            control={_io.control}
          />
          <TooltipComponent texto={TRANSFORMACAO_INFO.grupoRegra} />
        </FormGroup>
      </Grid>
      {isEdicao && (
        <>
          <Grid container item sm={12} md={6} justifyContent="flex-end" alignItems="center">
            <Controller
              name="ativo"
              control={_io.control}
              render={({ field }) => (
                <FormControlLabel
                  control={<Switch {...field} color="primary" defaultChecked={ativo} onChange={mudarStatusAtivo} />}
                  label={ativo ? 'Ativado' : 'Desativado'}
                />
              )}
            />
          </Grid>
        </>
      )}
      <Fieldset>
        <Grid item sm={12} md={12}>
          <FormGroup>
            <FormLabel>{TRANSFORMACAO.formularioTipoTemplate}</FormLabel>
            <Box>
              <Controller
                render={({ field }) => (
                  <FormControl>
                    <InputLabel>Selecione</InputLabel>
                    <SelectPadrao {...field} id="to" label="Selecione">
                      <MenuItem value="IdentificadorPedido">Id. do pedido</MenuItem>
                      <MenuItem value="Loja">Loja</MenuItem>
                      <MenuItem value="TipoLancamento">Tipo de lançamento</MenuItem>
                      <MenuItem value="Lancamento">Lançamento</MenuItem>
                      <MenuItem value="Valor">Valor</MenuItem>
                      <MenuItem value="Parcela">Parcela</MenuItem>
                      <MenuItem value="QuantidadeParcelas">Total de Parcelas</MenuItem>
                      <MenuItem value="PedidoPlanilhaSku">Código SKU</MenuItem>
                    </SelectPadrao>
                  </FormControl>
                )}
                name="campo"
                control={_io.control}
              />
            </Box>
            <TooltipComponent texto={TRANSFORMACAO_INFO.tipoTemplate} />
          </FormGroup>
        </Grid>
      </Fieldset>
    </Grid>
  )
}
