import styled from 'styled-components'
import { Avatar, Select, FormControl } from '@material-ui/core'
import { ContainerSombra } from '../ContainerSombra'
import { Tipografia } from '../Tipografia'

export const selecionarParceiroStyles = {
  Container: styled(ContainerSombra)`
    flex-grow: 1;
    padding: 24px;
    margin-top: 16px;
  `,
  Avatar: styled(Avatar)`
    .MuiAvatar-img {
      height: 40px;
      width: 40px;
      object-position: top;
      object-fit: none;
    }
  `,
  MenuItemContainer: styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
  `,
  Select: styled(Select)`
    height: 61px;
  `,
  FormControl: styled(FormControl)`
    margin: 12px 0px;
  `,
  Subtitulo: styled(Tipografia).attrs({
    variant: 'subtitle1'
  })`
    margin-top: 8px;
    margin-bottom: 24px;
  `
}
