import { GraficoDonutClassificacoesIO } from './GraficoDonutClassificacoes.IO'
import { Grid } from '@material-ui/core'
import { IOProps } from 'react-compose-io'
import { ContainerSombra } from '../../../../components/ContainerSombra'
import { GraficoDonut } from '../../../../components/GraficoDonut'
import { Tipografia } from '../../../../components/Tipografia'
import { formateCurrency } from '../../../../utils/stringUtils'
import { corLetraChip, customTheme } from '../../../../styles/customTheme'
import { transformarQuantidadeEmPorcentagem } from '../../../../utils/tratarDadosMesesPedidosClassificadosUtils'

export type GraficoDonutClassificacoesProps = {
  tituloGrafico: string
  quantidades: number[]
  classificacoes: string[]
  valores: number[]
  tamanhoGrafico: string
}

export function GraficoDonutClassificacoesView({
  _io,
  tituloGrafico,
  quantidades,
  classificacoes,
  tamanhoGrafico,
  valores
}: IOProps<GraficoDonutClassificacoesIO, GraficoDonutClassificacoesProps>) {
  const { ContainerGrafico, GridLegenda, Classificacao } = _io.styles

  return (
    <ContainerSombra>
      <Grid container spacing={2} alignItems="center" justifyContent="space-around">
        <Grid item xs={4} sm={3}>
          <ContainerGrafico>
            <Tipografia fontSize={'1.25rem'} fontWeight={'500'}>
              {tituloGrafico}
            </Tipografia>
            <GraficoDonut
              tituloGrafico={''}
              dadosColunas={transformarQuantidadeEmPorcentagem(quantidades)}
              legendaGrafico={classificacoes}
              ocultarLegenda
              width={tamanhoGrafico}
              height={tamanhoGrafico}
              tipoGrafico="classificacao"
            />
          </ContainerGrafico>
        </Grid>
        <Grid item xs={8}>
          <Grid container spacing={2}>
            {classificacoes.map((classificacao, index) => (
              <GridLegenda item key={classificacao}>
                <Classificacao cor={corLetraChip.get(classificacao) || customTheme.colors.cinza05}>
                  {`${classificacao} (${transformarQuantidadeEmPorcentagem(quantidades)[index].toFixed(2)}%)`}
                </Classificacao>
                <Tipografia fontSize="1.125rem" colorText={customTheme.colors.cinza07}>
                  {`${quantidades[index].toLocaleString('pt-BR')} pedidos`}
                </Tipografia>
                <Tipografia fontSize="1.125rem" colorText={customTheme.colors.cinza07} fontWeight="500">
                  {formateCurrency(valores[index])}
                </Tipografia>
              </GridLegenda>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </ContainerSombra>
  )
}
