import { ChangeEvent, useState } from 'react'
import { popoverFiltroPossiveisTratativasStyles } from './PopoverFiltroPossiveisTratativas.styles'
import { PopoverFiltroPossiveisTratativasProps } from './PopoverFiltroPossiveisTratativas.View'
import { CheckListItem } from '../CheckListItem'

export function popoverFiltroPossiveisTratativasIO({ handleCheckPossivelTratativa, isPossivelTratativaChecked }: PopoverFiltroPossiveisTratativasProps) {
  const [filtroPossivelTratativaInput, setFiltroPossivelTratativaInput] = useState('')

  const handleChangePossivelTratativaInput = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFiltroPossivelTratativaInput(e.target.value)
  }

  const filtrarTratativas = (possivelTratativa: string) => possivelTratativa.toUpperCase().includes(filtroPossivelTratativaInput.toUpperCase())

  const renderTratativasFiltradas = (possivelTratativa: string) => (
    <CheckListItem
      key={possivelTratativa}
      id={possivelTratativa}
      texto={possivelTratativa}
      handleCheck={() => handleCheckPossivelTratativa(possivelTratativa)}
      isChecked={isPossivelTratativaChecked(possivelTratativa)}
    />
  )

  return {
    styles: popoverFiltroPossiveisTratativasStyles,
    filtrarTratativas,
    handleChangePossivelTratativaInput,
    renderTratativasFiltradas
  }
}

export type PopoverFiltroPossiveisTratativasIO = ReturnType<typeof popoverFiltroPossiveisTratativasIO>
