import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import msgConstants from '../../../../constants/msgConstants'
import controleComissaoManager from '../../../../services/api/controleComissao/controleComissaoManager'
import { EditarChamadoForm } from '../../../../types/controleComissaoTypes'
import { historicoChamadoStyles } from './HistoricoChamado.styles'
import { HistoricoChamadoProps } from './HistoricoChamado.View'

export function historicoChamadoIO({ setIsEditandoChamado, chamado, buscarChamados }: HistoricoChamadoProps) {
  const [editarChamado, setEditarChamado] = useState(false)

  const { handleSubmit, control, setValue } = useForm<EditarChamadoForm>({
    defaultValues: {
      status: 1,
      descricao: ''
    }
  })

  const handleEditarChamado = () => {
    setValue('descricao', '')
    setValue('status', chamado.statusAtual)
    setEditarChamado(true)
    setIsEditandoChamado(true)
  }

  const handleCancelarEdicao = () => {
    setEditarChamado(false)
    setIsEditandoChamado(false)
    setValue('descricao', '')
    setValue('status', 1)
  }

  const handleConfirmarEdicao = (dataForm: EditarChamadoForm) => {
    setEditarChamado(false)
    setIsEditandoChamado(false)
    controleComissaoManager
      .editarChamado(chamado.controleCicloId, chamado.id, dataForm)
      .then(() => {
        buscarChamados()
      })
      .catch(() => {
        toast.error(msgConstants.CONTROLE_COMISSAO.erroEditarChamado)
      })
  }

  return {
    styles: historicoChamadoStyles,
    handleSubmit,
    control,
    handleCancelarEdicao,
    handleConfirmarEdicao,
    handleEditarChamado,
    editarChamado,
    setEditarChamado
  }
}

export type HistoricoChamadoIO = ReturnType<typeof historicoChamadoIO>
