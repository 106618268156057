import { Backdrop, Chip, Grid } from '@material-ui/core'
import { IOProps } from 'react-compose-io'
import { CustomDialog } from '../../../../components/CustomDialog/CustomDialog'
import { Config, HistoricoPadrao, semSequencia, TipoLancamento } from '../../../../types/tipoLancamentoApiTypes'
import { ParametroContabilDetalhes } from '../ParametroContabilDetalhes/index'
import { ModalConfirmarIO } from './ModalConfirmar.IO'
import { Botao } from '../../../../components/Botao'

export type ModalConfirmarProps = {
  isModal: boolean
  fecharModal: () => void
  submeterLancamento: () => void
  tipoLancamento: TipoLancamento
  configurarTipoLancamento?: Config
  historicoPadrao?: HistoricoPadrao
}

export function ModalConfirmarView({
  _io,
  isModal,
  fecharModal,
  submeterLancamento,
  tipoLancamento,
  configurarTipoLancamento,
  historicoPadrao
}: IOProps<ModalConfirmarIO, ModalConfirmarProps>) {
  const { Container, TextoSpan } = _io.styles

  return (
    <>
      <CustomDialog
        titulo={'Confirmar novo tipos de lançamento eFácil'}
        id={'modalHistorico'}
        habilitarAcoes
        fecharHabilitado
        actions={
          <Container>
            <Botao textoBotao="Cancelar" estilo="text" cor="primaria" onClick={fecharModal} />
            <Botao textoBotao="Confirmar" estilo="rounded" cor="primaria" onClick={submeterLancamento} />
          </Container>
        }
        open={isModal}
        onClose={fecharModal}
        fecharModal={fecharModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        fullWidth={true}
        maxWidth={'md'}
      >
        <>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <TextoSpan>Deseja mesmo associar este tipo de lançamento:</TextoSpan>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Chip label={tipoLancamento?.descricao || ''} />
            </Grid>
          </Grid>

          <ParametroContabilDetalhes
            config={tipoLancamento.sequencia !== semSequencia.sequencia ? configurarTipoLancamento : undefined}
            sequencia={tipoLancamento.sequencia !== semSequencia.sequencia ? tipoLancamento.sequencia : '-'}
            historicoPadrao={historicoPadrao}
            parametrosContabeisLabel={'A esta sequência'}
            historicoPadraoLabel={'E a este histórico padrão'}
          />
        </>
      </CustomDialog>
    </>
  )
}
