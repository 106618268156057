import React, { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import msgConstants from '../../../../constants/msgConstants'
import parceiroManager from '../../../../services/api/parceiro/parceiroManager'
import { loadingReset, loadingUpdate } from '../../../../store/actions/loadingAction'
import { TipoLancamentoParceiro } from '../../../../types/parceiroApiTypes'
import { ItemTipoLancamento } from '../ItemTipoLancamento/index'
import { tabTipoLancamentoParceiro } from './TabTipoLancamentoParceiro.style'

export function tabTipoLancamentoParceiroIO() {
  const dispatch = useDispatch()
  const [openCadastro, setOpenCadastro] = useState(false)
  const [tipoLancamentoList, setTipoLancamentoList] = useState<TipoLancamentoParceiro[]>([])
  const { parceiroId } = useParams<{ parceiroId: string }>()

  const atualizarDados = useCallback(async () => {
    try {
      dispatch(loadingUpdate(true))
      const response: TipoLancamentoParceiro[] = await parceiroManager.buscarTipoLancamentoParceiro(parceiroId)
      setTipoLancamentoList(response)
    } catch (err) {
      toast.error(msgConstants.TIPO_LANCAMENTO_PARCEIRO.erroBusca)
    } finally {
      dispatch(loadingReset())
    }
  }, [dispatch, parceiroId])

  React.useEffect(() => {
    atualizarDados()
  }, [atualizarDados])

  function abrirModalCadastro() {
    setOpenCadastro(true)
  }

  function fecharModalCadastro() {
    setOpenCadastro(false)
  }

  function linhaTabela(props: { item: TipoLancamentoParceiro; index: number }) {
    const { item, index } = props

    return (
      <ItemTipoLancamento parceiroId={parceiroId} atualizarDados={atualizarDados} linhaItemTipoLancamentoParceiro={item} indexLinhaItemTipoLancamento={index} />
    )
  }

  return {
    styles: tabTipoLancamentoParceiro,
    openCadastro,
    tipoLancamentoList,
    abrirModalCadastro,
    fecharModalCadastro,
    atualizarDados,
    parceiroId,
    linhaTabela
  }
}

export type TabTipoLancamentoParceiroIO = ReturnType<typeof tabTipoLancamentoParceiroIO>
