import { FormControl, Box } from '@material-ui/core'
import styled from 'styled-components'
import { ContainerSombra } from '../../../../components/ContainerSombra'
import { customTheme } from '../../../../styles/customTheme'

export const formNovaAssociacaoStyles = {
  Card: styled(ContainerSombra)`
    margin-top: 25px;
  `,
  Box: styled(Box)`
    margin: 8px;
    border-radius: 5px;
    padding: 40px;
    border: 1px solid ${customTheme.colors.grey02};
    width: 100%;
  `,
  Label: styled.label`
    margin-right: 45px;
    color: ${customTheme.colors.grey04};
    font-size: ${customTheme.fontSize[18]};
  `,
  FormControlController: styled(FormControl).attrs({
    variant: 'outlined',
    size: 'small',
    fullWidth: true
  })``,
  ContainerBotao: styled.div`
    width: 100%;
    margin: 10px;
    display: flex;
    align-items: center;
    gap: 12px;
    justify-content: space-between;
  `,
  GridBotao: styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
  `,
  ContainerForm: styled.form`
    margin-top: 8px;
  `
}
