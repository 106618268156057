import { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import msgConstants from '../../../../constants/msgConstants'
import { PAGES } from '../../../../router/routes'
import ciclosManager from '../../../../services/api/ciclos/ciclosManager'
import { CicloPorParceiroForm, Ciclos, IniciarNovoCicloForm, novoCiclo } from '../../../../types/ciclosApiType'
import { ResponsePaginado } from '../../../../types/genericoType'
import { cardExpansivelStyles } from './CardExpansivel.style'
import { CardExpansivelProps } from './CardExpansivel.View'
import { useForm } from 'react-hook-form'

export function cardExpansivelIO({ parceiroComCiclos, pagina }: CardExpansivelProps) {
  const [isCardExpandido, setIsCardExpandido] = useState(false)
  const [carregando, setCarregando] = useState(false)
  const [totalLinhas, setTotalLinhas] = useState(0)
  const [modalAberto, setModalAberto] = useState(false)
  const [ciclos, setCiclos] = useState<Ciclos[]>([])
  const router = useHistory()

  const { handleSubmit, control } = useForm<{ cicloId?: number }>({
    defaultValues: {
      cicloId: undefined
    }
  })

  const expandirCard = () => {
    setIsCardExpandido((prev) => !prev)
    if (!isCardExpandido) buscarCiclosParceiro({ pageNumber: 1, pageSize: 5, orderBy: 'desc', orderField: 'CicloId' })
  }

  useEffect(() => {
    setIsCardExpandido(false)
  }, [pagina])

  const buscarCicloIdParceiro = (data: { cicloId?: number }) => {
    buscarCiclosParceiro({ pageNumber: 1, pageSize: 5, orderBy: 'desc', orderField: 'CicloId', cicloId: data.cicloId })
  }

  const buscarCiclosParceiro = useCallback(
    (paginacao: CicloPorParceiroForm) => {
      setCarregando(true)
      ciclosManager
        .buscarCicloParceiro(parceiroComCiclos.parceiroId, paginacao)
        .then((response: ResponsePaginado<Ciclos>) => {
          setCiclos(response.itens)
          setTotalLinhas(response.totalItens)
        })
        .catch(() => {
          toast.error(msgConstants.USO_GENERICO.erroBusca)
        })
        .finally(() => {
          setCarregando(false)
        })
    },
    [parceiroComCiclos.parceiroId]
  )

  async function iniciarCiclo(data: IniciarNovoCicloForm) {
    await ciclosManager
      .iniciarCiclo(parceiroComCiclos.parceiroId, data)
      .then((response: novoCiclo) => {
        router.push(PAGES.iniciarCiclo(response.cicloId))
      })
      .catch(() => {
        toast.error(msgConstants.CICLO_FLUXO.erroIniciarCiclo)
      })
  }

  return {
    styles: cardExpansivelStyles,
    expandirCard,
    iniciarCiclo,
    isCardExpandido,
    buscarCiclosParceiro,
    ciclos,
    totalLinhas,
    carregando,
    modalAberto,
    setModalAberto,
    control,
    handleSubmit,
    buscarCicloIdParceiro,
    buscarCicloParceiroEnter: (event: { key: string }) => {
      if (event.key == 'Enter') {
        return handleSubmit(buscarCicloIdParceiro)()
      }
    }
  }
}
export type CardExpansivelIO = ReturnType<typeof cardExpansivelIO>
