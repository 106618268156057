import { Box, FormControl, Grid, Select, Typography } from '@material-ui/core'
import { CSSObjectWithLabel } from 'react-select'
import styled from 'styled-components'
import { customTheme } from '../../styles/customTheme'

export const stylesFieldForms = {
  ContainerGridCenter: styled(Grid).attrs({
    container: true,
    alignItems: 'center'
  })``,
  FormControl: styled(FormControl).attrs({
    variant: 'outlined',
    size: 'small',
    fullWidth: true
  })``,
  FormSelectControl: styled(Select).attrs({
    color: 'primary'
  })``,
  FormTitle: styled(Typography)`
    color: ${customTheme.colors.grey23};
    font-size: ${customTheme.fontSize[18]};
    font-weight: 500;
  `,
  FormSubtitle: styled(Typography).attrs({
    variant: 'subtitle1'
  })`
    margin-bottom: 24px;
    margin-top: 8px;
  `,
  FormGroup: styled(Box)`
    display: flex;
    align-items: center;
  `,
  FormLabel: styled.label`
    margin-right: 15px;
    color: ${customTheme.colors.grey04};
    font-size: ${customTheme.fontSize[18]};
  `,
  BoxRegra: styled(Box)`
    border: 1px solid ${customTheme.colors.grey02};
    width: 100%;
    padding: 10px;
    margin-top: 15px;
    margin-bottom: 15px;
    border-radius: 5px;
  `,
  Fieldset: styled(Box)`
    border-radius: 5px;
    padding: 10px;
    margin: 15px 8px;
    width: 100%;
    border: 1px solid ${customTheme.colors.grey02};
  `,
  SelectContainer: styled.div`
    margin-right: 20px;
  `,
  SelectPadrao: styled(Select)`
    width: 227px;
  `,
  SelectOperacao: styled(Select)`
    width: 100%;
  `,
  ReactSelectStyles: {
    multiValue: (styles: CSSObjectWithLabel) => ({
      ...styles,
      backgroundColor: customTheme.colors.blue01,
      borderRadius: '24px',
      alignItens: 'center'
    }),
    multiValueLabel: (styles: CSSObjectWithLabel) => ({
      ...styles,
      color: customTheme.colors.primary,
      fontFamily: customTheme.fontFamily.rubik400,
      fontSize: '100%'
    }),
    multiValueRemove: (styles: CSSObjectWithLabel) => ({
      ...styles,
      color: customTheme.colors.white05,
      borderRadius: '100%',
      padding: 0,
      margin: 8,
      backgroundColor: customTheme.colors.primary,
      '&:hover': {
        backgroundColor: customTheme.colors.primary,
        color: customTheme.colors.white05,
        cursor: 'pointer'
      }
    }),
    dropdownIndicator: (styles: CSSObjectWithLabel) => ({
      ...styles,
      '&:hover': {
        cursor: 'pointer'
      }
    }),
    clearIndicator: (styles: CSSObjectWithLabel) => ({
      ...styles,
      '&:hover': {
        cursor: 'pointer',
        opacity: '0.9',
        transition: '2s'
      }
    })
  }
}
