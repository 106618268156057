import styled from 'styled-components'

export const modalBuscarOPSStyles = {
  Form: styled.form`
    margin-bottom: 25px;

    input[type='number'] {
      -moz-appearance: textfield;
    }

    input[type='number']::-webkit-outer-spin-button,
    input[type='number']::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  `,
  ContainerDateInputs: styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
  `
}
