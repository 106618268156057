import Toolbar from '@material-ui/core/Toolbar'
import { ReactNode } from 'react'
import { DrawerContextProvider } from '../../contexts/drawer-context'
import { CustomDrawer } from '../CustomDrawer'
import { Header } from '../Header'
import { layoutStyles } from './Layout.style'

type Props = {
  children: NonNullable<ReactNode>
}

export const Layout: React.FC<React.PropsWithChildren<Props>> = ({ children }) => {
  const { DivRoot, Container, Main } = layoutStyles

  return (
    <DrawerContextProvider>
      <DivRoot>
        <Header />
        <Toolbar />
        <Container>
          <CustomDrawer />
          <Main>{children}</Main>
        </Container>
      </DivRoot>
    </DrawerContextProvider>
  )
}
