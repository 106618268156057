import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'

type CustomSimpleTableProps = {
  children: React.ReactNode
  cabecalhos: string[]
}

const CustomSimpleTable = ({ cabecalhos, children }: CustomSimpleTableProps) => {
  return (
    <TableContainer>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            {cabecalhos.map((cabecalho: string) => {
              return (
                <TableCell key={cabecalho} align="center">
                  {cabecalho}
                </TableCell>
              )
            })}
          </TableRow>
        </TableHead>
        <TableBody>{children}</TableBody>
      </Table>
    </TableContainer>
  )
}

export { CustomSimpleTable }
