import { TableRow } from '@material-ui/core'
import { IOProps } from 'react-compose-io'
import { Tabela } from '../../../../components/Tabela'
import { DetalhesPedidoMovimentoContabil } from '../../../../types/pedidosTypes'
import { cabecalhoPedidoContabilizacao } from '../../../../types/tabelaTypes'
import { formateCurrency, formateDate, validarTexto } from '../../../../utils/stringUtils'
import { TabelaMovimentoContabilIO } from './TabelaMovimentoContabil.IO'

export type TabelaMovimentoContabilProps = {
  pedidoInternalizado?: DetalhesPedidoMovimentoContabil[] | null
}

export function TabelaMovimentoContabilView({ _io, pedidoInternalizado }: IOProps<TabelaMovimentoContabilIO, TabelaMovimentoContabilProps>) {
  const { ContainerTable, TableCell } = _io.styles
  function linhaTabela(props: { item: DetalhesPedidoMovimentoContabil }) {
    const { item } = props

    return (
      <TableRow>
        <TableCell corletra={item.statusLancamento}>{validarTexto('' + item.pedidoId, '-')}</TableCell>
        <TableCell corletra={item.statusLancamento}>{`#${item.cicloId}`}</TableCell>
        <TableCell corletra={item.statusLancamento}>{formateDate(item.dataProcessamento)}</TableCell>
        <TableCell corletra={item.statusLancamento}>{item.tipoLancamentoEfacil}</TableCell>
        <TableCell corletra={item.statusLancamento}>{formateCurrency(item.valor)}</TableCell>
        <TableCell corletra={item.statusLancamento}>{item.sequenciaContabil}</TableCell>
        <TableCell corletra={item.statusLancamento}>{item.parcela}</TableCell>
      </TableRow>
    )
  }

  return (
    <>
      {pedidoInternalizado && (
        <ContainerTable>
          <Tabela
            cabecalho={cabecalhoPedidoContabilizacao}
            itensLista={pedidoInternalizado}
            LinhaTabela={linhaTabela}
            orderCampoPadrao={'pedidoId'}
            rowsPerPageOptions={[5, 10, 25]}
            linhasPorPaginaPadrao={5}
            maxHeight={'none'}
            alinharTextoCentro={true}
          />
        </ContainerTable>
      )}
    </>
  )
}
