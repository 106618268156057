import { Grid, Typography } from '@material-ui/core'
import styled from 'styled-components'
import { customTheme } from '../../../../styles/customTheme'
import { Link } from 'react-router-dom'

export const itemCicloPendenteListaStyles = {
  TituloLista: styled(Typography).attrs({
    variant: 'caption'
  })`
    color: ${customTheme.fontFamily.rubik500};
    font-weight: bold;
    line-height: 23px;
    color: ${customTheme.colors.black};
    margin-left: 10px;
  `,
  SubtituloLista: styled(Typography).attrs({
    variant: 'caption'
  })`
    color: ${customTheme.fontFamily.rubik400};
    font-weight: 400;
    line-height: 23px;
    color: ${customTheme.colors.black};
    margin-left: 10px;
  `,
  ContainerPedidos: styled(Grid).attrs({
    item: true,
    style: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'start',
      textAlign: 'right',
      justifyContent: 'center'
    }
  })``,
  ContainerItemCiclo: styled.div`
    background: ${customTheme.colors.red00};
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 4px 10px;
    margin-bottom: 2px;
    cursor: pointer;

    :hover {
      opacity: 0.8;
    }
  `,
  Link: styled(Link)`
    text-decoration: none;
    color: inherit;
  `
}
