import { useState } from 'react'
import { ResponseRegularizacaoPendente } from '../types/regularizacaoOPsTitulosTypes'

export function usePendenciasOpsTitulos() {
  const [pendenciasOpsTitulos, setPendenciasOpsTitulos] = useState<ResponseRegularizacaoPendente | null>(null)

  function SalvarPendenciasOpsTitulos(pendencias: ResponseRegularizacaoPendente) {
    setPendenciasOpsTitulos(pendencias)
  }

  function AtualizarPendenciasOpsTitulos(pedidoMkt: string) {
    if (pendenciasOpsTitulos != null) {
      const pendenciasOpsTitulosAtualizado = pendenciasOpsTitulos

      const pendenciasOpsTitulosAtualizadoEmAberto = pendenciasOpsTitulos?.itens.filter((item) => item.pedidoMkp != pedidoMkt)

      if (pendenciasOpsTitulosAtualizadoEmAberto.length == 0) {
        setPendenciasOpsTitulos(null)
        return
      }

      pendenciasOpsTitulosAtualizado.itens = pendenciasOpsTitulosAtualizadoEmAberto
      pendenciasOpsTitulosAtualizado.totalItens = pendenciasOpsTitulos.totalItens - 1
      setPendenciasOpsTitulos(pendenciasOpsTitulosAtualizado)
    }
  }

  return { pendenciasOpsTitulos, SalvarPendenciasOpsTitulos, AtualizarPendenciasOpsTitulos }
}
