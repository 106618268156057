import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import * as yup from 'yup'
import { detalhesCicloPedidoStyles } from './DetalhesCicloPedido.style'
import { useCallback, useEffect, useState } from 'react'
import { AtualizarDivergenciasPedidosMktRequest, DivergenciaPedidoMktResponse, ObterDivergenciasPedidosFiltros } from '../../types/cicloPedidosTypes'
import { loadingReset, loadingUpdate } from '../../store/actions/loadingAction'
import cicloPedidosManager from '../../services/api/cicloPedidos/cicloPedidosManager'
import { ResponsePaginado } from '../../types/genericoType'
import { toast } from 'react-toastify'
import { Paginacao } from '../../types/tabelaTypes'
import msgConstants from '../../constants/msgConstants'

export function detalhesCicloPedidoIO() {
  const dispatch = useDispatch()
  const router = useHistory()
  const { parceiroId } = useParams<{ parceiroId: string }>()
  const [abrirModalResolvidos, setAbrirModalResolvidos] = useState(false)
  const [abrirModalObservacao, setAbrirModalObservacao] = useState(false)
  const [detalhesCicloPedidoList, setDetalhesCicloPedidoList] = useState<ResponsePaginado<DivergenciaPedidoMktResponse>>()
  const [divergenciaRelatorio, setDivergenciaRelatorio] = useState<string>()
  const [checklistsSelecionados, setChecklistsSelecionados] = useState<string[]>([])

  const schema = yup.object({
    dataFimEmissao: yup.string().test('valorMinimo', `Deve ser maior ou igual que 'Data de emissão'`, function (dataFimEmissao, form) {
      const dataInicial = '' + form.parent.dataInicioEmissao
      const dataFinal = '' + dataFimEmissao

      if (form.parent.dataInicioEmissao == '') return true

      if (dataFimEmissao == '') return true

      return new Date(dataInicial) <= new Date(dataFinal)
    })
  })

  const {
    handleSubmit,
    control,
    formState: { errors },
    register,
    setValue,
    getValues
  } = useForm<ObterDivergenciasPedidosFiltros>({
    defaultValues: {
      pedidoMkt: '',
      dataInicioEmissao: '',
      dataFimEmissao: ''
    },
    resolver: yupResolver(schema)
  })

  const obterDivergenciasPedidosMkt = useCallback(
    async (paginacao: Paginacao) => {
      dispatch(loadingUpdate(true))

      await cicloPedidosManager
        .buscarDivergenciasPedidosMkt(parceiroId, {
          pageNumber: paginacao.pageNumber,
          pageSize: paginacao.pageSize,
          orderBy: paginacao.orderBy,
          orderField: paginacao.orderField,
          pedidoMkt: getValues('pedidoMkt'),
          dataInicioEmissao: getValues('dataInicioEmissao'),
          dataFimEmissao: getValues('dataFimEmissao')
        })
        .then((detalhesCicloPedidoResponse: ResponsePaginado<DivergenciaPedidoMktResponse>) => {
          setDetalhesCicloPedidoList(detalhesCicloPedidoResponse)
        })
        .catch((error) => toast.error(error.message))
        .finally(() => dispatch(loadingReset()))
    },
    [dispatch, parceiroId]
  )

  const obterRelatorioDivergencias = async () => {
    cicloPedidosManager
      .buscarDivergenciasRelatorio(parceiroId)
      .then((response: string) => setDivergenciaRelatorio(response))
      .catch((error) => toast.error(error.message))
  }

  useEffect(() => {
    obterDivergenciasPedidosMkt({ pageNumber: 1, pageSize: 10 })
    obterRelatorioDivergencias()
    setChecklistsSelecionados([])
  }, [])

  const handleLimpar = () => {
    setValue('pedidoMkt', '')
    setValue('dataInicioEmissao', '')
    setValue('dataFimEmissao', '')
    obterDivergenciasPedidosMkt({ pageNumber: 1, pageSize: 10 })
    setChecklistsSelecionados([])
  }

  const handleAplicar = () => {
    obterDivergenciasPedidosMkt({ pageNumber: 1, pageSize: 10 })
    setChecklistsSelecionados([])
  }

  const handleAdicionarObservacao = async (data: { observacao: string }) => {
    const body = checklistsSelecionados.map((x) => {
      return {
        observacao: data.observacao,
        pedidoMkt: x
      } as AtualizarDivergenciasPedidosMktRequest
    })

    setAbrirModalObservacao(false)

    await cicloPedidosManager
      .atualizarDivergenciasPedidosMkt(parceiroId, body)
      .then(() => {
        toast.success(msgConstants.CICLO_PEDIDO.observacaoAdicionadaSucesso)
        obterDivergenciasPedidosMkt({ pageNumber: 1, pageSize: 10 })
      })
      .catch((error) => toast.error(msgConstants.CICLO_PEDIDO.observacaoAdicionadaErro, error.message))

    setChecklistsSelecionados([])
  }

  const handleConfirmarStatusModal = async () => {
    const body = checklistsSelecionados.map((x) => {
      return {
        pedidoMkt: x,
        isResolvido: true
      } as AtualizarDivergenciasPedidosMktRequest
    })

    setAbrirModalResolvidos(false)

    await cicloPedidosManager
      .atualizarDivergenciasPedidosMkt(parceiroId, body)
      .then(() => {
        toast.success(msgConstants.CICLO_PEDIDO.statusResolvidoSucesso)
        obterDivergenciasPedidosMkt({ pageNumber: 1, pageSize: 10 })
      })
      .catch((error) => toast.error(msgConstants.CICLO_PEDIDO.statusResolvidoErro, error.message))

    setChecklistsSelecionados([])
  }

  return {
    styles: detalhesCicloPedidoStyles,
    router,
    handleSubmit,
    control,
    errors,
    register,
    handleLimpar,
    handleAplicar,
    detalhesCicloPedidoList,
    abrirModalResolvidos,
    setAbrirModalResolvidos,
    abrirModalObservacao,
    setAbrirModalObservacao,
    handleAdicionarObservacao,
    obterDivergenciasPedidosMkt,
    divergenciaRelatorio,
    checklistsSelecionados,
    setChecklistsSelecionados,
    handleConfirmarStatusModal
  }
}

export type DetalhesCicloPedidoIO = ReturnType<typeof detalhesCicloPedidoIO>
