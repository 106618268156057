import {
  ContabilizacaoFile,
  DetalhesContabilizacao,
  ResponseDetalhesContabilizacao,
  ResumoContabilizacao,
  ValidarCicloAjuste,
  ValoresContabilizacao
} from '../../../types/conciliacaoApiTypes'
import conciliacaoService from './conciliacaoService'

const buscarContabilizacoes = async (cicloId: string | undefined, lancamento?: string, sequenciaId?: string): Promise<ValoresContabilizacao> =>
  conciliacaoService.buscarContabilizacoes(cicloId, lancamento, sequenciaId)

const buscarDetalhesContabilizacoes = async (cicloId: string, body?: ResponseDetalhesContabilizacao): Promise<DetalhesContabilizacao> =>
  conciliacaoService.buscarDetalhesContabilizacoes(cicloId, body)

const exportarContabilizacaoCsv = async (cicloId?: string): Promise<ContabilizacaoFile> => conciliacaoService.exportarContabilizacaoCsv(cicloId)

const exportarContabilizacaoPdf = async (cicloId?: string): Promise<ContabilizacaoFile> => conciliacaoService.exportarContabilizacaoPdf(cicloId)

const validarConciliacaoCiclo = async (cicloId: string | number, validar: ValidarCicloAjuste): Promise<string> =>
  conciliacaoService.validarConciliacaoCiclo(cicloId, validar)

const confirmarConciliacaoValidacao = async (cicloId?: string | number): Promise<string> => conciliacaoService.confirmarConciliacaoValidacao(cicloId)

const buscarResumoContabilizacao = async (cicloId: string): Promise<ResumoContabilizacao> => conciliacaoService.buscarResumoContabilizacao(cicloId)

const exportarRelatorioContabilizacao = async (cicloId: string): Promise<void> => conciliacaoService.exportarRelatorioContabilizacao(cicloId)

export default {
  buscarContabilizacoes,
  buscarDetalhesContabilizacoes,
  exportarContabilizacaoCsv,
  exportarContabilizacaoPdf,
  validarConciliacaoCiclo,
  confirmarConciliacaoValidacao,
  buscarResumoContabilizacao,
  exportarRelatorioContabilizacao
}
