import { Drawer } from '@material-ui/core'
import styled from 'styled-components'
import { customTheme } from '../../styles/customTheme'

export const customDrawerStyle = {
  Drawer: styled(Drawer).attrs((props: { isopen: number }) => props)`
    .MuiDrawer-paper {
      background: ${customTheme.colors.white01};
      position: static;

      ${(props) => isOpen(props)}
    }
  `
}

function isOpen(props: { isopen: number }) {
  return props.isopen === 1
    ? `
          width: 240px;
          transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms
          `
    : `
          width: 57px;
          overflow-x: hidden;
          transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms
          `
}
