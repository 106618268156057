import { Grid, TextField } from '@material-ui/core'
import { IOProps } from 'react-compose-io'
import { Controller } from 'react-hook-form'
import { CustomDialog } from '../../../../components/CustomDialog/CustomDialog'
import { GrupoRegra } from '../../../../types/transformacaoApiTypes'
import { ModalGrupoRegraIO } from './ModalGrupoRegra.IO'
import { Botao } from './../../../../components/Botao/index'

export type ModalGrupoRegraProps = {
  isShow: boolean
  isClosed: () => void
  atualizarDados: () => void
  formData?: GrupoRegra
}

export default function ModalGrupoRegraView({ _io, isShow }: IOProps<ModalGrupoRegraIO, ModalGrupoRegraProps>) {
  return (
    <CustomDialog
      open={isShow}
      onClose={_io.fecharModal}
      id="modal-add-tipo-lancamento"
      titulo="Adicionar novo grupo"
      habilitarAcoes
      fecharHabilitado
      fecharModal={_io.fecharModal}
      actions={
        <Grid container justifyContent="center" alignItems="center" spacing={2}>
          <Grid item>
            <Botao textoBotao={'Cancelar'} estilo="text" cor="primaria" onClick={_io.fecharModal} />
          </Grid>
          <Grid item>
            <Botao width="114px" textoBotao={'Salvar'} estilo="rounded" cor="primaria" onClick={_io.handleSubmit(_io.salvarGrupoRegra)} />
          </Grid>
        </Grid>
      }
    >
      <form>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Controller
              render={({ field }) => (
                <TextField
                  required
                  {..._io.register('nome', { required: true })}
                  {...field}
                  label="Nome do grupo"
                  variant="outlined"
                  size="small"
                  error={_io.errors?.nome ? true : false}
                  helperText={_io.errors.nome?.message}
                  fullWidth
                />
              )}
              name="nome"
              control={_io.control}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Controller
              render={({ field }) => (
                <TextField
                  required
                  {..._io.register('descricao', { required: true })}
                  {...field}
                  label="Descrição"
                  variant="outlined"
                  size="small"
                  fullWidth
                  error={_io.errors?.descricao ? true : false}
                  helperText={_io.errors.descricao?.message}
                />
              )}
              name="descricao"
              control={_io.control}
            />
          </Grid>
        </Grid>
      </form>
    </CustomDialog>
  )
}
