import { Grid, TextField } from '@material-ui/core'
import { IOProps } from 'react-compose-io'
import { TabTransformacaoIO } from './TabTransformacao.IO'
import EditarAtivadoIcone from '../../../../assets/icons/edit-active.svg'
import EditarDesativadoIcone from '../../../../assets/icons/edit-desabilitado.svg'
import { Tipografia } from '../../../../components/Tipografia'
import { Controller } from 'react-hook-form'
import IconeButton from '../../../../components/IconeButton/IconeButton'
import { Botao } from './../../../../components/Botao/index'

export type TabTransformacaoProps = {}

export default function TabTransformacaoView({ _io }: IOProps<TabTransformacaoIO, TabTransformacaoProps>) {
  const { Container, ContainerForm, ImageIcon } = _io.styles

  return (
    <>
      <Container>
        <Tipografia>{'Insira abaixo o título das colunas de “Status” e o “Número do pedido” conforme o relatório do parceiro.'}</Tipografia>
        <ContainerForm>
          <Grid container direction="row" spacing={2} alignItems="center">
            <Grid item>
              <Controller
                render={({ field }) => (
                  <TextField
                    disabled={!_io.isEditando}
                    {...field}
                    label="Status"
                    variant="outlined"
                    size="small"
                    fullWidth
                    InputLabelProps={{
                      shrink: true
                    }}
                    error={_io.errors?.status ? true : false}
                    helperText={_io.errors.status?.message}
                  />
                )}
                name="status"
                control={_io.control}
              />
            </Grid>
            <Grid item>
              <Controller
                render={({ field }) => (
                  <TextField
                    disabled={!_io.isEditando}
                    {...field}
                    label="Número do pedido"
                    variant="outlined"
                    size="small"
                    fullWidth
                    InputLabelProps={{
                      shrink: true
                    }}
                    error={_io.errors?.pedido ? true : false}
                    helperText={_io.errors.pedido?.message}
                  />
                )}
                name="pedido"
                control={_io.control}
              />
            </Grid>
            {!_io.isPrimeiroCadastro && (
              <Grid item>
                <IconeButton
                  color="inherit"
                  descricao="Editar"
                  icone={<ImageIcon src={_io.isEditando ? EditarDesativadoIcone : EditarAtivadoIcone} />}
                  acao={() => _io.setIsEditando(true)}
                />
              </Grid>
            )}
          </Grid>
        </ContainerForm>
        <Botao onClick={_io.handleSubmit(_io.handleSalvar)} textoBotao="Salvar" cor="primaria" estilo="contained" disabled={!_io.isEditando} width="114px" />
      </Container>
    </>
  )
}
