import { HistoricoDebitoProps } from './HistoricoDebito.View'
import { historicoDebitoStyles } from './HistoricoDebito.styles'

export function historicoDebitoIO({ item }: HistoricoDebitoProps) {
  const getClassificacaoSugerida = (observacao: string) => {
    const regex = /^Classificação sugerida:\s*([^\\.]{0,100})\./

    const classificacao = observacao.match(regex)

    if (classificacao) {
      return classificacao[1]
    }

    return ''
  }

  const getObservacao = (observacaoCompleta: string) => {
    const regex = /(?<=\.)\s*(.*)/

    const observacao = observacaoCompleta.match(regex)

    if (observacao) {
      return observacao[1]
    }

    return ''
  }

  const getPrimeiraClassificacaoSugerida = (index: number) => {
    return item.observacoes.findIndex((historico) => historico.observacao.startsWith('Classificação sugerida:')) === index
  }

  return {
    styles: historicoDebitoStyles,
    getClassificacaoSugerida,
    getObservacao,
    getPrimeiraClassificacaoSugerida
  }
}

export type HistoricoDebitoIO = ReturnType<typeof historicoDebitoIO>
