import { useForm } from 'react-hook-form'
import { detalheInadimplenciaStyles } from './DetalheInadimplencia.style'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { ChangeEvent, useCallback, useEffect, useState, useReducer } from 'react'
import {
  AtualizarPedidoInadimplenciaRequest,
  ClassificacaoInadimplencia,
  INADIMPLENCIA,
  ObterPedidosInadimplenciaFiltros,
  ObterPedidosInadimplenciaResponse
} from '../../types/controleInadimplenciaTypes'
import { useDispatch } from 'react-redux'
import controleInadimplenciasManager from '../../services/api/controleInadimplencia/controleInadimplenciaManager'
import { toast } from 'react-toastify'
import msgConstants from '../../constants/msgConstants'
import { loadingReset, loadingUpdate } from './../../store/actions/loadingAction'
import { Paginacao } from '../../types/tabelaTypes'
import { useParams } from 'react-router-dom'
import { PossiveisTratativas, ResponsePaginadoCargo } from '../../types/perdasTypes'
import { CabecalhoTabelaDetalhesPerdas } from '../../components/TabelaDetalhesPerdas/TabelaDetalhesPerdas.View'
import { ItemInadimplencia } from './components/ItemInadimplencia'
import { detalhePerdasReducer } from '../../reducers/detalhePerdas/reducer'
import {
  abrirFiltroClassificacoes,
  abrirFiltroParceiros,
  abrirFiltroPedidos,
  abrirFiltroTratativas,
  abrirModalClassificacaoPedidos,
  aprovarSugestaoClassificacao,
  fecharFiltro,
  fecharModalClassificacaoPedidos,
  limparCheckboxGeral,
  limparFiltros,
  mudarPagina,
  mudarTamanhoPagina,
  preencherPossiveisTratativas,
  reiniciarPaginacao,
  restaurarValoresAposClassificarPedidos,
  selecionarCheckboxFiltroClassificacoes,
  selecionarCheckboxFiltroParceiros,
  selecionarCheckboxFiltroTratativas,
  selecionarCheckboxGeral
} from '../../reducers/detalhePerdas/actions'

const schema = yup.object({
  dataFimEmissao: yup.string().test('valorMinimo', `Deve ser maior ou igual que 'Data de emissão'`, function (dataFimEmissao, form) {
    const dataInicial = '' + form.parent.dataInicioEmissao
    const dataFinal = '' + dataFimEmissao

    if (form.parent.dataInicioEmissao == '') return true

    if (dataFimEmissao == '') return true

    return new Date(dataInicial) <= new Date(dataFinal)
  })
})

export function detalheInadimplenciaIO() {
  const { anomalia, parceiroId } = useParams<{ anomalia: string; parceiroId: string }>()

  const [detalhesPedidosInadimplencia, setDetalhesPedidosInadimplencia] = useState<ResponsePaginadoCargo<ObterPedidosInadimplenciaResponse>>()
  const [classificacoesInadimplencia, setClassificacoesInadimplencia] = useState<ClassificacaoInadimplencia[]>([])

  const [detalhePerdasState, dispatch] = useReducer(detalhePerdasReducer, {
    classificacaoSugerida: 0,
    checklistsSelecionados: [] as string[],
    modalClassificarPedidosAberto: false,
    isFiltroParceiroAberto: null,
    isFiltroPedidoAberto: null,
    isFiltroClassificacoesAberto: null,
    isFiltroPossiveisTratativasAberto: null,
    checkListParceirosSelecionados: Number(parceiroId) != 0 ? [Number(parceiroId)] : [],
    checkListPossiveisTratativasSelecionados: anomalia != INADIMPLENCIA.TOTAL ? [anomalia] : [],
    checkListClassificacoesSelecionados: [],
    possiveisTratativas: [],
    pagina: 0,
    linhasPorPagina: 10
  })

  const {
    classificacaoSugerida,
    checklistsSelecionados,
    modalClassificarPedidosAberto,
    isFiltroParceiroAberto,
    isFiltroPedidoAberto,
    isFiltroClassificacoesAberto,
    isFiltroPossiveisTratativasAberto,
    checkListParceirosSelecionados,
    checkListPossiveisTratativasSelecionados,
    checkListClassificacoesSelecionados,
    possiveisTratativas,
    pagina,
    linhasPorPagina
  } = detalhePerdasState

  const cabecalhosTabela: CabecalhoTabelaDetalhesPerdas[] = [
    {
      descricao: 'Data de emissão'
    },
    {
      descricao: 'Número do pedido marketplace',
      abrirPopover: (event: React.MouseEvent<HTMLButtonElement>) => dispatch(abrirFiltroPedidos(event.currentTarget))
    },
    {
      descricao: 'Saldo dos títulos'
    },
    {
      descricao: 'Possível tratativa',
      abrirPopover: (event: React.MouseEvent<HTMLButtonElement>) => dispatch(abrirFiltroTratativas(event.currentTarget))
    },
    {
      descricao: 'Classificação da análise',
      abrirPopover: (event: React.MouseEvent<HTMLButtonElement>) => dispatch(abrirFiltroClassificacoes(event.currentTarget))
    },
    {
      descricao: 'Parceiro',
      abrirPopover: (event: React.MouseEvent<HTMLButtonElement>) => dispatch(abrirFiltroParceiros(event.currentTarget))
    }
  ]

  const loadingDispatch = useDispatch()

  const {
    handleSubmit,
    control,
    formState: { errors },
    register,
    setValue,
    getValues
  } = useForm<ObterPedidosInadimplenciaFiltros>({
    defaultValues: {
      pedidoMkt: '',
      dataInicioEmissao: '',
      dataFimEmissao: ''
    },
    resolver: yupResolver(schema)
  })

  useEffect(() => {
    obterPedidosInadimplencia({ pageNumber: 1, pageSize: 10 })
    buscarClassificacoesInadimplencia()
    buscarPossiveisTratativas()
  }, [])

  const buscarPossiveisTratativas = async () => {
    const possiveisTratativasResponse: PossiveisTratativas = await controleInadimplenciasManager.buscarTratativasInadimplencia()
    dispatch(preencherPossiveisTratativas(possiveisTratativasResponse.tratativas))
  }

  const buscarClassificacoesInadimplencia = async () => {
    await controleInadimplenciasManager
      .buscarClassificacoesInadimplencia()
      .then((response: ClassificacaoInadimplencia[]) => setClassificacoesInadimplencia(response))
      .catch((error) => toast.error(error.message))
  }

  const handleClassificarPedidos = async (data: { observacao: string; classificacao: number }) => {
    loadingDispatch(loadingUpdate(true))
    const body = (checklistsSelecionados as string[]).map((x: string) => {
      return {
        observacao: data.observacao,
        pedidoPlanilha: x,
        classificacao: data.classificacao != 0 ? data.classificacao : null
      } as AtualizarPedidoInadimplenciaRequest
    })

    controleInadimplenciasManager
      .atualizarPedidosInadimplencia(body)
      .then(() => {
        toast.success(msgConstants.DEBITO_PEDIDO.pedidoAtualizadoSucesso)
      })
      .catch((error) => toast.error(error.message))
      .finally(() => {
        handleAplicar()
      })

    dispatch(restaurarValoresAposClassificarPedidos())
  }

  const obterPedidosInadimplencia = useCallback(
    async (paginacao: Paginacao, isResetarBusca = false) => {
      loadingDispatch(loadingUpdate(true))
      await controleInadimplenciasManager
        .buscarPedidosInadimplencia({
          pageNumber: paginacao.pageNumber,
          pageSize: paginacao.pageSize,
          orderBy: paginacao.orderBy,
          orderField: paginacao.orderField,
          pedidoMkt: getValues('pedidoMkt'),
          dataInicioEmissao: getValues('dataInicioEmissao'),
          dataFimEmissao: getValues('dataFimEmissao'),
          parceiroId: isResetarBusca ? [] : checkListParceirosSelecionados,
          possivelTratativa: isResetarBusca ? [] : checkListPossiveisTratativasSelecionados,
          classificacao: isResetarBusca ? [] : checkListClassificacoesSelecionados
        })
        .then((detalhesCicloPedidoResponse: ResponsePaginadoCargo<ObterPedidosInadimplenciaResponse>) => {
          setDetalhesPedidosInadimplencia(detalhesCicloPedidoResponse)
        })
        .catch((error) => toast.error(error.message))
        .finally(() => loadingDispatch(loadingReset()))
    },

    [loadingDispatch, checkListParceirosSelecionados, checkListPossiveisTratativasSelecionados, checkListClassificacoesSelecionados]
  )

  const handleAplicar = () => {
    dispatch(reiniciarPaginacao())
    obterPedidosInadimplencia({ pageNumber: 0, pageSize: 10 })
  }

  const handleLimpar = () => {
    setValue('dataInicioEmissao', '')
    setValue('dataFimEmissao', '')
    setValue('pedidoMkt', '')

    dispatch(limparFiltros())

    obterPedidosInadimplencia({ pageNumber: 0, pageSize: 10 }, true)
  }

  const handleCloseFiltro = () => {
    dispatch(fecharFiltro())
    obterPedidosInadimplencia({ pageNumber: 0, pageSize: linhasPorPagina })
  }

  const onMudancaPagina = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, novaPagina: number) => {
    dispatch(mudarPagina(novaPagina))
    obterPedidosInadimplencia({ pageNumber: novaPagina + 1, pageSize: linhasPorPagina })
  }

  const onMudancaLinhasPorPagina = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(mudarTamanhoPagina(parseInt(event.target.value, 10)))
    obterPedidosInadimplencia({ pageNumber: 0, pageSize: parseInt(event.target.value, 10) })
  }

  const handleAprovarSugestao = (pedidoMkt: string, classificacao: number) => {
    dispatch(aprovarSugestaoClassificacao(pedidoMkt, classificacao))
  }

  const handleCheckList = (pedidoMkt: string) => {
    dispatch(selecionarCheckboxGeral(pedidoMkt))
  }

  const renderLinhasTabela = (detalheInadimplencia: ObterPedidosInadimplenciaResponse) => (
    <ItemInadimplencia
      key={detalheInadimplencia.pedidoMkt}
      item={detalheInadimplencia}
      checklistsSelecionados={checklistsSelecionados}
      handleCheckList={handleCheckList}
      isAdministrador={detalhesPedidosInadimplencia ? detalhesPedidosInadimplencia.administrador : false}
      handleAprovarSugestao={handleAprovarSugestao}
    />
  )

  return {
    styles: detalheInadimplenciaStyles,
    control,
    register,
    errors,
    checklistsSelecionados,
    detalhesPedidosInadimplencia,
    fecharModal: () => dispatch(fecharModalClassificacaoPedidos()),
    handleAbrirModalClassificarPedido: () => dispatch(abrirModalClassificacaoPedidos()),
    modalClassificarPedidosAberto,
    handleClassificarPedidos,
    handleCleanAllCheckList: () => dispatch(limparCheckboxGeral()),
    handleSubmit,
    handleAplicar,
    handleLimpar,
    classificacoesInadimplencia,
    classificacaoSugerida,
    cabecalhosTabela,
    renderLinhasTabela,
    linhasPorPagina,
    pagina,
    onMudancaLinhasPorPagina,
    onMudancaPagina,
    isFiltroParceiroAberto,
    isFiltroPedidoAberto,
    isFiltroPossiveisTratativasAberto,
    isFiltroClassificacoesAberto,
    handleCloseFiltro,
    handleCheckFiltroParceiro: (parceiro: number) => dispatch(selecionarCheckboxFiltroParceiros(parceiro)),
    handleCheckFiltroPossivelTratativa: (tratativa: string) => dispatch(selecionarCheckboxFiltroTratativas(tratativa)),
    handleCheckFiltroClassificacoes: (classificacao: string) => dispatch(selecionarCheckboxFiltroClassificacoes(classificacao)),
    isParceiroChecked: (parceiro: number) => checkListParceirosSelecionados.includes(parceiro),
    isPossivelTratativaChecked: (tratativa: string) => checkListPossiveisTratativasSelecionados.includes(tratativa),
    isClassificacaoChecked: (classificacao: string) => checkListClassificacoesSelecionados.includes(classificacao),
    possiveisTratativas
  }
}

export type DetalheInadimplenciaIO = ReturnType<typeof detalheInadimplenciaIO>
