import { CircularProgress, Grid, TextField } from '@material-ui/core'
import styled from 'styled-components'
import { customTheme } from '../../styles/customTheme'
import { Botao } from '../../components/Botao'

export const relatorioConciliacaoStyles = {
  ContainerForm: styled(Grid)`
    background: ${customTheme.colors.white01};
    padding: 24px;
    box-shadow: 1px 2px 9px rgba(33, 33, 33, 0.25);
    border-radius: 4px;
    margin: 0;
  `,
  ContainerFormDataButton: styled.div`
    gap: 8px;
    display: flex;
    align-items: center;
  `,
  ContainerBotao: styled(Grid)`
    margin-top: 10px;
  `,
  UploadPlaninha: styled(Botao).attrs({
    component: 'span'
  })``,
  Input: styled.input`
    display: none;
  `,
  TextoErroBotao: styled(TextField)`
    .MuiFormHelperText-root {
      position: absolute;
      margin-top: 40px;
    }
  `,
  IconeCarregamento: styled(CircularProgress)`
    color: ${customTheme.colors.white01};
  `,
  TemplateContainer: styled.div`
    flex-grow: 1;
    background-color: ${customTheme.colors.white05};
    border-radius: 4px;
    padding: 6px;
    margin-inline: 8px;
  `,
  DownloadButton: styled.a`
    background-color: ${customTheme.colors.blue09};
    cursor: pointer;
    width: 40px;
    height: 40px;
    display: flex;
    padding: 7px;
    border-radius: 4px;

    &:hover {
      opacity: 0.75;
    }
  `
}
