import { CabecalhoHistoricoIO } from './CabecalhoHistorico.IO'
import { FormControl, Grid, MenuItem, Select } from '@material-ui/core'
import { IOProps } from 'react-compose-io'
import CloseIcon from '@material-ui/icons/Close'
import FilterIcon from '../../../../assets/icons/filter-icon-branco.svg'
import { Dispatch, SetStateAction } from 'react'
import { Tipografia } from './../../../../components/Tipografia/index'
import { corParceiroId } from '../../../../styles/customTheme'
import { ModalFiltroParceiro } from '../../../../components/ModalFiltroParceiro'
import { Controller } from 'react-hook-form'
import { Botao } from './../../../../components/Botao/index'

export type CabecalhoHistoricoProps = {
  parceiroSelecionado: number
  handleClickParceiro: (parceiroId: number) => void
  setMesesSelecionado: Dispatch<SetStateAction<1 | 6 | 12>>
}

export function CabecalhoHistoricoView({ _io, parceiroSelecionado, handleClickParceiro }: IOProps<CabecalhoHistoricoIO, CabecalhoHistoricoProps>) {
  const { Avatar, Chip, ContainerFormTitulo, Form } = _io.styles

  return (
    <>
      <Grid container spacing={2} justifyContent="space-between" alignItems="center" direction="row">
        <Grid item>
          <ContainerFormTitulo>
            <Tipografia variant="h5">Histórico de Perdas</Tipografia>
            {parceiroSelecionado !== 0 && _io.parceiros.length > 0 ? (
              <Chip
                icon={<Avatar src={_io.parceiros.filter((x) => x.parceiroId === parceiroSelecionado)[0].logoUrl} />}
                label={_io.parceiros.filter((x) => x.parceiroId === parceiroSelecionado)[0].razaoSocial}
                onDelete={() => _io.handleFecharChip()}
                deleteIcon={<CloseIcon />}
                variant="outlined"
                cor={corParceiroId.get(parceiroSelecionado)}
              />
            ) : null}
            <Form>
              <Controller
                render={({ field }) => (
                  <FormControl variant="standard" size="small" fullWidth>
                    <Select {...field} label="meses">
                      <MenuItem value={12}>Últimos 12 meses</MenuItem>
                      <MenuItem value={6}>Últimos 6 meses</MenuItem>
                      <MenuItem value={1}>Ano</MenuItem>
                    </Select>
                  </FormControl>
                )}
                name="meses"
                control={_io.control}
              />
            </Form>
          </ContainerFormTitulo>
        </Grid>
        <Grid item>
          <Botao
            textoBotao={'Filtrar por parceiro'}
            cor="primaria"
            estilo="contained"
            type="submit"
            startIcon={<img src={FilterIcon} />}
            onClick={() => _io.setAbrirModalFiltroParceiros(true)}
          />
        </Grid>
      </Grid>
      <ModalFiltroParceiro
        descricaoModal={`A visualização de histórico de perdas pode ser feita por parceiro.`}
        isShow={_io.abrirModalFiltroParceiros}
        isClosed={() => _io.setAbrirModalFiltroParceiros(false)}
        handleClickParceiro={handleClickParceiro}
        parceiros={_io.parceiros}
      />
    </>
  )
}
