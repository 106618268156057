import { cabecalhoDebitosStyles } from './CabecalhoDebitos.style'
import { CabecalhoDebitosProps } from './CabecalhoDebitos.View'
import { useCallback, useEffect, useState } from 'react'
import { Parceiro } from '../../../types/parceiroApiTypes'
import { toast } from 'react-toastify'
import parceiroManager from '../../../services/api/parceiro/parceiroManager'
import msgConstants from '../../../constants/msgConstants'

export function cabecalhoDebitosIO({ setParceiroTransportadoras, handleClickParceiro }: CabecalhoDebitosProps) {
  const [abrirModalFiltroParceiros, setAbrirModalFiltroParceiros] = useState(false)
  const [parceiros, setParceiros] = useState<Parceiro[]>([])

  useEffect(() => {
    obterParceirosAtivos()
  }, [])

  const obterParceirosAtivos = useCallback(async () => {
    await parceiroManager
      .buscarDadosParceiros(true)
      .then((response: Parceiro[]) => {
        setParceiros(response)
      })
      .catch(() => {
        toast.error(msgConstants.USO_GENERICO.erroBusca)
      })
  }, [])

  function handleFecharChip() {
    handleClickParceiro(0)
    setParceiroTransportadoras([])
  }

  return {
    styles: cabecalhoDebitosStyles,
    abrirModalFiltroParceiros,
    setAbrirModalFiltroParceiros,
    parceiros,
    handleFecharChip
  }
}

export type CabecalhoDebitosIO = ReturnType<typeof cabecalhoDebitosIO>
