import { TableRow, Chip, Box } from '@material-ui/core'
import styled from 'styled-components'
import { customTheme } from '../../../../styles/customTheme'

export const itemTransformacaoStyles = {
  TableRowHistorico: styled(TableRow)`
    background-color: ${customTheme.colors.blue01};
  `,
  ChipGrupoRegra: styled(Chip)`
    background: ${customTheme.colors.blue01};
    color: ${customTheme.colors.primary};
    margin: 2px;
    font-size: ${customTheme.fontSize[12]};
    border-radius: 24px;
  `,
  BoxHistorico: styled(Box)`
    padding: 8px;
  `
}
