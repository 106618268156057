import { Divider, Grid, Typography } from '@material-ui/core'
import styled from 'styled-components'
import { ContainerSombra } from '../../../../components/ContainerSombra'

export const debitoInicialTransportadoraBoxStyles = {
  Box: styled(ContainerSombra)`
    padding: 16px;
    margin-bottom: 16px;
  `,
  Divider: styled(Divider)`
    width: -webkit-fill-available;
    margin: 24px;
  `,
  GridTransportadoras: styled(Grid)`
    background-color: #b1d3ff1f;
    padding: 28px 32px !important;
    border-radius: 14px;
  `,
  GridValorTotal: styled(Grid)`
    padding: 0px 32px !important;
  `,
  GridExpardirIcone: styled(Grid)`
    margin-top: 16p;
  `,
  Titulo: styled(Typography)`
    margin-bottom: 24px;
  `
}
