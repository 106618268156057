import { IOProps } from 'react-compose-io'
import { DetalhesCicloPedidoIO } from './DetalhesCicloPedido.IO'
import { Tipografia } from './../../components/Tipografia/index'
import { Grid, TextField } from '@material-ui/core'
import { Controller } from 'react-hook-form'
import { Tabela } from '../../components/Tabela'
import { detalhesCicloPedido } from '../../types/tabelaTypes'
import { DivergenciaPedidoMktResponse } from '../../types/cicloPedidosTypes'
import { ItemCicloPedido } from './components/ItemCicloPedido'
import ConfirmarDialog from './../../components/ConfirmarDialog/ConfirmarDialog'
import { ModalAdicionarObservacao } from './components/ModalAdicionarObservacao'
import { Botao } from '../../components/Botao'
import { BotaoIconeExportar } from '../../components/BotaoIconeExportar/BotaoIconeExportar'

export type DetalhesCicloPedidoProps = {}

export function DetalhesCicloPedidoView({ _io }: IOProps<DetalhesCicloPedidoIO, DetalhesCicloPedidoProps>) {
  const { Container, BoxTabela, ContainerFormDataButton, TextFieldDataFim } = _io.styles

  function linhaTabela(props: { item: DivergenciaPedidoMktResponse }) {
    const { item } = props

    return <ItemCicloPedido item={item} checklistsSelecionados={_io.checklistsSelecionados} setChecklistsSelecionados={_io.setChecklistsSelecionados} />
  }

  return (
    <>
      <Tipografia variant="h5" fontWeight="500">
        Controle de ciclo de pedido
      </Tipografia>
      <Container>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Tipografia fontWeight="500" variant="h6">
              Detalhes de pedidos
            </Tipografia>
          </Grid>
          <Grid item>
            <BotaoIconeExportar url={_io.divergenciaRelatorio ? _io.divergenciaRelatorio : ''} />
          </Grid>
        </Grid>
        <BoxTabela>
          <form>
            <Grid container spacing={2} justifyContent="space-between">
              <Grid item xs={4}>
                <Controller
                  render={({ field }) => <TextField {...field} label="Buscar número do pedido marketplace" variant="outlined" size="small" fullWidth />}
                  name="pedidoMkt"
                  control={_io.control}
                />
              </Grid>
              <Grid item xs={2} />
              <Grid item xs={4}>
                <ContainerFormDataButton>
                  <Controller
                    render={({ field }) => (
                      <TextField
                        {..._io.register('dataInicioEmissao', { required: true })}
                        {...field}
                        label="Data de emissão"
                        variant="outlined"
                        size="small"
                        type="date"
                        fullWidth
                        InputLabelProps={{
                          shrink: true
                        }}
                      />
                    )}
                    name="dataInicioEmissao"
                    control={_io.control}
                  />
                  <Tipografia>até</Tipografia>
                  <Controller
                    render={({ field }) => (
                      <TextFieldDataFim
                        {..._io.register('dataFimEmissao', { required: true })}
                        {...field}
                        label=""
                        variant="outlined"
                        size="small"
                        type="date"
                        fullWidth
                        error={_io.errors?.dataFimEmissao ? true : false}
                        helperText={_io.errors.dataFimEmissao?.message}
                      />
                    )}
                    name="dataFimEmissao"
                    control={_io.control}
                  />
                </ContainerFormDataButton>
              </Grid>
              <Grid item xs={2}>
                <ContainerFormDataButton>
                  <Botao textoBotao="Limpar" cor="primaria" estilo="text" onClick={_io.handleLimpar} />
                  <Botao onClick={_io.handleSubmit(_io.handleAplicar)} textoBotao="Aplicar" cor="primaria" estilo="contained" />
                </ContainerFormDataButton>
              </Grid>
            </Grid>
          </form>

          {_io.detalhesCicloPedidoList && (
            <Tabela
              cabecalho={detalhesCicloPedido}
              itensLista={_io.detalhesCicloPedidoList.itens}
              LinhaTabela={linhaTabela}
              orderCampoPadrao={''}
              rowsPerPageOptions={[10, 25, 50]}
              linhasPorPaginaPadrao={10}
              maxHeight={'none'}
              alinharTextoCentro={false}
              buscarItensTabela={_io.obterDivergenciasPedidosMkt}
              totalItens={_io.detalhesCicloPedidoList.totalItens}
            />
          )}

          <Grid container justifyContent="flex-end" spacing={2}>
            <Grid item>
              <Botao
                disabled={_io.checklistsSelecionados.length == 0}
                textoBotao="Adicionar observação"
                onClick={() => _io.setAbrirModalObservacao(true)}
                cor="primaria"
                estilo="outlined"
              />
            </Grid>
            <Grid item>
              <Botao
                disabled={_io.checklistsSelecionados.length == 0}
                onClick={() => _io.setAbrirModalResolvidos(true)}
                textoBotao="Status resolvido"
                cor="primaria"
                estilo="contained"
              />
            </Grid>
          </Grid>
        </BoxTabela>
      </Container>
      <ConfirmarDialog
        open={_io.abrirModalResolvidos}
        setOpen={_io.setAbrirModalResolvidos}
        handleConfirm={_io.handleConfirmarStatusModal}
        confirmarButtonLabel={'Sim'}
        cancelarButtonLabel={'Não'}
      >
        <Tipografia variant="body1" textAlignment="center">
          Deseja mesmo marcar esse(s) pedido(s) como resolvido?
        </Tipografia>
      </ConfirmarDialog>
      <ModalAdicionarObservacao
        handleConfirmar={_io.handleAdicionarObservacao}
        isClosed={() => _io.setAbrirModalObservacao(false)}
        isShow={_io.abrirModalObservacao}
      />
    </>
  )
}
