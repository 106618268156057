import { tabelaArrastavelStyles } from './TabelaArrastavel.style'
import { TabelaArrastavelProps } from './TabelaArrastavel.View'
import { DropResult } from 'react-beautiful-dnd'

export function tabelaArrastavelIO({ setItensTabela }: TabelaArrastavelProps) {
  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return
    }

    if (result.destination.index === result.source.index) {
      return
    }

    setItensTabela((tabelaAtual: any) => {
      const tabelaAlterada = [...tabelaAtual]

      const [itemArrastado] = tabelaAlterada.splice(result.source.index, 1)
      tabelaAlterada.splice(result.destination!.index, 0, itemArrastado)

      return tabelaAlterada
    })
  }

  return {
    styles: tabelaArrastavelStyles,
    handleDragEnd
  }
}

export type TabelaArrastavelIO = ReturnType<typeof tabelaArrastavelIO>
