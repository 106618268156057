import { Grid } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { IOProps } from 'react-compose-io'
import Modal from '../../../../components/Modal/Modal'
import { ModalConfiguracoesGeraisIO } from './ModalConfiguracoesGerais.IO'

export type ModalConfiguracoesGeraisProps = {
  abrirModal: boolean
  handleFecharModal: () => void
  handleSalvarModal: () => void
}

export function ModalConfiguracoesGeraisView({ _io, abrirModal, handleFecharModal }: IOProps<ModalConfiguracoesGeraisIO, ModalConfiguracoesGeraisProps>) {
  const { Formulario, TextoFormulario } = _io.styles
  return (
    <Modal
      title="Cadastrar"
      openModal={abrirModal}
      fullWidth={true}
      maxWidth="md"
      onClose={handleFecharModal}
      onAction={_io.handleSubmit(_io.cadastrarNovaConfiguracao)}
      disabledButton={!_io.FatoId}
    >
      <Formulario>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={6}>
            <Autocomplete
              options={_io.siglaSistemaLista}
              getOptionLabel={(item) => `${item.sigla} - ${item.nome}`}
              onChange={(_e, options) => _io.setValue('SiglaSistema', options?.sigla || '')}
              renderInput={(params) => <TextoFormulario {...params} label="Sigla do sistema" fullWidth placeholder="Sigla do sistema" />}
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <Autocomplete
              options={_io.unidadeLista}
              disabled={!_io.SiglaSistema}
              getOptionLabel={(item) => `${item.unidadeNegocioId} - ${item.nome}`}
              onChange={(e, options) => _io.setValue('UnidadeNegocioId', options?.unidadeNegocioId || 0)}
              renderInput={(params) => <TextoFormulario {...params} label="Unidade de negócio" fullWidth placeholder="Unidade de negócio" />}
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <Autocomplete
              options={_io.eventoLista}
              disabled={!_io.UnidadeNegocioId}
              getOptionLabel={(item) => `${item.eventoId} - ${item.nome}`}
              onChange={(e, options) => _io.setValue('EventoId', options?.eventoId || 0)}
              renderInput={(params) => <TextoFormulario {...params} label="Evento" fullWidth placeholder="Evento" />}
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <Autocomplete
              options={_io.fatoLista}
              disabled={!_io.EventoId}
              getOptionLabel={(item) => `${item.fatoId} - ${item.nome}`}
              onChange={(e, options) => _io.setValue('FatoId', options?.fatoId || 0)}
              renderInput={(params) => <TextoFormulario {...params} label="Fato" fullWidth placeholder="Fato" />}
            />
          </Grid>
        </Grid>
      </Formulario>
    </Modal>
  )
}
