import { Grid, TableCell, TableRow } from '@material-ui/core'
import styled from 'styled-components'
import { customTheme } from '../../../../styles/customTheme'
import { Tipografia } from '../../../../components/Tipografia/index'
import { handleHexToRgba } from '../../../../utils/stringUtils'

export const historicoDebitoStyles = {
  TableRow: styled(TableRow)`
    background-color: ${handleHexToRgba(0.28, customTheme.colors.grey02)};
    padding: 19px;
  `,
  TableCell: styled(TableCell)`
    padding-bottom: 0px;
    padding-top: 0px;
  `,
  ContainerGrid: styled(Grid)`
    background-color: ${customTheme.colors.white01};
    padding: 16px 18px;
    margin-bottom: 12px;
  `,
  ContainerDetalheObservacao: styled.div`
    padding: 8px 28px;
  `,
  TipografiaHistorico: styled(Tipografia).attrs((props: { margintop?: string; marginbottom?: string }) => props)`
    margin-bottom: ${(props) => (props.marginbottom ? props.marginbottom : 'none')};
    margin-top: ${(props) => (props.margintop ? props.margintop : 'none')};
  `,
  TituloDescricao: styled(Tipografia)`
    margin: 17px 0;
  `,
  ContainerFlex: styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
  `
}
