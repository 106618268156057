import { FormControl, Grid, InputLabel, MenuItem, Select } from '@material-ui/core'
import { IOProps } from 'react-compose-io'
import { Controller } from 'react-hook-form'
import ConfirmarDialog from '../../../../components/ConfirmarDialog/ConfirmarDialog'
import { Sequencias } from '../../../../types/tipoLancamentoApiTypes'
import { ModalRegularizacaoCiclosIO } from './ModalRegularizacaoCiclos.IO'

export type ModalRegularizacaoCiclosProps = {
  isShow: boolean
  setOpen: (value: boolean | ((prevVar: boolean) => boolean)) => void
  handleConfirmarEfetivacao: (dataFormulario: any) => Promise<void>
  valorTotalTitulosSelecionados: number
  valorTotalOPsSelecionadas: number
  sequencias: Sequencias[]
}

export function ModalRegularizacaoCiclosView({
  _io,
  isShow,
  setOpen,
  handleConfirmarEfetivacao
}: IOProps<ModalRegularizacaoCiclosIO, ModalRegularizacaoCiclosProps>) {
  const { Container, Typography, Box } = _io.styles

  return (
    <ConfirmarDialog
      open={isShow}
      setOpen={setOpen}
      confirmarButtonLabel={'Confirmar'}
      cancelarButtonLabel={'Cancelar'}
      handleConfirm={_io.handleSubmit(handleConfirmarEfetivacao)}
    >
      <Box>
        <Typography>{_io.textModal}</Typography>
      </Box>
      <form>
        <Container>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Controller
                render={({ field }) => (
                  <FormControl variant="outlined" size="small" fullWidth>
                    <InputLabel>Ação</InputLabel>
                    <Select {...field} label="Ação">
                      <MenuItem value={0}>{_io.textAcao}</MenuItem>
                      <MenuItem value={1}>Regularizar em uma conta contábil</MenuItem>
                    </Select>
                  </FormControl>
                )}
                name="acao"
                control={_io.control}
              />
            </Grid>
            {_io.acaoSelecionada == 1 && (
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Controller
                  render={({ field }) => (
                    <FormControl variant="outlined" size="small" fullWidth error={_io.verificarErros(_io.errors, _io.errors?.sequenciaId)}>
                      <InputLabel>Conta Contábil</InputLabel>
                      <Select {...field} label="Conta Contábil">
                        {_io.sequenciasFiltradas().map((sequenciaCiclosFiltrados) => (
                          <MenuItem key={sequenciaCiclosFiltrados.numeroSequencia} value={sequenciaCiclosFiltrados.numeroSequencia}>
                            {sequenciaCiclosFiltrados.sequencia}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                  name="sequenciaId"
                  control={_io.control}
                />
              </Grid>
            )}
          </Grid>
        </Container>
      </form>
    </ConfirmarDialog>
  )
}
