import { Grid } from '@material-ui/core'
import styled from 'styled-components'

export const graficoValorQuantidadeClassificacoesStyles = {
  GridWidthLimitada: styled(Grid)`
    max-width: 475px;
  `,
  GridContainer: styled(Grid).attrs((width: { width?: string }) => width)`
    width: ${(props) => props.width};
  `
}
