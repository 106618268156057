import { CardContent, FormControlLabel, Grid, IconButton, InputLabel, MenuItem, Select, Switch, TextField, Typography } from '@material-ui/core'
import { Cancel } from '@material-ui/icons'
import { IOProps } from 'react-compose-io'
import { Controller } from 'react-hook-form'
import { CustomSimpleTable } from '../../../../components/CustomSimpleTable'
import Modal from '../../../../components/Modal/Modal'
import { RegraAssociacaoForm } from '../../../../types/associacaoApiTypes'
import { TipoLancamentoParceiro } from '../../../../types/parceiroApiTypes'
import PreVisualizarAssociacao from '../PreVisualizarAssociacao'
import { FormAssociacaoIO } from './FormAssociacao.IO'
import { Botao } from '../../../../components/Botao'

export type FormAssociacaoProps = {
  tipoLancamento: TipoLancamentoParceiro[]
  tituloFormulario: string
  formularioEdicao: boolean
  regraAssociacaoEditar?: RegraAssociacaoForm
}

export function FormAssociacaoView({ _io, tipoLancamento, tituloFormulario, formularioEdicao }: IOProps<FormAssociacaoIO, FormAssociacaoProps>) {
  const { ContainerBotao, GridBotao, FormControlController, Card, Label, Box, ContainerForm } = _io.styles

  return (
    <>
      <Typography variant="h5">{tituloFormulario}</Typography>
      <Card>
        <CardContent>
          <ContainerForm onSubmit={_io.handleSubmit(_io.submeterNovaAssociacao)}>
            <Grid container spacing={2} alignItems="center">
              {formularioEdicao && (
                <>
                  <Grid item xs={3} sm={3} md={3}>
                    <Controller
                      render={({ field }) => <TextField type="number" fullWidth {...field} size="small" label="Prioridade" variant="outlined" />}
                      name="prioridade"
                      control={_io.control}
                    />
                  </Grid>
                  <Grid item xs={7} sm={7} md={7} />
                  <Grid container item xs={2} sm={2} md={2} justifyContent="flex-end">
                    <Controller
                      name="ativo"
                      control={_io.control}
                      render={({ field }) => (
                        <FormControlLabel
                          control={<Switch {...field} defaultChecked={_io.checkedAtivo} onChange={_io.handleCheckedAtivo} color="primary" />}
                          label={_io.checkedAtivo ? 'Ativado' : 'Desativado'}
                          labelPlacement="bottom"
                        />
                      )}
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={12} sm={12} md={12}>
                <Typography variant="h6">Tipo de lançamento parceiro</Typography>
              </Grid>
              <Box>
                {_io.condicoes?.fields.map((condicao, condicaoIndex: number) => (
                  <Grid key={condicao.id} alignItems="center" container item sm={12} md={12} spacing={2}>
                    <Grid item sm={12} md={1}>
                      {condicaoIndex < 1 ? <Label>Se</Label> : <Label>e</Label>}
                    </Grid>
                    <Grid item sm={12} md={2}>
                      <Controller
                        render={({ field }) => (
                          <FormControlController>
                            <InputLabel>Operação</InputLabel>
                            <Select {...field} label="Operação">
                              {_io.listaCondicoes.map((listaCondicao, listaCondicaoIndex: number) => (
                                <MenuItem key={listaCondicaoIndex} value={listaCondicao.operacaoId}>
                                  {listaCondicao.nome}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControlController>
                        )}
                        name={`condicoes.${condicaoIndex}.operacaoId` as `condicoes.0.operacaoId`}
                        control={_io.control}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={3}>
                      <Controller
                        render={({ field }) => (
                          <FormControlController>
                            <InputLabel>Dado</InputLabel>
                            <Select {...field} label="Dado">
                              {_io.dadosCondicoes.map((dados, dadosIndex: number) => (
                                <MenuItem key={dadosIndex} value={dados}>
                                  {dados}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControlController>
                        )}
                        name={`condicoes.${condicaoIndex}.dado` as `condicoes.0.dado`}
                        control={_io.control}
                      />
                    </Grid>
                    <Grid item sm={12} md={2}>
                      <Controller
                        render={({ field }) => (
                          <FormControlController>
                            <InputLabel>Operador</InputLabel>
                            <Select {...field} label="Operador">
                              {_io.listaCondicoes
                                .filter((filtroListaCondicao) => filtroListaCondicao.operacaoId === _io.condicaoSelecionada[condicaoIndex]?.operacaoId)[0]
                                ?.operadores.map((item, index) => (
                                  <MenuItem key={index} value={item}>
                                    {item}
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControlController>
                        )}
                        name={`condicoes.${condicaoIndex}.operador` as `condicoes.0.operador`}
                        control={_io.control}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={3}>
                      <Controller
                        render={({ field }) => (
                          <FormControlController>
                            {_io.condicaoSelecionada[condicaoIndex]?.dado == 'TipoLancamento' ? (
                              <>
                                <InputLabel>Parâmetro</InputLabel>
                                <Select {...field} label="Parametro">
                                  {tipoLancamento.map((lancamento) => (
                                    <MenuItem key={lancamento.tipoLancamentoParceiroId} value={lancamento.descricao}>
                                      {lancamento.descricao}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </>
                            ) : (
                              <TextField fullWidth {...field} size="small" label="Parâmetro" variant="outlined" />
                            )}
                          </FormControlController>
                        )}
                        name={`condicoes.${condicaoIndex}.parametro.parametro` as `condicoes.0.parametro.parametro`}
                        control={_io.control}
                      />
                    </Grid>
                    <Grid item sm={12} md={1}>
                      {_io.condicoes.fields.length > 1 && (
                        <IconButton onClick={() => _io.condicoes.remove(condicaoIndex)}>
                          <Cancel />
                        </IconButton>
                      )}
                    </Grid>
                  </Grid>
                ))}
                <Botao onClick={_io.adicionarMaisCondicao} textoBotao="+ Adicionar condição" cor="primaria" estilo="text" />
              </Box>
              <Box>
                {_io.acoes.fields.map((acaoLancamento, acaoLancamentoIndex: number) => (
                  <Grid key={acaoLancamento.id} alignItems="center" container item sm={12} md={12} spacing={2}>
                    <Grid item sm={12} md={1}>
                      {acaoLancamentoIndex < 1 ? <Label>Então</Label> : <Label>e</Label>}
                    </Grid>
                    <Grid item xs={12} sm={12} md={5}>
                      <Controller
                        render={({ field }) => (
                          <FormControlController>
                            <InputLabel>Operação</InputLabel>
                            <Select {...field} disabled label="Operação">
                              <MenuItem key={acaoLancamentoIndex} value="TipoLancamentoOperacao">
                                Tipo lançamento eFácil
                              </MenuItem>
                            </Select>
                          </FormControlController>
                        )}
                        name={`acoes.${acaoLancamentoIndex}.operacaoId` as `acoes.0.operacaoId`}
                        control={_io.control}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <Controller
                        render={({ field }) => (
                          <FormControlController>
                            <InputLabel>Parâmetro</InputLabel>
                            <Select {...field} label="Parametro">
                              {_io.tipoLancamentoLista.map((lancamentoLista, lancamentoListaIndex) => (
                                <MenuItem key={lancamentoListaIndex} value={lancamentoLista.tipoLancamentosId}>
                                  {lancamentoLista.nome}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControlController>
                        )}
                        name={`acoes.${acaoLancamentoIndex}.parametro.parametro` as `acoes.0.parametro.parametro`}
                        control={_io.control}
                      />
                    </Grid>
                  </Grid>
                ))}
              </Box>
              <ContainerBotao>
                <GridBotao>
                  <Botao textoBotao="Pré-visualizar" cor="primaria" estilo="outlined" onClick={_io.handlePreVisualize} />
                </GridBotao>
                <GridBotao>
                  <Botao textoBotao="Cancelar" cor="primaria" estilo={!formularioEdicao ? 'text' : 'outlined'} onClick={_io.cancelarNovaAssociacao} />
                  <Botao
                    textoBotao="Salvar"
                    cor="primaria"
                    estilo={!formularioEdicao ? 'outlined' : 'contained'}
                    onClick={() => _io.setSalvar(false)}
                    type="submit"
                  />
                  {!formularioEdicao && (
                    <Botao textoBotao="Salvar e continuar" cor="primaria" estilo="contained" onClick={() => _io.setSalvar(true)} type="submit" />
                  )}
                </GridBotao>
              </ContainerBotao>
            </Grid>
          </ContainerForm>
        </CardContent>
        <Modal
          title="Pré visualização de associação"
          openModal={_io.abrirModal}
          fullWidth={false}
          maxWidth={false}
          onClose={_io.handleFecharModal}
          onAction={_io.handleFecharModal}
          hiddenButton
        >
          <Grid item sm={12}>
            <CustomSimpleTable cabecalhos={['Tipo de lançamento parceiro', 'Tipo de lançamento eFácil', 'Conta', 'Natureza', 'Tipo']}>
              {_io.preVisualizar != undefined ? <PreVisualizarAssociacao row={_io.preVisualizar} /> : null}
            </CustomSimpleTable>
          </Grid>
        </Modal>
      </Card>
    </>
  )
}
