import { CircularProgress, FormControl, Grid, TextField, Typography } from '@material-ui/core'
import styled from 'styled-components'
import { customTheme } from '../../../../styles/customTheme'

export const cabecalhoListaParceirosStyles = {
  Titulo: styled(Typography).attrs({
    variant: 'h5'
  })`
    font-weight: 500;
    line-height: 28px;
    color: ${customTheme.colors.grey09};
  `,
  InputData: styled(TextField).attrs({
    color: 'primary',
    border: 'none',
    outline: 'none',
    type: 'date',
    variant: 'outlined',
    size: 'small',
    focused: true,
    style: {
      color: `${customTheme.colors.white01}`,
      background: `${customTheme.colors.white01}`,
      border: 'none',
      outline: 'none'
    }
  })`
    margin-left: 8px;
    border-radius: 8px;
  `,
  ContainerCabecalho: styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    gap: 8px;
  `,
  ContainerBotaoExportar: styled.div`
    background: ${customTheme.colors.white01};
    border-radius: 4px;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  Subtitulo: styled(Typography).attrs({
    variant: 'h6'
  })`
    line-height: 21px;
    color: ${customTheme.colors.grey09};
  `,
  SelecionarTitulos: styled(FormControl).attrs({
    style: {
      background: 'white',
      width: '135px',
      marginLeft: '12px',
      borderRadius: '8px'
    },
    variant: 'outlined',
    size: 'small',
    fullWidth: true
  })``,
  ContainerError: styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 0;
  `,
  IconeCarregamento: styled(CircularProgress).attrs({
    style: {
      color: 'white',
      marginRight: '2px'
    },
    size: '12px'
  })``,
  ContainerTextoErroPreenchimento: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
  TextoErroPreenchimento: styled(Typography).attrs({
    variant: 'caption'
  })`
    color: ${customTheme.colors.red06};
  `,
  ContainerFiltro: styled(Grid)`
    margin-bottom: 8px;
  `
}
