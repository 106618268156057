import { useState } from 'react'
import TipoLancamentoManager from '../../../../services/api/tipoLancamento/tipoLancamentoManager'
import { ValidarCicloAjuste } from '../../../../types/conciliacaoApiTypes'
import { FormValuesSequencias } from '../../../../types/iniciarNovoCicloTypes'
import { Sequencias } from '../../../../types/tipoLancamentoApiTypes'
import { validarCiclosStyle } from './ValidacaoCiclo.style'
import { ValidacaoCicloProps } from './ValidacaoCiclo.View'

export function validacaoCicloIO({ conferirValidacaoCiclo, setContabilizacaoValores, adjustValues, contabilizacaoValores }: ValidacaoCicloProps) {
  const [arraySequencias, setArraySequencias] = useState<Sequencias[]>([])
  const [abrirModal, setAbrirModal] = useState(false)

  async function fetchSequencias() {
    if (adjustValues) {
      const response: Sequencias[] = await TipoLancamentoManager.buscarSequencias(adjustValues.credito < adjustValues.debito ? 'C' : 'D')
      setArraySequencias(response)
    }
  }

  const handleModal = () => {
    fetchSequencias()
    setAbrirModal(true)
  }

  const handleCloseModal = () => {
    setAbrirModal(false)
  }

  const handleClickAdjustmentButton = (seqSelected: FormValuesSequencias) => {
    if (contabilizacaoValores && adjustValues && seqSelected != undefined) {
      const validar: ValidarCicloAjuste = {
        ajuste: adjustValues.diferenca,
        tipoAjuste: adjustValues.credito < adjustValues.debito ? 'C' : 'D',
        sequenciaId: seqSelected.numeroSequenciaId
      }
      conferirValidacaoCiclo(validar)
      if (adjustValues.credito < adjustValues.debito) {
        setContabilizacaoValores({
          credito: contabilizacaoValores.credito + contabilizacaoValores.diferenca,
          debito: contabilizacaoValores.debito,
          diferenca: 0
        })
      } else {
        setContabilizacaoValores({
          credito: contabilizacaoValores.credito,
          debito: contabilizacaoValores.debito + contabilizacaoValores.diferenca,
          diferenca: 0
        })
      }
    }
    setAbrirModal(false)
  }

  return {
    styles: validarCiclosStyle,
    handleModal,
    handleCloseModal,
    arraySequencias,
    abrirModal,
    handleClickAdjustmentButton
  }
}

export type ValidacaoCicloIO = ReturnType<typeof validacaoCicloIO>
