import { DadosMeses } from '../utils/tratarDadosMesesUtils'
import { BaseClassificacao, Processamento } from './genericoType'
import { Paginacao } from './tabelaTypes'
import { PerdasEfetivadas } from './perdasTypes'

export type InadimplenciaPerdasEfetivadas = PerdasEfetivadas

export const INADIMPLENCIA = {
  TOTAL: 'Total',
  BACKOFFICE: 'Backoffice',
  EM_TRANSITO_LOGISTICA_MARTINS: 'LogisticaMartins',
  EM_TRANSITO_LOGISTICA_PARCEIRO: 'LogisticaParceiro',
  SEM_OCORRENCIA_EM_ANALISE: 'Sem ocorrencia',
  PERDA: 'Perda'
}

export type ControleInadimplenciasResponse = {
  processamento: Processamento
  dataAlteracao: string
  relatorios: Relatorio[]
  dadosParceiros: InadimplenciaParceiro[]
}

export type Relatorio = {
  urlRelatorio: string
  tipo: TipoAnomaliaEnum
}

export enum TipoAnomaliaEnum {
  EmTransitoBackoffice = 'Backoffice',
  EmTransitoLogisticaMartins = 'LogisticaMartins',
  EmTransitoLogisticaParceiro = 'LogisticaParceiro',
  SemOcorrenciaEmAnalise = 'Sem ocorrencia',
  SemOcorrenciaPerda = 'Perda',
  Total = 'Total'
}

export type InadimplenciaParceiro = {
  parceiroId: number
  tipo: TipoAnomaliaEnum
  urlRelatorio: string
  dados: DadosMeses[]
}

export type InadimplenciasAgrupadas = {
  ano: number
  valorTotal: number
  quantidadeTotal: number
  dadosMeses: {
    mes: string
    valorTotal: number
    quantidadeTotal: number
  }[]
}

export type ObterPedidosInadimplenciaResponse = {
  pedidoMkt: string
  tratativa: string
  parceiro: string
  saldo: number
  dataEmissao: string
  observacoes: HistoricoObservacaoInadimplencia[]
  titulos: TitulosReferentes[]
  classificacao: string
  classificacaoId: number
  aprovado: boolean
}

type HistoricoObservacaoInadimplencia = {
  observacao: string
  usuario: string
  data: string
}

type TitulosReferentes = {
  numPedido: string
  numTitulo: string
  saldo: string
  dataVencimento: string
  refaturado: string
  dataEmissao: string
}

export type AtualizarPedidoInadimplenciaRequest = {
  pedidoPlanilha: string
  observacao: string
  classificacao: number
}

export interface ObterPedidosInadimplenciaQuery extends Paginacao, ObterPedidosInadimplenciaFiltros {}

export type ObterPedidosInadimplenciaFiltros = {
  dataFimEmissao: string
  dataInicioEmissao: string
  pedidoMkt: string
  possivelTratativa: string[]
  classificacao: string[]
  parceiroId: number[]
}

export type ClassificacaoInadimplencia = BaseClassificacao
