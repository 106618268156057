import React, { useState } from 'react'
import { toast } from 'react-toastify'
import msgConstants from '../../../../constants/msgConstants'
import { ORDEM_CRESCENTE } from '../../../../constants/utilConstants'
import OrdemPagamentoManager from '../../../../services/api/ordemPagamento/ordemPagamentoManager'
import { IOpsVinculadas } from '../../../../types/ciclosApiType'
import { Order } from '../../../../types/tabelaTypes'
import { handleRequestSort, tratarMudancaLinhaPorPagina, tratarMudancaPagina } from '../../../../utils/tabelaUtils'
import { tabelaOPSStyles } from './TabelaOPS.style'
import { TabelaOPSProps } from './TabelaOPS.View'

export function tabelaOPSIO({ data, atualizarLista, cicloId }: TabelaOPSProps) {
  const [ordem, setOrdem] = useState<Order>(ORDEM_CRESCENTE)
  const [ordemBy, setOrdemBy] = useState<keyof never>('ordemPagamentoId')
  const [linhas] = useState<IOpsVinculadas[]>(data)
  const [loading, setLoading] = useState<boolean>(false)
  const [pagina, setPagina] = useState(0)
  const [linhasPorPagina, setLinhasPorPagina] = useState(5)
  const [totalLinhas] = useState(0)
  const [selected, setSelected] = useState<number[]>([])

  const excluirOPS = async (item: IOpsVinculadas) => {
    await OrdemPagamentoManager.desvincularOPS(item.ordemPagamentoId, cicloId, {
      bancoId: item.bancoId,
      dataEmissao: item.dataEmissao,
      operacaoOrdemPagamentoId: item.operacaoOrdemPagamentoId,
      ordemPagamentoId: item.ordemPagamentoId
    })
      .then(() => {
        toast.success(msgConstants.OPS.excluir)
        atualizarLista()
      })
      .catch(() => {
        toast.error(msgConstants.OPS.erroExcluir)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return {
    classes: tabelaOPSStyles,
    loading,
    linhas,
    ordem,
    pagina,
    linhasPorPagina,
    setOrdem,
    ordemBy,
    onMudancaPagina: (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
      tratarMudancaPagina(event, newPage, setPagina)
    },
    onMudancaLinhasPorPagina: (event: React.ChangeEvent<HTMLInputElement>) => {
      tratarMudancaLinhaPorPagina(event, setPagina, setLinhasPorPagina)
    },
    onRequestSort: handleRequestSort(ordemBy, ordem, setOrdem, setOrdemBy),
    rowsPerPageOptions: [5, 10, 25],
    excluirOPS,
    selected,
    setSelected,
    totalLinhas
  }
}
export type TabelaOPSIO = ReturnType<typeof tabelaOPSIO>
