import { Grid, TableCell, TableRow } from '@material-ui/core'
import styled from 'styled-components'
import { customTheme } from '../../../../styles/customTheme'
import { Tipografia } from '../../../../components/Tipografia'
import { handleHexToRgba } from '../../../../utils/stringUtils'

export const HistoricoControleComissaoPedidoStyles = {
  TableRow: styled(TableRow)`
    background-color: ${handleHexToRgba(0.28, customTheme.colors.cinza01)};
    padding: 19px;
  `,
  TableCell: styled(TableCell)`
    padding-bottom: 0px;
    padding-top: 0px;
  `,
  ContainerGrid: styled(Grid)`
    background-color: ${customTheme.colors.cinza02};
    padding: 16px 18px;
    margin-bottom: 12px;
  `,
  TituloDescricao: styled(Tipografia)`
    margin: 17px 0;
  `,
  TipografiaInline: styled(Tipografia)`
    display: inline;
  `
}
