import { itemCicloPendenteListaStyles } from './ItemCicloPendenteLista.style'

export function itemCicloPendenteListaIO() {
  return {
    styles: itemCicloPendenteListaStyles,
    tituloOps: `OP's pendentes`,
    tituloCiclos: `Ciclo`
  }
}

export type ItemCicloPendenteListaIO = ReturnType<typeof itemCicloPendenteListaIO>
