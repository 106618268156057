import { TableContainer } from '@material-ui/core'
import styled from 'styled-components'
import { customTheme } from '../../../../styles/customTheme'

export const collapseInadimplenciaTableStyles = {
  TableTituloContainer: styled.div`
    background-color: ${customTheme.colors.orange04};
    text-align: center;
    padding: 4px 0px;
  `,
  TableContainer: styled(TableContainer)`
    border-radius: 8px 8px 0px 0px;
    width: 285px;
  `,
  TableFooter: styled.div`
    background-color: ${customTheme.colors.white07};
    padding: 4px 11px;
    border-radius: 0px 0px 8px 8px;
    text-align: end;
  `
}
