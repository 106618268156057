import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import React from 'react'
import { modalStyles } from './Modal.style'
import { Botao } from '../Botao'

export interface DialogTitleProps {
  id: string
  children: React.ReactNode
  onClose: () => void
}

export interface ModalDialogProps {
  title: string
  children: React.ReactNode
  openModal: boolean
  fullWidth: boolean
  maxWidth?: false | 'xs' | 'sm' | 'md' | 'lg' | 'xl'
  onClose: () => void
  onAction: () => void
  disabledButton?: boolean
  hiddenButton?: boolean
}

export default function Modal({ title, children, openModal, fullWidth, maxWidth, onClose, onAction, disabledButton, hiddenButton }: ModalDialogProps) {
  const { CloseButton, DialogActions, DialogContent } = modalStyles

  return (
    <div>
      <Dialog onClose={onClose} aria-labelledby="customized-dialog-title" open={openModal} fullWidth={fullWidth} maxWidth={maxWidth}>
        <DialogTitle disableTypography>
          <Typography variant="h6">{title}</Typography>
          {onClose && (
            <CloseButton onClick={onClose}>
              <CloseIcon />
            </CloseButton>
          )}
        </DialogTitle>
        <DialogContent dividers>{children}</DialogContent>
        {!hiddenButton ? (
          <DialogActions>
            <Botao textoBotao="Cancelar" estilo="text" cor="primaria" onClick={onClose} />
            <Botao disabled={disabledButton} textoBotao="Salvar" estilo="text" cor="primaria" onClick={onAction} />
          </DialogActions>
        ) : null}
      </Dialog>
    </div>
  )
}
