import styled from 'styled-components'
import { ContainerSombra } from '../../components/ContainerSombra'

export const regularizacaoCiclosEfetivacaoStyles = {
  Container: styled(ContainerSombra)`
    flex-grow: 1;
    padding: 24px;
    margin-top: 27px;
  `,
  ContainerTabela: styled.div`
    display: flex;
    flex-direction: row;
    align-items: end;
    justify-content: end;
    gap: 12px;
    margin-top: 34px;
  `
}
