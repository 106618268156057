import { Grid, Backdrop } from '@material-ui/core'
import styled from 'styled-components'
import { ContainerSombra } from '../../../../components/ContainerSombra'

export const tabelaCicloStyles = {
  Container: styled(ContainerSombra)`
    flex-grow: 1;
    margin-top: 19px;
    width: 100%;
    position: relative;
  `,
  GridHeader: styled(Grid).attrs({
    direction: 'row',
    alignItems: 'center',
    spacing: 1,
    container: true
  })`
    padding: 25px;
  `,
  LimitedBackdrop: styled(Backdrop)`
    position: absolute;
    z-index: 1;
    border-radius: 10px;
  `
}
