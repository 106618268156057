import { ApexOptions } from 'apexcharts'
import { corAleatoria, corParceiroId, customTheme } from '../../../../styles/customTheme'
import { formateCurrency } from '../../../../utils/stringUtils'
import { graficoTitulosParceiroStyles } from './GraficoDonut.style'
import { GraficoDonutProps } from './GraficoDonut.View'

export function graficoDonutIO({ listaTitulosParceiros, textoValorTotal }: GraficoDonutProps) {
  const listaTitulosTodosParceiros = listaTitulosParceiros ? listaTitulosParceiros : []
  const valorPorcentagemParceiros = listaTitulosTodosParceiros.map((valorPorcentagem) => valorPorcentagem.porcentagem * 100)
  const nomeParceiros = listaTitulosTodosParceiros.map((nomeParceiro) => nomeParceiro.parceiroNome)

  const valorTotalQuantidadeTitulos = listaTitulosTodosParceiros
    .map((totalTitulosAbertos) => totalTitulosAbertos.informacoes[0].valor)
    .reduce((valor, acumulador) => {
      return valor + acumulador
    }, 0)
    .toFixed(2)

  return {
    styles: graficoTitulosParceiroStyles,
    options: {
      chart: {
        type: 'donut',
        stacked: true,
        stackType: '100%',
        redrawOnWindowResize: false
      },
      labels: nomeParceiros,
      dataLabels: {
        enabled: false
      },
      colors: [
        function ({ seriesIndex }: any) {
          if (corParceiroId.get(listaTitulosTodosParceiros[seriesIndex].parceiroId)) {
            return corParceiroId.get(listaTitulosTodosParceiros[seriesIndex].parceiroId)
          }
          return corAleatoria.get(window.crypto.getRandomValues(new Uint8Array(256))[0] % 8)
        }
      ],
      tooltip: {
        y: {
          formatter: function (percentualParceiro: number) {
            return percentualParceiro.toFixed(2) + '%'
          }
        }
      },
      legend: {
        show: true,
        showForSingleSeries: true,
        showForNullSeries: true,
        showForZeroSeries: true,
        position: 'bottom',
        fontSize: '12px',
        fontFamily: `${customTheme.fontFamily.rubik400}`,
        fontWeight: 400,
        itemMargin: {
          horizontal: 40,
          vertical: 10
        }
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              value: {
                show: true,
                fontSize: '16px',
                fontFamily: 'Helvetica, Arial, sans-serif',
                fontWeight: 400,
                color: undefined,
                offsetY: 16,
                formatter: function (percentualParceiro: string) {
                  return percentualParceiro + '%'
                }
              },
              total: {
                show: true,
                label: `(${textoValorTotal})`,
                fontSize: '12px',
                fontWeight: 600,
                formatter: () => formateCurrency(valorTotalQuantidadeTitulos),
                color: `${customTheme.colors.grey03}`
              }
            }
          }
        }
      }
    } as ApexOptions,
    series: valorPorcentagemParceiros
  }
}

export type GraficoDonutIO = ReturnType<typeof graficoDonutIO>
