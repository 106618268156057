import { IOProps } from 'react-compose-io'
import { AppMenuIO } from './AppMenu.IO'
import { AppMenuItem } from '../AppMenuItem'

type AppMenuProps = {}

export function AppMenuView({ _io }: IOProps<AppMenuIO, AppMenuProps>) {
  const { List } = _io.styles

  return (
    <List disablePadding>
      {_io.menusFiltradosPorAcesso.map((item, index) => (
        <AppMenuItem {...item} key={index} />
      ))}
    </List>
  )
}
