import { useHistory } from 'react-router-dom'
import { transitoBoxStyles } from './TransitoBox.styles'
import { PAGES } from '../../../../router/routes'
import { TransitoBoxProps } from './TransitoBox.View'

export function transitoBoxIO({ parceiroId }: TransitoBoxProps) {
  const history = useHistory()

  return {
    styles: transitoBoxStyles,
    handleVerMaisDetalhesPedidoBotao: (anomaliaTipo: string) => history.push(PAGES.detalheDebito(anomaliaTipo, parceiroId))
  }
}

export type TransitoBoxIO = ReturnType<typeof transitoBoxIO>
