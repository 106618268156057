import { Processamento, StatusProcessamento } from './genericoType'

type InformacoesTitulo = {
  valor: number
  quantidade: number
}

export interface TitulosParceirosAbertos extends TituloGeralAbertos {
  parceiroId: number
  parceiroNome: string
  logoParceiroUrl: string
  porcentagem: number
}

export interface TituloGeralAbertos {
  aberto: InformacoesTitulo
  emDia: InformacoesTitulo
  vencido: InformacoesTitulo
  urlRelatorio: string
  processamentoRelatorio: Processamento
}

export type ValorGeralTitulosAbertos = {
  resumoGeral: TituloGeralAbertos
  resumoParceiros: TitulosParceirosAbertos[]
  processamento: Processamento
  createdAt: string
}

export type ResumoParceiro = {
  parceiroId: number
  parceiroNome: string
  logoParceiroUrl: string
  porcentagem: number
}
export interface TituloGeralLiquidados {
  liquidados: InformacoesTitulo
  baixaParcial: InformacoesTitulo
  baixaTotal: InformacoesTitulo
  urlRelatorio: string
  processamentoRelatorio: Processamento
}
export interface TitulosParceirosLiquidados extends TituloGeralLiquidados {
  parceiroResumo: ResumoParceiro
}

export type Semana = {
  numero: number
  dataInicio: string
  dataFim: string
  liquidados: InformacoesTitulo
}

export type ValorLiquidados = {
  resumoGeral: TituloGeralLiquidados
  resumoParceiros: TitulosParceirosLiquidados[]
  processamento: Processamento
  semanas: Semana[]
}

export interface TituloGeradosResumoGeral {
  titulos: InformacoesTitulo
  urlRelatorio: string
  processamentoRelatorio: Processamento
}
export interface TitulosParceirosGerados extends TituloGeradosResumoGeral {
  parceiroResumo: ResumoParceiro
}

export type Dias = {
  numero: number
  dataReferencia: string
  titulos: InformacoesTitulo
}

export type ValorGerados = {
  resumoGeral: TituloGeradosResumoGeral
  resumoParceiros: TitulosParceirosGerados[]
  processamento: Processamento
  dias: Dias[]
}

export type BuscarTitulosForm = {
  dataPeriodo: string
  dataComparacao: string
  titulos: string
}

export class CardTitulos {
  informacoes: TituloCardInformacao[] = []
  urlRelatorio = ''
  processamentoRelatorio: Processamento = { status: StatusProcessamento.NOVO }

  static fromAberto(titulosGeral: TituloGeralAbertos) {
    const { aberto, emDia, vencido, urlRelatorio, processamentoRelatorio } = titulosGeral
    const tituloCard = new CardTitulos()
    tituloCard.informacoes = [
      new TituloCardInformacao(aberto, 'Em aberto'),
      new TituloCardInformacao(emDia, 'Em dia'),
      new TituloCardInformacao(vencido, 'Vencidos')
    ]
    tituloCard.urlRelatorio = urlRelatorio
    tituloCard.processamentoRelatorio = processamentoRelatorio
    return tituloCard
  }
  static fromLiquidados(tituloLiquidado: TituloGeralLiquidados) {
    const { baixaParcial, baixaTotal, liquidados, urlRelatorio, processamentoRelatorio } = tituloLiquidado
    const tituloCard = new CardTitulos()
    tituloCard.informacoes = [
      new TituloCardInformacao(liquidados, 'Títulos liquidados'),
      new TituloCardInformacao(baixaParcial, 'Baixa Parcial'),
      new TituloCardInformacao(baixaTotal, 'Baixa Total')
    ]
    tituloCard.urlRelatorio = urlRelatorio
    tituloCard.processamentoRelatorio = processamentoRelatorio
    return tituloCard
  }
  static fromGerados(tituloGerados: TituloGeradosResumoGeral) {
    const { titulos, urlRelatorio, processamentoRelatorio } = tituloGerados
    const tituloCard = new CardTitulos()
    tituloCard.informacoes = [new TituloCardInformacao(titulos, 'Títulos gerados')]
    tituloCard.urlRelatorio = urlRelatorio
    tituloCard.processamentoRelatorio = processamentoRelatorio
    return tituloCard
  }
}

export class CardTitulosParceiro extends CardTitulos {
  parceiroId = 0
  parceiroNome = ''
  logoParceiroUrl = ''
  porcentagem = 0

  static fromAberto(titulosParceiro: TitulosParceirosAbertos) {
    const { parceiroId, parceiroNome, logoParceiroUrl, porcentagem } = titulosParceiro
    const tituloCard = super.fromAberto(titulosParceiro) as CardTitulosParceiro

    tituloCard.parceiroNome = parceiroNome
    tituloCard.logoParceiroUrl = logoParceiroUrl
    tituloCard.porcentagem = porcentagem
    tituloCard.parceiroId = parceiroId
    return tituloCard
  }
  static fromLiquidados(tituloLiquidado: TitulosParceirosLiquidados) {
    const { parceiroResumo: resumoParceiro } = tituloLiquidado

    const tituloCard = super.fromLiquidados(tituloLiquidado) as CardTitulosParceiro
    tituloCard.parceiroNome = resumoParceiro.parceiroNome
    tituloCard.logoParceiroUrl = resumoParceiro.logoParceiroUrl
    tituloCard.porcentagem = resumoParceiro.porcentagem
    tituloCard.parceiroId = resumoParceiro.parceiroId
    return tituloCard
  }
  static fromGerados(tituloGerado: TitulosParceirosGerados) {
    const { parceiroResumo: resumoParceiro } = tituloGerado

    const tituloCard = super.fromGerados(tituloGerado) as CardTitulosParceiro
    tituloCard.parceiroNome = resumoParceiro.parceiroNome
    tituloCard.logoParceiroUrl = resumoParceiro.logoParceiroUrl
    tituloCard.porcentagem = resumoParceiro.porcentagem
    tituloCard.parceiroId = resumoParceiro.parceiroId
    return tituloCard
  }
}

class TituloCardInformacao {
  valor: number
  quantidade: number
  nome: string
  constructor(titulosParceiro: InformacoesTitulo, nome: string) {
    const { quantidade, valor } = titulosParceiro
    this.quantidade = quantidade
    this.valor = valor
    this.nome = nome
  }
}
