import { TableCell } from '@material-ui/core'
import { IOProps } from 'react-compose-io'
import { Tabela } from '../../../../components/Tabela'
import { Contabilizacao } from '../../../../types/conciliacaoApiTypes'
import { cabecalhoTabelaContabilizacao } from '../../../../types/tabelaTypes'
import { formateCurrency } from '../../../../utils/stringUtils'
import { TabelaContabilizacaoIO } from './TabelaContabilizacao.IO'

export type TabelaContabilizacaoProps = {
  cicloId: string
  buscarContabilizacoes: () => void
  totalRows: number
  contabilizacaoList?: Contabilizacao[]
}

export function TabelaContabilizacaoView({ _io, contabilizacaoList }: IOProps<TabelaContabilizacaoIO, TabelaContabilizacaoProps>) {
  const { Container, TableRowBody } = _io.styles

  function linhaTabela(props: { item: Contabilizacao }) {
    const { item } = props

    return (
      <TableRowBody hover onClick={(event) => _io.handlePushRotaTabelaContabilizacao(event, item.tipoLancamento, item.sequenciaId)} tabIndex={-1}>
        <TableCell>{item.tipoLancamento}</TableCell>
        <TableCell>{item.sequencia}</TableCell>
        <TableCell>{item.conta}</TableCell>
        <TableCell>{item.natureza}</TableCell>
        <TableCell>{formateCurrency(item.valor)}</TableCell>
      </TableRowBody>
    )
  }

  return (
    <>
      {contabilizacaoList != null && contabilizacaoList != undefined && (
        <Container>
          <Tabela
            cabecalho={cabecalhoTabelaContabilizacao}
            itensLista={contabilizacaoList}
            LinhaTabela={linhaTabela}
            linhasPorPaginaPadrao={5}
            orderCampoPadrao="tipoLancamento"
            rowsPerPageOptions={[5, 10, 25]}
            alinharTextoCentro={true}
          ></Tabela>
        </Container>
      )}
    </>
  )
}
