import { ParceiroResumo } from './genericoType'

export type BuscarPedidoForm = {
  pedidoId: string
  contaContabil: string
  tipoLancamento: string
}

export type ResumoPedidosInternalizacao = {
  pedido: number
  parceiroResumo: ParceiroResumo
  clienteId: number
  valor: number
  comissao: number
  parcelas: number
  parcelasPagas: number
  detalhes: DetalhesPedidoInternalizado[]
}

export type DetalhesPedidoInternalizado = {
  cicloId: number
  dataProcessamento: string
  clienteId: number
  pedidoPlanilhaId: string
  pedidoId: number
  lancamentoParceiro: string
  novoTipoLancamentoParceiro: string
  valor: number
  parcela: number
  tipoLancamentoEfacil: string
  pedidoPlanilhaSku: string
  statusLancamento: StatusItemLancamento
}

export type DetalhesPedidoMovimentoContabil = {
  pedidoPlanilhaId: string
  pedidoId: number
  cicloId: number
  dataProcessamento: string
  tipoLancamentoEfacil: string
  valor: number
  numeroSequencia: number
  parcela: number
  sequenciaContabil: string
  statusLancamento: StatusItemLancamento
}

export enum StatusItemLancamento {
  NORMAL = 0,
  REGULARIZACAO_AUTOMATICA = 1,
  OP = 2,
  ANULADO = 3,
  REGULARIZACAO_MANUAL = 4
}
