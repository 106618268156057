import { Grid, List, ListItem, ListItemText, Typography } from '@material-ui/core'
import { IOProps } from 'react-compose-io'
import { ResumoItem } from '../../components/ResumoItem/index'
import { ResumoConciliacaoIO } from './ResumoConciliacao.IO'
import { customTheme } from './../../../../styles/customTheme'
import { Tipografia } from './../../../../components/Tipografia/index'

export type ResumoConciliacaoProps = {
  cicloId: string
}

export function ResumoConciliacaoView({ _io }: IOProps<ResumoConciliacaoIO, ResumoConciliacaoProps>) {
  const { ContainerListaResumo, ContainerGrid, Container, HeaderCabecalho, ListValue, DividerList, VerticalDivider, TextCabecalho } = _io.styles

  return (
    <Container>
      <HeaderCabecalho>
        <Tipografia variant="h6">Resumo da Conciliação Financeira</Tipografia>
      </HeaderCabecalho>
      {_io.listaResumoConciliacaoFinanceira.map((listaResumoConciliacao, listaResumoConciliacaoIndex) => (
        <List key={listaResumoConciliacaoIndex}>
          <ListItem>
            <ContainerListaResumo container>
              <ContainerListaResumo item xs={6}>
                <ListValue primary={listaResumoConciliacao.primeiroTituloLinha} />
                <ListItemText>
                  <Typography variant="h6">{listaResumoConciliacao.primeiroValorLinha}</Typography>
                </ListItemText>
              </ContainerListaResumo>
              <ContainerListaResumo item xs={6}>
                <ListValue primary={listaResumoConciliacao.segundoTituloLinha} />
                <ListItemText>
                  <Typography variant="h6">{listaResumoConciliacao.segundoValorLinha}</Typography>
                </ListItemText>
              </ContainerListaResumo>
            </ContainerListaResumo>
          </ListItem>
          <DividerList />
        </List>
      ))}
      <HeaderCabecalho>
        <TextCabecalho fontSize={customTheme.fontSize[18]} fontWeight="500" colorText={customTheme.colors.grey23}>
          Detalhamento
        </TextCabecalho>
      </HeaderCabecalho>

      <ContainerGrid container>
        <Grid item xs={5}>
          <Grid container item spacing={1}>
            <ResumoItem label="Títulos liquidados" value={_io.resumo.titulosLiquidados} />
            <ResumoItem label="Crédito não identificados" value={_io.resumo.creditoNaoIdentificado} />
            <ResumoItem label="Comissões débito" value={_io.resumo.comissoesDebito} />
          </Grid>
          <Grid container item spacing={1}>
            <ResumoItem label="Juros recebidos" value={_io.resumo.jurosRecebidos} />
            <ResumoItem label="Desconto concedido" value={_io.resumo.descontoConcedido} />
          </Grid>
        </Grid>
        <VerticalDivider orientation="vertical" flexItem />
        <Grid item xs={5}>
          <Grid container item spacing={1}>
            <ResumoItem label="Comissões crédito" value={_io.resumo.comissoesCredito} />
            <ResumoItem label="Devoluções" value={_io.resumo.devolucoes} />
            <ResumoItem label="Imposto de renda" value={_io.resumo.impostoDeRenda} />
          </Grid>
        </Grid>
      </ContainerGrid>
    </Container>
  )
}
