import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import msgConstants from '../../constants/msgConstants'
import PedidosManager from '../../services/api/pedidos/pedidosManager'
import TipoLancamentoManager from '../../services/api/tipoLancamento/tipoLancamentoManager'
import { loadingReset, loadingUpdate } from '../../store/actions/loadingAction'
import { BuscarPedidoForm, DetalhesPedidoMovimentoContabil, ResumoPedidosInternalizacao } from '../../types/pedidosTypes'
import { Sequencias, TiposLancamentosData } from '../../types/tipoLancamentoApiTypes'
import { pedidosStyles } from './Pedidos.style'

export function pedidosIO() {
  const dispatch = useDispatch()
  const [sequencias, setSequencias] = useState<Sequencias[]>([])
  const [tiposLancamentosEFacil, setTiposLancamentosEFacil] = useState<TiposLancamentosData[]>([])
  const [pedidoInternalizado, setPedidoInternalizado] = useState<ResumoPedidosInternalizacao | null>()
  const [pedidoMovimentoContabil, setPedidoMovimentoContabil] = useState<DetalhesPedidoMovimentoContabil[] | null>()
  const { handleSubmit, control, setValue } = useForm<BuscarPedidoForm>({
    defaultValues: {
      pedidoId: '',
      contaContabil: '',
      tipoLancamento: ''
    }
  })

  const buscarPedido = async (data: BuscarPedidoForm) => {
    dispatch(loadingUpdate(true))
    await PedidosManager.buscarPedidoInternalizado(data.pedidoId)
      .then((pedidoInternalizadoResponse: ResumoPedidosInternalizacao) => {
        if (data.tipoLancamento != '') {
          pedidoInternalizadoResponse.detalhes = pedidoInternalizadoResponse.detalhes.filter((item) => item.tipoLancamentoEfacil === data.tipoLancamento)
        }
        setPedidoInternalizado(pedidoInternalizadoResponse)
      })
      .catch(() => {
        toast.error(msgConstants.USO_GENERICO.erroBusca)
      })
    await PedidosManager.buscarPedidoMovimentoContabil(data.pedidoId)
      .then((pedidoMovimentoContabilResponse: DetalhesPedidoMovimentoContabil[]) => {
        if (data.contaContabil != '') {
          pedidoMovimentoContabilResponse = pedidoMovimentoContabilResponse.filter((item) => item.numeroSequencia === parseInt(data.contaContabil))
        }
        if (data.tipoLancamento != '') {
          pedidoMovimentoContabilResponse = pedidoMovimentoContabilResponse.filter((item) => item.tipoLancamentoEfacil === data.tipoLancamento)
        }
        setPedidoMovimentoContabil(pedidoMovimentoContabilResponse)
      })
      .catch(() => {
        toast.error(msgConstants.USO_GENERICO.erroBusca)
      })
    dispatch(loadingReset())
  }

  const limparFormulario = () => {
    setPedidoInternalizado(null)
    setPedidoMovimentoContabil(null)
    setValue('contaContabil', '')
    setValue('tipoLancamento', '')
    setValue('pedidoId', '')
  }

  useEffect(() => {
    async function fetchData() {
      const sequenciasResponse: Sequencias[] = await TipoLancamentoManager.buscarSequencias()
      const tiposlancamentosEfacilResponse: TiposLancamentosData[] = await TipoLancamentoManager.buscarTiposLancamentos()
      setSequencias(sequenciasResponse)
      setTiposLancamentosEFacil(tiposlancamentosEfacilResponse)
      dispatch(loadingReset())
    }

    dispatch(loadingUpdate(true))
    fetchData()
  }, [dispatch])

  return {
    styles: pedidosStyles,
    handleSubmit,
    control,
    buscarPedido,
    sequencias,
    tiposLancamentosEFacil,
    limparFormulario,
    pedidoInternalizado,
    pedidoMovimentoContabil
  }
}

export type PedidosIO = ReturnType<typeof pedidosIO>
