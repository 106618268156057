import { Grid, InputLabel, MenuItem, Select } from '@material-ui/core'
import { IOProps } from 'react-compose-io'
import { Controller } from 'react-hook-form'
import { TRANSFORMACAO_LABELS_ACAO } from '../../../constants/transformacao'
import { FormFieldsProps } from '../../../types/regraApiType'
import { FieldsTipoParametro } from '../FieldsTipoParametro'
import { FieldsBuscarLinhaIO } from './FieldsBuscarLinha.IO'

export type FieldsBuscarLinhaProps = {
  indexItensRegraForm: number
  itensCampo: FormFieldsProps[]
  cabecalhos: string[]
}

export default function FieldsBuscarLinhaView({ _io, indexItensRegraForm, itensCampo, cabecalhos }: IOProps<FieldsBuscarLinhaIO, FieldsBuscarLinhaProps>) {
  const { FormControl, FormGroup } = _io.styles

  return (
    <>
      {_io.acaoSelecionada[indexItensRegraForm]?.operacaoId === 'BuscarLinhaOperacao' && (
        <>
          <Grid item sm={12} md={2}>
            <FormGroup>
              <Controller
                render={({ field }) => (
                  <FormControl>
                    <InputLabel>{TRANSFORMACAO_LABELS_ACAO.dado}</InputLabel>
                    <Select {...field} label={TRANSFORMACAO_LABELS_ACAO.dado}>
                      {cabecalhos.map((cabecalho: string, cabecalhoIndex: number) => (
                        <MenuItem key={cabecalhoIndex} value={cabecalho}>
                          {cabecalho}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
                name={`acoes.${indexItensRegraForm}.dado` as `acoes.0.dado`}
                control={_io.control}
              />
            </FormGroup>
          </Grid>
          <Grid item sm={12} md={2}>
            <FormGroup>
              <Controller
                render={({ field }) => (
                  <FormControl>
                    <InputLabel>Operadores</InputLabel>
                    <Select {...field} label="Operadores">
                      {itensCampo
                        .filter((item) => item.operacaoId === _io.acaoSelecionada[indexItensRegraForm]?.operacaoId)[0]
                        ?.operadores.map((item, index: number) => (
                          <MenuItem key={index} value={item}>
                            {item}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                )}
                name={`acoes.${indexItensRegraForm}.operador` as `acoes.0.operador`}
                control={_io.control}
              />
            </FormGroup>
          </Grid>
          <FieldsTipoParametro
            itensCampo={itensCampo}
            control={_io.control}
            cabecalhos={cabecalhos}
            indexItensRegraForm={indexItensRegraForm}
            itensRegraTransformacaoForm={_io.acaoSelecionada}
          />
        </>
      )}
    </>
  )
}
