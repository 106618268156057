import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect } from 'react'
import { DeepMap, FieldError, useForm } from 'react-hook-form'
import * as yup from 'yup'
import msgConstants from '../../../../constants/msgConstants'
import { RegularizacaoEfetivacaoCicloForm } from '../../../../types/regularizacaoOPsTitulosTypes'
import { modalRegularizacaoCiclosStyles } from './ModalRegularizacaoCiclos.styles'
import { ModalRegularizacaoCiclosProps } from './ModalRegularizacaoCiclos.View'

const schema = yup
  .object()
  .shape({
    sequenciaId: yup.number().when('acao', {
      is: 1,
      then: yup.number().min(1).required(msgConstants.USO_GENERICO.campo_obrigatorio)
    })
  })
  .required()

export function modalRegularizacaoCiclosIO({ valorTotalTitulosSelecionados, valorTotalOPsSelecionadas, sequencias, isShow }: ModalRegularizacaoCiclosProps) {
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    watch
  } = useForm<RegularizacaoEfetivacaoCicloForm>({
    resolver: yupResolver(schema),
    defaultValues: {
      sequenciaId: 0,
      acao: 0
    }
  })

  const acaoSelecionada = watch('acao')

  useEffect(() => {
    if (acaoSelecionada == 0) {
      setValue('sequenciaId', 0)
    }
  }, [acaoSelecionada])

  useEffect(() => {
    if (isShow) {
      setValue('acao', 0)
      setValue('sequenciaId', 0)
    }
  }, [isShow])

  const verificarErros = (handleErros?: DeepMap<RegularizacaoEfetivacaoCicloForm, FieldError>, erro?: FieldError) => {
    return handleErros != undefined && erro != undefined
  }

  const textModal = () => {
    if (valorTotalOPsSelecionadas > valorTotalTitulosSelecionados) return 'Valor da Ordem de Pagamento é MAIOR que o valor do Título. O que deseja fazer?'
    else return 'Valor da Ordem de Pagamento é MENOR que o valor do título. O que deseja fazer?'
  }

  const textAcao = () => {
    if (valorTotalOPsSelecionadas > valorTotalTitulosSelecionados) return 'Gerar uma nova Ordem de Pagamento'
    else return 'Deixar saldo como pendente'
  }

  const sequenciasFiltradas = () => {
    if (valorTotalOPsSelecionadas > valorTotalTitulosSelecionados) return sequencias.filter((x) => x.tipoNaturezaContabil == 'Credito')
    else return sequencias.filter((x) => x.tipoNaturezaContabil == 'Debito')
  }

  return {
    styles: modalRegularizacaoCiclosStyles,
    textModal: textModal(),
    textAcao: textAcao(),
    sequenciasFiltradas,
    verificarErros,
    handleSubmit,
    control,
    errors,
    acaoSelecionada
  }
}

export type ModalRegularizacaoCiclosIO = ReturnType<typeof modalRegularizacaoCiclosIO>
