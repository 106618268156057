import { Config, HistoricoPadrao, Sequencias, TipoLancamentoDataApi, TiposLancamentosData, TipoVariavel } from '../../../types/tipoLancamentoApiTypes'
import tipoLancamentoService from './tipoLancamentoService'

const buscarSequencias = async (tipoNaturezaContabil?: string): Promise<Sequencias[]> => tipoLancamentoService.buscarSequencias(tipoNaturezaContabil)

const buscarConfigAtiva = async (): Promise<Config> => tipoLancamentoService.buscarConfigAtiva()

const buscarTiposLancamentos = async (): Promise<TiposLancamentosData[]> => tipoLancamentoService.buscarTiposLancamentos()

const inserirTipoLancamento = async (body: TipoLancamentoDataApi): Promise<TiposLancamentosData> => tipoLancamentoService.inserirTipoLancamento(body)

const alterarTipoLancamento = async (body: TipoLancamentoDataApi, tipoLancamentoId?: number): Promise<void> =>
  tipoLancamentoService.alterarTipoLancamento(body, tipoLancamentoId)

const validarHistoricoPadrao = async (body: HistoricoPadrao, sequenciaId?: number): Promise<void> =>
  tipoLancamentoService.validarHistoricoPadrao(body, sequenciaId)

const salvarHistoricoPadrao = async (body: HistoricoPadrao | undefined, tipoLancamentoId?: number): Promise<void> =>
  tipoLancamentoService.salvarHistoricoPadrao(body, tipoLancamentoId)

const alterarHistoricoPadrao = async (body: HistoricoPadrao | undefined, tipoLancamentoId?: number): Promise<void> =>
  tipoLancamentoService.alterarHistoricoPadrao(body, tipoLancamentoId)

const obterHistoricoPadraoTipoLancamento = async (tipoLancamentoId?: number): Promise<HistoricoPadrao> =>
  tipoLancamentoService.obterHistoricoPadraoTipoLancamento(tipoLancamentoId)

const obterHistoricoPadraoSequencia = async (sequenciaId: number): Promise<HistoricoPadrao> => tipoLancamentoService.obterHistoricoPadraoSequencia(sequenciaId)

const obterTiposVariaveisHistoricoPadrao = async (): Promise<TipoVariavel[]> => tipoLancamentoService.obterTiposVariaveisHistoricoPadrao()

export default {
  buscarSequencias,
  buscarConfigAtiva,
  buscarTiposLancamentos,
  inserirTipoLancamento,
  alterarTipoLancamento,
  validarHistoricoPadrao,
  salvarHistoricoPadrao,
  alterarHistoricoPadrao,
  obterHistoricoPadraoTipoLancamento,
  obterHistoricoPadraoSequencia,
  obterTiposVariaveisHistoricoPadrao
}
