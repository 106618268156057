import {
  EfetivarOPsTitulosRequest,
  ObterOpsTituloPorPedidoResponse,
  RegularizacaoPendenteForm,
  ResponseRegularizacaoPendente,
  ValorCiclosPendentes
} from '../../../types/regularizacaoOPsTitulosTypes'
import { concatenarFiltrosQuery } from '../../../utils/stringUtils'
import api from '../../axiosInstance'

const buscarResumoRegularizacaoCiclos = async (dataInicio: string, dataFim: string): Promise<ValorCiclosPendentes> => {
  const URL_REGULARIZACAO_CICLOS_PENDENTES = `/RegularizacaoOPsTitulos/Resumo${concatenarFiltrosQuery({
    dataInicio: dataInicio,
    dataFim: dataFim
  })}`
  const response = await api.conectarGet<ValorCiclosPendentes>(URL_REGULARIZACAO_CICLOS_PENDENTES)
  return response.data
}

const buscarRegularizacoesPendentes = async (controleComissaoPaginadoRequest: RegularizacaoPendenteForm): Promise<ResponseRegularizacaoPendente> => {
  const URL_BUSCAR_PEDIDO = `/RegularizacaoOPsTitulos/Pendencias${concatenarFiltrosQuery(controleComissaoPaginadoRequest)}`
  const response = await api.conectarGet<ResponseRegularizacaoPendente>(URL_BUSCAR_PEDIDO)
  return response.data
}

const buscarEfetivarRegularizacaoCiclos = async (idPedido: string, clienteId?: string): Promise<ObterOpsTituloPorPedidoResponse> => {
  let URL_REGULARIZACAO_CICLOS_EFETIVADOS_OP_TITULOS = `/RegularizacaoOPsTitulos/Pedidos/${idPedido}`

  if (clienteId && clienteId != '0') {
    URL_REGULARIZACAO_CICLOS_EFETIVADOS_OP_TITULOS = URL_REGULARIZACAO_CICLOS_EFETIVADOS_OP_TITULOS + `?ClienteId=${clienteId}`
  }

  const response = await api.conectarGet<ObterOpsTituloPorPedidoResponse>(URL_REGULARIZACAO_CICLOS_EFETIVADOS_OP_TITULOS)
  return response.data
}

const efetivarRegularizacaoCiclos = async (dataFormulario: EfetivarOPsTitulosRequest): Promise<boolean> => {
  const URL_CICLOS_EFETIVADOS = `/RegularizacaoOPsTitulos/Efetivar`
  const response = await api.conectarPost<boolean>(URL_CICLOS_EFETIVADOS, dataFormulario)
  return response.data
}

export default {
  buscarResumoRegularizacaoCiclos,
  buscarRegularizacoesPendentes,
  buscarEfetivarRegularizacaoCiclos,
  efetivarRegularizacaoCiclos
}
