import { yupResolver } from '@hookform/resolvers/yup'
import { useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import msgConstants from '../../constants/msgConstants'
import { useConciliacaoContext } from '../../contexts/conciliacao-context'
import loginManager from '../../services/api/login/loginManager'
import { FormLoginType } from '../../types/loginTypes'
import { loginStyles } from './Login.style'

export function loginIO() {
  const { handleLogin } = useConciliacaoContext()
  const [mostrarSenha, setMostrarSenha] = useState(false)
  const loginSchema = Yup.object().shape({
    usuario: Yup.string().required('Preencha o Usuário'),
    senha: Yup.string().required('Preencha a Senha')
  })

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting }
  } = useForm({
    resolver: yupResolver(loginSchema),
    mode: 'onBlur',
    reValidateMode: 'onBlur'
  })

  const handlerAutenticar: SubmitHandler<FormLoginType> = async (data: FormLoginType) => {
    await loginManager
      .autenticacaoLogin(data)
      .then((response: string) => {
        handleLogin(response)
        toast.success(msgConstants.LOGIN.loginSucesso)
      })
      .catch((err) => {
        toast.error(err.message)
      })
  }

  return {
    styles: loginStyles,
    control,
    handleSubmit,
    errors,
    isSubmitting,
    handlerAutenticar,
    mostrarSenha,
    setMostrarSenha
  }
}

export type LoginIO = ReturnType<typeof loginIO>
