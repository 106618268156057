import { Box, TableCell, TableRow } from '@material-ui/core'
import styled from 'styled-components'
import { customTheme } from '../../../../styles/customTheme'

export const itemConfiguracoesGeraisStyles = {
  LinhaTabela: styled(TableRow)`
    border-bottom: unset;
    cursor: pointer;
  `,
  LinhaHistorico: styled(TableRow)`
    background-color: ${customTheme.colors.blue01};
  `,
  CelulaHistorico: styled(TableCell)`
    padding-bottom: 0px;
    padding-top: 0px;
  `,
  BoxAtivo: styled(Box)`
    padding: 8px;
    border-radius: 5px;
    background-color: ${customTheme.colors.green03};
    color: ${customTheme.colors.green05};
  `,
  BoxInativo: styled(Box)`
    padding: 8px;
    border-radius: 5px;
    background-color: ${customTheme.colors.red00};
    color: ${customTheme.colors.red06};
  `,
  BoxHistorico: styled(Box)`
    margin: 8px;
  `,
  BoxPadding: styled(Box)`
    padding: 8px;
  `
}
