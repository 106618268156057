import { legendaStyles } from './Legenda.style'

export interface LegendaProps {
  cor: string
  descricao: string
  tamanhoTexto?: 'subtitle1'
}

export function Legenda({ cor, descricao, tamanhoTexto }: LegendaProps) {
  const { ContainerTextoLegenda, CirculoLegenda, TextoLegenda } = legendaStyles

  return (
    <ContainerTextoLegenda>
      <CirculoLegenda cor={cor} />
      <TextoLegenda variant={tamanhoTexto ? tamanhoTexto : 'caption'} cor={cor}>
        {descricao}
      </TextoLegenda>
    </ContainerTextoLegenda>
  )
}
