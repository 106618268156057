import styled from 'styled-components'
import { ToastContainer } from 'react-toastify'

export const ToastStyled = styled(ToastContainer)`
  .Toastify__toast--info {
    background: 'rgb(51, 102, 255)';
  }
  .Toastify__toast--success {
    background: #dcfedc;
    color: #54ac5b;
  }
  .Toastify__toast--warning {
    background: #feffc8;
    color: #4e4b59;
  }
  .Toastify__toast--error {
    background: #fad7de;
    color: #ff0000;
  }
  .Toastify__progress-bar {
    background: #85838a;
  }
`
