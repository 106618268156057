import { Grid, IconButton, InputAdornment, TextField } from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import { IOProps } from 'react-compose-io'
import { Controller } from 'react-hook-form'
import Banner from '../../assets/images/login/eFacil-01.png'
import Logo from '../../assets/images/login/martins-logo.png'
import { Tipografia } from '../../components/Tipografia'
import { customTheme } from '../../styles/customTheme'
import { LoginIO } from './Login.IO'
import { Botao } from './../../components/Botao/index'

type LoginProps = {}

export function LoginView({ _io }: IOProps<LoginIO, LoginProps>) {
  const { ContainerForm, Img, GridContainer, GridHeader } = _io.styles

  return (
    <GridContainer container direction="column" alignItems="center" justifyContent="space-between">
      <GridHeader item container direction="column" alignContent="center" alignItems="center">
        <Img height={'67px'} width={'175px'} alt="eFacil" src={Banner} loading="lazy" />
        <Tipografia variant="body2" colorText={customTheme.colors.grey03}>
          Conciliação Financeira
        </Tipografia>
      </GridHeader>

      <Grid item>
        <ContainerForm>
          <Tipografia colorText={customTheme.colors.grey04} variant="h6">
            Seja bem vindo
          </Tipografia>
          <Tipografia variant="subtitle1" colorText={customTheme.colors.grey04}>
            Acesse inserindo suas credenciais abaixo
          </Tipografia>
          <br />
          <form>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Controller
                  render={({ field }) => (
                    <TextField
                      required
                      {...field}
                      label="Usuário"
                      variant="outlined"
                      size="small"
                      error={_io.errors?.usuario ? true : false}
                      helperText={_io.errors.usuario?.message}
                      fullWidth
                    />
                  )}
                  name="usuario"
                  control={_io.control}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  render={({ field }) => (
                    <TextField
                      required
                      {...field}
                      label="Senha"
                      variant="outlined"
                      size="small"
                      error={_io.errors?.senha ? true : false}
                      helperText={_io.errors.senha?.message}
                      fullWidth
                      type={_io.mostrarSenha ? 'text' : 'password'}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={() => _io.setMostrarSenha(!_io.mostrarSenha)} edge="end">
                              {_io.mostrarSenha ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                  )}
                  name="senha"
                  control={_io.control}
                />
              </Grid>
              <Grid item xs={12}>
                <Botao
                  textoBotao={'Entrar'}
                  onClick={_io.handleSubmit(_io.handlerAutenticar)}
                  cor="primaria"
                  estilo="contained"
                  width={'370px'}
                  tamanho="grande"
                />
              </Grid>
            </Grid>
          </form>
        </ContainerForm>
      </Grid>

      <Grid item container direction="column" alignContent="center" alignItems="center">
        <Tipografia variant="subtitle2" colorText={customTheme.colors.grey06} fontWeight="400">
          EFÁCIL É UMA EMPRESA DO GRUPO
        </Tipografia>
        <Img height={'90px'} width={'90px'} alt="eFacil" src={Logo} loading="lazy" />
      </Grid>
    </GridContainer>
  )
}
