import styled from 'styled-components'
import { customTheme } from '../../styles/customTheme'

export const checkListItemStyles = {
  ContainerTexto: styled.div`
    display: flex;
    align-items: center;
    color: ${customTheme.colors.cinza06};
  `
}
