import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { default as ciclosManager } from '../../services/api/ciclos/ciclosManager'
import { loadingReset, loadingUpdate } from '../../store/actions/loadingAction'
import { ParceiroCiclo } from '../../types/ciclosApiType'
import { tratarMudancaLinhaPorPagina, tratarMudancaPagina } from '../../utils/tabelaUtils'
import { ciclosStyles } from './Ciclos.styles'

export function ciclosIO() {
  const dispatch = useDispatch()
  const [linhasPorPagina, setLinhasPorPagina] = useState(5)
  const [pagina, setPagina] = useState(0)
  const [parceirosComCiclos, setParceirosComCiclos] = useState<ParceiroCiclo[]>([])
  const [parceirosComCiclosFiltrados, setParceirosComCiclosFiltrados] = useState<ParceiroCiclo[]>([])
  const [filtro, setFiltro] = useState('')

  const filtrarParceirosComCiclos = (nomeFiltrado: string) => {
    const ciclosFiltradosPorNome = parceirosComCiclos.filter((itemCiclo: ParceiroCiclo) => {
      return itemCiclo.nome.toLowerCase().includes(nomeFiltrado.toLowerCase()) || String(itemCiclo.parceiroId).includes(nomeFiltrado)
    })
    setParceirosComCiclosFiltrados(ciclosFiltradosPorNome)
  }

  useEffect(() => {
    filtrarParceirosComCiclos(filtro)
  }, [filtro, pagina, linhasPorPagina])

  useEffect(() => {
    async function buscarParceirosComCiclos() {
      const response: ParceiroCiclo[] = await ciclosManager.buscarParceirosComCiclos()
      setParceirosComCiclos(response)
      setParceirosComCiclosFiltrados(response)
      dispatch(loadingReset())
    }
    dispatch(loadingUpdate(true))
    buscarParceirosComCiclos()
  }, [dispatch])

  return {
    styles: ciclosStyles,
    parceirosComCiclosFiltrados,
    filtro,
    filtrarParceirosComCiclos,
    setFiltro,
    setPagina,
    pagina,
    linhasPorPagina,
    setLinhasPorPagina,
    rowsPerPageOptions: [5, 10, 25],
    onMudancaPagina: (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
      tratarMudancaPagina(event, newPage, setPagina)
    },
    onMudancaLinhasPorPagina: (event: React.ChangeEvent<HTMLInputElement>) => {
      tratarMudancaLinhaPorPagina(event, setPagina, setLinhasPorPagina)
    },
    totalLinhas: parceirosComCiclosFiltrados.length
  }
}

export type CiclosIO = ReturnType<typeof ciclosIO>
