import { Grid, CircularProgress } from '@material-ui/core'
import { IOProps } from 'react-compose-io'
import { ModalChamadosIO } from './ModalChamados.IO'
import { customTheme } from '../../../../styles/customTheme'
import { AdicionarChamadoForm } from '../AdicionarChamadoForm'
import { HistoricoChamado } from '../HistoricoChamado'
import { ControleComissaoChamados } from '../../../../types/controleComissaoTypes'
import { Tipografia } from '../../../../components/Tipografia'
import { Botao } from './../../../../components/Botao/index'

export type ModalChamadosProps = {
  isModalAberto: boolean
  setAbrirModal: (value: boolean | ((prevVar: boolean) => boolean)) => void
  chamados: ControleComissaoChamados[]
  isCarregandoChamados: boolean
  buscarChamados: () => Promise<void>
  controleComissaoCicloId: string
  atualizarListaControleComissao: () => void
}

export default function ModalChamadosView({
  _io,
  isModalAberto,
  setAbrirModal,
  chamados,
  isCarregandoChamados,
  buscarChamados,
  controleComissaoCicloId,
  atualizarListaControleComissao
}: IOProps<ModalChamadosIO, ModalChamadosProps>) {
  const { Dialog, DialogActions, DialogTitle, AddCircleRounded, DialogContent, AddCircleRoundedDisabled } = _io.styles

  const AdicionarButton = (
    <>
      {_io.isEditandoChamado ? (
        <>
          <Grid item>
            <Tipografia colorText={customTheme.colors.grey02}>Editar</Tipografia>
          </Grid>
          <Grid item>
            <AddCircleRoundedDisabled />
          </Grid>
        </>
      ) : (
        <AddCircleRounded onClick={() => _io.setMostrarAdicionarChamadoForm(true)} />
      )}
    </>
  )

  return (
    <Dialog open={isModalAberto} keepMounted onClose={() => setAbrirModal(false)} maxWidth={'xl'}>
      <DialogTitle>Controle de Chamados</DialogTitle>

      <DialogContent>
        {_io.mostrarAdicionarChamadoForm ? (
          <AdicionarChamadoForm
            setMostrarAdicionarChamadoForm={_io.setMostrarAdicionarChamadoForm}
            buscarChamados={buscarChamados}
            controleComissaoCicloId={controleComissaoCicloId}
            isPrimeiroChamado={chamados.length === 0}
            atualizarListaControleComissao={atualizarListaControleComissao}
          />
        ) : (
          <Grid container direction="column" justifyContent="center" alignItems="center" spacing={0}>
            {AdicionarButton}
          </Grid>
        )}

        {isCarregandoChamados ? (
          <Grid container justifyContent="center">
            <CircularProgress />
          </Grid>
        ) : (
          chamados.map((chamado) => (
            <HistoricoChamado
              key={chamado.id}
              chamado={chamado}
              isAdicionandoChamado={_io.mostrarAdicionarChamadoForm}
              setIsEditandoChamado={_io.setIsEditandoChamado}
              buscarChamados={buscarChamados}
            />
          ))
        )}
      </DialogContent>

      <DialogActions>
        <Botao onClick={() => setAbrirModal(false)} textoBotao={'Sair'} cor="primaria" estilo="rounded" width="200px" />
      </DialogActions>
    </Dialog>
  )
}
