import styled from 'styled-components'
import { customTheme } from '../../../../styles/customTheme'

export const modalConfirmarStyles = {
  Container: styled.div`
    display: flex;
    justify-content: center;
    padding: 8px 16px;
    gap: 8px;
  `,
  TextoSpan: styled.span`
    font-weight: 400;
    font-size: ${customTheme.fontSize[16]};
    padding: 12px;
    background-color: ${customTheme.colors.blue01};
  `
}
