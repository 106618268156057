import { Typography, Chip, TableCell, Grid, MobileStepper, Paper, Select } from '@material-ui/core'
import styled from 'styled-components'
import { customTheme } from '../../../../styles/customTheme'

export const modalPriorizarStyles = {
  Label: styled(Typography)`
    margin-bottom: 8px;
    text-align: center;
  `,
  Chip: styled(Chip)`
    background: ${customTheme.colors.blue01};
    color: ${customTheme.colors.primary};
    margin-right: 4px;
    font-size: ${customTheme.fontSize[12]};
    border-radius: 24px;
  `,
  TableCell: styled(TableCell)`
    padding: 8px 16px;
  `,
  GridContainer: styled(Grid)`
    text-align: center;
    margin-top: 8px;
    max-width: 300px;
  `,
  Paper: styled(Paper)`
    width: 1000px;
  `,
  Select: styled(Select)`
    .MuiOutlinedInput-inputMarginDense {
      padding-top: 10px;
      padding-bottom: 10px;
      padding-left: 10px;
    }
  `,
  MobileStepperView: styled(MobileStepper)`
    .MuiMobileStepper-progress {
      background-color: ${customTheme.colors.white01};
      height: 10px;
      border-radius: 10px;
      border: 1px solid ${customTheme.colors.grey02};

      .MuiLinearProgress-barColorPrimary {
        background-color: ${customTheme.colors.azul06};
      }
    }
  `
}
