import { IOProps } from 'react-compose-io'
import { SemOcorrenciaBoxIO } from './SemOcorrenciaBox.IO'
import AnaliseIcon from '../../../../assets/icons/analiseIcone.svg'
import PerdaIcon from '../../../../assets/icons/perdaIcone.svg'
import CifraoIcone from '../../../../assets/icons/cifrao.svg'
import CuboIcone from '../../../../assets/icons/cubo.svg'
import { CabecalhoExportarRelatorio } from '../../../../components/CabecalhoExportarRelatorio'
import { Grid } from '@material-ui/core'
import { TooltipComponent } from './../../../../components/Tooltip/Tooltip'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import { BoxValor } from './../../../../components/BoxValor/index'
import { formateCurrency } from '../../../../utils/stringUtils'
import { GraficoBarras } from './../../../../components/GraficoBarras/index'
import { ResumoDebitosGeralResponse, ResumoDebitosParceiroResponse } from '../../../../types/debitosTypes'
import { valoresGrafico, legendaMesGrafico, corBarra } from '../../../../utils/debitoGraficos'
import { TIPO_VALOR_GRAFICO, VALOR_ESCALA_GRAFICO } from '../../../../constants/utilConstants'
import { Botao } from './../../../../components/Botao/index'

export type SemOcorrenciaBoxProps = {
  urlRelatorio: string
  perda: ResumoDebitosParceiroResponse | ResumoDebitosGeralResponse
  emAnalise: ResumoDebitosParceiroResponse | ResumoDebitosGeralResponse
  parceiroId: number
}

export function SemOcorrenciaBoxView({ _io, perda, emAnalise, urlRelatorio }: IOProps<SemOcorrenciaBoxIO, SemOcorrenciaBoxProps>) {
  const { Box, Titulo, Divider } = _io.styles

  return (
    <Box>
      <CabecalhoExportarRelatorio
        url={urlRelatorio}
        tituloCabecalho={'Sem Ocorrências (SAC/0800)'}
        textoTooltip={`O painel Sem Ocorrência indica débitos efetuados pelos parceiros referente a pedidos que não possuem ocorrências.`}
      />

      <Grid container justifyContent="space-evenly" spacing={1}>
        <Grid item container direction="column" alignItems="center" spacing={2} sm={12} md={6}>
          <Grid item>
            <img src={AnaliseIcon} alt="" />
          </Grid>
          <Grid item>
            <Titulo>
              Em análise
              <TooltipComponent
                texto={`Pedidos que precisam de tratativa das áreas de Atendimento ou Logística (SAC/0800).`}
                Icone={HelpOutlineIcon}
                color={'action'}
              />
            </Titulo>
          </Grid>
          <Grid item>
            <BoxValor icone={CifraoIcone} descricao={'Valor total em análise'} valor={formateCurrency(emAnalise.valor)}></BoxValor>
          </Grid>
          <Grid item>
            <BoxValor icone={CuboIcone} descricao={'Quantidade total de pedidos em análise '} valor={emAnalise.quantidade.toLocaleString('pt-BR')} />
          </Grid>
          <Grid item>
            <GraficoBarras
              tituloGrafico={'Valor mensal sem ocorrência -  em análise'}
              dadosColunas={valoresGrafico(emAnalise.valoresMensais)}
              descricaoColunas={legendaMesGrafico(emAnalise.valoresMensais)}
              legendaEixoY={'Milhares'}
              valorDivididoLegenda={VALOR_ESCALA_GRAFICO.MIL}
              width="400"
              corBarra={corBarra}
              tipoValor={TIPO_VALOR_GRAFICO.MOEDA}
            />
          </Grid>
          <Grid item>
            <Botao
              cor="primaria"
              estilo="contained"
              type="submit"
              textoBotao="Ver detalhes dos pedidos"
              onClick={_io.onClickDetalhesPedidosBotaoSemOcorrencia}
              width="360px"
              tamanho="grande"
            />
          </Grid>
        </Grid>
        <Grid item sm={false} md={'auto'}>
          <Divider orientation="vertical" />
        </Grid>
        <Grid item container direction="column" alignItems="center" spacing={2} sm={12} md={6}>
          <Grid item>
            <img src={PerdaIcon} alt="" />
          </Grid>
          <Grid item>
            <Titulo>
              Perda
              <TooltipComponent
                texto={`Pedidos que foram analisados pelas áreas de Logística ou Atendimento e considerados como perda.`}
                Icone={HelpOutlineIcon}
                color={'action'}
              />
            </Titulo>
          </Grid>
          <Grid item>
            <BoxValor icone={CifraoIcone} descricao={'Valor total de perda'} valor={formateCurrency(perda.valor)}></BoxValor>
          </Grid>
          <Grid item>
            <BoxValor
              descricaoWidth="260px"
              icone={CuboIcone}
              descricao={'Quantidade total de pedidos considerados perda'}
              valor={perda.quantidade.toLocaleString('pt-BR')}
            />
          </Grid>
          <Grid item>
            <GraficoBarras
              tituloGrafico={'Valor mensal sem ocorrência - perda'}
              dadosColunas={valoresGrafico(perda.valoresMensais)}
              descricaoColunas={legendaMesGrafico(perda.valoresMensais)}
              legendaEixoY={'Milhares'}
              valorDivididoLegenda={VALOR_ESCALA_GRAFICO.MIL}
              width="400"
              corBarra={corBarra}
              tipoValor={TIPO_VALOR_GRAFICO.MOEDA}
            />
          </Grid>
          <Grid item>
            <Botao
              cor="primaria"
              estilo="contained"
              type="submit"
              textoBotao="Ver detalhes dos pedidos"
              onClick={_io.onClickDetalhesPedidosBotaoPerdas}
              width="360px"
              tamanho="grande"
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}
