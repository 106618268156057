import { useFormContext, useWatch } from 'react-hook-form'
import { stylesFieldForms } from '../styles'

export function fieldsTipoLancamentoIO() {
  const { control } = useFormContext()

  const acaoSelecionada = useWatch({
    name: 'acoes',
    control
  })

  return {
    styles: stylesFieldForms,
    control,
    acaoSelecionada
  }
}
export type FieldsTipoLancamentoIO = ReturnType<typeof fieldsTipoLancamentoIO>
