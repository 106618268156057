import { Grid } from '@material-ui/core'
import { IOProps } from 'react-compose-io'
import { cabecalhoGruposRegras } from '../../../../types/tabelaTypes'
import { AbaTabela } from '../AbaTabela'
import { ModalGrupoRegra } from '../ModalGrupoRegra'
import { TabGrupoRegraIO } from './TabGrupoRegra.IO'
import { Botao } from './../../../../components/Botao/index'

export type TabGrupoRegraProps = {}

export default function TabGrupoRegraView({ _io }: IOProps<TabGrupoRegraIO, TabGrupoRegraProps>) {
  const { Container } = _io.styles

  return (
    <>
      <Container>
        <Grid container justifyContent="flex-end" spacing={2}>
          <Grid item>
            <Botao textoBotao="Adicionar" cor="primaria" estilo="contained" onClick={_io.abrirModal} />
          </Grid>
        </Grid>
        <AbaTabela orderId={'grupoId'} listItem={_io.gruposregrasList} headCells={cabecalhoGruposRegras} LinhaTabela={_io.linhaTabela}></AbaTabela>
      </Container>
      <ModalGrupoRegra atualizarDados={_io.atualizarDados} isShow={_io.modalAberto} isClosed={_io.fecharModal} />
    </>
  )
}
