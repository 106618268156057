import { Typography } from '@material-ui/core'
import styled from 'styled-components'

export const informacoesIniciaisPedidoStyles = {
  ContainerColuna: styled.div`
    display: flex;
    flex-direction: column;
    margin-right: 42px;
  `,
  TextoDescritivo: styled(Typography).attrs({
    variant: 'subtitle1'
  })`
    font-style: normal;
    font-weight: 500;
    line-height: 23px;
  `,
  ContainerLinha: styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 16px;
  `,
  SubtituloDescritivo: styled(Typography).attrs({
    variant: 'subtitle1'
  })`
    font-style: normal;
    font-weight: 400;
    margin-top: 10px;
  `
}
