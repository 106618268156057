import { useCallback, useEffect, useState } from 'react'
import { controleInadimplenciasStyles } from './ControleInadimplencias.styles'
import controleInadimplenciasManager from '../../services/api/controleInadimplencia/controleInadimplenciaManager'
import { toast } from 'react-toastify'
import msgConstants from '../../constants/msgConstants'
import { useHistory } from 'react-router-dom'
import { StatusProcessamento } from '../../types/genericoType'
import { ControleInadimplenciasResponse, INADIMPLENCIA, Relatorio } from '../../types/controleInadimplenciaTypes'
import { DadosMeses, unirInadimplencias } from '../../utils/tratarDadosMesesUtils'
import { gerarDadosGrafico } from './../../utils/tratarDadosMesesUtils'

export function controleInadimplenciasIO() {
  const [controleInadimplenciasResponse, setControleInadimplenciasResponse] = useState<ControleInadimplenciasResponse>({} as ControleInadimplenciasResponse)
  const [parceiroSelecionado, setParceiroSelecionado] = useState(0)
  const [mesesSelecionado, setMesesSelecionado] = useState<3 | 6>(6)
  const [valorSetTimeOut, setValorSetTimeOut] = useState<number>(0)
  const [carregando, setCarregando] = useState(false)
  const history = useHistory()

  useEffect(() => {
    obterControleInadimplencias()
  }, [])

  useEffect(() => {
    return history.listen(() => {
      clearTimeout(valorSetTimeOut)
    })
  }, [valorSetTimeOut])

  const obterControleInadimplencias = useCallback(async () => {
    setCarregando(true)
    await controleInadimplenciasManager
      .buscarControleInadimplencias()
      .then((response: ControleInadimplenciasResponse) => {
        const status = response.processamento.status
        setControleInadimplenciasResponse(response)

        if (status === StatusProcessamento.PROCESSANDO) {
          const atualizarStatus = setTimeout(() => {
            obterControleInadimplencias()
          }, 10000)
          setValorSetTimeOut(Number(atualizarStatus))
        } else {
          clearTimeout(valorSetTimeOut)
          setCarregando(false)
        }

        if (status === StatusProcessamento.NOVO) {
          setCarregando(false)
          toast.error(msgConstants.REGULARIZACAO.erroAoBuscarPendencias)
        }
      })
      .catch(() => {
        setCarregando(false)
        toast.error(msgConstants.USO_GENERICO.erroBusca)
      })
  }, [controleInadimplenciasResponse])

  function gerarDadosControleInadimplencias() {
    setCarregando(true)
    controleInadimplenciasManager
      .gerarDadosControleInadimplencias()
      .then(() => {
        obterControleInadimplencias()
      })
      .catch((e: Error) => {
        toast.error(e.message)
      })
  }

  function handleFecharChip() {
    setParceiroSelecionado(0)
  }

  function filtrarInadimplencias(tipo: string) {
    const inadimplencias = controleInadimplenciasResponse?.dadosParceiros?.filter((x) => x.tipo == tipo)
    let inadimplenciaMeses: DadosMeses[] = []

    if (parceiroSelecionado !== 0) {
      inadimplenciaMeses = inadimplencias?.filter((x) => x.parceiroId === parceiroSelecionado)[0].dados
    } else {
      inadimplenciaMeses = unirInadimplencias(inadimplencias)
    }
    return inadimplenciaMeses
  }

  function filtrarInadimplenciasRelatorio(): Relatorio[] {
    const inadimplencias = controleInadimplenciasResponse

    if (parceiroSelecionado !== 0) {
      const inadimplenciasParceiro = inadimplencias?.dadosParceiros.filter((x) => x.parceiroId === parceiroSelecionado)
      const relatoriosInadimplenciasParceiro: Relatorio[] = []

      inadimplenciasParceiro.forEach((x) => relatoriosInadimplenciasParceiro.push({ tipo: x.tipo, urlRelatorio: x.urlRelatorio }))

      return relatoriosInadimplenciasParceiro
    } else {
      return inadimplencias.relatorios
    }
  }

  function handleClickParceiro(parceiroId: number) {
    setParceiroSelecionado(parceiroId)
  }

  return {
    styles: controleInadimplenciasStyles,
    controleInadimplenciasResponse,
    handleClickParceiro,
    parceiroSelecionado,
    handleFecharChip,
    carregando,
    mesesSelecionado,
    setMesesSelecionado,
    gerarDadosControleInadimplencias,
    history,
    dadosGrafico: gerarDadosGrafico(filtrarInadimplencias(INADIMPLENCIA.TOTAL), mesesSelecionado),
    inadimplenciasFiltradas: filtrarInadimplencias(INADIMPLENCIA.TOTAL),
    inadimplenciasFiltradasEmTransitoAtendimento: filtrarInadimplencias(INADIMPLENCIA.BACKOFFICE),
    inadimplenciasFiltradasEmTransitoLogisticaMartins: filtrarInadimplencias(INADIMPLENCIA.EM_TRANSITO_LOGISTICA_MARTINS),
    inadimplenciasFiltradasEmTransitoLogisticaParceiro: filtrarInadimplencias(INADIMPLENCIA.EM_TRANSITO_LOGISTICA_PARCEIRO),
    inadimplenciasFiltradasSemOcorrenciaEmAnalise: filtrarInadimplencias(INADIMPLENCIA.SEM_OCORRENCIA_EM_ANALISE),
    inadimplenciasFiltradasSemOcorrenciaPerda: filtrarInadimplencias(INADIMPLENCIA.PERDA),
    relatoriosInadimplencias: filtrarInadimplenciasRelatorio()
  }
}

export type ControleInadimplenciasIO = ReturnType<typeof controleInadimplenciasIO>
