import { useCallback, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import parceiroManager from '../../services/api/parceiro/parceiroManager'
import { Parceiro } from '../../types/parceiroApiTypes'
import { controleCicloPedidoStyles } from './ControleCicloPedido.style'
import { PAGES } from './../../router/routes'
import { DivergentesResponse, ObterDivergentesResponse } from '../../types/cicloPedidosTypes'
import cicloPedidosManager from '../../services/api/cicloPedidos/cicloPedidosManager'
import { StatusProcessamento } from '../../types/genericoType'
import msgConstants from '../../constants/msgConstants'

export function controleCicloPedidoIO() {
  const router = useHistory()
  const { parceiroId } = useParams<{ parceiroId: string }>()
  const [parceiros, setParceiros] = useState<Parceiro[]>([])
  const [divergencias, setDivergencias] = useState<DivergentesResponse>()
  const [parceiroSelecionado, setParceiroSelecionado] = useState<number>(Number(parceiroId ? parceiroId : 0))
  const [valorSetTimeOut, setValorSetTimeOut] = useState<number>(0)
  const [carregando, setCarregando] = useState(true)

  useEffect(() => {
    if (parceiroSelecionado) obterDivergencias()
  }, [])

  useEffect(() => {
    return router.listen(() => {
      clearTimeout(valorSetTimeOut)
    })
  }, [valorSetTimeOut])

  useEffect(() => {
    const obterParceirosAtivos = async () => {
      await parceiroManager
        .buscarDadosParceiros(true)
        .then((response: Parceiro[]) => {
          setParceiros(response)
        })
        .catch((error) => {
          toast.error(error.message)
        })
        .finally()
    }

    obterParceirosAtivos()
  }, [])

  const obterDivergencias = useCallback(async () => {
    setCarregando(true)
    await cicloPedidosManager
      .buscarDivergencias(parceiroSelecionado)
      .then((response: ObterDivergentesResponse) => {
        const status = response.processamento.status
        setDivergencias(response.divergenciasResponse)

        if (status === StatusProcessamento.PROCESSANDO) {
          const atualizarStatus = setTimeout(() => {
            obterDivergencias()
          }, 30000)
          setValorSetTimeOut(Number(atualizarStatus))
        } else {
          clearTimeout(valorSetTimeOut)
          setCarregando(false)
        }

        if (status === StatusProcessamento.NOVO) {
          toast.error(msgConstants.USO_GENERICO.erroBusca)
        }
      })
      .catch((error) => {
        toast.error(error.message)
      })
  }, [divergencias])

  const handleIconeVoltar = () => router.push(PAGES.controleCicloPedido)

  function handleAtualizarParceiro() {
    cicloPedidosManager
      .atualizarDivergenciasTela(parceiroSelecionado)
      .then((response: number) => {
        if (response >= 200 && response < 300) toast.success('Atualização acionada para o parceiro')
        obterDivergencias()
      })
      .catch((error) => {
        toast.error(error.message)
      })
  }

  const handleConfirmar = (dadosForm: { parceiroId: number }) => {
    setParceiroSelecionado(dadosForm.parceiroId)
    router.push(PAGES.controleCicloPedidoParceiro(dadosForm.parceiroId))
  }

  return {
    styles: controleCicloPedidoStyles,
    parceiroSelecionado,
    setParceiroSelecionado,
    parceiros,
    divergencias,
    parceiroLogo: parceiros.filter((parceiro) => parceiro.parceiroId === parceiroSelecionado)[0]?.logoUrl,
    parceiroNome: parceiros.filter((parceiro) => parceiro.parceiroId === parceiroSelecionado)[0]?.razaoSocial,
    handleIconeVoltar,
    router,
    obterDivergencias,
    carregando,
    setCarregando,
    handleConfirmar,
    dataUpload: divergencias?.dataUltimoUpload
      ? `${new Date(divergencias?.dataUltimoUpload).getDate()} de ${new Date(divergencias?.dataUltimoUpload).toLocaleString('pt-BR', {
          month: 'long'
        })}/${new Date(divergencias?.dataUltimoUpload).getFullYear()}, às ${new Date(divergencias?.dataUltimoUpload).getHours()}h ${new Date(
          divergencias?.dataUltimoUpload
        ).getMinutes()}m`
      : 'data não encontrada',
    dataAtualizacao: divergencias?.dataUltimaAtualizacao
      ? `${new Date(divergencias?.dataUltimaAtualizacao).toLocaleDateString('pt-BR')} às ${new Date(
          divergencias?.dataUltimaAtualizacao
        ).getHours()}h ${new Date(divergencias?.dataUltimaAtualizacao).getMinutes()}m`
      : 'data não encontrada',
    handleAtualizarParceiro
  }
}

export type ControleCicloPedidoIO = ReturnType<typeof controleCicloPedidoIO>
