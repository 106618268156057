import { Grid, MenuItem, Select } from '@material-ui/core'
import { FormEventHandler } from 'react'
import { IOProps } from 'react-compose-io'
import { Control, Controller, DeepMap, FieldError } from 'react-hook-form'
import { BotaoIconeSvg } from '../../../../components/BotaoIconeSvg'
import { BuscarTitulosForm } from '../../../../types/titulosTypes'
import { exportPDF } from '../../../../utils/uploadFileUtils'
import { ExportIcon } from '../../../../assets/icons/ExportIcon'
import { Botao } from './../../../../components/Botao/index'
import { CabecalhoListaParceirosIO } from './CabecalhoListaParceiros.IO'
import { customTheme } from '../../../../styles/customTheme'
import RefreshIcon from '@material-ui/icons/Refresh'
import { formateDate, formateHoras } from '../../../../utils/stringUtils'
import { Tipografia } from '../../../../components/Tipografia'

export type CabecalhoListaParceirosProps = {
  buscarTitulos: FormEventHandler<HTMLFormElement>
  atualizarTitulos: () => void
  controleFormulario: Control<BuscarTitulosForm>
  erroPreenchimento?: DeepMap<BuscarTitulosForm, FieldError>
  limparFiltro?: () => void
  botaoCarregando: boolean
  tituloTela: string
  titulosAbertos: boolean
  tituloDataInicial: string
  tituloDataFinal: string
  componenteId: string
  dataUltimaAtualizacaoAbertos?: string
}

export function CabecalhoListaParceirosView({
  _io,
  controleFormulario,
  limparFiltro,
  buscarTitulos,
  botaoCarregando,
  tituloTela,
  titulosAbertos,
  tituloDataInicial,
  tituloDataFinal,
  erroPreenchimento,
  componenteId,
  atualizarTitulos,
  dataUltimaAtualizacaoAbertos
}: IOProps<CabecalhoListaParceirosIO, CabecalhoListaParceirosProps>) {
  const {
    Titulo,
    InputData,
    ContainerCabecalho,
    Subtitulo,
    SelecionarTitulos,
    ContainerError,
    IconeCarregamento,
    TextoErroPreenchimento,
    ContainerTextoErroPreenchimento,
    ContainerFiltro
  } = _io.styles

  return (
    <>
      <form onSubmit={buscarTitulos}>
        <ContainerCabecalho>
          <ContainerCabecalho>
            <Titulo>Controle de Títulos</Titulo>
            <Controller
              render={({ field }) => (
                <SelecionarTitulos>
                  <Select {...field} id="titulos" label="Titulos" defaultValue={'1'}>
                    <MenuItem key={'1'} value={'Em aberto'}>
                      Em aberto
                    </MenuItem>
                    <MenuItem key={'2'} value={'Liquidados'}>
                      Liquidados
                    </MenuItem>
                    <MenuItem key={'3'} value={'Gerados'}>
                      Gerados
                    </MenuItem>
                  </Select>
                </SelecionarTitulos>
              )}
              name="titulos"
              control={controleFormulario}
            />
          </ContainerCabecalho>
          <BotaoIconeSvg
            onClick={() =>
              exportPDF(
                componenteId,
                controleFormulario?.fieldsRef?.current?.dataPeriodo?._f.value,
                controleFormulario?.fieldsRef?.current?.dataComparacao?._f.value
              )
            }
            icone={<ExportIcon width={24} height={24} color={customTheme.colors.azul06} />}
          />
        </ContainerCabecalho>
        <ContainerFiltro container direction="row" alignItems="center" justifyContent="space-between">
          <Grid item>
            <ContainerCabecalho>
              <Subtitulo>{tituloTela}</Subtitulo>
              <Botao estilo="outlined" cor="primaria" textoBotao="Atualizar dados" startIcon={<RefreshIcon />} onClick={atualizarTitulos} />
            </ContainerCabecalho>
          </Grid>
          <Grid item>
            <Grid container item alignItems="center" spacing={1}>
              <Grid item>
                <ContainerTextoErroPreenchimento>
                  {erroPreenchimento != undefined && erroPreenchimento.dataPeriodo && (
                    <TextoErroPreenchimento>{erroPreenchimento.dataPeriodo.message}</TextoErroPreenchimento>
                  )}
                  <Controller
                    name="dataPeriodo"
                    control={controleFormulario}
                    render={({ field }) => (
                      <InputData
                        label={tituloDataInicial}
                        {...field}
                        error={_io.verificarErros(erroPreenchimento, erroPreenchimento?.dataPeriodo)}
                        inputProps={{
                          max: _io.dataAtualPadrao
                        }}
                      />
                    )}
                  />
                </ContainerTextoErroPreenchimento>
              </Grid>
              <Grid item>
                <ContainerTextoErroPreenchimento>
                  {erroPreenchimento != undefined && erroPreenchimento.dataComparacao && (
                    <TextoErroPreenchimento>{erroPreenchimento.dataComparacao.message}</TextoErroPreenchimento>
                  )}
                  <Controller
                    name="dataComparacao"
                    control={controleFormulario}
                    render={({ field }) => (
                      <ContainerError>
                        <InputData
                          label={tituloDataFinal}
                          {...field}
                          error={_io.verificarErros(erroPreenchimento, erroPreenchimento?.dataComparacao)}
                          inputProps={{
                            max: _io.dataAtualPadrao
                          }}
                        />
                      </ContainerError>
                    )}
                  />
                </ContainerTextoErroPreenchimento>
              </Grid>
              <Grid item>{titulosAbertos && <Botao textoBotao="Limpar" estilo="outlined" cor="primaria" onClick={limparFiltro} />}</Grid>
              <Grid item>
                <Botao icone={botaoCarregando ? <IconeCarregamento /> : undefined} textoBotao={'Aplicar'} estilo="contained" cor="primaria" type="submit" />
              </Grid>
            </Grid>
          </Grid>
        </ContainerFiltro>
        {titulosAbertos && (
          <ContainerFiltro>
            <Tipografia variant="body2" colorText={customTheme.colors.cinza07}>
              {`Última atualização: ${formateDate(dataUltimaAtualizacaoAbertos)} às ${formateHoras(dataUltimaAtualizacaoAbertos)}`}
            </Tipografia>
          </ContainerFiltro>
        )}
      </form>
    </>
  )
}
