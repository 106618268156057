import { Grid, Typography } from '@material-ui/core'
import styled from 'styled-components'
import { customTheme } from '../../styles/customTheme'

export const itemHistoricoRegularizacaoStyles = {
  InformacoesUsuario: styled(Typography).attrs({
    variant: 'caption'
  })`
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    color: ${customTheme.colors.grey09};
  `,
  ContainerUsuario: styled.div`
    flex-direction: column;
    display: flex;
    margin-right: 8px;
  `,
  Container: styled.div`
    background: ${customTheme.colors.white05};
    border-radius: 8px;
    padding: 10px;
    margin-bottom: 10px;
  `,
  ContainerBotaoExportar: styled(Grid).attrs({
    xs: 1,
    sm: 1,
    md: 1,
    lg: 1,
    item: true
  })`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  `,
  ContainerDadosUsuario: styled(Grid).attrs({
    xs: 12,
    sm: 12,
    md: 1,
    lg: 1,
    item: true
  })`
    display: flex;
    flex-direction: row;
    align-items: center;
  `,
  TituloCarregando: styled(Typography).attrs({
    variant: 'subtitle2'
  })`
    font-weight: 500;
    line-height: 20px;
    color: ${customTheme.colors.grey03};
  `,
  Subtitulo: styled(Typography).attrs({
    variant: 'body2'
  })`
    font-weight: 400;
    color: ${customTheme.colors.grey03};
  `,
  ContainerTexto: styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 21px;
  `,
  ContainerIcone: styled(Grid).attrs({
    xs: 11,
    sm: 11,
    md: 10,
    lg: 10,
    item: true
  })`
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: center;
  `,
  IconInfo: styled.img`
    margin-top: 8px;
  `,
  TituloErro: styled(Typography).attrs({
    variant: 'h6'
  })`
    font-weight: 500;
    line-height: 26px;
    color: ${customTheme.colors.red06};
  `
}
