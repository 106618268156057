import { useForm } from 'react-hook-form'
import { detalheDebitoStyles } from './DetalheDebito.style'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { ChangeEvent, useCallback, useEffect, useState, useReducer } from 'react'
import { INADIMPLENCIA } from '../../types/controleInadimplenciaTypes'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import msgConstants from '../../constants/msgConstants'
import { loadingReset, loadingUpdate } from './../../store/actions/loadingAction'
import { Paginacao } from '../../types/tabelaTypes'
import { useParams } from 'react-router-dom'
import {
  AtualizarPedidoDebitoRequest,
  ClassificacaoDebito,
  DEBITO,
  ObterPedidosDebitosFiltros,
  ObterPedidosDebitosResponse,
  ResponsePedidosDebitoDetalhado
} from '../../types/debitosTypes'
import debitosManager from '../../services/api/debitos/debitosManager'
import AtendimentoIcon from '../../assets/icons/atendimentoIcone.svg'
import LogisticaIcon from '../../assets/icons/logisticaIcone.svg'
import LogisticaParceiroIcon from '../../assets/icons/logisticaParceiro.svg'
import AnaliseIcon from '../../assets/icons/analiseIcone.svg'
import { PossiveisTratativas } from '../../types/perdasTypes'
import { ItemDebito } from './components/ItemDebito/index'
import { CabecalhoTabelaDetalhesPerdas } from '../../components/TabelaDetalhesPerdas/TabelaDetalhesPerdas.View'
import { detalhePerdasReducer } from '../../reducers/detalhePerdas/reducer'
import {
  abrirFiltroClassificacoes,
  abrirFiltroParceiros,
  abrirFiltroPedidos,
  abrirFiltroTratativas,
  abrirModalClassificacaoPedidos,
  aprovarSugestaoClassificacao,
  fecharFiltro,
  fecharModalClassificacaoPedidos,
  limparCheckboxGeral,
  limparFiltros,
  mudarPagina,
  mudarTamanhoPagina,
  preencherPossiveisTratativas,
  reiniciarPaginacao,
  restaurarValoresAposClassificarPedidos,
  selecionarCheckboxFiltroClassificacoes,
  selecionarCheckboxFiltroParceiros,
  selecionarCheckboxFiltroTratativas,
  selecionarCheckboxGeral
} from '../../reducers/detalhePerdas/actions'
import { StatusProcessamento } from '../../types/genericoType'

const schema = yup.object({
  dataLancamentoFim: yup.string().test('valorMinimo', `Deve ser maior ou igual que 'Data de lançamento'`, function (dataLancamentoFim, form) {
    const dataInicial = '' + form.parent.dataLancamentoInicio
    const dataFinal = '' + dataLancamentoFim

    if (form.parent.dataLancamentoInicio == '' || dataLancamentoFim == '') return true

    return new Date(dataInicial) <= new Date(dataFinal)
  })
})

const isPreFiltroAnomalia = (anomalia: string) => anomalia != INADIMPLENCIA.TOTAL && anomalia != DEBITO.FINAL

export function detalheDebitoIO() {
  const { anomalia, parceiroId } = useParams<{ anomalia: string; parceiroId: string }>()

  const [detalhesPedidosDebitos, setDetalhesPedidosDebitos] = useState<ResponsePedidosDebitoDetalhado<ObterPedidosDebitosResponse>>()
  const [classificacoesDebitos, setClassificacoesDebitos] = useState<ClassificacaoDebito[]>([])
  const [isModalRegularizarPerdasAberto, setIsModalRegularizarPerdasAberto] = useState(false)

  const [detalhePerdasState, dispatch] = useReducer(detalhePerdasReducer, {
    classificacaoSugerida: 0,
    checklistsSelecionados: [] as string[],
    modalClassificarPedidosAberto: false,
    isFiltroParceiroAberto: null,
    isFiltroPedidoAberto: null,
    isFiltroClassificacoesAberto: null,
    isFiltroPossiveisTratativasAberto: null,
    checkListParceirosSelecionados: Number(parceiroId) != 0 ? [Number(parceiroId)] : [],
    checkListPossiveisTratativasSelecionados: isPreFiltroAnomalia(anomalia) ? [anomalia] : [],
    checkListClassificacoesSelecionados: [],
    possiveisTratativas: [],
    pagina: 0,
    linhasPorPagina: 10
  })

  const {
    classificacaoSugerida,
    checklistsSelecionados,
    modalClassificarPedidosAberto,
    isFiltroParceiroAberto,
    isFiltroPedidoAberto,
    isFiltroClassificacoesAberto,
    isFiltroPossiveisTratativasAberto,
    checkListParceirosSelecionados,
    checkListPossiveisTratativasSelecionados,
    checkListClassificacoesSelecionados,
    possiveisTratativas,
    pagina,
    linhasPorPagina
  } = detalhePerdasState

  const cabecalhosTabela: CabecalhoTabelaDetalhesPerdas[] = [
    {
      descricao: 'Parceiro',
      abrirPopover: (event: React.MouseEvent<HTMLButtonElement>) => dispatch(abrirFiltroParceiros(event.currentTarget))
    },
    {
      descricao: 'Data do lançamento'
    },
    {
      descricao: 'Número do pedido marketplace',
      abrirPopover: (event: React.MouseEvent<HTMLButtonElement>) => dispatch(abrirFiltroPedidos(event.currentTarget))
    },
    {
      descricao: 'Valor do débito'
    },
    {
      descricao: 'Possível tratativa',
      abrirPopover: (event: React.MouseEvent<HTMLButtonElement>) => dispatch(abrirFiltroTratativas(event.currentTarget))
    },
    {
      descricao: 'Classificação da análise',
      abrirPopover: (event: React.MouseEvent<HTMLButtonElement>) => dispatch(abrirFiltroClassificacoes(event.currentTarget))
    }
  ]

  const loadingDispatch = useDispatch()

  const {
    handleSubmit,
    control,
    formState: { errors },
    register,
    setValue,
    getValues
  } = useForm<ObterPedidosDebitosFiltros>({
    defaultValues: {
      dataLancamentoInicio: '',
      dataLancamentoFim: '',
      pedidoMkt: ''
    },
    resolver: yupResolver(schema)
  })

  useEffect(() => {
    obterPedidosDebitos({ pageNumber: 0, pageSize: 10 })
    buscarClassificacoesDebitos()
    buscarPossiveisTratativas()
  }, [])

  const buscarPossiveisTratativas = async () => {
    const possiveisTratativasResponse: PossiveisTratativas = await debitosManager.buscarPossiveisTratativasDebitos()
    dispatch(preencherPossiveisTratativas(possiveisTratativasResponse.tratativas))
  }

  const buscarClassificacoesDebitos = async () => {
    await debitosManager
      .buscarClassificacoesDebitos()
      .then((response: ClassificacaoDebito[]) => setClassificacoesDebitos(response))
      .catch((error) => toast.error(error.message))
  }

  const handleClassificarPedidos = async (data: { observacao: string; classificacao: number }) => {
    const body = (checklistsSelecionados as string[]).map((x) => {
      return {
        observacao: data.observacao,
        pedidoPlanilha: x,
        classificacao: data.classificacao != 0 ? data.classificacao : null
      } as AtualizarPedidoDebitoRequest
    })

    dispatch(fecharModalClassificacaoPedidos())
    loadingDispatch(loadingUpdate(true))
    await debitosManager
      .atualizarClassificacaoPedidoDebito(body)
      .then(() => {
        toast.success(msgConstants.DEBITO_PEDIDO.pedidoAtualizadoSucesso)
      })
      .catch((error) => toast.error(error.message))
      .finally(() => {
        handleAplicar()
      })

    dispatch(restaurarValoresAposClassificarPedidos())
  }

  const obterPedidosDebitos = useCallback(
    async (paginacao: Paginacao, isResetarBusca = false) => {
      loadingDispatch(loadingUpdate(true))
      await debitosManager
        .buscarPedidosDebitos({
          pageNumber: paginacao.pageNumber,
          pageSize: paginacao.pageSize,
          orderBy: paginacao.orderBy,
          orderField: paginacao.orderField,
          pedidoMkt: getValues('pedidoMkt'),
          dataLancamentoInicio: getValues('dataLancamentoInicio'),
          dataLancamentoFim: getValues('dataLancamentoFim'),
          parceiroId: isResetarBusca ? [] : checkListParceirosSelecionados,
          possivelTratativa: isResetarBusca ? [] : checkListPossiveisTratativasSelecionados,
          classificacao: isResetarBusca ? [] : checkListClassificacoesSelecionados
        })
        .then((response: ResponsePedidosDebitoDetalhado<ObterPedidosDebitosResponse>) => {
          setDetalhesPedidosDebitos(response)
        })
        .catch((error) => toast.error(error.message))
        .finally(() => loadingDispatch(loadingReset()))
    },

    [loadingDispatch, checkListParceirosSelecionados, checkListPossiveisTratativasSelecionados, checkListClassificacoesSelecionados]
  )

  const handleAplicar = () => {
    dispatch(reiniciarPaginacao())
    obterPedidosDebitos({ pageNumber: 0, pageSize: 10 })
  }

  const handleLimpar = () => {
    setValue('dataLancamentoInicio', '')
    setValue('dataLancamentoFim', '')
    setValue('pedidoMkt', '')

    dispatch(limparFiltros())

    obterPedidosDebitos({ pageNumber: 0, pageSize: 10 }, true)
  }

  const getCabecalhoDesing = () => {
    switch (anomalia) {
      case INADIMPLENCIA.BACKOFFICE:
        return {
          descricao: 'Detalhes de pedidos em trânsito - Backoffice',
          icone: AtendimentoIcon
        }
      case INADIMPLENCIA.EM_TRANSITO_LOGISTICA_MARTINS:
        return {
          descricao: 'Detalhes de pedidos em trânsito - Logística própria',
          icone: LogisticaIcon
        }
      case INADIMPLENCIA.EM_TRANSITO_LOGISTICA_PARCEIRO:
        return {
          descricao: 'Detalhes de pedidos em trânsito - Logística parceiro',
          icone: LogisticaParceiroIcon
        }
      case INADIMPLENCIA.SEM_OCORRENCIA_EM_ANALISE:
        return {
          descricao: 'Detalhes de pedidos sem ocorrência - Em análise',
          icone: AnaliseIcon
        }
      default:
        return {
          descricao: 'Detalhes de pedidos',
          icone: ''
        }
    }
  }

  const handleAprovarSugestao = (pedidoMkt: string, classificacao: number) => {
    dispatch(aprovarSugestaoClassificacao(pedidoMkt, classificacao))
  }

  const handleCheckList = (pedidoMkt: string) => {
    dispatch(selecionarCheckboxGeral(pedidoMkt))
  }

  const handleCloseFiltro = () => {
    dispatch(fecharFiltro())
    obterPedidosDebitos({ pageNumber: 0, pageSize: linhasPorPagina })
  }

  const onMudancaPagina = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, novaPagina: number) => {
    dispatch(mudarPagina(novaPagina))
    obterPedidosDebitos({ pageNumber: novaPagina + 1, pageSize: linhasPorPagina })
  }

  const onMudancaLinhasPorPagina = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(mudarTamanhoPagina(parseInt(event.target.value, 10)))
    obterPedidosDebitos({ pageNumber: 0, pageSize: parseInt(event.target.value, 10) })
  }

  const renderLinhasTabela = (detalheDebito: ObterPedidosDebitosResponse) => (
    <ItemDebito
      key={detalheDebito.pedidoMkt}
      item={detalheDebito}
      checklistsSelecionados={checklistsSelecionados}
      handleCheckList={handleCheckList}
      isAdministrador={detalhesPedidosDebitos ? detalhesPedidosDebitos.administrador : false}
      handleAprovarSugestao={handleAprovarSugestao}
    />
  )

  const isRegularizarDebitosDesabilitado = () => {
    return (
      detalhesPedidosDebitos &&
      detalhesPedidosDebitos.processamentoDebitoPerda &&
      detalhesPedidosDebitos.processamentoDebitoPerda.processamento.status === StatusProcessamento.PROCESSANDO
    )
  }

  const handleFecharModalRegularizarPerdasAberto = () => {
    setIsModalRegularizarPerdasAberto(false)

    obterPedidosDebitos({ pageNumber: pagina + 1, pageSize: linhasPorPagina })
  }

  return {
    styles: detalheDebitoStyles,
    control,
    register,
    errors,
    checklistsSelecionados,
    handleCleanAllCheckList: () => dispatch(limparCheckboxGeral()),
    detalhesPedidosDebitos,
    fecharModal: () => dispatch(fecharModalClassificacaoPedidos()),
    modalClassificarPedidosAberto,
    handleClassificarPedidos,
    handleSubmit,
    handleAplicar,
    handleLimpar,
    classificacoesDebitos,
    getCabecalhoDesing,
    classificacaoSugerida,
    abrirModalClassificarPedido: () => dispatch(abrirModalClassificacaoPedidos()),
    linhasPorPagina,
    pagina,
    onMudancaPagina,
    onMudancaLinhasPorPagina,
    cabecalhosTabela,
    isFiltroParceiroAberto,
    isFiltroPedidoAberto,
    isFiltroPossiveisTratativasAberto,
    isFiltroClassificacoesAberto,
    handleCloseFiltro,
    handleCheckFiltroParceiro: (parceiro: number) => dispatch(selecionarCheckboxFiltroParceiros(parceiro)),
    handleCheckFiltroPossivelTratativa: (tratativa: string) => dispatch(selecionarCheckboxFiltroTratativas(tratativa)),
    handleCheckFiltroClassificacoes: (classificacao: string) => dispatch(selecionarCheckboxFiltroClassificacoes(classificacao)),
    isParceiroChecked: (parceiro: number) => checkListParceirosSelecionados.includes(parceiro),
    isPossivelTratativaChecked: (tratativa: string) => checkListPossiveisTratativasSelecionados.includes(tratativa),
    isClassificacaoChecked: (classificacao: string) => checkListClassificacoesSelecionados.includes(classificacao),
    renderLinhasTabela,
    possiveisTratativas,
    isModalRegularizarPerdasAberto,
    handleAbrirModalRegularizarPerdasAberto: () => setIsModalRegularizarPerdasAberto(true),
    isRegularizarDebitosDesabilitado: isRegularizarDebitosDesabilitado(),
    handleFecharModalRegularizarPerdasAberto
  }
}

export type DetalheDebitoIO = ReturnType<typeof detalheDebitoIO>
