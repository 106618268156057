import { Chip, Switch } from '@material-ui/core'
import styled from 'styled-components'
import { customTheme } from './../../../../styles/customTheme'

export const modalTipoStatusStyles = {
  Form: styled.form`
    height: auto;
  `,
  Chip: styled(Chip)`
    background-color: ${(props: { background: string; colorfont: string }) => props.background};
    color: ${(props: { background: string; colorfont: string }) => props.colorfont};

    .MuiChip-deleteIconSmall {
      color: ${(props: { background: string; colorfont: string }) => props.colorfont};

      :hover {
        opacity: 0.8;
      }
    }
  `,
  Switch: styled(Switch)`
    .MuiSwitch-track {
      width: 43px;
      height: 24px;
      border-radius: 14px;

      position: absolute;
      top: 7px;
      left: 10px;
    }

    .MuiSwitch-switchBase {
      left: 3px;
    }

    .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
      background-color: ${customTheme.colors.azul06};
      opacity: 0.2;
    }

    .MuiSwitch-colorSecondary.Mui-checked {
      color: ${customTheme.colors.azul06};
    }

    .MuiSwitch-switchBase.Mui-checked {
      transform: translateX(18px);
    }
  `
}
