import { TablePaginationActionsProps } from '@material-ui/core/TablePagination/TablePaginationActions'
import { componentePaginacaoStyles } from './ComponentePaginacao.styles'

export function componentePaginacaoIO({ count, onPageChange, page, rowsPerPage }: TablePaginationActionsProps) {
  const handleIrPrimeiraPagina = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    onPageChange(event, 0)
  }

  const handlePularUmaPagina = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    onPageChange(event, page - 1)
  }

  const handleVoltarUmaPagina = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    onPageChange(event, page + 1)
  }

  const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
  }

  return {
    styles: componentePaginacaoStyles(),
    handleIrPrimeiraPagina,
    handlePularUmaPagina,
    handleVoltarUmaPagina,
    handleLastPageButtonClick,
    desabilitarUltimoBotao: page >= Math.ceil(count / rowsPerPage) - 1,
    desabilitarPrimeiroBotao: page === 0
  }
}

export type ComponentePaginacaoIO = ReturnType<typeof componentePaginacaoIO>
