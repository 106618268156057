import { Checkbox, TableCell, TableRow } from '@material-ui/core'
import { IOProps } from 'react-compose-io'
import { Tabela } from '../../../../components/Tabela'
import { IOpsVinculadas } from '../../../../types/ciclosApiType'
import { OrdemPagamentoRequest } from '../../../../types/regularizacaoOPsTitulosTypes'
import { cabecalhoTabelaResultadoOPS } from '../../../../types/tabelaTypes'
import { formateCurrency, validarTexto } from '../../../../utils/stringUtils'
import { TabelaResultadoOPSIO } from './TabelaResultadosOPS.IO'
import { HeaderTabelaResultadosOPS } from './../HeaderTabelaResultado/index'

export type TabelaResultadoOPSProps = {
  data: IOpsVinculadas[]
  setFiltradas: (ops: OrdemPagamentoRequest[]) => void
  handleAdicionarOps: () => void
  handleAdicionarOpsContinuar: () => void
  totalCiclo: number | undefined
}

function TabelaResultadoOPSView({
  _io,
  setFiltradas,
  handleAdicionarOps,
  handleAdicionarOpsContinuar
}: IOProps<TabelaResultadoOPSIO, TabelaResultadoOPSProps>) {
  const { CicloVinculadoContainer } = _io.styles

  function linhaTabela(props: { item: IOpsVinculadas }) {
    const { item } = props
    const isItemSelected = _io.isSelected(item)
    const labelId = `enhanced-table-checkbox-${item}`
    setFiltradas(_io.selected)

    return (
      <>
        <TableRow hover role="checkbox" aria-checked={isItemSelected} tabIndex={-1} key={item.ordemPagamentoId} selected={isItemSelected}>
          <TableCell padding="checkbox">
            <Checkbox
              onChange={(event) => _io.handleCheckboxOps(event, item)}
              aria-checked={isItemSelected}
              tabIndex={-1}
              key={item.ordemPagamentoId}
              checked={isItemSelected}
              inputProps={{ 'aria-labelledby': labelId }}
            />
          </TableCell>
          <TableCell align="center" component="th" id={labelId} scope="row" padding="none">
            {item.ordemPagamentoId}
          </TableCell>
          <TableCell align="center">{formateCurrency(item.valorPagamento)}</TableCell>
          <TableCell align="center">{new Date(item.dataEmissao).toLocaleDateString()}</TableCell>
          <TableCell align="center">{validarTexto(item.emitente, 'Sem emitente')}</TableCell>
          <TableCell align="center">{item.bancoId}</TableCell>
          <TableCell align="center">{validarTexto(item.descricao, 'Sem descrição')}</TableCell>
          <TableCell align="center">
            {item.cicloId !== 0 && <CicloVinculadoContainer>{`Vinculado ao ciclo ${item.cicloId.toLocaleString('pt-BR')}`}</CicloVinculadoContainer>}
          </TableCell>
        </TableRow>
      </>
    )
  }

  return (
    <>
      <HeaderTabelaResultadosOPS
        totalOpsSelecionadas={_io.selected.length}
        handleAdicionarOps={handleAdicionarOps}
        handleAdicionarOpsContinuar={handleAdicionarOpsContinuar}
      />
      <Tabela
        cabecalho={cabecalhoTabelaResultadoOPS}
        itensLista={_io.linhas}
        LinhaTabela={linhaTabela}
        orderCampoPadrao={'ordemPagamentoId'}
        rowsPerPageOptions={[5, 10, 15]}
        linhasPorPaginaPadrao={_io.linhasPorPagina}
        alinharTextoCentro={true}
        isCheckbox={true}
        handleSelectAllClick={_io.handleSelectAllClick}
        totalSelecionados={_io.selected.length}
        maxHeight={'40vh'}
      />
    </>
  )
}
export default TabelaResultadoOPSView
