import { useState } from 'react'
import { ORDEM_CRESCENTE } from '../../../../constants/utilConstants'
import { IOpsVinculadas } from '../../../../types/ciclosApiType'
import { OrdemPagamentoRequest } from '../../../../types/regularizacaoOPsTitulosTypes'
import { Order } from '../../../../types/tabelaTypes'
import { handleRequestSort, tratarMudancaLinhaPorPagina, tratarMudancaPagina } from '../../../../utils/tabelaUtils'
import { tabelaResultadosOPSStyles } from './TabelaResultadosOPS.style'
import { TabelaResultadoOPSProps } from './TabelaResultadosOPS.View'

export function tabelaResultadoOPSIO({ data }: TabelaResultadoOPSProps) {
  const [ordem, setOrdem] = useState<Order>(ORDEM_CRESCENTE)
  const [ordemBy, setOrdemBy] = useState<keyof never>('ordemPagamentoId')
  const [selected, setSelected] = useState<OrdemPagamentoRequest[]>([])
  const [pagina, setPagina] = useState(0)
  const [linhasPorPagina, setLinhasPorPagina] = useState(5)
  const [linhas] = useState<IOpsVinculadas[]>(data)
  const [totalSelecionado, setTotal] = useState<number[]>([])

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds: OrdemPagamentoRequest[] = linhas
        .filter((iopsVinculadas) => iopsVinculadas.cicloId == 0)
        .map((iopsVinculadas) => {
          return {
            ordemPagamentoId: iopsVinculadas.ordemPagamentoId,
            bancoId: iopsVinculadas.bancoId,
            operacaoOrdemPagamentoId: iopsVinculadas.operacaoOrdemPagamentoId,
            dataEmissao: iopsVinculadas.dataEmissao
          } as OrdemPagamentoRequest
        })

      const selecionados = linhas.filter((iopsVinculadas) => iopsVinculadas.cicloId == 0).map((iopsVinculadas) => iopsVinculadas.valorPagamento)
      setSelected(newSelecteds)
      setTotal([...totalSelecionado, ...selecionados])
      return
    }
    setSelected([])
    setTotal([])
  }

  const isSelected = (itemLista: OrdemPagamentoRequest) => {
    const selecionado = selected.filter(
      (item) =>
        item.bancoId == itemLista.bancoId &&
        item.ordemPagamentoId == itemLista.ordemPagamentoId &&
        item.operacaoOrdemPagamentoId == itemLista.operacaoOrdemPagamentoId &&
        item.dataEmissao == itemLista.dataEmissao
    )

    return selecionado.length != 0
  }

  const handleCheckboxOps = (event: React.ChangeEvent<HTMLInputElement>, opsItem: IOpsVinculadas) => {
    if (event.target.checked) {
      if (opsItem.cicloId == 0) {
        setSelected([...selected, opsItem])
      }
    } else {
      const index = selected.findIndex(
        (item) =>
          item.bancoId == opsItem.bancoId &&
          item.ordemPagamentoId == opsItem.ordemPagamentoId &&
          item.operacaoOrdemPagamentoId == opsItem.operacaoOrdemPagamentoId &&
          item.dataEmissao == opsItem.dataEmissao
      )
      const newSelected = selected.map((x) => x)
      if (index > -1) {
        newSelected.splice(index, 1)
      }
      setSelected(newSelected)
    }
  }

  return {
    styles: tabelaResultadosOPSStyles,
    onMudancaPagina: (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
      tratarMudancaPagina(event, newPage, setPagina)
    },
    onMudancaLinhasPorPagina: (event: React.ChangeEvent<HTMLInputElement>) => {
      tratarMudancaLinhaPorPagina(event, setPagina, setLinhasPorPagina)
    },
    onRequestSort: handleRequestSort(ordemBy, ordem, setOrdem, setOrdemBy),
    rowsPerPageOptions: [5, 10, 25],
    ordem,
    pagina,
    ordemBy,
    selected,
    handleSelectAllClick,
    linhas,
    linhasPorPagina,
    isSelected,
    setSelected,
    handleCheckboxOps
  }
}

export type TabelaResultadoOPSIO = ReturnType<typeof tabelaResultadoOPSIO>
