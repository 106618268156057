import { customTheme } from '../styles/customTheme'
import { ControleComissaoPedidoResponse, DetalheComissaoPedidoChaves, DetalheControleComissaoPedidoResponse } from '../types/controleComissaoTypes'

export const deDetalheControleComissaoPedidoParaChave = (detalheControleComissaoPedido: DetalheControleComissaoPedidoResponse) => {
  // eslint-disable-next-line max-len
  return `${detalheControleComissaoPedido.pedidoPlanilhaId}/${detalheControleComissaoPedido.parceiroId}/${detalheControleComissaoPedido.pedidoPlanilhaId}/${detalheControleComissaoPedido.posicaoPlanilha}/${detalheControleComissaoPedido.mercadoriaId}/${detalheControleComissaoPedido.cicloId}/${detalheControleComissaoPedido.parcela}`
}

export const deControleComissaoPedidoParaChave = (controleComissaoPedido: ControleComissaoPedidoResponse) => {
  return `${controleComissaoPedido.pedidoPlanilhaId}/${controleComissaoPedido.parceiroId}`
}

export const comissaoPedidoChavesFromString = (input: string): DetalheComissaoPedidoChaves => {
  const chaves = input.split('/')

  return {
    pedidoPlanilhaId: chaves[0],
    parceiroId: Number(chaves[1]),
    posicaoPlanilha: Number(chaves[3]),
    mercadoriaId: Number(chaves[4]),
    cicloId: Number(chaves[5]),
    parcela: Number(chaves[6])
  }
}

export const handleSelecao = <T>(item: T, setList: React.Dispatch<React.SetStateAction<T[]>>) => {
  setList((previousList) => {
    if (previousList.includes(item)) {
      return previousList.filter((listItem) => listItem !== item)
    } else {
      return [...previousList, item]
    }
  })
}

export function corBarraQuantidadePedidos() {
  return customTheme.colors.amarelo07
}

export function corBarraValorPedidos({ value }: { dataPointIndex: number; value: number }) {
  if (value >= 0) return customTheme.colors.cinza08
  else return customTheme.colors.vermelho05
}
