import { TypographyProps } from '@material-ui/core'
import { tipografiaStyles } from './Tipografia.styles'

export interface TipografiaProps extends TypographyProps {
  fontWeight?: string
  fontSize?: string
  lineHeight?: string
  colorText?: string
  textAlignment?: string
}

export function Tipografia({ fontSize, fontWeight, lineHeight, colorText, textAlignment, children, ...rest }: TipografiaProps) {
  const { Typography } = tipografiaStyles

  return (
    <Typography colortext={colorText} size={fontSize} weight={fontWeight} lineheight={lineHeight} textalign={textAlignment} {...rest}>
      {children}
    </Typography>
  )
}
