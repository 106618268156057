import { IconButton } from '@material-ui/core'
import { TablePaginationActionsProps } from '@material-ui/core/TablePagination/TablePaginationActions'
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons'
import PropTypes from 'prop-types'
import LastPageIcon from '@material-ui/icons/LastPage'
import FirstPageIcon from '@material-ui/icons/FirstPage'
import { IOProps } from 'react-compose-io'
import { ComponentePaginacaoIO } from './ComponentePaginacao.IO'

ComponentePaginacaoView.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired
}

export function ComponentePaginacaoView({ _io }: IOProps<ComponentePaginacaoIO, TablePaginationActionsProps>) {
  return (
    <div className={_io.styles.root}>
      <IconButton onClick={_io.handleIrPrimeiraPagina} disabled={_io.desabilitarPrimeiroBotao}>
        <FirstPageIcon />
      </IconButton>
      <IconButton onClick={_io.handlePularUmaPagina} disabled={_io.desabilitarPrimeiroBotao}>
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton onClick={_io.handleVoltarUmaPagina} disabled={_io.desabilitarUltimoBotao}>
        <KeyboardArrowRight />
      </IconButton>
      <IconButton onClick={_io.handleLastPageButtonClick} disabled={_io.desabilitarUltimoBotao}>
        <LastPageIcon />
      </IconButton>
    </div>
  )
}
