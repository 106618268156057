import { modalVisualizarAssociacaoStyles } from './ModalVisualizarAssociacao.style'

export function modalVisualizarAssociacaoIO() {
  const visualizarAssociacao = [
    { titleCabecalho: 'Tipo de lançamento parceiro' },
    { titleCabecalho: 'Tipo de lançamento eFácil' },
    { titleCabecalho: 'Conta' },
    { titleCabecalho: 'Natureza' },
    { titleCabecalho: 'Tipo' }
  ]

  return {
    styles: modalVisualizarAssociacaoStyles,
    visualizarAssociacao
  }
}

export type ModalVisualizarAssociacaoIO = ReturnType<typeof modalVisualizarAssociacaoIO>
