import { ResponsePaginado } from '../../../types/genericoType'
import { HistoricoRegularizacao, ValidacaoPlanilhaResponse } from '../../../types/regularizacaoTypes'
import { Paginacao } from '../../../types/tabelaTypes'
import { concatenarFiltrosQuery } from '../../../utils/stringUtils'
import api from '../../axiosInstance'

const efetivarRegularizacaoTitulos = async (arquivoId: string): Promise<boolean> => {
  const URL_REGULARIZAR_TITULOS = `RegularizacaoTitulos/Efetivar/${arquivoId}`
  const response = await api.conectarPost<boolean>(URL_REGULARIZAR_TITULOS)
  return response.data
}

const validarPlanilhaRegularizacaoTitulos = async (arquivo: FormData): Promise<ValidacaoPlanilhaResponse> => {
  const URL_VALIDAR_PLANILHA = `/RegularizacaoTitulos/ValidarTitulos`
  const response = await api.conectarPost<ValidacaoPlanilhaResponse>(URL_VALIDAR_PLANILHA, arquivo, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  return response.data
}

const buscarHistoricoRegularizacaoTitulos = async (paginacao: Paginacao): Promise<ResponsePaginado<HistoricoRegularizacao>> => {
  const URL_BUSCAR_HISTORICO = `/RegularizacaoTitulos/Historico${concatenarFiltrosQuery({
    pageNumber: paginacao.pageNumber,
    pageSize: paginacao.pageSize,
    orderField: 'historico.dataReferencia',
    orderBy: 'desc'
  })}`
  const response = await api.conectarGet<ResponsePaginado<HistoricoRegularizacao>>(URL_BUSCAR_HISTORICO)
  return response.data
}

export default {
  buscarHistoricoRegularizacaoTitulos,
  validarPlanilhaRegularizacaoTitulos,
  efetivarRegularizacaoTitulos
}
