import { useCallback, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import parceiroManager from '../../services/api/parceiro/parceiroManager'
import { Parceiro } from '../../types/parceiroApiTypes'
import { PAGES } from './../../router/routes'
import { StatusProcessamento } from '../../types/genericoType'
import { ResumosPedidoComissaoParceiro } from '../../types/controleComissaoTypes'
import { controleComissaoPedidoStyles } from './ControleComissaoPedido.style'
import { loadingReset, loadingUpdate } from '../../store/actions/loadingAction'
import { useDispatch } from 'react-redux'
import controleComissaoManager from '../../services/api/controleComissao/controleComissaoManager'
import { useForm } from 'react-hook-form'
import { gerarDadosGrafico } from '../../utils/tratarDadosMesesUtils'

export function controleComissaoPedidoIO() {
  const router = useHistory()
  const { parceiroId } = useParams<{ parceiroId: string }>()
  const [parceiros, setParceiros] = useState<Parceiro[]>([])
  const [parceiroSelecionado, setParceiroSelecionado] = useState<number>(Number(parceiroId ? parceiroId : 0))
  const [valorSetTimeOut, setValorSetTimeOut] = useState<number>(0)
  const [resumoParceiro, setResumoParceiro] = useState<ResumosPedidoComissaoParceiro>()
  const [mesesSelecionado, setMesesSelecionado] = useState<3 | 6>(6)
  const [atualizando, setAtualizando] = useState<boolean>(false)

  const loadingDispatch = useDispatch()
  const { control, watch } = useForm<{ meses: 3 | 6 }>({
    defaultValues: {
      meses: 6
    }
  })

  useEffect(() => {
    if (parceiroSelecionado) obterResumosPedidoComissaoParceiro()
  }, [])

  useEffect(() => {
    return router.listen(() => {
      clearTimeout(valorSetTimeOut)
    })
  }, [valorSetTimeOut])

  useEffect(() => {
    const obterParceirosAtivos = async () => {
      await parceiroManager
        .buscarDadosParceiros(true)
        .then((response: Parceiro[]) => {
          setParceiros(response)
        })
        .catch((error) => {
          toast.error(error.message)
        })
        .finally()
    }

    obterParceirosAtivos()
  }, [])

  const dropDownMes = watch('meses')

  useEffect(() => {
    setMesesSelecionado(dropDownMes)
  }, [dropDownMes])

  const obterResumosPedidoComissaoParceiro = useCallback(async () => {
    loadingDispatch(loadingUpdate(true))
    await controleComissaoManager
      .obterResumosPedidoComissaoParceiro(parceiroSelecionado)
      .then((response: ResumosPedidoComissaoParceiro) => {
        if (response) {
          const status = response.processamento.status

          if (status === StatusProcessamento.PROCESSANDO) {
            setAtualizando(true)
            const atualizarStatus = setTimeout(() => {
              obterResumosPedidoComissaoParceiro()
              setResumoParceiro(response)
            }, 30000)
            setValorSetTimeOut(Number(atualizarStatus))
          } else {
            setResumoParceiro(response)
            setAtualizando(false)
            clearTimeout(valorSetTimeOut)
          }
        } else {
          setResumoParceiro(undefined)
          clearTimeout(valorSetTimeOut)
        }
      })
      .catch((error: any) => {
        toast.error(error.message)
      })
      .finally(() => loadingDispatch(loadingReset()))
  }, [resumoParceiro])

  const handleIconeVoltar = () => router.push(PAGES.comissaoPedidos)

  const handleConfirmar = (dadosForm: { parceiroId: number }) => {
    setParceiroSelecionado(dadosForm.parceiroId)
    router.push(PAGES.comissaoPedidosParceiro(dadosForm.parceiroId))
  }

  const handleVerMaisDetalhesPedidoBotao = () => router.push(PAGES.controleComissaoPedidoClassificacao(parceiroSelecionado))

  function handleAtualizarParceiro() {
    controleComissaoManager
      .atualizaResumosPedidosComissao(parceiroSelecionado)
      .then((response: number) => {
        if (response >= 200 && response < 300) toast.success('Atualização acionada para o parceiro')
        obterResumosPedidoComissaoParceiro()
      })
      .catch((error) => {
        toast.error(error.message)
      })
  }

  const getTotaisMeses = (): { quantidade: number; valor: number } =>
    mesesSelecionado === 6
      ? {
          quantidade: resumoParceiro?.dados?.quantidade6Meses ?? 0,
          valor: resumoParceiro?.dados?.total6Meses ?? 0
        }
      : {
          quantidade: resumoParceiro?.dados?.quantidade3Meses ?? 0,
          valor: resumoParceiro?.dados?.total3Meses ?? 0
        }

  return {
    styles: controleComissaoPedidoStyles,
    parceiroSelecionado,
    setParceiroSelecionado,
    parceiros,
    parceiroLogo: parceiros.filter((parceiro) => parceiro.parceiroId === parceiroSelecionado)[0]?.logoUrl,
    parceiroNome: parceiros.filter((parceiro) => parceiro.parceiroId === parceiroSelecionado)[0]?.razaoSocial,
    handleIconeVoltar,
    router,
    handleConfirmar,
    dataAtualizacao: !atualizando
      ? resumoParceiro?.dataReferencia
        ? `${new Date(resumoParceiro?.dataReferencia).toLocaleDateString('pt-BR')} às ${new Date(resumoParceiro?.dataReferencia).getHours()}h ${new Date(
            resumoParceiro?.dataReferencia
          ).getMinutes()}m`
        : 'Data não encontrada'
      : 'Atualizando...',
    handleAtualizarParceiro,
    dadosGrafico: gerarDadosGrafico(resumoParceiro?.dados?.dadosMensais ?? [], mesesSelecionado),
    urlRelatorio: resumoParceiro?.dados?.urlRelatorio ?? '',
    resumoQuatidade: getTotaisMeses().quantidade,
    resumoValor: getTotaisMeses().valor,
    handleVerMaisDetalhesPedidoBotao,
    control
  }
}

export type ControleComissaoPedidoIO = ReturnType<typeof controleComissaoPedidoIO>
