import { Typography } from '@material-ui/core'
import styled from 'styled-components'
import { ContainerSombra } from '../../../../components/ContainerSombra'
import { customTheme } from '../../../../styles/customTheme'

const defaultProps = {
  borderColor: `${customTheme.colors.grey02}`,
  border: 1,
  style: { width: '100%', padding: 10, marginTop: 15, marginBottom: 15 }
}
const Container = styled(ContainerSombra)`
  margin: 10px 0;
  border-radius: 8px;
  width: 100%;
  overflow: auto;
`
const Subtitle = styled(Typography).attrs({
  variant: 'subtitle1'
})`
  font-weight: 400;
  margin-top: 8px;
  margin-bottom: 24px;
`
const CardSubtitle = styled(Typography).attrs({
  variant: 'subtitle1'
})`
  color: ${customTheme.colors.grey03};
  font-weight: 400;
  line-height: 19px;
  margin-bottom: 20px;
`
const Upload = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`
const Main = styled.main`
  display: inline-grid;
  width: 100%;
`

export { defaultProps, Subtitle, Container, CardSubtitle, Upload, Main }
