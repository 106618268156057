import { DetalhePerdasActionsTypes } from './actions'

export interface DetalhePerdasState {
  classificacaoSugerida: number
  modalClassificarPedidosAberto: boolean
  possiveisTratativas: string[]
  pagina: number
  linhasPorPagina: number

  isFiltroClassificacoesAberto: null | HTMLElement
  isFiltroParceiroAberto: null | HTMLElement
  isFiltroPedidoAberto: null | HTMLElement
  isFiltroPossiveisTratativasAberto: null | HTMLElement

  checklistsSelecionados: string[]
  checkListParceirosSelecionados: number[]
  checkListPossiveisTratativasSelecionados: string[]
  checkListClassificacoesSelecionados: string[]
}

export function detalhePerdasReducer(state: DetalhePerdasState, action: any) {
  switch (action.type) {
    case DetalhePerdasActionsTypes.PREENCHER_POSSIVEIS_TRATATIVAS:
      return {
        ...state,
        possiveisTratativas: action.payload.tratativas as string[]
      }

    case DetalhePerdasActionsTypes.RESTAURAR_VALORES_APOS_CLASSIFICAR_PEDIDOS:
      return {
        ...state,
        checklistsSelecionados: [],
        classificacaoSugerida: 0
      }

    case DetalhePerdasActionsTypes.REINICIAR_PAGINACAO:
      return {
        ...state,
        checklistsSelecionados: [],
        pagina: 0,
        linhasPorPagina: 10
      }

    case DetalhePerdasActionsTypes.LIMPAR_FILTROS_DE_BUSCA:
      return {
        ...state,
        checklistsSelecionados: [],
        checkListParceirosSelecionados: [],
        checkListPossiveisTratativasSelecionados: [],
        checkListClassificacoesSelecionados: [],
        pagina: 0,
        linhasPorPagina: 10
      }

    case DetalhePerdasActionsTypes.APROVAR_SUGESTAO_DE_CLASSIFICACAO:
      return {
        ...state,
        checklistsSelecionados: [action.payload.pedidoMkt],
        classificacaoSugerida: action.payload.classificacao,
        modalClassificarPedidosAberto: true
      }

    case DetalhePerdasActionsTypes.ABRIR_MODAL_CLASSIFICACAO_PEDIDOS:
      return {
        ...state,
        classificacaoSugerida: 0,
        modalClassificarPedidosAberto: true
      }

    case DetalhePerdasActionsTypes.FECHAR_MODAL_CLASSIFICACAO_PEDIDOS:
      return {
        ...state,
        modalClassificarPedidosAberto: false
      }

    case DetalhePerdasActionsTypes.SELECIONAR_CHECK_GERAL: {
      const checklistsJaSelecionados = state.checklistsSelecionados
      const { pedidoMkt } = action.payload
      let novoChecklistsSelecionados = []

      if (checklistsJaSelecionados.includes(pedidoMkt)) {
        novoChecklistsSelecionados = checklistsJaSelecionados.filter((selecionado) => selecionado != pedidoMkt)
      } else novoChecklistsSelecionados = [...checklistsJaSelecionados, pedidoMkt]

      return {
        ...state,
        checklistsSelecionados: novoChecklistsSelecionados
      }
    }

    case DetalhePerdasActionsTypes.LIMPAR_CHECK_GERAL:
      return {
        ...state,
        checklistsSelecionados: []
      }

    case DetalhePerdasActionsTypes.FECHAR_FILTRO:
      return {
        ...state,
        isFiltroParceiroAberto: null,
        isFiltroPedidoAberto: null,
        isFiltroClassificacoesAberto: null,
        isFiltroPossiveisTratativasAberto: null,
        checklistsSelecionados: [],
        pagina: 0,
        linhasPorPagina: 10
      }

    case DetalhePerdasActionsTypes.SELECIONAR_CHECK_FILTRO_PARCEIROS: {
      const checklistsJaSelecionados = state.checkListParceirosSelecionados
      const { parceiroId } = action.payload
      let novoChecklistsSelecionados = []

      if (checklistsJaSelecionados.includes(parceiroId)) {
        novoChecklistsSelecionados = checklistsJaSelecionados.filter((selecionado) => selecionado != parceiroId)
      } else novoChecklistsSelecionados = [...checklistsJaSelecionados, parceiroId]

      return {
        ...state,
        checkListParceirosSelecionados: novoChecklistsSelecionados
      }
    }

    case DetalhePerdasActionsTypes.SELECIONAR_CHECK_FILTRO_CLASSIFICACOES: {
      const checklistsJaSelecionados = state.checkListClassificacoesSelecionados
      const { classificacao } = action.payload
      let novoChecklistsSelecionados = []

      if (checklistsJaSelecionados.includes(classificacao)) {
        novoChecklistsSelecionados = checklistsJaSelecionados.filter((selecionado) => selecionado != classificacao)
      } else novoChecklistsSelecionados = [...checklistsJaSelecionados, classificacao]

      return {
        ...state,
        checkListClassificacoesSelecionados: novoChecklistsSelecionados
      }
    }

    case DetalhePerdasActionsTypes.SELECIONAR_CHECK_FILTRO_TRATATIVAS: {
      const checklistsJaSelecionados = state.checkListPossiveisTratativasSelecionados
      const { tratativa } = action.payload
      let novoChecklistsSelecionados = []

      if (checklistsJaSelecionados.includes(tratativa)) {
        novoChecklistsSelecionados = checklistsJaSelecionados.filter((selecionado) => selecionado != tratativa)
      } else novoChecklistsSelecionados = [...checklistsJaSelecionados, tratativa]

      return {
        ...state,
        checkListPossiveisTratativasSelecionados: novoChecklistsSelecionados
      }
    }

    case DetalhePerdasActionsTypes.MUDAR_TAMANHO_PAGINA:
      return {
        ...state,
        pagina: 0,
        linhasPorPagina: action.payload.novoTamanhoPagina
      }

    case DetalhePerdasActionsTypes.MUDAR_PAGINA:
      return {
        ...state,
        pagina: action.payload.novaPagina
      }

    case DetalhePerdasActionsTypes.ABRIR_FILTRO_CLASSIFICACOES:
      return {
        ...state,
        isFiltroClassificacoesAberto: action.payload.target
      }

    case DetalhePerdasActionsTypes.ABRIR_FILTRO_PARCEIROS:
      return {
        ...state,
        isFiltroParceiroAberto: action.payload.target
      }

    case DetalhePerdasActionsTypes.ABRIR_FILTRO_PEDIDOS:
      return {
        ...state,
        isFiltroPedidoAberto: action.payload.target
      }

    case DetalhePerdasActionsTypes.ABRIR_FILTRO_TRATATIVAS:
      return {
        ...state,
        isFiltroPossiveisTratativasAberto: action.payload.target
      }

    default:
      return state
  }
}
