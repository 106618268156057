import { Button } from '@material-ui/core'
import styled from 'styled-components'
import { customTheme } from './../../styles/customTheme'
import { handleHexToRgba } from '../../utils/stringUtils'

type EstiloBotao = {
  estilo?: 'text' | 'contained' | 'outlined' | 'rounded'
  tamanho?: 'pequeno' | 'medio' | 'grande'
  cor?: 'primaria' | 'erro' | 'sucesso' | string
  width?: string
  margin?: string
}

const valorBoxShadowPadrao = `0px 1px 1px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)`

export const botaoStyles = {
  Button: styled(Button)`
    text-transform: none;
    background: ${({ estilo, cor }: EstiloBotao) => estiloBotao(estilo, cor)?.background};
    color: ${({ estilo, cor }: EstiloBotao) => estiloBotao(estilo, cor)?.color};
    height: ${({ tamanho }: EstiloBotao) => tamanhoBotao(tamanho)};
    border-radius: ${({ estilo }: EstiloBotao) => estiloBotao(estilo)?.borderRadius};
    border: ${({ estilo, cor }: EstiloBotao) => estiloBotao(estilo, cor)?.border};
    padding: 4px 16px;
    font-size: ${customTheme.fontSize[18]};
    width: ${({ width }: EstiloBotao) => (width ? width : 'auto')};
    margin: ${({ margin }: EstiloBotao) => (margin ? margin : 'none')};

    :hover {
      cursor: pointer;
      opacity: 0.9;
      transition: 0.1;
      background: ${({ estilo, cor }: EstiloBotao) => estiloBotao(estilo, cor)?.hover};
      border: ${({ estilo, cor }: EstiloBotao) => estiloBotao(estilo, cor)?.bordaHover};
      box-shadow: ${({ estilo }: EstiloBotao) => estiloBotao(estilo)?.boxShadow};
    }

    :disabled {
      cursor: default;
      background: ${({ estilo }: EstiloBotao) => estiloBotao(estilo)?.disableBackgroundColor};
      color: ${customTheme.colors.cinza04};
      border: ${({ estilo }: EstiloBotao) => estiloBotao(estilo)?.borderDisable};
      transition: none;
    }
  `,
  ContainerIconeTexto: styled.div`
    gap: 9.5px;
    display: flex;
    align-items: center;
  `
}

const corBotao = (estilo?: string, opacidadeBotao?: number) => {
  switch (estilo) {
    case 'primaria':
      return { cor: `${customTheme.colors.azul06}`, hover: handleHexToRgba(opacidadeBotao, `${customTheme.colors.azul06}`) }
    case 'erro':
      return { cor: `${customTheme.colors.vermelho04}`, hover: handleHexToRgba(opacidadeBotao, `${customTheme.colors.vermelho04}`) }
    case 'sucesso':
      return { cor: `${customTheme.colors.verde05}`, hover: handleHexToRgba(opacidadeBotao, `${customTheme.colors.verde05}`) }
    default:
      return { cor: estilo, hover: handleHexToRgba(opacidadeBotao, estilo) }
  }
}

const tamanhoBotao = (tamanho?: string) => {
  switch (tamanho) {
    case 'pequeno':
      return '28px'
    case 'medio':
      return '36px'
    case 'grande':
      return '44px'
    default:
      return '36px'
  }
}

const estiloBotao = (tamanho?: string, cor?: string) => {
  if (tamanho == 'text')
    return {
      borderRadius: '4px',
      background: 'none',
      color: corBotao(cor).cor,
      disableBackgroundColor: 'none',
      borderDisable: 'none',
      hover: corBotao(cor).hover,
      border: 'none'
    }
  if (tamanho == 'contained')
    return {
      borderRadius: '4px',
      background: corBotao(cor).cor,
      color: `${customTheme.colors.white01}`,
      disableBackgroundColor: `${customTheme.colors.cinza02}`,
      borderDisable: 'none',
      hover: corBotao(cor).cor,
      boxShadow: valorBoxShadowPadrao,
      border: 'none'
    }
  if (tamanho == 'outlined')
    return {
      borderRadius: '4px',
      background: 'none',
      color: corBotao(cor, 0.5).cor,
      disableBackgroundColor: 'transparent',
      borderDisable: `1px solid ${customTheme.colors.cinza04}`,
      border: `1px solid ${corBotao(cor, 0.5).hover}`,
      bordaHover: `1px solid ${corBotao(cor, 0.5).cor}`,
      hover: 'transparent'
    }
  if (tamanho == 'rounded')
    return {
      borderRadius: '50px',
      background: corBotao(cor).cor,
      color: `${customTheme.colors.white01}`,
      disableBackgroundColor: `${customTheme.colors.cinza02}`,
      borderDisable: 'none',
      hover: corBotao(cor).cor,
      boxShadow: valorBoxShadowPadrao,
      border: 'none'
    }
}
