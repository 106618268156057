import { Box, Dialog, DialogContent } from '@material-ui/core'
import styled from 'styled-components'

export const customDialogStyles = {
  Box: styled(Box)`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
  Dialog: styled(Dialog)`
    .MuiDialogContent-root {
      overflow: 'hidden';
    }
  `,
  DialogContent: styled(DialogContent)`
    overflow-y: auto;
  `
}
