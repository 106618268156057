import { MESES_ABREVIADOS } from '../../../../constants/utilConstants'
import { InadimplenciasAgrupadas } from '../../../../types/controleInadimplenciaTypes'
import { collapseInadimplenciaTableStyles } from './CollapseInadimplenciaTable.style'
import { CollapseInadimplenciaTableProps } from './CollapseInadimplenciaTable.View'
import { useState } from 'react'

export function collapseInadimplenciaTableIO({ inadimplencias }: CollapseInadimplenciaTableProps) {
  const [controleCollapse, setControleCollapse] = useState<number[]>([])

  const dadosAgrupados: InadimplenciasAgrupadas[] = inadimplencias.reduce((acc: InadimplenciasAgrupadas[], dado) => {
    const ano = dado.ano
    const mes = dado.mes
    const valor = dado.valor
    const quantidade = dado.quantidade
    let dadosAno = acc.find((d) => d.ano === ano)
    if (!dadosAno) {
      dadosAno = {
        ano,
        valorTotal: 0,
        quantidadeTotal: 0,
        dadosMeses: []
      }
      for (let i = 1; i <= 12; i++) {
        dadosAno.dadosMeses[i - 1] = {
          mes: MESES_ABREVIADOS[i - 1],
          valorTotal: 0,
          quantidadeTotal: 0
        }
      }
      acc.push(dadosAno)
    }
    dadosAno.valorTotal += valor
    dadosAno.quantidadeTotal += quantidade
    dadosAno.dadosMeses[mes - 1].valorTotal += valor
    dadosAno.dadosMeses[mes - 1].quantidadeTotal += quantidade
    return acc
  }, [])

  return {
    styles: collapseInadimplenciaTableStyles,
    controleCollapse,
    setControleCollapse,
    dadosAgrupados: [...dadosAgrupados].sort(function (a, b) {
      if (a.ano > b.ano) {
        return 1
      }
      if (a.ano < b.ano) {
        return -1
      }

      return 0
    }),
    valorTotal: dadosAgrupados.reduce((total, ano) => total + ano.valorTotal, 0),
    quantidadeTotal: dadosAgrupados.reduce((total, ano) => total + ano.quantidadeTotal, 0)
  }
}

export type CollapseInadimplenciaTableIO = ReturnType<typeof collapseInadimplenciaTableIO>
