import { Collapse, Grid, IconButton, TableCell } from '@material-ui/core'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import { IOProps } from 'react-compose-io'
import { ConfiguracaoGeral } from '../../../../types/configuracoesApiType'
import { checarStatus, formateDate } from '../../../../utils/stringUtils'
import { ItemConfiguracoesGeraisIO } from './ItemConfigiracoesGerais.IO'
import { Tipografia } from '../../../../components/Tipografia'

export type ItemConfiguracoesGeraisProps = {
  linhaConfiguracaoGeral: ConfiguracaoGeral
}

export function ItemConfiguracoesGeraisView({ _io, linhaConfiguracaoGeral }: IOProps<ItemConfiguracoesGeraisIO, ItemConfiguracoesGeraisProps>) {
  const { LinhaTabela, LinhaHistorico, CelulaHistorico, BoxAtivo, BoxInativo, BoxHistorico, BoxPadding } = _io.styles

  const sequenciaFinalizadaDataDesativacao = linhaConfiguracaoGeral?.dataDesativacao && (
    <Grid item xs={12}>
      <BoxPadding>
        <Grid item xs={12}>
          <Tipografia variant="subtitle2">Finalizou Sequência</Tipografia>
        </Grid>
        <Grid item xs={12}>
          {formateDate(linhaConfiguracaoGeral.dataDesativacao, true)} - {linhaConfiguracaoGeral?.usuarioDesativacao ?? '-'}
        </Grid>
      </BoxPadding>
    </Grid>
  )

  return (
    <>
      <LinhaTabela onClick={_io.fecharModal}>
        <TableCell>
          <IconButton size="small" onClick={_io.fecharModal}>
            {_io.abrirModal ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="center">{linhaConfiguracaoGeral.siglaSistema || ' -- '}</TableCell>
        <TableCell align="center">{linhaConfiguracaoGeral.unidadeNegocioDescricao || ' -- '}</TableCell>
        <TableCell align="center">{linhaConfiguracaoGeral.eventoDescricao || ' -- '}</TableCell>
        <TableCell align="center">{linhaConfiguracaoGeral.fatoDescricao || ' -- '}</TableCell>
        <TableCell align="center">
          {linhaConfiguracaoGeral.ativo ? (
            <BoxAtivo>{checarStatus(linhaConfiguracaoGeral.ativo)}</BoxAtivo>
          ) : (
            <BoxInativo>{checarStatus(linhaConfiguracaoGeral.ativo)}</BoxInativo>
          )}
        </TableCell>
      </LinhaTabela>
      <LinhaHistorico>
        <CelulaHistorico colSpan={6}>
          <Collapse in={_io.abrirModal} timeout="auto" unmountOnExit>
            <BoxHistorico>
              <Tipografia gutterBottom variant="subtitle2">
                Histórico
              </Tipografia>

              {linhaConfiguracaoGeral?.dataCadastro && (
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Grid item xs={12}>
                      <Tipografia variant="subtitle2">Cadastramento</Tipografia>
                    </Grid>
                    <Grid item xs={12}>
                      <Tipografia variant="subtitle2">
                        {`${formateDate(linhaConfiguracaoGeral.dataCadastro, true)} - ${linhaConfiguracaoGeral?.usuarioCadastro ?? '-'}`}
                      </Tipografia>
                    </Grid>
                  </Grid>
                  {sequenciaFinalizadaDataDesativacao}
                </Grid>
              )}
            </BoxHistorico>
          </Collapse>
        </CelulaHistorico>
      </LinhaHistorico>
    </>
  )
}
