import { DadosMeses } from '../utils/tratarDadosMesesUtils'
import { BaseClassificacao, Processamento, ResponsePaginado } from './genericoType'
import { Paginacao } from './tabelaTypes'

export interface ControleComissaoPaginadoRequest extends Paginacao {
  listaStatus?: number[]
  parceirosIds?: number[]
  search?: string
}

export type ControleComissaoStatusResumo = {
  status: number
  quantidade: number
}

export type ControleComissaoPaginado = {
  pageNumber: number
  pageSize: number
  totalItens: number
  totalPages: number
  itens: ControleComissao[]
}

export type ControleComissao = {
  parceiro?: ControleComissaoParceiro
  cicloId: number
  comissaoCobrada: number
  comissaoDevida: number
  diferenca: number
  urlRelatorio: string
  listaStatus: historicoControleComissao[]
  statusAtual: number
  id: string
  createdAt: string
}

export type ControleComissaoChamados = {
  controleCicloId: string
  numeroChamado: string
  statusAtual: number
  chamados: Chamados[]
  id: string
  createdAt: string
}

export type Chamados = {
  observacao: string
  data: string
  funcionario: ControleComissaoFuncionario
}

export type historicoControleComissao = {
  status: number
  dataAlteracao: Date
  funcionario?: ControleComissaoFuncionario
}

type ControleComissaoParceiro = {
  id: number
  descricao: string
}

type ControleComissaoFuncionario = {
  id: number
  nome: string
}

export type ControleComissaoStatusForm = {
  status: number
}

export type AdicionarChamadoForm = {
  numeroChamado: string
  descricao: string
}

export type EditarChamadoForm = {
  status: number
  descricao: string
}

export enum ControleComissaoStatus {
  Concluido = 1,
  EmAndamento = 2,
  Pendente = 3
}

export enum ControleComissaoChamadoStatus {
  Fechado = 1,
  EmAndamento = 2
}

export const ControleComissaoStatusMap = new Map<ControleComissaoStatus, string>([
  [ControleComissaoStatus.Concluido, 'Concluído'],
  [ControleComissaoStatus.EmAndamento, 'Em Andamento'],
  [ControleComissaoStatus.Pendente, 'Pendente']
])

export const ControleComissaoChamadoStatusMap = new Map<ControleComissaoChamadoStatus, string>([
  [ControleComissaoChamadoStatus.Fechado, 'Fechado'],
  [ControleComissaoChamadoStatus.EmAndamento, 'Em Andamento']
])

export type ObterControleComissaoPedidosFiltros = {
  dataLancamentoFim: string
  dataLancamentoInicio: string
  pedidoMkt: string
  classificacao: string[]
}

export interface ObterControleComissaoPedidosQuery extends Paginacao, ObterControleComissaoPedidosFiltros {}

export type ClassificacaoControleComissaoPedidos = BaseClassificacao &
  ({ name: 'Em Andamento'; value: 2 } | { name: 'Pendente'; value: 1 | null } | { name: 'Concluido'; value: 0 })

export type ObterControleComissaoPedidosResponse = ResponsePaginado<ControleComissaoPedidoResponse>

export type ControleComissaoPedidoResponse = {
  parceiroId: number
  pedidoPlanilhaId: string
  comissaoCobradaTotal: number
  comissaoDevidaTotal: number
  diferencaTotal: number
  parceiro: string
  valorTotalRecebido: number
  valorTotalPedido: number
  classificacaoGeral: string
  dataEmissao: string
  detalhes: DetalheControleComissaoPedidoResponse[]
}

export type DetalheControleComissaoPedidoResponse = {
  posicaoPlanilha: number
  parcela: number
  pedidoPlanilhaId: string
  parceiroId: number
  comissaoCobrada: number
  comissaoDevida: number
  diferenca: number
  cicloId: number
  percentualCobrado: number
  percentualDevido: number
  mercadoriaId: number
  classificacaoStatus: number | null
  valorRecebido: number
  dataProcessamentoCiclo: string
  tipoComissao: string
  descricaoParceiro: string
  historicoPedidoComissao: HistoricoControleComissaoPedidoResponse[]
}

export type HistoricoControleComissaoPedidoResponse = {
  nomeFuncionario: string | null
  pedidoPlanilhaId: string
  cicloId: number
  mercadoriaId: number
  parcela: number
  dataAlteracao: string
  funcionarioId: number
  observacao: string
}

export type AtualizaPedidosComissaoRequest = {
  classificacaoStatus?: number
  observacao: string
} & DetalheComissaoPedidoChaves

export type DetalheComissaoPedidoChaves = {
  pedidoPlanilhaId: string
  parceiroId: number
  posicaoPlanilha: number
  mercadoriaId: number
  cicloId: number
  parcela: number
}

export type ResumosPedidoComissaoParceiro = {
  dataReferencia: Date
  parceiroId: number
  processamento: Processamento
  dados: ResumosPedidoComissaoParceiroDados
}

export type ResumosPedidoComissaoParceiroDados = {
  total6Meses: number
  quantidade6Meses: number
  total3Meses: number
  quantidade3Meses: number
  urlRelatorio: string
  dadosMensais: [DadosMeses]
}
