import { IOProps } from 'react-compose-io'
import { DownloadRelatorio } from '../../../../components/DownloadRelatorio'
import { CardTitulosParceiro } from '../../../../types/titulosTypes'
import { ItemListaParceirosIO } from './ItemListaParceiros.IO'

export type ItemListaParceirosProps = {
  tituloParceiro: CardTitulosParceiro
  data: string
  exportarRelatorioParceiro: (data: string, parceiroId: number) => void
  titulosAbertos: boolean
}

export function ItemListaParceirosView({
  _io,
  titulosAbertos,
  data,
  tituloParceiro,
  exportarRelatorioParceiro
}: IOProps<ItemListaParceirosIO, ItemListaParceirosProps>) {
  const {
    ContainerColunaAlinhado,
    SubtituloItem,
    TituloParceiro,
    ContainerColuna,
    ContainerLinha,
    ContainerDadosParceiro,
    TituloCard,
    SubtituloParceiro,
    ValorData
  } = _io.styles

  return (
    <ContainerDadosParceiro>
      <ContainerColuna>
        {titulosAbertos && <ValorData>{_io.dataFormatada}</ValorData>}
        <ContainerLinha>
          {_io.itemListaParceiros().map((itemParceiros, index) => (
            <ContainerColunaAlinhado key={index}>
              <TituloCard>{itemParceiros.titulo}</TituloCard>
              <ContainerLinha>
                <ContainerColuna>
                  <SubtituloItem>Valor total</SubtituloItem>
                  <TituloParceiro>{itemParceiros.valor}</TituloParceiro>
                </ContainerColuna>
                <ContainerColuna>
                  <SubtituloItem>Qtd. de títulos</SubtituloItem>
                  <SubtituloParceiro>{itemParceiros.quantidade.toLocaleString('pt-BR')}</SubtituloParceiro>
                </ContainerColuna>
              </ContainerLinha>
            </ContainerColunaAlinhado>
          ))}
        </ContainerLinha>
      </ContainerColuna>
      <DownloadRelatorio
        textoTooltip={`Exportar relatório do parceiro ${tituloParceiro?.parceiroNome} na data ${data}`}
        relatorioDisponivel={_io.relatorioDisponivel}
        iconeCarregandoRelatorio={_io.carregandoRelatorio}
        url={tituloParceiro?.urlRelatorio}
        gerarRelatorio={() => {
          exportarRelatorioParceiro(data, tituloParceiro.parceiroId)
        }}
      />
    </ContainerDadosParceiro>
  )
}
