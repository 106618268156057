import { LOADING_RESET, LOADING_UPDATE } from '../actions/loadingAction'

const initialState = {
  show: false
}

const LoadingReducer = (state = initialState, action: { type: unknown; payload: boolean }) => {
  switch (action.type) {
    case LOADING_UPDATE:
      return { ...state, show: action.payload }
    case LOADING_RESET:
      return initialState
    default:
      return state
  }
}

export default LoadingReducer
