import MaterialTable, { Filter } from 'material-table'
import React from 'react'
import { IOProps } from 'react-compose-io'
import { customTheme } from '../../styles/customTheme'
import { ItemLancamentoNaoAssociado } from '../../types/ciclosApiType'
import { Order } from '../../types/tabelaTypes'
import { tableIcons } from './icons'
import { TabelaEditavelIO } from './TabelaEditavel.IO'

export type TabelaEditavelProps = {
  lancamentoNaoAssociado: ItemLancamentoNaoAssociado[]
  setCheckboxSelecionado: React.Dispatch<React.SetStateAction<string[]>>
  pagina: number
  opcoesNumerosPaginacao: number[]
  onMudancaLinhasPorPagina: (numero: number) => void
  onMudancaPorPagina: (newPage: number, pageSize: number) => void
  totalLinhas: number
  textoFiltro: any
  onOrdernarTabela: (order: number, tipoOrdernacao: Order) => void | undefined
  onFiltrarCaixaSelecao: (filters: Filter<ItemLancamentoNaoAssociado>[]) => void | undefined
  editarLancamentoVariaveisItem: (itemLancamentoNaoAssociado: ItemLancamentoNaoAssociado[]) => Promise<void>
}

export function TabelaEditavelView({
  _io,
  lancamentoNaoAssociado,
  setCheckboxSelecionado,
  pagina,
  opcoesNumerosPaginacao,
  onMudancaLinhasPorPagina,
  onMudancaPorPagina,
  totalLinhas,
  onOrdernarTabela,
  editarLancamentoVariaveisItem,
  onFiltrarCaixaSelecao
}: IOProps<TabelaEditavelIO, TabelaEditavelProps>) {
  return (
    <MaterialTable
      title=""
      style={{
        backgroundColor: `${customTheme.colors.red00}`,
        boxShadow: 'none'
      }}
      columns={_io.colunaLancamentoVariaveis}
      data={lancamentoNaoAssociado}
      icons={tableIcons}
      totalCount={totalLinhas}
      options={{
        selection: true,
        search: false,
        filtering: true,
        rowStyle: {
          backgroundColor: `${customTheme.colors.red00}`,
          color: `${customTheme.colors.grey09}`
        },
        headerStyle: {
          backgroundColor: `${customTheme.colors.red00}`,
          color: `${customTheme.colors.grey09}`,
          textAlign: 'center',
          padding: '16px',
          fontFamily: `${customTheme.fontFamily.rubik500}`,
          fontWeight: 500,
          fontSize: `${customTheme.fontSize[12]}`,
          lineHeight: '20px'
        },
        actionsCellStyle: {
          color: `${customTheme.colors.grey09}`
        },
        toolbar: false,
        showTitle: false,
        actionsColumnIndex: -1,
        pageSizeOptions: opcoesNumerosPaginacao,
        pageSize: 10,
        paging: true,
        emptyRowsWhenPaging: false,
        paginationType: 'normal',
        showSelectAllCheckbox: true
      }}
      localization={{
        header: {
          actions: ''
        },
        pagination: {
          labelDisplayedRows: '{from}-{to} de {count}',
          labelRowsSelect: 'Linhas por página:'
        }
      }}
      onSelectionChange={(data) => {
        const filtrarData = data.map((lancamentos) => lancamentos.numero.toString())
        setCheckboxSelecionado(filtrarData)
      }}
      onChangeRowsPerPage={onMudancaLinhasPorPagina}
      onChangePage={onMudancaPorPagina}
      onOrderChange={onOrdernarTabela}
      onFilterChange={onFiltrarCaixaSelecao}
      page={pagina}
      editable={{
        onRowUpdate: (newData: ItemLancamentoNaoAssociado, oldData?: ItemLancamentoNaoAssociado) => {
          return new Promise((resolve) => {
            const dataUpdate = [...lancamentoNaoAssociado]
            const target = dataUpdate.find((el) => el.numero == oldData?.numero)
            const editarLancamento = target != undefined ? target : newData
            const index = dataUpdate.indexOf(editarLancamento)
            dataUpdate[index] = newData
            editarLancamentoVariaveisItem([newData])
            resolve(newData)
          })
        }
      }}
    />
  )
}
