import { IOProps } from 'react-compose-io'
import { PAGES } from '../../../../router/routes'
import { RegularizacaoCicloParceiro } from '../../../../types/regularizacaoOPsTitulosTypes'
import { ItemCicloPendenteListaIO } from './ItemCicloPendenteLista.IO'

export type ItemCicloPendenteListaProps = {
  valorCiclo: RegularizacaoCicloParceiro
}

export function ItemCicloPendenteListaView({ _io, valorCiclo }: IOProps<ItemCicloPendenteListaIO, ItemCicloPendenteListaProps>) {
  const { ContainerItemCiclo, ContainerPedidos, TituloLista, SubtituloLista, Link } = _io.styles

  return (
    <>
      {valorCiclo.ciclosPendentesParceiros &&
        valorCiclo.ciclosPendentesParceiros.map((cicloPendente, indexCicloPendente) => (
          <Link to={PAGES.informacaoId(cicloPendente.cicloId.toString(), null, null, null)} key={indexCicloPendente}>
            <ContainerItemCiclo key={indexCicloPendente}>
              <ContainerPedidos>
                <TituloLista>{_io.tituloCiclos}</TituloLista>
                <SubtituloLista>#{cicloPendente.cicloId.toLocaleString('pt-BR')}</SubtituloLista>
              </ContainerPedidos>
              <ContainerPedidos>
                <TituloLista>{_io.tituloOps}</TituloLista>
                <SubtituloLista>{cicloPendente.totalOpsPendentes.toLocaleString('pt-BR')}</SubtituloLista>
              </ContainerPedidos>
            </ContainerItemCiclo>
          </Link>
        ))}
    </>
  )
}
