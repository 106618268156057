import styled from 'styled-components'
import { ContainerSombra } from '../../components/ContainerSombra'

export const regularizacaoCiclosResumoStyles = {
  ContainerLista: styled(ContainerSombra)`
    flex-grow: 1;
    padding: 16px;
    margin-top: 8px;
  `,
  ContainerBranco: styled(ContainerSombra)`
    margin: 20px 0;
    padding: 8px;
  `
}
