import { CircularProgress, Divider, Grid } from '@material-ui/core'
import { IOProps } from 'react-compose-io'
import ErroInformacao from '../../assets/icons/erro.svg'
import { customTheme } from '../../styles/customTheme'
import { StatusProcessamento } from '../../types/genericoType'
import { HistoricoRegularizacao } from '../../types/regularizacaoTypes'
import { formateDate } from '../../utils/stringUtils'
import { ItensRegularizacaoContaResumo } from '../ItensRegularizacaoContaResumo'
import { ItemHistoricoRegularizacaoIO } from './ItemHistoricoRegularizacao.IO'
import { BotaoIconeExportar } from '../BotaoIconeExportar/BotaoIconeExportar'

export type ItemHistoricoRegularizacaoProps = {
  historico: HistoricoRegularizacao
  descricaoBaixas: string
}

export function ItemHistoricoRegularizacaoView({ _io, historico, descricaoBaixas }: IOProps<ItemHistoricoRegularizacaoIO, ItemHistoricoRegularizacaoProps>) {
  const {
    InformacoesUsuario,
    ContainerUsuario,
    Container,
    ContainerBotaoExportar,
    ContainerDadosUsuario,
    ContainerIcone,
    ContainerTexto,
    Subtitulo,
    TituloCarregando,
    IconInfo,
    TituloErro
  } = _io.styles

  return (
    <Container>
      <Grid container spacing={1}>
        <ContainerDadosUsuario>
          <ContainerUsuario>
            <InformacoesUsuario>{formateDate(historico.historico.dataReferencia, true)}</InformacoesUsuario>
            <InformacoesUsuario>{historico.historico.funcionario.nome}</InformacoesUsuario>
          </ContainerUsuario>
          <Divider orientation="vertical" />
        </ContainerDadosUsuario>
        {historico.processamento.status == StatusProcessamento.CONCLUIDO && (
          <>
            <Grid xs={11} sm={11} md={10} lg={10} item>
              <ItensRegularizacaoContaResumo corFundo={customTheme.colors.blue09} descricaoBaixas={descricaoBaixas} contasResumos={historico.contas} />
            </Grid>
            <ContainerBotaoExportar>
              <BotaoIconeExportar url={historico.urlRelatorio} />
            </ContainerBotaoExportar>
          </>
        )}
        {historico.processamento.status == StatusProcessamento.PROCESSANDO && (
          <ContainerIcone>
            <CircularProgress />
            <ContainerTexto>
              <TituloCarregando>Processando...</TituloCarregando>
              <Subtitulo>{historico.arquivoId}</Subtitulo>
            </ContainerTexto>
          </ContainerIcone>
        )}
        {historico.processamento.status == StatusProcessamento.NOVO && (
          <ContainerIcone>
            <IconInfo src={ErroInformacao} />
            <ContainerTexto>
              <TituloErro>Erro ao processar</TituloErro>
              <Subtitulo>Faça o upload da planilha novamente</Subtitulo>
              <Subtitulo>{historico.arquivoId}</Subtitulo>
            </ContainerTexto>
          </ContainerIcone>
        )}
      </Grid>
    </Container>
  )
}
