import { Grid } from '@material-ui/core'
import { IOProps } from 'react-compose-io'
import { AntTab } from '../../components/AntTab/AntTab.style'
import { AntTabs } from '../../components/AntTabs/AntTabs.styles'
import TabPanel from '../../components/TabPainel/TabPanel'
import { TabStatus } from './components/TabStatus/index'
import { TabTransformacao } from './components/TabTransformacao'
import { ParametrosCicloPedidoIO } from './ParametrosCicloPedido.IO'
import { InformacaoParceiro } from '../../components/InformacaoParceiro'
import FileCopyOutlined from '@material-ui/icons/FileCopyOutlined'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import { TooltipComponent } from '../../components/Tooltip/Tooltip'
import { ContainerSombra } from '../../components/ContainerSombra'

export type ParametrosCicloPedidoProps = {}

export default function ParametrosCicloPedidoView({ _io }: IOProps<ParametrosCicloPedidoIO, ParametrosCicloPedidoProps>) {
  const { ContainerCabecalho, ContainerCircle, Titulo } = _io.styles

  return (
    <>
      <InformacaoParceiro />

      <ContainerCabecalho>
        <Grid container direction="row" alignItems="center" spacing={2}>
          <Grid item>
            <ContainerCircle>
              <FileCopyOutlined color="primary" fontSize="medium" />
            </ContainerCircle>
          </Grid>
          <Grid item>
            <Titulo>Parâmetros - Ciclo de pedido</Titulo>
          </Grid>
          <Grid item>
            <TooltipComponent
              texto={`Os parâmetros a seguir definem como o sistema processará o relatório de Ciclo de Pedido desse parceiro.`}
              Icone={HelpOutlineIcon}
              color={'action'}
            />
          </Grid>
        </Grid>
      </ContainerCabecalho>

      <ContainerSombra>
        <AntTabs value={_io.aba} onChange={_io.handleChange} aria-label="abas filtro de parceiros">
          <AntTab label="Lista de Status" />
          <AntTab label="Transformar" />
        </AntTabs>
        <TabPanel value={_io.aba} index={0}>
          <TabStatus />
        </TabPanel>
        <TabPanel value={_io.aba} index={1}>
          <TabTransformacao />
        </TabPanel>
      </ContainerSombra>
    </>
  )
}
