import { applyMiddleware, compose, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'
import rootReducer from './reducers'

const middleware = [thunk]

const composeEnhancers = composeWithDevTools(applyMiddleware(...middleware)) || compose

const store = createStore(rootReducer, composeEnhancers)

export {}
export default store
