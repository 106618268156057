import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import * as yup from 'yup'
import msgConstants from '../../../../constants/msgConstants'
import { LancamentoState } from '../../../../interfaces/interfaces'
import { closeModalEditar, efetivarEdicao, lancamentoUpdate } from '../../../../store/actions/lancamentoAction'
import { FormularioTipoLancamento, semSequencia, Sequencias, TipoLancamento } from '../../../../types/tipoLancamentoApiTypes'
import { estaVazio } from '../../../../utils/stringUtils'
import { modalEditarStyles } from './ModalEditar.style'
import { ModalEditarProps } from './ModalEditar.View'

const schema = yup.object().shape({
  descricao: yup.string().required(msgConstants.USO_GENERICO.campo_obrigatorio),
  sequencia: yup.string().required(msgConstants.USO_GENERICO.campo_obrigatorio),
  referencia: yup.string().required(msgConstants.USO_GENERICO.campo_obrigatorio)
})

export function modalEditarIO({ abrirModal, setAbrirModal }: ModalEditarProps) {
  const dispatch = useDispatch()
  const lancamento = useSelector((state: LancamentoState) => state.lancamento.lancamentoObj)
  const [lancamentoEditado, setLancamentoEditado] = useState<boolean>(false)
  const [tipoLancamento, setTipoLancamento] = useState<TipoLancamento>(lancamento)

  const {
    handleSubmit,
    control,
    setValue,
    reset,
    formState: { errors }
  } = useForm<FormularioTipoLancamento>({
    resolver: yupResolver(schema),
    defaultValues: useMemo(() => {
      const { descricao, conta, referencia, natureza, tipo } = lancamento
      const sequencia = estaVazio(lancamento.sequencia) ? semSequencia.sequencia : lancamento.sequencia
      return { descricao, sequencia, conta, natureza, tipo, referencia }
    }, [lancamento])
  })

  const submeterDadosFormulario = (data: FormularioTipoLancamento) => {
    setTipoLancamento({
      ...tipoLancamento,
      descricao: data.descricao,
      sequencia: data.sequencia,
      conta: data.conta,
      natureza: data.natureza,
      tipo: data.tipo,
      referencia: data.referencia
    })
    setLancamentoEditado(true)
    setAbrirModal(false)
  }

  useEffect(() => {
    if (lancamentoEditado) {
      reset()
      setLancamentoEditado(false)
      dispatch(lancamentoUpdate(tipoLancamento))
      dispatch(efetivarEdicao())
      dispatch(closeModalEditar())
    }
  }, [tipoLancamento, lancamentoEditado, dispatch, reset])

  const selecionarSequenciaFormulario = (sequenciaItem: Sequencias) => {
    setValue('sequencia', sequenciaItem.sequencia ?? '')
    setValue('conta', '' + sequenciaItem.contaContabilId ?? '')
    setValue('natureza', sequenciaItem.tipoNaturezaContabil ?? '')
    setValue('tipo', sequenciaItem.tipoAgrupamentoContabil ?? '')
  }

  useEffect(() => {
    if (abrirModal) {
      const { descricao, conta, referencia, natureza, tipo } = lancamento
      const sequencia = estaVazio(lancamento.sequencia) ? semSequencia.sequencia : lancamento.sequencia
      reset({ descricao, sequencia, conta, natureza, tipo, referencia })

      setTipoLancamento(lancamento)
    }
  }, [lancamento, abrirModal, reset])

  const fecharModalEdicao = () => {
    setTipoLancamento({ ...tipoLancamento, descricao: '', sequencia: '', conta: '', natureza: '', tipo: '', referencia: '' })
    setAbrirModal(false)
  }

  return {
    styles: modalEditarStyles,
    control,
    errors,
    fecharModalEdicao,
    selecionarSequenciaFormulario,
    submeterDadosFormulario,
    handleSubmit,
    tipoLancamento
  }
}

export type ModalEditarIO = ReturnType<typeof modalEditarIO>
