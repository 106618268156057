import { Box, Grid } from '@material-ui/core'
import { IOProps } from 'react-compose-io'
import { Tipografia } from '../../../../components/Tipografia'
import { customTheme } from '../../../../styles/customTheme'
import { Parceiro } from '../../../../types/parceiroApiTypes'
import { formateDate } from '../../../../utils/stringUtils'
import { HistoricoParceiroIO } from './HistoricoParceiro.IO'

export type HistoricoParceiroProps = {
  parceiroData?: Parceiro
}

export function HistoricoParceiroView({ _io, parceiroData }: IOProps<HistoricoParceiroIO, HistoricoParceiroProps>) {
  const { Container } = _io.styles
  return (
    <Container>
      <Grid container spacing={0}>
        <Tipografia fontSize={customTheme.fontSize[18]}>Histórico</Tipografia>

        {parceiroData?.dataCadastro != null && (
          <Grid item xs={12}>
            <Box>
              <Grid item xs={12}>
                <Tipografia variant="subtitle2">Cadastro de parceiro</Tipografia>
              </Grid>
              <Grid item xs={12}>
                <Tipografia variant="body2">{`${formateDate(parceiroData?.dataCadastro, true)} - ${parceiroData.usuarioCadastro || ''}`}</Tipografia>
              </Grid>
            </Box>
          </Grid>
        )}
        {parceiroData?.dataEdicao != null && (
          <Grid item xs={12}>
            <Box>
              <Grid item xs={12}>
                <Tipografia variant="subtitle2">Ultima edição de parceiro</Tipografia>
              </Grid>
              <Grid item xs={12}>
                {formateDate(parceiroData?.dataEdicao, true)} - {parceiroData.usuarioEdicao || ''}
              </Grid>
            </Box>
          </Grid>
        )}
      </Grid>
    </Container>
  )
}
