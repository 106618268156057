import { IOProps } from 'react-compose-io'
import { HistoricoRegularizacao } from '../../components/HistoricoRegularizacao'
import { ModalValidacaoPlanilhaRegularizacao } from '../../components/ModalValidacaoPlanilhaRegularizacao'
import { UploadPlanilhaRegularizacao } from '../../components/UploadPlanilhaRegularizacao'
import { RegularizacaoOPsIO } from './RegularizacaoOPs.IO'
import { Tipografia } from '../../components/Tipografia'
import { OpsEmAbertoResumo } from './components/OpsEmAbertoResumo'

export type RegularizacaoOPsProps = {}

export function RegularizacaoOPsView({ _io }: IOProps<RegularizacaoOPsIO, RegularizacaoOPsProps>) {
  return (
    <>
      <Tipografia variant="h5">{"Regularização de OP's"}</Tipografia>
      <OpsEmAbertoResumo />
      <UploadPlanilhaRegularizacao
        validarPlanilha={_io.validarPlanilha}
        subtitulo="Faça o upload da planilha para fazer a baixa de OP's."
        titulo={"Upload planilha de baixa de OP's"}
        planilhaPadraoTexto={"Baixe a planilha padronizada antes de começar a importação. A lista de OP's deve estar no formato da planilha padrão."}
        planilhaPadraoUrl={process.env.REACT_APP_PLANILHA_PADRAO_REGULARIZACAO_OPS}
        setArquivoId={_io.setArquivoId}
      />
      <ModalValidacaoPlanilhaRegularizacao
        abrirModalValidarPlanilha={_io.abrirModalValidarPlanilha}
        handleFecharModalValidarPlanilha={_io.handleFecharModalValidarPlanilha}
        handleConfirmarDados={() => _io.efetivarRegularizacaoOPs()}
        itemListaValidado={_io.informacaoValidadaModal}
        mensagemErroUploadPlanilha={_io.mensagemErroUploadPlanilha}
        descricaoBaixas={"OP's:"}
      />
      <HistoricoRegularizacao
        historicosRegularizacao={_io.historicosRegularizacao.itens}
        totalItens={_io.totalLinhas}
        buscarHistorico={_io.atualizarHistoricoRegularizacaoOPs}
        valorSetTimeOut={_io.valorSetTimeOut}
        descricaoBaixas={"OP's baixadas:"}
      />
    </>
  )
}
