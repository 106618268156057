import { Menu, TableCell, TableRow } from '@material-ui/core'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { ReactNode } from 'react'
import { IOProps } from 'react-compose-io'
import { EditarItemTabela } from '../../../../components/EditarItemTabela/EditarItemTabela'
import IconeButton from '../../../../components/IconeButton/IconeButton'
import { customTheme } from '../../../../styles/customTheme'
import { ObterListaStatusParceiroResponse } from '../../../../types/parceiroApiTypes'
import { StatusEfacil } from '../../../../types/statusEfacilTypes'
import { StatusTracking } from '../../../../types/statusTrackingTypes'
import { ModalTipoStatus } from '../ModalTipoStatus'
import { ItemStatusIO } from './ItemStatus.IO'

export type ItemStatusProps = {
  linhaItemStatusParceiro: ObterListaStatusParceiroResponse
  indexLinhaItemStatus: number
  parceiroId: string
  atualizarDados: () => void
  statusTrackingList: StatusTracking[]
  statusEfacilList: StatusEfacil[]
}

export function ItemStatusView({
  _io,
  linhaItemStatusParceiro,
  indexLinhaItemStatus,
  atualizarDados,
  statusTrackingList,
  statusEfacilList
}: IOProps<ItemStatusIO, ItemStatusProps>) {
  const { TextoTabela, ChipStatus, ContainerChips } = _io.styles

  return (
    <>
      <ModalTipoStatus
        formularioPreenchido={linhaItemStatusParceiro}
        isShow={_io.abrirModalEditar}
        isClosed={_io.fecharModalEdicao}
        atualizarDados={atualizarDados}
        statusTrackingList={statusTrackingList}
        statusEfacilList={statusEfacilList}
      />
      <TableRow key={indexLinhaItemStatus}>
        <TableCell align="center">
          <TextoTabela>{linhaItemStatusParceiro.nomeStatusParceiro}</TextoTabela>
        </TableCell>
        <TableCell align="center">
          <ContainerChips>
            {linhaItemStatusParceiro.listaStatusEfacil.map((item) => (
              <ChipStatus
                colorfont={customTheme.colors.laranja08}
                background={customTheme.colors.laranja01}
                label={item.codigo + ' - ' + item.nome}
                key={item.codigo}
              />
            ))}
          </ContainerChips>
        </TableCell>
        <TableCell align="center">
          <ContainerChips>
            {linhaItemStatusParceiro.listaStatusTracking.map((item) => (
              <ChipStatus colorfont={customTheme.colors.primary} background={customTheme.colors.blue01} label={item.nome} key={item.codigo} />
            ))}
          </ContainerChips>
        </TableCell>
        <TableCell align="center">
          <TextoTabela>{linhaItemStatusParceiro.verificarOcorrencia ? 'Ativo' : 'Inativo'}</TextoTabela>
        </TableCell>
        <TableCell align="center">
          <TextoTabela>{linhaItemStatusParceiro.ativo ? 'Ativo' : 'Inativo'}</TextoTabela>
        </TableCell>

        <TableCell align="center">
          <IconeButton color="inherit" descricao="ações" icone={<MoreVertIcon />} acao={_io.handleAtivarIconeAncora} />
          <Menu id="simple-menu" anchorEl={_io.ativarIconeAncora} keepMounted open={Boolean(_io.ativarIconeAncora)} onClose={_io.handleFecharIconeAncora}>
            {EditarItemTabela(linhaItemStatusParceiro, _io.handleAtivarIconeAncoraModalEdicao, _io.desativarStatusParceiro).map((itemMenu: ReactNode) => {
              return itemMenu
            })}
          </Menu>
        </TableCell>
      </TableRow>
    </>
  )
}
