import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import msgConstants from '../../constants/msgConstants'
import transformacaoManager from '../../services/api/transformacao/transformacaoManager'
import { loadingReset, loadingUpdate } from '../../store/actions/loadingAction'
import { RegraTransformacao } from '../../types/transformacaoApiTypes'

export function regraTransformacaoIO() {
  const { parceiroId, regraId } = useParams<{ parceiroId: string; regraId: string }>()
  const [regraTransformacaoEditar, setRegraTransformacaoEditar] = useState<RegraTransformacao>()
  const dispatch = useDispatch()

  useEffect(() => {
    const buscarRegraAssociacao = async () => {
      if (parceiroId && regraId) {
        dispatch(loadingUpdate(true))
        transformacaoManager
          .buscarRegra(parceiroId, regraId)
          .then((response: RegraTransformacao) => {
            setRegraTransformacaoEditar(response)
          })
          .catch(() => toast.error(msgConstants.USO_GENERICO.erroBusca))
          .finally(() => dispatch(loadingReset()))
      }
    }

    buscarRegraAssociacao()
  }, [dispatch, parceiroId, regraId])

  return {
    regraTransformacaoEditar,
    regraId
  }
}

export type RegraTransformacaoIO = ReturnType<typeof regraTransformacaoIO>
