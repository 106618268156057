import { Typography } from '@material-ui/core'
import { statusProgressStyles } from './StatusProgress.style'

type IStatusProgressProps = {
  etapaState?: string
  valueState?: number
  progress?: number
}

export function StatusProgress({ etapaState, valueState, progress }: IStatusProgressProps) {
  const { CustomLinearProgress, Container, BoxContainer, BoxMinWidth, BoxLine } = statusProgressStyles

  return (
    <Container>
      <BoxContainer>
        <BoxLine>
          <CustomLinearProgress variant="determinate" value={valueState} etapastate={etapaState} />
        </BoxLine>
        <BoxMinWidth>
          <Typography variant="body2" color="textPrimary">{`${progress}%`}</Typography>
        </BoxMinWidth>
      </BoxContainer>
    </Container>
  )
}
