import { Typography } from '@material-ui/core'
import styled from 'styled-components'

export const modalControleComissaoStatusStyles = {
  Container: styled.div`
    text-align: justify;
  `,
  Typography: styled(Typography).attrs({
    variant: 'subtitle1'
  })`
    margin-bottom: 16px;
    text-align: justify;
  `
}
