import { ValorGerados, ValorGeralTitulosAbertos, ValorLiquidados } from '../../../types/titulosTypes'
import { concatenarFiltrosQuery } from '../../../utils/stringUtils'
import api from '../../axiosInstance'

const buscarTitulosAbertos = async (dataSelecionada: string, attManual: boolean): Promise<ValorGeralTitulosAbertos> => {
  const URL_TITULOS_ABERTOS = `/ControleTitulos/Abertos?dataReferencia=${dataSelecionada}&attManual=${attManual}`
  const response = await api.conectarGet<ValorGeralTitulosAbertos>(URL_TITULOS_ABERTOS)
  return response.data
}

const buscarTitulosLiquidados = async (dataInicio: string, dataFim: string, attManual: boolean): Promise<ValorLiquidados> => {
  const URL_TITULOS_LIQUIDADOS = `/ControleTitulos/Liquidados?dataInicio=${dataInicio}&dataFim=${dataFim}&attManual=${attManual}`
  const response = await api.conectarGet<ValorLiquidados>(URL_TITULOS_LIQUIDADOS)
  return response.data
}

const buscarTitulosGerados = async (dataInicio: string, dataFim: string, attManual: boolean): Promise<ValorGerados> => {
  const URL_TITULOS_GERADOS = `/ControleTitulos/Gerados?dataInicio=${dataInicio}&dataFim=${dataFim}&attManual=${attManual}`
  const response = await api.conectarGet<ValorGerados>(URL_TITULOS_GERADOS)
  return response.data
}

const exportarTitulosAbertos = async (dataReferencia: string, parceiroId?: number): Promise<void> => {
  const URL_TITULOS_ABERTOS_RELATORIOS = `/ControleTitulos/Relatorio/Abertos${concatenarFiltrosQuery({
    dataReferencia: dataReferencia,
    parceiroId: parceiroId
  })}`
  await api.conectarPost<ValorGeralTitulosAbertos>(URL_TITULOS_ABERTOS_RELATORIOS)
}

const exportarTitulosLiquidados = async (dataInicio: string, parceiroId?: number, dataFim?: string): Promise<void> => {
  const URL_TITULOS_LIQUIDADOS_RELATORIO = `/ControleTitulos/Relatorio/Liquidados${concatenarFiltrosQuery({
    dataInicio: dataInicio,
    parceiroId: parceiroId,
    dataFim: dataFim
  })}`
  await api.conectarPost<ValorGeralTitulosAbertos>(URL_TITULOS_LIQUIDADOS_RELATORIO)
}

const exportarTitulosGerados = async (dataInicio: string, parceiroId?: number, dataFim?: string): Promise<void> => {
  const URL_TITULOS_GERADOS_RELATORIOS = `/ControleTitulos/Relatorio/Gerados${concatenarFiltrosQuery({
    dataInicio: dataInicio,
    parceiroId: parceiroId,
    dataFim: dataFim
  })}`
  await api.conectarPost<ValorGeralTitulosAbertos>(URL_TITULOS_GERADOS_RELATORIOS)
}

export default {
  buscarTitulosAbertos,
  exportarTitulosAbertos,
  buscarTitulosLiquidados,
  exportarTitulosLiquidados,
  buscarTitulosGerados,
  exportarTitulosGerados
}
