import { IOProps } from 'react-compose-io'
import { BotaoIO } from './Botao.IO'
import { ButtonProps } from '@material-ui/core'
import { ReactElement } from 'react'

export interface BotaoProps extends ButtonProps {
  textoBotao: string | JSX.Element
  icone?: ReactElement
  iconeDireita?: boolean
  estilo?: 'text' | 'contained' | 'outlined' | 'rounded'
  tamanho?: 'pequeno' | 'medio' | 'grande'
  cor?: 'primaria' | 'erro' | 'sucesso' | string
  width?: string
  margin?: string
}

export function BotaoView({ _io, margin, textoBotao, iconeDireita, tamanho, estilo, icone, width, ...rest }: IOProps<BotaoIO, BotaoProps>) {
  const { Button, ContainerIconeTexto } = _io.styles
  return (
    <>
      {iconeDireita ? (
        <Button tamanho={tamanho} estilo={estilo} cor={_io.corBotao} width={width} {...rest}>
          <ContainerIconeTexto>
            {icone}
            {textoBotao}
          </ContainerIconeTexto>
        </Button>
      ) : (
        <Button margin={margin} tamanho={tamanho} estilo={estilo} cor={_io.corBotao} width={width} {...rest}>
          <ContainerIconeTexto>
            {textoBotao}
            {icone}
          </ContainerIconeTexto>
        </Button>
      )}
    </>
  )
}
