import { Divider, Typography } from '@material-ui/core'
import styled from 'styled-components'
import { ContainerSombra } from '../../../../components/ContainerSombra'
import { customTheme } from '../../../../styles/customTheme'

export const emTransitoInadimplenciaStyles = {
  Box: styled(ContainerSombra)`
    padding: 16px;
    margin-top: 32px;
  `,
  Titulo: styled(Typography)`
    display: flex;
    align-items: center;
    font-size: ${customTheme.fontSize[18]};
    font-weight: 500;
    color: ${customTheme.colors.grey23};
    margin-bottom: 24px;
  `,
  Divider: styled(Divider)`
    width: 90%;
    margin: 32px auto;
  `
}
