import { IOProps } from 'react-compose-io'
import { RelatorioConciliacaoIO } from './RelatorioConciliacao.IO'
import { Tipografia } from '../../components/Tipografia/index'
import { FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@material-ui/core'
import { Botao } from '../../components/Botao'
import { Controller } from 'react-hook-form'
import { dataAtualPadrao } from '../../utils/stringUtils'
import DownloadIcon from '../../assets/icons/download-icon.svg'
import { customTheme } from '../../styles/customTheme'

export type RelatorioConciliacaoProps = {}

export function RelatorioConciliacaoView({ _io }: IOProps<RelatorioConciliacaoIO, RelatorioConciliacaoProps>) {
  const {
    TextoErroBotao,
    ContainerForm,
    ContainerFormDataButton,
    ContainerBotao,
    UploadPlaninha,
    Input,
    IconeCarregamento,
    TemplateContainer,
    DownloadButton
  } = _io.styles

  return (
    <form>
      <Tipografia variant="h5">Relatórios</Tipografia>
      <ContainerForm container spacing={2} justifyContent="flex-start" alignItems="center">
        <Grid item xs={12}>
          <Tipografia variant="h6">Emissão de relatório</Tipografia>
        </Grid>
        <Grid item lg={2} md={3} xl={3} sm={12} xs={12}>
          <Controller
            render={({ field }) => (
              <>
                <FormControl fullWidth variant="outlined" size="small">
                  <InputLabel>Conta contábil</InputLabel>
                  <Select {...field} label="Conta contábil">
                    <MenuItem value={0}>Selecione a conta</MenuItem>
                    {_io.contaContabil.map((conta) => (
                      <MenuItem key={conta.contaContabilId} value={conta.contaContabilId}>
                        {conta.contaContabilId}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </>
            )}
            name="contaContabilId"
            control={_io.control}
          />
        </Grid>
        <Grid item lg={4} md={4} xl={4} sm={12} xs={12}>
          <ContainerFormDataButton>
            <Controller
              render={({ field }) => (
                <TextField
                  {..._io.register('dataInicio', { required: true })}
                  {...field}
                  label="Data de emissão"
                  variant="outlined"
                  size="small"
                  type="date"
                  fullWidth
                  InputLabelProps={{
                    shrink: true
                  }}
                  inputProps={{
                    max: dataAtualPadrao
                  }}
                />
              )}
              name="dataInicio"
              control={_io.control}
            />
            <Tipografia>até</Tipografia>
            <Controller
              render={({ field }) => (
                <TextoErroBotao
                  {..._io.register('dataFim', { required: true })}
                  {...field}
                  label=""
                  variant="outlined"
                  size="small"
                  type="date"
                  fullWidth
                  error={_io.errors?.dataFim ? true : false}
                  helperText={_io.errors.dataFim?.message}
                  inputProps={{
                    max: dataAtualPadrao
                  }}
                />
              )}
              name="dataFim"
              control={_io.control}
            />
          </ContainerFormDataButton>
        </Grid>
        <Grid item lg={2} md={2} xl={3} sm={10} xs={10}>
          <Controller
            render={({ field }) => <TextField {...field} label="Número do pedido" variant="outlined" size="small" fullWidth />}
            name="pedidoMKT"
            control={_io.control}
          />
        </Grid>
        <Grid item lg={2} md={2} xl={3} sm={12} xs={12}>
          <Controller
            render={({ field }) => (
              <>
                <FormControl fullWidth variant="outlined" size="small">
                  <InputLabel>Parceiro</InputLabel>
                  <Select {...field} label="Parceiro">
                    <MenuItem value={0}>Selecione um parceiro</MenuItem>
                    {_io.parceiros.map((parceiro) => (
                      <MenuItem key={parceiro.parceiroId} value={parceiro.parceiroId}>
                        {parceiro.nomeFantasia}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </>
            )}
            name="parceiroId"
            control={_io.control}
          />
        </Grid>
        <Grid item lg={2} md={2} xl={2} sm={2} xs={2}>
          <Botao textoBotao="Limpar" estilo="text" onClick={_io.handleLimparFiltro} />
        </Grid>
        <ContainerBotao item container spacing={2}>
          <Grid item>
            <Botao
              textoBotao="Emitir Relatório"
              estilo="contained"
              startIcon={_io.carregarEmissaoRelatorio ? <IconeCarregamento size="18px" /> : _io.corBotaoExportarRelatorio}
              disabled={_io.filtrosPreenchidos}
              onClick={_io.handleSubmit(_io.handleAplicar)}
            />
          </Grid>
          <Grid item>
            <Input
              accept={_io.inputFileOptions}
              id="contained-button-file"
              type="file"
              onChange={_io.handleSelecionarArquivo}
              disabled={_io.habilitarBotaoUploadPlanilha}
            />
            <label htmlFor="contained-button-file">
              <UploadPlaninha
                textoBotao="Upload planilha"
                estilo="outlined"
                startIcon={_io.iconeRelatorioImportado}
                disabled={_io.habilitarBotaoUploadPlanilha}
              />
            </label>
          </Grid>
          <Grid item>
            <Botao textoBotao="Cancelar Upload" estilo="text" disabled={!_io.isArquivo} onClick={_io.handleCancelarUpload} />
          </Grid>
        </ContainerBotao>

        <TemplateContainer>
          <Grid container spacing={2} align-items="center">
            <Grid item>
              <DownloadButton href={process.env.REACT_APP_PLANILHA_PADRAO_RELATORIO_CONCILIACAO}>
                <img alt="download icon" src={DownloadIcon} />
              </DownloadButton>
            </Grid>
            <Grid item>
              <Tipografia variant="subtitle2">Primeira vez?</Tipografia>
              <Tipografia colorText={customTheme.colors.grey03} variant="body2">
                {'Baixe a planilha padronizada antes de começar a importação. A lista de Pedidos deve estar no formato da planilha padrão.'}
              </Tipografia>
            </Grid>
          </Grid>
        </TemplateContainer>
      </ContainerForm>
    </form>
  )
}
