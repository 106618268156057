import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import msgConstants from '../../../../constants/msgConstants'
import TitulosManager from '../../../../services/api/titulos/titulosManager'
import { loadingReset, loadingUpdate } from '../../../../store/actions/loadingAction'
import { StatusProcessamento } from '../../../../types/genericoType'
import { BuscarTitulosForm, CardTitulos, ValorGeralTitulosAbertos } from '../../../../types/titulosTypes'
import { formatarData } from '../../../../utils/stringUtils'
import { titulosAbertosStyles } from './TitulosAbertos.styles'
import { TitulosAbertosProps } from './TitulosAbertos.View'

export function titulosAbertosIO({
  setValue,
  dataComparacaoAlterada,
  dataPeriodoAlterada,
  setCarregarBotao,
  dataTituloComparacao,
  dataTituloPeriodo,
  setDataTituloComparacao,
  setDataTituloPeriodo,
  gerarRelatorioTitulosAbertos
}: TitulosAbertosProps) {
  const dispatch = useDispatch()
  const dataAtual = String(formatarData(new Date().toISOString(), '-'))
  const [titulosPeriodo, setTitulosPeriodo] = useState<ValorGeralTitulosAbertos>({} as ValorGeralTitulosAbertos)
  const [titulosComparacao, setTitulosComparacao] = useState<ValorGeralTitulosAbertos>({} as ValorGeralTitulosAbertos)
  const [valorSetTimeOut, setValorSetTimeOut] = useState<number>(0)

  const limparFiltroPesquisa = () => {
    clearTimeout(valorSetTimeOut)
    setValue('dataPeriodo', dataAtual)
    setValue('dataComparacao', '')
    setValue('titulos', 'Em aberto')
    setTitulosComparacao({} as ValorGeralTitulosAbertos)
    dataPeriodoAlterada !== dataAtual && estadoInicialTela()
    setDataTituloPeriodo(dataAtual)
    setDataTituloComparacao('')
    setCarregarBotao(false)
  }

  const buscarTitulosAbertos = async (
    dataPesquisada: string,
    setData: React.Dispatch<React.SetStateAction<string>>,
    setTitulos: React.Dispatch<React.SetStateAction<ValorGeralTitulosAbertos>>,
    dataFormulario: string
  ) => {
    await TitulosManager.buscarTitulosAbertos(dataPesquisada)
      .then((response: ValorGeralTitulosAbertos) => {
        if (response.processamento == undefined || response.processamento.status == StatusProcessamento.PROCESSANDO) {
          const atualizarStatus = setTimeout(() => {
            buscarTitulosAbertos(dataPesquisada, setData, setTitulos, dataFormulario)
          }, 10000)
          setValorSetTimeOut(Number(atualizarStatus))
        } else {
          setCarregarBotao(false)
          setData(dataFormulario)
          setTitulos(response)
        }
      })
      .catch((e: Error) => {
        toast.error(msgConstants.TITULOS.erroTitulos)
        toast.error(e.message)
        setCarregarBotao(false)
      })
  }

  const buscaConstanteTitulos = (valorTitulos: ValorGeralTitulosAbertos, buscarRelatorioTitulo: () => Promise<void>) => {
    if (valorTitulos.resumoGeral) {
      const statusResumoGeral = valorTitulos.resumoGeral.processamentoRelatorio?.status
      const statusResumoParceiros = valorTitulos.resumoParceiros.map((parceiro) => parceiro.processamentoRelatorio?.status)

      if (statusResumoGeral == StatusProcessamento.PROCESSANDO || statusResumoParceiros.includes(StatusProcessamento.PROCESSANDO)) {
        clearTimeout(valorSetTimeOut)

        const atualizarStatus = setTimeout(() => {
          buscarRelatorioTitulo()
        }, 10000)
        setValorSetTimeOut(Number(atualizarStatus))
      }
    }
  }

  useEffect(() => {
    buscaConstanteTitulos(titulosPeriodo, () => buscarTitulosAbertos(dataTituloPeriodo, setDataTituloPeriodo, setTitulosPeriodo, dataPeriodoAlterada))
  }, [titulosPeriodo])

  useEffect(() => {
    buscaConstanteTitulos(titulosComparacao, () =>
      buscarTitulosAbertos(dataTituloComparacao, setDataTituloComparacao, setTitulosComparacao, dataComparacaoAlterada)
    )
  }, [titulosComparacao])

  const estadoInicialTela = (attManual?: boolean) => {
    dispatch(loadingUpdate(true))
    TitulosManager.buscarTitulosAbertos(dataAtual, attManual)
      .then((response: ValorGeralTitulosAbertos) => {
        setTitulosPeriodo(response)
      })
      .catch((e: Error) => {
        toast.error(msgConstants.TITULOS.erroTitulos)
        toast.error(e.message)
      })
      .finally(() => {
        dispatch(loadingReset())
      })
  }

  const atualizarTitulos = () => {
    limparFiltroPesquisa()
    estadoInicialTela(true)
  }

  useEffect(() => {
    estadoInicialTela()
  }, [dataAtual, dispatch])

  const handleBuscarTitulos = async (data: BuscarTitulosForm) => {
    clearTimeout(valorSetTimeOut)
    if (data.dataPeriodo !== dataTituloPeriodo) {
      setCarregarBotao(true)
      buscarTitulosAbertos(data.dataPeriodo, setDataTituloPeriodo, setTitulosPeriodo, dataPeriodoAlterada)
    }
    if (data.dataComparacao !== dataTituloComparacao && data.dataComparacao !== '') {
      setCarregarBotao(true)
      buscarTitulosAbertos(data.dataComparacao, setDataTituloComparacao, setTitulosComparacao, dataComparacaoAlterada)
    }
  }

  const compararPorcentagemValor = (valorPeriodo: number, valorComparacao: number) => {
    const diferencaPeriodos = valorPeriodo - valorComparacao
    return ((diferencaPeriodos * 100) / valorPeriodo).toFixed(2)
  }

  const mapearCardResumoGeral = (cardTitulos: CardTitulos) => {
    if (cardTitulos.informacoes) {
      const titulosComparacaoCard = titulosComparacao.resumoGeral ? CardTitulos.fromAberto(titulosComparacao.resumoGeral) : undefined
      return (
        cardTitulos.informacoes &&
        cardTitulos.informacoes.map((informacao, index) => {
          return {
            tooltip: 'Fazer download do relatório de título em aberto',
            titulo: informacao.nome,
            valor: informacao.valor,
            quantidade: informacao.quantidade,
            porcentagem: titulosComparacaoCard ? compararPorcentagemValor(informacao.valor, titulosComparacaoCard.informacoes[index].valor) : 0,
            primeiroCard: index == 0 ? true : false,
            status: cardTitulos.processamentoRelatorio?.status,
            url: cardTitulos?.urlRelatorio
          }
        })
      )
    }
    return []
  }

  return {
    styles: titulosAbertosStyles,
    limparFiltroPesquisa,
    limparFiltro: () => limparFiltroPesquisa(),
    handleBuscarTitulos,
    dataAtualPadrao: dataAtual,
    existeTitulosFiltrados: Boolean(titulosComparacao.resumoParceiros),
    titulosPeriodo,
    titulosComparacao,
    mapearCardResumoGeral,
    atualizarTitulos,
    exportarRelatorioComparacao: (data: string, parceiro?: number) =>
      gerarRelatorioTitulosAbertos(
        () => buscarTitulosAbertos(dataTituloComparacao, setDataTituloComparacao, setTitulosComparacao, dataComparacaoAlterada),
        data,
        parceiro
      ),
    exportarRelatorioPeriodo: (data: string, parceiro?: number) =>
      gerarRelatorioTitulosAbertos(() => buscarTitulosAbertos(dataTituloPeriodo, setDataTituloPeriodo, setTitulosPeriodo, dataPeriodoAlterada), data, parceiro)
  }
}

export type TitulosAbertosIO = ReturnType<typeof titulosAbertosIO>
