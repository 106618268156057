import { IOProps } from 'react-compose-io'
import { FormularioPedidoIO } from './FormularioPedido.IO'
import { FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import { Control, Controller } from 'react-hook-form'
import { BuscarPedidoForm } from '../../../../types/pedidosTypes'
import { Sequencias, TiposLancamentosData } from '../../../../types/tipoLancamentoApiTypes'
import { FormEventHandler } from 'react'
import { Botao } from '../../../../components/Botao'

export type FormularioPedidoProps = {
  control: Control<BuscarPedidoForm>
  limparFormulario: () => void
  tiposLancamentosEFacil: TiposLancamentosData[]
  sequencias: Sequencias[]
  handleSubmit: FormEventHandler<HTMLFormElement>
}

export function FormularioPedidoView({
  _io,
  limparFormulario,
  control,
  handleSubmit,
  tiposLancamentosEFacil,
  sequencias
}: IOProps<FormularioPedidoIO, FormularioPedidoProps>) {
  const { GridCenter, GridButton } = _io.styles

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={3}>
        <Grid item md={3} sm={12} xs={12}>
          <Controller
            render={({ field }) => <TextField {...field} size="small" label="Número do pedido" variant="outlined" required fullWidth />}
            name="pedidoId"
            control={control}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} alignItems="center">
        <Grid item md={3} sm={12} xs={12}>
          <Controller
            render={({ field }) => (
              <FormControl variant="outlined" size="small" fullWidth>
                <InputLabel>Tipo de lançamento</InputLabel>
                <Select {...field} label="Tipo de lançamento">
                  {tiposLancamentosEFacil.map((tipoLancamentoEFacil) => (
                    <MenuItem key={tipoLancamentoEFacil.tipoLancamentosId} value={tipoLancamentoEFacil.nome}>
                      {tipoLancamentoEFacil.nome}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            name="tipoLancamento"
            control={control}
          />
        </Grid>
        <Grid item md={3} sm={12} xs={12}>
          <Controller
            render={({ field }) => (
              <FormControl variant="outlined" size="small" fullWidth>
                <InputLabel>Conta contábil</InputLabel>
                <Select {...field} label="Conta contábil">
                  {sequencias.map((contaContabil) => (
                    <MenuItem key={contaContabil.numeroSequencia} value={contaContabil.numeroSequencia}>
                      {contaContabil.sequencia}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            name="contaContabil"
            control={control}
          />
        </Grid>
        <GridCenter item />
        <GridButton item md={2} xs={12} sm={4}>
          <Botao onClick={limparFormulario} textoBotao="Limpar" estilo="text" cor="primaria" />
        </GridButton>
        <GridButton item md={2} xs={12} sm={4}>
          <Botao textoBotao="Pesquisar" cor="primaria" estilo="contained" type="submit" icone={<SearchIcon fontSize={'small'} />} iconeDireita />
        </GridButton>
      </Grid>
    </form>
  )
}
