import { useState } from 'react'
import { popoverFiltroControleComissaoStyles } from './PopoverFiltroControleComissao.styles'
import { PopoverFiltroControleComissaoProps } from './PopoverFiltroControleComissao.View'

export function popoverFiltroControleComissaoIO({
  setFiltroStatusLista,
  setFiltroParceirosLista,
  filtroStatusLista,
  filtroParceirosLista,
  handleAplicarFiltro
}: PopoverFiltroControleComissaoProps) {
  const [abrirFiltro, setAbrirFiltro] = useState<null | HTMLElement>(null)

  const handleFiltroIconeAncora = (event: React.MouseEvent<HTMLImageElement>) => {
    setAbrirFiltro(event.currentTarget)
  }

  const handleAnularIconeAncora = () => {
    setAbrirFiltro(null)
  }

  const handleCheckListStatus = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked === true) {
      setFiltroStatusLista([...filtroStatusLista, Number(event.target.value)])
    } else {
      const statusListaFiltrado = filtroStatusLista.filter((status) => status != Number(event.target.value))
      setFiltroStatusLista(statusListaFiltrado)
    }
  }

  const handleCheckListParceiro = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked === true) {
      setFiltroParceirosLista([...filtroParceirosLista, Number(event.target.value)])
    } else {
      const parceiroListaFiltrado = filtroParceirosLista.filter((parceiro) => parceiro != Number(event.target.value))
      setFiltroParceirosLista(parceiroListaFiltrado)
    }
  }

  const isParceiroSelecionado = (parceiroId: number) => {
    return filtroParceirosLista.find((x) => x == parceiroId) ? true : false
  }

  const isStatusSelecionado = (statusId: number) => {
    return filtroStatusLista.find((x) => x == statusId) ? true : false
  }

  const handleLimpar = () => {
    setFiltroStatusLista([])
    setFiltroParceirosLista([])
  }

  const aplicarFiltro = () => {
    handleAplicarFiltro()
    handleAnularIconeAncora()
  }

  return {
    styles: popoverFiltroControleComissaoStyles,
    abrirFiltro,
    handleFiltroIconeAncora,
    handleAnularIconeAncora,
    handleCheckListParceiro,
    handleCheckListStatus,
    isParceiroSelecionado,
    isStatusSelecionado,
    handleLimpar,
    aplicarFiltro
  }
}

export type PopoverFiltroControleComissaoIO = ReturnType<typeof popoverFiltroControleComissaoIO>
