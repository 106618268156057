import { FormControl, OutlinedInput, TableCell } from '@material-ui/core'
import styled from 'styled-components'
import { customTheme } from '../../../../styles/customTheme'

export const cabecalhoTabelaContabilizacaoStyles = {
  ContainerCabecalhoContabilizacao: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px;
  `,
  ContainerBotao: styled.div`
    background: ${customTheme.colors.white01};
    border-radius: 4px;
    width: 45px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 12px;
  `,
  ContainerLinha: styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
  `,
  FormularioControleInput: styled(FormControl).attrs({
    fullWidth: true,
    variant: 'outlined',
    size: 'small'
  })``,
  InputFiltrar: styled(OutlinedInput)`
    background: white;
    cursor: pointer;
  `,
  ContainerModal: styled.div`
    width: 100%;
    background-color: ${customTheme.colors.white01};
    & .mui-error {
      color: ${customTheme.colors.grey08};
    }
  `,
  CelulaTabela: styled(TableCell)`
    text-align: start;
  `
}
