import { useEffect, useState, KeyboardEvent } from 'react'
import { StatusProcessamento } from '../../../../types/genericoType'
import { cabecalhoTabelaContabilizacaoStyles } from './CabecalhoTabelaContabilizacao.styles'
import { CabecalhoTabelaContabilizacaoProps } from './CabecalhoTabelaContabilizacao.View'

export function cabecalhoTabelaContabilizacaoIO({ status, searchEnter }: CabecalhoTabelaContabilizacaoProps) {
  const [relatorioDisponivel, setRelatorioDisponivel] = useState(false)
  const [carregandoRelatorio, setCarregandoRelatorio] = useState(false)

  useEffect(() => {
    if (status) {
      if (status == null || status == StatusProcessamento.NOVO) {
        setCarregandoRelatorio(false)
        setRelatorioDisponivel(false)
        return
      }
      if (status == StatusProcessamento.PROCESSANDO) {
        setCarregandoRelatorio(true)
        setRelatorioDisponivel(false)
        return
      }
      if (status == StatusProcessamento.CONCLUIDO) {
        setCarregandoRelatorio(false)
        setRelatorioDisponivel(true)
        return
      }
    }
  }, [status])

  return {
    styles: cabecalhoTabelaContabilizacaoStyles,
    relatorioDisponivel,
    carregandoRelatorio,
    handleKeyEnter: (event: KeyboardEvent<HTMLDivElement>) => {
      if (event.key == 'Enter') {
        searchEnter()
      }
    }
  }
}
export type CabecalhoTabelaContabilizacaoIO = ReturnType<typeof cabecalhoTabelaContabilizacaoIO>
