import { IOProps } from 'react-compose-io'
import { GraficoDonutIO } from './GraficoDonut.IO'
import Chart from 'react-apexcharts'

export type GraficoDonutProps = {
  tituloGrafico: string
  legendaGrafico: string[]
  dadosColunas: number[]
  ocultarLegenda?: boolean
  width?: string
  height?: string
  tipoGrafico: 'mes' | 'classificacao'
}

export function GraficoDonutView({ _io, tituloGrafico, width, height }: IOProps<GraficoDonutIO, GraficoDonutProps>) {
  const { Tipografia } = _io.styles

  return (
    <>
      <Tipografia variant="subtitle1" fontWeight="500">
        {tituloGrafico}
      </Tipografia>
      <Chart options={_io.options} series={_io.series} type="donut" width={width ? width : '472'} height={height ? height : '100%'} />
    </>
  )
}
