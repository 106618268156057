import { TableCell, TableRow } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import { PreviewItemAssociacao } from '../../../types/associacaoApiTypes'

const useRowStyles = makeStyles(() => ({
  root: {
    '& > *': {
      borderBottom: 'unset'
    }
  }
}))

const PreVisualizarAssociacao = (props: { row: PreviewItemAssociacao }) => {
  const classes = useRowStyles()
  const { row } = props

  const validData = (data?: string | number | null) => data ?? '-'

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell align="center">{validData(row?.tipoLancamento)}</TableCell>
        <TableCell align="center">{validData(row?.tipoLancamentoEfacil)}</TableCell>
        <TableCell align="center">{validData(row?.contaContabilId)}</TableCell>
        <TableCell align="center">{validData(row?.tipoNaturezaContabil)}</TableCell>
        <TableCell align="center">{validData(row?.tipoAgrupamento)}</TableCell>
      </TableRow>
    </React.Fragment>
  )
}

export default PreVisualizarAssociacao
