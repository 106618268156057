import { useHistory } from 'react-router-dom'
import { PAGES } from '../../../../router/routes'
import { linhaTabelaClicavelNovaGuia } from '../../../../utils/tabelaUtils'
import { linhaTabelaCicloStyles } from './LinhaTabelaCiclo.style'
import { LinhaTabelaCicloProps } from './LinhaTabelaCiclo.View'

export function linhaTabelaCicloIO({ item }: LinhaTabelaCicloProps) {
  const router = useHistory()

  const verificarCiclo = (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
    linhaTabelaClicavelNovaGuia(event, PAGES.iniciarCiclo(item.cicloId), router)
  }

  return {
    styles: linhaTabelaCicloStyles,
    verificarCiclo
  }
}

export type LinhaTabelaCicloIO = ReturnType<typeof linhaTabelaCicloIO>
