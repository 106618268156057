import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import msgConstants from '../../../../constants/msgConstants'
import ordemPagamentoManager from '../../../../services/api/ordemPagamento/ordemPagamentoManager'
import { FormValuesBuscarOPs } from '../../../../types/iniciarNovoCicloTypes'
import { OrdemPagamentoRequest } from '../../../../types/regularizacaoOPsTitulosTypes'
import { formatarData } from '../../../../utils/stringUtils'
import { modalBuscarOPSStyles } from './ModalBuscarOPS.style'
import { ModalBuscarOPSProps } from './ModalBuscarOPS.View'

export function modalBuscarOPSIO({ atualizarLista, cicloId, fecharModal }: ModalBuscarOPSProps) {
  const { control, handleSubmit, reset, setValue } = useForm<FormValuesBuscarOPs>({})
  const [opsSelecionadas, setOpsSelecionadas] = useState<OrdemPagamentoRequest[]>([])
  const [ops, setOps] = useState<any>([])
  const [loading, setLoading] = useState(false)

  const buscarOps = async (data: FormValuesBuscarOPs) => {
    setLoading(true)
    ordemPagamentoManager
      .buscarOps(data)
      .then((response) => {
        setOps(response)
      })
      .catch((e: Error) => {
        toast.error(e.message)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const vincularOps = async (limparModal: boolean) => {
    setLoading(true)
    ordemPagamentoManager
      .vincularOPS(cicloId, opsSelecionadas)
      .then(() => {
        atualizarOpsSelecionadas()
        toast.success(msgConstants.OPS.selecionar)
        limparModal && limparDadosModal()
      })
      .catch(() => {
        toast.error(msgConstants.OPS.erroBuscar)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  function atualizarOpsSelecionadas() {
    const opsAtualizado = ops
    opsSelecionadas.forEach((opSelecionada) => {
      opsAtualizado.find(
        (op: any) =>
          op.operacaoOrdemPagamentoId == opSelecionada.operacaoOrdemPagamentoId &&
          op.bancoId == opSelecionada.bancoId &&
          op.dataEmissao == opSelecionada.dataEmissao &&
          op.ordemPagamentoId == opSelecionada.ordemPagamentoId
      ).cicloId = cicloId
    })
    setOps(opsAtualizado)
  }

  const limparDadosModal = () => {
    setValue('dataFim', '')
    setValue('dataInicio', '')
    reset()
    atualizarLista()
    fecharModal()
    setOps([])
  }

  return {
    styles: modalBuscarOPSStyles,
    buscarOps,
    handleAdicionarOps: () => {
      vincularOps(true)
    },
    handleAdicionarOpsContinuar: () => {
      vincularOps(false)
    },
    loading,
    resultado: ops,
    setFiltradas: setOpsSelecionadas,
    control,
    handleSubmit,
    dataAtualPadrao: String(formatarData(new Date().toISOString(), '-')),
    limparDadosModal
  }
}
export type ModalBuscarOPSIO = ReturnType<typeof modalBuscarOPSIO>
