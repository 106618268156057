import { useCallback, useEffect, useState } from 'react'
import { RecuperacaoValoresPorParceiro, ResponseDebitos, ResumoDebitosParceiroResponse } from '../../types/debitosTypes'
import { controleDebitosRecebiveisStyles } from './ControleDebitosRecebiveis.styles'
import controleDebitosManager from '../../services/api/debitos/debitosManager'
import { toast } from 'react-toastify'
import msgConstants from '../../constants/msgConstants'
import { useHistory, useParams } from 'react-router-dom'
import { StatusProcessamento } from '../../types/genericoType'
import { Transportadora } from '../../types/transportadoras'
import { RecuperacaoBoxProps } from './components/RecuperacaoBox/RecuperacaoBox.View'
import { TransitoBoxProps } from './components/TransitoBox/TransitoBox.View'
import { SemOcorrenciaBoxProps } from './components/SemOcorrenciaBox/SemOcorrenciaBox.View'

export function controleDebitosRecebiveisIO() {
  const [controleDebitosResponse, setControleDebitosResponse] = useState<ResponseDebitos>({} as ResponseDebitos)
  const [parceiroTransportadoras, setParceiroTransportadoras] = useState<Transportadora[]>([])
  const { parceiroId } = useParams<{ parceiroId: string }>()
  const [parceiroSelecionado, setParceiroSelecionado] = useState(Number(parceiroId))
  const [valorSetTimeOut, setValorSetTimeOut] = useState<number>(0)
  const [carregando, setCarregando] = useState(false)

  const history = useHistory()

  useEffect(() => {
    obterControleDebitos()
  }, [])

  useEffect(() => {
    return history.listen(() => {
      clearTimeout(valorSetTimeOut)
    })
  }, [valorSetTimeOut])

  const obterControleDebitos = useCallback(async () => {
    setCarregando(true)
    await controleDebitosManager
      .buscarControleDebitos()
      .then((response: ResponseDebitos) => {
        const status = response.processamento.status
        setControleDebitosResponse(response)

        if (status === StatusProcessamento.PROCESSANDO) {
          const atualizarStatus = setTimeout(() => {
            obterControleDebitos()
          }, 10000)
          setValorSetTimeOut(Number(atualizarStatus))
        } else {
          clearTimeout(valorSetTimeOut)
          setCarregando(false)
        }

        if (status === StatusProcessamento.NOVO) {
          toast.error(msgConstants.REGULARIZACAO.erroAoBuscarPendencias)
        }
      })
      .catch(() => {
        toast.error(msgConstants.USO_GENERICO.erroBusca)
      })
  }, [controleDebitosResponse])

  function gerarDadosControleDebitos() {
    setCarregando(true)
    controleDebitosManager
      .gerarDadosControleDebitos()
      .then(() => {
        obterControleDebitos()
      })
      .catch((e: Error) => {
        toast.error(e.message)
      })
  }

  const propsRecuperacao = () => {
    if (parceiroSelecionado !== 0) {
      const recuperacaoParceiro = controleDebitosResponse?.recuperacao?.resumosDebitosPorParceiro?.filter((x) => x.parceiroId === parceiroSelecionado)[0]

      if (recuperacaoParceiro) {
        return {
          totalPorcentagem: recuperacaoParceiro?.totalPorcentagem,
          totalEmTransito: recuperacaoParceiro?.totalEmTransito,
          maiorRecuperacao: recuperacaoParceiro?.maiorRecuperacao,
          menorRecuperacao: recuperacaoParceiro?.menorRecuperacao,
          recuperacaoGrafico: recuperacaoParceiro
        } as RecuperacaoBoxProps
      }

      return {
        totalPorcentagem: 0,
        totalEmTransito: 0,
        maiorRecuperacao: { valor: 0, mes: '-', ano: 0 },
        menorRecuperacao: { valor: 0, mes: '-', ano: 0 },
        recuperacaoGrafico: {
          valor: 0,
          quantidade: 0,
          valoresMensais: controleDebitosResponse?.resumosRepasse?.resumoDebitosTotal?.valoresMensais.map((valorMensal) => {
            valorMensal.valor = 0
            return valorMensal
          }),
          urlRelatorio: ''
        } as RecuperacaoValoresPorParceiro
      } as RecuperacaoBoxProps
    }

    const recuperacaoTotal = controleDebitosResponse.recuperacao

    return {
      totalPorcentagem: recuperacaoTotal?.totalPorcentagem,
      totalEmTransito: recuperacaoTotal?.totalEmTransito,
      maiorRecuperacao: recuperacaoTotal?.maiorRecuperacao,
      menorRecuperacao: recuperacaoTotal?.menorRecuperacao,
      recuperacaoGrafico: recuperacaoTotal?.resumoDebitosTotal
    } as RecuperacaoBoxProps
  }

  const propsEmTransito = () => {
    const objetoEmTransitoNaoEncontrado = {
      quantidade: 0,
      quantidadeTransportadoraMartins: 0,
      quantidadeTransportadoraParceira: 0,
      urlRelatorio: '',
      urlRelatorioTransportadoraMartins: '',
      urlRelatorioTransportadoraParceira: '',
      valor: 0,
      valorTransportadoraMartins: 0,
      valorTransportadoraParceira: 0,
      valoresMensais: controleDebitosResponse?.resumosRepasse?.resumoDebitosTotal?.valoresMensais.map((valorMensal) => {
        valorMensal.valor = 0
        valorMensal.valorTransportadoraMartins = 0
        valorMensal.valorTransportadoraParceira = 0
        return valorMensal
      })
    } as ResumoDebitosParceiroResponse

    if (parceiroSelecionado !== 0) {
      const relatorioEmTransitoParceiro = controleDebitosResponse.emTransito?.urlRelatorio.urlRelatoriosParceiros?.filter(
        (x) => x.parceiroId === parceiroSelecionado
      )[0]
      const atendimentoParceiro = controleDebitosResponse.emTransito?.atendimento.resumosDebitosPorParceiro.filter(
        (x) => x.parceiroId === parceiroSelecionado
      )[0]
      const logisticaMartinsParceiro = controleDebitosResponse.emTransito?.logisticaMartins.resumosDebitosPorParceiro.filter(
        (x) => x.parceiroId === parceiroSelecionado
      )[0]
      const logisticaParceiro = controleDebitosResponse.emTransito?.logisticaParceiro.resumosDebitosPorParceiro.filter(
        (x) => x.parceiroId === parceiroSelecionado
      )[0]

      return {
        urlRelatorio: relatorioEmTransitoParceiro ? relatorioEmTransitoParceiro.urlRelatorio : null,
        atendimento: atendimentoParceiro ? atendimentoParceiro : objetoEmTransitoNaoEncontrado,
        logisticaMartins: logisticaMartinsParceiro ? logisticaMartinsParceiro : objetoEmTransitoNaoEncontrado,
        logisticaParceiro: logisticaParceiro ? logisticaParceiro : objetoEmTransitoNaoEncontrado
      } as TransitoBoxProps
    }

    return {
      urlRelatorio: controleDebitosResponse.emTransito?.urlRelatorio.urlRelatorioGeral,
      atendimento:
        controleDebitosResponse.emTransito?.atendimento.resumoDebitosTotal.quantidade > 0
          ? controleDebitosResponse.emTransito?.atendimento.resumoDebitosTotal
          : objetoEmTransitoNaoEncontrado,
      logisticaMartins: controleDebitosResponse.emTransito?.logisticaMartins.resumoDebitosTotal,
      logisticaParceiro: controleDebitosResponse.emTransito?.logisticaParceiro.resumoDebitosTotal
    } as TransitoBoxProps
  }

  const propsSemOcorrencia = () => {
    if (parceiroSelecionado !== 0) {
      const relatorioParceiro = controleDebitosResponse.semOcorrencia?.urlRelatorio.urlRelatoriosParceiros?.filter(
        (x) => x.parceiroId === parceiroSelecionado
      )[0]
      const perdaParceiro = controleDebitosResponse.semOcorrencia?.perdas.resumosDebitosPorParceiro.filter((x) => x.parceiroId === parceiroSelecionado)[0]
      const emAnaliseParceiro = controleDebitosResponse.semOcorrencia?.emAnalise.resumosDebitosPorParceiro.filter(
        (x) => x.parceiroId === parceiroSelecionado
      )[0]

      const objetoEmTransitoNaoEncontrado = {
        quantidade: 0,
        quantidadeTransportadoraMartins: 0,
        quantidadeTransportadoraParceira: 0,
        urlRelatorio: '',
        urlRelatorioTransportadoraMartins: '',
        urlRelatorioTransportadoraParceira: '',
        valor: 0,
        valorTransportadoraMartins: 0,
        valorTransportadoraParceira: 0,
        valoresMensais: controleDebitosResponse?.resumosRepasse?.resumoDebitosTotal?.valoresMensais.map((valorMensal) => {
          valorMensal.valor = 0
          return valorMensal
        })
      } as ResumoDebitosParceiroResponse

      return {
        urlRelatorio: relatorioParceiro ? relatorioParceiro.urlRelatorio : '',
        perda: perdaParceiro ? perdaParceiro : objetoEmTransitoNaoEncontrado,
        emAnalise: emAnaliseParceiro ? emAnaliseParceiro : objetoEmTransitoNaoEncontrado
      } as SemOcorrenciaBoxProps
    }

    return {
      urlRelatorio: controleDebitosResponse.semOcorrencia?.urlRelatorio.urlRelatorioGeral,
      perda: controleDebitosResponse.semOcorrencia?.perdas.resumoDebitosTotal,
      emAnalise: controleDebitosResponse.semOcorrencia?.emAnalise.resumoDebitosTotal
    } as SemOcorrenciaBoxProps
  }

  function handleClickParceiro(parceiro: number) {
    setParceiroSelecionado(parceiro)
  }

  return {
    styles: controleDebitosRecebiveisStyles,
    carregando,
    controleDebitosResponse,
    parceiroSelecionado,
    handleClickParceiro,
    setParceiroTransportadoras,
    gerarDadosControleDebitos,
    parceiroTransportadoras,
    recuperacao: propsRecuperacao(),
    emTransito: propsEmTransito(),
    semOcorrencia: propsSemOcorrencia()
  }
}

export type ControleDebitosRecebiveisIO = ReturnType<typeof controleDebitosRecebiveisIO>
