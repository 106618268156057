import { IOpsVinculadas } from '../../../types/ciclosApiType'
import { FormValuesBuscarOPs } from '../../../types/iniciarNovoCicloTypes'
import { OrdemPagamentoRequest } from '../../../types/regularizacaoOPsTitulosTypes'
import OrdemPagamentoService from './ordemPagamentoService'

const desvincularOPS = async (item: string | number, cicloId: string | number, body: OrdemPagamentoRequest): Promise<IOpsVinculadas[]> =>
  OrdemPagamentoService.desvincularOPS(item, cicloId, body)

const vincularOPS = async (cicloId: string, body: OrdemPagamentoRequest[]): Promise<string> => OrdemPagamentoService.vincularOPS(cicloId, body)

const buscarOps = async (data: FormValuesBuscarOPs): Promise<string> => OrdemPagamentoService.buscarOps(data)

export default {
  desvincularOPS,
  vincularOPS,
  buscarOps
}
