import { IOProps } from 'react-compose-io'
import { RepasseBox } from './components/RepasseBox'
import { ControleDebitosIO } from './ControleDebitos.IO'
import { DebitoBox } from './components/DebitoBox'
import ProjecaoIcone from '../../assets/icons/projecaoIcone.svg'
import RostoFelizIcon from '../../assets/icons/rostoFelizIcone.svg'
import TransicaoIcon from '../../assets/icons/transicaoIcone.svg'
import { corParceiroId, customTheme } from '../../styles/customTheme'
import { Grid } from '@material-ui/core'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import { StatusProcessamento } from '../../types/genericoType'
import { DebitoInicialTransportadoraBox } from './components/DebitoInicialTransportadoraBox'
import { BoxValor } from '../../components/BoxValor'
import { Tipografia } from '../../components/Tipografia'
import { TooltipComponent } from './../../components/Tooltip/Tooltip'
import { PAGES } from '../../router/routes'
import { CabecalhoDebitos } from './../../components/Debitos/CabecalhoDebitos/index'
import { porcentagem } from '../../utils/stringUtils'
import { Botao } from '../../components/Botao'

export type ControleDebitosProps = {}

export function ControleDebitosView({ _io }: IOProps<ControleDebitosIO, ControleDebitosProps>) {
  const { Box, Container, GridContainer, CircularProgress, Divider } = _io.styles

  const Carregamento = _io.carregando && (
    <Grid container justifyContent="center">
      <CircularProgress />
    </Grid>
  )

  function DebitoInicial() {
    return _io.parceiroTransportadoras.length > 0 ? (
      <DebitoInicialTransportadoraBox resumosDebitoParceiro={_io.resumoDebitosParceiroTransportadora} />
    ) : (
      <DebitoBox
        urlRelatorio={_io.resumosDebitoInicial?.urlRelatorio}
        valoresMensais={_io.resumosDebitoInicial.valoresMensais}
        valorDebito={_io.resumosDebitoInicial.valor}
        quantidadeDebito={_io.resumosDebitoInicial.quantidade}
        valoresPorcentagemMensal={_io.resumosDebitoInicialPorcentagem.resumoPorcentagens.porcentagensMensais}
        valoresPorcentagemAcumulada={_io.resumosDebitoInicialPorcentagem.resumoPorcentagensAcumulados.porcentagensMensais}
        porcentagemAcumulada={_io.resumosDebitoInicialPorcentagem.porcentagemTotalAcumulada}
        tipoDebito={'inicial'}
        textoTooltip={`O painel de Débito Inicial indica os valores que foram debitados pelos parceiros no ciclo financeiro de repasse.`}
      />
    )
  }

  const totalEmTransito = _io.resumosRecuperacao ? _io.resumosRecuperacao.totalEmTransito : 0
  const totalPorcentagem = _io.resumosRecuperacao ? _io.resumosRecuperacao.totalPorcentagem : 0

  return (
    <>
      <Tipografia variant="h5">Débitos</Tipografia>
      {_io.controleDebitosResponse.processamento && (
        <Container cor={corParceiroId.get(_io.parceiroSelecionado)}>
          <CabecalhoDebitos
            parceiroSelecionado={_io.parceiroSelecionado}
            desabilitarBotoes={_io.carregando}
            handleAtualizar={() => _io.gerarDadosControleDebitos()}
            handleClickParceiro={_io.handleClickParceiro}
            setParceiroTransportadoras={_io.setParceiroTransportadoras}
            dataUltimaAtualizacao={_io.controleDebitosResponse.dataAtualizacao}
          />

          {_io.controleDebitosResponse.processamento.status !== StatusProcessamento.CONCLUIDO ? (
            <>{Carregamento}</>
          ) : (
            <>
              <RepasseBox
                quantidadeRepasse={_io.resumosRepasse.quantidade}
                valorRepasse={_io.resumosRepasse.valor}
                valoresMensais={_io.resumosRepasse.valoresMensais}
                url={_io.resumosRepasse?.urlRelatorio}
              />
              {DebitoInicial()}
              <DebitoBox
                urlRelatorio={_io.resumosDebitoFinal?.urlRelatorio}
                valoresMensais={_io.resumosDebitoFinal.valoresMensais}
                valorDebito={_io.resumosDebitoFinal.valor}
                quantidadeDebito={_io.resumosDebitoFinal.quantidade}
                valoresPorcentagemMensal={_io.resumosDebitoFinalPorcentagem.resumoPorcentagens.porcentagensMensais}
                valoresPorcentagemAcumulada={_io.resumosDebitoFinalPorcentagem.resumoPorcentagensAcumulados.porcentagensMensais}
                porcentagemAcumulada={_io.resumosDebitoFinalPorcentagem.porcentagemTotalAcumulada}
                tipoDebito={'final'}
                parceiroId={_io.parceiroSelecionado}
                textoTooltip={`O painel de Débito Final indica os débitos que estão em análise e ainda não foram recuperados.`}
              />
              <GridContainer container spacing={2}>
                <Grid container xs={5} item alignItems="center" alignContent="space-between" justifyContent="center" spacing={2}>
                  <Grid item xs={12}>
                    <BoxValor
                      icone={ProjecaoIcone}
                      descricao={`Meta`}
                      tooltipDescricao={`A meta representa a porcentagem máxima de débito final dentro da margem de segurança do negócio.`}
                      variantDescricao={'h6'}
                      valor={porcentagem(_io.controleDebitosResponse.resumoDebitoFinal.meta)}
                      variantValor={customTheme.fontSize[34]}
                      width="370px"
                      heigth="138px"
                      descricaoWidth="240px"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <BoxValor
                      icone={_io.iconeDebitoFinalAcumulado().icon}
                      descricao={`Débito final acumulado`}
                      variantDescricao={'h6'}
                      valor={porcentagem(_io.resumosDebitoFinalPorcentagem.porcentagemTotalAcumulada)}
                      variantValor={customTheme.fontSize[34]}
                      heigth="138px"
                      width="370px"
                      descricaoWidth="240px"
                      tipoSombra={_io.iconeDebitoFinalAcumulado().sombra}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={7}>
                  <Box>
                    <Grid container direction="row" alignItems="center" justifyContent="center">
                      <Grid item container alignItems="center" justifyContent="center" xs={5}>
                        <img src={RostoFelizIcon} />
                        <Grid container direction="row" alignItems="center" justifyContent="center">
                          <Tipografia fontWeight="500" variant="h6" colorText={customTheme.colors.grey23}>
                            Recuperação total
                          </Tipografia>
                          <TooltipComponent
                            texto={'Esse valor se refere ao total de débitos recuperados pela a área nos últimos 6 meses.'}
                            Icone={HelpOutlineIcon}
                            color={'action'}
                          />
                        </Grid>
                        <Tipografia fontWeight="600" fontSize="2.5rem">
                          {porcentagem(totalPorcentagem)}
                        </Tipografia>
                      </Grid>
                      <Grid item>
                        <Divider orientation="vertical" variant="middle" />
                      </Grid>
                      <Grid item container alignItems="center" justifyContent="center" xs={5}>
                        <img src={TransicaoIcon} />
                        <Grid container direction="row" alignItems="center" justifyContent="center">
                          <Tipografia fontWeight="500" variant="h6" colorText={customTheme.colors.grey23}>
                            Total em Trânsito
                          </Tipografia>
                          <TooltipComponent
                            texto={'Esse valor se refere ao total de débitos que estão em análise e tem possibilidade de recuperação pela área responsável.'}
                            Icone={HelpOutlineIcon}
                            color={'action'}
                          />
                        </Grid>
                        <Tipografia fontWeight="600" fontSize="2.5rem">
                          {`${(totalEmTransito * 100).toFixed(2)}%`}
                        </Tipografia>
                      </Grid>
                      <Grid item container justifyContent="center" xs={12}>
                        <Botao
                          cor="primaria"
                          estilo="contained"
                          type="submit"
                          textoBotao="Ver mais dados"
                          onClick={() => _io.history.push(PAGES.debitosRecebiveis(_io.parceiroSelecionado))}
                          width="360px"
                          tamanho="grande"
                          margin="24px 0 0 0"
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </GridContainer>
            </>
          )}
        </Container>
      )}
    </>
  )
}
