import { Box } from '@material-ui/core'
import styled from 'styled-components'
import { customTheme } from '../../../../styles/customTheme'

export const listaTitulosAbertosParceirosStyles = {
  Container: styled(Box)`
    background: ${customTheme.colors.white05};
    border-radius: 8px;
    padding: 10px 8px;
  `,
  ContainerColuna: styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
  `,
  ContainerLista: styled.div`
    display: flex;
    border-radius: 8px;
    padding: 11px 6px;
    background: ${customTheme.colors.white01};
    margin-top: 12px;
  `,
  ContainerDivider: styled.div`
    margin: 5px;
  `
}
