import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import msgConstants from '../../../../constants/msgConstants'
import { ORDEM_CRESCENTE } from '../../../../constants/utilConstants'
import { PAGES } from '../../../../router/routes'
import ciclosManager from '../../../../services/api/ciclos/ciclosManager'
import { ItemLancamentoNaoAssociado, LancamentosVariaveisApi } from '../../../../types/ciclosApiType'
import { Order } from '../../../../types/tabelaTypes'
import { handleRequestSort, tratarMudancaLinhaPorPagina, tratarMudancaPagina } from '../../../../utils/tabelaUtils'
import { lancamentoNaoAssociadoStyles } from './LancamentoNaoAssociado.style'
import { LancamentonAssociadoProps } from './LancamentoNaoAssociado.View'

export function lancamentoNaoAssociadoIO({ cicloId, parceiroId, setHabilitarConfirmar, cicloStatusGeral }: LancamentonAssociadoProps) {
  const router = useHistory()
  const [naoAssociado, setNaoAssociado] = useState<LancamentosVariaveisApi>({} as LancamentosVariaveisApi)
  const [pagina, setPagina] = useState(0)
  const [linhasPorPagina, setLinhasPorPagina] = useState(10)
  const [totalLinhas, setTotalLinhas] = useState(0)
  const [loading, setLoading] = useState<boolean>(true)
  const [ordem, setOrdem] = useState<Order>(ORDEM_CRESCENTE)
  const [ordemBy, setOrdemBy] = useState<keyof never>('historicoPadrao')

  useEffect(() => {
    async function buscarLancamentoNaoAssociado() {
      setLoading(true)
      await ciclosManager
        .buscarLancamentoNaoAssociado(cicloId, pagina + 1, linhasPorPagina)
        .then((response: LancamentosVariaveisApi) => {
          setNaoAssociado(response)
          setTotalLinhas(response.totalItens)
        })
        .catch(() => {
          toast.error(msgConstants.LANCAMENTO_ASSOCIACAO.erroBuscaLancamentoNaoAssociacao)
        })
        .finally(() => {
          setLoading(false)
        })
    }
    buscarLancamentoNaoAssociado()
  }, [cicloId, cicloStatusGeral, pagina, linhasPorPagina])

  useEffect(() => {
    const peloMenosUmItemTratado = naoAssociado.itens && naoAssociado?.itens.find((item) => item.tratada === true)
    if (cicloStatusGeral.internalizacao[0].detalheObservacao || peloMenosUmItemTratado) {
      setHabilitarConfirmar(true)
    }
  }, [naoAssociado, setHabilitarConfirmar, cicloStatusGeral])

  return {
    styles: lancamentoNaoAssociadoStyles,
    naoAssociado,
    onMudancaPagina: (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
      tratarMudancaPagina(event, newPage, setPagina)
    },
    onMudancaLinhasPorPagina: (event: React.ChangeEvent<HTMLInputElement>) => {
      tratarMudancaLinhaPorPagina(event, setPagina, setLinhasPorPagina)
    },
    linhasPorPaginaOptions: [5, 10, 25],
    pagina,
    totalLinhas,
    loading,
    linhasPorPagina,
    ordem,
    ordemBy,
    onRequestSort: handleRequestSort(ordemBy, ordem, setOrdem, setOrdemBy),
    onSubmitLancamento: (index: number, row: ItemLancamentoNaoAssociado) => {
      return router.push(PAGES.lancamentoNaoAssociado(cicloId, row.identificadorPedido?.replace(' ', '-'), parceiroId.toString()), {
        item: row,
        parceiro: parceiroId,
        linha: index,
        idCiclo: cicloId
      })
    }
  }
}
export type LancamentoNaoAssociadoIO = ReturnType<typeof lancamentoNaoAssociadoIO>
