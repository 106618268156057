import { IOpsVinculadas } from '../../../types/ciclosApiType'
import { FormValuesBuscarOPs } from '../../../types/iniciarNovoCicloTypes'
import { OrdemPagamentoRequest, VincularOrdemPagamentoResponse } from '../../../types/regularizacaoOPsTitulosTypes'
import api from '../../axiosInstance'

const desvincularOPS = async (item: string | number, cicloId: string | number, body: OrdemPagamentoRequest): Promise<IOpsVinculadas[]> => {
  const URL_EXCLUIR_TABELA_OPS = `OrdensPagamentos/${item}/Ciclos/${cicloId}`
  const response = await api.conectarPut<IOpsVinculadas[]>(URL_EXCLUIR_TABELA_OPS, body)
  return response.data
}

const vincularOPS = async (cicloId: string, opsChaves: OrdemPagamentoRequest[]): Promise<string> => {
  const body: VincularOrdemPagamentoResponse = {
    opsChaves: opsChaves
  }
  const URL_ORDEM_PAGAMENTO_FILTRADO = `OrdensPagamentos/Ciclos/${cicloId}`
  const response = await api.conectarPost<string>(URL_ORDEM_PAGAMENTO_FILTRADO, body)
  return response.data
}

const buscarOps = async (data: FormValuesBuscarOPs): Promise<string> => {
  const response = await api.conectarGet<string>('OrdensPagamentos', {
    params: {
      ...data
    }
  })
  return response.data
}

export default {
  desvincularOPS,
  vincularOPS,
  buscarOps
}
