import { IOProps } from 'react-compose-io'
import { TabelaDetalheControleComissaoPedidoIO } from './TabelaDetalheControleComissaoPedido.IO'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import { ReactNode } from 'react'

export interface CabecalhoTabelaDetalheControleComissaoPedido {
  descricao: string
}

export type TabelaDetalheControleComissaoPedidoProps = {
  cabecalhoTabela: CabecalhoTabelaDetalheControleComissaoPedido[]
  checklistsSelecionados: string[]
  children: ReactNode
}

export function TabelaDetalheControleComissaoPedidoView({
  _io,
  cabecalhoTabela,
  children
}: IOProps<TabelaDetalheControleComissaoPedidoIO, TabelaDetalheControleComissaoPedidoProps>) {
  const { ContainerTabela } = _io.styles

  return (
    <ContainerTabela>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center" size="small"></TableCell>
              <TableCell align="center" size="small"></TableCell>
              {cabecalhoTabela.map(_io.renderCabecalho)}
            </TableRow>
          </TableHead>
          <TableBody>{children}</TableBody>
        </Table>
      </TableContainer>
    </ContainerTabela>
  )
}
