import { Table, TableBody, TableContainer, TablePagination, Grid, Typography } from '@material-ui/core'
import { IOProps } from 'react-compose-io'
import InputPesquisa from '../../components/InputPesquisa/index'
import { ComponentePaginacao } from '../../components/Tabela/ComponentePaginacao/index'
import { CiclosIO } from './Ciclos.IO'
import { CardExpansivel } from './components/CardExpansivel/index'

type CiclosProps = {}

export function CiclosView({ _io }: IOProps<CiclosIO, CiclosProps>) {
  return (
    <Grid container direction="column">
      <Grid item container direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="h5">Ciclos</Typography>
        <InputPesquisa valorBuscado={_io.filtro} setValorBuscado={_io.setFiltro} onChange={_io.filtrarParceirosComCiclos} />
      </Grid>
      <TableContainer>
        <Table>
          <TableBody>
            <>
              {_io.parceirosComCiclosFiltrados
                .slice(_io.pagina * _io.linhasPorPagina, _io.pagina * _io.linhasPorPagina + _io.linhasPorPagina)
                .map((ciclo, index) => (
                  <CardExpansivel parceiroComCiclos={ciclo} key={index} pagina={_io.pagina} />
                ))}
            </>
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={_io.rowsPerPageOptions}
          component="div"
          count={_io.totalLinhas}
          rowsPerPage={_io.linhasPorPagina}
          page={_io.pagina}
          onPageChange={_io.onMudancaPagina}
          onRowsPerPageChange={_io.onMudancaLinhasPorPagina}
          labelRowsPerPage="Ciclos por página"
          ActionsComponent={ComponentePaginacao}
        />
      </TableContainer>
    </Grid>
  )
}
