import { Box, Collapse, Grid, IconButton } from '@material-ui/core'
import { IOProps } from 'react-compose-io'
import { cabecalhoControleComissao } from '../../../../types/tabelaTypes'
import { formateDate, formateHoras } from '../../../../utils/stringUtils'
import { HistoricoDebitoIO } from './HistoricoDebito.IO'
import { Tipografia } from '../../../../components/Tipografia/index'
import { corChip, corLetraChip, customTheme } from '../../../../styles/customTheme'
import { ObterPedidosDebitosResponse } from '../../../../types/debitosTypes'
import { ChipClassificacaoPerda } from '../../../../components/ChipClassificacaoPerda/ChipClassificacaoPerda'
import { Check } from '../../../../assets/icons/Check'

export type HistoricoDebitoProps = {
  item: ObterPedidosDebitosResponse
  historicoAberto: boolean
  isAdministrador: boolean
  handleAprovarSugestao: (pedidoMkt: string, classificacaoSugerida: number) => void
}

export function HistoricoDebitoView({ _io, item, historicoAberto, isAdministrador, handleAprovarSugestao }: IOProps<HistoricoDebitoIO, HistoricoDebitoProps>) {
  const { TableRow, TableCell, ContainerGrid, ContainerDetalheObservacao, TituloDescricao, TipografiaHistorico, ContainerFlex } = _io.styles

  return (
    <TableRow>
      <TableCell colSpan={cabecalhoControleComissao.length}>
        <Collapse in={historicoAberto} timeout="auto" unmountOnExit>
          <Box>
            <TituloDescricao variant="subtitle1" fontWeight="600">
              Detalhes
            </TituloDescricao>

            <ContainerGrid spacing={2} direction="column">
              <Grid item>
                <TipografiaHistorico marginbottom="24px" fontWeight="500" colorText={customTheme.colors.cinza08} variant="subtitle1">
                  Observações
                </TipografiaHistorico>
                {item.observacoes.length > 0 ? (
                  <>
                    {item.observacoes.map((historico, index) => (
                      <ContainerDetalheObservacao key={index}>
                        {historico.observacao.startsWith('Classificação sugerida:') && (
                          <>
                            <Tipografia fontWeight="500" colorText={customTheme.colors.grey21} variant="subtitle1">
                              {_io.getObservacao(historico.observacao)}
                            </Tipografia>
                            <ContainerFlex>
                              <Tipografia fontWeight="500" colorText={customTheme.colors.azul04} variant="subtitle1">
                                Classificação sugerida:
                              </Tipografia>
                              <ChipClassificacaoPerda
                                label={_io.getClassificacaoSugerida(historico.observacao)}
                                bgcolor={corChip.get(_io.getClassificacaoSugerida(historico.observacao))}
                                fontcolor={corLetraChip.get(_io.getClassificacaoSugerida(historico.observacao))}
                              />
                              {_io.getPrimeiraClassificacaoSugerida(index) && (
                                <>
                                  {!item.aprovado && isAdministrador && (
                                    <IconButton onClick={() => handleAprovarSugestao(item.pedidoMkt, item.classificacaoId)}>
                                      <Check color={customTheme.colors.azul04} />
                                    </IconButton>
                                  )}
                                  {!item.aprovado && !isAdministrador && (
                                    <Tipografia fontWeight="500" colorText={customTheme.colors.grey21} variant="subtitle1">
                                      {' - Aguardando Aprovação'}
                                    </Tipografia>
                                  )}
                                </>
                              )}
                            </ContainerFlex>
                          </>
                        )}

                        {historico.observacao.startsWith('Classificação aprovada.') && (
                          <>
                            <Tipografia fontWeight="500" colorText={customTheme.colors.grey21} variant="subtitle1">
                              {_io.getObservacao(historico.observacao)}
                            </Tipografia>
                            <Tipografia fontWeight="500" colorText={customTheme.colors.verde06} variant="subtitle1">
                              Classificação aprovada.
                            </Tipografia>
                          </>
                        )}

                        {!historico.observacao.startsWith('Classificação aprovada.') && !historico.observacao.startsWith('Classificação sugerida:') && (
                          <Tipografia fontWeight="500" colorText={customTheme.colors.grey21} variant="subtitle1">
                            {historico.observacao}
                          </Tipografia>
                        )}

                        <TipografiaHistorico margintop="12px" variant="body2">
                          {`${formateDate(historico.data)} às ${formateHoras(historico.data)} | Usuário: ${historico.usuario}`}
                        </TipografiaHistorico>
                      </ContainerDetalheObservacao>
                    ))}
                  </>
                ) : (
                  <Tipografia fontWeight="400" colorText={customTheme.colors.grey21} variant="subtitle1">
                    Este pedido não possui nenhuma observação registrada.
                  </Tipografia>
                )}
              </Grid>
            </ContainerGrid>
          </Box>
        </Collapse>
      </TableCell>
    </TableRow>
  )
}
