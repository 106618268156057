import { useState, useEffect } from 'react'
import Cookies from 'js-cookie'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import msgConstants from '../../constants/msgConstants'
import { PAGES } from '../../router/routes'
import { useDrawerContext } from '../../contexts/drawer-context'
import loginManager from '../../services/api/login/loginManager'
import { headerStyles } from './Header.style'

export function headerIO() {
  const [usuario, setUsuario] = useState('')
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const openMenu = Boolean(anchorEl)
  const router = useHistory()
  const { isOpened, toggleIsOpened } = useDrawerContext()

  useEffect(() => {
    buscarUsuario()
  }, [])

  const buscarUsuario = () => {
    const username = Cookies.get('username')

    if (username) {
      setUsuario(username)
    }
  }

  const handleMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLogout = async () => {
    try {
      await loginManager.logout().then(() => {
        Cookies.remove('username', { path: '' })
        sessionStorage.removeItem('username')
        handleClose()
        router.push(PAGES.login)
      })
    } catch {
      toast.info(msgConstants.LOGOUT_ERRO)
      handleClose()
    }
  }

  return {
    styles: headerStyles,
    usuario,
    openMenu,
    isOpened,
    toggleIsOpened,
    handleMenu,
    handleLogout,
    handleClose,
    anchorEl
  }
}

export type HeaderIO = ReturnType<typeof headerIO>
