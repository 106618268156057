import styled from 'styled-components'
import { customTheme } from '../../../../styles/customTheme'

export const parametroContabilDetalhesStyles = {
  TextoChip: styled.span`
    font-weight: 400;
    font-size: ${customTheme.fontSize[16]};
    margin: 6px 0;
  `,
  ContainerBranco: styled.div`
    background-color: ${customTheme.colors.blue01};
    margin-top: 2px;
    padding: 4px 16px;
  `,
  ParagrafoChip: styled.p`
    font-weight: 400;
    font-size: ${customTheme.fontSize[16]};
  `
}
