import styled from 'styled-components'
import { customTheme } from '../../styles/customTheme'

export type SombraTipo = 'tipo1' | 'tipo2' | 'tipo3' | 'tipo4' | 'tipo5' | 'none'

type ContainerSombraProp = {
  sombra?: SombraTipo
}

export const ContainerSombra = styled.div`
  background: ${customTheme.colors.white01};
  border-radius: 8px;
  box-shadow: ${({ sombra }: ContainerSombraProp) => estiloSombra(sombra)};
`

const estiloSombra = (tipoSombra?: SombraTipo) => {
  switch (tipoSombra) {
    case 'tipo1':
      return `0px 2px 8px rgba(33, 33, 33, 0.2);`
    case 'tipo2':
      return `0px 2px 8px rgba(255, 0, 0, 0.2);`
    case 'tipo3':
      return `0px 2px 8px rgba(4, 197, 0, 0.2);`
    case 'tipo4':
      return `0px 1px 1px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12);`
    case 'none':
      return 'none'
    default:
      return `1px 2px 9px rgba(33, 33, 33, 0.25);`
  }
}
