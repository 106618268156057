import { MenuItem } from '@material-ui/core'
import EditOutlinedIcon from '@material-ui/icons/EditOutlined'
import ToggleOffOutlinedIcon from '@material-ui/icons/ToggleOffOutlined'
import { ReactNode } from 'react'
import { editarItemTabelaStyles } from './EditarItemTabela.style'

export function EditarItemTabela(
  linhaSelecionadaTabela: any,
  editarItem: (itemSelecionado: any) => void,
  desativarItemSelecionado: (semSelecao: any) => void,
  ancoraHef?: string
) {
  const { Link, BoxMenu } = editarItemTabelaStyles

  const getItemMenu = (descricao: string, icone: ReactNode, callback: (parametro: any) => void, menuFuncao: any, link?: string) => (
    <div>
      {link ? (
        <Link to={link}>
          <MenuItem>
            <BoxMenu>
              {icone}
              {descricao}
            </BoxMenu>
          </MenuItem>
        </Link>
      ) : (
        <MenuItem onClick={() => callback(menuFuncao)}>
          <BoxMenu>
            {icone}
            {descricao}
          </BoxMenu>
        </MenuItem>
      )}
    </div>
  )

  return [
    getItemMenu('Editar', <EditOutlinedIcon />, editarItem, linhaSelecionadaTabela, ancoraHef),
    getItemMenu(`${linhaSelecionadaTabela.ativo ? 'Desativar' : 'Ativar'}`, <ToggleOffOutlinedIcon />, desativarItemSelecionado, linhaSelecionadaTabela)
  ]
}
