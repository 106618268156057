import { ResumoInadimplenciaIO } from './ResumoInadimplencia.IO'
import { IOProps } from 'react-compose-io'
import Grid from '@material-ui/core/Grid'
import { Tipografia } from '../../../../components/Tipografia'
import { customTheme } from '../../../../styles/customTheme'
import { GraficoDonut } from '../../../../components/GraficoDonut'

export type ResumoInadimplenciaProps = {
  mesesSelecionado: number
  icone: string
  titulo: string
  totalTitulosUltimosMeses: string
  totalTitulos: string
  valoresGrafico: number[]
  legendaGrafico: string[][]
  valorTotal: number
}

export function ResumoInadimplenciaView({
  _io,
  mesesSelecionado,
  icone,
  titulo,
  totalTitulosUltimosMeses,
  totalTitulos
}: IOProps<ResumoInadimplenciaIO, ResumoInadimplenciaProps>) {
  const { Box, Imagem, DividerHorizontal, DividerVertical } = _io.styles

  return (
    <Box>
      <Grid container alignItems="center" direction="column">
        <Grid item>
          <Imagem src={icone} />
        </Grid>
        <Grid item>
          <Tipografia fontWeight="500" variant="h6">
            {titulo}
          </Tipografia>
        </Grid>
      </Grid>
      <Grid container justifyContent="space-evenly" direction="row" alignItems="center">
        <Grid item>
          <Tipografia fontWeight="500" variant="h6" colorText={customTheme.colors.grey25}>
            {`Total nos últimos ${mesesSelecionado} meses`}
          </Tipografia>
          <Tipografia fontWeight="600" variant="h4" fontSize={customTheme.fontSize[40]} colorText={customTheme.colors.grey24}>
            {totalTitulosUltimosMeses}
          </Tipografia>

          <DividerHorizontal />

          <Tipografia fontWeight="500" variant="h6" colorText={customTheme.colors.grey25}>
            Total de títulos com inadimplência
          </Tipografia>
          <Tipografia fontWeight="600" variant="h6" fontSize={customTheme.fontSize[40]} colorText={customTheme.colors.grey24}>
            {totalTitulos}
          </Tipografia>
        </Grid>
        <Grid item>
          <DividerVertical />
        </Grid>
        <Grid item>
          <GraficoDonut
            tituloGrafico={`Porcentagem de títulos inadimplentes nos últimos ${mesesSelecionado} meses`}
            legendaGrafico={_io.gerarLegendasMensais}
            dadosColunas={_io.gerarPorcentagensMensais}
            height="184px"
            width="350px"
            tipoGrafico="mes"
          />
        </Grid>
      </Grid>
    </Box>
  )
}
