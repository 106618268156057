import { Grid } from '@material-ui/core'
import { IOProps } from 'react-compose-io'
import { DebitoBoxIO } from './DebitoBox.IO'
import { validarTexto } from '../../../../utils/stringUtils'
import { GraficoLinhas } from '../../../../components/GraficoLinhas'
import { Legenda } from '../../../../components/Legenda/Legenda'
import { customTheme } from '../../../../styles/customTheme'
import { PorcentagensMensais, ValoresMensais } from '../../../../types/debitosTypes'
import { CabecalhoIconeExportarRelatorio } from '../../../../components/CabecalhoIconeExportarRelatorio'
import { CabecalhoExportarRelatorio } from '../../../../components/CabecalhoExportarRelatorio'
import { GraficoValorQuantidade } from './../../../../components/Debitos/GraficoValorQuantidade/index'
import { VALOR_ESCALA_GRAFICO } from '../../../../constants/utilConstants'
import { Botao } from '../../../../components/Botao'

export type DebitoBoxProps = {
  porcentagemAcumulada: number
  valorDebito: number
  quantidadeDebito: number
  valoresMensais: ValoresMensais[]
  valoresPorcentagemAcumulada: PorcentagensMensais[]
  valoresPorcentagemMensal: PorcentagensMensais[]
  urlRelatorio: string
  iconeTransportadora?: string
  tipoDebito: 'final' | 'inicial'
  textoTooltip?: string
  parceiroId?: number
  valorMensalConsiderado?: 'valor' | 'valorTransportadoraParceira' | 'valorTransportadoraMartins'
}

export function DebitoBoxView({
  _io,
  valorDebito,
  quantidadeDebito,
  urlRelatorio,
  iconeTransportadora,
  tipoDebito,
  textoTooltip
}: IOProps<DebitoBoxIO, DebitoBoxProps>) {
  const { Box, BoxLegenda, Divider, SubTitulo, TypographyPorcentagem, GridWidthLimitada } = _io.styles

  return (
    <Box transportadora={iconeTransportadora ? 1 : 0}>
      {iconeTransportadora ? (
        <CabecalhoIconeExportarRelatorio url={urlRelatorio} icone={iconeTransportadora} />
      ) : (
        <CabecalhoExportarRelatorio url={urlRelatorio} textoTooltip={validarTexto(textoTooltip, '-').toString()} tituloCabecalho={`Débito ${tipoDebito}`} />
      )}

      <GraficoValorQuantidade
        tituloGrafico={`Valor de débito ${tipoDebito} mensal`}
        valoresMensais={_io.valorDebitoMensal}
        legendaMeses={_io.legendaDebitoMensal}
        legendaEixoY="Milhares"
        divisorLegenda={VALOR_ESCALA_GRAFICO.MIL}
        descricaoValor={`Valor total de débito ${tipoDebito}`}
        valor={valorDebito}
        descricaoQuantidade={`Quantidade total de pedidos com débito ${tipoDebito}`}
        quantidade={quantidadeDebito}
      />
      <Grid container spacing={2} direction="row" justifyContent="center" alignContent="center">
        <GridWidthLimitada item sm={7} md={7} lg={6}>
          <GraficoLinhas
            tituloGrafico={`Porcentagem de débito ${tipoDebito} mensal`}
            dadosLinhas={_io.dadosLinhas}
            descricaoLinhas={_io.legendaPorcentagemDebito}
          />
        </GridWidthLimitada>
        <GridWidthLimitada item container justifyContent="center" alignContent="center" direction="column" spacing={2} sm={12} md={4} lg={6}>
          <Grid item>
            <BoxLegenda>
              <SubTitulo>Legenda</SubTitulo>
              <Legenda descricao={`Débito ${tipoDebito} acumulado`} cor={customTheme.colors.blue14} tamanhoTexto="subtitle1" />
              <Legenda descricao={`Débito ${tipoDebito}`} cor={customTheme.colors.blue13} tamanhoTexto="subtitle1" />
              <Divider />
              <SubTitulo>{`Porcentagem total do débito ${tipoDebito} acumulado nos últimos 6 meses`}</SubTitulo>
              <TypographyPorcentagem>{_io.porcentagemAcumulada}%</TypographyPorcentagem>
            </BoxLegenda>
          </Grid>
          {tipoDebito == 'final' && (
            <Grid item>
              <Botao
                cor="primaria"
                estilo="contained"
                type="submit"
                textoBotao="Ver detalhes dos pedidos"
                onClick={_io.onClickDetalhesPedidosBotao}
                width="360px"
                tamanho="grande"
              />
            </Grid>
          )}
        </GridWidthLimitada>
      </Grid>
    </Box>
  )
}
