import { Menu, TableCell, TableRow } from '@material-ui/core'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { ReactNode } from 'react'
import { IOProps } from 'react-compose-io'
import { EditarItemTabela } from '../../../../components/EditarItemTabela/EditarItemTabela'
import IconeButton from '../../../../components/IconeButton/IconeButton'
import { GrupoRegra } from '../../../../types/transformacaoApiTypes'
import { ModalGrupoRegra } from '../ModalGrupoRegra'
import { ItemGrupoRegraIO } from './ItemGrupoRegra.IO'

export type ItemGrupoRegraProps = {
  row: GrupoRegra
  index: React.Key | number
  parceiroId: string
  atualizarDados: () => void
}

export default function ItemGrupoRegraView({ _io, row, index, atualizarDados }: IOProps<ItemGrupoRegraIO, ItemGrupoRegraProps>) {
  return (
    <>
      <ModalGrupoRegra atualizarDados={atualizarDados} isShow={_io.modalAberto} isClosed={_io.fecharModal} formData={row} />
      <TableRow key={index}>
        <TableCell align="center">{row.grupoId}</TableCell>
        <TableCell align="center">{row.nome}</TableCell>
        <TableCell align="center">{row.descricao}</TableCell>
        <TableCell align="center">{row?.ativo ? 'Ativo' : 'Inativo'}</TableCell>
        <TableCell align="center">
          <div>
            <IconeButton color="inherit" descricao="ações" icone={<MoreVertIcon />} acao={_io.abrirMenu} />
            <Menu id="simple-menu" anchorEl={_io.anchorEl} keepMounted open={Boolean(_io.anchorEl)} onClose={_io.fecharMenu}>
              {EditarItemTabela(row, _io.handleEdit, _io.handleStatus).map((itemMenu: ReactNode) => {
                return itemMenu
              })}
            </Menu>
          </div>
        </TableCell>
      </TableRow>
    </>
  )
}
