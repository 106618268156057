export const SinoIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={25} height={25} fill="none">
    <rect width={24} height={24} x={0.529} y={0.5} fill="#3289FF" rx={12} />
    <path
      fill="#fff"
      d={`M16.529 13.287v-2.12a4 4 0 0 0-3.334-3.94V6.5a.667.667 0 1 0-1.333 
      0v.727a4 4 0 0 0-3.333 3.94v2.12a2 2 0 0 0-1.334 1.88V16.5a.667.667 0 0 
      0 .667.667h2.093a2.667 2.667 0 0 0 5.147 0h2.093a.667.667 0 0 0 
      .667-.667v-1.333a2 2 0 0 0-1.333-1.88Zm-6.667-2.12a2.667 2.667 0 1 1 
      5.333 0v2H9.862v-2Zm2.667 6.666a1.334 1.334 0 0 1-1.147-.666h2.293a1.333 
      1.333 0 0 1-1.146.666Zm4-2h-8v-.666a.667.667 0 0 1 .666-.667h6.667a.667.667 
      0 0 1 .667.667v.666Z`}
    />
  </svg>
)
