import { Grid, InputLabel, MenuItem, Select, TextField } from '@material-ui/core'
import { IOProps } from 'react-compose-io'
import { Control, Controller, FieldValues } from 'react-hook-form'
import { TRANSFORMACAO_INFO } from '../../../constants/transformacao'
import { TIPO_PARAMETRO } from '../../../constants/utilConstants'
import { FormFieldsProps, TipoParametro } from '../../../types/regraApiType'
import { ItemRegraTransformacaoAcaoForm, ItemRegraTransformacaoCondicaoForm } from '../../../types/transformacaoApiTypes'
import { TooltipComponent } from '../../Tooltip/Tooltip'
import { FieldsTipoParametroIO } from './FieldsTipoParametro.IO'

export type FieldsTipoParametroProps = {
  indexItensRegraForm: number
  itensRegraTransformacaoForm: ItemRegraTransformacaoAcaoForm[] | ItemRegraTransformacaoCondicaoForm[]
  cabecalhos: string[]
  control: Control<FieldValues>
  itensCampo: FormFieldsProps[]
  isCondicao?: boolean
}

export default function FieldsTipoParametroView({
  _io,
  indexItensRegraForm,
  itensRegraTransformacaoForm,
  cabecalhos,
  control,
  itensCampo
}: IOProps<FieldsTipoParametroIO, FieldsTipoParametroProps>) {
  const { FormControl, FormGroup } = _io.styles

  return (
    <>
      <Grid item sm={12} md={2}>
        <FormGroup>
          <Controller
            render={({ field }) => (
              <FormControl>
                <InputLabel id="demo-simple-select-label">Tipo Parâmetro</InputLabel>
                <Select {...field} id="to" label="Tipo Parâmetro">
                  {itensCampo
                    .filter((filtroTipoParametro) => filtroTipoParametro.operacaoId === itensRegraTransformacaoForm[indexItensRegraForm]?.operacaoId)[0]
                    ?.tipoParametros?.map((tipoParametro: TipoParametro) => (
                      <MenuItem key={tipoParametro.tipoParametroId} value={tipoParametro.tipoParametroId}>
                        {tipoParametro.descricao}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            )}
            name={_io.verificarCondicao('tipoParametro')}
            control={control}
          />
          <TooltipComponent texto={TRANSFORMACAO_INFO.acao.tipoParametro} />
        </FormGroup>
      </Grid>
      {itensRegraTransformacaoForm[indexItensRegraForm]?.parametro.tipoParametro === TIPO_PARAMETRO.coluna && (
        <Grid item xs={12} sm={12} md={2}>
          <FormGroup>
            <Controller
              render={({ field }) => (
                <FormControl>
                  <InputLabel id="demo-simple-select-label">Coluna</InputLabel>
                  <Select {...field} id="to" label="Coluna">
                    {cabecalhos.map((cabecalho: string, cabecalhoIndex: number) => (
                      <MenuItem key={cabecalhoIndex} value={cabecalho}>
                        {cabecalho}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
              name={_io.verificarCondicao('parametro')}
              control={control}
            />
            <TooltipComponent texto={TRANSFORMACAO_INFO.acao.parametroColuna} />
          </FormGroup>
        </Grid>
      )}
      {itensRegraTransformacaoForm[indexItensRegraForm]?.parametro.tipoParametro === TIPO_PARAMETRO.criterio && (
        <Grid item xs={12} sm={12} md={2}>
          <FormGroup>
            <Controller
              render={({ field }) => <TextField fullWidth {...field} size="small" label="Parâmetro" variant="outlined" />}
              name={_io.verificarCondicao('parametro')}
              control={control}
            />
            <TooltipComponent texto={TRANSFORMACAO_INFO.acao.parametroCriterio} />
          </FormGroup>
        </Grid>
      )}
    </>
  )
}
