import IconButton from '@material-ui/core/IconButton'
import TableCell from '@material-ui/core/TableCell'
import styled from 'styled-components'
import { customTheme } from './../../../../styles/customTheme'

export const collapseInadimplenciaItemStyles = {
  TableCellIcon: styled(TableCell)`
    display: flex;
    align-items: center;
  `,
  TableCellDetalhes: styled(TableCell)`
    padding-bottom: 0px;
    padding-top: 0px;
  `,
  IconButton: styled(IconButton)`
    flex: 0 0 auto;
    background-color: ${customTheme.colors.grey01};
    margin: 12px 4px 12px 0px;
    padding: 0px;
    overflow: visible;
    font-size: ${customTheme.fontSize[16]};
    border-radius: 0px !important;
  `
}
