import { Box, FormControl, Grid, IconButton, InputLabel, MenuItem, Select } from '@material-ui/core'
import { Cancel } from '@material-ui/icons'
import { IOProps } from 'react-compose-io'
import { Controller } from 'react-hook-form'
import { TipoLancamentoParceiro } from '../../../../types/parceiroApiTypes'
import { ModalVisualizarAssociacao } from '../ModalVisualizarAssociacao/index'
import { NovaAssociacaoIO } from './NovaAssociacao.IO'
import { Tipografia } from './../../../../components/Tipografia/index'
import { Botao } from './../../../../components/Botao/index'

export type NovaAssociacaoProps = {
  tipoLancamento: TipoLancamentoParceiro[]
}
export function NovaAssociacaoView({ _io }: IOProps<NovaAssociacaoIO, NovaAssociacaoProps>) {
  const { Container, Label, ContainerBox, CardContent } = _io.styles
  return (
    <Container>
      <CardContent>
        <Tipografia variant="h6">Adicionar nova associação</Tipografia>
        <form>
          <Grid container spacing={2}>
            <ContainerBox>
              {_io.condicoes.fields.map((condicaoAssociacao, condicaoAssociacaoIndex) => {
                return (
                  <Grid key={condicaoAssociacao.id} alignItems="center" container item sm={12} md={12} spacing={2}>
                    <Grid item sm={12} md={1}>
                      {condicaoAssociacaoIndex < 1 ? <Label>Se</Label> : <Label>e</Label>}
                    </Grid>
                    <Grid item sm={12} md={2}>
                      <Controller
                        render={({ field }) => (
                          <FormControl variant="outlined" size="small" fullWidth>
                            <InputLabel id="demo-simple-select-label">Operação</InputLabel>
                            <Select {...field} id="to" label="Operação">
                              {_io.condicaoList.map((condicaoLista, condicaoListaIndex) => (
                                <MenuItem key={condicaoListaIndex} value={condicaoLista.operacaoId}>
                                  {condicaoLista.nome}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        )}
                        name={`condicoes.${condicaoAssociacaoIndex}.operacaoId` as `condicoes.0.operacaoId`}
                        control={_io.control}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={3}>
                      <Controller
                        render={({ field }) => (
                          <FormControl variant="outlined" size="small" fullWidth>
                            <InputLabel id="demo-simple-select-label">Dado</InputLabel>
                            <Select {...field} id="to" label="Dado">
                              {_io.dadoField.map((dadosAssociacao, dadosAssociacaoIndex) => (
                                <MenuItem key={dadosAssociacaoIndex} value={dadosAssociacao}>
                                  {dadosAssociacao}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        )}
                        name={`condicoes.${condicaoAssociacaoIndex}.dado` as `condicoes.0.dado`}
                        control={_io.control}
                      />
                    </Grid>
                    <Grid item sm={12} md={2}>
                      <Controller
                        render={({ field }) => (
                          <FormControl variant="outlined" size="small" fullWidth>
                            <InputLabel id="demo-simple-select-label">Operador</InputLabel>
                            <Select {...field} id="to" label="Operador">
                              {_io.condicaoList
                                .filter(
                                  (filtrarCondicaoLista) => filtrarCondicaoLista.operacaoId === _io.condicaoSelecionada[condicaoAssociacaoIndex]?.operacaoId
                                )[0]
                                ?.operadores.map((operador, operadorIndex) => (
                                  <MenuItem key={operadorIndex} value={operador}>
                                    {operador}
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                        )}
                        name={`condicoes.${condicaoAssociacaoIndex}.operador` as `condicoes.0.operador`}
                        control={_io.control}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={3}>
                      <Controller
                        render={({ field }) => (
                          <FormControl variant="outlined" size="small" fullWidth>
                            <InputLabel id="demo-simple-select-label">Parametro</InputLabel>
                            <Select {...field} id="to" label="Parametro">
                              {_io.tipoLancamento.map((parametro) => (
                                <MenuItem key={parametro.tipoLancamentoParceiroId} value={parametro.descricao}>
                                  {parametro.descricao}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        )}
                        name={`condicoes.${condicaoAssociacaoIndex}.parametro.parametro` as `condicoes.0.parametro.parametro`}
                        control={_io.control}
                      />
                    </Grid>
                    <Grid item sm={12} md={1}>
                      {_io.condicoes.fields.length > 1 && (
                        <IconButton onClick={() => _io.condicoes.remove(condicaoAssociacaoIndex)}>
                          <Cancel />
                        </IconButton>
                      )}
                    </Grid>
                  </Grid>
                )
              })}
              <Botao cor="primaria" estilo="text" tamanho="pequeno" textoBotao="+ Adicionar condição" onClick={_io.adicionarMaisCondicoes} />
            </ContainerBox>

            <ContainerBox>
              {_io.acoes.fields.map((acao, acaoIndex) => {
                return (
                  <Grid key={acao.id} alignItems="center" container item sm={12} md={12} spacing={2}>
                    <Grid item sm={12} md={1}>
                      {acaoIndex < 1 ? <Label>Então</Label> : <Label>e</Label>}
                    </Grid>
                    <Grid item xs={12} sm={12} md={5}>
                      <Controller
                        render={({ field }) => (
                          <FormControl variant="outlined" size="small" fullWidth>
                            <InputLabel id="demo-simple-select-label">Operação</InputLabel>
                            <Select {...field} disabled label="Operação">
                              <MenuItem key={acaoIndex} value="TipoLancamentoOperacao">
                                Tipo lançamento eFácil
                              </MenuItem>
                            </Select>
                          </FormControl>
                        )}
                        name={`acoes.${acaoIndex}.operacaoId` as `acoes.0.operacaoId`}
                        control={_io.control}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <Controller
                        render={({ field }) => (
                          <FormControl variant="outlined" size="small" fullWidth>
                            <InputLabel id="demo-simple-select-label">Parametro</InputLabel>
                            <Select {...field} label="Parametro">
                              {_io.tipoLancamentoList.map((parametroLista, parametroListaIndex) => (
                                <MenuItem key={parametroListaIndex} value={parametroLista.tipoLancamentosId}>
                                  {parametroLista.nome}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        )}
                        name={`acoes.${acaoIndex}.parametro.parametro` as `acoes.0.parametro.parametro`}
                        control={_io.control}
                      />
                    </Grid>
                  </Grid>
                )
              })}
            </ContainerBox>
            <Grid container item justifyContent="space-between">
              <Box>
                <Botao onClick={_io.handlePreVisualize} textoBotao={'Pré-visualizar'} cor="primaria" estilo="outlined" />
              </Box>
              <Box>
                <Grid container item spacing={2}>
                  <Grid item>
                    <Botao onClick={_io.retornarPaginaAnterior} textoBotao={'Cancelar'} cor="primaria" estilo="outlined" />
                  </Grid>
                  <Grid item>
                    <Botao onClick={_io.handleSubmit(_io.submeterAssociacao)} textoBotao={'Salvar'} cor="primaria" estilo="contained" />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </form>
      </CardContent>
      <ModalVisualizarAssociacao abrirModal={_io.abrirModal} handleFecharModal={_io.handleFecharModal} preVisualizar={_io.preVisualizar} />
    </Container>
  )
}
