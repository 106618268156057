import SearchIcon from '@material-ui/icons/Search'
import { IOProps } from 'react-compose-io'
import { InputPesquisaIO } from './InputPesquisa.IO'

export type InputPesquisaProps = {
  valorBuscado: string
  setValorBuscado: React.Dispatch<React.SetStateAction<string>>
  onChange: (a: any) => void
}

function InputPesquisaView({ _io, valorBuscado }: IOProps<InputPesquisaIO, InputPesquisaProps>) {
  const { Container, ContainerInput, InputSearch } = _io.styles
  return (
    <Container>
      <ContainerInput>
        <SearchIcon fontSize={'small'} />
        <InputSearch type="text" value={valorBuscado} placeholder="Buscar" onChange={_io.tratarValorBuscado}></InputSearch>
      </ContainerInput>
    </Container>
  )
}

export default InputPesquisaView
