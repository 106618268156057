import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import msgConstants from '../../constants/msgConstants'
import RegularizacaoOPsTitulosManager from '../../services/api/regularizacaoOpsTitulos/regularizacaoOPsTitulosManager'
import { loadingReset, loadingUpdate } from '../../store/actions/loadingAction'
import { PeriodoPendenciasRegularizacaoCicloForm, ValorCiclosPendentes } from '../../types/regularizacaoOPsTitulosTypes'
import { dataInicialMes, formatarData, validarTexto } from '../../utils/stringUtils'
import { regularizacaoCiclosResumoStyles } from './RegularizacaoCiclosResumo.style'

export function regularizacaoCiclosResumoIO() {
  const dispatch = useDispatch()
  const mesAnoAtual = dataInicialMes()
  const diaMesAnoAtual = String(formatarData(new Date().toISOString(), '-'))
  const [ciclosPendentes, setCiclosPendentes] = useState<ValorCiclosPendentes>({} as ValorCiclosPendentes)

  const buscarCiclosPendentes = (dataInicio: string, dataFim: string) => {
    dispatch(loadingUpdate(true))
    RegularizacaoOPsTitulosManager.buscarCiclosPendentes(dataInicio, dataFim)
      .then((response: ValorCiclosPendentes) => {
        setCiclosPendentes(response)
      })
      .catch(() => {
        toast.error(msgConstants.REGULARIZACAO.erroAoBuscarPendencias)
      })
      .finally(() => {
        dispatch(loadingReset())
      })
  }

  useEffect(() => {
    buscarCiclosPendentes(mesAnoAtual, diaMesAnoAtual)
  }, [mesAnoAtual, diaMesAnoAtual, dispatch])

  const mapearValoresRegularizacao = (resumoGeral: ValorCiclosPendentes) => {
    if (resumoGeral) {
      return [
        {
          quantidade: resumoGeral.totalCiclosPendentes,
          valorTotal: validarTexto(resumoGeral.totalCiclosPendentes, '-'),
          tituloCard: 'Ciclos pendentes',
          quantidadeTexto: 'Qtd. de ciclos',
          somenteQuantidade: true
        },
        {
          valorTotal: validarTexto(resumoGeral.valorTotalOPsPendentes, '0'),
          quantidade: resumoGeral.totalOPsPendentes,
          tituloCard: `OP's  pendentes`,
          quantidadeTexto: `Qtd. de op's`,
          somenteQuantidade: false
        },
        {
          valorTotal: validarTexto(resumoGeral.valorTotalPedidosPendentes, '0'),
          quantidade: resumoGeral.totalPedidosPendentes,
          tituloCard: 'Pedidos pendentes',
          quantidadeTexto: 'Qtd. de pedidos',
          somenteQuantidade: false
        }
      ]
    }
    return []
  }

  return {
    styles: regularizacaoCiclosResumoStyles,
    handleBuscarRegularizacaoCiclos: (data: PeriodoPendenciasRegularizacaoCicloForm) => {
      buscarCiclosPendentes(data.dataInicial, data.dataFinal)
    },
    ciclosPendentes,
    mapearValoresRegularizacao
  }
}

export type RegularizacaoCiclosResumoIO = ReturnType<typeof regularizacaoCiclosResumoIO>
