import { TIPO_VALOR_GRAFICO, VALOR_ESCALA_GRAFICO } from '../../../../constants/utilConstants'
import { dadosHistoricoStyles } from './DadosHistorico.style'
import { DadosHistoricoProps } from './DadosHistorico.View'

export function dadosHistoricoIO({ valoresGrafico, tipoValorGrafico }: DadosHistoricoProps) {
  return {
    styles: dadosHistoricoStyles,
    widthGrafico: valoresGrafico.length > 6 ? '620px' : '502px',
    valorDivididoLegenda: tipoValorGrafico === TIPO_VALOR_GRAFICO.MOEDA ? VALOR_ESCALA_GRAFICO.MIL : VALOR_ESCALA_GRAFICO.UM
  }
}

export type DadosHistoricoIO = ReturnType<typeof dadosHistoricoIO>
