import { Grid, Typography } from '@material-ui/core'
import styled from 'styled-components'
import { customTheme } from '../../../../styles/customTheme'
import { Link } from 'react-router-dom'

export const itemListaRegularizacaoStyles = {
  TituloLista: styled(Typography).attrs({
    variant: 'body2'
  })`
    color: ${customTheme.fontFamily.rubik500};
    font-weight: bold;
    line-height: 23px;
    color: ${customTheme.colors.black};
  `,
  SubtituloLista: styled(Typography).attrs({
    variant: 'body2'
  })`
    color: ${customTheme.fontFamily.rubik400};
    font-weight: 400;
    line-height: 23px;
    color: ${customTheme.colors.black};
  `,
  ContainerPedidos: styled(Grid).attrs({
    item: true,
    style: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
      textAlign: 'right',
      justifyContent: 'center'
    }
  })``,
  Link: styled(Link)`
    text-decoration: none;
    color: inherit;

    :hover {
      opacity: 0.8;
    }
  `
}
