import { IOProps } from 'react-compose-io'
import { PopoverFiltroPedidoMktIO } from './PopoverFiltroPedidoMkt.IO'
import { ReactNode } from 'react'

export type PopoverFiltroPedidoMktProps = {
  popoverAberto: null | HTMLElement
  handleClosePopover: () => void
  children: ReactNode
}

export function PopoverFiltroPedidoMktView({
  _io,
  popoverAberto,
  handleClosePopover,
  children
}: IOProps<PopoverFiltroPedidoMktIO, PopoverFiltroPedidoMktProps>) {
  const { ContainerPopoverPedidoMkt, Popover } = _io.styles

  return (
    <Popover
      keepMounted
      anchorEl={popoverAberto}
      open={Boolean(popoverAberto)}
      onClose={handleClosePopover}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
    >
      <ContainerPopoverPedidoMkt>{children}</ContainerPopoverPedidoMkt>
    </Popover>
  )
}
