import styled from 'styled-components'
import TextField from '@material-ui/core/TextField/TextField'
import { ContainerSombra } from '../../components/ContainerSombra'

export const detalheDebitoStyles = {
  Container: styled(ContainerSombra)`
    padding: 24px;
    margin-top: 16px;
  `,
  ContainerFormDataButton: styled.div`
    gap: 8px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  `,
  TextFieldDataFim: styled(TextField)`
    .MuiFormHelperText-root {
      position: absolute;
      margin-top: 40px;
    }
  `,
  ContainerFormulario: styled.form`
    margin-top: 51px;
  `
}
