import { useState } from 'react'
import { useDispatch } from 'react-redux'
import TipoLancamentoManager from '../../../../services/api/tipoLancamento/tipoLancamentoManager'
import { lancamentoUpdate, openModalDesativar } from '../../../../store/actions/lancamentoAction'
import { Config, defaultHistoricoPadrao, TipoLancamento } from '../../../../types/tipoLancamentoApiTypes'
import { estaVazio } from '../../../../utils/stringUtils'
import { itemLancamentoStyles } from './ItemLancamento.style'
import { ItemLancamentoProps } from './ItemLancamento.View'

export function itemLancamentoIO({ visualizarHistorico, itemLancamentoIndex, setHistoricoPadrao, obterHistoricoPadraoSequencia }: ItemLancamentoProps) {
  const dispatch = useDispatch()
  const [expandirHistoricoTabela, setExpandirHistoricoTabela] = useState<null | HTMLElement>(null)
  const [abrirModal, setAbrirModal] = useState<boolean>(false)
  const [configurarItemLancamento, setConfigurarItemLancamento] = useState({} as Config)

  const expandirHistorico = (event: React.MouseEvent<HTMLButtonElement>) => {
    setExpandirHistoricoTabela(event.currentTarget)
  }

  const fecharHistorico = () => {
    setExpandirHistoricoTabela(null)
  }

  const editarLancamento = (lacamentoSelecionado: TipoLancamento) => {
    if (!estaVazio(lacamentoSelecionado.sequencia)) obterHistoricoPadraoSequencia(lacamentoSelecionado.sequencia.split(' ')[0])
    else setHistoricoPadrao(defaultHistoricoPadrao)

    dispatch(lancamentoUpdate({ ...lacamentoSelecionado }))
    buscarConfiguracaoAtiva()
    setAbrirModal(true)
    setExpandirHistoricoTabela(null)
  }

  const desativarLancamento = (lacamentoSelecionado: TipoLancamento) => {
    dispatch(lancamentoUpdate({ ...lacamentoSelecionado }))
    dispatch(openModalDesativar())
    setExpandirHistoricoTabela(null)
  }

  async function buscarConfiguracaoAtiva() {
    const response: Config = await TipoLancamentoManager.buscarConfigAtiva()
    setConfigurarItemLancamento(response)
  }

  return {
    styles: itemLancamentoStyles,
    abrirModal,
    setAbrirModal,
    configurarItemLancamento,
    expandirCelulaTabela: () => {
      visualizarHistorico(itemLancamentoIndex)
    },
    expandirHistoricoTabela,
    expandirHistorico,
    fecharHistorico,
    desativarLancamento,
    editarLancamento
  }
}

export type ItemLancamentoIO = ReturnType<typeof itemLancamentoIO>
