import { customTheme } from './../../styles/customTheme'
import styled from 'styled-components'
import { Divider } from '@material-ui/core'
import { ContainerSombra } from '../../components/ContainerSombra'

export const controleHistoricoPerdasStyles = {
  Container: styled(ContainerSombra).attrs((cor: { cor: string }) => cor)`
    padding: 24px;
    border-color: ${(props) => props.cor};
    border-style: solid;
    border-width: 1px;
  `,
  Divider: styled(Divider)`
    color: ${customTheme.colors.cinza03};
  `,
  PageDivider: styled(Divider)`
    color: ${customTheme.colors.grey22};
    height: 2px;
    margin: 20px 0px;
  `
}
