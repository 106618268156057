import { Grid } from '@material-ui/core'
import { IOProps } from 'react-compose-io'
import { Control, DeepMap, FieldError, UseFormHandleSubmit, UseFormSetValue } from 'react-hook-form'
import { CardValorQuantidade } from '../../../../components/CardValorQuantidade/index'
import { BuscarTitulosForm, CardTitulos, CardTitulosParceiro } from '../../../../types/titulosTypes'
import { CabecalhoListaParceiros } from '../CabecalhoListaParceiros/index'
import { GraficoBarraDias } from '../GraficoBarraDias'
import { GraficoDonut } from '../GraficoDonut'
import { ListaTitulosParceiros } from '../ListaTitulosParceiros/index'
import { TitulosGeradosIO } from './TitulosGerados.IO'

export type TitulosGeradosProps = {
  setDataFinalTitulosGerados: React.Dispatch<React.SetStateAction<string>>
  setDataInicialTitulosGerados: React.Dispatch<React.SetStateAction<string>>
  dataFinalTitulosGerados: string
  dataInicialTitulosGerados: string
  dataInicialTitulosGeradosAlterada: string
  dataFinalTitulosGeradosAlterada: string
  controleFormulario: Control<BuscarTitulosForm>
  setCarregarBotao: React.Dispatch<React.SetStateAction<boolean>>
  carregarBotao: boolean
  setValue: UseFormSetValue<BuscarTitulosForm>
  handleSubmite: UseFormHandleSubmit<BuscarTitulosForm>
  gerarRelatorioTitulosGerados: (buscarRelatorio: () => void, dataInicio: string, parceiroId?: number, dataFim?: string) => Promise<void>
  erroPreenchimento: DeepMap<BuscarTitulosForm, FieldError>
}

export function TitulosGeradosView({
  _io,
  controleFormulario,
  handleSubmite,
  carregarBotao,
  erroPreenchimento,
  dataInicialTitulosGerados,
  dataFinalTitulosGerados
}: IOProps<TitulosGeradosIO, TitulosGeradosProps>) {
  const { CardCabecalho, ContainerBranco } = _io.styles

  return (
    <div>
      <CabecalhoListaParceiros
        controleFormulario={controleFormulario}
        buscarTitulos={handleSubmite(_io.handleBuscarTitulos)}
        atualizarTitulos={_io.atualizarDadosDataAtual}
        botaoCarregando={carregarBotao}
        tituloTela="Títulos Gerados"
        titulosAbertos={false}
        tituloDataFinal=""
        tituloDataInicial="Período"
        erroPreenchimento={erroPreenchimento}
        componenteId="titulosGerados"
      />
      {_io.titulosGerados.resumoGeral && _io.titulosGerados.resumoParceiros.length > 0 && (
        <div id="titulosGerados">
          <CardCabecalho>
            <Grid container spacing={1} direction="row">
              {_io.titulosGerados.resumoGeral &&
                _io.mapearResumoTitulosGerados(CardTitulos.fromGerados(_io.titulosGerados.resumoGeral)).map((titulosGeral, indexTitulosGeral) => (
                  <Grid item xs={12} sm={12} md={4} lg={4} key={indexTitulosGeral} id="card-valor-quantidade-gerados">
                    <CardValorQuantidade
                      textoTooltip={titulosGeral.tooltip}
                      tituloCard={titulosGeral.titulo}
                      valorTotal={titulosGeral.valor}
                      exibirBotaoExportar={titulosGeral.primeiroCard}
                      quantidade={titulosGeral.quantidade}
                      dataPeriodo={''}
                      valorPesquisado={false}
                      exportarRelatorioParceiro={() => {
                        _io.exportarRelatorioPeriodo(dataInicialTitulosGerados, undefined, dataFinalTitulosGerados)
                      }}
                      status={titulosGeral.status}
                      url={titulosGeral.url}
                      fonteMargemTituloGerado={'20px'}
                      quantidadeTexto={'Qtd. de títulos'}
                      somenteQuantidade={false}
                      dataComparacao={dataFinalTitulosGerados}
                    />
                  </Grid>
                ))}
              <Grid item xs={12} sm={12} md={8} lg={8} id="grafico-dias-gerados">
                <GraficoBarraDias listaTitulosParceiros={_io.titulosGerados.dias} />
              </Grid>
            </Grid>
          </CardCabecalho>
          <ContainerBranco>
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <>
                  <ListaTitulosParceiros
                    tituloCard="Títulos gerados por parceiros"
                    dataFinalFormulario={dataFinalTitulosGerados}
                    dataInicialFormulario={dataInicialTitulosGerados}
                    valorPesquisado={false}
                    listaParceiros={
                      _io.titulosGerados.resumoParceiros && _io.titulosGerados.resumoParceiros?.map((resumo) => CardTitulosParceiro.fromGerados(resumo))
                    }
                    exportarRelatorioPeriodo={_io.exportarRelatorioPeriodo}
                    titulosAbertos={false}
                  />
                </>
              </Grid>
              <Grid item xs={4}>
                <div>
                  <GraficoDonut
                    dataGrafico={''}
                    textoValorTotal="Títulos gerados"
                    listaTitulosParceiros={
                      _io.titulosGerados.resumoParceiros && _io.titulosGerados.resumoParceiros?.map((resumo) => CardTitulosParceiro.fromGerados(resumo))
                    }
                  />
                </div>
              </Grid>
            </Grid>
          </ContainerBranco>
        </div>
      )}
    </div>
  )
}
