import { useFormContext } from 'react-hook-form'
import { GrupoRegra } from '../../../types/transformacaoApiTypes'
import { stylesFieldForms } from '../styles'

import { FieldsDescricaoProps } from './FieldsDescricao.View'

export function fieldsDescricaoIO({ grupoRegraLista }: FieldsDescricaoProps) {
  const { control } = useFormContext()

  const gruposRegraListSelect = grupoRegraLista.map((grupos: GrupoRegra) => ({
    value: grupos.grupoId,
    label: grupos.nome
  }))

  return {
    styles: stylesFieldForms,
    control,
    gruposRegraListSelect
  }
}
export type FieldsDescricaoIO = ReturnType<typeof fieldsDescricaoIO>
