import { inputPesquisaStyles } from './InputPesquisa.style'
import { InputPesquisaProps } from './InputPesquisa.View'

export function inputPesquisaIO({ setValorBuscado }: InputPesquisaProps) {
  return {
    styles: inputPesquisaStyles,
    labelButton: 'Buscar',
    tratarValorBuscado: (event: any) => setValorBuscado(event.target.value)
  }
}

export type InputPesquisaIO = ReturnType<typeof inputPesquisaIO>
