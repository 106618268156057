import styled from 'styled-components'
import { Tipografia } from '../../../../components/Tipografia'
import { customTheme } from '../../../../styles/customTheme'

export const ItemControleComissaoPedidoStyles = {
  ValorRecebido: styled(Tipografia).attrs((props: { valorrecebido: number; valorpedido: number }) => props)`
    color: ${(props) => (props.valorrecebido >= props.valorpedido ? customTheme.colors.verde05 : customTheme.colors.laranja06)};
    display: inline;
  `,

  TipografiaInline: styled(Tipografia)`
    display: inline;
  `
}
