import { Box, TableCell, TableRow, Typography } from '@material-ui/core'
import styled from 'styled-components'
import { customTheme } from '../../../../styles/customTheme'

type CorLinhaTabela = {
  corLinha: boolean
}

export const itemLancamentoStyles = {
  Container: styled.div``,
  BotaoMenu: styled(Box).attrs({
    marginRight: '5px'
  })``,
  LinhaTabela: styled(TableRow).attrs({
    backgroundColor: `${customTheme.colors.blue01}`,
    style: {
      cursor: 'pointer',
      paddingTop: 0,
      paddingBottom: 0
    }
  })`
    background-color: ${({ corLinha }: CorLinhaTabela) => (corLinha ? customTheme.colors.blue01 : undefined)};
  `,
  ContainerTabela: styled(Box).attrs({
    borderRadius: '5px'
  })`
    border-radius: 5px;
    padding: 8px;
  `,
  Linha: styled(TableCell)`
    padding-top: 0px;
    padding-bottom: 0px;
  `,
  TituloHistorico: styled(Typography).attrs({
    variant: 'body1'
  })`
    margin-bottom: 0px;
    margin-top: 3px;
  `,
  SubtituloHistorico: styled.span``,
  LinhaEsquerda: styled(TableCell).attrs({
    align: 'left'
  })`
    margin-bottom: 0px;
    margin-top: 3px;
  `,
  LinhaCentro: styled(TableCell).attrs({
    align: 'center'
  })``,
  TituloTabela: styled.h3`
    margin-bottom: 0px;
    margin-top: 3px;
  `,
  SubtituloTabela: styled.h4`
    margin-bottom: 0px;
    margin-top: 3px;
  `,
  BoxMenu: styled(Box)`
    display: flex;
    flex-direction: row;
    gap: 8px;
  `
}
