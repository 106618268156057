import { IOProps } from 'react-compose-io'
import { Dias } from '../../../../types/titulosTypes'
import { GraficoBarraDiasIO } from './GraficoBarraDias.IO'
import Chart from 'react-apexcharts'

export type GraficoBarraDiasProps = {
  listaTitulosParceiros: Dias[]
}

export function GraficoBarraDiasView({ _io }: IOProps<GraficoBarraDiasIO, GraficoBarraDiasProps>) {
  const { Conteudo, TituloContainer, Container } = _io.styles
  return (
    <Container>
      <TituloContainer>Por dia</TituloContainer>
      <Conteudo>
        <Chart options={_io.options} series={_io.series} type="bar" width="700" height="120" />
      </Conteudo>
    </Container>
  )
}
