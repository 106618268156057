import { Processamento, ResponsePaginado } from './genericoType'

export type HistoricoRegularizacao = {
  contas: ContasResumos[]
  historico: HistoricoUsuario
  urlRelatorio: string
  processamento: Processamento
  arquivoId: string
}

export type ContasResumos = {
  contaContabil: string
  quantidade: number
  valor: number
}

export type ResumoTotal = {
  quantidade: number
  contasContabeis: number
  valor: number
}

export type ValidacaoPlanilhaResponse = {
  resumos: ContasResumos[]
  total: ResumoTotal
}

export type HistoricoUsuario = {
  dataReferencia: string
  funcionario: Funcionario
}

export type Funcionario = {
  id: number
  nome: string
}

export const valorDefaultHistoricoRegularizacao: ResponsePaginado<HistoricoRegularizacao> = {
  itens: [],
  pageNumber: 1,
  pageSize: 25,
  totalItens: 0,
  totalPages: 0
}

export const valorDefaultModalValidado: ValidacaoPlanilhaResponse = {
  resumos: [],
  total: {
    contasContabeis: 0,
    quantidade: 0,
    valor: 0
  }
}
