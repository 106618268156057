import { Grid, Step, Typography, StepContent, FormControl, InputLabel, Select, MenuItem, TextField } from '@material-ui/core'
import { IOProps } from 'react-compose-io'
import { Dispatch, SetStateAction } from 'react'
import { HistoricoChamadoIO } from './HistoricoChamado.IO'
import edicaoIcon from '../../../../assets/icons/edit.svg'
import edicaoDesabilitadaIcon from '../../../../assets/icons/edit-desabilitado.svg'
import { ControleComissaoChamadoStatusMap, ControleComissaoChamadoStatus, ControleComissaoChamados } from '../../../../types/controleComissaoTypes'
import { corFundoStatus, corTextoStatus } from '../../ControleComissao.IO'
import { FiberManualRecord as FiberManualRecordIcon } from '@material-ui/icons'
import { Controller } from 'react-hook-form'
import { formateDate } from '../../../../utils/stringUtils'

export type HistoricoChamadoProps = {
  isAdicionandoChamado: boolean
  setIsEditandoChamado: Dispatch<SetStateAction<boolean>>
  chamado: ControleComissaoChamados
  buscarChamados: () => Promise<void>
}

export default function HistoricoChamadoView({ _io, isAdicionandoChamado, chamado }: IOProps<HistoricoChamadoIO, HistoricoChamadoProps>) {
  const { StepLabel, BoxStatus, Stepper, EdicaoIcon, EdicaoDesabilitadoIcon, HighlightOff, CheckCircleOutline, HistoricoLabel, TypographyChamado } = _io.styles

  function StepIcon() {
    return <FiberManualRecordIcon fontSize="small" />
  }

  const EdicaoIcone = () => (
    <>
      {isAdicionandoChamado ? (
        <EdicaoDesabilitadoIcon src={edicaoDesabilitadaIcon} />
      ) : (
        <EdicaoIcon src={edicaoIcon} onClick={() => _io.handleEditarChamado()} />
      )}
    </>
  )

  return (
    <>
      <Grid container justifyContent="space-between" alignContent="center">
        <Grid item xs={8}>
          <TypographyChamado>
            <Typography variant="h6">Chamado {chamado.numeroChamado}</Typography>
            {_io.editarChamado ? (
              <>
                <HighlightOff color="primary" onClick={() => _io.handleCancelarEdicao()} />
                <CheckCircleOutline color="primary" onClick={_io.handleSubmit(_io.handleConfirmarEdicao)} />
              </>
            ) : (
              EdicaoIcone()
            )}
          </TypographyChamado>
        </Grid>
        <Grid item xs={4}>
          {_io.editarChamado ? (
            <form>
              <Controller
                render={({ field }) => (
                  <FormControl variant="outlined" size="small" fullWidth>
                    <InputLabel>Status</InputLabel>
                    <Select {...field} label="Status">
                      <MenuItem value={ControleComissaoChamadoStatus.EmAndamento}>
                        {ControleComissaoChamadoStatusMap.get(ControleComissaoChamadoStatus.EmAndamento)}
                      </MenuItem>
                      <MenuItem value={ControleComissaoChamadoStatus.Fechado}>
                        {ControleComissaoChamadoStatusMap.get(ControleComissaoChamadoStatus.Fechado)}
                      </MenuItem>
                    </Select>
                  </FormControl>
                )}
                name="status"
                control={_io.control}
              />
            </form>
          ) : (
            <BoxStatus background={corFundoStatus(chamado.statusAtual)} color={corTextoStatus(chamado.statusAtual)}>
              {ControleComissaoChamadoStatusMap.get(chamado.statusAtual)}
            </BoxStatus>
          )}
        </Grid>
      </Grid>

      <Stepper orientation="vertical">
        {_io.editarChamado && (
          <Step expanded={true}>
            <StepLabel StepIconComponent={StepIcon}>
              <Typography variant="subtitle1">Nova Observação</Typography>
            </StepLabel>
            <StepContent>
              <form>
                <Controller render={({ field }) => <TextField {...field} variant="outlined" size="small" fullWidth />} name="descricao" control={_io.control} />
              </form>
            </StepContent>
          </Step>
        )}

        {chamado.chamados.map((chamadoHistoricoItem, index) => (
          <Step key={index} expanded={true}>
            <StepLabel StepIconComponent={StepIcon}>
              <Typography variant="subtitle1">{chamadoHistoricoItem.observacao}</Typography>
            </StepLabel>
            <StepContent>
              <HistoricoLabel>
                {formateDate(chamadoHistoricoItem.data, true).replace(' ', ' às ')} | Usuário: {chamadoHistoricoItem.funcionario.nome}
              </HistoricoLabel>
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </>
  )
}
