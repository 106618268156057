import {
  AtualizarDivergenciasPedidosMktRequest,
  DivergenciaPedidoMktResponse,
  ObterDivergentesResponse,
  ObterDivergenciasPedidosMktQuery
} from '../../../types/cicloPedidosTypes'
import { ResponsePaginado } from '../../../types/genericoType'

import cicloPedidosService from './cicloPedidosService'

const buscarDivergencias = async (parceiroId: number | string): Promise<ObterDivergentesResponse> => cicloPedidosService.buscarDivergencias(parceiroId)

const atualizarDivergenciasTela = async (parceiroId: number | string): Promise<number> => cicloPedidosService.atualizarDivergenciasTela(parceiroId)

const gerarDivergencias = async (parceiroId: number, planilha: FormData): Promise<boolean> => cicloPedidosService.gerarDivergencias(parceiroId, planilha)

const atualizarDivergenciasPedidosMkt = async (parceiroId: number | string, body: AtualizarDivergenciasPedidosMktRequest[]): Promise<boolean> =>
  cicloPedidosService.atualizarDivergenciasPedidosMkt(parceiroId, body)

const buscarDivergenciasPedidosMkt = async (
  parceiroId: number | string,
  query: ObterDivergenciasPedidosMktQuery
): Promise<ResponsePaginado<DivergenciaPedidoMktResponse>> => cicloPedidosService.buscarDivergenciasPedidosMkt(parceiroId, query)

const buscarDivergenciasRelatorio = async (parceiroId: number | string): Promise<string> => cicloPedidosService.buscarDivergenciasRelatorio(parceiroId)

export default {
  buscarDivergencias,
  gerarDivergencias,
  buscarDivergenciasPedidosMkt,
  atualizarDivergenciasPedidosMkt,
  buscarDivergenciasRelatorio,
  atualizarDivergenciasTela
}
