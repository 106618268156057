import { Box } from '@material-ui/core'
import styled from 'styled-components'
import { ContainerSombra } from '../../../../components/ContainerSombra'

export const vincularOPSStyles = {
  ContainerFlex: styled(Box)`
    display: flex;
    justify-content: center;
  `,
  ContainerAdicionarOPs: styled(Box)`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  `,
  Container: styled(ContainerSombra)`
    margin: 20px 0px;
    padding: 24px;
  `
}
