import { Divider, Typography } from '@material-ui/core'
import styled from 'styled-components'
import { ContainerSombra } from '../../../../components/ContainerSombra'
import { customTheme } from '../../../../styles/customTheme'

export const semOcorrenciaBoxStyles = {
  Box: styled(ContainerSombra)`
    margin: auto;
    padding: 16px;
    margin-top: 24px;
    margin-bottom: 24px;
  `,
  Titulo: styled(Typography).attrs({
    variant: 'h6'
  })`
    display: flex;
    align-items: center;
    color: ${customTheme.colors.grey23};
    font-size: ${customTheme.fontSize[18]};
  `,
  Divider: styled(Divider)`
    margin-left: 16px;
  `
}
