import { IOProps } from 'react-compose-io'
import { HistoricoRegularizacao } from '../../components/HistoricoRegularizacao'
import { ModalValidacaoPlanilhaRegularizacao } from '../../components/ModalValidacaoPlanilhaRegularizacao'
import { UploadPlanilhaRegularizacao } from '../../components/UploadPlanilhaRegularizacao'
import { RegularizacaoTitulosIO } from './RegularizacaoTitulos.IO'
import { Tipografia } from '../../components/Tipografia'

export type RegularizacaoTitulosProps = {}

export function RegularizacaoTitulosView({ _io }: IOProps<RegularizacaoTitulosIO, RegularizacaoTitulosProps>) {
  return (
    <>
      <Tipografia variant="h5">Regularização de títulos</Tipografia>
      <UploadPlanilhaRegularizacao
        validarPlanilha={_io.validarPlanilha}
        titulo={'Upload planilha de baixa de títulos'}
        subtitulo="Faça o upload da planilha para fazer a baixa de títulos"
        planilhaPadraoTexto={'Baixe a planilha padronizada antes de começar a importação. A lista de títulos deve estar no formato da planilha padrão.'}
        planilhaPadraoUrl={process.env.REACT_APP_PLANILHA_PADRAO_REGULARIZACAO_TITULOS}
        setArquivoId={_io.setArquivoId}
      />
      <ModalValidacaoPlanilhaRegularizacao
        abrirModalValidarPlanilha={_io.abrirModalValidarPlanilha}
        handleFecharModalValidarPlanilha={_io.handleFecharModalValidarPlanilha}
        handleConfirmarDados={() => _io.efetivarRegularizacaoTitulos()}
        itemListaValidado={_io.informacaoValidadaModal}
        mensagemErroUploadPlanilha={_io.mensagemErroUploadPlanilha}
        descricaoBaixas={'Títulos:'}
      />
      <HistoricoRegularizacao
        historicosRegularizacao={_io.historicosRegularizacao.itens}
        totalItens={_io.totalLinhas}
        buscarHistorico={_io.atualizarHistoricoRegularizacaoTitulos}
        valorSetTimeOut={_io.valorSetTimeOut}
        descricaoBaixas={'Títulos baixados:'}
      />
    </>
  )
}
