import { Grid, TextField } from '@material-ui/core'
import { IOProps } from 'react-compose-io'
import { Controller } from 'react-hook-form'
import { CustomDialog } from '../../../../components/CustomDialog/CustomDialog'
import { ModalAdicionarObservacaoIO } from './ModalAdicionarObservacao.IO'
import { Tipografia } from './../../../../components/Tipografia/index'
import { Botao } from './../../../../components/Botao/index'

export type ModalAdicionarObservacaoProps = {
  isShow: boolean
  isClosed: () => void
  handleConfirmar: (data: { observacao: string }) => Promise<void>
}

export default function ModalAdicionarObservacaoView({ _io, isShow }: IOProps<ModalAdicionarObservacaoIO, ModalAdicionarObservacaoProps>) {
  return (
    <CustomDialog
      open={isShow}
      onClose={_io.fecharModal}
      id="modal-iniciar-novo-ciclo"
      titulo="Adicionar Observação"
      habilitarAcoes
      fecharHabilitado
      fecharModal={_io.fecharModal}
      actions={
        <Grid container spacing={2} alignContent="center" justifyContent="center">
          <Grid item>
            <Botao textoBotao="Cancelar" cor="primaria" estilo="text" onClick={_io.fecharModal} width="156px" />
          </Grid>
          <Grid item>
            <Botao textoBotao="Confirmar" cor="primaria" estilo="rounded" onClick={_io.handleSubmit(_io.submit)} width="156px" />
          </Grid>
        </Grid>
      }
    >
      <Tipografia>Adicione no campo de Observações o que deseja</Tipografia>
      <Tipografia>registrar a respeito do pedido selecionado.</Tipografia>
      <br />
      <form>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Controller
              render={({ field }) => (
                <TextField
                  {...field}
                  error={_io.errors?.observacao ? true : false}
                  helperText={_io.errors.observacao?.message}
                  label="Observações"
                  variant="outlined"
                  size="small"
                  fullWidth
                  multiline
                  minRows={3}
                  maxRows={6}
                />
              )}
              name="observacao"
              control={_io.control}
            />
          </Grid>
        </Grid>
      </form>
    </CustomDialog>
  )
}
