import { Collapse, Grid, Menu, TableCell, TableRow, Typography } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { ReactNode } from 'react'
import { IOProps } from 'react-compose-io'
import { EditarItemTabela } from '../../../../components/EditarItemTabela/EditarItemTabela'
import IconeButton from '../../../../components/IconeButton/IconeButton'
import { PAGES } from '../../../../router/routes'
import { cabecalhoTransformacaoRegras } from '../../../../types/tabelaTypes'
import { GrupoRegra } from '../../../../types/transformacaoApiTypes'
import { checarStatus, formateDate } from '../../../../utils/stringUtils'
import { ItemTransformacaoIO } from './ItemTransformacao.IO'

export type ItemTransformacaoProps = {
  linhaItemTransformacao: any
  index: number
  atualizarDados: () => void
}

export function ItemTransformacaoView({ _io, linhaItemTransformacao }: IOProps<ItemTransformacaoIO, ItemTransformacaoProps>) {
  const { BoxHistorico, ChipGrupoRegra, TableRowHistorico } = _io.styles

  const editouSequencia =
    linhaItemTransformacao?.dataEdicao != null ? (
      <Grid item xs={12}>
        <BoxHistorico>
          <Grid item xs={12}>
            <Typography variant="subtitle2">Editou Sequência</Typography>
          </Grid>
          <Grid item xs={12}>
            {formateDate(linhaItemTransformacao?.dataEdicao, true)} - {linhaItemTransformacao?.usuarioEdicao ?? '-'}
          </Grid>
        </BoxHistorico>
      </Grid>
    ) : null

  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={_io.handleAbrirToogle}>
            {_io.abrir ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="center">{linhaItemTransformacao.nomeRegra || ' -- '}</TableCell>
        <TableCell align="center">{linhaItemTransformacao.campo || ' -- '}</TableCell>
        <TableCell align="center">
          {linhaItemTransformacao.grupos.map((grupo: GrupoRegra) => {
            return <ChipGrupoRegra key={grupo.grupoId} label={grupo.nome} />
          })}
        </TableCell>
        <TableCell align="center">{checarStatus(linhaItemTransformacao.ativo)}</TableCell>
        <TableCell align="center">
          <div>
            <IconeButton color="inherit" descricao="ações" icone={<MoreVertIcon />} acao={_io.handleAlterarIconeAncora} />
            <Menu id="simple-menu" anchorEl={_io.ancoraAtiva} keepMounted open={Boolean(_io.ancoraAtiva)} onClose={_io.handleAnularIconeAncora}>
              {EditarItemTabela(
                linhaItemTransformacao,
                _io.editarRegraTransformacao,
                _io.desativarRegraTransformacao,
                PAGES.regraTransformacaoFormEditar(_io.parceiroId, String(linhaItemTransformacao.regraId))
              ).map((itemMenu: ReactNode) => {
                return itemMenu
              })}
            </Menu>
          </div>
        </TableCell>
      </TableRow>
      <TableRowHistorico>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={cabecalhoTransformacaoRegras.length}>
          <Collapse in={_io.abrir} timeout="auto" unmountOnExit>
            <BoxHistorico>
              <Typography variant="subtitle2" gutterBottom component="div">
                Histórico
              </Typography>
              {linhaItemTransformacao?.dataCadastro ? (
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <BoxHistorico>
                      <Grid item xs={12}>
                        <Typography variant="subtitle2">Cadastramento</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="body2">
                          {formateDate(linhaItemTransformacao?.dataCadastro, true)} - {linhaItemTransformacao?.usuarioCadastro ?? '-'}
                        </Typography>
                      </Grid>
                    </BoxHistorico>
                  </Grid>
                  {editouSequencia}
                </Grid>
              ) : null}
            </BoxHistorico>
          </Collapse>
        </TableCell>
      </TableRowHistorico>
    </>
  )
}
