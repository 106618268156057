import { yupResolver } from '@hookform/resolvers/yup'
import { DeepMap, FieldError, useForm } from 'react-hook-form'
import * as yup from 'yup'
import msgConstants from '../../../../constants/msgConstants'
import { PeriodoPendenciasRegularizacaoCicloForm } from '../../../../types/regularizacaoOPsTitulosTypes'
import { dataInicialMes, formatarData } from '../../../../utils/stringUtils'
import { cabecalhoStyles } from './Cabecalho.style'

export function cabecalhoIO() {
  const dataMesAnoAtual = dataInicialMes()
  const diaMesAnoAtual = String(formatarData(new Date().toISOString(), '-'))

  const verificarErros = (handleErros?: DeepMap<PeriodoPendenciasRegularizacaoCicloForm, FieldError>, erro?: FieldError) => {
    return handleErros != undefined && erro != undefined
  }

  const defaultValues: PeriodoPendenciasRegularizacaoCicloForm = {
    dataInicial: dataMesAnoAtual,
    dataFinal: diaMesAnoAtual
  }

  const schema = yup.object().shape({
    dataInicial: yup.string().required(msgConstants.USO_GENERICO.campo_obrigatorio),
    dataFinal: yup.string().required(msgConstants.USO_GENERICO.campo_obrigatorio)
  })

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<PeriodoPendenciasRegularizacaoCicloForm>({
    defaultValues,
    resolver: yupResolver(schema)
  })

  return {
    styles: cabecalhoStyles,
    verificarErros,
    errors,
    dataAtual: String(formatarData(new Date().toISOString(), '-')),
    control,
    handleSubmit
  }
}

export type CabecalhoIO = ReturnType<typeof cabecalhoIO>
