import { Grid } from '@material-ui/core'
import { IOProps } from 'react-compose-io'
import { DebitoInicialTransportadoraBoxIO } from './DebitoInicialTransportadoraBox.IO'
import MartinsTransportadoraIcone from '../../../../assets/icons/martins_transportadora.svg'
import ParceiroTransportadoraIcone from '../../../../assets/icons/parceiro_transportadora.svg'
import PorcentagemIcone from '../../../../assets/icons/porcentagem.svg'
import SomatorioTotalIcone from '../../../../assets/icons/somatorio_total.svg'
import { BoxValor } from '../../../../components/BoxValor'
import { formateCurrency } from '../../../../utils/stringUtils'
import { ResumoDebitosParceiroPorcentagemResponse } from '../../../../types/debitosTypes'
import { CabecalhoExportarRelatorio } from '../../../../components/CabecalhoExportarRelatorio'
import IconButton from '@material-ui/core/IconButton'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import { DebitoBox } from './../DebitoBox/index'

export type DebitoInicialTransportadoraBoxProps = {
  resumosDebitoParceiro: ResumoDebitosParceiroPorcentagemResponse
}

export function DebitoInicialTransportadoraBoxView({
  _io,
  resumosDebitoParceiro
}: IOProps<DebitoInicialTransportadoraBoxIO, DebitoInicialTransportadoraBoxProps>) {
  const { Box, Divider, GridTransportadoras, Titulo, GridValorTotal, GridExpardirIcone } = _io.styles

  return (
    <Box>
      <CabecalhoExportarRelatorio
        url={resumosDebitoParceiro?.urlRelatorio}
        textoTooltip={`O painel de Débito Inicial indica os valores que foram debitados pelos parceiros no ciclo financeiro de repasse.`}
        tituloCabecalho={'Débito inicial'}
      />

      {_io.expandirDebitoInicial ? (
        <Grid container spacing={2} alignContent="center" justifyContent="center" direction="column">
          <GridTransportadoras item>
            <Grid item sm={12} md={12} lg={12}>
              <Titulo variant="h6">Transportadoras</Titulo>
            </Grid>
            <Grid container justifyContent="center" spacing={2}>
              <Grid item sm={6} md={6} lg={6}>
                <BoxValor
                  icone={MartinsTransportadoraIcone}
                  descricao={'Valor total de débito inicial'}
                  valor={formateCurrency(resumosDebitoParceiro.valorTransportadoraMartins)}
                ></BoxValor>
              </Grid>
              <Grid item sm={6} md={6} lg={6}>
                <BoxValor
                  icone={ParceiroTransportadoraIcone}
                  descricao={'Valor total de débito inicial'}
                  valor={formateCurrency(resumosDebitoParceiro.valorTransportadoraParceira)}
                ></BoxValor>
              </Grid>
            </Grid>
          </GridTransportadoras>
          <Grid item sm={12} md={12} lg={12}>
            <Divider />
          </Grid>
          <GridValorTotal item>
            <Grid container justifyContent="center" spacing={2}>
              <Grid item sm={6} md={6} lg={6}>
                <BoxValor icone={SomatorioTotalIcone} descricao={'Total'} valor={formateCurrency(resumosDebitoParceiro.valor)}></BoxValor>
              </Grid>
              <Grid item sm={6} md={6} lg={6}>
                <BoxValor
                  icone={PorcentagemIcone}
                  descricao={'Débito inicial acumulado'}
                  valor={resumosDebitoParceiro.porcentagemTotalAcumulada + ' %'}
                ></BoxValor>
              </Grid>
            </Grid>
          </GridValorTotal>
        </Grid>
      ) : (
        <>
          <DebitoBox
            iconeTransportadora={MartinsTransportadoraIcone}
            urlRelatorio={resumosDebitoParceiro.urlRelatorioTransportadoraMartins}
            valoresMensais={resumosDebitoParceiro.valoresMensais}
            valorDebito={resumosDebitoParceiro.valorTransportadoraMartins}
            quantidadeDebito={resumosDebitoParceiro.quantidadeTransportadoraMartins}
            valoresPorcentagemMensal={resumosDebitoParceiro.resumoPorcentagensTransportadoraMartins.porcentagensMensais}
            valoresPorcentagemAcumulada={resumosDebitoParceiro.resumoPorcentagensAcumuladosTransportadoraMartins.porcentagensMensais}
            porcentagemAcumulada={resumosDebitoParceiro.porcentagemTotalAcumuladaTransportadoraMartins}
            valorMensalConsiderado={'valorTransportadoraMartins'}
            tipoDebito={'inicial'}
          />
          <DebitoBox
            iconeTransportadora={ParceiroTransportadoraIcone}
            urlRelatorio={resumosDebitoParceiro.urlRelatorioTransportadoraParceira}
            valoresMensais={resumosDebitoParceiro.valoresMensais}
            valorDebito={resumosDebitoParceiro.valorTransportadoraParceira}
            quantidadeDebito={resumosDebitoParceiro.quantidadeTransportadoraParceira}
            valoresPorcentagemMensal={resumosDebitoParceiro.resumoPorcentagensTransportadoraParceira.porcentagensMensais}
            valoresPorcentagemAcumulada={resumosDebitoParceiro.resumoPorcentagensAcumuladosTransportadoraParceira.porcentagensMensais}
            porcentagemAcumulada={resumosDebitoParceiro.porcentagemTotalAcumuladaTransportadoraParceira}
            valorMensalConsiderado={'valorTransportadoraParceira'}
            tipoDebito={'inicial'}
          />
          <DebitoBox
            iconeTransportadora={SomatorioTotalIcone}
            urlRelatorio={resumosDebitoParceiro.urlRelatorio}
            valoresMensais={resumosDebitoParceiro.valoresMensais}
            valorDebito={resumosDebitoParceiro.valor}
            quantidadeDebito={resumosDebitoParceiro.quantidade}
            valoresPorcentagemMensal={resumosDebitoParceiro.resumoPorcentagens.porcentagensMensais}
            valoresPorcentagemAcumulada={resumosDebitoParceiro.resumoPorcentagensAcumulados.porcentagensMensais}
            porcentagemAcumulada={resumosDebitoParceiro.porcentagemTotalAcumulada}
            tipoDebito={'inicial'}
          />
        </>
      )}

      <GridExpardirIcone container justifyContent="center">
        <IconButton onClick={() => _io.setExpandirDebitoInicial((prev) => !prev)}>
          {_io.expandirDebitoInicial ? <KeyboardArrowDownIcon fontSize="large" /> : <KeyboardArrowUpIcon fontSize="large" />}
        </IconButton>
      </GridExpardirIcone>
    </Box>
  )
}
