import { TableCell, TableRow, Box } from '@material-ui/core'
import styled from 'styled-components'
import { customTheme } from '../../../../styles/customTheme'

export const itemCicloPedidoStyles = {
  TableRowHistorico: styled(TableRow)`
    background-color: ${customTheme.colors.blue01};
  `,
  TableCellHistorico: styled(TableCell)`
    padding-bottom: 0px;
    padding-top: 0px;
  `,
  ContainerHistorico: styled.div`
    background-color: ${customTheme.colors.white01};
    padding: 10px 18px;
  `,
  BoxHistorico: styled(Box)`
    margin: 8px;
  `
}
