import api from '../../axiosInstance'

import { ConfiguracaoGeral, ParametroContabel, Sistemas, UnidadeNegocio, Evento, Fato } from '../../../types/configuracoesApiType'

const URL_PARAMETROS = `/ParametrosContabeis`

const buscarConfiguracoesCadastradas = async (): Promise<ConfiguracaoGeral[]> => {
  const URL_BUSCAR_CONFIGURACOES = `${URL_PARAMETROS}`
  const response = await api.conectarGet<ConfiguracaoGeral[]>(URL_BUSCAR_CONFIGURACOES)
  return response.data
}

const cadastrarConfiguracoes = async (body: ParametroContabel): Promise<void> => {
  await api.conectarPost<void>(`${URL_PARAMETROS}`, body)
}

const buscarSistemas = async (): Promise<Sistemas[]> => {
  const URL_BUSCAR_SISTEMAS = `${URL_PARAMETROS}/Sistemas`
  const response = await api.conectarGet<Sistemas[]>(URL_BUSCAR_SISTEMAS)
  return response.data
}

const buscarUnidadesNegocios = async (siglaSistema: string): Promise<UnidadeNegocio[]> => {
  const URL_BUSCAR_UNIDADES_NEGOCIO = `${URL_PARAMETROS}/Sistemas/${siglaSistema}/UnidadesNegocios`
  const response = await api.conectarGet<UnidadeNegocio[]>(URL_BUSCAR_UNIDADES_NEGOCIO)
  return response.data
}

const buscarEventos = async (siglaSistema: string, unidadeId: number): Promise<Evento[]> => {
  const URL_BUSCAR_EVENTOS = `${URL_PARAMETROS}/Sistemas/${siglaSistema}/UnidadesNegocios/${unidadeId}/Eventos`
  const response = await api.conectarGet<Evento[]>(URL_BUSCAR_EVENTOS)
  return response.data
}

const buscarFatos = async (siglaSistema: string, unidadeId: number, eventoId: number): Promise<Fato[]> => {
  const URL_BUSCAR_FATOS = `${URL_PARAMETROS}/Sistemas/${siglaSistema}/UnidadesNegocios/${unidadeId}/Eventos/${eventoId}/Fatos`
  const response = await api.conectarGet<Fato[]>(URL_BUSCAR_FATOS)
  return response.data
}

export default {
  buscarConfiguracoesCadastradas,
  cadastrarConfiguracoes,
  buscarSistemas,
  buscarUnidadesNegocios,
  buscarEventos,
  buscarFatos
}
