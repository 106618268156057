import { Collapse, Grid, Menu, TableCell, TableRow, Typography } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import { IOProps } from 'react-compose-io'
import { RegraAssociacao } from '../../../../types/associacaoApiTypes'
import { ItemAssociacaoIO } from './ItemAssociacao.IO'
import { checarStatus, formateDate } from '../../../../utils/stringUtils'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import IconeButton from '../../../../components/IconeButton/IconeButton'
import { EditarItemTabela } from '../../../../components/EditarItemTabela/EditarItemTabela'
import { ReactNode } from 'react'
import { PAGES } from '../../../../router/routes'

export type ItemAssociacaoProps = {
  row: RegraAssociacao
  preVisualizar?: boolean
  index?: number
  atualizarDados: () => void
}

export default function ItemAssociacaoView({ _io, preVisualizar, row }: IOProps<ItemAssociacaoIO, ItemAssociacaoProps>) {
  const { BoxHistorico, TableRowHistorico } = _io.styles

  return (
    <>
      {!preVisualizar && (
        <>
          <TableRow>
            <TableCell>
              <IconButton size="small" onClick={() => _io.setHistoricoAberto(!_io.historicoAberto)}>
                {_io.alterarIconeMenu}
              </IconButton>
            </TableCell>

            <TableCell align="center">{row?.prioridade}</TableCell>
            <TableCell align="center">{_io.validData(row?.condicoes[0].parametro.parametro)}</TableCell>
            <TableCell align="center">{_io.validData(row?.tipoLancamentoEfacil)}</TableCell>
            <TableCell align="center">{_io.validData(row?.contaContabilId)}</TableCell>
            <TableCell align="center">{_io.validData(row?.tipoNaturezaContabil)}</TableCell>
            <TableCell align="center">{_io.validData(row?.tipoAgrupamento)}</TableCell>
            <TableCell align="center">{checarStatus(row.ativo ?? false)}</TableCell>

            <TableCell align="center">
              <div>
                <IconeButton color="inherit" descricao="ações" icone={<MoreVertIcon />} acao={_io.handleClick} />
                <Menu id="simple-menu" anchorEl={_io.ancoraMenu} keepMounted open={Boolean(_io.ancoraMenu)} onClose={_io.handleFecharAncora}>
                  {EditarItemTabela(row, _io.handleEditar, _io.handleDesative, PAGES.associacaoEditar(_io.parceiroId, String(row.regraId))).map(
                    (itemMenu: ReactNode) => {
                      return itemMenu
                    }
                  )}
                </Menu>
              </div>
            </TableCell>
          </TableRow>
          <TableRowHistorico>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
              <Collapse in={_io.historicoAberto} timeout="auto" unmountOnExit>
                <BoxHistorico>
                  <Typography variant="subtitle2" gutterBottom component="div">
                    Histórico
                  </Typography>
                  {row?.dataCadastro && (
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <BoxHistorico>
                          <Grid item xs={12}>
                            <Typography variant="subtitle2">Cadastramento</Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography variant="body2">
                              {formateDate(row?.dataCadastro, true)} - {_io.validData(row?.usuarioCadastro)}
                            </Typography>
                          </Grid>
                        </BoxHistorico>
                      </Grid>
                      {row?.dataEdicao != null && (
                        <Grid item xs={12}>
                          <BoxHistorico>
                            <Grid item xs={12}>
                              <Typography variant="subtitle2">Editou Sequência</Typography>
                            </Grid>
                            <Grid item xs={12}>
                              {formateDate(row?.dataEdicao, true)} - {_io.validData(row?.usuarioEdicao)}
                            </Grid>
                          </BoxHistorico>
                        </Grid>
                      )}
                    </Grid>
                  )}
                </BoxHistorico>
              </Collapse>
            </TableCell>
          </TableRowHistorico>
        </>
      )}
    </>
  )
}
