import { Grid, CircularProgress, Divider } from '@material-ui/core'
import styled from 'styled-components'
import { ContainerSombra } from '../../components/ContainerSombra'

export const controleDebitosStyles = {
  Container: styled(ContainerSombra).attrs((cor: { cor: string }) => cor)`
    padding: 24px;
    border-color: ${(props) => props.cor};
    border-style: solid;
    border-width: 1px;
  `,
  GridContainer: styled(Grid)`
    max-width: 1000px;
    justify-content: center;
    margin: auto;
  `,
  CircularProgress: styled(CircularProgress)`
    margin-top: 24px;
  `,
  Box: styled(ContainerSombra).attrs((props: { transportadora: number }) => props)`
    padding: 16px;
    max-width: 540px;
  `,
  Divider: styled(Divider)`
    height: 194px;
  `
}
