import { Box, TableCell, TablePagination, TableRow } from '@material-ui/core'
import styled from 'styled-components'
import { ContainerSombra } from '../../components/ContainerSombra'
import { customTheme } from '../../styles/customTheme'

export const lancamentoAssociacaoStyles = {
  Container: styled(ContainerSombra)`
    padding: 24px;
  `,
  DivTab: styled.div`
    margin-top: 30px;
    width: 100%;
  `,
  TableRowContainer: styled(TableRow)`
    color: ${customTheme.colors.red06};
    border-bottom: 1px solid ${customTheme.colors.red06};
  `,
  TableCellItem: styled(TableCell)`
    color: ${customTheme.colors.red06};
  `,
  TablePaginationContainer: styled(TablePagination)`
    display: flex;
    align-items: center;
    justify-content: flex-end;
  `,
  BoxTable: styled(Box)`
    padding-bottom: 24px;
  `
}
