import { Grid } from '@material-ui/core'
import { IOProps } from 'react-compose-io'
import { BoxValorIO } from './BoxValor.IO'
import { Tipografia } from './../Tipografia/index'
import { customTheme } from '../../styles/customTheme'
import { Variant } from '@material-ui/core/styles/createTypography'
import { TooltipComponent } from './../Tooltip/Tooltip'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import { SombraTipo } from '../ContainerSombra'

export type BoxValorProps = {
  icone: string
  descricao: string
  tooltipDescricao?: string
  valor: string
  variantDescricao?: 'inherit' | Variant
  variantValor?: string
  width?: string
  descricaoWidth?: string
  heigth?: string
  tipoSombra?: SombraTipo
}

const BoxValorView = ({
  _io,
  icone,
  descricao,
  valor,
  variantValor,
  variantDescricao,
  tooltipDescricao,
  width,
  descricaoWidth,
  heigth,
  tipoSombra
}: IOProps<BoxValorIO, BoxValorProps>) => {
  const { ContainerSombra, GridText, Imagem } = _io.styles

  return (
    <ContainerSombra heigth={heigth} width={width} sombra={tipoSombra}>
      <Grid container direction="row" spacing={2} alignItems="center">
        <Imagem src={icone} />
        <GridText item container direction="column" width={descricaoWidth}>
          <Grid container direction="row" alignItems="center">
            <Tipografia variant={variantDescricao ? variantDescricao : 'subtitle1'} lineHeight="16px" fontWeight="500" colorText={customTheme.colors.grey23}>
              {descricao}
            </Tipografia>
            {tooltipDescricao && <TooltipComponent texto={tooltipDescricao} Icone={HelpOutlineIcon} color={'action'} />}
          </Grid>

          <Tipografia fontSize={variantValor ? variantValor : customTheme.fontSize[24]} fontWeight="600">
            {valor}
          </Tipografia>
        </GridText>
      </Grid>
    </ContainerSombra>
  )
}

export default BoxValorView
