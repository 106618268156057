import { Grid, Typography } from '@material-ui/core'
import { Dispatch, SetStateAction } from 'react'
import { IOProps } from 'react-compose-io'
import { BoxValores } from '../../../../components/BoxValores/index'
import { ContabilizacaoValores, ValidarCicloAjuste } from '../../../../types/conciliacaoApiTypes'
import { ModalAddConta } from '../ModalAddConta/index'
import { ValidacaoCicloIO } from './ValidacaoCiclo.IO'
import { Botao } from '../../../../components/Botao'

export type ValidacaoCicloProps = {
  cicloId: string
  conferirValidacaoCiclo: (validarConciliacao: ValidarCicloAjuste | undefined) => void
  contabilizacaoValores?: ContabilizacaoValores
  setContabilizacaoValores: Dispatch<SetStateAction<ContabilizacaoValores | undefined>>
  adjustValues?: ContabilizacaoValores
}

function ValidacaoCicloView({ _io, contabilizacaoValores }: IOProps<ValidacaoCicloIO, ValidacaoCicloProps>) {
  const { Container, ContainerHeader } = _io.styles
  return (
    <>
      {contabilizacaoValores && (
        <Container>
          <ContainerHeader>
            <Typography variant="h6"> Validação do Ciclo</Typography>
          </ContainerHeader>

          <Grid container spacing={2} alignItems="flex-end" direction="row">
            <Grid item>
              <BoxValores campoTexto="Valor total Crédito" valor={contabilizacaoValores?.credito} />
            </Grid>
            <Grid item>
              <BoxValores campoTexto="Valor total Débito" valor={contabilizacaoValores?.debito} />
            </Grid>
            {contabilizacaoValores?.diferenca == 0 ? (
              <Grid item>
                <BoxValores campoTexto="Diferença" valor={contabilizacaoValores?.diferenca} possuiCor />
              </Grid>
            ) : (
              <>
                <Grid item>
                  <BoxValores campoTexto="Diferença" valor={contabilizacaoValores?.diferenca} possuiErro />
                </Grid>
                <Grid item>
                  <Botao textoBotao="Ajustar" onClick={_io.handleModal} cor="erro" estilo="contained" />
                </Grid>
                <ModalAddConta
                  abrirModal={_io.abrirModal}
                  fecharModal={_io.handleCloseModal}
                  sequencias={_io.arraySequencias}
                  submeterValoresSequencias={_io.handleClickAdjustmentButton}
                  tituloModal="Indicar conta contábil"
                />
              </>
            )}
          </Grid>
        </Container>
      )}
    </>
  )
}
export default ValidacaoCicloView
