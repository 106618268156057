import { Tabs } from '@material-ui/core'
import styled from 'styled-components'
import { customTheme } from '../../styles/customTheme'

export const AntTabs = styled((props) => {
  return <Tabs {...props} classes={{ indicator: 'indicator' }} />
})`
  && {
    border-bottom: 1px solid ${customTheme.colors.white03};
  }

  && .indicator {
    background-color: ${customTheme.colors.azul06};
  }
`
