import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import msgConstants from '../../../../constants/msgConstants'
import { modalAdicionarObservacaoStyles } from './ModalAdicionarObservacao.styles'
import { ModalAdicionarObservacaoProps } from './ModalAdicionarObservacao.View'

export function modalAdicionarObservacaoIO({ isClosed, handleConfirmar }: ModalAdicionarObservacaoProps) {
  const schema = yup
    .object({
      observacao: yup.string().required(msgConstants.USO_GENERICO.campo_obrigatorio)
    })
    .required()

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue
  } = useForm<{ observacao: string }>({
    defaultValues: {
      observacao: ''
    },
    resolver: yupResolver(schema)
  })

  const fecharModal = () => {
    isClosed()
    setValue('observacao', '')
  }

  const submit = (data: { observacao: string }) => {
    handleConfirmar(data)
    setValue('observacao', '')
  }

  return {
    styles: modalAdicionarObservacaoStyles,
    schema,
    control,
    handleSubmit,
    errors,
    fecharModal,
    submit
  }
}

export type ModalAdicionarObservacaoIO = ReturnType<typeof modalAdicionarObservacaoIO>
