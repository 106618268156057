import { IconButton } from '@material-ui/core'
import { IOProps } from 'react-compose-io'
import { BotaoIconeSvg } from '../BotaoIconeSvg'
import { DownloadRelatorioIO } from './DownloadRelatorio.IO'
import { ExportIcon } from '../../assets/icons/ExportIcon'
import { customTheme } from '../../styles/customTheme'
import { CoresIconeProps } from '../BotaoIconeSvg/BotaoIconeSvg.View'
import { handleExportarArquivo } from '../../utils/uploadFileUtils'

export type DownloadRelatorioProps = {
  textoTooltip: string
  gerarRelatorio: () => void
  relatorioDisponivel: boolean
  iconeCarregandoRelatorio: boolean
  url?: string
}

export function DownloadRelatorioView({
  _io,
  url,
  textoTooltip,
  iconeCarregandoRelatorio,
  relatorioDisponivel,
  gerarRelatorio
}: IOProps<DownloadRelatorioIO, DownloadRelatorioProps>) {
  const { TooltipElement, IconeCarregamento } = _io.styles

  const RelatorioDisponivel = (
    <>
      {url && (
        <BotaoIconeSvg
          corIcone={CoresIconeProps.Sucesso}
          onClick={() => handleExportarArquivo(url)}
          icone={<ExportIcon width={24} height={24} color={customTheme.colors.verde05} />}
        />
      )}
    </>
  )

  const IconeExportar = (
    <>
      {!relatorioDisponivel ? (
        <BotaoIconeSvg onClick={() => gerarRelatorio()} icone={<ExportIcon width={24} height={24} color={customTheme.colors.azul06} />} />
      ) : (
        RelatorioDisponivel
      )}
    </>
  )

  return (
    <TooltipElement title={textoTooltip}>
      {iconeCarregandoRelatorio ? (
        <IconButton>
          <IconeCarregamento />
        </IconButton>
      ) : (
        IconeExportar
      )}
    </TooltipElement>
  )
}
