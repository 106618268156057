import { IOProps } from 'react-compose-io'
import { DetalheInadimplenciaIO } from './DetalheInadimplencia.IO'
import { Tipografia } from './../../components/Tipografia/index'
import { Grid, TextField } from '@material-ui/core'
import { Controller } from 'react-hook-form'
import { Botao } from '../../components/Botao'
import { ModalClassificarPedidos } from '../../components/ModalClassificarPedidos'
import { PopoverFiltroClassificacoes } from './../../components/PopoverFiltroClassificacoes'
import { PopoverFiltroPossiveisTratativas } from './../../components/PopoverFiltroPossiveisTratativas'
import { PopoverFiltroPedidoMkt } from '../../components/PopoverFiltroPedidoMkt'
import { PopoverFiltroParceiro } from '../../components/PopoverFiltroParceiro'
import { TabelaDetalhesPerdas } from './../../components/TabelaDetalhesPerdas/index'

export type DetalheInadimplenciaProps = {}

export function DetalheInadimplenciaView({ _io }: IOProps<DetalheInadimplenciaIO, DetalheInadimplenciaProps>) {
  const { Container, ContainerFormDataButton, TextFieldDataFim, ContainerFormulario } = _io.styles

  return (
    <>
      <Tipografia variant="h5" fontWeight="500">
        Inadimplência
      </Tipografia>
      <Container>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Tipografia fontWeight="500" variant="h6">
              Detalhes de pedidos inadimplentes
            </Tipografia>
          </Grid>
        </Grid>
        <Grid item></Grid>
        <ContainerFormulario>
          <Grid container spacing={1} justifyContent="space-between" direction="row">
            <Grid item>
              <ContainerFormDataButton>
                <Controller
                  render={({ field }) => (
                    <TextField
                      {..._io.register('dataInicioEmissao', { required: true })}
                      {...field}
                      label="Data de emissão"
                      variant="outlined"
                      size="small"
                      type="date"
                      fullWidth
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  )}
                  name="dataInicioEmissao"
                  control={_io.control}
                />
                <Tipografia>até</Tipografia>
                <Controller
                  render={({ field }) => (
                    <TextFieldDataFim
                      {..._io.register('dataFimEmissao', { required: true })}
                      {...field}
                      label=""
                      variant="outlined"
                      size="small"
                      type="date"
                      fullWidth
                      error={_io.errors?.dataFimEmissao ? true : false}
                      helperText={_io.errors.dataFimEmissao?.message}
                    />
                  )}
                  name="dataFimEmissao"
                  control={_io.control}
                />
                <Botao width="94px" textoBotao="Limpar" cor="primaria" estilo="text" onClick={_io.handleLimpar} />
                <Botao textoBotao="Filtrar" cor="primaria" estilo="outlined" onClick={_io.handleSubmit(_io.handleAplicar)} />
              </ContainerFormDataButton>
            </Grid>

            <Grid item>
              <Botao
                textoBotao="Classificar pedidos"
                estilo="contained"
                cor="primaria"
                onClick={_io.handleAbrirModalClassificarPedido}
                disabled={_io.checklistsSelecionados.length == 0}
              />
            </Grid>
          </Grid>
        </ContainerFormulario>

        <TabelaDetalhesPerdas
          cabecalhoTabela={_io.cabecalhosTabela}
          checklistsSelecionados={_io.checklistsSelecionados}
          handleSelectAllCheckList={_io.handleCleanAllCheckList}
          quantidadeTotalDePerdas={_io.detalhesPedidosInadimplencia ? _io.detalhesPedidosInadimplencia.totalItens : 0}
          pagina={_io.pagina}
          linhasPorPagina={_io.linhasPorPagina}
          handleMudarPagina={_io.onMudancaPagina}
          handleMudarTamanhoPagina={_io.onMudancaLinhasPorPagina}
        >
          {_io.detalhesPedidosInadimplencia && _io.detalhesPedidosInadimplencia.itens.map(_io.renderLinhasTabela)}
        </TabelaDetalhesPerdas>
      </Container>

      <ModalClassificarPedidos
        handleConfirmar={_io.handleClassificarPedidos}
        isClosed={_io.fecharModal}
        isShow={_io.modalClassificarPedidosAberto}
        classificacoes={_io.classificacoesInadimplencia}
        classificacaoSugerida={_io.classificacaoSugerida}
      />

      <PopoverFiltroParceiro
        popoverAberto={_io.isFiltroParceiroAberto}
        handleClosePopover={_io.handleCloseFiltro}
        isParceiroChecked={_io.isParceiroChecked}
        handleCheckParceiro={_io.handleCheckFiltroParceiro}
      />

      <PopoverFiltroPedidoMkt popoverAberto={_io.isFiltroPedidoAberto} handleClosePopover={_io.handleCloseFiltro}>
        <Controller
          render={({ field }) => <TextField {...field} label="Buscar n° pedido marketplace" variant="outlined" size="small" fullWidth />}
          name="pedidoMkt"
          control={_io.control}
        />
      </PopoverFiltroPedidoMkt>

      <PopoverFiltroPossiveisTratativas
        popoverAberto={_io.isFiltroPossiveisTratativasAberto}
        handleClosePopover={_io.handleCloseFiltro}
        isPossivelTratativaChecked={_io.isPossivelTratativaChecked}
        handleCheckPossivelTratativa={_io.handleCheckFiltroPossivelTratativa}
        possiveisTratativas={_io.possiveisTratativas}
      />

      <PopoverFiltroClassificacoes
        popoverAberto={_io.isFiltroClassificacoesAberto}
        handleClosePopover={_io.handleCloseFiltro}
        classificacoes={_io.classificacoesInadimplencia}
        isClassificacaoChecked={_io.isClassificacaoChecked}
        handleCheckClassificacao={_io.handleCheckFiltroClassificacoes}
      />
    </>
  )
}
