import { Grid } from '@material-ui/core'
import styled from 'styled-components'

export const graficoDonutClassificacoesStyles = {
  ContainerGrafico: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  `,
  GridLegenda: styled(Grid)`
    width: 13rem;
  `,
  Classificacao: styled.span<{ cor: string }>`
    display: flex;
    align-items: normal;
    gap: 0.25rem;
    font-size: 0.8125rem;
    color: ${(props) => props.cor};
    font-weight: 500;
    line-height: 112%;
    height: 2rem;
    margin-bottom: 0.75rem;

    &::before {
      content: '';
      min-width: 0.8125rem;
      height: 0.8125rem;
      border-radius: 9999px;
      background: ${(props) => props.cor};
    }
  `
}
