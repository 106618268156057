import { IOProps } from 'react-compose-io'
import { GraficoBarrasIO } from './GraficoBarras.IO'
import Chart from 'react-apexcharts'
import { Tipografia } from './../Tipografia/index'

type FuncaoCor1 = ({ dataPointIndex }: { dataPointIndex: number; value: number }) => string
type FuncaoCor2 = ({ value }: { dataPointIndex: number; value: number }) => string
type FuncaoCor3 = () => string

export type GraficoBarrasProps = {
  tituloGrafico?: string
  descricaoColunas: string[][]
  dadosColunas: number[]
  legendaEixoY: string
  valorDivididoLegenda: number
  tipoValor: 'unidade' | 'moeda'
  width?: string
  height?: string
  corBarra: FuncaoCor1 | FuncaoCor2 | FuncaoCor3
  handleClickColuna?: (mes: number) => void
  legend?: {}
}

export function GraficoBarrasView({ _io, tituloGrafico, width, height }: IOProps<GraficoBarrasIO, GraficoBarrasProps>) {
  return (
    <>
      {tituloGrafico && (
        <Tipografia variant="h6" fontWeight="500">
          {tituloGrafico}
        </Tipografia>
      )}
      <Chart options={_io.options} series={_io.series} type="bar" width={width ? width : '472'} height={height ? height : '100%'} />
    </>
  )
}
