import { useState } from 'react'
import { tabelaEditavelErroProcessamentoStyles } from './TabelaEditavelErroProcessamento.styles'
import { TabelaEditavelErroProcessamentoProps } from './TabelaEditavelErroProcessamento.View'

export function tabelaEditavelErroProcessamentoIO({
  corpoTabela,
  cabecalhoTabela,
  editarItensErroProcessamento,
  setCheckboxSelecionado
}: TabelaEditavelErroProcessamentoProps) {
  const cabecalhoEditavel: { title: string; field: string }[] = cabecalhoTabela.map((valorCabecalho, indexCabecalho) => {
    return {
      title: valorCabecalho.toString(),
      field: indexCabecalho.toString()
    }
  })

  const [colunaErroProcessamento] = useState<{ title: string; field: string }[]>(cabecalhoEditavel)
  const [corpoTabelaErroProcessamento] = useState<string[][]>(converterTabelaObjeto(corpoTabela))
  const posicaoUltimaColuna = cabecalhoTabela.length - 1

  function converterTabelaObjeto(valorCorpoErroProcessamento?: string[][]) {
    const linhaTabelaErroProcessamento = valorCorpoErroProcessamento?.map((valor) =>
      valor.map((valorExibidoTabela, indexValorExibidoTabela) => {
        return indexValorExibidoTabela === valor.length - 2 && valorExibidoTabela === 'I'
          ? {
              [indexValorExibidoTabela]: 'IGNORADO'
            }
          : {
              [indexValorExibidoTabela]: valorExibidoTabela
            }
      })
    )

    const linhaModificadaTabela: any[] = []

    linhaTabelaErroProcessamento?.forEach((corpo) => {
      linhaModificadaTabela.push(Object.assign({}, ...corpo))
    })

    return linhaModificadaTabela
  }

  const editarElementosTabela = (itemlinhaErroProcessamento: string[] | undefined, novoItemlinhaErroProcessamento: string[]): Promise<any> => {
    return new Promise((resolve) => {
      const dadosTabelaErroProcessamento = [...corpoTabelaErroProcessamento]
      const itemSelecionadoTabela = dadosTabelaErroProcessamento.find((itemTabela) => itemTabela == itemlinhaErroProcessamento)
      const editarItemErroProcessamento = itemSelecionadoTabela != undefined ? itemSelecionadoTabela : novoItemlinhaErroProcessamento
      const indexLinhaSelecionada = dadosTabelaErroProcessamento.indexOf(editarItemErroProcessamento)
      dadosTabelaErroProcessamento[indexLinhaSelecionada] = novoItemlinhaErroProcessamento
      const converterTabelaArray = Object.assign([], novoItemlinhaErroProcessamento)
      editarItensErroProcessamento(converterTabelaArray, Number(novoItemlinhaErroProcessamento[posicaoUltimaColuna]))
      resolve(novoItemlinhaErroProcessamento)
    })
  }

  const onCheckBoxSelecionado = (erroProcessamento: string[][]) => {
    const indexSelecionados = erroProcessamento.map((itemErro) => {
      return Number(itemErro[posicaoUltimaColuna])
    })
    setCheckboxSelecionado(indexSelecionados)
  }

  return {
    styles: tabelaEditavelErroProcessamentoStyles,
    colunaErroProcessamento,
    corpoTabelaErroProcessamento,
    editarElementosTabela,
    onCheckBoxSelecionado
  }
}

export type TabelaEditavelErroProcessamentoIO = ReturnType<typeof tabelaEditavelErroProcessamentoIO>
