import { Tab } from '@material-ui/core'
import styled from 'styled-components'
import { customTheme } from '../../styles/customTheme'

export const AntTab = styled((props) => {
  return <Tab {...props} classes={{ selected: 'selected' }} />
})`
  && {
    text-transform: none;
    min-width: 72px;
    font-weight: 400;
    margin-right: 32px;
    font-size: 18px;
    color: ${customTheme.colors.cinza05};
  }

  &&:hover {
    color: ${customTheme.colors.azul06};
    opacity: 1;
  }

  &&:focus {
    color: ${customTheme.colors.azul06};
  }

  &&.selected {
    color: ${customTheme.colors.azul06};
    font-weight: 500;
  }
`
