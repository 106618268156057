import { Grid } from '@material-ui/core'
import { IOProps } from 'react-compose-io'
import { Tipografia } from '../../components/Tipografia'
import { ParametrosSelecionarIO } from './ParametrosSelecionar.IO'
import FolderOpenIcon from '@material-ui/icons/FolderOpen'
import FileCopyOutlined from '@material-ui/icons/FileCopyOutlined'
import { InformacaoParceiro } from '../../components/InformacaoParceiro'
import { ABAS_DETALHES_PARCEIROS, PAGES } from '../../router/routes'

export type ParametrosSelecionarProps = {}

export default function ParametrosSelecionarView({ _io }: IOProps<ParametrosSelecionarIO, ParametrosSelecionarProps>) {
  const { Container, LinksContainer, Link, Subtitulo } = _io.styles

  return (
    <>
      <InformacaoParceiro />

      <Container>
        <Tipografia variant="h6">Seleção de parâmentros</Tipografia>
        <Subtitulo variant="subtitle1">Qual tipo de parâmetros você deseja editar?</Subtitulo>
        <LinksContainer>
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <FolderOpenIcon color="primary"></FolderOpenIcon>
            </Grid>
            <Grid item>
              <Link to={PAGES.parametrosRepasse(_io.parceiroId, ABAS_DETALHES_PARCEIROS.tiposLancamentos)}>Referente ao repasse do parceiro</Link>
            </Grid>
          </Grid>
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <FileCopyOutlined color="primary"></FileCopyOutlined>
            </Grid>
            <Grid item>
              <Link to={PAGES.parametrosCicloPedido(_io.parceiroId)}>Referente ao ciclo de pedido</Link>
            </Grid>
          </Grid>
        </LinksContainer>
      </Container>
    </>
  )
}
