import { ApexOptions } from 'apexcharts'
import { customTheme } from '../../styles/customTheme'
import { graficoLinhasStyles } from './GraficoLinhas.style'
import { GraficoLinhasProps } from './GraficoLinhas.View'

export function graficoLinhasIO({ dadosLinhas, descricaoLinhas }: GraficoLinhasProps) {
  return {
    styles: graficoLinhasStyles,
    options: {
      chart: {
        type: 'line',
        dropShadow: {
          enabled: true,
          color: '#000',
          top: 8,
          left: 3,
          blur: 8,
          opacity: 0.1
        },
        toolbar: {
          show: false
        },
        animations: {
          enabled: true
        },
        redrawOnWindowResize: false
      },
      colors: [customTheme.colors.blue14, customTheme.colors.blue13],
      plotOptions: {
        bar: {
          borderRadius: 4,
          columnWidth: '45%'
        }
      },
      legend: {
        show: false
      },
      xaxis: {
        categories: descricaoLinhas,
        labels: {
          style: {
            fontSize: '16px'
          }
        }
      },
      yaxis: {
        labels: {
          formatter: function (value: number) {
            return `${(value * 100).toFixed(2)} %`
          },
          style: {
            fontSize: '16px'
          }
        },
        title: {
          text: 'Porcentagem',
          offsetX: -8,
          style: {
            fontSize: '14px',
            fontFamily: customTheme.fontFamily.rubik400,
            fontWeight: 'none'
          }
        }
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        y: {
          formatter: function (val: number) {
            return `${(val * 100).toFixed(2)} %`
          }
        },
        x: {
          show: false
        },
        z: {
          show: false
        }
      },
      dataLabels: {
        enabled: false
      },
      markers: {
        size: 5,
        strokeColors: 'transparent',
        strokeWidth: 2,
        shape: 'circle',
        radius: 2
      }
    } as ApexOptions,
    series: dadosLinhas
  }
}

export type GraficoLinhasIO = ReturnType<typeof graficoLinhasIO>
