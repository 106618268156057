import { DialogContent, IconButton, DialogActions } from '@material-ui/core'
import styled from 'styled-components'
import { customTheme } from '../../styles/customTheme'

export const modalStyles = {
  CloseButton: styled(IconButton)`
    position: absolute;
    right: 8px;
    top: 8px;
    color: ${customTheme.colors.grey14};
  `,
  DialogActions: styled(DialogActions)`
    margin: 0px;
    padding: 8px;
    justify-content: center;
  `,
  DialogContent: styled(DialogContent)`
    padding: 16px;
  `
}
