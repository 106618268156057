import styled from 'styled-components'
import { ContainerSombra } from '../../../../components/ContainerSombra'

export const graficoBarraSemanasStyles = {
  Container: styled(ContainerSombra)`
    width: 100%;
    height: 100%;
    padding: 20px;
  `,
  ContainerGrafico: styled.div`
    margin-top: 24px;
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
  `
}
