import styled from 'styled-components'
import { Botao } from '../../../../components/Botao'
import { ContainerSombra } from '../../../../components/ContainerSombra'

export const stylesCicloInformacao = {
  Container: styled(ContainerSombra)`
    margin-bottom: 19px;
    padding: 18px;
    flex-grow: 1;
  `,
  UpdateButton: styled(Botao).attrs({
    component: 'span'
  })`
    margin-left: 8px;
  `,
  Input: styled.input`
    display: none;
  `
}
