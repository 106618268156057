import { useCallback, useState } from 'react'

export function useCabecalhoPlanilha() {
  const [headersData, setHeaders] = useState<any>([])

  const getHeadersPlanilha = useCallback((headersSelected: any) => {
    setHeaders(headersSelected)
  }, [])

  return { getHeadersPlanilha, headersData }
}
