import { yupResolver } from '@hookform/resolvers/yup'
import { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import msgConstants from '../../../../constants/msgConstants'
import parceiroManager from '../../../../services/api/parceiro/parceiroManager'
import { loadingReset, loadingUpdate } from '../../../../store/actions/loadingAction'
import { ParametrizacaoCampos, ParametrizacaoCamposParceiro } from '../../../../types/parceiroApiTypes'
import { tabTransformacaoStyles } from './TabTransformacao.styles'
import * as yup from 'yup'

export function tabTransformacaoIO() {
  const [isEditando, setIsEditando] = useState(false)
  const [isPrimeiroCadastro, setIsPrimeiroCadastro] = useState(false)
  const { parceiroId } = useParams<{ parceiroId: string }>()
  const dispatch = useDispatch()

  const schema = yup
    .object({
      pedido: yup.string().required('Campo obrigatório'),
      status: yup.string().required('Campo obrigatório')
    })
    .required()

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors }
  } = useForm<ParametrizacaoCampos>({
    resolver: yupResolver(schema)
  })

  const buscarParametrosCiclo = useCallback(async () => {
    dispatch(loadingUpdate(true))
    await parceiroManager
      .buscarParametrosCicloPedido(parceiroId)
      .then((response: ParametrizacaoCamposParceiro) => {
        if (response.status == '' && response.pedido == '') {
          setIsPrimeiroCadastro(true)
          setIsEditando(true)
        } else {
          setValue('status', response.status)
          setValue('pedido', response.pedido)
          setIsEditando(false)
          setIsPrimeiroCadastro(false)
        }
      })
      .catch(() => toast.error(msgConstants.USO_GENERICO.erroBusca))
      .finally(() => dispatch(loadingReset()))
  }, [dispatch, parceiroId, setValue])

  useEffect(() => {
    buscarParametrosCiclo()
  }, [buscarParametrosCiclo])

  const handleSalvar = async (data: ParametrizacaoCampos) => {
    dispatch(loadingUpdate(true))

    await parceiroManager
      .editarParametrosCicloPedido(parceiroId, data)
      .then(() => {
        setIsPrimeiroCadastro(false)
        setIsEditando(false)
        toast.success(msgConstants.USO_GENERICO.edicao_sucesso)
      })
      .catch((error) => toast.error(error.message))
      .finally(() => {
        dispatch(loadingReset())
      })
  }

  return {
    styles: tabTransformacaoStyles,
    parceiroId,
    handleSubmit,
    control,
    handleSalvar,
    isEditando,
    setIsEditando,
    isPrimeiroCadastro,
    errors
  }
}

export type TabTransformacaoIO = ReturnType<typeof tabTransformacaoIO>
