import styled from 'styled-components'
import { ContainerSombra } from '../../../../components/ContainerSombra'

export const transitoBoxStyles = {
  Box: styled(ContainerSombra).attrs((props: { width: string; maxwidth: string }) => props)`
    margin: auto;
    padding: 16px;
    margin-top: 24px;
    margin-bottom: 24px;
    width: ${(props) => props.width};
    max-width: ${(props) => props.maxwidth};
  `
}
