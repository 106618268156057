import { styles } from './BadgeMessages.styles'

type IBadgeMessagesProps = {
  etapa?: string
  descricao: string
}

export function BadgeMessages({ etapa, descricao }: IBadgeMessagesProps) {
  const { Container } = styles
  return (
    <Container estapaStatus={etapa}>
      <p>{descricao}</p>
    </Container>
  )
}
