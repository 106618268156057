import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import { loadingReset, loadingUpdate } from '../../../../store/actions/loadingAction'
import { RegraAssociacao } from '../../../../types/associacaoApiTypes'
import { converterToAcaoAssociacaoForm, converterToCondicaoAssociacaoForm } from '../../../../utils/regraFormsUtils'
import { itemAssociacaoStyles } from './ItemAssociacao.styles'
import AssociacaoManager from '../../../../services/api/associacao/associacaoManager'
import { toast } from 'react-toastify'
import msgConstants from '../../../../constants/msgConstants'
import { ItemAssociacaoProps } from './ItemAssociacao.View'
import { PAGES } from '../../../../router/routes'

export function itemAssociacaoIO({ atualizarDados }: ItemAssociacaoProps) {
  const router = useHistory()
  const dispatch = useDispatch()
  const { parceiroId } = useParams<{ parceiroId: string }>()
  const [historicoAberto, setHistoricoAberto] = useState(false)
  const [ancoraMenu, setAncoraMenu] = useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAncoraMenu(event.currentTarget)
  }

  const handleFecharAncora = () => {
    setAncoraMenu(null)
  }

  const handleEditar = (regraSelecionada: RegraAssociacao) => {
    router.push(PAGES.associacaoEditar(parceiroId, String(regraSelecionada.regraId)))
    setAncoraMenu(null)
  }

  const handleDesative = (data: RegraAssociacao) => {
    dispatch(loadingUpdate(true))
    const dataEdit = { ...data, ativo: false, acoes: converterToAcaoAssociacaoForm(data.acoes), condicoes: converterToCondicaoAssociacaoForm(data.condicoes) }

    AssociacaoManager.editarRegra(dataEdit, parceiroId, dataEdit.regraId)
      .then(() => {
        toast.success(msgConstants.USO_GENERICO.desativar_sucesso)
        atualizarDados()
      })
      .catch((error: Error) => {
        toast.error(error.message)
      })

    dispatch(loadingReset())
  }

  const validData = (data?: string | number | null) => data ?? '-'

  const alterarIconeMenu = historicoAberto ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />

  return {
    styles: itemAssociacaoStyles,
    setHistoricoAberto,
    alterarIconeMenu,
    validData,
    handleDesative,
    handleEditar,
    handleFecharAncora,
    handleClick,
    ancoraMenu,
    parceiroId,
    historicoAberto
  }
}

export type ItemAssociacaoIO = ReturnType<typeof itemAssociacaoIO>
