import styled from 'styled-components'
import { customTheme } from '../../styles/customTheme'
import { Popover } from '@material-ui/core'

export const popoverFiltroPedidoComissaoClassificacoesStyles = {
  Popover: styled(Popover)`
    .MuiPaper-rounded {
      border-radius: 10px;
    }
  `,
  ContainerPopover: styled.div`
    padding: 12px;
    max-height: 200px;
    min-width: 300px;

    div {
      display: flex;
      align-items: center;
      gap: 8px;
      border-bottom: 1px solid ${customTheme.colors.cinza03};
    }

    div:first-child {
      border-bottom: none;
    }

    div:last-child {
      border-bottom: none;
    }
  `
}
