import { ChangeEvent } from 'react'
import { IOProps } from 'react-compose-io'
import { Acao, Condicao } from '../../../types/regraApiType'
import { GrupoRegra } from '../../../types/transformacaoApiTypes'
import { FieldsAcao } from '../FieldsAcao/index'
import { FieldsCondicao } from '../FieldsCondicao'
import { FieldsDescricao } from '../FieldsDescricao/index'
import { FormularioRegraIO } from './FormularioRegra.IO'

type FormularioRegraProps = {
  mudarStatusParaAtivo?: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void
  ativo?: boolean
  isEdicao?: boolean
  condicaoLista: Condicao[]
  acaoLista: Acao[]
  grupoRegraLista: GrupoRegra[]
  cabecalhosPlanilha: string[]
}

export function FormularioRegraView({
  mudarStatusParaAtivo,
  ativo,
  condicaoLista,
  acaoLista,
  grupoRegraLista,
  cabecalhosPlanilha,
  isEdicao
}: IOProps<FormularioRegraIO, FormularioRegraProps>) {
  return (
    <>
      <FieldsDescricao isEdicao={isEdicao} grupoRegraLista={grupoRegraLista} mudarStatusAtivo={mudarStatusParaAtivo} ativo={ativo} />
      <FieldsCondicao itensCampo={condicaoLista} cabecalhos={cabecalhosPlanilha} />
      <FieldsAcao itensCampo={acaoLista} cabecalhos={cabecalhosPlanilha} />
    </>
  )
}
