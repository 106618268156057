import { Badge, Box, Button, Divider, IconButton, Menu } from '@material-ui/core'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import MenuIcon from '@material-ui/icons/Menu'
import { IOProps } from 'react-compose-io'
import Logo from '../../assets/images/logo-efacil.svg'
import { HeaderIO } from './Header.IO'

export type HeaderProps = {}

export function HeaderView({ _io }: IOProps<HeaderIO, HeaderProps>) {
  const { AppBar, BoxLogo, Toolbar, ToggleIconButton, TextHeader, NotificationsNoneIcon, MenuContainer, MenuItem, Avatar } = _io.styles

  return (
    <AppBar elevation={1}>
      <Toolbar>
        <MenuContainer>
          <ToggleIconButton color="inherit" onClick={() => _io.toggleIsOpened(!_io.isOpened)}>
            {_io.isOpened ? <ChevronLeftIcon /> : <MenuIcon />}
          </ToggleIconButton>
          <BoxLogo>
            <img src={Logo} alt="Logo eFacil" />
          </BoxLogo>
          <Box>
            <TextHeader variant="subtitle2">{process.env.REACT_APP_HEADER_TEXT}</TextHeader>
          </Box>
        </MenuContainer>
        <Box>
          <IconButton color="inherit" size="medium">
            <Badge badgeContent={0} color="error" overlap="rectangular">
              <NotificationsNoneIcon />
            </Badge>
          </IconButton>
          <Button color="primary" onClick={_io.handleMenu}>
            <MenuContainer>
              <Avatar variant="circular">{_io.usuario.charAt(0).toUpperCase() || ''}</Avatar>
              <ExpandMoreIcon />
            </MenuContainer>
          </Button>
          <Menu
            id="menu-appbar"
            anchorEl={_io.anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
            }}
            keepMounted
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
            }}
            open={_io.openMenu}
            onClose={_io.handleClose}
          >
            <MenuItem>Perfil</MenuItem>
            <Divider />
            <MenuItem onClick={_io.handleLogout}>Sair</MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  )
}
