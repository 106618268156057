import { GraficoValorQuantidadeClassificacoesIO } from './GraficoValorQuantidadeClassificacoes.IO'
import { Grid } from '@material-ui/core'
import { IOProps } from 'react-compose-io'
import CifraoIcone from './../../../../assets/icons/cifrao.svg'
import CuboIcone from './../../../../assets/icons/cubo.svg'
import { formateCurrency } from '../../../../utils/stringUtils'
import { BoxValor } from './../../../../components/BoxValor/index'
import { ContainerSombra } from '../../../../components/ContainerSombra'
import { GraficoBarras } from '../../../../components/GraficoBarras'
import { TIPO_VALOR_GRAFICO } from '../../../../constants/utilConstants'
import { customTheme } from '../../../../styles/customTheme'
import { Tipografia } from '../../../../components/Tipografia'

export type GraficoValorQuantidadeClassificacoesProps = {
  titulo: string
  tituloGrafico: string
  valoresMensais: number[]
  legendaMeses: string[][]
  legendaEixoY: string
  divisorLegenda: number
  descricaoValor: string
  valor: number
  descricaoQuantidade: string
  quantidade: number
  handleClickColuna: (mes: number) => void
}

export function GraficoValorQuantidadeClassificacoesView({
  _io,
  titulo,
  tituloGrafico,
  valoresMensais,
  legendaMeses,
  legendaEixoY,
  divisorLegenda,
  descricaoValor,
  valor,
  descricaoQuantidade,
  quantidade,
  handleClickColuna
}: IOProps<GraficoValorQuantidadeClassificacoesIO, GraficoValorQuantidadeClassificacoesProps>) {
  const { GridWidthLimitada, GridContainer } = _io.styles

  return (
    <ContainerSombra>
      <Tipografia variant="h6">{titulo}</Tipografia>
      <GridContainer container spacing={2} direction="row" justifyContent="center" alignContent="center">
        <GridWidthLimitada item sm={7} md={7} lg={6}>
          <GraficoBarras
            tituloGrafico={tituloGrafico}
            dadosColunas={valoresMensais}
            descricaoColunas={legendaMeses}
            legendaEixoY={legendaEixoY}
            valorDivididoLegenda={divisorLegenda}
            corBarra={() => customTheme.colors.blue13}
            tipoValor={TIPO_VALOR_GRAFICO.MOEDA}
            handleClickColuna={handleClickColuna}
          />
        </GridWidthLimitada>
        <GridWidthLimitada item container justifyContent="center" alignContent="center" direction="column" spacing={2} sm={12} md={4} lg={6}>
          <Grid item>
            <BoxValor icone={CifraoIcone} descricao={descricaoValor} valor={formateCurrency(valor)} tipoSombra={'none'} />
          </Grid>
          <Grid item>
            <BoxValor icone={CuboIcone} descricao={descricaoQuantidade} valor={quantidade.toLocaleString('pt-BR')} tipoSombra={'none'} />
          </Grid>
        </GridWidthLimitada>
      </GridContainer>
    </ContainerSombra>
  )
}
