import { SvgIconTypeMap } from '@material-ui/core'
import { OverridableComponent } from '@material-ui/core/OverridableComponent'
import Information from '../../assets/icons/information.svg'
import { styles } from './Tooltip.style'

type TooltipProps = {
  texto: string
  Icone?: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>
  color?: 'inherit' | 'disabled' | 'primary' | 'secondary' | 'action' | 'error'
}

export function TooltipComponent({ texto, Icone, color }: TooltipProps) {
  const { IconInfo, TooltipElement, BotaoIcone } = styles
  return (
    <TooltipElement placement="top" title={texto}>
      {Icone ? <BotaoIcone>{<Icone color={color} />}</BotaoIcone> : <IconInfo src={Information} />}
    </TooltipElement>
  )
}
