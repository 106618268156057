import { TextField } from '@material-ui/core'
import { IOProps } from 'react-compose-io'
import { PopoverFiltroParceiroIO } from './PopoverFiltroParceiro.IO'

export type PopoverFiltroParceiroProps = {
  popoverAberto: null | HTMLElement
  handleClosePopover: () => void
  isParceiroChecked: (parceiroId: number) => boolean
  handleCheckParceiro: (parceiroId: number) => void
}

export function PopoverFiltroParceiroView({ _io, popoverAberto, handleClosePopover }: IOProps<PopoverFiltroParceiroIO, PopoverFiltroParceiroProps>) {
  const { ContainerPopoverParceiro, Popover } = _io.styles

  return (
    <Popover
      keepMounted
      anchorEl={popoverAberto}
      open={Boolean(popoverAberto)}
      onClose={handleClosePopover}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
    >
      <ContainerPopoverParceiro>
        <TextField onChange={_io.handleChangeParceiroInput} label="Buscar parceiro" variant="outlined" size="small" fullWidth />
        {_io.parceirosAtivos.filter(_io.filtrarParceiros).map(_io.renderParceirosFiltradas)}
      </ContainerPopoverParceiro>
    </Popover>
  )
}
