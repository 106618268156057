import { Grid, Typography } from '@material-ui/core'
import styled from 'styled-components'
import { customTheme } from '../../styles/customTheme'

export const informacoesModalRegularizacaoErroStyles = {
  ContainerListaModal: styled(Grid).attrs({
    item: true,
    xs: 11,
    sm: 11,
    md: 11,
    lg: 11
  })`
    overflow: auto;
    text-align: start;
  `,
  ContainerErro: styled(Grid).attrs({
    container: true
  })`
    background: ${customTheme.colors.red00};
    border-radius: 4px;
    margin: 12px 0;
    display: flex;
    align-items: start;
    justify-content: start;
    flex-direction: row;
    padding: 12px 16px;
    width: 845px;
  `,
  ContainerInformacoesTexto: styled.div`
    display: flex;
    flex-direction: row;
    align-items: start;
  `,
  TituloContainerErro: styled(Typography).attrs({
    variant: 'subtitle2'
  })`
    font-style: bold;
    color: ${customTheme.colors.grey09};
  `,
  MensagemErro: styled(Typography).attrs({
    variant: 'body2'
  })`
    font-style: normal;
    color: ${customTheme.colors.grey04};
  `,
  IconInfo: styled.img`
    margin: 1px;
  `
}
