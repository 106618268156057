import { customTheme } from '../styles/customTheme'
import { ValoresMensais } from '../types/debitosTypes'

export const valoresGrafico = (valoresMensais: ValoresMensais[], transportadora?: 'parceiro' | 'martins') =>
  valoresMensais.map((valorMes) => {
    if (transportadora == 'parceiro') return valorMes.valorTransportadoraParceira

    if (transportadora == 'martins') return valorMes.valorTransportadoraMartins

    return valorMes.valor
  })

export const quantidadeGrafico = (valoresMensais: ValoresMensais[], transportadora?: 'parceiro' | 'martins') =>
  valoresMensais.map((valorMes) => {
    if (transportadora == 'parceiro') return valorMes.quantidadeTransportadoraParceira

    if (transportadora == 'martins') return valorMes.quantidadeTransportadoraMartins

    return valorMes.quantidade
  })

export const legendaMesGrafico = (valoresMensais: ValoresMensais[]) =>
  valoresMensais.map((valorMes) => {
    return [valorMes.mes]
  })

export function corBarra({ dataPointIndex }: { dataPointIndex: number; value: number }) {
  if (dataPointIndex == 0) {
    return customTheme.colors.blue12
  } else {
    return customTheme.colors.blue13
  }
}
