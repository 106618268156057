import { useState } from 'react'
import { deControleComissaoPedidoParaChave } from '../../../../utils/controleComissaoUtils'
import { useConciliacaoContext } from '../../../../contexts/conciliacao-context'
import { ItemControleComissaoPedidoStyles } from './ItemControleComissaoPedido.styles'
import { DetalheControleComissaoPedidoResponse } from '../../../../types/controleComissaoTypes'
import { ItemControleComissaoPedidoProps } from './ItemControleComissaoPedido.View'

export function itemControleComissaoPedidoIO({ chave }: ItemControleComissaoPedidoProps) {
  const [pedidoAberto, setPedidoAberto] = useState(false)
  const { handleCheckClickListExterior, isCheckListExteriorSelecionado } = useConciliacaoContext()

  return {
    styles: ItemControleComissaoPedidoStyles,
    pedidoAberto,
    handleAbrirPedidoToggle: () => setPedidoAberto(!pedidoAberto),
    isCheckListSelecionado: isCheckListExteriorSelecionado,
    handleClickCheckList: (detalhes: DetalheControleComissaoPedidoResponse[]) => {
      if (pedidoAberto === false) setPedidoAberto(!pedidoAberto)
      handleCheckClickListExterior(detalhes, chave)
    },
    deControleComissaoPedidoParaChave
  }
}

export type ItemControleComissaoPedidoIO = ReturnType<typeof itemControleComissaoPedidoIO>
