import * as React from 'react'
import { IconeProps } from './icon'

export const NuvemUpload = ({ color }: IconeProps) => (
  <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d={`
        M12.815 3.16504C12.3567 2.11205 11.5658 1.23835 10.5635 0.677839C9.56122 0.117326 8.40277 
        -0.0990825 7.2657 0.0617753C6.12863 0.222633 5.07567 0.751883 4.26817 1.56842C3.46067 2.38497 
        2.94317 3.44375 2.79498 4.58254C2.07992 4.75379 1.45264 5.18167 1.03228 5.78494C0.611919 6.3882
         0.427742 7.12484 0.514726 7.85496C0.601711 8.58507 0.9538 9.25782 1.50413 9.74544C2.05446 
         10.2331 2.7647 10.5016 3.49998 10.5C3.69889 10.5 3.88966 10.421 4.03031 10.2804C4.17096 10.1397
          4.24998 9.94896 4.24998 9.75004C4.24998 9.55113 4.17096 9.36037 4.03031 9.21971C3.88966 9.07906 
          3.69889 9.00004 3.49998 9.00004C3.10215 9.00004 2.72062 8.84201 2.43932 8.5607C2.15801 8.2794 
          1.99998 7.89787 1.99998 7.50004C1.99998 7.10222 2.15801 6.72069 2.43932 6.43938C2.72062 6.15808 
          3.10215 6.00004 3.49998 6.00004C3.69889 6.00004 3.88966 5.92103 4.03031 5.78037C4.17096 5.63972 
          4.24998 5.44896 4.24998 5.25004C4.25189 4.363 4.56819 3.50536 5.14268 2.82948C5.71717 2.1536
           6.51265 1.70326 7.38779 1.55845C8.26293 1.41364 9.16108 1.58375 9.92266 2.03855C10.6842 
           2.49335 11.2599 3.2034 11.5475 4.04254C11.5903 4.17142 11.6674 4.28624 11.7704 4.37474C11.8735 
           4.46324 11.9986 4.52211 12.1325 4.54504C12.632 4.63945 13.0849 4.90025 13.4172 5.28494C13.7496 
           5.66964 13.9419 6.15556 13.9628 6.66352C13.9837 7.17149 13.8319 7.67155 13.5322 8.08222C13.2325
            8.49289 12.8026 8.78997 12.3125 8.92504C12.1195 8.97477 11.9542 9.09911 11.853 9.27071C11.7517
             9.4423 11.7228 9.6471 11.7725 9.84004C11.8222 10.033 11.9465 10.1983 12.1181 10.2995C12.2897 
             10.4008 12.4945 10.4298 12.6875 10.38C13.4768 10.1715 14.1764 9.71104 14.6802 9.06864C15.184 
             8.42625 15.4644 7.637 15.4789 6.82075C15.4933 6.00449 15.2409 5.20583 14.7602 4.54603C14.2794
              3.88624 13.5964 3.40137 12.815 3.16504ZM8.53248 4.71754C8.46115 4.64926 8.37704 4.59574 
              8.28498 4.56004C8.10238 4.48503 7.89757 4.48503 7.71498 4.56004C7.62292 4.59574 7.53881 
              4.64926 7.46748 4.71754L5.21748 6.96754C5.07625 7.10877 4.99691 7.30032 4.99691 
              7.50004C4.99691 7.69977 5.07625 7.89132 5.21748 8.03254C5.35871 8.17377 5.55025 8.25311 
              5.74998 8.25311C5.94971 8.25311 6.14125 8.17377 6.28248 8.03254L7.24998 
              7.05754V11.25C7.24998 11.449 7.329 11.6397 7.46965 11.7804C7.6103 11.921 7.80107 
              12 7.99998 12C8.19889 12 8.38966 11.921 8.53031 11.7804C8.67096 11.6397 8.74998 11.449 
              8.74998 11.25V7.05754L9.71748 8.03254C9.7872 8.10284 9.87015 8.15864 9.96155 
              8.19671C10.0529 8.23479 10.151 8.25439 10.25 8.25439C10.349 8.25439 10.447 8.23479 10.5384 
              8.19671C10.6298 8.15864 10.7128 8.10284 10.7825 8.03254C10.8528 7.96282 10.9086 7.87987 
              10.9466 7.78848C10.9847 7.69708 11.0043 7.59905 11.0043 7.50004C11.0043 7.40104 10.9847 
              7.30301 10.9466 7.21161C10.9086 7.12022 10.8528 7.03727 10.7825 6.96754L8.53248 4.71754Z
      `}
      fill={color}
    />
  </svg>
)
