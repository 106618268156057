import { toast } from 'react-toastify'
import msgConstants from '../../../constants/msgConstants'
import { DetalhesPedidoMovimentoContabil, ResumoPedidosInternalizacao } from '../../../types/pedidosTypes'
import api from '../../axiosInstance'

const buscarPedidoInternalizado = async (pedidoId: string): Promise<ResumoPedidosInternalizacao> => {
  const URL_BUSCAR_PEDIDO = `Pedidos/${pedidoId}/Resumo`
  const response = await api.conectarGet<ResumoPedidosInternalizacao>(URL_BUSCAR_PEDIDO)
  response.status === 204 && toast.warning(msgConstants.PEDIDO_NAO_ENCONTRADO(pedidoId))
  return response.data
}

const buscarPedidoMovimentoContabil = async (pedidoId: string): Promise<DetalhesPedidoMovimentoContabil[]> => {
  const URL_BUSCAR_PEDIDO = `Pedidos/${pedidoId}/Resumo/Contabilizacao`
  const response = await api.conectarGet<DetalhesPedidoMovimentoContabil[]>(URL_BUSCAR_PEDIDO)
  response.status === 204 && toast.warning(msgConstants.PEDIDO_NAO_ENCONTRADO(pedidoId))
  return response.data
}

export default {
  buscarPedidoInternalizado,
  buscarPedidoMovimentoContabil
}
