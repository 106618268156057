import { valoresGrafico, quantidadeGrafico, legendaMesGrafico } from '../../../../utils/debitoGraficos'
import { repasseBoxStyles } from './RepasseBox.styles'
import { RepasseBoxProps } from './RepasseBox.View'

export function repasseBoxIO({ valoresMensais }: RepasseBoxProps) {
  const valorRepasseTotal = valoresGrafico(valoresMensais)
  const quantidadeRepasseTotal = quantidadeGrafico(valoresMensais)
  const legendaGrafico = legendaMesGrafico(valoresMensais)

  return {
    styles: repasseBoxStyles,
    legendaGrafico,
    valorRepasseTotal,
    quantidadeRepasseTotal
  }
}

export type RepasseBoxIO = ReturnType<typeof repasseBoxIO>
