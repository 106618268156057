import { ApexOptions } from 'apexcharts'
import { formateCurrency } from '../../../../utils/stringUtils'
import { graficoBarraDiasStyles } from './GraficoBarraDias.style'
import { GraficoBarraDiasProps } from './GraficoBarraDias.View'

export function graficoBarraDiasIO({ listaTitulosParceiros }: GraficoBarraDiasProps) {
  const listaTitulosTodosParceiros = listaTitulosParceiros ? listaTitulosParceiros : []
  const valorPorcentagemParceiros = listaTitulosTodosParceiros.map((valorPorcentagem) => valorPorcentagem.titulos?.valor)
  const quantidade = listaTitulosTodosParceiros.map((valorPorcentagem) => valorPorcentagem.titulos.quantidade)

  const tamanhoLetraLegenda = quantidade.length < 20 ? '12px' : '9px'

  const legendaMesAno = (data: string) => {
    const dataReferencia = new Date(data)
    const mesReferencia = new Date(data).getMonth() + 1
    const month = mesReferencia.toString().length < 2 ? `0${mesReferencia}` : mesReferencia
    const dia = dataReferencia.getUTCDate().toString().length < 2 ? `0${dataReferencia.getUTCDate()}` : dataReferencia.getUTCDate()

    return `${dia}/${month}`
  }

  const legendaGrafico = listaTitulosTodosParceiros.map((legenda) => {
    return [legendaMesAno(legenda.dataReferencia)]
  })

  return {
    styles: graficoBarraDiasStyles,
    series: [
      {
        name: '',
        data: valorPorcentagemParceiros
      }
    ],
    options: {
      chart: {
        type: 'bar',
        toolbar: {
          show: false,
          tools: {
            download: false
          }
        },
        redrawOnWindowResize: false
      },
      plotOptions: {
        bar: {
          borderRadius: 3,
          columnWidth: '25%'
        }
      },
      legend: {
        show: false
      },
      xaxis: {
        categories: legendaGrafico,
        labels: {
          style: {
            fontSize: tamanhoLetraLegenda,
            fontWeight: 400
          }
        }
      },
      yaxis: {
        labels: {
          show: false
        }
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        y: {
          formatter: function (val: number, opt: any) {
            return `${quantidade[opt.dataPointIndex].toLocaleString('pt-BR')} - ${formateCurrency(val)}`
          }
        }
      },
      dataLabels: {
        enabled: false
      }
    } as ApexOptions
  }
}

export type GraficoBarraDiasIO = ReturnType<typeof graficoBarraDiasIO>
