import { IconButton, TableCell, TableRow, Checkbox } from '@material-ui/core'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import { IOProps } from 'react-compose-io'
import { formateCurrency, formateDate } from '../../../../utils/stringUtils'
import { ItemDebitoIO } from './ItemDebito.IO'
import { corChip, corLetraChip } from '../../../../styles/customTheme'
import { HistoricoDebito } from '../HistoricoDebito'
import { ObterPedidosDebitosResponse } from '../../../../types/debitosTypes'
import { ChipClassificacaoPerda } from './../../../../components/ChipClassificacaoPerda/ChipClassificacaoPerda'
import { SinoIcon } from './../../../../assets/icons/SinoIcon'

export type ItemDebitoProps = {
  item: ObterPedidosDebitosResponse
  checklistsSelecionados: string[]
  handleCheckList: (pedidoMkt: string) => void
  isAdministrador: boolean
  handleAprovarSugestao: (pedidoMkt: string, classificacaoSugerida: number) => void
}

export function ItemDebitoView({ _io, item, isAdministrador, handleAprovarSugestao, handleCheckList }: IOProps<ItemDebitoIO, ItemDebitoProps>) {
  return (
    <>
      <TableRow>
        <TableCell align="center" size="small">
          <IconButton size="small" onClick={_io.handleAbrirHistoricoToggle}>
            {_io.historicoAberto ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="center" size="small">
          <Checkbox checked={_io.isCheckListSelecionado()} onChange={() => handleCheckList(item.pedidoMkt)} />
        </TableCell>
        <TableCell align="center" size="small">
          {item.parceiro}
        </TableCell>
        <TableCell align="center" size="small">
          {formateDate(item.dataLancamento)}
        </TableCell>
        <TableCell align="center" size="small">
          {item.pedidoMkt}
        </TableCell>
        <TableCell align="center" size="small">
          {formateCurrency(item.valorDebito)}
        </TableCell>
        <TableCell align="center" size="small">
          {item.tratativa}
        </TableCell>
        <TableCell align="center" size="small">
          {_io.isPedidoComSugestaoPendente && <SinoIcon />}
          {_io.isPedidoAprovado && (
            <ChipClassificacaoPerda label={item.classificacao} bgcolor={corChip.get(item.classificacao)} fontcolor={corLetraChip.get(item.classificacao)} />
          )}
        </TableCell>
      </TableRow>
      <HistoricoDebito historicoAberto={_io.historicoAberto} item={item} isAdministrador={isAdministrador} handleAprovarSugestao={handleAprovarSugestao} />
    </>
  )
}
