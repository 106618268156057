import { IOProps } from 'react-compose-io'
import { ControleHistoricoPerdasIO } from './ControleHistoricoPerdas.IO'
import { corParceiroId } from '../../styles/customTheme'
import { CabecalhoHistorico } from './components/CabecalhoHistorico'
import CifraoIcone from '../../assets/icons/cifrao.svg'
import CuboIcone from '../../assets/icons/cubo.svg'
import { CabecalhoExportarRelatorio } from './../../components/CabecalhoExportarRelatorio'
import { DadosHistorico } from './components/DadosHistorico'
import { customTheme } from './../../styles/customTheme'
import { Grid } from '@material-ui/core'
import { TIPO_VALOR_GRAFICO } from '../../constants/utilConstants'

export enum TipoPerda {
  DEBITO = 'DEBITO',
  INADIMPLENCIA = 'INADIMPLENCIA'
}

export type ControleHistoricoPerdasProps = {}

export function ControleHistoricoPerdasView({ _io }: IOProps<ControleHistoricoPerdasIO, ControleHistoricoPerdasProps>) {
  const { Container, Divider, PageDivider } = _io.styles

  return (
    <>
      <CabecalhoHistorico
        parceiroSelecionado={_io.parceiroSelecionado}
        handleClickParceiro={_io.handleClickParceiro}
        setMesesSelecionado={_io.setMesesSelecionado}
      />
      <Container cor={corParceiroId.get(_io.parceiroSelecionado)}>
        {_io.historicoPerdasDebitos.length > 0 ? (
          <>
            <CabecalhoExportarRelatorio
              fontSize={'h6'}
              url={'' + _io.selecionarUrlRelatorio(TipoPerda.DEBITO)}
              tituloCabecalho={'Débitos lançados para perda'}
            />
            <DadosHistorico
              icone={CifraoIcone}
              tituloGrafico="Valor em Reais (R$)"
              valoresGrafico={_io.dadosGraficoDebito.valor}
              legendaGrafico={_io.dadosGraficoDebito.nomeMeses}
              valor={_io.valorTotalDebitos}
              valorDescricao="Valor Total"
              legendaEixoYGrafico="Milhares (R$)"
              tipoValorGrafico={TIPO_VALOR_GRAFICO.MOEDA}
              corBarraGrafico={customTheme.colors.azul03}
            />

            <Divider variant="middle" />

            <DadosHistorico
              icone={CuboIcone}
              tituloGrafico="Quantidade em unidades"
              valoresGrafico={_io.dadosGraficoDebito.quantidade}
              legendaGrafico={_io.dadosGraficoDebito.nomeMeses}
              valor={_io.quantidadeTotalDebitos}
              valorDescricao="Quantidade Total"
              legendaEixoYGrafico="Unidades"
              tipoValorGrafico={TIPO_VALOR_GRAFICO.UNIDADE}
              corBarraGrafico={customTheme.colors.laranja04}
            />
          </>
        ) : (
          <Grid container justifyContent="center">
            <h4>Ainda não foram efetivadas perdas de débito!</h4>
          </Grid>
        )}

        <PageDivider />

        {_io.historicoPerdasInadimplencia.length > 0 ? (
          <>
            <CabecalhoExportarRelatorio
              fontSize={'h6'}
              url={'' + _io.selecionarUrlRelatorio(TipoPerda.INADIMPLENCIA)}
              tituloCabecalho={'Títulos inadimplentes lançados para perda'}
            />
            <DadosHistorico
              icone={CifraoIcone}
              tituloGrafico="Valor em Reais (R$)"
              valoresGrafico={_io.dadosGraficoInadimplencia.valor}
              legendaGrafico={_io.dadosGraficoInadimplencia.nomeMeses}
              valor={_io.valorTotalInadimplencia}
              valorDescricao="Valor Total"
              legendaEixoYGrafico="Milhares (R$)"
              tipoValorGrafico={TIPO_VALOR_GRAFICO.MOEDA}
              corBarraGrafico={customTheme.colors.azul03}
            />

            <Divider variant="middle" />

            <DadosHistorico
              icone={CuboIcone}
              tituloGrafico="Quantidade em unidades"
              valoresGrafico={_io.dadosGraficoInadimplencia.quantidade}
              legendaGrafico={_io.dadosGraficoInadimplencia.nomeMeses}
              valor={_io.quantidadeTotalInadimplencia}
              valorDescricao="Quantidade Total"
              legendaEixoYGrafico="Unidades"
              tipoValorGrafico={TIPO_VALOR_GRAFICO.UNIDADE}
              corBarraGrafico={customTheme.colors.laranja04}
            />
          </>
        ) : (
          <Grid container justifyContent="center">
            <h4>Ainda não foram efetivadas perdas de inadimplência!</h4>
          </Grid>
        )}
      </Container>
    </>
  )
}
