import { Box, Grid, Table, TableBody, TableContainer, TableHead } from '@material-ui/core'
import { IOProps } from 'react-compose-io'
import { EnhancedTableHead } from '../../components/EnhancedTableHead/index'
import { FormularioTipoLancamentoParceiro } from '../../components/FormularioTipoLancamentoParceiro/index'
import { ComponentePaginacao } from '../../components/Tabela/ComponentePaginacao/index'
import { cabecalhoLancamentoAssociacao } from '../../types/tabelaTypes'
import { formateCurrency } from '../../utils/stringUtils'
import { getComparator, ordenarCabecalho } from '../../utils/tabelaUtils'
import { ItemTipoLancamento } from '../ParametrosRepasse/components/ItemTipoLancamento/index'
import { NovaAssociacao } from './components/NovaAssociacao/index'
import { LancamentoAssociacaoIO } from './LancamentoAssociacao.IO'
import { Tipografia } from './../../components/Tipografia/index'
import { ContainerSombra } from '../../components/ContainerSombra'

export type LancamentoAssociacaoProps = {}

export function LancamentoAssociacaoView({ _io }: IOProps<LancamentoAssociacaoIO, LancamentoAssociacaoProps>) {
  const { Container, DivTab, TableRowContainer, TableCellItem, TablePaginationContainer, BoxTable } = _io.styles

  return (
    <>
      <Container>
        <Box>
          <Tipografia variant="h6">Novo lançamento identificado</Tipografia>
        </Box>
        <BoxTable>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRowContainer>
                  <TableCellItem>Id. Pedido</TableCellItem>
                  <TableCellItem>Loja</TableCellItem>
                  <TableCellItem>Tipo de lançamento</TableCellItem>
                  <TableCellItem>Lançamento</TableCellItem>
                  <TableCellItem>Valor</TableCellItem>
                  <TableCellItem>Parcela</TableCellItem>
                </TableRowContainer>
              </TableHead>
              <TableBody>
                <TableRowContainer key={_io.novosLancamentosList?.identificadorPedido}>
                  <TableCellItem>{_io.novosLancamentosList?.identificadorPedido}</TableCellItem>
                  <TableCellItem>{_io.novosLancamentosList?.loja}</TableCellItem>
                  <TableCellItem>{_io.novosLancamentosList?.tipoLancamento}</TableCellItem>
                  <TableCellItem>{_io.novosLancamentosList?.lancamento}</TableCellItem>
                  <TableCellItem>{formateCurrency(_io.novosLancamentosList?.valor)}</TableCellItem>
                  <TableCellItem>{_io.novosLancamentosList?.parcela}</TableCellItem>
                </TableRowContainer>
              </TableBody>
            </Table>
          </TableContainer>
        </BoxTable>
        <Box>
          <FormularioTipoLancamentoParceiro
            larguraDescricao={3}
            larguraSiglaContabilizacao={3}
            isModal={false}
            cadastrarTipoLancamentoParceiro={_io.salvarTipoLancamento}
          />
        </Box>
      </Container>
      <ContainerSombra>
        <DivTab>
          <Grid container direction="row" spacing={2}>
            <Grid item sm={12}>
              <>
                <TableContainer>
                  <Table>
                    <EnhancedTableHead
                      alignCenter={true}
                      headCells={cabecalhoLancamentoAssociacao}
                      order={_io.order}
                      orderBy={_io.orderBy}
                      onRequestSort={_io.onRequestSort}
                    />
                    <TableBody>
                      {ordenarCabecalho(_io.tipoLancamentoList, getComparator(_io.order, _io.orderBy))
                        .slice(_io.pagina * _io.linhasPorPagina, _io.pagina * _io.linhasPorPagina + _io.linhasPorPagina)
                        .map((row, index) => (
                          <ItemTipoLancamento
                            atualizarDados={_io.handleUpdateStatus}
                            key={index}
                            linhaItemTipoLancamentoParceiro={row}
                            indexLinhaItemTipoLancamento={index}
                          />
                        ))}
                    </TableBody>
                  </Table>
                  <TablePaginationContainer
                    rowsPerPageOptions={[5, 10, 25]}
                    count={_io.tipoLancamentoList.length}
                    rowsPerPage={_io.linhasPorPagina}
                    page={_io.pagina}
                    onPageChange={_io.onMudancaPagina}
                    onRowsPerPageChange={_io.onMudancaLinhasPorPagina}
                    labelRowsPerPage="Itens por página"
                    ActionsComponent={ComponentePaginacao}
                  />
                </TableContainer>
              </>
            </Grid>
          </Grid>
        </DivTab>
      </ContainerSombra>
      {_io.tipoLancamentoList.filter((tipoLancamentoParceiro) => tipoLancamentoParceiro.tipoContabilizacaoId === 'F').length > 0 && (
        <NovaAssociacao tipoLancamento={_io.tipoLancamentoList} />
      )}
    </>
  )
}
