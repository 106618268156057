import { CardActions, CardContent, Grid, Typography } from '@material-ui/core'
import React from 'react'
import { customCardStyles } from './CustomCard.style'

interface ICustomCardProps {
  title: string
  actionFooter?: React.ReactNode
  actionHeader?: React.ReactNode
  children: React.ReactNode
  isTitle?: boolean
  isActionHeader?: boolean
  isActionFooter?: boolean
}

const CustomCard: React.FC<React.PropsWithChildren<ICustomCardProps>> = ({
  title,
  actionFooter,
  actionHeader,
  children,
  isTitle,
  isActionFooter,
  isActionHeader
}) => {
  const { Card } = customCardStyles

  return (
    <Card>
      <CardContent>
        {isTitle && (
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="h6">{`${title ?? ''}`}</Typography>
            </Grid>
            <Grid item>{isActionHeader && <CardActions>{actionHeader}</CardActions>}</Grid>
          </Grid>
        )}
        {children}
      </CardContent>
      {isActionFooter && <CardActions>{actionFooter}</CardActions>}
    </Card>
  )
}

export default CustomCard
