import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { ITipoLancamentoParceiroForm } from '../../types/parceiroApiTypes'
import { formularioTipoLancamentoParceiroStyles } from './FormularioTipoLancamentoParceiro.style'
import { FormularioTipoLancamentoParceiroProps } from './FormularioTipoLancamentoParceiro.View'
import ciclosManager from '../../services/api/ciclos/ciclosManager'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { loadingReset, loadingUpdate } from '../../store/actions/loadingAction'
import { toast } from 'react-toastify'
import msgConstants from '../../constants/msgConstants'

export function formularioTipoLancamentoParceiroIO({ dadosLinha, isClosed, cadastrarTipoLancamentoParceiro }: FormularioTipoLancamentoParceiroProps) {
  const [change, setChange] = useState('')
  const dispatch = useDispatch()
  const historico = useHistory<number | string | undefined | any>()

  const schema = yup
    .object({
      descricao: yup.string().required('Campo descrição é obrigatório'),
      tipoContabilizacaoId: yup.string().required('Selecione um tipo de contabilização'),
      sigla: yup.string().required('Campo sigla é obrigatório')
    })
    .required()

  const {
    handleSubmit,
    control,
    register,
    setValue,
    watch,
    formState: { errors }
  } = useForm<ITipoLancamentoParceiroForm>({
    defaultValues: {
      descricao: '',
      tipoContabilizacaoId: '',
      sigla: ''
    },
    resolver: yupResolver(schema)
  })

  const valorPadraoTipoLancamento = watch('tipoContabilizacaoId')

  useEffect(() => {
    if (change == valorPadraoTipoLancamento) return
    if (valorPadraoTipoLancamento == 'V') {
      setChange(valorPadraoTipoLancamento)
      return setValue('sigla', 'MAN')
    }
    setChange(valorPadraoTipoLancamento)
    return setValue('sigla', '')
  }, [valorPadraoTipoLancamento, change, setValue])

  useEffect(() => {
    if (dadosLinha) {
      setValue('descricao', dadosLinha.descricao ? dadosLinha.descricao : '')
      setValue('tipoContabilizacaoId', dadosLinha.tipoContabilizacaoId ? dadosLinha.tipoContabilizacaoId : '')
      setValue('sigla', dadosLinha.sigla ? dadosLinha.sigla : '')
      setChange(dadosLinha.tipoContabilizacaoId)
    }
  }, [dadosLinha, setValue])

  const onCloseModalResetData: any = () => {
    isClosed && isClosed()
    setValue('tipoContabilizacaoId', '')
    setValue('sigla', '')
    setValue('descricao', '')
  }

  async function tratarLancamento() {
    const { idCiclo, linha } = historico.location.state
    const tipoTratamento = 'C'
    dispatch(loadingUpdate(true))
    await ciclosManager
      .tratarLancamentoNaoAssociado(idCiclo, linha, tipoTratamento)
      .then(() => {
        historico.goBack()
      })
      .catch(() => {
        toast.error(msgConstants.LANCAMENTO_ASSOCIACAO.erroLancamentoNaoAssociado, { type: 'error' })
      })
      .finally(() => {
        dispatch(loadingReset())
      })
  }
  async function salvarTipoLancamentoParceiro() {
    handleSubmit(cadastrarTipoLancamentoParceiro)()
    valorPadraoTipoLancamento == 'V' && (await tratarLancamento())
    dadosLinha && isClosed ? isClosed() : onCloseModalResetData()
  }

  return {
    styles: formularioTipoLancamentoParceiroStyles,
    control,
    register,
    errors,
    onCloseModalResetData,
    salvarTipoLancamentoParceiro,
    limparComponente: () => {
      dadosLinha && isClosed ? isClosed() : onCloseModalResetData()
    },
    handleSubmit
  }
}

export type FormularioTipoLancamentoParceiroIO = ReturnType<typeof formularioTipoLancamentoParceiroIO>
