import { useState } from 'react'
import { itemCicloPedidoStyles } from './ItemCicloPedido.styles'
import { ItemCicloPedidoProps } from './ItemCicloPedido.View'

export function itemCicloPedidoIO({ item, checklistsSelecionados, setChecklistsSelecionados }: ItemCicloPedidoProps) {
  const [historicoAberto, setHistoricoAberto] = useState(false)

  const isCheckListSelecionado = () => {
    return checklistsSelecionados.includes(item.pedidoMkt)
  }

  const handleCheckList = () => {
    let selecionados = [...checklistsSelecionados]
    if (selecionados.includes(item.pedidoMkt)) {
      selecionados = selecionados.filter((x) => x != item.pedidoMkt)
    } else {
      selecionados.push(item.pedidoMkt)
    }

    setChecklistsSelecionados(selecionados)
  }

  return {
    styles: itemCicloPedidoStyles,
    historicoAberto,
    handleAbrirHistoricoToggle: () => setHistoricoAberto(!historicoAberto),
    handleCheckList,
    isCheckListSelecionado
  }
}

export type ItemCicloPedidoIO = ReturnType<typeof itemCicloPedidoIO>
