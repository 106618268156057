import { IOProps } from 'react-compose-io'
import { PopoverFiltroPedidoComissaoClassificacoesIO } from './PopoverFiltroPedidoComissaoClassificacoes.IO'
import { ClassificacaoControleComissaoPedidos } from '../../types/controleComissaoTypes'

export type PopoverFiltroPedidoComissaoClassificacoesProps = {
  popoverAberto: null | HTMLElement
  handleClosePopover: () => void
  isClassificacaoChecked: (classificacao: string) => boolean
  handleCheckClassificacao: (classificacao: string) => void
  classificacoes: ClassificacaoControleComissaoPedidos[]
}

export function PopoverFiltroPedidoComissaoClassificacoesView({
  _io,
  popoverAberto,
  handleClosePopover,
  classificacoes
}: IOProps<PopoverFiltroPedidoComissaoClassificacoesIO, PopoverFiltroPedidoComissaoClassificacoesProps>) {
  const { ContainerPopover, Popover } = _io.styles

  return (
    <Popover
      keepMounted
      anchorEl={popoverAberto}
      open={Boolean(popoverAberto)}
      onClose={handleClosePopover}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
    >
      <ContainerPopover>{classificacoes.map(_io.renderClassificacoes)}</ContainerPopover>
    </Popover>
  )
}
