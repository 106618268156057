import { TextField, Typography } from '@material-ui/core'
import styled from 'styled-components'
import { customTheme } from '../../../../styles/customTheme'

export const cabecalhoStyles = {
  InputData: styled(TextField).attrs({
    color: 'primary',
    border: 'none',
    outline: 'none',
    type: 'date',
    variant: 'outlined',
    size: 'small',
    focused: true,
    style: {
      color: `${customTheme.colors.white01}`,
      background: `${customTheme.colors.white01}`,
      border: 'none',
      outline: 'none'
    }
  })`
    margin-left: 8px;
    border-radius: 8px;
  `,
  ContainerCabecalho: styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  `,
  ContainerSubtitulo: styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    margin: 0px 0 8px 0;
  `,
  ContainerError: styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 0;
  `,
  ContainerTextoErroPreenchimento: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
  TextoErroPreenchimento: styled(Typography).attrs({
    variant: 'caption'
  })`
    color: ${customTheme.colors.red06};
  `
}
