import { useState } from 'react'
import { cabecalhoLancamentosVariaveis } from '../../types/tabelaTypes'
import { tabelaEditavelStyles } from './TabelaEditavel.styles'
import { TabelaEditavelProps } from './TabelaEditavel.View'

export function tabelaEditavelIO({ textoFiltro }: TabelaEditavelProps) {
  const [colunaLancamentoVariaveis, setColunaLancamentoVariaveis] = useState<any>(cabecalhoLancamentosVariaveis(textoFiltro))

  return {
    styles: tabelaEditavelStyles,
    colunaLancamentoVariaveis,
    setColunaLancamentoVariaveis
  }
}

export type TabelaEditavelIO = ReturnType<typeof tabelaEditavelIO>
