import styled from 'styled-components'
import { CustomDialog } from '../CustomDialog/CustomDialog'

export const modalClassificarPedidosStyles = {
  CustomDialog: styled(CustomDialog)`
    .MuiDialog-paper {
      width: 444px;
      height: 390px;
    }
  `
}
