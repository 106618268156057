import { IOProps } from 'react-compose-io'
import { BotaoIconeSvgIO } from './BotaoIconeSvg.IO'

export enum CoresIconeProps {
  Primaria = 'primaria',
  Sucesso = 'verde',
  Erro = 'vermelho'
}

export type BotaoIconeSvgProps = {
  icone: JSX.Element
  onClick: () => void
  corIcone?: CoresIconeProps
  disabled?: boolean
}

export function BotaoIconeSvgView({ _io, icone, onClick, corIcone, disabled }: IOProps<BotaoIconeSvgIO, BotaoIconeSvgProps>): JSX.Element {
  const { Container, Button, Icon } = _io.styles

  return (
    <Button disabled={disabled}>
      <Container coricone={corIcone}>
        <Icon onClick={onClick}>{icone}</Icon>
      </Container>
    </Button>
  )
}
