import { AppBar, Toolbar, Box, IconButton, Typography, Avatar, MenuItem } from '@material-ui/core'
import styled from 'styled-components'
import { customTheme } from '../../styles/customTheme'
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone'

export const headerStyles = {
  AppBar: styled(AppBar)`
    background: ${process.env.REACT_APP_HEADER_BACKGROUND_COLOR};
    color: ${customTheme.colors.blue05};
  `,
  Toolbar: styled(Toolbar)`
    width: 100%;
    display: flex;
    justify-content: space-between;
  `,
  MenuContainer: styled(Box)`
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  ToggleIconButton: styled(IconButton)`
    margin-left: 8px;
  `,
  TextHeader: styled(Typography)`
    color: ${process.env.REACT_APP_HEADER_TEXT_COLOR};
    font-style: normal;
    font-weight: 500;
    font-size: ${customTheme.fontSize[18]};
  `,
  NotificationsNoneIcon: styled(NotificationsNoneIcon)`
    font-size: ${customTheme.fontSize[32]};
  `,
  Avatar: styled(Avatar)`
    color: ${customTheme.colors.white01};
    background-color: ${customTheme.colors.orange01};
  `,
  MenuItem: styled(MenuItem)`
    width: 200px;
  `,
  BoxLogo: styled(Box)`
    margin: 0px 8px;
  `
}
