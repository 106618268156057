import { Route, Switch } from 'react-router-dom'
import { CustomBreadcrumbs } from '../components/CustomBreadcrumbs'
import { Layout } from '../components/Layout/Layout'
import { Login, PaginaNaoEncontrada } from '../pages'
import ProtectedRoute from './ProtectedRoute'
import { ROUTES } from './routes'

export const Router = () => {
  return (
    <Switch>
      <Route path="/login" component={Login} />
      <Layout>
        {ROUTES.map(({ path, Component }, key) => (
          <ProtectedRoute
            isAuth
            exact
            path={path}
            key={key}
            render={(props: any) => {
              return (
                <>
                  <CustomBreadcrumbs {...props} />
                  <Component {...props} />
                </>
              )
            }}
          />
        ))}
      </Layout>
      <Route path="/404" component={() => <PaginaNaoEncontrada />} />
    </Switch>
  )
}
