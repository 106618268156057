import { FormControl, Grid, InputLabel, MenuItem, Select, TextField, OutlinedInput } from '@material-ui/core'
import { IOProps } from 'react-compose-io'
import { Controller } from 'react-hook-form'
import { ModalClassificarPedidosIO } from './ModalClassificarPedidos.IO'
import { Botao } from '../Botao'
import { Tipografia } from '../Tipografia'
import { BaseClassificacao } from '../../types/genericoType'

export type ModalClassificarPedidosProps = {
  isShow: boolean
  isClosed: () => void
  handleConfirmar: (data: { observacao: string; classificacao: number }) => Promise<void>
  classificacoes: BaseClassificacao[]
  classificacaoSugerida: number
}

export default function ModalClassificarPedidosView({
  _io,
  isShow,
  classificacoes,
  classificacaoSugerida
}: IOProps<ModalClassificarPedidosIO, ModalClassificarPedidosProps>) {
  const { CustomDialog } = _io.styles

  return (
    <CustomDialog
      open={isShow}
      onClose={_io.fecharModal}
      id="modal-iniciar-novo-ciclo"
      titulo="Classificar Pedidos"
      habilitarAcoes
      fecharHabilitado
      fecharModal={_io.fecharModal}
      actions={
        <Grid container spacing={2} alignContent="center" justifyContent="center">
          <Grid item>
            <Botao textoBotao="Cancelar" estilo="text" onClick={_io.fecharModal} cor="primaria" />
          </Grid>
          <Grid item>
            <Botao textoBotao="Confirmar" onClick={_io.handleSubmit(_io.submit)} estilo="rounded" cor="primaria" disabled={_io.confirmarDesabilitado} />
          </Grid>
        </Grid>
      }
    >
      {classificacaoSugerida == 0 ? (
        <Tipografia>Classifique o(s) pedido(s) selecionado(s) de acordo com a sua análise, e adicione uma observação, se necessário.</Tipografia>
      ) : (
        <Tipografia>Um usuário da logística classificou o pedido. Deseja confirmar ou alterar a classificação?</Tipografia>
      )}
      <br />
      <form>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Controller
              render={({ field }) => (
                <FormControl variant="outlined" size="small" fullWidth>
                  <InputLabel shrink>Classificação da análise</InputLabel>
                  <Select {...field} label="Classificação da análise" input={<OutlinedInput notched name="Classificação da análise" labelWidth={180} />}>
                    {classificacoes.map((classificacao: BaseClassificacao) => (
                      <MenuItem key={classificacao.value} value={classificacao.value}>
                        {classificacao.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
              name="classificacao"
              control={_io.control}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Controller
              render={({ field }) => <TextField {...field} label="Observações" variant="outlined" size="small" fullWidth multiline minRows={3} maxRows={6} />}
              name="observacao"
              control={_io.control}
            />
          </Grid>
        </Grid>
      </form>
    </CustomDialog>
  )
}
