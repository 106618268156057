import { IOProps } from 'react-compose-io'
import { ContasResumos } from '../../types/regularizacaoTypes'
import { formateCurrency } from '../../utils/stringUtils'
import { ItensRegularizacaoContaResumoIO } from './ItensRegularizacaoContaResumo.IO'

export type ItensRegularizacaoContaResumoProps = {
  contasResumos: ContasResumos[]
  descricaoBaixas: string
  corFundo: string
}

export function ItensRegularizacaoContaResumoView({
  _io,
  contasResumos,
  descricaoBaixas,
  corFundo
}: IOProps<ItensRegularizacaoContaResumoIO, ItensRegularizacaoContaResumoProps>) {
  const { ContainerInformacoesValor, ContainerContasResumo, ValorTexto, ValorRotulo, ContainerContaResumo } = _io.styles

  return (
    <>
      {contasResumos.map((contaResumo, index) => (
        <ContainerContasResumo corFundo={corFundo} container key={index}>
          <ContainerContaResumo>
            <ValorRotulo>Conta contábil:</ValorRotulo>
            <ValorTexto>{contaResumo.contaContabil}</ValorTexto>
          </ContainerContaResumo>
          <ContainerInformacoesValor>
            <ValorRotulo>{descricaoBaixas}</ValorRotulo>
            <ValorTexto>{contaResumo.quantidade.toLocaleString('pt-BR')}</ValorTexto>
          </ContainerInformacoesValor>
          <ContainerInformacoesValor>
            <ValorRotulo>Valor:</ValorRotulo>
            <ValorTexto>{formateCurrency(contaResumo.valor)}</ValorTexto>
          </ContainerInformacoesValor>
        </ContainerContasResumo>
      ))}
    </>
  )
}
