import Cookies from 'js-cookie'
import { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { PAGES } from '../router/routes'
import jwt from 'jwt-decode'

export default function useAuth() {
  const location = useLocation()
  const router = useHistory()
  const username = Cookies.get('username')

  const { from }: any = location.state || { from: { pathname: '/inicio' } }
  const [autenticado, setAutenticado] = useState(false)

  useEffect(() => {
    if (username) {
      setAutenticado(true)
    }
  }, [username])

  const handleLogin = (tokenUsuario: string) => {
    const jwtDecodificado: { nome: string } = jwt(tokenUsuario)

    if (jwtDecodificado) {
      Cookies.set('username', jwtDecodificado.nome, {
        secure: true,
        path: `${from}`,
        sameSite: 'None',
        expires: 1
      })

      setAutenticado(true)
      router.push(PAGES.inicio)
    }
  }

  return { handleLogin, autenticado }
}
