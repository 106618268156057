import {
  AtualizarPedidoInadimplenciaRequest,
  ClassificacaoInadimplencia,
  ControleInadimplenciasResponse,
  ObterPedidosInadimplenciaQuery,
  ObterPedidosInadimplenciaResponse,
  InadimplenciaPerdasEfetivadas
} from '../../../types/controleInadimplenciaTypes'
import { PossiveisTratativas, ResponsePaginadoCargo, ResumoPedidosPerdasClassificacao } from '../../../types/perdasTypes'
import controleInadimplenciaService from './controleInadimplenciaService'

const buscarControleInadimplencias = async (): Promise<ControleInadimplenciasResponse> => controleInadimplenciaService.buscarControleInadimplencias()

const gerarDadosControleInadimplencias = async (): Promise<boolean> => controleInadimplenciaService.gerarDadosControleInadimplencias()

const buscarPedidosInadimplencia = async (query: ObterPedidosInadimplenciaQuery): Promise<ResponsePaginadoCargo<ObterPedidosInadimplenciaResponse>> =>
  controleInadimplenciaService.buscarPedidosInadimplencia(query)

const buscarTratativasInadimplencia = async (): Promise<PossiveisTratativas> => controleInadimplenciaService.buscarTratativasInadimplencia()

const atualizarPedidosInadimplencia = async (body: AtualizarPedidoInadimplenciaRequest[]): Promise<boolean> =>
  controleInadimplenciaService.atualizarPedidosInadimplencia(body)

const buscarClassificacoesInadimplencia = async (): Promise<ClassificacaoInadimplencia[]> => controleInadimplenciaService.buscarClassificacoesInadimplencia()

const buscarHistoricoInadimplenciaEfetivadas = async (): Promise<InadimplenciaPerdasEfetivadas[]> =>
  controleInadimplenciaService.buscarHistoricoInadimplenciaEfetivadas()

const buscarResumoPedidosClassificadosInadimplencia = async (): Promise<ResumoPedidosPerdasClassificacao[]> =>
  controleInadimplenciaService.buscarResumoPedidosClassificadosInadimplencia()

export default {
  buscarControleInadimplencias,
  gerarDadosControleInadimplencias,
  buscarPedidosInadimplencia,
  atualizarPedidosInadimplencia,
  buscarClassificacoesInadimplencia,
  buscarHistoricoInadimplenciaEfetivadas,
  buscarResumoPedidosClassificadosInadimplencia,
  buscarTratativasInadimplencia
}
