import { IconButton, TableCell, TableRow, Checkbox } from '@material-ui/core'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import { IOProps } from 'react-compose-io'
import { formateCurrency, formateDate } from '../../../../utils/stringUtils'
import { ItemInadimplenciaIO } from './ItemInadimplencia.IO'
import { corChip, corLetraChip } from '../../../../styles/customTheme'
import { ObterPedidosInadimplenciaResponse } from '../../../../types/controleInadimplenciaTypes'
import { HistoricoInadimplencia } from '../HistoricoInadimplencia'
import { SinoIcon } from './../../../../assets/icons/SinoIcon'
import { ChipClassificacaoPerda } from './../../../../components/ChipClassificacaoPerda/ChipClassificacaoPerda'

export type ItemInadimplenciaProps = {
  item: ObterPedidosInadimplenciaResponse
  checklistsSelecionados: string[]
  handleCheckList: (pedidoMkt: string) => void
  isAdministrador: boolean
  handleAprovarSugestao: (pedidoMkt: string, classificacaoSugerida: number) => void
}

export function ItemInadimplenciaView({
  _io,
  item,
  handleCheckList,
  isAdministrador,
  handleAprovarSugestao
}: IOProps<ItemInadimplenciaIO, ItemInadimplenciaProps>) {
  return (
    <>
      <TableRow>
        <TableCell align="center">
          <IconButton aria-label="expand row" size="small" onClick={_io.handleAbrirHistoricoToggle}>
            {_io.historicoAberto ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="center">
          <Checkbox checked={_io.isCheckListSelecionado()} onChange={() => handleCheckList(item.pedidoMkt)} />
        </TableCell>
        <TableCell align="center">{formateDate(item.dataEmissao)}</TableCell>
        <TableCell align="center">{item.pedidoMkt}</TableCell>
        <TableCell align="center">{formateCurrency(item.saldo)}</TableCell>
        <TableCell align="center">{item.tratativa}</TableCell>
        <TableCell align="center">
          {_io.isPedidoComSugestaoPendente && <SinoIcon />}
          {_io.isPedidoAprovado && (
            <ChipClassificacaoPerda label={item.classificacao} bgcolor={corChip.get(item.classificacao)} fontcolor={corLetraChip.get(item.classificacao)} />
          )}
        </TableCell>
        <TableCell align="center">{item.parceiro}</TableCell>
      </TableRow>
      <HistoricoInadimplencia
        historicoAberto={_io.historicoAberto}
        item={item}
        isAdministrador={isAdministrador}
        handleAprovarSugestao={handleAprovarSugestao}
      />
    </>
  )
}
