import { Grid, IconButton, InputAdornment, TableCell, TableRow, TextField, Typography, CircularProgress } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import { IOProps } from 'react-compose-io'
import { Controller } from 'react-hook-form'
import { Tabela } from '../../../../components/Tabela'
import { CicloTabelaItem } from '../../../../types/ciclosApiType'
import { cabecalhoTabelaCiclo } from '../../../../types/tabelaTypes'
import { formateCurrency } from '../../../../utils/stringUtils'
import { TabelaCicloIO } from './TabelaCiclo.IO'

export type TabelaCicloProps = {
  cicloId: number | string | undefined
  updateTable: boolean
}

function linhaTabela(props: { item: CicloTabelaItem }) {
  const { item } = props

  return (
    <>
      <TableRow>
        <TableCell align="center">{item.identificadorPedido || ''}</TableCell>
        <TableCell align="center">{item.loja || ''}</TableCell>
        <TableCell align="center">{item.tipoLancamento || ''}</TableCell>
        <TableCell align="center">{item.lancamento || ''}</TableCell>
        <TableCell align="center">{formateCurrency(item.valor)}</TableCell>
        <TableCell align="center">{item.parcela || ''}</TableCell>
      </TableRow>
    </>
  )
}

export function TabelaCicloView({ _io }: IOProps<TabelaCicloIO, TabelaCicloProps>) {
  const { Container, GridHeader, LimitedBackdrop } = _io.styles

  return (
    <Container>
      <LimitedBackdrop open={_io.loading}>
        <CircularProgress color="primary" />
      </LimitedBackdrop>
      <GridHeader>
        <Grid item xs={12} sm={9}>
          <Typography variant="h6">Tabela do Ciclo</Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                size="small"
                label="Buscar"
                variant="outlined"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={_io.handleSubmit(_io.pesquisarPedido)} edge="end">
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            )}
            name="pedidoId"
            control={_io.control}
          />
        </Grid>
      </GridHeader>
      <Tabela
        cabecalho={cabecalhoTabelaCiclo}
        itensLista={_io.linhas}
        LinhaTabela={linhaTabela}
        orderCampoPadrao={''}
        rowsPerPageOptions={[10, 25, 50, 100]}
        linhasPorPaginaPadrao={25}
        maxHeight={'none'}
        alinharTextoCentro={true}
        buscarItensTabela={_io.buscarTabelaCiclo}
        totalItens={_io.totalLinhas}
      />
    </Container>
  )
}
