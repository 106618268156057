import { useState } from 'react'
import { ItemDebitoProps } from './ItemDebito.View'
import { stringIsNullOrEmpty } from '../../../../utils/stringUtils'

export function itemDebitoIO({ item, checklistsSelecionados }: ItemDebitoProps) {
  const [historicoAberto, setHistoricoAberto] = useState(false)

  const isCheckListSelecionado = () => {
    return checklistsSelecionados.includes(item.pedidoMkt)
  }

  return {
    historicoAberto,
    handleAbrirHistoricoToggle: () => setHistoricoAberto(!historicoAberto),
    isCheckListSelecionado,
    isPedidoComSugestaoPendente: !item.aprovado && !stringIsNullOrEmpty(item.classificacao),
    isPedidoAprovado: item.aprovado && !stringIsNullOrEmpty(item.classificacao)
  }
}

export type ItemDebitoIO = ReturnType<typeof itemDebitoIO>
