// *** LOADING ***
export const LOADING_UPDATE = 'LOADING_UPDATE'
export const LOADING_RESET = 'LOADING_RESET'

export const loadingUpdate = (show: boolean) => ({
  type: LOADING_UPDATE,
  payload: show
})

export const loadingReset = () => {
  return {
    type: LOADING_RESET,
    payload: ''
  }
}
