import { Box, Table, TableCell, TableRow, Typography } from '@material-ui/core'
import styled from 'styled-components'
import { ContainerSombra } from '../../../../components/ContainerSombra'
import { customTheme } from '../../../../styles/customTheme'

export const lancamentoNaoAssociadoStyles = {
  Container: styled(ContainerSombra)`
    width: 100%;
    margin-top: 19px;
    background-color: ${customTheme.colors.red00};
    flex-grow: 1;
    padding: 18px;
  `,
  TableRow: styled(TableRow)`
    text-align: center;
  `,
  BoxAssociada: styled(Box)`
    background-color: transparent;
    color: ${customTheme.colors.red06};
    border: 1px solid ${customTheme.colors.red06};
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: capitalize;
    height: 31px;
    width: 200px;
  `,
  TableCellBody: styled(TableCell)`
    color: ${customTheme.colors.red06};
  `,
  SubtitleCabecalho: styled(Typography).attrs({
    variant: 'subtitle1'
  })`
    color: ${customTheme.colors.grey23};
    margin-top: 8px;
    margin-bottom: 24px;
  `,
  ContainerCabecalho: styled.div`
    display: flex;
    flex-direction: column;
  `,
  Box: styled(Box)`
    display: flex;
    justify-content: center;
  `,
  Tabela: styled(Table)``
}
