const LOGOUT_ERRO = 'Não foi possivel realizar logout!'

const ERRO_PRE_VISUALIZACAO = 'Não foi possivel exibir a pré visualização, verifique os dados novamente!'

const BUSCAR_TABELA_CONTABILIZACAO_ERRO = 'Falha ao buscar as Contabilizações Vinculadas a esse Ciclo!'
const BUSCAR_DETALHES_CONTABILIZACAO_ERRO = 'Falha ao buscar os detalhes da conciliação!'
const BUSCAR_ERROS_PROCESSAMENTO_ERRO = 'Falha ao buscar Erro de Processamento!'

const INICIAR_CICLO = {
  parceiro_inexistente: 'Nenhum parceiro com este Identificador',
  processar_sucesso: 'Processamento realizado com sucesso',
  internalizacao_sucesso: 'Internalização realizada com sucesso!',
  internalizacao_erro: 'Falha ao realizar a internalização',
  validacao_ciclo_sucesso: 'Validação do ciclo concluída com sucesso!',
  validacao_ciclo_erro: 'Falha ao realizar a validação do ciclo',
  erro_buscar_resumo_contabilizacao: 'Falha ao buscar os resumo da conciliação!',
  erro_ignorar_regra: 'Erro ao ignorar regra(s)!',
  sucesso_ignorar_regra: 'Regras(s) ignorada(s) com sucesso!',
  erro_editar_planilha: 'Erro ao editar planilha!',
  sucesso_editar_planilha: 'Planilha editada com sucesso'
}

const PEDIDO_NAO_ENCONTRADO = (pedidoId: string) => `O pedido ${pedidoId} não foi encontrado`

const LOGIN = {
  loginSucesso: 'Login realizado com sucesso!',
  paginaNaoEncontrada: 'Página não encontrada'
}

const USO_GENERICO = {
  erro_generico: 'Erro inesperado, tente novamente mais tarde',
  campo_obrigatorio: 'Campo obrigatório',
  cadastro_sucesso: 'Cadastro realizado com sucesso!',
  cadastro_erro: 'Erro ao realizar cadastro!',
  edicao_sucesso: 'Edição realizada com sucesso!',
  edicao_erro: 'Erro ao realizar a edição!',
  desativar_sucesso: 'Desativação realizada com sucesso!',
  erroExportar: 'Não foi possível exportar relatório',
  erroBusca: 'Erro ao realizar busca!'
}

const OPS = {
  buscar: 'OP(s) encontrada',
  excluir: 'OP excluída com sucesso!',
  selecionar: 'OP(s) selecionadas com sucesso!',
  erroSelecionar: 'Erro ao selecionar OP(s)',
  erroBuscar: 'Erro ao buscar OP(s)',
  erroExcluir: 'Erro ao excluir OP(s)',
  confirmar: 'OP(s) vinculada com sucesso!',
  erroBuscarTotal: 'Erro ao buscar total do ciclo!'
}

const ARQUIVO = {
  processando: 'Processando arquivo!',
  erroUpload: 'Erro no upload do arquivo!',
  extensaoInvalida: 'Este arquivo não está entre as extenções válidas!',
  uploadPreVisualizarErro: 'Efetuar upload do arquivo para executar a pré visualização',
  erroUploadArquivo: 'Erro ao fazer upload do arquivo',
  erroProcessamento: 'Não foi possivel buscar os erros de processamento!'
}

const CICLO_FLUXO = {
  erroTransicaoEtapa: 'Erro na transição do historico do ciclo!',
  cicloCancelado: 'Ciclo cancelado com Sucesso',
  cicloCanceladoErro: 'Erro ao cancelar o Ciclo',
  erroBuscarTabelaCiclo: 'Erro ao buscar a tabela transformada',
  erroIniciarCiclo: 'Erro ao iniciar novo ciclo',
  erroBuscarStatus: 'Erro ao busca status do ciclo'
}

const TIPO_LANCAMENTO_PARCEIRO = {
  erroBusca: 'Erro ao buscar tipos lançamentos do Parceiro',
  buscar_historico_sequencia_erro: 'Erro ao obter historico padrão vinculado a sequencia'
}

const LANCAMENTO_ASSOCIACAO = {
  erroLancamentoNaoAssociado: 'Erro ao tratar lançamento lançamentos não associados',
  sucessoCadastro: 'Sucesso ao cadastrar tipo de lançamento',
  erroCadastro: 'Erro ao cadastrar tipo de lançamento',
  sucessoEditar: 'Sucesso ao editar tipo de lançamento',
  erroEditar: 'Erro ao editar tipo de lançamento',
  erroBuscaLancamentoNaoAssociacao: 'Erro ao buscar lançamentos não associados'
}

const LANCAMENTO_VARIAVEIS = {
  erroBuscarLancamentos: 'Não foi possivel buscar os lançamentos variaveis'
}

const TITULOS = {
  erroTitulos: 'Não foi possível buscar os títulos',
  erroRelatorio: 'Não foi possível exportar relatório'
}

const REGULARIZACAO = {
  erroAoBuscarPendencias: 'Erro ao buscar informações, tente novamente',
  sucessoRegularizacaoEfetivacao: 'Sucesso ao efetivar o regularização',
  erroRegularizacaoEfetivacao: 'Erro ao efetivar o regularização',
  semInformacoesConsulta: 'A informação solicitada não consta na nossa base de dados. Faça uma nova consulta!',
  inserirArquivo: 'Faça upload de um arquivo para continuarmos!'
}

const CONTROLE_COMISSAO = {
  erroAtualizarStatus: 'Erro ao atualizar status!',
  erroBuscarChamdos: 'Erro ao buscar chamados!',
  erroEditarChamado: 'Erro ao editar chamado!',
  erroAdicionarChamado: 'Erro ao adicionar chamado!',
  pedidoAtualizadoSucesso: 'Pedido atualizado com sucesso'
}

const CICLO_PEDIDO = {
  observacaoAdicionadaSucesso: 'Observação adicionada com sucesso',
  observacaoAdicionadaErro: 'Erro ao adicionar observações!',
  statusResolvidoSucesso: 'Status resolvido vinculado com sucesso com sucesso',
  statusResolvidoErro: 'Erro ao vincular status resolvido!'
}

const DEBITO_PEDIDO = {
  pedidoAtualizadoSucesso: 'Pedido atualizado com sucesso',
  pedidoAtualizadoErro: 'Erro ao atualizar pedido!'
}

export default {
  INICIAR_CICLO,
  LOGOUT_ERRO,
  ERRO_PRE_VISUALIZACAO,
  OPS,
  LOGIN,
  BUSCAR_TABELA_CONTABILIZACAO_ERRO,
  BUSCAR_DETALHES_CONTABILIZACAO_ERRO,
  BUSCAR_ERROS_PROCESSAMENTO_ERRO,
  ARQUIVO,
  CICLO_FLUXO,
  TIPO_LANCAMENTO_PARCEIRO,
  LANCAMENTO_ASSOCIACAO,
  LANCAMENTO_VARIAVEIS,
  USO_GENERICO,
  TITULOS,
  PEDIDO_NAO_ENCONTRADO,
  REGULARIZACAO,
  CONTROLE_COMISSAO,
  CICLO_PEDIDO,
  DEBITO_PEDIDO
}
