import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import msgConstants from '../../../../constants/msgConstants'
import { useConciliacaoContext } from '../../../../contexts/conciliacao-context'
import ciclosManager from '../../../../services/api/ciclos/ciclosManager'
import { arquivoTemp } from '../../../../types/ciclosApiType'
import { base64FileToCsvArray, csvArrayToFile, getExtensaoArquivo } from '../../../../utils/uploadFileUtils'
import { uploadPlanilhaStyles } from './UploadPlanilha.styles'
import { UploadPlanilhaProps } from './UploadPlanilha.View'

export function uploadPlanilhaIO({ setArquivoId }: UploadPlanilhaProps) {
  const { getHeadersPlanilha } = useConciliacaoContext()
  const [cabecalho, setCabecalho] = useState<any>([])
  const [colunas, setColunas] = useState<any>([])
  const [arquivos, setArquivos] = useState<File[]>([])
  const [loading, setLoading] = useState(false)
  const [carregandoEnvioArquivos, setCarregandoEnvioArquivos] = useState(false)
  const [envioArquivos, setEnvioArquivos] = useState(false)
  const [visible, setVisible] = useState(false)
  const [progress, setProgress] = useState(0)
  const [complete, setComplete] = useState(false)
  const [erroUpload, setErroUpload] = useState(false)
  const [nomeArquivo, setNomeArquivo] = useState('')
  const [tamanhoDoArquivo, setLinhasCarregadas] = useState<number>()
  const [linhasCarregadas, setLoadedRows] = useState<any[]>([])
  const [setError] = useState<any>()

  useEffect(() => {
    async function loadMore() {
      setLoading(true)
      try {
        if (colunas.length > linhasCarregadas.length) {
          const newData = colunas.slice(linhasCarregadas.length, linhasCarregadas.length + 100)
          await sleep(500)
          setLoadedRows((loadedRowsInsideState) => loadedRowsInsideState.concat(newData))
        }
      } catch (err) {
        setError(err)
      } finally {
        setLoading(false)
      }
    }

    const observadorIntercesao = new IntersectionObserver((entries) => {
      if (entries.some((entry) => entry.isIntersecting)) {
        loadMore()
      }
    })

    if (document.querySelector('#sentinela')) {
      observadorIntercesao.observe(document.querySelector('#sentinela') || new Element())
    }

    return () => observadorIntercesao.disconnect()
  }, [cabecalho, colunas, setError, linhasCarregadas.length])

  async function sleep(duration: number) {
    return new Promise<void>((resolve) => {
      setTimeout(() => {
        resolve()
      }, duration)
    })
  }

  const handleSubmit = (filesUploaded: File[]) => {
    setVisible(true)
    setEnvioArquivos(true)
    setCarregandoEnvioArquivos(true)

    filesUploaded.forEach((file: File) => {
      setNomeArquivo(file.name)
      setLinhasCarregadas(file.size)
      setArquivos([...arquivos, file])

      const fileExtension = getExtensaoArquivo(file.name)
      const reader = new FileReader()

      if (fileExtension != undefined) {
        reader.onloadend = () => {
          const fileArray: any[] = base64FileToCsvArray(reader.result as string)

          setCabecalho(fileArray[0])
          getHeadersPlanilha(fileArray[0])
          setColunas(fileArray.slice(1))
          setLoadedRows(fileArray.slice(1, 101))

          const contentType = 'text/csv'
          const newFile = csvArrayToFile(fileArray, file.name, contentType)

          ciclosManager
            .uploadArquivoTemp(fileExtension.name)
            .then((responseTemp: arquivoTemp) => {
              setProgress(50)
              ciclosManager
                .uploadArquivoCiclo(responseTemp.url, newFile, fileExtension.value)
                .then(() => {
                  toast.success(`Upload do arquivo ${file.name} efetuado com sucesso`)
                  setArquivoId(responseTemp.arquivoId)
                  setComplete(true)
                  setProgress(100)
                })
                .catch(() => {
                  setErroUpload(true)
                  setEnvioArquivos(false)
                  setComplete(true)
                  toast.error(msgConstants.ARQUIVO.erroUploadArquivo)
                })
            })
            .catch((err) => {
              if (err) {
                setErroUpload(true)
                setEnvioArquivos(false)
                setComplete(true)
                toast.error(msgConstants.ARQUIVO.erroUploadArquivo, { type: 'error' })
              }
            })
        }
        reader.readAsDataURL(file)
      }
    })
    setCarregandoEnvioArquivos(false)
  }

  const handleReset = () => {
    setVisible(false)
    setCarregandoEnvioArquivos(false)
    setComplete(false)
    setErroUpload(false)
    setEnvioArquivos(false)
    setArquivos([])
  }

  return {
    styles: uploadPlanilhaStyles,
    handleSubmit,
    visible,
    nomeArquivo,
    tamanhoDoArquivo,
    progress,
    envioArquivos,
    erroUpload,
    carregandoEnvioArquivos,
    complete,
    handleReset,
    arquivos,
    cabecalho,
    linhasCarregadas,
    loading
  }
}
export type UploadPlanilhaIO = ReturnType<typeof uploadPlanilhaIO>
