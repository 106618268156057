import { Box } from '@material-ui/core'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { ContainerSombra } from '../../components/ContainerSombra'
import { customTheme } from '../../styles/customTheme'
import { Tipografia } from './../../components/Tipografia/index'

export const parametrosSelecionarStyles = {
  Container: styled(ContainerSombra)`
    padding: 24px 30px;
    margin-top: 16px;
  `,
  LinksContainer: styled(Box)`
    margin-top: 32px;
  `,
  Link: styled(Link)`
    color: ${customTheme.colors.grey16};

    :hover {
      color: ${customTheme.colors.primary};
    }
  `,
  Subtitulo: styled(Tipografia)`
    margin-top: 8px;
    margin-bottom: 24px;
  `
}
