import { Grid } from '@material-ui/core'
import { IOProps } from 'react-compose-io'
import { Config, HistoricoPadrao } from '../../../../types/tipoLancamentoApiTypes'
import { validarTexto } from '../../../../utils/stringUtils'
import { ParametroContabilDetalhesIO } from './ParametroContabilDetalhes.IO'
import { Tipografia } from './../../../../components/Tipografia/index'
import { customTheme } from '../../../../styles/customTheme'

export type ParametroContabilDetalhesProps = {
  config?: Config
  sequencia: string
  historicoPadrao?: HistoricoPadrao
  parametrosContabeisLabel: string
  historicoPadraoLabel: string
}

export function ParametroContabilDetalhesView({
  _io,
  config,
  sequencia,
  historicoPadrao,
  parametrosContabeisLabel,
  historicoPadraoLabel
}: IOProps<ParametroContabilDetalhesIO, ParametroContabilDetalhesProps>) {
  const { ParagrafoChip, TextoChip, ContainerBranco } = _io.styles

  return (
    <>
      <TextoChip>{parametrosContabeisLabel}</TextoChip>
      <ContainerBranco>
        <Grid container spacing={1}>
          <Grid item xs={3} sm={3}>
            <Tipografia variant="body2" colorText={customTheme.colors.grey03}>
              Sigla Sistema
            </Tipografia>
          </Grid>
          <Grid item xs={9} sm={9}>
            <Tipografia variant="body2" colorText={customTheme.colors.grey03}>
              Unidade Negócio
            </Tipografia>
          </Grid>
          <Grid item xs={3} sm={3}>
            <Tipografia variant="body2" colorText={customTheme.colors.grey04} fontWeight="500">
              {config ? `${config.siglaSistema} - ${config.sistemaDescricao}` : '-'}
            </Tipografia>
          </Grid>
          <Grid item xs={9} sm={9}>
            <Tipografia variant="body2" colorText={customTheme.colors.grey04} fontWeight="500">
              {config ? `${config.unidadeNegocioId} - ${config.unidadeNegocioDescricao}` : '-'}
            </Tipografia>
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={3} sm={3}>
            <Tipografia variant="body2" colorText={customTheme.colors.grey03}>
              Evento
            </Tipografia>
          </Grid>
          <Grid item xs={9} sm={9}>
            <Tipografia variant="body2" colorText={customTheme.colors.grey03}>
              Fato
            </Tipografia>
          </Grid>
          <Grid item xs={3} sm={3}>
            <Tipografia variant="body2" colorText={customTheme.colors.grey04} fontWeight="500">
              {config ? `${config.unidadeNegocioId} - ${config.unidadeNegocioDescricao}` : '-'}
            </Tipografia>
          </Grid>
          <Grid item xs={9} sm={9}>
            <Tipografia variant="body2" colorText={customTheme.colors.grey04} fontWeight="500">
              {config ? `${config.fatoId} - ${config.fatoDescricao}` : '-'}
            </Tipografia>
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12}>
            <Tipografia variant="body2" colorText={customTheme.colors.grey03}>
              Sequencia
            </Tipografia>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Tipografia variant="body2" colorText={customTheme.colors.grey04} fontWeight="500">
              {validarTexto(sequencia, '-')}
            </Tipografia>
          </Grid>
        </Grid>
      </ContainerBranco>

      <TextoChip>{historicoPadraoLabel}</TextoChip>
      <ContainerBranco>
        <Tipografia variant="body2" colorText={customTheme.colors.grey04}>
          Histórico
        </Tipografia>
        <ParagrafoChip>{historicoPadrao ? `${historicoPadrao.historicoPadraoId} ${historicoPadrao.historicoPadrao}` : '-'}</ParagrafoChip>
      </ContainerBranco>
    </>
  )
}
