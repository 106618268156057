import { Box } from '@material-ui/core'
import styled from 'styled-components'
import { customTheme } from '../../../../styles/customTheme'

export const cardComissaoStyles = {
  Box: styled(Box)`
    border-radius: 8px;
    margin: 10px 0px;
    padding: 8px;
    color: ${customTheme.colors.grey23};
    background-color: ${(props: { background: string }) => props.background};
  `
}
