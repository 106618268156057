import { IOProps } from 'react-compose-io'
import { ControleComissaoPedidoClassificacaoIO } from './ControleComissaoPedidoClassificacao.IO'
import { Tipografia } from '../../components/Tipografia'
import { Grid } from '@material-ui/core'
import { Controller } from 'react-hook-form'
import TextField from '../../components/TextField/TextField'
import { Botao } from '../../components/Botao'
import { TabelaControleComissaoPedido } from '../../components/TabelaControleComissaoPedido'
import { PopoverFiltroPedidoMkt } from '../../components/PopoverFiltroPedidoMkt'
import { PopoverFiltroPedidoComissaoClassificacoes } from '../../components/PopoverFiltroPedidoComissaoClassificacoes'
import { ModalClassificarPedidos } from '../../components/ModalClassificarPedidos'

type ControleComissaoPedidoClassificacaoProps = {}

export function ControleComissaoPedidoClassificacaoView({ _io }: IOProps<ControleComissaoPedidoClassificacaoIO, ControleComissaoPedidoClassificacaoProps>) {
  const { Container, ContainerFormDataButton, TextFieldDataFim, ContainerFormulario, BotaoExportar, Titulo } = _io.styles
  return (
    <>
      <Container>
        <Grid container justifyContent="space-between" spacing={2} direction="row">
          <Grid item>
            <Titulo>{'Detalhes dos pedidos'}</Titulo>
          </Grid>
          <BotaoExportar
            url={_io.urlTela}
            textoTooltip={''}
            relatorioDisponivel={!!_io.urlTela}
            iconeCarregandoRelatorio={_io.relatorioLoad}
            gerarRelatorio={() => _io.handleRelatorioTela()}
          />
        </Grid>
        <ContainerFormulario>
          <Grid container spacing={1} justifyContent="space-between" direction="row">
            <Grid item>
              <ContainerFormDataButton>
                <Controller
                  render={({ field }) => (
                    <TextField
                      {..._io.register('dataLancamentoInicio', { required: true })}
                      {...field}
                      label="Data de lançamento"
                      variant="outlined"
                      size="small"
                      type="date"
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  )}
                  name="dataLancamentoInicio"
                  control={_io.control}
                />
                <Tipografia>até</Tipografia>
                <Controller
                  render={({ field }) => (
                    <TextFieldDataFim
                      {..._io.register('dataLancamentoFim', { required: true })}
                      {...field}
                      label=""
                      variant="outlined"
                      size="small"
                      type="date"
                      error={_io.errors?.dataLancamentoFim ? true : false}
                      helperText={_io.errors.dataLancamentoFim?.message}
                    />
                  )}
                  name="dataLancamentoFim"
                  control={_io.control}
                />

                <Botao
                  width="94px"
                  textoBotao="Limpar"
                  cor="primaria"
                  estilo="text"
                  onClick={() => {
                    _io.handleLimpar()
                  }}
                />
                <Botao textoBotao="Filtrar" cor="primaria" estilo="outlined" onClick={_io.handleSubmit(_io.handleAplicar)} />
              </ContainerFormDataButton>
            </Grid>
            <Grid item>
              <Botao
                textoBotao="Classificar pedidos"
                estilo="contained"
                cor="primaria"
                onClick={() => {
                  _io.abrirModalClassificarPedido()
                }}
                disabled={_io.checkListSelecionados.length == 0}
              />
            </Grid>
          </Grid>
        </ContainerFormulario>

        <TabelaControleComissaoPedido
          cabecalhoTabela={_io.cabecalhosTabela}
          checklistsSelecionados={_io.checkListSelecionados}
          handleSelectAllCheckList={_io.handleCleanAllCheckList}
          quantidadeTotalControleComissaoPedidos={_io.controleComissaoPedidos ? _io.controleComissaoPedidos.totalItens : 0}
          pagina={_io.pagina}
          linhasPorPagina={_io.linhasPorPagina}
          handleMudarPagina={_io.onMudancaPagina}
          handleMudarTamanhoPagina={_io.onMudancaLinhasPorPagina}
        >
          {_io.controleComissaoPedidos && _io.controleComissaoPedidos.itens.map(_io.renderLinhasTabela)}
        </TabelaControleComissaoPedido>
      </Container>

      <ModalClassificarPedidos
        handleConfirmar={_io.handleClassificarPedidos}
        isClosed={_io.fecharModal}
        isShow={_io.modalClassificarPedidosAberto}
        classificacoes={_io.classificacoes}
        classificacaoSugerida={0}
      />
      <PopoverFiltroPedidoMkt popoverAberto={_io.filtroPedidoAberto} handleClosePopover={_io.closeFiltro}>
        <Controller
          render={({ field }) => <TextField {...field} label="Buscar n° pedido marketplace" variant="outlined" size="small" fullWidth />}
          name="pedidoMkt"
          control={_io.control}
        />
      </PopoverFiltroPedidoMkt>

      <PopoverFiltroPedidoComissaoClassificacoes
        popoverAberto={_io.filtroClassificacoesAberto}
        classificacoes={_io.classificacoes}
        handleClosePopover={_io.closeFiltro}
        isClassificacaoChecked={_io.isClassificacaoChecked}
        handleCheckClassificacao={_io.handleCheckFiltroClassificacao}
      />
    </>
  )
}
