import { useEffect, useState } from 'react'
import { useFieldArray, useForm, useWatch } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import msgConstants from '../../../../constants/msgConstants'
import { TIPO_PARAMETRO } from '../../../../constants/utilConstants'
import { PAGES, ABAS_DETALHES_PARCEIROS } from '../../../../router/routes'
import AssociacaoManager from '../../../../services/api/associacao/associacaoManager'
import tipoLancamentoManager from '../../../../services/api/tipoLancamento/tipoLancamentoManager'
import { loadingReset, loadingUpdate } from '../../../../store/actions/loadingAction'
import { PreviewItemAssociacao, RegraAssociacaoForm } from '../../../../types/associacaoApiTypes'
import { Condicao } from '../../../../types/regraApiType'
import { TiposLancamentosData } from '../../../../types/tipoLancamentoApiTypes'
import { gerarNomeRegraAssociacao } from '../../../../utils/stringUtils'
import { formNovaAssociacaoStyles } from './FormAssociacao.style'
import { FormAssociacaoProps } from './FormAssociacao.View'

const defaultValues = {
  nomeRegra: '',
  campo: 'TipoLancamento',
  ativo: true,
  condicoes: [
    {
      operacaoId: '',
      dado: '',
      operador: '',
      parametro: {
        parametro: '',
        tipoParametro: TIPO_PARAMETRO.criterio
      },
      tipoCondicao: 'E'
    }
  ],
  acoes: [
    {
      operacaoId: 'TipoLancamentoOperacao',
      dado: '',
      operador: '',
      parametro: {
        parametro: '',
        tipoParametro: TIPO_PARAMETRO.criterio
      }
    }
  ]
}

export function formAssociacaoIO({ formularioEdicao, regraAssociacaoEditar }: FormAssociacaoProps) {
  const dispatch = useDispatch()
  const router = useHistory()
  const { parceiroId, regraId } = useParams<{ parceiroId: string; regraId: string }>()
  const [listaCondicoes, setlistaCondicoes] = useState<Condicao[]>([])
  const [tipoLancamentoLista, setTipoLancamentoLista] = useState<TiposLancamentosData[]>([])
  const [abrirModal, setAbrirModal] = useState(false)
  const [preVisualizar, setpreVisualizar] = useState<PreviewItemAssociacao>()
  const [salvar, setSalvar] = useState(false)
  const [checkedAtivo, setCheckedAtivo] = useState(regraAssociacaoEditar ? regraAssociacaoEditar.ativo : true)
  const { handleSubmit, control, getValues, reset } = useForm<RegraAssociacaoForm>({
    defaultValues: formularioEdicao ? regraAssociacaoEditar : defaultValues
  })

  const condicoes = useFieldArray({
    name: 'condicoes',
    control
  })

  const acoes = useFieldArray({
    name: 'acoes',
    control
  })

  const condicaoSelecionada = useWatch({
    name: 'condicoes',
    control
  })

  useEffect(() => {
    async function buscarCondicoes() {
      const response: Condicao[] = await AssociacaoManager.buscarCondicoes()
      setlistaCondicoes(response)
    }

    async function buscarTiposLancamento() {
      const tiposLancamentos: TiposLancamentosData[] = await tipoLancamentoManager.buscarTiposLancamentos()
      setTipoLancamentoLista(tiposLancamentos.filter((tipoLancamento: TiposLancamentosData) => tipoLancamento.ativo === true))
    }

    buscarCondicoes()
    buscarTiposLancamento()
  }, [])

  const adicionarNovaAssociacao = (data: RegraAssociacaoForm) => {
    dispatch(loadingUpdate(true))

    const nomeRegra = gerarNomeRegraAssociacao(data, tipoLancamentoLista)
    data = { ...data, nomeRegra: nomeRegra }

    AssociacaoManager.cadastrarRegra(data, parceiroId).then(() => {
      toast.success(msgConstants.USO_GENERICO.cadastro_sucesso)
      dispatch(loadingReset())
      salvar ? reset() : router.push(PAGES.parametrosRepasse(parceiroId, ABAS_DETALHES_PARCEIROS.associar))
    })
    dispatch(loadingReset())
  }

  const handleFecharModal = () => {
    setAbrirModal(false)
  }

  const handlePreVisualize = async () => {
    const regraForm: RegraAssociacaoForm = getValues()
    dispatch(loadingUpdate(true))
    regraForm?.condicoes.map((item) => (item.tipoCondicao = 'E'))
    await AssociacaoManager.previewRegra(regraForm, parceiroId)
      .then((response: PreviewItemAssociacao) => {
        setpreVisualizar(response)
        setAbrirModal(true)
      })
      .catch(() => {
        toast.error(msgConstants.ERRO_PRE_VISUALIZACAO)
      })
      .finally(() => {
        dispatch(loadingReset())
      })
  }

  const editarNovaAssociacao = (data: RegraAssociacaoForm) => {
    dispatch(loadingUpdate(true))
    data.ativo = checkedAtivo

    const nomeRegra = gerarNomeRegraAssociacao(data, tipoLancamentoLista)
    data = { ...data, nomeRegra: nomeRegra }

    AssociacaoManager.editarRegra(data, parceiroId, regraId)
      .then(() => {
        toast.success(msgConstants.USO_GENERICO.edicao_sucesso)
        router.push(PAGES.parametrosRepasse(parceiroId, ABAS_DETALHES_PARCEIROS.associar))
      })
      .catch(() => {
        toast.error(msgConstants.USO_GENERICO.edicao_erro)
      })
      .finally(() => dispatch(loadingReset()))
  }

  const handleCheckedAtivo = (event: any) => {
    setCheckedAtivo(event.target.checked)
  }

  const cancelarNovaAssociacao = () => {
    router.push(PAGES.parametrosRepasse(parceiroId, ABAS_DETALHES_PARCEIROS.associar))
  }

  return {
    styles: formNovaAssociacaoStyles,
    listaCondicoes,
    control,
    handleSubmit,
    submeterNovaAssociacao: (data: RegraAssociacaoForm) => {
      formularioEdicao ? editarNovaAssociacao(data) : adicionarNovaAssociacao(data)
    },
    condicoes,
    dadosCondicoes: ['Valor', 'TipoLancamento', 'Loja', 'Lancamento', 'IdentificadorPedido', 'Parcela'],
    condicaoSelecionada,
    acoes,
    tipoLancamentoLista,
    preVisualizar,
    abrirModal,
    handleFecharModal,
    handlePreVisualize,
    cancelarNovaAssociacao,
    setSalvar,
    handleCheckedAtivo,
    checkedAtivo,
    adicionarMaisCondicao: () =>
      condicoes.append({
        operacaoId: '',
        dado: '',
        operador: '',
        parametro: {
          parametro: '',
          tipoParametro: TIPO_PARAMETRO.criterio
        },
        tipoCondicao: 'E'
      })
  }
}

export type FormAssociacaoIO = ReturnType<typeof formAssociacaoIO>
