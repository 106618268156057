import { useForm } from 'react-hook-form'
import { modalFiltroParceiroStyles } from './ModalFiltroParceiro.style'
import { ModalFiltroParceiroProps } from './ModalFiltroParceiro.View'

export function modalFiltroParceiroIO({ isClosed, handleClickParceiro, setParceiroTransportadoras, parceiros }: ModalFiltroParceiroProps) {
  const { handleSubmit, control, setValue } = useForm<{ parceiroId: number }>({
    defaultValues: {
      parceiroId: 0
    }
  })

  const fecharModal = () => {
    isClosed()
    setValue('parceiroId', 0)
  }

  const handleConfirmar = (dadosForm: { parceiroId: number }) => {
    isClosed()
    handleClickParceiro(dadosForm.parceiroId)

    if (setParceiroTransportadoras != undefined) {
      setParceiroTransportadoras(obterTransportadoraParceiroSelecionado(dadosForm.parceiroId))
    }
  }

  const obterTransportadoraParceiroSelecionado = (parceiroId: number) => {
    const parceiroFiltrado = parceiros.find((parceiro) => parceiro.parceiroId === parceiroId)

    return parceiroFiltrado ? parceiroFiltrado.transportadoras : []
  }

  return {
    styles: modalFiltroParceiroStyles,
    handleSubmit,
    control,
    fecharModal,
    handleConfirmar
  }
}

export type ModalFiltroParceiroIO = ReturnType<typeof modalFiltroParceiroIO>
