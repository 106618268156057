import { Grid } from '@material-ui/core'
import styled from 'styled-components'

export const graficoValorQuantidadeMultiploStyles = {
  GridWidthLimitada: styled(Grid)`
    max-width: 475px;
  `,
  GridHeightLimitada: styled(Grid)`
    min-height: 250px;
    max-height: 450px;
  `,
  GridContainer: styled(Grid).attrs((width: { width?: string }) => width)`
    width: ${(props) => props.width};
  `
}
