import styled from 'styled-components'
import { Typography } from '@material-ui/core'

export const parceirosStyles = {
  TableContainer: styled.div`
    width: 100%;
    height: 100%;
  `,
  Titulo: styled(Typography)`
    margin-top: 24px;
    margin-bottom: 16px;
  `
}
