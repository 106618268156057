import { useParams } from 'react-router-dom'
import { parametrosSelecionarStyles } from './ParametrosSelecionar.styles'

export function parametrosSelecionarIO() {
  const { parceiroId } = useParams<{ parceiroId: string }>()

  return {
    styles: parametrosSelecionarStyles,
    parceiroId
  }
}

export type ParametrosSelecionarIO = ReturnType<typeof parametrosSelecionarIO>
