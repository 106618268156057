import styled from 'styled-components'
import { ContainerSombra } from '../../../../components/ContainerSombra'

export const titulosAbertosStyles = {
  ContainerCabecalho: styled(ContainerSombra)`
    margin-bottom: 7px;
    padding: 9px;
  `,
  Container: styled(ContainerSombra)`
    margin-top: 20px;
    padding: 0 8px;
  `
}
