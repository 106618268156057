import { Grid, Typography } from '@material-ui/core'
import { IOProps } from 'react-compose-io'
import { ContainerAvatar } from '../../components/ContainerAvatar'
import { GridTypography } from '../../components/GridTypography'
import { formateCurrency, validarTexto } from '../../utils/stringUtils'
import { FormularioPedido } from './components/FormularioPedido'
import { TabelaMovimentoContabil } from './components/TabelaMovimentoContabil'
import { TabelaInternalizacao } from './components/TabelaInternalizacao'
import { PedidosIO } from './Pedidos.IO'
import { StatusItemLancamento } from '../../types/pedidosTypes'
import { corStatusLancamento } from './Pedidos.style'
import { Legenda } from '../../components/Legenda/Legenda'
import { Tipografia } from './../../components/Tipografia/index'
import { customTheme } from './../../styles/customTheme'

export type PedidosProps = {}

export function PedidosView({ _io }: IOProps<PedidosIO, PedidosProps>) {
  const {
    Container,
    TypographySubTitle,
    ContainerLegenda,
    TituloLegenda,
    SubtituloPedido,
    GridContainer,
    TituloMovimentoContabil,
    TituloInternalizacao
  } = _io.styles

  return (
    <>
      <Tipografia variant="h5">Movimentações do pedido</Tipografia>
      <Container>
        <TypographySubTitle variant="h6">Pesquisar pedido</TypographySubTitle>
        <FormularioPedido
          control={_io.control}
          handleSubmit={_io.handleSubmit(_io.buscarPedido)}
          limparFormulario={_io.limparFormulario}
          sequencias={_io.sequencias}
          tiposLancamentosEFacil={_io.tiposLancamentosEFacil}
        />
      </Container>
      {(_io.pedidoInternalizado || _io.pedidoMovimentoContabil) && (
        <>
          <Container>
            <Tipografia variant="h6">Resultado</Tipografia>
            {_io.pedidoInternalizado && (
              <SubtituloPedido fontSize={customTheme.fontSize[18]} fontWeight="500" colorText={customTheme.colors.grey23}>
                {`Pedido ${_io.pedidoInternalizado.pedido}`}
              </SubtituloPedido>
            )}
            <GridContainer justifyContent="space-between" spacing={1}>
              <Grid item xs={8} sm={8} md={9} lg={9}>
                <Grid container direction="row" spacing={2} justifyContent="flex-start">
                  <Grid item xs={2} sm={2} md={2} lg={2}>
                    <Typography variant="subtitle1" color="textSecondary">
                      Parceiro
                    </Typography>
                    <div>
                      {_io.pedidoInternalizado && (
                        <ContainerAvatar
                          avatarDescricao={_io.pedidoInternalizado.parceiroResumo?.parceiroNome}
                          avatarSrc={_io.pedidoInternalizado.parceiroResumo?.logoParceiroUrl}
                          tamanhoImagem={30}
                        />
                      )}
                    </div>
                  </Grid>
                  {_io.pedidoInternalizado && (
                    <>
                      <GridTypography titulo={'Cliente'} valor={validarTexto(_io.pedidoInternalizado.clienteId, '- -')} />
                      <GridTypography titulo={'Valor do pedido'} valor={formateCurrency(_io.pedidoInternalizado.valor)} />
                      <GridTypography titulo={'Valor do comissão'} valor={formateCurrency(_io.pedidoInternalizado.comissao)} />
                      <GridTypography titulo={'Parcelas'} valor={`${_io.pedidoInternalizado.parcelas} parcelas`} />
                      <GridTypography titulo={'Parcelas Pagas'} valor={`${_io.pedidoInternalizado.parcelasPagas} parcelas`} />
                    </>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={4} sm={4} md={3} lg={3}>
                <ContainerLegenda>
                  <TituloLegenda>Legenda</TituloLegenda>
                  <Legenda descricao="Pedido com regularização automática" cor={corStatusLancamento(StatusItemLancamento.REGULARIZACAO_AUTOMATICA)} />
                  <Legenda descricao="Pedido com regularização manual" cor={corStatusLancamento(StatusItemLancamento.REGULARIZACAO_MANUAL)} />
                  <Legenda descricao="Pedido que gerou OP" cor={corStatusLancamento(StatusItemLancamento.OP)} />
                  <Legenda descricao="Pedido anulado" cor={corStatusLancamento(StatusItemLancamento.ANULADO)} />
                </ContainerLegenda>
              </Grid>
            </GridContainer>

            <TituloMovimentoContabil fontSize={customTheme.fontSize[14]} fontWeight="500">
              Movimentações Contábeis
            </TituloMovimentoContabil>
            <TabelaMovimentoContabil pedidoInternalizado={_io.pedidoMovimentoContabil} />
            <TituloInternalizacao fontSize={customTheme.fontSize[14]} fontWeight="500">
              Internalização
            </TituloInternalizacao>

            <TabelaInternalizacao pedidoInternalizado={_io.pedidoInternalizado} />
          </Container>
        </>
      )}
    </>
  )
}
