import { Chip } from '@material-ui/core'
import styled from 'styled-components'
import { Tipografia } from '../../../../components/Tipografia'
import { customTheme } from '../../../../styles/customTheme'

export const itemStatusStyles = {
  TextoTabela: styled(Tipografia)`
    margin-right: 26px;
  `,
  ChipStatus: styled(Chip)`
    background: ${(props: { background: string; colorfont: string }) => props.background};
    color: ${(props: { background: string; colorfont: string }) => props.colorfont};
    border-radius: 24px;
    margin: 2px;
    font-weight: 400;
    font-size: ${customTheme.fontSize[12]};
    min-width: 126px;
    height: 23px;
  `,
  ContainerChips: styled.div`
    justify-content: center;
  `
}
