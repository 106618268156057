import { IOProps } from 'react-compose-io'
import { UploadPlanilhaCicloPedidoIO } from './UploadPlanilhaCicloPedido.IO'
import { Tipografia } from './../../../../components/Tipografia/index'
import { customTheme } from './../../../../styles/customTheme'
import { Grid } from '@material-ui/core'
import { validarTexto } from '../../../../utils/stringUtils'
import Dropzone from 'react-dropzone'
import { Dispatch, SetStateAction } from 'react'
import { Botao } from '../../../../components/Botao'

export type UploadPlanilhaCicloPedidoProps = {
  dataUpload?: string
  usuarioUltimoUpload?: string
  parceiroSelecionado: number
  atualizarDivergencias: () => Promise<void>
  setCarregando: Dispatch<SetStateAction<boolean>>
}

export function UploadPlanilhaCicloPedidoView({ _io, usuarioUltimoUpload }: IOProps<UploadPlanilhaCicloPedidoIO, UploadPlanilhaCicloPedidoProps>) {
  const {
    BoxUploadPlanilha,
    UploadMessage,
    GridUploadInfo,
    DropContainer,
    DropSuccess,
    SpanSize,
    SpanTitle,
    GridUploadInfoIndicador,
    UploadButton
  } = _io.styles

  return (
    <>
      <BoxUploadPlanilha>
        <Tipografia colorText={customTheme.colors.grey23} fontSize={customTheme.fontSize[18]} fontWeight="500">
          Upload de nova planilha
        </Tipografia>
        <Dropzone
          maxFiles={1}
          accept={[
            '.csv',
            'text/csv',
            'application/vnd.ms-excel',
            'application/csv',
            'text/x-csv',
            'application/x-csv',
            'text/comma-separated-values',
            'text/x-comma-separated-values'
          ]}
          onDropAccepted={_io.onArquivoAceito}
        >
          {({ getRootProps, getInputProps }) => (
            <DropContainer {...getRootProps()}>
              {!_io.dropVisivel ? (
                <>
                  <input placeholder="Upload" {...getInputProps()} />
                  <>
                    <UploadMessage>Arraste a planilha para cá</UploadMessage>
                    <UploadMessage>ou</UploadMessage>
                    <UploadMessage>
                      <UploadButton>clique aqui para selecionar de seu computador</UploadButton>
                    </UploadMessage>
                  </>
                </>
              ) : (
                <DropSuccess>
                  <Grid container spacing={10}>
                    <GridUploadInfo item xs={6} sm={6}>
                      <SpanTitle>Nome do arquivo: {validarTexto(_io.nomeArquivo, 'não foi possível identificar')}</SpanTitle>
                      <SpanSize>Tamanho do arquivo: {validarTexto(_io.tamanhoArquivo + ' bytes', 'não foi possível identificar')}</SpanSize>
                    </GridUploadInfo>
                    <GridUploadInfoIndicador item xs={5} sm={5}>
                      {_io.uploadSucesso && <SpanTitle>Carregamento completo</SpanTitle>}
                    </GridUploadInfoIndicador>
                  </Grid>
                </DropSuccess>
              )}
            </DropContainer>
          )}
        </Dropzone>
        <Grid container direction="row">
          <Tipografia fontWeight="500">Último upload: &nbsp;</Tipografia>
          <Tipografia>{`${_io.dataUpload} - ${validarTexto(usuarioUltimoUpload, 'usuário não encontrado')}.`}</Tipografia>
        </Grid>
        <Grid container direction="row" alignItems="center" justifyContent="flex-end" spacing={2}>
          <Grid item>
            <Botao disabled={!_io.uploadSucesso} onClick={_io.handleCancelar} textoBotao="Cancelar" estilo="text" cor="primaria" />
          </Grid>
          <Grid item>
            <Botao disabled={!_io.uploadSucesso} onClick={_io.handleConfirmar} textoBotao="Confirmar e atualizar" estilo="contained" cor="primaria" />
          </Grid>
        </Grid>
      </BoxUploadPlanilha>
    </>
  )
}
