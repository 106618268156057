export const customTheme = {
  colors: {
    black: '#000000',
    black02: '#0000003f',
    primary: '#2F89FC',
    secondary: '#9FAAEF',
    blue00: '#e0e8f0',
    blue01: '#E9F6FF',
    blue02: '#40a9ff',
    blue03: '#1890ff',
    blue04: '#00ACEC',
    blue05: '#10A4E6',
    blue06: '#2F88FC',
    blue07: '#18498E',
    blue08: '#0189FF',
    blue09: '#E3F2FF',
    blue10: '#06EAC3',
    blue11: '#6458FC',
    blue12: '#8AABF3',
    blue13: '#2F63D5',
    blue14: '#000F31',
    blue15: '#e0f7fa',
    blue16: '#1D40EE',
    white01: '#FFFFFF',
    white02: '#FEFFD6',
    white03: '#e8e8e8',
    white04: '#E5E5E5',
    white05: '#F9F9F9',
    white06: '#f1f0ef',
    white07: '#FFF2EA',
    grey00: '#CCC',
    grey01: '#F0F0F0',
    grey02: '#CDCDCD',
    grey03: '#85838A',
    grey04: '#4E4B59',
    grey05: '#6D6E75',
    grey06: '#45464b',
    grey07: '#47484D',
    grey08: '#404040',
    grey09: '#33303E',
    grey10: '#2B2C32',
    grey11: '#6D7384',
    grey12: '#A7ADBF',
    grey13: '#D9D9D9',
    grey14: '#969696',
    grey15: '#A2ADA7',
    grey16: '#212121',
    grey17: '#f5f5f5bf',
    grey18: '#f5f5f5',
    grey19: '#3F3F3F',
    grey20: '#C6DFFF0F',
    grey21: '#616161',
    grey22: '#BDBDBD',
    grey23: '#3A4050',
    grey24: '#19202D',
    grey25: '#595F70',
    green01: '#E4F5EA',
    green03: '#C6F5E2',
    green02: '#007732',
    green04: '#54AC5B',
    green05: '#04C500',
    green06: '#3EB96F',
    green07: '#4FF0D5',
    green08: '#78e5d5',
    green09: '#7BB51C',
    green10: '#33691E',
    orange01: '#FE8C23',
    orange02: '#FF9C13',
    orange03: '#FF6D00',
    orange04: '#FF5D0B',
    yellow01: '#FFC700',
    yellow02: '#FAE728',
    red00: '#FAD7DE',
    red03: '#D50000',
    red04: '#e57878',
    red05: '#DA2D27',
    red06: '#FF0000',
    pink: '#FFC0CB',
    //paletaGiovana
    amarelo01: '#FFF7DE',
    amarelo02: '#FFEAAD',
    amarelo03: '#FFDD77',
    amarelo04: '#FFD03A',
    amarelo05: '#FFC500',
    amarelo06: '#FFBB00',
    amarelo07: '#F9A900',
    amarelo08: '#A16207',
    azul01: '#EAF3FF',
    azul02: '#B3D3FF',
    azul03: '#80B7FF',
    azul04: '#3289FF',
    azul05: '#006CFF',
    azul06: '#1D40EE',
    azul07: '#072AD8',
    azul08: '#041AC2',
    azul09: '#0314B2',
    azul10: '#020BA3',
    cinza01: '#F9F9F9',
    cinza02: '#EDEEF0',
    cinza03: '#DDDDDD',
    cinza04: '#999DA9',
    cinza05: '#838896',
    cinza06: '#6D7384',
    cinza07: '#595F70',
    cinza08: '#3A4050',
    cinza09: '#19202D',
    cinza10: '#10151D',
    verde01: '#E5F8EC',
    verde02: '#C1EDCF',
    verde03: '#68D790',
    verde04: '#3ACD77',
    verde05: '#00B354',
    verde06: '#008F3B',
    verde07: '#006F27',
    vermelho01: '#FFEBEE',
    vermelho02: '#FFCDD2',
    vermelho03: '#F09A99',
    vermelho04: '#E73933',
    vermelho05: '#C82826',
    vermelho06: '#B91B1A',
    vermelho07: '#770000',
    laranja01: '#FFF4EE',
    laranja02: '#FDC8AA',
    laranja03: '#FF7B25',
    laranja04: '#FF8E54',
    laranja05: '#FF7530',
    laranja06: '#FF5D0B',
    laranja07: '#E75308',
    laranja08: '#C93D02',
    laranja09: '#B63700',
    laranja10: '#AA2D01',
    roxo01: '#FFF4FC',
    roxo02: '#B7008F',
    viavarejo: '#6458FC',
    magalu: '#0189FF',
    b2w: '#06EAC3',
    leroy: '#7BB51C',
    mercadolivre: '#FAE728',
    amazon: '#FF9C13',
    carrefour: '#DA2D27',
    neutral: {
      white: '#fff',
      light: {
        300: '#9FAAEF',
        500: '#536DE4'
      },
      dark: '#173EC5',
      soft: '',
      medium: ''
    },
    suport: {
      success: '#DCFEDC',
      warning: '#FEFFD6',
      error: '#E73A33',
      successLight: '',
      warningLight: '',
      errorLight: ''
    }
  },
  borderRadius: '',
  fontTokens: {},
  fontWeight: {
    light: 0,
    regular: 0,
    medium: 0,
    bold: 0,
    black: 0
  },
  name: '',
  fontFamily: {
    rubik400: '../assets/fonts/Rubik-VariableFont_400.ttf',
    rubik500: '../assets/fonts/Rubik-VariableFont_500.ttf'
  },
  fontSize: {
    8: '0.5rem',
    10: '0.625rem',
    11: '0.6875rem',
    12: '0.75rem',
    13: '0.8125rem',
    14: '0.875rem',
    15: '0.938rem',
    16: '1rem',
    18: '1.125rem',
    20: '1.25rem',
    22: '1.375rem',
    24: '1.5rem',
    30: '1.875rem',
    32: '2rem',
    34: '2.125rem',
    40: '2.5rem'
  }
}

export const corAleatoria = new Map<number, string>([
  [0, `${customTheme.colors.yellow01}`],
  [1, `${customTheme.colors.blue01}`],
  [2, `${customTheme.colors.orange01}`],
  [3, `${customTheme.colors.green02}`],
  [4, `${customTheme.colors.orange02}`],
  [5, `${customTheme.colors.blue08}`],
  [6, `${customTheme.colors.grey03}`],
  [7, `${customTheme.colors.pink}`],
  [8, `${customTheme.colors.red05}`]
])

export const corParceiroId = new Map<number, string>([
  [0, `transparent`],
  [2, `${customTheme.colors.blue11}`],
  [10, `${customTheme.colors.yellow02}`],
  [13, `${customTheme.colors.blue10}`],
  [15, `${customTheme.colors.orange02}`],
  [16, `${customTheme.colors.blue08}`],
  [19, `${customTheme.colors.red05}`],
  [29, `${customTheme.colors.green09}`]
])

export const corMes = new Map<string, string>([
  ['Janeiro', `${customTheme.colors.azul09}`],
  ['Fevereiro', `${customTheme.colors.azul04}`],
  ['Março', `${customTheme.colors.laranja07}`],
  ['Abril', `${customTheme.colors.verde07}`],
  ['Maio', `${customTheme.colors.amarelo06}`],
  ['Junho', `${customTheme.colors.verde06}`],
  ['Julho', `${customTheme.colors.azul06}`],
  ['Agosto', `${customTheme.colors.verde05}`],
  ['Setembro', `${customTheme.colors.vermelho05}`],
  ['Outubro', `${customTheme.colors.roxo02}`],
  ['Novembro', `${customTheme.colors.amarelo04}`],
  ['Dezembro', `${customTheme.colors.laranja10}`]
])

export const corLetraChip = new Map<string, string>([
  ['Abertura de Chamado', `${customTheme.colors.verde07}`],
  ['Análise', `${customTheme.colors.laranja09}`],
  ['CI', `${customTheme.colors.roxo02}`],
  ['Perda', `${customTheme.colors.vermelho07}`],
  ['Abertura de ocorrência', `${customTheme.colors.amarelo08}`],
  ['Aguardando chamado', `${customTheme.colors.azul10}`],
  ['Em devolução', `${customTheme.colors.cinza10}`],
  ['Acareação', `${customTheme.colors.vermelho04}`],
  ['Indenização', `${customTheme.colors.amarelo07}`],
  ['Aguardando aprovacao da classificacao', `${customTheme.colors.azul04}`],
  ['Aguardando pagamento', `${customTheme.colors.verde06}`],
  ['Recuperado', `${customTheme.colors.verde03}`]
])

export const corChip = new Map<string, string>([
  ['Abertura de Chamado', `${customTheme.colors.verde01}`],
  ['Análise', `${customTheme.colors.laranja01}`],
  ['CI', `${customTheme.colors.roxo01}`],
  ['Perda', `${customTheme.colors.vermelho01}`],
  ['Abertura de ocorrência', `${customTheme.colors.amarelo01}`],
  ['Aguardando chamado', `${customTheme.colors.azul01}`],
  ['Em devolução', `${customTheme.colors.cinza02}`],
  ['Acareação', `${customTheme.colors.vermelho01}`],
  ['Indenização', `${customTheme.colors.cinza01}`]
])

export const corClassificacaoChip = new Map<string, string>([
  ['Pendente', `${customTheme.colors.cinza01}`],
  ['Em Andamento', `${customTheme.colors.laranja01}`],
  ['Concluido', `${customTheme.colors.verde01}`]
])

export const corClassificacaoLetra = new Map<string, string>([
  ['Pendente', `${customTheme.colors.cinza10}`],
  ['Em Andamento', `${customTheme.colors.laranja09}`],
  ['Concluido', `${customTheme.colors.verde07}`]
])
