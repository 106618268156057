import { LinearProgress, Box } from '@material-ui/core'
import styled from 'styled-components'
import { customTheme } from '../../styles/customTheme'

export const statusProgressStyles = {
  CustomLinearProgress: styled(({ ...otherProps }) => <LinearProgress {...otherProps} />)`
    && {
      .MuiLinearProgress-barColorPrimary {
        background-color: ${(props) => {
          if (props.etapastate === 'WARNING') {
            return customTheme.colors.yellow01
          } else if (props.etapastate === 'SUCCESS') {
            return customTheme.colors.green04
          } else {
            return customTheme.colors.red06
          }
        }};
      }
    }

    background-color: ${customTheme.colors.grey00};
  `,
  Container: styled.div`
    width: 100%;
  `,
  BoxContainer: styled(Box)`
    display: flex;
    align-items: center;
  `,
  BoxMinWidth: styled(Box)`
    min-width: 35px;
  `,
  BoxLine: styled(Box)`
    width: 100%;
    margin-right: 8px;
  `
}
