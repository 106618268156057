import { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import ControleComissaoManager from '../../services/api/controleComissao/controleComissaoManager'
import parceiroManager from '../../services/api/parceiro/parceiroManager'
import { loadingReset, loadingUpdate } from '../../store/actions/loadingAction'
import { customTheme } from '../../styles/customTheme'
import { ControleComissaoPaginado, ControleComissaoStatusResumo, ControleComissaoStatus } from '../../types/controleComissaoTypes'
import { Parceiro } from '../../types/parceiroApiTypes'
import { Paginacao } from '../../types/tabelaTypes'
import { controleComissaoStyles } from './ControleComissao.styles'

export const corFundoStatus = (status: number) => {
  switch (status) {
    case ControleComissaoStatus.Pendente:
      return customTheme.colors.vermelho01
    case ControleComissaoStatus.EmAndamento:
      return customTheme.colors.amarelo01
    case ControleComissaoStatus.Concluido:
      return customTheme.colors.verde01
    default:
      return customTheme.colors.amarelo01
  }
}

export const corTextoStatus = (status: number) => {
  switch (status) {
    case ControleComissaoStatus.Pendente:
      return customTheme.colors.vermelho06
    case ControleComissaoStatus.EmAndamento:
      return customTheme.colors.amarelo07
    case ControleComissaoStatus.Concluido:
      return customTheme.colors.verde07
    default:
      return customTheme.colors.amarelo07
  }
}

export function controleComissaoIO() {
  const [controleComissaoResumosPaginado, setControleComissaoResumosPaginado] = useState<ControleComissaoPaginado>({
    itens: [],
    pageNumber: 1,
    pageSize: 20,
    totalItens: 0,
    totalPages: 0
  })
  const [controleComissaoStatusResumo, setControleComissaoStatusResumo] = useState<ControleComissaoStatusResumo[]>([])
  const [parceirosAtivos, setParceirosAtivos] = useState<Parceiro[]>([])
  const [filtroStatusLista, setFiltroStatusLista] = useState<number[]>([])
  const [filtroParceirosLista, setFiltroParceirosLista] = useState<number[]>([])
  const dispatch = useDispatch()

  const { handleSubmit, control, setValue } = useForm<{ pesquisa: string }>({
    defaultValues: {
      pesquisa: ''
    }
  })

  const buscarControleComissao = useCallback(
    async (paginacao: Paginacao, search?: string) => {
      dispatch(loadingUpdate(true))

      await ControleComissaoManager.buscarControleComissaoResumo({
        pageNumber: paginacao.pageNumber,
        pageSize: paginacao.pageSize,
        orderBy: paginacao.orderBy,
        orderField: paginacao.orderField,
        listaStatus: filtroStatusLista,
        parceirosIds: filtroParceirosLista,
        search: search
      }).then((controleComissaoResponse: ControleComissaoPaginado) => {
        setControleComissaoResumosPaginado(controleComissaoResponse)
        dispatch(loadingReset())
      })
    },
    [dispatch, filtroStatusLista, filtroParceirosLista]
  )

  const buscarControleComissaoStatusResumo = useCallback(async () => {
    await ControleComissaoManager.buscarControleComissaoStatusResumo().then((controleComissaoStatusResumoResponse: ControleComissaoStatusResumo[]) => {
      setControleComissaoStatusResumo(controleComissaoStatusResumoResponse)
    })
  }, [])

  const buscarParceirosAtivos = useCallback(async () => {
    const parceirosResponse: Parceiro[] = await parceiroManager.buscarDadosParceiros(true)
    setParceirosAtivos(parceirosResponse)
  }, [])

  const getQuantidadeIncidentes = (status: number) => {
    const statusProcurado = controleComissaoStatusResumo.find((x) => x.status === status)

    if (statusProcurado !== undefined) return statusProcurado.quantidade

    return 0
  }

  const atualizarLista = () => {
    setControleComissaoStatusResumo([])
    setControleComissaoResumosPaginado({
      itens: [],
      pageNumber: 1,
      pageSize: 20,
      totalItens: 0,
      totalPages: 0
    })
    buscarControleComissao({ pageNumber: 1, pageSize: 25 })
    buscarControleComissaoStatusResumo()
  }

  const buscarValorPesquisado = (valorPesquisa: { pesquisa: string }) => {
    setControleComissaoResumosPaginado({
      itens: [],
      pageNumber: 1,
      pageSize: 20,
      totalItens: 0,
      totalPages: 0
    })
    buscarControleComissao({ pageNumber: 1, pageSize: 25 }, valorPesquisa.pesquisa)
    setValue('pesquisa', '')
  }

  const handleAplicarFiltro = () => {
    setControleComissaoResumosPaginado({
      itens: [],
      pageNumber: 1,
      pageSize: 20,
      totalItens: 0,
      totalPages: 0
    })
    buscarControleComissao({ pageNumber: 1, pageSize: 25 }, '')
  }

  useEffect(() => {
    buscarControleComissao({ pageNumber: 1, pageSize: 25 })
    buscarControleComissaoStatusResumo()
    buscarParceirosAtivos()
  }, [])

  return {
    styles: controleComissaoStyles,
    buscarControleComissao,
    controleComissaoResumosPaginado,
    atualizarLista,
    getQuantidadeIncidentes,
    buscarValorPesquisado,
    parceirosAtivos,
    handleAplicarFiltro,
    filtroStatusLista,
    filtroParceirosLista,
    setFiltroStatusLista,
    setFiltroParceirosLista,
    handleSubmit,
    control
  }
}

export type ControleComissaoIO = ReturnType<typeof controleComissaoIO>
