import { IOProps } from 'react-compose-io'
import { DetalheDebitoIO } from './DetalheDebito.IO'
import { Tipografia } from './../../components/Tipografia/index'
import { Controller } from 'react-hook-form'
import { Botao } from '../../components/Botao'
import { ModalClassificarPedidos } from '../../components/ModalClassificarPedidos'
import { CircularProgress, Grid, TextField } from '@material-ui/core'
import { PopoverFiltroParceiro } from './../../components/PopoverFiltroParceiro'
import { PopoverFiltroPedidoMkt } from './../../components/PopoverFiltroPedidoMkt'
import { PopoverFiltroPossiveisTratativas } from './../../components/PopoverFiltroPossiveisTratativas'
import { PopoverFiltroClassificacoes } from './../../components/PopoverFiltroClassificacoes'
import { TabelaDetalhesPerdas } from './../../components/TabelaDetalhesPerdas'
import { ModalRegularizarPerdasDebito } from './components/ModalRegularizarPerdasDebito/index'
import { customTheme } from '../../styles/customTheme'

export type DetalheDebitoProps = {}

export function DetalheDebitoView({ _io }: IOProps<DetalheDebitoIO, DetalheDebitoProps>) {
  const { Container, ContainerFormDataButton, TextFieldDataFim, ContainerFormulario } = _io.styles

  return (
    <>
      <Tipografia variant="h5" fontWeight="500">
        Débitos
      </Tipografia>
      <Container>
        <Grid container alignItems="center" spacing={2}>
          <Grid item>
            <img src={_io.getCabecalhoDesing().icone} />
          </Grid>
          <Grid item>
            <Tipografia fontWeight="500" variant="h6">
              {_io.getCabecalhoDesing().descricao}
            </Tipografia>
          </Grid>
        </Grid>

        <ContainerFormulario>
          <Grid container spacing={1} justifyContent="space-between" direction="row">
            <Grid item>
              <ContainerFormDataButton>
                <Controller
                  render={({ field }) => (
                    <TextField
                      {..._io.register('dataLancamentoInicio', { required: true })}
                      {...field}
                      label="Data de lançamento"
                      variant="outlined"
                      size="small"
                      type="date"
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  )}
                  name="dataLancamentoInicio"
                  control={_io.control}
                />
                <Tipografia>até</Tipografia>
                <Controller
                  render={({ field }) => (
                    <TextFieldDataFim
                      {..._io.register('dataLancamentoFim', { required: true })}
                      {...field}
                      label=""
                      variant="outlined"
                      size="small"
                      type="date"
                      error={_io.errors?.dataLancamentoFim ? true : false}
                      helperText={_io.errors.dataLancamentoFim?.message}
                    />
                  )}
                  name="dataLancamentoFim"
                  control={_io.control}
                />

                <Botao width="94px" textoBotao="Limpar" cor="primaria" estilo="text" onClick={_io.handleLimpar} />
                <Botao textoBotao="Filtrar" cor="primaria" estilo="outlined" onClick={_io.handleSubmit(_io.handleAplicar)} />
              </ContainerFormDataButton>
            </Grid>
            <Grid item>
              <Botao
                textoBotao="Classificar pedidos"
                estilo="contained"
                cor="primaria"
                onClick={_io.abrirModalClassificarPedido}
                disabled={_io.checklistsSelecionados.length == 0}
              />
            </Grid>
          </Grid>
        </ContainerFormulario>

        <Grid container direction="column">
          <Grid item>
            <Botao
              textoBotao="Contabilizar Débitos Perda"
              estilo="contained"
              cor="primaria"
              disabled={_io.isRegularizarDebitosDesabilitado}
              onClick={_io.handleAbrirModalRegularizarPerdasAberto}
              icone={_io.isRegularizarDebitosDesabilitado ? <CircularProgress color="inherit" size={12} /> : undefined}
            />
          </Grid>
          <Grid item>
            {_io.isRegularizarDebitosDesabilitado && (
              <Tipografia colorText={customTheme.colors.cinza04} fontSize={customTheme.fontSize[12]}>
                A regularização de débito perda está sendo executada, atualize a página para verificar se o processo finalizou.
              </Tipografia>
            )}
            {_io.detalhesPedidosDebitos?.processamentoDebitoPerda?.mensagemErro && (
              <>
                <Tipografia colorText={customTheme.colors.vermelho04} fontSize={customTheme.fontSize[13]}>
                  Ocorreu um erro durante a regularização de débito perda, tente rodar o processo novamente!
                </Tipografia>
                <Tipografia colorText={customTheme.colors.vermelho04} fontSize={customTheme.fontSize[11]}>
                  <strong>Detalhes do erro: </strong> {_io.detalhesPedidosDebitos?.processamentoDebitoPerda?.mensagemErro}
                </Tipografia>
              </>
            )}
          </Grid>
        </Grid>

        <TabelaDetalhesPerdas
          cabecalhoTabela={_io.cabecalhosTabela}
          checklistsSelecionados={_io.checklistsSelecionados}
          handleSelectAllCheckList={_io.handleCleanAllCheckList}
          quantidadeTotalDePerdas={_io.detalhesPedidosDebitos ? _io.detalhesPedidosDebitos.totalItens : 0}
          pagina={_io.pagina}
          linhasPorPagina={_io.linhasPorPagina}
          handleMudarPagina={_io.onMudancaPagina}
          handleMudarTamanhoPagina={_io.onMudancaLinhasPorPagina}
        >
          {_io.detalhesPedidosDebitos && _io.detalhesPedidosDebitos.itens.map(_io.renderLinhasTabela)}
        </TabelaDetalhesPerdas>
      </Container>

      <ModalClassificarPedidos
        handleConfirmar={_io.handleClassificarPedidos}
        isClosed={_io.fecharModal}
        isShow={_io.modalClassificarPedidosAberto}
        classificacoes={_io.classificacoesDebitos}
        classificacaoSugerida={_io.classificacaoSugerida}
      />

      <PopoverFiltroParceiro
        popoverAberto={_io.isFiltroParceiroAberto}
        handleClosePopover={_io.handleCloseFiltro}
        isParceiroChecked={_io.isParceiroChecked}
        handleCheckParceiro={_io.handleCheckFiltroParceiro}
      />

      <PopoverFiltroPedidoMkt popoverAberto={_io.isFiltroPedidoAberto} handleClosePopover={_io.handleCloseFiltro}>
        <Controller
          render={({ field }) => <TextField {...field} label="Buscar n° pedido marketplace" variant="outlined" size="small" fullWidth />}
          name="pedidoMkt"
          control={_io.control}
        />
      </PopoverFiltroPedidoMkt>

      <PopoverFiltroPossiveisTratativas
        popoverAberto={_io.isFiltroPossiveisTratativasAberto}
        handleClosePopover={_io.handleCloseFiltro}
        isPossivelTratativaChecked={_io.isPossivelTratativaChecked}
        handleCheckPossivelTratativa={_io.handleCheckFiltroPossivelTratativa}
        possiveisTratativas={_io.possiveisTratativas}
      />

      <PopoverFiltroClassificacoes
        popoverAberto={_io.isFiltroClassificacoesAberto}
        handleClosePopover={_io.handleCloseFiltro}
        classificacoes={_io.classificacoesDebitos.filter((classificacao) => classificacao.name !== 'Recuperado')}
        isClassificacaoChecked={_io.isClassificacaoChecked}
        handleCheckClassificacao={_io.handleCheckFiltroClassificacoes}
      />

      <ModalRegularizarPerdasDebito isClosed={_io.handleFecharModalRegularizarPerdasAberto} isShow={_io.isModalRegularizarPerdasAberto} />
    </>
  )
}
