import { Transportadora } from '../../../types/transportadoras'
import api from '../../axiosInstance'

const buscarTransportadoras = async (): Promise<Transportadora[]> => {
  const URL_BUSCAR_TRANSPORTADORAS = `Transportadores`
  const response = await api.conectarGet<Transportadora[]>(URL_BUSCAR_TRANSPORTADORAS)
  return response.data
}

export default {
  buscarTransportadoras
}
