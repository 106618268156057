import { IOProps } from 'react-compose-io'
import { ContainerAvatar } from '../../../../components/ContainerAvatar'
import { CardTitulosParceiro } from '../../../../types/titulosTypes'
import { ItemListaParceiros } from '../ItemParceiroLista'
import { ListaTitulosParceirosIO } from './ListaTitulosParceiros.IO'
import { Tipografia } from './../../../../components/Tipografia/index'

export type ListaTitulosParceirosProps = {
  listaParceiros: CardTitulosParceiro[]
  listaValorPesquisado?: CardTitulosParceiro[]
  valorPesquisado: boolean
  dataInicialFormulario: string
  dataFinalFormulario: string
  exportarRelatorioPeriodo: (data: string, parceiroId: number, dataFim?: string) => void
  exportarRelatorioComparacao?: (data: string, parceiroId: number) => void
  tituloCard: string
  titulosAbertos: boolean
}

export function ListaTitulosParceirosView({
  _io,
  listaParceiros,
  valorPesquisado,
  dataFinalFormulario,
  dataInicialFormulario,
  listaValorPesquisado,
  exportarRelatorioPeriodo,
  exportarRelatorioComparacao,
  tituloCard,
  titulosAbertos
}: IOProps<ListaTitulosParceirosIO, ListaTitulosParceirosProps>) {
  const { ContainerDivider, ContainerColuna, Container, ContainerLista } = _io.styles

  return (
    <Container>
      <Tipografia variant="h6">{tituloCard}</Tipografia>
      {listaParceiros &&
        listaParceiros.length > 0 &&
        listaParceiros.map((tituloParceiro, titulosParceiroIndex) => (
          <ContainerLista key={titulosParceiroIndex}>
            <ContainerDivider>
              <ContainerAvatar avatarDescricao={tituloParceiro.parceiroNome} avatarSrc={tituloParceiro.logoParceiroUrl || ''} />
            </ContainerDivider>
            <ContainerColuna>
              <ItemListaParceiros
                data={dataInicialFormulario}
                key={titulosParceiroIndex}
                tituloParceiro={tituloParceiro}
                exportarRelatorioParceiro={() => {
                  exportarRelatorioPeriodo(dataInicialFormulario, tituloParceiro.parceiroId, dataFinalFormulario)
                }}
                titulosAbertos={titulosAbertos}
              />
              {valorPesquisado && exportarRelatorioComparacao && listaValorPesquisado && (
                <ItemListaParceiros
                  data={dataFinalFormulario}
                  key={titulosParceiroIndex}
                  tituloParceiro={listaValorPesquisado[titulosParceiroIndex]}
                  exportarRelatorioParceiro={() => {
                    exportarRelatorioComparacao(dataFinalFormulario, listaValorPesquisado[titulosParceiroIndex].parceiroId)
                  }}
                  titulosAbertos={titulosAbertos}
                />
              )}
            </ContainerColuna>
          </ContainerLista>
        ))}
    </Container>
  )
}
