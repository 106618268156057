import { useFormContext, useWatch } from 'react-hook-form'
import { ItemRegraTransformacaoAcaoForm } from '../../../types/transformacaoApiTypes'
import { stylesFieldForms } from '../styles'

export function fieldsBuscarLinhaIO() {
  const { control } = useFormContext()

  const acaoSelecionada: ItemRegraTransformacaoAcaoForm[] = useWatch({
    name: 'acoes',
    control
  })

  return {
    styles: stylesFieldForms,
    control,
    acaoSelecionada
  }
}

export type FieldsBuscarLinhaIO = ReturnType<typeof fieldsBuscarLinhaIO>
