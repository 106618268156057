import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import UnfoldMoreRoundedIcon from '@material-ui/icons/UnfoldMoreRounded'
import React from 'react'
import { IOProps } from 'react-compose-io'
import { HeadCell } from '../../interfaces/interfaces'
import { Order } from '../../types/tabelaTypes'
import { EnhancedTableHeadIO } from './EnhancedTableHead.IO'

export type EnhancedTableProps = {
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof any) => void
  order: Order
  rowCount?: number
  headCells: HeadCell[]
  orderBy?: string | number | symbol
  alignCenter: boolean
  onSelectAllClick?: (event: React.ChangeEvent<HTMLInputElement>) => void
  isCheckbox?: boolean
  numSelected?: number
  colorCheckbox?: string
  corTextoCabecalho?: string
}

export default function EnhancedTableHeadView({
  _io,
  headCells,
  order,
  orderBy,
  isCheckbox,
  numSelected,
  onSelectAllClick,
  rowCount,
  colorCheckbox,
  alignCenter,
  corTextoCabecalho
}: IOProps<EnhancedTableHeadIO, EnhancedTableProps>) {
  const { TableHead, Checkbox, TableCellAlingned, TableSortLabel } = _io.styles

  return (
    <TableHead>
      <TableRow>
        {isCheckbox && (
          <>
            <TableCell padding="checkbox">
              <Checkbox
                indeterminate={Number(numSelected) === Number(rowCount)}
                onChange={onSelectAllClick}
                inputProps={{ 'aria-label': 'select all desserts' }}
                checked={Boolean(numSelected)}
                colorcheckbox={colorCheckbox}
              />
            </TableCell>
          </>
        )}
        {headCells.map((headCell, index) => (
          <TableCellAlingned aligncenter={alignCenter ? 1 : 0} key={index} sortDirection={orderBy === headCell.id ? order : false}>
            {headCell.id === null ? (
              headCell.label
            ) : (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={_io.ordenacaoPadrao(headCell)}
                onClick={_io.createSortHandler(headCell.id)}
                IconComponent={UnfoldMoreRoundedIcon}
                colortext={corTextoCabecalho}
              >
                {headCell.label}
              </TableSortLabel>
            )}
          </TableCellAlingned>
        ))}
      </TableRow>
    </TableHead>
  )
}
