import { Sequencias, TipoLancamento } from '../../types/tipoLancamentoApiTypes'

// *** USER ***
export const LANCAMENTO_UPDATE = 'LANCAMENTO_UPDATE'
export const MODAL_OPEN = 'MODAL_OPEN'
export const MODAL_DESATIVAR_OPEN = 'MODAL_DESATIVAR_OPEN'
export const LANCAMENTO_UPDATE_TRIGGER = 'LANCAMENTO_UPDATE_TRIGGER'
export const LANCAMENTO_UPDATE_DISABLE = 'LANCAMENTO_UPDATE_DISABLE'
export const DESATIVAR_LANCAMENTO = 'DESATIVAR_LANCAMENTO'
export const DESATIVAR_LANCAMENTO_EFETIVADO = 'DESATIVAR_LANCAMENTO_EFETIVADO'
export const UPDATE_SEQUENCIAS = 'UPDATE_SEQUENCIAS'

export const lancamentoUpdate = (lancamento: TipoLancamento) => ({
  type: LANCAMENTO_UPDATE,
  payload: lancamento
})

export const openModalEditar = () => ({
  type: MODAL_OPEN,
  payload: true
})

export const closeModalEditar = () => ({
  type: MODAL_OPEN,
  payload: false
})

export const openModalDesativar = () => ({
  type: MODAL_DESATIVAR_OPEN,
  payload: true
})

export const closeModalDesativar = () => ({
  type: MODAL_DESATIVAR_OPEN,
  payload: false
})

export const efetivarEdicao = () => ({
  type: LANCAMENTO_UPDATE_TRIGGER,
  payload: undefined
})

export const desativarEdicao = () => ({
  type: LANCAMENTO_UPDATE_DISABLE,
  payload: undefined
})

export const efetivarDesativacao = () => ({
  type: DESATIVAR_LANCAMENTO,
  payload: undefined
})

export const desativarDesativacao = () => ({
  type: DESATIVAR_LANCAMENTO_EFETIVADO,
  payload: undefined
})

export const atualizarSequencias = (sequencias: Sequencias[]) => ({
  type: UPDATE_SEQUENCIAS,
  payload: sequencias
})
