import styled from 'styled-components'
import { customTheme } from '../../styles/customTheme'
import { ContainerSombra } from '../ContainerSombra'

export const tabelaControleComissaoPedidoStyles = {
  ContainerTabela: styled(ContainerSombra)`
    padding: 20px 24px;
    margin-top: 16px;
  `,
  CelulaComFiltro: styled.div`
    display: flex;
    flex-wrap: nowrap;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;

    .MuiSvgIcon-colorPrimary {
      color: ${customTheme.colors.azul06};
    }
  `
}
