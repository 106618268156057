import styled from 'styled-components'
import { customTheme } from '../../styles/customTheme'

export const layoutStyles = {
  DivRoot: styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  `,
  Container: styled.div`
    display: flex;
    flex: 1;
  `,
  Main: styled.main`
    flex: 1;
    padding: 30px;
    background-color: ${customTheme.colors.grey01};
  `
}
