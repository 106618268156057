import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import transformacaoManager from '../../../../services/api/transformacao/transformacaoManager'
import { loadingReset, loadingUpdate } from '../../../../store/actions/loadingAction'
import { GrupoRegra } from '../../../../types/transformacaoApiTypes'
import { ItemGrupoRegra } from '../ItemGrupoRegra'
import { tabGrupoRegraStyles } from './TabGrupoRegra.styles'

export function tabGrupoRegraIO() {
  const { parceiroId } = useParams<{ parceiroId: string }>()
  const dispatch = useDispatch()
  const [gruposregrasList, setGruposRegrasList] = useState<GrupoRegra[]>([])
  const [modalAberto, setModalAberto] = useState(false)

  const atualizarDados = useCallback(async () => {
    dispatch(loadingUpdate(true))

    const response: GrupoRegra[] = await transformacaoManager.buscarGruposRegras(parceiroId)

    setGruposRegrasList(response)
    dispatch(loadingReset())
  }, [dispatch, parceiroId])

  useEffect(() => {
    atualizarDados()
  }, [atualizarDados])

  function fecharModal() {
    setModalAberto(false)
  }

  function abrirModal() {
    setModalAberto(true)
  }

  function linhaTabela(props: { item: GrupoRegra; index: number }) {
    const { item, index } = props

    return <ItemGrupoRegra row={item} index={index} atualizarDados={atualizarDados} parceiroId={parceiroId} />
  }

  return {
    styles: tabGrupoRegraStyles,
    gruposregrasList,
    setGruposRegrasList,
    modalAberto,
    fecharModal,
    abrirModal,
    atualizarDados,
    linhaTabela
  }
}

export type TabGrupoRegraIO = ReturnType<typeof tabGrupoRegraIO>
