import { Grid } from '@material-ui/core'
import { IOProps } from 'react-compose-io'
import { CardValorQuantidade } from '../../components/CardValorQuantidade'
import { Cabecalho } from './components/Cabecalho'
import { ItemListaRegularizacao } from './components/ItemListaRegularizacao/index'
import { RegularizacaoCiclosResumoIO } from './RegularizacaoCiclosResumo.IO'
import { Tipografia } from '../../components/Tipografia'

export type RegularizacaoCiclosResumoProps = {}

export function RegularizacaoCiclosResumoView({ _io }: IOProps<RegularizacaoCiclosResumoIO, RegularizacaoCiclosResumoProps>) {
  const { ContainerLista, ContainerBranco } = _io.styles

  return (
    <>
      <Cabecalho handleAplicar={_io.handleBuscarRegularizacaoCiclos} />
      <ContainerBranco>
        <Grid container spacing={2}>
          {_io.mapearValoresRegularizacao(_io.ciclosPendentes).map((valorCard, indexValorCard) => (
            <Grid item xs={4} key={indexValorCard}>
              <CardValorQuantidade
                tituloCard={valorCard.tituloCard}
                dataPeriodo={''}
                valorTotal={valorCard.valorTotal}
                quantidade={valorCard.quantidade}
                quantidadeTexto={valorCard.quantidadeTexto}
                valorPesquisado={false}
                status={0}
                somenteQuantidade={valorCard.somenteQuantidade}
              />
            </Grid>
          ))}
        </Grid>
      </ContainerBranco>
      {_io.ciclosPendentes.resumoParceiros && _io.ciclosPendentes.resumoParceiros.length > 0 && (
        <ContainerLista>
          <Tipografia variant="h6">Parceiros</Tipografia>
          {_io.ciclosPendentes.resumoParceiros.map((valorCiclo) => (
            <ContainerLista key={valorCiclo.parceiroResumo.parceiroId}>
              <ItemListaRegularizacao valorCiclo={valorCiclo} />
            </ContainerLista>
          ))}
        </ContainerLista>
      )}
    </>
  )
}
