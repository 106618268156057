import { TableCell } from '@material-ui/core'
import styled from 'styled-components'
import { customTheme } from '../../../../styles/customTheme'
import { StatusItemLancamento } from '../../../../types/pedidosTypes'
import { corStatusLancamento } from '../../Pedidos.style'

export const tabelaInternalizacaoStyles = {
  ContainerTable: styled.div`
    border-radius: 10px;
    background-color: ${customTheme.colors.white05};
  `,
  TableCell: styled(TableCell).attrs((props: { corletra: StatusItemLancamento }) => props)`
    color: ${(props) => corStatusLancamento(props.corletra)};
  `
}
