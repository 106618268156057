import { Box, CircularProgress, Grid, IconButton, TextField } from '@material-ui/core'
import { SearchOutlined } from '@material-ui/icons'
import { IOProps } from 'react-compose-io'
import { Controller, ControllerRenderProps } from 'react-hook-form'
import { CustomDialog } from '../../../../components/CustomDialog/CustomDialog'
import { TabelaResultadoOPS } from '../TabelaResultadosOPS/index'
import { ModalBuscarOPSIO } from './ModalBuscarOPS.IO'
import { Tipografia } from '../../../../components/Tipografia'
import { FormValuesBuscarOPs } from '../../../../types/iniciarNovoCicloTypes'

export type ModalBuscarOPSProps = {
  inserirOp: boolean
  fecharModal: () => void
  cicloId: string
  atualizarLista: () => void
  totalRecebidaCiclo: number | undefined
}

export function ModalBuscarOPSView({ _io, inserirOp, totalRecebidaCiclo }: IOProps<ModalBuscarOPSIO, ModalBuscarOPSProps>) {
  const { Form, ContainerDateInputs } = _io.styles

  function DateInput(field: ControllerRenderProps<FormValuesBuscarOPs, 'dataInicio'> | ControllerRenderProps<FormValuesBuscarOPs, 'dataFim'>) {
    return (
      <TextField
        type="date"
        variant="outlined"
        size="small"
        {...field}
        inputProps={{
          max: _io.dataAtualPadrao
        }}
      />
    )
  }

  return (
    <CustomDialog
      maxWidth="lg"
      fecharHabilitado
      fecharModal={_io.limparDadosModal}
      open={inserirOp}
      onClose={_io.limparDadosModal}
      id="buscarOP"
      titulo="Buscar OP"
    >
      <Box>
        <Form onSubmit={_io.handleSubmit(_io.buscarOps)}>
          <Grid container spacing={2} alignItems="center">
            <Grid item md={2}>
              <Controller
                name="ordemPagamentoId"
                control={_io.control}
                render={({ field }) => <TextField type="number" label="Número da OP" variant="outlined" size="small" {...field} />}
              />
            </Grid>
            <Grid item md={'auto'}>
              <ContainerDateInputs>
                <Controller name={'dataInicio'} control={_io.control} render={({ field }) => DateInput(field)} />
                <Tipografia>até</Tipografia>
                <Controller name={'dataFim'} control={_io.control} render={({ field }) => DateInput(field)} />
              </ContainerDateInputs>
            </Grid>

            <Grid item md={2}>
              <Controller
                name="emitente"
                control={_io.control}
                render={({ field }) => <TextField type="text" label="Emitente" variant="outlined" size="small" {...field} />}
              />
            </Grid>
            <Grid item md={1}>
              <Controller
                name="bancoId"
                control={_io.control}
                render={({ field }) => <TextField type="number" label="Banco" variant="outlined" size="small" {...field} />}
              />
            </Grid>
            <Grid item md={1}>
              <Controller
                name="valor"
                control={_io.control}
                render={({ field }) => <TextField type="number" label="Valor" variant="outlined" size="small" {...field} />}
              />
            </Grid>
            <Grid item md={1}>
              <IconButton color="primary" type="submit">
                <SearchOutlined />
              </IconButton>
            </Grid>
          </Grid>
        </Form>
      </Box>

      {!_io.loading ? (
        _io.resultado &&
        _io.resultado?.length > 0 && (
          <Box>
            <TabelaResultadoOPS
              data={_io.resultado}
              setFiltradas={_io.setFiltradas}
              handleAdicionarOps={_io.handleAdicionarOps}
              totalCiclo={totalRecebidaCiclo}
              handleAdicionarOpsContinuar={_io.handleAdicionarOpsContinuar}
            />
          </Box>
        )
      ) : (
        <Grid container justifyContent="center">
          <CircularProgress />
        </Grid>
      )}
    </CustomDialog>
  )
}
