import { Box, Grid, Typography } from '@material-ui/core'
import styled from 'styled-components'
import { ContainerSombra } from '../../components/ContainerSombra'
import { customTheme } from '../../styles/customTheme'
import { StatusItemLancamento } from '../../types/pedidosTypes'
import { Tipografia } from './../../components/Tipografia/index'

export const pedidosStyles = {
  Container: styled(ContainerSombra)`
    flex-grow: 1;
    padding: 16px;
    margin: 24px 0;
  `,
  TypographySubTitle: styled(Typography)`
    margin-bottom: 16px;
  `,
  ContainerLegenda: styled(Box)`
    background-color: ${customTheme.colors.white05};
    border-radius: 12px;
    padding: 8px;
    display: flex;
    align-items: flex-start;
    text-align: start;
    flex-direction: column;
  `,
  TituloLegenda: styled(Typography).attrs({
    variant: 'subtitle2'
  })`
    color: ${customTheme.colors.black};
  `,
  TextoLegenda: styled(Typography).attrs({
    variant: 'caption'
  })`
    color: ${(props: { corletra: StatusItemLancamento }) => corStatusLancamento(props.corletra)};
  `,
  CirculoLegenda: styled.div`
    border-radius: 100%;
    height: 15px;
    width: 15px;
    margin-right: 10px;
    background-color: ${(props: { corletra: StatusItemLancamento }) => corStatusLancamento(props.corletra)};
  `,
  SubtituloPedido: styled(Tipografia)`
    margin-top: '24px';
    margin-bottom: '24px';
  `,
  GridContainer: styled(Grid).attrs({
    container: true
  })`
    margin-top: 24px;
  `,
  TituloMovimentoContabil: styled(Tipografia)`
    margin-bottom: 16px;
  `,
  TituloInternalizacao: styled(Tipografia)`
    margin-bottom: 16px;
    margin-top: 24px;
  `
}

export function corStatusLancamento(corletra: StatusItemLancamento) {
  if (corletra == StatusItemLancamento.REGULARIZACAO_AUTOMATICA) {
    return customTheme.colors.laranja10
  }
  if (corletra == StatusItemLancamento.OP) {
    return customTheme.colors.azul07
  }
  if (corletra == StatusItemLancamento.ANULADO) {
    return customTheme.colors.cinza05
  }
  if (corletra == StatusItemLancamento.REGULARIZACAO_MANUAL) {
    return customTheme.colors.roxo02
  }
  return customTheme.colors.grey09
}
