import { customTheme } from '../../../../styles/customTheme'
import { resumoItemStyle } from './ResumoItem.style'

export function resumoItemIO() {
  const verifyStyle = (value: number) => {
    if (value > 0) return customTheme.colors.blue05
    else if (value < 0) return customTheme.colors.red06
    else return customTheme.colors.grey04
  }

  return {
    verifyStyle,
    styles: resumoItemStyle
  }
}
export type ResumoItemIO = ReturnType<typeof resumoItemIO>
