import { MESES_ABREVIADOS } from '../constants/utilConstants'
import { InadimplenciaParceiro } from '../types/controleInadimplenciaTypes'
import { DebitosPerdasEfetivados } from '../types/debitosTypes'

export type DadosMeses = {
  ano: number
  mes: number
  quantidade: number
  valor: number
}

const pegarUltimosMeses = (dadosMeses: DadosMeses[], mesesSelecionados: number, ignorarMesAtual = false) => {
  const date = new Date()

  if (ignorarMesAtual) {
    const currentMonth = date.getMonth()
    date.setMonth(currentMonth - 1)
  }

  const anoAtual = date.getFullYear()
  const mesAtual = date.getMonth()

  const valor = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0].splice(0, mesesSelecionados)
  const quantidade = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0].splice(0, mesesSelecionados)
  const nomeMeses = [[''], [''], [''], [''], [''], [''], [''], [''], [''], [''], [''], ['']].splice(0, mesesSelecionados)

  const dadosMesesOrdenado = [...dadosMeses].sort((a, b) => {
    if (a.ano !== b.ano) {
      return b.ano - a.ano
    }
    return b.mes - a.mes
  })

  for (let i = mesAtual; i > mesAtual - mesesSelecionados; i--) {
    const indexMes = i < 0 ? 12 + i : i

    let ano = anoAtual

    if (indexMes > mesAtual) {
      ano = anoAtual - 1
    }

    const dadosMes = dadosMesesOrdenado.find((data) => data.ano === ano && data.mes === indexMes + 1)

    if (dadosMes) {
      valor[mesAtual - i] = dadosMes.valor
      quantidade[mesAtual - i] = dadosMes.quantidade
    }

    nomeMeses[mesAtual - i] = [MESES_ABREVIADOS[indexMes]]
  }

  return {
    valor: [...valor].reverse(),
    quantidade: [...quantidade].reverse(),
    nomeMeses: [...nomeMeses].reverse()
  }
}

const pegarAnos = (dadosMeses: DadosMeses[]) => {
  const resumo = dadosMeses.reduce<Array<{ ano: number; quantidade: number; valor: number }>>((acc, curr) => {
    const { ano, quantidade, valor } = curr
    const index = acc.findIndex((item) => item.ano === ano)
    if (index === -1) {
      acc.push({ ano, quantidade, valor })
    } else {
      acc[index].quantidade += quantidade
      acc[index].valor += valor
    }
    return acc
  }, [])

  const anosOrdenados = resumo.map((item) => item.ano).sort((a, b) => a - b)

  const resultado = anosOrdenados.map((ano) => {
    const itemAno = resumo.find((item) => item.ano === ano)

    return {
      ano: [String(ano)],
      quantidade: itemAno?.quantidade ?? 0,
      valor: itemAno?.valor ?? 0
    }
  })

  return {
    nomeMeses: resultado.map((x) => x.ano),
    quantidade: resultado.map((x) => x.quantidade),
    valor: resultado.map((x) => x.valor)
  }
}

export function unirInadimplencias(inadimplencias: InadimplenciaParceiro[]) {
  let dadosMeses = inadimplencias?.reduce((acc: DadosMeses[], parceiroAtual: InadimplenciaParceiro) => [...acc, ...parceiroAtual.dados], [])

  dadosMeses = unirDadosMeses(dadosMeses)

  return dadosMeses
}

export function unirPerdas(inadimplencias: DebitosPerdasEfetivados[]) {
  let debitosPerdasMeses = inadimplencias?.reduce((acc: DadosMeses[], parceiroAtual: DebitosPerdasEfetivados) => [...acc, ...parceiroAtual.dados], [])

  debitosPerdasMeses = unirDadosMeses(debitosPerdasMeses)

  return debitosPerdasMeses
}

function unirDadosMeses(dadosMeses: DadosMeses[]) {
  dadosMeses = dadosMeses?.reduce((acc: DadosMeses[], mesAtual) => {
    const mesesRepetidos = acc.find((i) => i.ano === mesAtual.ano && i.mes === mesAtual.mes)
    if (mesesRepetidos) {
      mesesRepetidos.quantidade += mesAtual.quantidade
      mesesRepetidos.valor += mesAtual.valor
    } else {
      acc.push({ ...mesAtual })
    }
    return acc
  }, [])
  return dadosMeses
}

export const gerarDadosGrafico = (dadosMeses: DadosMeses[], mesesSelecionado: number, ignorarMesAtual = false) => {
  if (dadosMeses != undefined && mesesSelecionado !== 1) return pegarUltimosMeses(dadosMeses, mesesSelecionado, ignorarMesAtual)
  if (dadosMeses != undefined && mesesSelecionado === 1) return pegarAnos(dadosMeses)

  return {
    valor: [0, 0, 0, 0, 0, 0],
    nomeMeses: [[''], [''], [''], [''], [''], ['']],
    quantidade: [0, 0, 0, 0, 0, 0]
  }
}
