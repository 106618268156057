import { TableCell, TableRow } from '@material-ui/core'
import styled from 'styled-components'

export const linhaTabelaCicloStyles = {
  TableRow: styled(TableRow).attrs({
    hover: true,
    tabIndex: -1,
    flexGrow: 1,
    height: '100vh',
    muitablecellsizesmall: {
      padding: 0
    }
  })`
    :hover {
      cursor: pointer;
    }
  `,
  TableCell: styled(TableCell)`
    height: 100%;
  `,
  TableCellStatus: styled(TableCell)`
    width: 350px;
  `
}
