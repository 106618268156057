import { Grid } from '@material-ui/core'
import { IOProps } from 'react-compose-io'
import { cabecalhoAssociacaoRegras } from '../../../../types/tabelaTypes'
import { AbaTabela } from '../AbaTabela'
import { TabAssociacaoIO } from './TabAssociacao.IO'
import { Botao } from './../../../../components/Botao/index'

export type TabAssociacaoProps = {}

export default function TabAssociacaoView({ _io }: IOProps<TabAssociacaoIO, TabAssociacaoProps>) {
  const { Container } = _io.styles

  return (
    <Container>
      <Grid container justifyContent="flex-end" spacing={2}>
        <Grid item>
          <Botao textoBotao="Adicionar" cor="primaria" estilo="contained" onClick={_io.handleClick} />
        </Grid>
      </Grid>
      <AbaTabela orderId={'regraId'} listItem={_io.regrasList} headCells={cabecalhoAssociacaoRegras} LinhaTabela={_io.linhaTabela} />
    </Container>
  )
}
