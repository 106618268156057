import { TableCell, TableContainer, TableRow } from '@material-ui/core'
import styled from 'styled-components'
import { customTheme } from '../../../../styles/customTheme'
import { Tipografia } from '../../../../components/Tipografia/index'
import { handleHexToRgba } from '../../../../utils/stringUtils'

interface CelulaTabelaProps {
  isdata?: boolean
}

export const historicoInadimplenciaStyles = {
  TableRowHistorico: styled(TableRow)`
    background-color: ${handleHexToRgba(0.28, customTheme.colors.grey02)};
    padding: 19px;
  `,
  TableCellHistorico: styled(TableCell)`
    padding-bottom: 0px;
    padding-top: 0px;
  `,
  ContainerHistorico: styled.div`
    background-color: ${customTheme.colors.white01};
    padding: 16px 18px;
    margin-bottom: 12px;
  `,
  ContainerDetalheObservacao: styled.div`
    padding: 8px 28px;
  `,
  TipografiaHistorico: styled(Tipografia).attrs((props: { margintop?: string; marginbottom?: string }) => props)`
    margin-bottom: ${(props) => (props.marginbottom ? props.marginbottom : 'none')};
    margin-top: ${(props) => (props.margintop ? props.margintop : 'none')};
  `,
  TabelaTitulo: styled(TableContainer)`
    padding: 36px 36px 0 36px;
  `,
  TituloDescricao: styled(Tipografia)`
    margin: 17px 0;
  `,
  CelulaCabecalho: styled(TableCell)`
    border: none;
    color: ${customTheme.colors.grey21};
    font-weight: 500;
    font-size: ${customTheme.fontSize[14]};
  `,
  CelulaTabela: styled(TableCell)<CelulaTabelaProps>`
    border: none;
    color: ${({ isdata }) => (isdata ? customTheme.colors.vermelho05 : customTheme.colors.grey04)};
    font-weight: ${({ isdata }) => (isdata ? 500 : 400)};
    text-align: center;
    font-size: ${customTheme.fontSize[16]};
  `,
  ContainerFlex: styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
  `
}
