import { informacoesModalRegularizacaoSucessoStyles } from './InformacoesModalRegularizacaoSucesso.style'
import { InformacoesModalRegularizacaoSucessoProps } from './InformacoesModalRegularizacaoSucesso.View'

export function informacoesModalRegularizacaoSucessoIO({ validacaoPlanilhaResponse }: InformacoesModalRegularizacaoSucessoProps) {
  return {
    styles: informacoesModalRegularizacaoSucessoStyles,
    alturaContainerResumo: validacaoPlanilhaResponse.resumos.length < 5 ? 'auto' : '160px'
  }
}

export type InformacoesModalRegularizacaoSucessoIO = ReturnType<typeof informacoesModalRegularizacaoSucessoIO>
