import { IOProps } from 'react-compose-io'
import { ValidacaoPlanilhaResponse } from '../../types/regularizacaoTypes'
import ConfirmarDialog from '../ConfirmarDialog/ConfirmarDialog'
import { InformacoesModalRegularizacaoErro } from '../InformacoesModalRegularizacaoErro/index'
import { InformacoesModalRegularizacaoSucesso } from '../InformacoesModalRegularizacaoSucesso/index'
import { ModalValidacaoPlanilhaRegularizacaoIO } from './ModalValidacaoPlanilhaRegularizacao.IO'

export type ModalValidacaoPlanilhaRegularizacaoProps = {
  abrirModalValidarPlanilha: boolean
  handleFecharModalValidarPlanilha: (value: boolean | ((prevVar: boolean) => boolean)) => void
  handleConfirmarDados: () => void
  itemListaValidado: ValidacaoPlanilhaResponse
  mensagemErroUploadPlanilha: string
  descricaoBaixas: string
}

export function ModalValidacaoPlanilhaRegularizacaoView({
  _io,
  abrirModalValidarPlanilha,
  handleFecharModalValidarPlanilha,
  handleConfirmarDados,
  itemListaValidado,
  mensagemErroUploadPlanilha,
  descricaoBaixas
}: IOProps<ModalValidacaoPlanilhaRegularizacaoIO, ModalValidacaoPlanilhaRegularizacaoProps>) {
  const { SubtituloContainer, ContainerModal, ContainerTituloModal } = _io.styles

  return (
    <ConfirmarDialog
      tamanhoModal={'md'}
      open={abrirModalValidarPlanilha}
      setOpen={handleFecharModalValidarPlanilha}
      confirmarButtonLabel={'Confirmar'}
      cancelarButtonLabel={'Cancelar'}
      handleConfirm={handleConfirmarDados}
      dialogTitle="Resumo"
      ocultarBotoes={Boolean(mensagemErroUploadPlanilha)}
    >
      <ContainerModal>
        {!mensagemErroUploadPlanilha ? (
          <>
            <ContainerTituloModal>
              <SubtituloContainer>Confira abaixo o resumo da planilha do upload</SubtituloContainer>
            </ContainerTituloModal>
            <InformacoesModalRegularizacaoSucesso descricaoBaixas={descricaoBaixas} validacaoPlanilhaResponse={itemListaValidado} />
          </>
        ) : (
          <>
            <ContainerTituloModal>
              <SubtituloContainer>Alguns erros foram encontrados na leitura da planilha</SubtituloContainer>
            </ContainerTituloModal>
            <InformacoesModalRegularizacaoErro mensagemErroUploadPlanilha={mensagemErroUploadPlanilha} />
          </>
        )}
      </ContainerModal>
    </ConfirmarDialog>
  )
}
