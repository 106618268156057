import Collapse from '@material-ui/core/Collapse'
import Divider from '@material-ui/core/Divider'
import List from '@material-ui/core/List'
import ListItemText from '@material-ui/core/ListItemText'
import IconExpandLess from '@material-ui/icons/ExpandLess'
import IconExpandMore from '@material-ui/icons/ExpandMore'
import PropTypes from 'prop-types'
import { IOProps } from 'react-compose-io'
import { AppMenuItemIO } from './AppMenuItem.IO'
import { AppMenuItem } from './index'

export const AppMenuPropTypes = {
  name: PropTypes.string.isRequired,
  link: PropTypes.string,
  Icon: PropTypes.elementType,
  items: PropTypes.array
}

type AppMenuItemType = PropTypes.InferProps<typeof AppMenuPropTypes>
type AppMenuItemOmissaoItems = Omit<AppMenuItemType, 'items'>

export type AppMenuItemProps = AppMenuItemOmissaoItems & {
  items?: AppMenuItemProps[]
}

export function AppMenuItemView({ _io, name, link, Icon, items = [] }: IOProps<AppMenuItemIO, AppMenuItemProps>) {
  const { AppMenuItemComponent, ListItemIcon } = _io.styles

  return (
    <>
      <AppMenuItemComponent link={link} onClick={_io.handleAbrirMenuLateral}>
        {Icon && (
          <ListItemIcon title={name}>
            <Icon />
          </ListItemIcon>
        )}
        <ListItemText primary={_io.isDrawerOpen ? name : ' '} inset={!Icon} />
        {_io.expandirMenu && !_io.abrirMenu && <IconExpandMore />}
        {_io.expandirMenu && _io.abrirMenu && <IconExpandLess />}
      </AppMenuItemComponent>
      {_io.expandirMenu && (
        <Collapse in={_io.abrirMenu} timeout="auto" unmountOnExit>
          <Divider />
          <List component="div" disablePadding>
            {items.map((item, index) => (
              <AppMenuItem {...item} key={index} />
            ))}
          </List>
        </Collapse>
      )}
    </>
  )
}

export default AppMenuItemView
