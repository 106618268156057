import Grid from '@material-ui/core/Grid/Grid'
import Typography from '@material-ui/core/Typography/Typography'

export type GridTypographyProps = {
  titulo: string
  valor: string | number
}

export const GridTypography = ({ titulo, valor }: GridTypographyProps) => {
  return (
    <Grid item>
      <Typography variant="subtitle1" color="textSecondary">
        {titulo}
      </Typography>
      <Typography variant="subtitle2">{valor}</Typography>
    </Grid>
  )
}
