import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import { IOProps } from 'react-compose-io'
import Modal from '../../../../components/Modal/Modal'
import { PreviewItemAssociacao } from '../../../../types/associacaoApiTypes'
import PreVisualizarAssociacao from '../../../Associacao/components/PreVisualizarAssociacao'
import { ModalVisualizarAssociacaoIO } from './ModalVisualizarAssociacao.IO'

export type ModalVisualizarAssociacaoProps = {
  handleFecharModal: () => void
  abrirModal: boolean
  preVisualizar: PreviewItemAssociacao | undefined
}

export function ModalVisualizarAssociacaoView({
  _io,
  abrirModal,
  handleFecharModal,
  preVisualizar
}: IOProps<ModalVisualizarAssociacaoIO, ModalVisualizarAssociacaoProps>) {
  return (
    <Modal
      title="Pré visualização de associação"
      openModal={abrirModal}
      fullWidth={false}
      maxWidth={false}
      onClose={handleFecharModal}
      onAction={handleFecharModal}
      hiddenButton
    >
      <Grid item sm={12}>
        <TableContainer>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                {_io.visualizarAssociacao.map((cabecalho, index) => (
                  <TableCell key={index} align="center">
                    {cabecalho.titleCabecalho}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>{preVisualizar != undefined ? <PreVisualizarAssociacao row={preVisualizar} /> : null}</TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Modal>
  )
}
