import { HeadCell } from '../interfaces/interfaces'

export type Order = 'asc' | 'desc'

export interface Paginacao {
  pageNumber?: number
  pageSize?: number
  orderBy?: string
  orderField?: string | number | symbol
}

export const cabecalhoVisualizarContabilizacao: HeadCell[] = [
  { id: 'historicoPadrao', numeric: false, disablePadding: true, label: 'Histórico' },
  { id: 'valor', numeric: true, disablePadding: false, label: 'Valor' },
  { id: 'parcela', numeric: true, disablePadding: false, label: 'Parcela' }
]
export const cabecalhoVisualizarContabilizacaoModal: HeadCell[] = [
  { id: 'historicoPadrao', numeric: false, disablePadding: true, label: 'Histórico' },
  { id: 'tipoLancamento', numeric: false, disablePadding: true, label: 'Tipo Lançamento' },
  { id: 'sequencia', numeric: false, disablePadding: true, label: 'Sequência' },
  { id: 'valor', numeric: true, disablePadding: false, label: 'Valor' },
  { id: 'parcela', numeric: true, disablePadding: false, label: 'Parcela' }
]

export const cabecalhoGruposRegras: HeadCell[] = [
  { id: 'grupoId', numeric: true, disablePadding: false, label: 'Núm.' },
  { id: 'nome', numeric: false, disablePadding: false, label: 'Grupo' },
  { id: 'descricao', numeric: false, disablePadding: false, label: 'Descrição' },
  { id: 'ativo', numeric: false, disablePadding: false, label: 'Tipo de lançamento eFácil' },
  { id: 'acoes', numeric: false, disablePadding: false, label: '' }
]

export const cabecalhoTipoLancamentoParceiro: HeadCell[] = [
  { id: 'tipoLancamentoParceiroId', numeric: true, disablePadding: false, label: 'Número' },
  { id: 'descricao', numeric: false, disablePadding: false, label: 'Tipo Lançamento Parceiro' },
  { id: 'tipoContabilizacaoId', numeric: false, disablePadding: false, label: 'Tipo' },
  { id: 'ativo', numeric: false, disablePadding: false, label: 'Status' },
  { id: 'sigla', numeric: false, disablePadding: false, label: 'Sigla' },
  { id: 'acoes', numeric: false, disablePadding: false, label: '' }
]

export const cabecalhoAssociacaoRegras: HeadCell[] = [
  { id: 'historico', numeric: false, disablePadding: false, label: '' },
  { id: 'prioridade', numeric: true, disablePadding: false, label: 'Prioridade' },
  { id: 'tipoLancamento', numeric: false, disablePadding: false, label: 'Tipo de lançamento parceiro' },
  { id: 'tipoLancamentoEfacil', numeric: false, disablePadding: false, label: 'Tipo de lançamento eFácil' },
  { id: 'contaContabilId', numeric: true, disablePadding: false, label: 'Conta' },
  { id: 'tipoNaturezaContabil', numeric: false, disablePadding: false, label: 'Natureza' },
  { id: 'tipoAgrupamento', numeric: false, disablePadding: false, label: 'Tipo' },
  { id: 'ativo', numeric: false, disablePadding: false, label: 'Status' },
  { id: 'acoes', numeric: true, disablePadding: false, label: '' }
]

export const cabecalhoTransformacaoRegras: HeadCell[] = [
  { id: 'historico', numeric: false, disablePadding: true, label: '' },
  { id: 'nomeRegra', numeric: false, disablePadding: true, label: 'Nome do parâmetro' },
  { id: 'campo', numeric: false, disablePadding: true, label: 'Campo' },
  { id: 'grupos', numeric: false, disablePadding: true, label: 'Grupos' },
  { id: 'ativo', numeric: false, disablePadding: true, label: 'Status' },
  { id: 'acoes', numeric: true, disablePadding: true, label: '' }
]

export const cabecalhoTabelaResultadoOPS: HeadCell[] = [
  { id: 'ordemPagamentoId', numeric: true, disablePadding: false, label: 'OP' },
  { id: 'valorPagamento', numeric: true, disablePadding: false, label: 'Valor' },
  { id: 'dataEmissao', numeric: false, disablePadding: false, label: 'Data de emissão' },
  { id: 'emitente', numeric: false, disablePadding: false, label: 'Emitente' },
  { id: 'bancoId', numeric: true, disablePadding: false, label: 'Banco' },
  { id: 'descricao', numeric: false, disablePadding: false, label: 'Descrição' },
  { id: 'acao', numeric: false, disablePadding: true, label: '' }
]

export const cabecalhoTabelaCiclosParceiro: HeadCell[] = [
  { id: 'cicloId', numeric: true, disablePadding: true, label: 'Id. do ciclo' },
  { id: 'dataCadastro', numeric: true, disablePadding: false, label: 'Data' },
  { id: 'totalRepasse', numeric: true, disablePadding: false, label: 'Valor total de repasse' },
  { id: null, numeric: false, disablePadding: false, label: 'Ciclo do Parceiro' },
  { id: null, numeric: true, disablePadding: false, label: 'Status' },
  { id: null, numeric: true, disablePadding: false, label: 'Progresso' }
]

export const cabecalhoLancamentoNaoAssociado: HeadCell[] = [
  { id: 'identificadorPedido', numeric: true, disablePadding: true, label: 'Id. do Pedido' },
  { id: 'loja', numeric: false, disablePadding: false, label: 'Loja' },
  { id: 'tipoLancamento', numeric: false, disablePadding: false, label: 'Tipo de Lancamento' },
  { id: 'lancamento', numeric: false, disablePadding: false, label: 'Lancamento' },
  { id: 'valor', numeric: true, disablePadding: false, label: 'Valor' },
  { id: 'parcela', numeric: true, disablePadding: false, label: 'Parcela' }
]

export const cabecalhoTabelaContabilizacao: HeadCell[] = [
  { id: 'tipoLancamento', numeric: false, disablePadding: true, label: 'Tipo de lançamento' },
  { id: 'sequenciaId', numeric: true, disablePadding: false, label: 'Sequência' },
  { id: 'conta', numeric: true, disablePadding: false, label: 'Conta' },
  { id: 'natureza', numeric: false, disablePadding: false, label: 'Natureza' },
  { id: 'valor', numeric: true, disablePadding: false, label: 'Valor' }
]

export const cabecalhoTabelaCiclo: HeadCell[] = [
  { id: 'identificadorPedido', numeric: false, disablePadding: true, label: 'Id. Pedido' },
  { id: 'loja', numeric: false, disablePadding: false, label: 'Loja' },
  { id: 'tipoLancamento', numeric: false, disablePadding: false, label: 'Tipo de Lançamento' },
  { id: 'lancamento', numeric: false, disablePadding: false, label: 'Lançamento' },
  { id: 'valor', numeric: true, disablePadding: false, label: 'Valor' },
  { id: 'parcela', numeric: true, disablePadding: false, label: 'Parcela' }
]

export const cabecalhoLancamentoAssociacao: HeadCell[] = [
  { id: 'tipoLancamentoParceiroId', numeric: true, disablePadding: true, label: 'Núm.' },
  { id: 'descricao', numeric: false, disablePadding: true, label: 'Tipo de lançamento' },
  { id: 'tipoContabilizacaoId', numeric: false, disablePadding: true, label: 'Tipo' },
  { id: 'ativo', numeric: false, disablePadding: true, label: 'Status' },
  { id: 'sigla', numeric: false, disablePadding: true, label: 'Sigla' },
  { id: '', numeric: false, disablePadding: true, label: '' }
]

export const cabecalhoLancamentosVariaveis = (textoFiltro: any) => {
  return [
    {
      field: 'tipoLancamento',
      title: 'Tipo Lançamento Original',
      editable: 'never',
      lookup: textoFiltro
    },
    {
      field: 'tipoLancamentoNovo',
      title: 'Tipo Lançamento Novo',
      editable: 'never',
      filtering: false
    },
    {
      field: 'identificadorPedido',
      title: 'Id. Pedido',
      filtering: false
    },
    {
      field: 'lancamento',
      title: 'Lançamento',
      editable: 'never',
      filtering: false
    },
    {
      field: 'valor',
      title: 'Valor',
      filtering: false,
      type: 'currency',
      currencySetting: { locale: 'en-US', currencyCode: 'BRL', minimumFractionDigits: 0, maximumFractionDigits: 2 }
    },
    {
      field: 'parcela',
      title: 'Parcela',
      editable: 'never',
      filtering: false
    },
    {
      field: 'sequencia',
      title: 'Sequência',
      editable: 'never',
      filtering: false
    }
  ]
}

export const cabecalhoTiposDeLancamentos: HeadCell[] = [
  { id: 'id', numeric: true, disablePadding: true, label: 'Num' },
  { id: 'descricao', numeric: true, disablePadding: false, label: 'Tipo de Lançamento' },
  { id: 'sequencia', numeric: true, disablePadding: false, label: 'Sequência' },
  { id: 'conta', numeric: true, disablePadding: false, label: 'Conta' },
  { id: 'natureza', numeric: true, disablePadding: false, label: 'Natureza' },
  { id: 'tipo', numeric: true, disablePadding: false, label: 'Tipo' },
  { id: 'status', numeric: true, disablePadding: false, label: 'Status' },
  { id: '', numeric: false, disablePadding: false, label: '' }
]

export const cabecalhoParceiros: HeadCell[] = [
  { id: 'parceiroId', numeric: true, disablePadding: true, label: 'Código' },
  { id: 'razaoSocial', numeric: false, disablePadding: false, label: 'Razão Social' },
  { id: 'nomeFantasia', numeric: false, disablePadding: false, label: 'Nome Fantasia' },
  { id: 'ultimoRepasse', numeric: false, disablePadding: false, label: 'Último repasse' },
  { id: 'proximoRepasse', numeric: false, disablePadding: false, label: 'Próximo repasse' },
  { id: 'status', numeric: false, disablePadding: false, label: 'Status' }
]

export const cabecalhoConfiguracoesGerais: HeadCell[] = [
  { id: '', numeric: false, disablePadding: false, label: '' },
  { id: 'parceiroId', numeric: true, disablePadding: true, label: 'Sigla do sistema' },
  { id: 'razaoSocial', numeric: false, disablePadding: false, label: 'Unidade de negócio' },
  { id: 'nomeFantasia', numeric: false, disablePadding: false, label: 'Evento' },
  { id: 'ultimoRepasse', numeric: false, disablePadding: false, label: 'Fato' },
  { id: 'status', numeric: false, disablePadding: false, label: 'Status' }
]

export const cabecalhoPedidoInternalizacao: HeadCell[] = [
  { id: 'pedidoId', numeric: false, disablePadding: true, label: 'Pedido eFácil' },
  { id: 'lancamentoParceiro', numeric: false, disablePadding: true, label: 'Tipo de lançamento parceiro' },
  { id: 'novoTipoLancamentoParceiro', numeric: false, disablePadding: true, label: 'Tipo de lançamento atual' },
  { id: 'cicloId', numeric: true, disablePadding: true, label: 'Ciclo' },
  { id: 'dataProcessamento', numeric: false, disablePadding: true, label: 'Data do ciclo' },
  { id: 'valor', numeric: true, disablePadding: true, label: 'Valor' },
  { id: 'parcela', numeric: true, disablePadding: true, label: 'Parcela' }
]

export const cabecalhoPedidoContabilizacao: HeadCell[] = [
  { id: 'pedidoId', numeric: false, disablePadding: true, label: 'Pedido eFácil' },
  { id: 'cicloId', numeric: true, disablePadding: true, label: 'Ciclo' },
  { id: 'dataProcessamento', numeric: false, disablePadding: true, label: 'Data do processamento' },
  { id: 'tipoLancamentoEfacil', numeric: false, disablePadding: true, label: 'Tipo de lançamento eFácil' },
  { id: 'valor', numeric: true, disablePadding: true, label: 'Valor' },
  { id: 'numeroSequencia', numeric: false, disablePadding: true, label: 'Sequencia contábil' },
  { id: 'parcela', numeric: true, disablePadding: true, label: 'Parcela' }
]

export const cabecalhoControleComissao: HeadCell[] = [
  { id: null, numeric: true, disablePadding: true, label: '' },
  { id: 'parceiro.descricao', numeric: false, disablePadding: true, label: 'Parceiro' },
  { id: 'cicloId', numeric: true, disablePadding: true, label: 'Ciclo' },
  { id: 'comissaoCobrada', numeric: true, disablePadding: true, label: 'Comissão cobrada' },
  { id: 'comissaoDevida', numeric: true, disablePadding: true, label: 'Comissão devida' },
  { id: 'diferenca', numeric: true, disablePadding: true, label: 'Diferença' },
  { id: 'statusAtual', numeric: true, disablePadding: true, label: 'Status' },
  { id: null, numeric: true, disablePadding: true, label: '' }
]

export const cabecalhoRegularizacaoPendente: HeadCell[] = [
  { id: 'ciclo', numeric: false, disablePadding: true, label: 'Ciclo' },
  { id: 'parceiro', numeric: false, disablePadding: true, label: 'Parceiro' },
  { id: 'pedidoMkp', numeric: false, disablePadding: true, label: 'Ped. Mktplace' },
  { id: 'clienteId', numeric: false, disablePadding: true, label: 'Cód do cliente' },
  { id: 'parcela', numeric: true, disablePadding: true, label: 'Parcela' },
  { id: null, numeric: true, disablePadding: false, label: 'Valor saldo' },
  { id: 'dataPagamento', numeric: false, disablePadding: true, label: 'Data pagamento' }
]

export const cabecalhoOrdemPagamento: HeadCell[] = [
  { id: 'numeroOp', numeric: true, disablePadding: true, label: 'Número OP' },
  { id: 'banco', numeric: false, disablePadding: true, label: 'Banco' },
  { id: 'emitente', numeric: true, disablePadding: true, label: 'Emitente' },
  { id: 'descricaoPagamento', numeric: true, disablePadding: true, label: 'Descrição Pagamento' },
  { id: 'dataEmissao', numeric: true, disablePadding: true, label: 'Data Emissão' },
  { id: 'valorOp', numeric: true, disablePadding: true, label: 'Valor Op' }
]

export const cabecalhoRegularizacaoTitulo: HeadCell[] = [
  { id: 'pedidoEfacil', numeric: true, disablePadding: true, label: 'Pedido Efácil' },
  { id: 'titulo', numeric: false, disablePadding: true, label: 'Título' },
  { id: 'parcela', numeric: true, disablePadding: true, label: 'Parcela' },
  { id: 'dataEmissao', numeric: true, disablePadding: true, label: 'Data Emissão' },
  { id: 'dataVencimento', numeric: true, disablePadding: true, label: 'Data de vencimento' },
  { id: 'Saldo Título', numeric: true, disablePadding: true, label: 'Saldo Título' }
]

export const cabecalhoParametrizacaoStatus: HeadCell[] = [
  { id: 'tipoStatusParceiro', numeric: false, disablePadding: true, label: 'Tipo de status do parceiro' },
  { id: null, numeric: false, disablePadding: false, label: 'Status do e-Fácil' },
  { id: null, numeric: false, disablePadding: false, label: 'Status do tracking' },
  { id: 'verificarOcorrencia', numeric: false, disablePadding: false, label: 'Consultar ocorrência SAC/0800' },
  { id: 'ativo', numeric: false, disablePadding: false, label: 'Status da regra' },
  { id: 'acoes', numeric: false, disablePadding: false, label: '' }
]

export const detalhesCicloPedido: HeadCell[] = [
  { id: null, numeric: false, disablePadding: true, label: '' },
  { id: null, numeric: false, disablePadding: true, label: 'Selecionar' },
  { id: 'pedidoMkt', numeric: false, disablePadding: true, label: 'Pedido marketplace' },
  { id: 'pedidoId', numeric: false, disablePadding: true, label: 'Pedido Efácil' },
  { id: 'nomeStatusParceiro', numeric: false, disablePadding: true, label: 'Status do parceiro' },
  { id: null, numeric: false, disablePadding: true, label: 'Status' },
  { id: 'dataEmissaoPedido ', numeric: false, disablePadding: true, label: 'Data' }
]

export const detalhesInadimplencia: HeadCell[] = [
  { id: null, numeric: false, disablePadding: true, label: '' },
  { id: null, numeric: false, disablePadding: true, label: 'Selecionar' },
  { id: 'dataEmissao', numeric: false, disablePadding: true, label: 'Data de Emissão' },
  { id: null, numeric: false, disablePadding: true, label: 'Número do pedido marketplace' },
  { id: 'saldo', numeric: false, disablePadding: true, label: 'Saldo dos títulos' },
  { id: null, numeric: false, disablePadding: true, label: 'Possível tratativa' },
  { id: null, numeric: false, disablePadding: true, label: 'Classificação da análise' },
  { id: 'parceiro', numeric: false, disablePadding: true, label: 'Parceiro' }
]

export const detalhesOpsEmAberto: HeadCell[] = [
  { id: 'dataEmissao', numeric: false, disablePadding: true, label: 'Data de Emissão' },
  { id: 'bancoId', numeric: false, disablePadding: true, label: 'Banco' },
  { id: 'ordemPagamentoId', numeric: false, disablePadding: true, label: 'Número da OP' },
  { id: null, numeric: false, disablePadding: true, label: 'Valor da OP' },
  { id: null, numeric: false, disablePadding: true, label: 'Número do pedido' },
  { id: 'clienteId', numeric: false, disablePadding: true, label: 'Cód. do cliente' },
  { id: null, numeric: false, disablePadding: true, label: 'Parceiro' },
  { id: null, numeric: false, disablePadding: true, label: 'Ciclo' }
]

export const detalhesDebito: HeadCell[] = [
  { id: null, numeric: false, disablePadding: true, label: '' },
  { id: null, numeric: false, disablePadding: true, label: 'Selecionar' },
  { id: 'parceiro', numeric: false, disablePadding: true, label: 'Parceiro' },
  { id: 'dataLancamento', numeric: false, disablePadding: true, label: 'Data do débito' },
  { id: 'pedidoMkt', numeric: false, disablePadding: true, label: 'Número do pedido marketplace' },
  { id: 'valorDebito', numeric: false, disablePadding: true, label: 'Valor do débito' },
  { id: 'tratativa', numeric: false, disablePadding: true, label: 'Possível tratativa' },
  { id: 'classificacaoAnalise', numeric: false, disablePadding: true, label: 'Classificação da análise' }
]
