import { StepLabel, StepConnector, Box, Stepper } from '@material-ui/core'
import styled from 'styled-components'
import { ContainerSombra } from '../../../../components/ContainerSombra'
import { customTheme } from './../../../../styles/customTheme'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'

export const stylesCicloEtapa = {
  Container: styled(ContainerSombra)`
    & .Mui-error {
      color: ${customTheme.colors.grey08};
    }

    & .Mui.MuiStepper-root {
      border-radius: 8px;
    }
  `,
  StepLabel: styled(StepLabel)`
    cursor: pointer;
  `,
  ContainerHistorico: styled.div`
    padding: 8px;
  `,
  StepLine: styled(StepConnector)`
    .MuiStepConnector-alternativeLabel {
      top: 13px;
      left: calc(-50% + 16px);
      right: calc(50% + 16px);
    }

    .MuiStepConnector-line {
      border-color: #eaeaf0;
      border-radius: 1px;
      border-top-width: 3px;
    }

    &:not(.Mui-disabled) {
      .MuiStepConnector-line {
        border-color: ${customTheme.colors.azul06};
      }
    }
  `,
  Circle: styled.div`
    background-color: #eaeaf0;
    width: 15px;
    height: 15px;
    border-radius: 50%;
  `,
  Box: styled(Box)`
    margin-top: 8px;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
  `,
  IconContainer: styled.div`
    height: 28px;
    display: flex;
    align-items: center;
  `,
  Stepper: styled(Stepper)`
    border-radius: 8px;
  `,
  FiberManualRecordIconAtivo: styled(FiberManualRecordIcon)`
    color: ${customTheme.colors.azul06};
  `
}
