import {
  MenuItem,
  Select,
  TableBody,
  TableContainer,
  TablePagination,
  Typography,
  Table,
  InputLabel,
  InputAdornment,
  FormControl,
  Grid
} from '@material-ui/core'
import FilterListIcon from '@material-ui/icons/FilterList'
import { IOProps } from 'react-compose-io'
import { EnhancedTableHead } from '../../components/EnhancedTableHead/index'
import { ComponentePaginacao } from '../../components/Tabela/ComponentePaginacao/index'
import { cabecalhoTiposDeLancamentos } from '../../types/tabelaTypes'
import { getComparator, ordenarCabecalho } from '../../utils/tabelaUtils'
import { DadosTiposLancamentos } from './components/DadosTiposLancamentos/index'
import { ItemLancamento } from './components/ItemLancamento/index'
import { ModalConfirmar } from './components/ModalConfirmar/index'
import { TiposDeLancamentosIO } from './TiposDeLancamentos.IO'
import DataEmpty from './../../components/DataEmpty/DataEmpty'

export type TiposDeLancamentosProps = {}

export function TiposDeLancamentosView({ _io }: IOProps<TiposDeLancamentosIO, TiposDeLancamentosProps>) {
  const { ContainerPaper, ContainerBranco, Subtitulo } = _io.styles

  return (
    <>
      <ModalConfirmar
        isModal={_io.modal}
        fecharModal={_io.handleFecharModal}
        submeterLancamento={_io.handleSalvarTipoLancamento}
        tipoLancamento={_io.tipoLancamento}
        configurarTipoLancamento={_io.configurarTipoLancamento}
        historicoPadrao={_io.historicoPadrao}
      />

      <ContainerBranco>
        <Typography variant="h6">Tipos de lançamento eFácil</Typography>
        <Subtitulo variant="subtitle1">Novo tipo de lançamento eFácil</Subtitulo>
        <DadosTiposLancamentos
          telaMenor={true}
          submeterTipoLancamento={_io.handleSubmit(_io.submeterFormularioTipoLancamento)}
          controleFormulario={_io.control}
          erroPreenchimento={_io.errors}
          clicarSequenciaFormulario={_io.handleSelecionarSequencia}
        />
      </ContainerBranco>

      <ContainerPaper>
        <Grid container alignContent="center" justifyContent="space-between">
          <Grid item>
            <Typography variant="h6">Tipos de lançamentos eFácil cadastrados</Typography>
          </Grid>
          <Grid item xs={2}>
            <FormControl variant="outlined" size="small" fullWidth>
              <InputLabel>Filtro</InputLabel>
              <Select
                label="Filtro"
                value={_io.filtro}
                onChange={_io.tratarMudancaFiltroSelecionado}
                startAdornment={
                  <InputAdornment position="start">
                    <FilterListIcon />
                  </InputAdornment>
                }
              >
                <MenuItem value={'a'}>Ativos</MenuItem>
                <MenuItem value={'d'}>Desativados</MenuItem>
                <MenuItem value={'t'}>Todos</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        {_io.lancamentos.length > 0 ? (
          <>
            <TableContainer>
              <Table>
                <EnhancedTableHead
                  alignCenter={true}
                  order={_io.ordem}
                  onRequestSort={_io.onRequestSort}
                  rowCount={_io.lancamentos.length || 0}
                  headCells={cabecalhoTiposDeLancamentos}
                />
                <TableBody>
                  {ordenarCabecalho(_io.lancamentos, getComparator(_io.ordem, _io.ordemBy))
                    .slice(_io.pagina * _io.linhasPorPagina, _io.pagina * _io.linhasPorPagina + _io.linhasPorPagina)
                    .map((lancamento, lancamentoIndex) => {
                      return (
                        <ItemLancamento
                          key={lancamento.id}
                          linhaTipoLancamento={lancamento}
                          visualizarTabela={_io.estadosItensTabela[lancamentoIndex]}
                          visualizarHistorico={_io.toggleEstadoItemTabela}
                          itemLancamentoIndex={lancamentoIndex}
                          setHistoricoPadrao={_io.setHistoricoPadrao}
                          historicoPadrao={_io.historicoPadrao}
                          obterHistoricoPadraoSequencia={_io.obterHistoricoPadraoSequencia}
                        />
                      )
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={_io.linhasPorPaginaOpcao}
              component="div"
              count={_io.lancamentos.length}
              rowsPerPage={_io.linhasPorPagina}
              page={_io.pagina}
              onPageChange={_io.onMudancaPagina}
              onRowsPerPageChange={_io.onMudancaLinhasPorPagina}
              labelRowsPerPage="Itens por página"
              ActionsComponent={ComponentePaginacao}
            />
          </>
        ) : (
          <DataEmpty descricao="Nenhum tipo de lançamento eFácil cadastrado!" />
        )}
      </ContainerPaper>
    </>
  )
}
