import { CircularProgress, TableCell } from '@material-ui/core'
import { IOProps } from 'react-compose-io'
import ConfirmarDialog from '../../../../components/ConfirmarDialog/ConfirmarDialog'
import { TabelaEditavelErroProcessamento } from '../TabelaEditavelErroProcessamento/index'
import { ErroProcessamentoIO } from './ErroProcessamento.IO'
import { Tipografia } from '../../../../components/Tipografia'
import { customTheme } from './../../../../styles/customTheme'
import { Botao } from '../../../../components/Botao'

export type ErroProcessamentoProps = {
  cicloId: string
  parceiroId: number
  setHabilitarConfirmar(enable: boolean): void
}

export function ErroProcessamentoView({ _io }: IOProps<ErroProcessamentoIO, ErroProcessamentoProps>) {
  const { Box, Container, ContainerStyled, ContainerCabecalho, SubtitleCabecalho, TableRow, ContainerTitle, GridBotao } = _io.styles

  return (
    <>
      <Container>
        <ContainerStyled>
          <ContainerCabecalho container>
            <ContainerTitle>
              <Tipografia variant="h6" colorText={customTheme.colors.red06}>
                Erro no Processamento
              </Tipografia>
              <SubtitleCabecalho>Não foi possível processar os dados abaixo:</SubtitleCabecalho>
            </ContainerTitle>
            <GridBotao item>
              <Botao textoBotao="Desfazer" estilo="text" cor="erro" onClick={_io.desfazerProcessamento} disabled={_io.verificarSelecao} />
              <Botao textoBotao="Ignorar" estilo="outlined" cor="erro" onClick={_io.isAbrirModal} disabled={_io.verificarSelecao} />
              <Botao textoBotao="Adicionar novos parâmetros" estilo="contained" cor="erro" onClick={_io.handleAdicionar} disabled={_io.verificarSelecao} />
            </GridBotao>
          </ContainerCabecalho>

          {!_io.loading ? (
            <>
              {_io.erroProcessamentoLista && (
                <TabelaEditavelErroProcessamento
                  cabecalhoTabela={_io.erroProcessamentoLista.headers}
                  corpoTabela={_io.erroProcessamentoLista.itens}
                  setCheckboxSelecionado={_io.setSelectedsErroProcessamento}
                  pagina={_io.pagina}
                  opcoesNumerosPaginacao={_io.opcoesNumerosPaginacao}
                  totalLinhas={_io.totalLinhas}
                  onMudancaLinhasPorPagina={_io.onMudancaLinhasPorPagina}
                  onMudancaPorPagina={_io.onMudancaPagina}
                  onOrdernarTabela={_io.ordernarTabelaEditavel}
                  editarItensErroProcessamento={_io.editarErroProcessamento}
                  linhasPorPagina={_io.linhasPorPagina}
                />
              )}
            </>
          ) : (
            <Box>
              <TableRow tabIndex={-1}>
                <TableCell align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            </Box>
          )}
        </ContainerStyled>
      </Container>

      <ConfirmarDialog
        open={_io.abrirModal}
        setOpen={_io.setAbrirModal}
        handleConfirm={_io.ignorarErrosProcessamento}
        confirmarButtonLabel={'Sim, tenho!'}
        cancelarButtonLabel={'Não'}
      >
        <Tipografia variant="body1">{_io.textoModalErroProcessamento}</Tipografia>
      </ConfirmarDialog>
    </>
  )
}
