import { useFieldArray, useFormContext } from 'react-hook-form'
import { TIPO_PARAMETRO } from '../../../constants/utilConstants'
import { stylesFieldForms } from '../styles'

export function fieldsAcaoIO() {
  const { control } = useFormContext()

  const acoes = useFieldArray({
    name: 'acoes',
    control
  })

  return {
    styles: stylesFieldForms,
    acoes,
    control,
    submeterAcao: () =>
      acoes.append({
        operacaoId: null,
        dado: '',
        operador: '',
        parametro: {
          parametro: '',
          tipoParametro: TIPO_PARAMETRO.criterio
        }
      })
  }
}
export type FieldsAcaoIO = ReturnType<typeof fieldsAcaoIO>
