import { Box } from '@material-ui/core'
import Tooltip from '@material-ui/core/Tooltip'
import { IOProps } from 'react-compose-io'
import { HeaderTabelaResultadosOPSIO } from './HeaderTabelaResultadosOPS.IO'
import { Tipografia } from '../../../../components/Tipografia'
import { Botao } from './../../../../components/Botao/index'

export type HeaderTabelaResultadosOPSProps = {
  totalOpsSelecionadas: number
  handleAdicionarOps: () => void
  handleAdicionarOpsContinuar: () => void
}

export function HeaderTabelaResultadosOPSView({
  _io,
  totalOpsSelecionadas,
  handleAdicionarOps,
  handleAdicionarOpsContinuar
}: IOProps<HeaderTabelaResultadosOPSIO, HeaderTabelaResultadosOPSProps>) {
  const { Container, ContainerBotao } = _io.styles

  return (
    <Container numSelect={totalOpsSelecionadas > 0}>
      {totalOpsSelecionadas > 0 ? (
        <Tipografia variant="subtitle1">{`${totalOpsSelecionadas} Op(s) selecionada(s)`}</Tipografia>
      ) : (
        <Box>
          <Tipografia variant="h6">Resultado(s)</Tipografia>
        </Box>
      )}
      {totalOpsSelecionadas > 0 ? (
        <ContainerBotao>
          <Tooltip title="Selecionar op(s)">
            <Botao type="submit" textoBotao="Adicionar" cor="primaria" estilo="outlined" onClick={handleAdicionarOps} />
          </Tooltip>
          <Tooltip title="Adicionar e continuar op(s)">
            <Botao type="submit" textoBotao="Adicionar e continuar" cor="primaria" estilo="contained" onClick={handleAdicionarOpsContinuar} />
          </Tooltip>
        </ContainerBotao>
      ) : null}
    </Container>
  )
}
