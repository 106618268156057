import { Grid, InputLabel, ListItemIcon, ListItemText, MenuItem, OutlinedInput } from '@material-ui/core'
import { IOProps } from 'react-compose-io'
import { Controller } from 'react-hook-form'
import { SelecionarParceiroIO } from './SelecionarParceiro.IO'
import { Tipografia } from '../Tipografia'
import { Botao } from '../Botao'
import { Parceiro } from '../../types/parceiroApiTypes'

export type SelecionarParceiroProps = {
  handleConfirmar: (dadosForm: { parceiroId: number }) => void
  parceiros: Parceiro[]
  subtitulo: string
}

export function SelecionarParceiroView({ _io, parceiros, handleConfirmar, subtitulo }: IOProps<SelecionarParceiroIO, SelecionarParceiroProps>) {
  const { Avatar, MenuItemContainer, Select, FormControl, Container, Subtitulo } = _io.styles

  return (
    <>
      <Container>
        <Tipografia variant="h6">Parceiros</Tipografia>
        <Subtitulo>{subtitulo}</Subtitulo>
        <form>
          <Controller
            render={({ field }) => (
              <FormControl variant="outlined" size="small" fullWidth>
                <InputLabel shrink>Parceiro</InputLabel>

                <Select {...field} label="Parceiro" fullWidth input={<OutlinedInput notched name="parceiro" id="outlined-parceiro" labelWidth={65} />}>
                  {parceiros.map((parceiro) => (
                    <MenuItem key={parceiro.parceiroId} value={parceiro.parceiroId}>
                      <MenuItemContainer>
                        <ListItemIcon>
                          <Avatar src={parceiro.logoUrl} />
                        </ListItemIcon>
                        <ListItemText primary={parceiro.razaoSocial} />
                      </MenuItemContainer>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            name="parceiroId"
            control={_io.control}
          />
        </form>
        <Grid container justifyContent="flex-end">
          <Botao
            disabled={_io.parceiroIdSelecionado == undefined}
            textoBotao="Confirmar"
            onClick={_io.handleSubmit(handleConfirmar)}
            width="200px"
            estilo="contained"
            cor="primaria"
          />
        </Grid>
      </Container>
    </>
  )
}
