import { Grid, Typography } from '@material-ui/core'
import { IOProps } from 'react-compose-io'
import Dropzone from 'react-dropzone'
import DownloadIcon from '../../../src/assets/icons/download-icon.svg'
import { validarTexto } from '../../utils/stringUtils'
import { UploadPlanilhaRegularizacaoIO } from './UploadPlanilhaRegularizacao.IO'
import { Botao } from '../Botao'

export type UploadPlanilhaRegularizacaoProps = {
  titulo: string
  subtitulo: string
  planilhaPadraoUrl?: string
  planilhaPadraoTexto: string
  validarPlanilha: (arquivo?: FormData) => void
  setArquivoId: React.Dispatch<React.SetStateAction<string>>
}

export function UploadPlanilhaRegularizacaoView({
  _io,
  titulo,
  planilhaPadraoUrl,
  planilhaPadraoTexto,
  subtitulo
}: IOProps<UploadPlanilhaRegularizacaoIO, UploadPlanilhaRegularizacaoProps>) {
  const {
    UploadMessage,
    Container,
    TemplateContainer,
    GridUploadInfo,
    DropContainer,
    DropSuccess,
    SpanSize,
    SpanTitle,
    GridUploadInfoIndicador,
    UploadButton,
    TypographyTemplateBody,
    DownloadButton,
    Subtitulo
  } = _io.styles

  return (
    <Container>
      <Typography variant="h6">{titulo}</Typography>
      <Subtitulo variant="subtitle1">{subtitulo}</Subtitulo>

      <Dropzone
        maxFiles={1}
        accept={['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel']}
        onDropAccepted={_io.onArquivoAceito}
      >
        {({ getRootProps, getInputProps }) => (
          <DropContainer {...getRootProps()}>
            {!_io.dropVisivel ? (
              <>
                <input placeholder="Upload" {...getInputProps()} />
                <>
                  <UploadMessage>Arraste a planilha para cá</UploadMessage>
                  <UploadMessage>ou</UploadMessage>
                  <UploadMessage>
                    <UploadButton>clique aqui para selecionar de seu computador</UploadButton>
                  </UploadMessage>
                </>
              </>
            ) : (
              <DropSuccess>
                <Grid container spacing={10}>
                  <GridUploadInfo item xs={6} sm={6}>
                    <SpanTitle>Nome do arquivo: {validarTexto(_io.nomeArquivo, 'não foi possível identificar')}</SpanTitle>
                    <SpanSize>Tamanho do arquivo: {validarTexto(_io.tamanhoArquivo + ' bytes', 'não foi possível identificar')}</SpanSize>
                  </GridUploadInfo>
                  <GridUploadInfoIndicador item xs={5} sm={5}>
                    {_io.uploadSucesso && <SpanTitle>Carregamento completo</SpanTitle>}
                  </GridUploadInfoIndicador>
                </Grid>
              </DropSuccess>
            )}
          </DropContainer>
        )}
      </Dropzone>
      <TemplateContainer>
        <Grid container spacing={2} align-items="center">
          <Grid item>
            <DownloadButton href={planilhaPadraoUrl}>
              <img alt="upload icon" src={DownloadIcon} />
            </DownloadButton>
          </Grid>
          <Grid item>
            <Typography variant="subtitle2">Primeira vez?</Typography>
            <TypographyTemplateBody variant="body2">{planilhaPadraoTexto}</TypographyTemplateBody>
          </Grid>
        </Grid>
      </TemplateContainer>

      <Grid container spacing={2} justifyContent="flex-end">
        <Grid item>
          <Botao textoBotao="Cancelar" cor="primaria" estilo="outlined" onClick={_io.handleCancelar} />
        </Grid>
        <Grid item>
          <Botao onClick={_io.handleConfirmar} textoBotao="Confirmar" estilo="contained" cor="primaria" />
        </Grid>
      </Grid>
    </Container>
  )
}
