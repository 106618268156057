import { useEffect, useState } from 'react'
import { controleClassificacoesStyles } from './ControleClassificacoes.styles'
import controleInadimplenciaManager from '../../services/api/controleInadimplencia/controleInadimplenciaManager'
import { toast } from 'react-toastify'
import msgConstants from '../../constants/msgConstants'
import {
  agruparClassificacaoPorMes,
  filtrarDadosClassificados,
  filtrarDadosSemClassificacao,
  gerarDadosGraficoDonuts,
  unirParceirosPedidosClassificados
} from '../../utils/tratarDadosMesesPedidosClassificadosUtils'
import { DadosMeses, gerarDadosGrafico } from '../../utils/tratarDadosMesesUtils'
import { PAGES } from '../../router/routes'
import { ClassificacoesMensais, ResumoPedidosPerdasClassificacao } from '../../types/perdasTypes'
import debitosManager from '../../services/api/debitos/debitosManager'

export function controleClassificacoesIO() {
  const [resumoClassificacoesPedidos, setResumoClassificacoesPedidos] = useState<ResumoPedidosPerdasClassificacao[]>([])
  const [parceiroSelecionado, setParceiroSelecionado] = useState(0)
  const [mesesSelecionado, setMesesSelecionado] = useState<6 | 3>(6)
  const [mesGraficoSelecionadoIndex, setMesGraficoSelecionadoIndex] = useState(0)
  const [abaIndex, setAbaIndex] = useState(0)
  const [carregando, setCarregando] = useState(false)
  const [isModalResumoMesAberto, setIsModalResumoMesAberto] = useState(false)

  useEffect(() => {
    setCarregando(true)

    if (abaIndex === 0) {
      buscarResumoClassificacoesPedidosDebitos()
      return
    }

    buscarResumoClassificacoesPedidosInadimplentes()
  }, [abaIndex])

  async function buscarResumoClassificacoesPedidosInadimplentes() {
    await controleInadimplenciaManager
      .buscarResumoPedidosClassificadosInadimplencia()
      .then((response) => {
        setResumoClassificacoesPedidos(response)
      })
      .catch(() => toast.error(msgConstants.USO_GENERICO.erroBusca))
      .finally(() => setCarregando(false))
  }

  async function buscarResumoClassificacoesPedidosDebitos() {
    await debitosManager
      .buscarResumoPedidosClassificadosDebitos()
      .then((response) => {
        setResumoClassificacoesPedidos(response)
      })
      .catch(() => toast.error(msgConstants.USO_GENERICO.erroBusca))
      .finally(() => setCarregando(false))
  }

  function filtrarPedidosClassificadosPorParceiro(): DadosMeses[] {
    const pedidosMeses: ClassificacoesMensais[] = filtrarParceiro()

    return filtrarDadosClassificados(pedidosMeses)
  }

  function filtrarPedidosNaoClassificadosPorParceiro(): DadosMeses[] {
    const pedidosMeses = filtrarParceiro()

    return filtrarDadosSemClassificacao(pedidosMeses)
  }

  function filtrarParceiro() {
    let pedidosMeses: ClassificacoesMensais[] = []

    if (parceiroSelecionado !== 0) {
      pedidosMeses = resumoClassificacoesPedidos.filter((classificacoesPedidosPorParceiro) => {
        return classificacoesPedidosPorParceiro.parceiroId === parceiroSelecionado
      })[0].classificacoesMensais
    } else {
      pedidosMeses = unirParceirosPedidosClassificados(resumoClassificacoesPedidos)
    }

    return pedidosMeses
  }

  function handleClickMes(mesSelecionado: 6 | 3) {
    setMesGraficoSelecionadoIndex(0)
    setMesesSelecionado(mesSelecionado)
  }

  function handleClickParceiro(parceiroId: number) {
    setParceiroSelecionado(parceiroId)
  }

  function handleChangeAba(event: React.ChangeEvent<{}>, novaAba: number) {
    setAbaIndex(novaAba)
  }

  function handleAbrirModalResumoMes(mes: number) {
    setMesGraficoSelecionadoIndex(mes)
    setIsModalResumoMesAberto(true)
  }

  function handleFecharModalResumoMes() {
    setIsModalResumoMesAberto(false)
  }

  function handleDetalhesPedidosBotao(): string {
    if (abaIndex === 0) {
      return PAGES.detalheDebito('Total', parceiroSelecionado)
    }

    return PAGES.detalheInadimplencia(parceiroSelecionado, 'Total')
  }

  function gerarDadosGraficoDonut(mesSelecionadoIndex?: number) {
    const pedidosFiltradosPorParceiro = filtrarParceiro()
    const pedidosFiltradosAgrupadosPorMes = agruparClassificacaoPorMes(pedidosFiltradosPorParceiro)

    if (mesSelecionadoIndex === undefined) return gerarDadosGraficoDonuts(pedidosFiltradosAgrupadosPorMes, mesesSelecionado)

    return gerarDadosGraficoDonuts(pedidosFiltradosAgrupadosPorMes, mesesSelecionado, mesSelecionadoIndex)
  }

  const pedidosClassificadosFiltradosPorParceiro = filtrarPedidosClassificadosPorParceiro()
  const pedidosNaoClassificadosFiltradosPorParceiro = filtrarPedidosNaoClassificadosPorParceiro()

  return {
    styles: controleClassificacoesStyles,
    handleClickParceiro,
    parceiroSelecionado,
    handleClickMes,
    handleChangeAba,
    isModalResumoMesAberto,
    handleFecharModalResumoMes,
    handleAbrirModalResumoMes,
    abaIndex,
    mesGraficoSelecionadoIndex,
    carregando,
    handleDetalhesPedidosBotao,
    dadosGraficoBarraClassificados: gerarDadosGrafico(pedidosClassificadosFiltradosPorParceiro, mesesSelecionado),
    dadosGraficoBarraNaoClassificados: gerarDadosGrafico(pedidosNaoClassificadosFiltradosPorParceiro, mesesSelecionado),
    dadosGraficoDonut: gerarDadosGraficoDonut(),
    dadosGraficoDonutModal: gerarDadosGraficoDonut(mesGraficoSelecionadoIndex)
  }
}

export type ControleClassificacoesIO = ReturnType<typeof controleClassificacoesIO>
