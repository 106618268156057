import { IOProps } from 'react-compose-io'
import { PopoverFiltroPossiveisTratativasIO } from './PopoverFiltroPossiveisTratativas.IO'
import { TextField } from '@material-ui/core'

export type PopoverFiltroPossiveisTratativasProps = {
  popoverAberto: null | HTMLElement
  handleClosePopover: () => void
  isPossivelTratativaChecked: (tratativa: string) => boolean
  handleCheckPossivelTratativa: (tratativa: string) => void
  possiveisTratativas: string[]
}

export function PopoverFiltroPossiveisTratativasView({
  _io,
  popoverAberto,
  handleClosePopover,
  possiveisTratativas
}: IOProps<PopoverFiltroPossiveisTratativasIO, PopoverFiltroPossiveisTratativasProps>) {
  const { ContainerPopover, Popover } = _io.styles

  return (
    <Popover
      keepMounted
      anchorEl={popoverAberto}
      open={Boolean(popoverAberto)}
      onClose={handleClosePopover}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
    >
      <ContainerPopover>
        <TextField onChange={_io.handleChangePossivelTratativaInput} label="Buscar possível tratativa" variant="outlined" size="small" fullWidth />
        {possiveisTratativas.filter(_io.filtrarTratativas).map(_io.renderTratativasFiltradas)}
      </ContainerPopover>
    </Popover>
  )
}
