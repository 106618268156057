import { Breadcrumbs } from '@material-ui/core'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { customTheme } from '../../styles/customTheme'

export const breadCrumbsStyles = {
  Breadcrumbs: styled(Breadcrumbs)`
    margin-bottom: 16px;
  `,
  Link: styled(Link)`
    text-decoration: none;
    color: ${customTheme.colors.grey25};
    :hover {
      opacity: 0.8;
      color: ${customTheme.colors.blue16};
      text-decoration: underline;
    }
  `
}
