import React from 'react'
import { IOProps } from 'react-compose-io'
import { Tabela } from '../../../../components/Tabela'
import { HeadCell } from '../../../../interfaces/interfaces'
import { formateCurrency } from '../../../../utils/stringUtils'
import { TabelaRegularizacaoEfetivacaoIO } from './TabelaRegularizacaoEfetivacao.IO'

export type TabelaRegularizacaoEfetivacaoProps = {
  informacoesTabela: any[]
  handleSelectAllClick?: (event: React.ChangeEvent<HTMLInputElement>) => void
  tituloTabela: string
  cabecalhoTabela: HeadCell[]
  valorTotalTabela: number
  linhaTabela(props: { item: any }): JSX.Element
  totalSelecionados: number
}

export function TabelaRegularizacaoEfetivacaoView({
  _io,
  informacoesTabela,
  tituloTabela,
  cabecalhoTabela,
  handleSelectAllClick,
  valorTotalTabela,
  linhaTabela,
  totalSelecionados
}: IOProps<TabelaRegularizacaoEfetivacaoIO, TabelaRegularizacaoEfetivacaoProps>) {
  const { ContainerAlinhamento, Container, TituloTabela, ContainerTabela, TituloValorTotal, TextoValorTotal, ContainerValorTotal } = _io.styles

  return (
    <Container>
      <TituloTabela>{tituloTabela}</TituloTabela>
      <ContainerAlinhamento>
        <ContainerTabela>
          <Tabela
            cabecalho={cabecalhoTabela}
            itensLista={informacoesTabela}
            LinhaTabela={linhaTabela}
            orderCampoPadrao={'pedidoId'}
            rowsPerPageOptions={[100, 200, 1000]}
            linhasPorPaginaPadrao={100}
            maxHeight={'none'}
            alinharTextoCentro={false}
            semPaginacao={true}
            isCheckbox={true}
            handleSelectAllClick={handleSelectAllClick}
            totalSelecionados={totalSelecionados}
          />
        </ContainerTabela>
        <ContainerValorTotal>
          <TituloValorTotal>Total de títulos selecionado:</TituloValorTotal>
          <TextoValorTotal>{formateCurrency(valorTotalTabela)}</TextoValorTotal>
        </ContainerValorTotal>
      </ContainerAlinhamento>
    </Container>
  )
}
