import { Backdrop, Fade } from '@material-ui/core'
import { IOProps } from 'react-compose-io'
import { FormValuesSequencias } from '../../../../types/iniciarNovoCicloTypes'
import { Sequencias } from '../../../../types/tipoLancamentoApiTypes'
import { DadosSequencias } from '../DadosSequenciais'
import { ModalAddContaIO } from './ModalAddConta.IO'

export type ModalAddContaProps = {
  sequencias: Sequencias[]
  fecharModal: () => void
  abrirModal: boolean
  submeterValoresSequencias: (e: FormValuesSequencias) => void
  tituloModal: string
}

function ModalAddContaView({ _io, fecharModal, abrirModal, sequencias, tituloModal }: IOProps<ModalAddContaIO, ModalAddContaProps>) {
  const { ModalContainer, Paper, BoxContainer, TextSpan, Close, BoxColorBlue } = _io.styles
  return (
    <ModalContainer
      open={abrirModal}
      onClose={fecharModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
    >
      <Fade in={abrirModal}>
        <Paper>
          <BoxContainer>
            <TextSpan>{tituloModal}</TextSpan>
            <Close onClick={fecharModal} />
          </BoxContainer>
          <BoxColorBlue>
            <DadosSequencias
              sequencias={sequencias}
              handleClose={fecharModal}
              handleSubmitForm={_io.handleSubmit(_io.onSubmit)}
              controlForm={_io.control}
              handleChangeForm={_io.handleChangeForm}
              handleErros={_io.errors}
            />
          </BoxColorBlue>
        </Paper>
      </Fade>
    </ModalContainer>
  )
}
export default ModalAddContaView
