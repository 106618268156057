import React, { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import msgConstants from '../../../../constants/msgConstants'
import parceiroManager from '../../../../services/api/parceiro/parceiroManager'
import statusEfacilManager from '../../../../services/api/statusEfacil/statusEfacilManager'
import statusTrackingManager from '../../../../services/api/statusTracking/statusTrackingManager'
import { loadingReset, loadingUpdate } from '../../../../store/actions/loadingAction'
import { ObterListaStatusParceiroResponse } from '../../../../types/parceiroApiTypes'
import { StatusEfacil } from '../../../../types/statusEfacilTypes'
import { StatusTracking } from '../../../../types/statusTrackingTypes'
import { tabStatus } from './TabStatus.style'

export function tabStatusIO() {
  const dispatch = useDispatch()
  const [openCadastro, setOpenCadastro] = useState(false)
  const [listaStatusParceiro, setListaStatusParceiro] = useState<ObterListaStatusParceiroResponse[]>([])
  const [statusTrackingList, setStatusTrackingList] = useState<StatusTracking[]>([])
  const [statusEfacilList, setStatusEfacilList] = useState<StatusEfacil[]>([])
  const { parceiroId } = useParams<{ parceiroId: string }>()

  const atualizarDados = useCallback(async () => {
    dispatch(loadingUpdate(true))
    await parceiroManager
      .listarStatusParceiro(parceiroId)
      .then((response: ObterListaStatusParceiroResponse[]) => setListaStatusParceiro(response))
      .catch(() => toast.error(msgConstants.TIPO_LANCAMENTO_PARCEIRO.erroBusca))
      .finally(() => dispatch(loadingReset()))
  }, [dispatch, parceiroId])

  const buscarStatusTracking = async () => {
    await statusTrackingManager
      .buscarStatusTracking()
      .then((response: StatusTracking[]) => setStatusTrackingList(response))
      .catch((error) => toast.error(error.message))
  }

  const buscarStatusEfacil = async () => {
    await statusEfacilManager
      .buscarStatusEfacil()
      .then((response: StatusEfacil[]) => setStatusEfacilList(response))
      .catch((error) => toast.error(error.message))
  }

  React.useEffect(() => {
    atualizarDados()
    buscarStatusTracking()
    buscarStatusEfacil()
  }, [atualizarDados])

  function abrirModalCadastro() {
    setOpenCadastro(true)
  }

  function fecharModalCadastro() {
    setOpenCadastro(false)
  }

  return {
    styles: tabStatus,
    openCadastro,
    listaStatusParceiro,
    abrirModalCadastro,
    fecharModalCadastro,
    atualizarDados,
    parceiroId,
    statusTrackingList,
    statusEfacilList
  }
}

export type TabStatusIO = ReturnType<typeof tabStatusIO>
