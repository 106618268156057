import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import { toast } from 'react-toastify'
import msgConstants from '../constants/msgConstants'
import { history } from '../history'
import { ErrorsResponse } from '../types/api'

export const SISTEMA_INDISPONIVEL = 'Sistema Indisponível, tente novamente mais tarde'

const criarContentTypeConfig = (config?: AxiosRequestConfig): AxiosRequestConfig => ({
  headers: {
    'Content-Type': 'application/json',
    ...((config && config.headers) || {})
  },
  withCredentials: true,
  ...(config || {})
})

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL
})

instance.interceptors.response.use(
  async (response: AxiosResponse): Promise<any> => {
    if (response.status >= 200 && response.status < 300) {
      return response
    }
  },
  (error: AxiosError) => {
    const { response }: { response?: AxiosResponse; request?: XMLHttpRequest } = error
    if (response) {
      if (response.status === 401 || response.status === 403) {
        toast.warning('Sua sessão expirou!', { type: 'warning' })
        return history.push('/login')
      }
      if (response.status === 404) {
        toast.error(msgConstants.LOGIN.paginaNaoEncontrada, { type: 'error' })
        return history.push('/404')
      }
      if (response.status >= 400 && response.status <= 499) {
        const responseTratada = response as AxiosResponse<ErrorsResponse>

        return Promise.reject(Error(`° ${responseTratada?.data.mensagens.join('\n° ')}`))
      }
    }
    return Promise.reject(Error(msgConstants.USO_GENERICO.erro_generico))
  }
)

function conectarGet<T>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
  return instance.get<T>(url, criarContentTypeConfig(config)).catch((err) => Promise.reject(err))
}

function conectarPost<T>(url: string, body: unknown = {}, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
  return instance.post<T>(url, body, criarContentTypeConfig(config)).catch((err) => Promise.reject(err))
}

function conectarPut<T>(url: string, body: unknown = {}, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
  return instance.put<T>(url, body, criarContentTypeConfig(config)).catch((err) => Promise.reject(err))
}

function conectarPatch<T>(url: string, body: unknown = {}, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
  return instance.patch<T>(url, body, criarContentTypeConfig(config)).catch((err) => Promise.reject(err))
}

function conectarDelete<T>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
  return instance.delete<T>(url, criarContentTypeConfig(config)).catch((err) => Promise.reject(err))
}

export default {
  instance,
  conectarGet,
  conectarPost,
  conectarPut,
  conectarDelete,
  conectarPatch
}
