import { IOProps } from 'react-compose-io'
import { CheckListItemIO } from './CheckListItem.IO'
import { Checkbox } from '@material-ui/core'
import { ChipClassificacaoPerda } from './../ChipClassificacaoPerda/ChipClassificacaoPerda'
import { Tipografia } from '../Tipografia'
import { corChip, corClassificacaoChip, corClassificacaoLetra, corLetraChip } from '../../styles/customTheme'

export type CheckiListItemProps = {
  handleCheck: (itemSelecionadoId: string | number) => void
  isChecked: boolean
  id: string | number
  texto: string
  Icon?: () => JSX.Element
  isChip?: boolean
  isPedidoControleComissao?: boolean
}

export function CheckListItemView({
  _io,
  handleCheck,
  isChecked,
  texto,
  id,
  isChip,
  Icon,
  isPedidoControleComissao
}: IOProps<CheckListItemIO, CheckiListItemProps>) {
  const { ContainerTexto } = _io.styles

  return (
    <div>
      <Checkbox onChange={() => handleCheck(id)} checked={isChecked} />
      {isChip ? (
        <ChipClassificacaoPerda
          label={texto}
          bgcolor={isPedidoControleComissao ? corClassificacaoChip.get(texto) : corChip.get(texto)}
          fontcolor={isPedidoControleComissao ? corClassificacaoLetra.get(texto) : corLetraChip.get(texto)}
        />
      ) : (
        <ContainerTexto>
          {Icon && <Icon />}

          <Tipografia>{texto}</Tipografia>
        </ContainerTexto>
      )}
    </div>
  )
}
