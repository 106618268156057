import { Grid } from '@material-ui/core'
import { IOProps } from 'react-compose-io'
import { Control, DeepMap, FieldError, UseFormHandleSubmit, UseFormSetValue } from 'react-hook-form'
import { CardValorQuantidade } from '../../../../components/CardValorQuantidade'
import { BuscarTitulosForm, CardTitulos, CardTitulosParceiro } from '../../../../types/titulosTypes'
import { CabecalhoListaParceiros } from '../CabecalhoListaParceiros/index'
import { GraficoBarraSemanas } from '../GraficoBarraSemanas/index'
import { GraficoDonut } from '../GraficoDonut'
import { ListaTitulosParceiros } from '../ListaTitulosParceiros/index'
import { TitulosLiquidadosIO } from './TitulosLiquidados.IO'

export type TitulosLiquidadosProps = {
  setDataInicialTituloLiquidado: React.Dispatch<React.SetStateAction<string>>
  setDataFinalTituloLiquidado: React.Dispatch<React.SetStateAction<string>>
  setCarregarBotao: React.Dispatch<React.SetStateAction<boolean>>
  dataFinalTituloLiquidado: string
  dataInicialTituloLiquidado: string
  carregarBotao: boolean
  controleFormulario: Control<BuscarTitulosForm>
  setValue: UseFormSetValue<BuscarTitulosForm>
  dataInicialAlteradaFormulario: string
  dataFinalAlteradaFormulario: string
  handleSubmite: UseFormHandleSubmit<BuscarTitulosForm>
  gerarRelatorio: (buscarRelatorio: () => void, dataInicio: string, parceiroId?: number, dataFim?: string) => Promise<void>
  erroPreenchimento: DeepMap<BuscarTitulosForm, FieldError>
}

export function TitulosLiquidadosView({
  _io,
  carregarBotao,
  controleFormulario,
  handleSubmite,
  dataFinalTituloLiquidado,
  dataInicialTituloLiquidado,
  erroPreenchimento
}: IOProps<TitulosLiquidadosIO, TitulosLiquidadosProps>) {
  const { CardCabecalho, ContainerBranco } = _io.styles

  return (
    <div>
      <CabecalhoListaParceiros
        controleFormulario={controleFormulario}
        buscarTitulos={handleSubmite(_io.handleBuscarTitulos)}
        atualizarTitulos={_io.atualizarDadosDataAtual}
        botaoCarregando={carregarBotao}
        tituloTela="Títulos Liquidados"
        titulosAbertos={false}
        tituloDataFinal=""
        tituloDataInicial="Período"
        erroPreenchimento={erroPreenchimento}
        componenteId="titulosLiquidados"
      />
      {_io.tituloLiquidado.resumoGeral && _io.tituloLiquidado.resumoParceiros.length > 0 && (
        <div id="titulosLiquidados">
          <CardCabecalho>
            <Grid container spacing={2}>
              {_io.tituloLiquidado.resumoGeral &&
                _io.mapearResumoGeralLiquidado(CardTitulos.fromLiquidados(_io.tituloLiquidado.resumoGeral)).map((titulosGeral, indexTitulosGeral) => (
                  <Grid item xs={4} key={indexTitulosGeral}>
                    <CardValorQuantidade
                      textoTooltip={titulosGeral.tooltip}
                      tituloCard={titulosGeral.titulo}
                      valorTotal={titulosGeral.valor}
                      exibirBotaoExportar={titulosGeral.primeiroCard}
                      quantidade={titulosGeral.quantidade}
                      quantidadeTexto={'Qtd. de títulos'}
                      dataPeriodo={''}
                      valorPesquisado={false}
                      exportarRelatorioParceiro={() => {
                        _io.exportarRelatorioPeriodo(dataInicialTituloLiquidado, undefined, dataFinalTituloLiquidado)
                      }}
                      status={titulosGeral.status}
                      url={titulosGeral.url}
                      somenteQuantidade={false}
                    />
                  </Grid>
                ))}
            </Grid>
          </CardCabecalho>
          <GraficoBarraSemanas listaTitulosParceiros={_io.tituloLiquidado.semanas} />
          <ContainerBranco>
            <Grid container spacing={2}>
              <Grid item md={12} lg={8}>
                <>
                  <ListaTitulosParceiros
                    tituloCard="Títulos liquidados por parceiros"
                    dataFinalFormulario={dataFinalTituloLiquidado}
                    dataInicialFormulario={dataInicialTituloLiquidado}
                    valorPesquisado={false}
                    listaParceiros={
                      _io.tituloLiquidado.resumoParceiros && _io.tituloLiquidado.resumoParceiros?.map((resumo) => CardTitulosParceiro.fromLiquidados(resumo))
                    }
                    exportarRelatorioPeriodo={_io.exportarRelatorioPeriodo}
                    titulosAbertos={false}
                  />
                </>
              </Grid>
              <Grid item md={12} lg={4}>
                <div>
                  <GraficoDonut
                    dataGrafico={''}
                    textoValorTotal="Títulos liquidados"
                    listaTitulosParceiros={
                      _io.tituloLiquidado.resumoParceiros && _io.tituloLiquidado.resumoParceiros?.map((resumo) => CardTitulosParceiro.fromLiquidados(resumo))
                    }
                  />
                </div>
              </Grid>
            </Grid>
          </ContainerBranco>
        </div>
      )}
    </div>
  )
}
