import { TableRow } from '@material-ui/core'
import styled from 'styled-components'

export const tabelaContabilizacaoStyles = {
  Container: styled.div`
    margin-top: 16px;
    padding: 8px;
  `,
  TableRowBody: styled(TableRow)`
    cursor: pointer;
  `
}
