import { Menu, TableCell, TableRow } from '@material-ui/core'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import React, { ReactNode } from 'react'
import { IOProps } from 'react-compose-io'
import { EditarItemTabela } from '../../../../components/EditarItemTabela/EditarItemTabela'
import IconeButton from '../../../../components/IconeButton/IconeButton'
import { TipoLancamentoParceiro } from '../../../../types/parceiroApiTypes'
import { ModalTipoLancamentoParceiro } from '../ModalTipoLancamentoParceiro/index'
import { ItemTipoLancamentoIO } from './ItemTipoLancamento.IO'

export type ItemTipoLancamentoProps = {
  linhaItemTipoLancamentoParceiro: TipoLancamentoParceiro
  indexLinhaItemTipoLancamento: number
  parceiroId?: string
  atualizarDados: () => void
}

export function ItemTipoLancamentoView({
  _io,
  linhaItemTipoLancamentoParceiro,
  indexLinhaItemTipoLancamento,
  atualizarDados
}: IOProps<ItemTipoLancamentoIO, ItemTipoLancamentoProps>) {
  return (
    <React.Fragment>
      <ModalTipoLancamentoParceiro
        dadosLinha={linhaItemTipoLancamentoParceiro}
        isShow={_io.abrirModalEditar}
        isClosed={_io.fecharModalEdicao}
        atualizarDados={atualizarDados}
        tituloModal="Editar tipo de lançamento parceiro"
      />
      <TableRow key={indexLinhaItemTipoLancamento}>
        <TableCell align="center">{linhaItemTipoLancamentoParceiro.tipoLancamentoParceiroId}</TableCell>
        <TableCell align="center">{linhaItemTipoLancamentoParceiro.descricao}</TableCell>
        <TableCell align="center">{_io.getTipoContabilizacaoId(linhaItemTipoLancamentoParceiro?.tipoContabilizacaoId)}</TableCell>
        <TableCell align="center">{linhaItemTipoLancamentoParceiro.ativo ? 'Ativo' : 'Inativo'}</TableCell>
        <TableCell align="center">{linhaItemTipoLancamentoParceiro.sigla}</TableCell>

        <TableCell align="center">
          <div>
            <IconeButton color="inherit" descricao="ações" icone={<MoreVertIcon />} acao={_io.handleAtivarIconeAncora} />
            <Menu id="simple-menu" anchorEl={_io.ativarIconeAncora} keepMounted open={Boolean(_io.ativarIconeAncora)} onClose={_io.handleFecharIconeAncora}>
              {EditarItemTabela(linhaItemTipoLancamentoParceiro, _io.handleAtivarIconeAncoraModalEdicao, _io.salvarEdicaoTipoLancamentoParceiro).map(
                (itemMenu: ReactNode) => {
                  return itemMenu
                }
              )}
            </Menu>
          </div>
        </TableCell>
      </TableRow>
    </React.Fragment>
  )
}
