import { Box, Grid } from '@material-ui/core'
import { Botao } from './../Botao/index'

type CustomActionsButtonsProps = {
  hasPreview?: boolean
  handlePreview?: () => void
  hasCancel?: boolean
  handleCancelar?: () => void
  hasSave?: boolean
  handleSave?: () => void
  hasSaveContinue?: boolean
  handleSaveContinue?: () => void
}

const CustomActionsButtons = ({
  hasPreview,
  hasCancel,
  hasSave,
  hasSaveContinue,
  handlePreview,
  handleCancelar,
  handleSave,
  handleSaveContinue
}: CustomActionsButtonsProps) => {
  return (
    <Grid container item justifyContent="space-between">
      {hasPreview && (
        <Box>
          <Grid item>
            <Botao estilo="outlined" cor="primaria" textoBotao=" Pré-visualizar" onClick={handlePreview} />
          </Grid>
        </Box>
      )}
      <Box>
        <Grid container item spacing={2}>
          {hasCancel && (
            <Grid item>
              <Botao estilo="text" cor="primaria" textoBotao="Cancelar" onClick={handleCancelar} />
            </Grid>
          )}
          {hasSave && (
            <Grid item>
              <Botao type="submit" estilo="outlined" cor="primaria" textoBotao="Salvar" onClick={handleSave} />
            </Grid>
          )}
          {hasSaveContinue && (
            <Grid item>
              <Botao type="submit" estilo="contained" cor="primaria" textoBotao="Salvar e continuar" onClick={handleSaveContinue} />
            </Grid>
          )}
        </Grid>
      </Box>
    </Grid>
  )
}

export { CustomActionsButtons }
