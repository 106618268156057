const TRANSFORMACAO = {
  titulo: 'Regras de padronização de planilha',
  cartaoTitulo: 'Planilha parceiro',
  cartaoSubtitulo: 'Faça o upload da planilha do parceiro que deseja padronizar.',
  formularioTituloNovaRegra: 'Nova regra',
  formularioTituloEditarRegra: 'Editar Regra',
  formularioSubtitulo: 'Abaixo você conseguirá selecionar os campos do template padrão e adicionar condições/ações para que a padronização aconteça.',
  formularioAcao: 'Adicione a ação que deseja para que esta regra funcione.',
  formularioCondicao: 'Adicione condições para essa regra, se houver.',
  formularioTipoTemplate: 'Quando o campo do template padrão for'
}

const TRANSFORMACAO_INFO = {
  tituloRegra: 'Adicione um título para regra que você irá criar',
  tipoPlanilha: 'Selecione o tipo de planilha linhas/colunas',
  tipoTemplate: 'Selecione aqui o campo que vai ser considerado para a condição e ação',
  prioridade: 'Informe a prioridade da regra',
  grupoRegra: 'Selecione o grupo que esta regra está vinculado, pode ser mais de um',
  condicao: {
    tipoCondicao: 'Selecione aqui a coluna da planilha do parceiro que deseja adicionar condições',
    tipoOperacaoCondicao: 'Selecione a operação que deseja para a condição desta regra',
    tipoParametroCondicao: 'Insira aqui o texto/valor que será o critério da condição'
  },
  acao: {
    tipoOperacaoAcao: 'Selecione a operação que deseja para a ação desta regra',
    aritmetica: 'Selecione a operação para {0} linhas/colunas ou valores',
    concatenar: 'Selecione a operação para unir no ínicio ou no fim um critério',
    considerar: 'Selecione a operação para considerar um critério',
    separador: 'Selecione a operação para separar por vírgula ou ponto uma coluna',
    substituir: 'Substituir uma coluna por um critério',
    parametroColuna: 'Selecione a coluna que deseja da planilha do parceiro',
    parametroCriterio: 'Insira aqui o texto/valor que será o critério da ação',
    tipoParametro: 'Selecione coluna planilha do parceiro ou critério digitável'
  }
}

const TRANSFORMACAO_MODAL = {
  titulo: 'Pré visualização de transformação'
}

const TRANSFORMACAO_DICA = {
  dica: 'Clique aqui para ver dicas que podem te ajudar.'
}

const TRANSFORMACAO_LABELS_ACAO = {
  dado: 'Coluna'
}

export { TRANSFORMACAO, TRANSFORMACAO_INFO, TRANSFORMACAO_DICA, TRANSFORMACAO_MODAL, TRANSFORMACAO_LABELS_ACAO }
