import { Grid, Typography } from '@material-ui/core'
import { IOProps } from 'react-compose-io'
import { CardComissaoIO } from './CardComissao.IO'

type CardComissaoProps = {
  backgroundColor: string
  icon: string
  quantidade: number
  descricao: string
}

export function CardComissaoView({ _io, backgroundColor, icon, quantidade, descricao }: IOProps<CardComissaoIO, CardComissaoProps>) {
  const { Box } = _io.styles

  return (
    <>
      <Grid item md={4} xs={4} sm={4}>
        <Box background={backgroundColor}>
          <Grid container spacing={2} direction="row" alignItems="center" justifyContent="center">
            <Grid item>
              <img src={icon} />
            </Grid>
            <Grid item>
              <Typography variant="h4">{quantidade.toLocaleString('pt-BR')}</Typography>
              <Typography style={{ fontWeight: 400, fontSize: '14px', lineHeight: '15px' }} variant="body2">
                incidentes
              </Typography>
              <Typography style={{ fontWeight: 400, fontSize: '14px', lineHeight: '15px' }} variant="body2">
                {descricao}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </>
  )
}
