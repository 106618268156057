import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { LancamentoState } from '../../../../interfaces/interfaces'
import { closeModalDesativar, efetivarDesativacao, lancamentoUpdate } from '../../../../store/actions/lancamentoAction'
import { TipoLancamento } from '../../../../types/tipoLancamentoApiTypes'
import { modalDesativarStyles } from './ModalDesativar.style'

export function modalDesativarIO() {
  const dispatch = useDispatch()
  const { lancamentoObj } = useSelector((state: LancamentoState) => state.lancamento)
  const { descricao } = useSelector((state: LancamentoState) => state.lancamento.lancamentoObj)
  const abrirModal = useSelector((state: LancamentoState) => state.lancamento.modalDesativar.open)
  const [tipoLancamento, setTipoLancamento] = useState<TipoLancamento>(lancamentoObj)

  const handleDesativar = () => {
    setTipoLancamento({ ...lancamentoObj, status: 'Desativado' })
    dispatch(lancamentoUpdate(tipoLancamento))
    dispatch(efetivarDesativacao())
    dispatch(closeModalDesativar())
  }

  const handleCancelar = () => {
    dispatch(closeModalDesativar())
  }

  return {
    styles: modalDesativarStyles,
    tipoLancamento,
    handleDesativar,
    handleCancelar,
    fecharModal: () => dispatch(closeModalDesativar()),
    abrirModal,
    descricao
  }
}

export type ModalDesativarIO = ReturnType<typeof modalDesativarIO>
