import { CollapseInadimplenciaTableIO } from './CollapseInadimplenciaTable.IO'
import { IOProps } from 'react-compose-io'
import { Grid, Table, TableBody } from '@material-ui/core'
import { Tipografia } from './../../../../components/Tipografia/index'
import { customTheme } from '../../../../styles/customTheme'
import { CollapseInadimplenciaItem } from './../CollapseInadimplenciaItem/index'
import { formateCurrency } from '../../../../utils/stringUtils'
import { DadosMeses } from '../../../../utils/tratarDadosMesesUtils'

export type CollapseInadimplenciaTableProps = {
  inadimplencias: DadosMeses[]
}

export function CollapseInadimplenciaTableView({ _io }: IOProps<CollapseInadimplenciaTableIO, CollapseInadimplenciaTableProps>) {
  const { TableTituloContainer, TableContainer, TableFooter } = _io.styles

  return (
    <>
      <TableContainer>
        <TableTituloContainer>
          <Tipografia colorText={customTheme.colors.white01} fontWeight="500">
            Visualização rápida
          </Tipografia>
        </TableTituloContainer>
        <Table size="small">
          <TableBody>
            {_io.dadosAgrupados.map((dadoAgrupado) => (
              <CollapseInadimplenciaItem
                key={dadoAgrupado.ano}
                inadimplenciasPorAno={dadoAgrupado}
                setControleCollapse={_io.setControleCollapse}
                controleCollapse={_io.controleCollapse}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TableFooter>
        <Grid container justifyContent="space-between" spacing={3}>
          <Grid item>
            <Tipografia fontWeight="500">Total</Tipografia>
          </Grid>
          <Grid item>
            <Tipografia fontWeight="500">{formateCurrency(_io.valorTotal)}</Tipografia>
            <Tipografia fontWeight="400" variant="body2">
              {`${_io.quantidadeTotal.toLocaleString('pt-BR')} títulos`}
            </Tipografia>
          </Grid>
        </Grid>
      </TableFooter>
    </>
  )
}
