import { Grid, TextField } from '@material-ui/core'
import { IOProps } from 'react-compose-io'
import { Controller } from 'react-hook-form'
import { CustomDialog } from '../../../../components/CustomDialog/CustomDialog'
import { ModalNovoCicloIO } from './ModalNovoCiclo.IO'
import { IniciarNovoCicloForm } from '../../../../types/ciclosApiType'
import { Botao } from '../../../../components/Botao'

export type ModalNovoCicloProps = {
  isShow: boolean
  isClosed: () => void
  handleConfirmar: (data: IniciarNovoCicloForm) => Promise<void>
}

export default function ModalNovoCicloView({ _io, isShow }: IOProps<ModalNovoCicloIO, ModalNovoCicloProps>) {
  return (
    <CustomDialog
      open={isShow}
      onClose={_io.fecharModal}
      id="modal-iniciar-novo-ciclo"
      titulo="Iniciar novo ciclo"
      habilitarAcoes
      fecharHabilitado
      fecharModal={_io.fecharModal}
      actions={
        <Grid container spacing={2} alignContent="center" justifyContent="center">
          <Grid item>
            <Botao textoBotao="Cancelar" onClick={_io.fecharModal} estilo="text" cor="primaria" width="156px" />
          </Grid>
          <Grid item>
            <Botao textoBotao="Confirmar" onClick={_io.handleSubmit(_io.submit)} estilo="rounded" cor="primaria" width="156px" />
          </Grid>
        </Grid>
      }
    >
      <form>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Controller
              render={({ field }) => (
                <TextField
                  required
                  {..._io.register('dataInicialParceiro', { required: true })}
                  {...field}
                  label="Data inicial do ciclo"
                  variant="outlined"
                  size="small"
                  type="date"
                  focused
                  error={_io.errors?.dataInicialParceiro ? true : false}
                  helperText={_io.errors.dataInicialParceiro?.message}
                  fullWidth
                />
              )}
              name="dataInicialParceiro"
              control={_io.control}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Controller
              render={({ field }) => (
                <TextField
                  required
                  {..._io.register('dataFinalPrevistaParceiro', { required: true })}
                  {...field}
                  label="Data final do ciclo"
                  variant="outlined"
                  size="small"
                  type="date"
                  focused
                  fullWidth
                  error={_io.errors?.dataFinalPrevistaParceiro ? true : false}
                  helperText={_io.errors.dataFinalPrevistaParceiro?.message}
                />
              )}
              name="dataFinalPrevistaParceiro"
              control={_io.control}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Controller
              render={({ field }) => <TextField {...field} label="Comentários" variant="outlined" size="small" fullWidth />}
              name="comentariosCicloParceiro"
              control={_io.control}
            />
          </Grid>
        </Grid>
      </form>
    </CustomDialog>
  )
}
