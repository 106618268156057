import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import parceiroManager from '../../services/api/parceiro/parceiroManager'
import transportadorasManager from '../../services/api/transportadoras/transportadorasManager'
import { loadingReset, loadingUpdate } from '../../store/actions/loadingAction'
import { Parceiro, ParceiroRequest } from '../../types/parceiroApiTypes'
import { Transportadora } from '../../types/transportadoras'
import { formatarData } from '../../utils/stringUtils'
import { cadastrarNovoParceiroStyles } from './CadastrarNovoParceiro.style'

export function cadastrarNovoParceiroIO() {
  const router = useHistory()
  const dispatch = useDispatch()
  const [parceiro, setParceiro] = useState<Parceiro>({} as Parceiro)
  const [transportadoras, setTransportadoras] = useState<Transportadora[]>([])
  const { parceiroId } = useParams<{ parceiroId: string }>()

  const { handleSubmit, control, setValue } = useForm({
    defaultValues: {
      parceiroId: 0,
      razaoSocial: '',
      nomeFantasia: '',
      periodicidade: 0,
      tipoPeriodicidadeId: '',
      ultimoRepasse: '',
      url: '',
      transportadorasId: [] as number[]
    }
  })

  useEffect(() => {
    async function buscarParceiro(buscarParceiroId: string) {
      const transportadorasResponse = await transportadorasManager.buscarTransportadoras()
      setTransportadoras(transportadorasResponse)
      const parceiroResponse = await parceiroManager.buscarDadoParceiro(buscarParceiroId)
      setParceiro(parceiroResponse)

      setValue('parceiroId', parceiroResponse.parceiroId)
      setValue('razaoSocial', parceiroResponse.razaoSocial)
      setValue('nomeFantasia', parceiroResponse.nomeFantasia)
      setValue('periodicidade', parceiroResponse.periodicidade ?? 0)
      setValue('tipoPeriodicidadeId', parceiroResponse.tipoPeriodicidadeId)
      setValue('ultimoRepasse', String(formatarData(parceiroResponse.ultimoRepasse)))
      setValue(
        'transportadorasId',
        parceiroResponse.transportadoras.map((transportadora) => transportadora.transportadoraId)
      )
      setValue('url', parceiroResponse.url)

      dispatch(loadingReset())
    }

    dispatch(loadingUpdate(true))
    buscarParceiro(parceiroId)
  }, [dispatch, parceiroId, setValue])

  async function salvarDadosParceiro(data: ParceiroRequest) {
    await parceiroManager
      .cadastrarDadosParceiros(data)
      .then(() => {
        dispatch(loadingUpdate(true))
        router.goBack()
        dispatch(loadingReset())
      })
      .catch((error: Error) => {
        toast.error(error.message)
        dispatch(loadingReset())
      })
  }

  function cancelarCadastro() {
    router.goBack()
  }

  return {
    styles: cadastrarNovoParceiroStyles,
    cancelarCadastro,
    handleSubmit,
    salvarDadosParceiro,
    control,
    parceiro,
    setValue,
    transportadoras
  }
}

export type CadastrarNovoParceiroIO = ReturnType<typeof cadastrarNovoParceiroIO>
