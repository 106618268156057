import { ModalClassificacoesPorMesIO } from './ModalClassificacoesPorMes.IO'
import { Grid } from '@material-ui/core'
import { IOProps } from 'react-compose-io'
import CifraoIcone from './../../../../assets/icons/cifrao.svg'
import CuboIcone from './../../../../assets/icons/cubo.svg'
import { formateCurrency } from '../../../../utils/stringUtils'
import { BoxValor } from './../../../../components/BoxValor/index'
import { ContainerSombra } from '../../../../components/ContainerSombra'
import { MESES_ABREVIADOS_2_MESES } from '../../../../constants/utilConstants'
import DataEmpty from '../../../../components/DataEmpty/DataEmpty'
import { GraficoDonutClassificacoes } from '../GraficoDonutClassificacoes'
import { DadosGraficoDonut } from '../../../../utils/tratarDadosMesesPedidosClassificadosUtils'

interface DadosGrafico {
  valor: number[]
  quantidade: number[]
  nomeMeses: string[][]
}

export type ModalClassificacoesPorMesProps = {
  isAberto: boolean
  handleFecharModal: () => void
  dadosPedidosClassificados: DadosGrafico
  dadosPedidosNaoClassificados: DadosGrafico
  dadosGraficoDonut: DadosGraficoDonut
  mesSelecionadoIndex: number
}

export function ModalClassificacoesPorMesView({
  _io,
  isAberto,
  handleFecharModal,
  dadosPedidosClassificados,
  dadosPedidosNaoClassificados,
  mesSelecionadoIndex,
  dadosGraficoDonut
}: IOProps<ModalClassificacoesPorMesIO, ModalClassificacoesPorMesProps>) {
  const { CustomDialogCustomWidth, TituloModal, GridWidthLimitada } = _io.styles

  return (
    <CustomDialogCustomWidth
      titulo={`${MESES_ABREVIADOS_2_MESES.get(dadosPedidosNaoClassificados.nomeMeses[mesSelecionadoIndex][0])}`}
      id={'modal-class-mes'}
      open={isAberto}
      fecharHabilitado
      fecharModal={handleFecharModal}
    >
      <Grid container justifyContent="center" alignItems="center" spacing={2}>
        <Grid item>
          <ContainerSombra>
            <Grid item container justifyContent="center" alignContent="center" direction="column" spacing={1}>
              <Grid item>
                <TituloModal variant="h6">Pedidos classificados</TituloModal>
              </Grid>
              <Grid item>
                <BoxValor
                  icone={CifraoIcone}
                  descricao={'Valor total classificado'}
                  valor={formateCurrency(dadosPedidosClassificados.valor[mesSelecionadoIndex])}
                  tipoSombra={'none'}
                />
              </Grid>
              <Grid item>
                <BoxValor
                  icone={CuboIcone}
                  descricao={'Quantidade total de pedidos classificados'}
                  valor={dadosPedidosClassificados.quantidade[mesSelecionadoIndex].toLocaleString('pt-BR')}
                  tipoSombra={'none'}
                />
              </Grid>
            </Grid>
          </ContainerSombra>
        </Grid>
        <Grid item>
          <ContainerSombra>
            <Grid item container justifyContent="center" alignContent="center" direction="column" spacing={1}>
              <Grid item>
                <TituloModal variant="h6">Pedidos não classificados</TituloModal>
              </Grid>
              <Grid item>
                <BoxValor
                  icone={CifraoIcone}
                  descricao={'Valor total não classificado'}
                  valor={formateCurrency(dadosPedidosNaoClassificados.valor[mesSelecionadoIndex])}
                  tipoSombra={'none'}
                />
              </Grid>
              <Grid item>
                <BoxValor
                  icone={CuboIcone}
                  descricao={'Quantidade total de pedidos não classificados'}
                  valor={dadosPedidosNaoClassificados.quantidade[mesSelecionadoIndex].toLocaleString('pt-BR')}
                  tipoSombra={'none'}
                />
              </Grid>
            </Grid>
          </ContainerSombra>
        </Grid>
        <GridWidthLimitada item lg={12}>
          {dadosGraficoDonut.classificacoes.length > 0 ? (
            <GraficoDonutClassificacoes
              tituloGrafico={'Quantidade de Classificações'}
              quantidades={dadosGraficoDonut.quantidade}
              classificacoes={dadosGraficoDonut.classificacoes}
              valores={dadosGraficoDonut.valor}
              tamanhoGrafico={'174px'}
            />
          ) : (
            <ContainerSombra>
              <DataEmpty descricao="Ainda não foram feitas nenhuma classificação para o mês selecionado" />
            </ContainerSombra>
          )}
        </GridWidthLimitada>
      </Grid>
    </CustomDialogCustomWidth>
  )
}
