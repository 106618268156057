import { Avatar, Backdrop } from '@material-ui/core'
import styled from 'styled-components'
import { ContainerSombra } from '../../components/ContainerSombra'

export const controleCicloPedidoStyles = {
  Container: styled(ContainerSombra)`
    flex-grow: 1;
    padding: 24px;
    margin-top: 16px;
    position: relative;
  `,
  Avatar: styled(Avatar)`
    height: 64px;
    width: 64px;

    .MuiAvatar-img {
      height: 54px;
      width: 64px;
      object-position: top;
      object-fit: cover;
    }
  `,
  CabecalhoContainer: styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
  `,
  LimitedBackdrop: styled(Backdrop)`
    position: absolute;
    z-index: 1;
    border-radius: 10px;
  `,
  ContainerButtons: styled.div`
    display: flex;
    gap: 8px;
    margin-bottom: 8px;
  `
}
