import { Box, TableCell, TableRow } from '@material-ui/core'
import styled from 'styled-components'
import { customTheme } from '../../../../styles/customTheme'

export const itemControleComissaoStyles = {
  TableRowHistorico: styled(TableRow)`
    background-color: ${customTheme.colors.blue01};
  `,
  TableCellHistorico: styled(TableCell)`
    padding-bottom: 0px;
    padding-top: 0px;
  `,
  BoxStatus: styled(Box)`
    padding: 8px;
    border-radius: 4px;
    text-align: -webkit-center;
    background-color: ${(props: { background: string; color: string }) => props.background};
    color: ${(props: { background: string; color: string }) => props.color};
  `,
  BoxHistorico: styled(Box)`
    margin: 8px;
  `
}
