import { yupResolver } from '@hookform/resolvers/yup'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { RegraTransformacao } from '../../../../types/transformacaoApiTypes'
import { modalPriorizarStyles } from './ModalPriorizar.styles'
import { ModalPriorizarProps } from './ModalPriorizar.View'
import TransformacaoManager from '../../../../services/api/transformacao/transformacaoManager'
import { loadingReset, loadingUpdate } from '../../../../store/actions/loadingAction'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import msgConstants from '../../../../constants/msgConstants'

export function modalPriorizarIO({ isClosed, regrasList, parceiroId, atualizarDados }: ModalPriorizarProps) {
  const [regrasFiltradas, setRegrasFiltradas] = useState<RegraTransformacao[]>([])
  const [progresso, setProgresso] = useState(1)
  const dispatch = useDispatch()

  const schema = yup
    .object({
      campo: yup.string().required('Campo nome é obrigatório')
    })
    .required()

  const {
    handleSubmit,
    control,
    formState: { errors },
    register,
    setValue
  } = useForm<{ campo: string }>({
    defaultValues: {
      campo: ''
    },
    resolver: yupResolver(schema)
  })

  const fecharModal = () => {
    isClosed()
    setRegrasFiltradas([])
    setProgresso(1)
    setValue('campo', '')
  }

  const voltarPriorizacao = () => {
    setRegrasFiltradas([])
    setProgresso(1)
    setValue('campo', '')
  }

  const avancarPriorizacao = (dadosForm: { campo: string }) => {
    const regrasPorCampo = regrasList.filter((regra) => regra.campo === dadosForm.campo)
    const regrasOrdernadas = [...regrasPorCampo].sort((a, b) => a.prioridade - b.prioridade)
    setRegrasFiltradas(regrasOrdernadas)
    setProgresso(4)
  }

  const atualizarPriorizacao = async () => {
    dispatch(loadingUpdate(true))

    const reordenarPrioridadeRequest = regrasFiltradas.map((regra, index) => {
      return { regraId: regra.regraId, prioridade: index + 1 }
    })

    await TransformacaoManager.reordenarPrioridade(parceiroId, reordenarPrioridadeRequest)
      .then(() => {
        fecharModal()
        toast.success(msgConstants.USO_GENERICO.edicao_sucesso)
        atualizarDados()
      })
      .catch(() => {
        toast.error(msgConstants.USO_GENERICO.edicao_erro)
        dispatch(loadingReset())
      })
  }

  return {
    styles: modalPriorizarStyles,
    schema,
    register,
    control,
    handleSubmit,
    errors,
    fecharModal,
    atualizarPriorizacao,
    avancarPriorizacao,
    regrasFiltradas,
    setRegrasFiltradas,
    progresso,
    voltarPriorizacao
  }
}

export type ModalPriorizarIO = ReturnType<typeof modalPriorizarIO>
