import { Typography } from '@material-ui/core'
import styled from 'styled-components'

type TipografiaStyles = {
  weight?: string
  size?: string
  lineheight?: string
  colortext?: string
  textalign?: string
}

export const tipografiaStyles = {
  Typography: styled(Typography)`
    font-size: ${({ size }: TipografiaStyles) => size};
    font-weight: ${({ weight }: TipografiaStyles) => weight};
    line-height: ${({ lineheight }: TipografiaStyles) => lineheight};
    color: ${({ colortext }: TipografiaStyles) => colortext};
    text-align: ${({ textalign }: TipografiaStyles) => textalign};
  `
}
