import styled from 'styled-components'
import { customTheme } from '../../styles/customTheme'
import { Tipografia } from './../../components/Tipografia/index'

export const parametrosRepasseStyles = {
  ContainerCircle: styled.div`
    border: 1px solid ${customTheme.colors.primary};
    border-radius: 80px;
    height: 45px;
    width: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  ContainerCabecalho: styled.div`
    margin: 24px 0px;
  `,
  Titulo: styled(Tipografia).attrs({
    variant: 'h5',
    fontWeight: '500'
  })`
    margin: 0;
  `
}
