import {
  AtualizarDivergenciasPedidosMktRequest,
  DivergenciaPedidoMktResponse,
  ObterDivergentesResponse,
  ObterDivergenciasPedidosMktQuery
} from '../../../types/cicloPedidosTypes'
import { ResponsePaginado } from '../../../types/genericoType'
import { concatenarFiltrosQuery } from '../../../utils/stringUtils'
import api from '../../axiosInstance'

const buscarDivergencias = async (parceiroId: number | string): Promise<ObterDivergentesResponse> => {
  const URL_BUSCAR_DIVERGENCIAS = `CicloPedidos/Parceiros/${parceiroId}/Divergencias`
  const response = await api.conectarGet<ObterDivergentesResponse>(URL_BUSCAR_DIVERGENCIAS)
  return response.data
}

const atualizarDivergenciasTela = async (parceiroId: number | string): Promise<number> => {
  const URL_ATUALIZAR_DIVERGENCIAS_TELA = `CicloPedidos/Parceiros/${parceiroId}/Divergencias/Atualizar`
  const response = await api.conectarPost<number>(URL_ATUALIZAR_DIVERGENCIAS_TELA)
  return response.status
}

const gerarDivergencias = async (parceiroId: number, planilha: FormData): Promise<boolean> => {
  const URL_GERAR_DIVERGENCIAS = `CicloPedidos/Parceiros/${parceiroId}/Divergencias`
  const response = await api.conectarPost<boolean>(URL_GERAR_DIVERGENCIAS, planilha, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })

  return response.data
}

const atualizarDivergenciasPedidosMkt = async (parceiroId: number | string, body: AtualizarDivergenciasPedidosMktRequest[]): Promise<boolean> => {
  const URL_ATUALIZAR_DIVERGENCIAS_PEDIDOS_MKT = `CicloPedidos/Parceiros/${parceiroId}/Divergencias/PedidosMkt`
  const response = await api.conectarPatch<boolean>(URL_ATUALIZAR_DIVERGENCIAS_PEDIDOS_MKT, body)
  return response.data
}

const buscarDivergenciasPedidosMkt = async (
  parceiroId: number | string,
  query: ObterDivergenciasPedidosMktQuery
): Promise<ResponsePaginado<DivergenciaPedidoMktResponse>> => {
  const URL_BUSCAR_DIVERGENCIAS_PEDIDOS_MKT = `CicloPedidos/Parceiros/${parceiroId}/Divergencias/PedidosMkt${concatenarFiltrosQuery(query)}`
  const response = await api.conectarGet<ResponsePaginado<DivergenciaPedidoMktResponse>>(URL_BUSCAR_DIVERGENCIAS_PEDIDOS_MKT)

  return response.data
}

const buscarDivergenciasRelatorio = async (parceiroId: number | string): Promise<string> => {
  const URL_BUSCAR_DIVERGENCIAS_RELATORIO = `CicloPedidos/Parceiros/${parceiroId}/Divergencias/Relatorio`
  const response = await api.conectarGet<string>(URL_BUSCAR_DIVERGENCIAS_RELATORIO)
  return response.data
}

export default {
  buscarDivergencias,
  gerarDivergencias,
  atualizarDivergenciasPedidosMkt,
  buscarDivergenciasPedidosMkt,
  buscarDivergenciasRelatorio,
  atualizarDivergenciasTela
}
