import { Grid } from '@material-ui/core'
import { IOProps } from 'react-compose-io'
import { customTheme } from '../../styles/customTheme'
import { ValidacaoPlanilhaResponse } from '../../types/regularizacaoTypes'
import { formateCurrency } from '../../utils/stringUtils'
import { ItensRegularizacaoContaResumo } from '../ItensRegularizacaoContaResumo'
import { InformacoesModalRegularizacaoSucessoIO } from './InformacoesModalRegularizacaoSucesso.IO'

export type InformacoesModalRegularizacaoSucessoProps = {
  validacaoPlanilhaResponse: ValidacaoPlanilhaResponse
  descricaoBaixas: string
}

export function InformacoesModalRegularizacaoSucessoView({
  _io,
  validacaoPlanilhaResponse,
  descricaoBaixas
}: IOProps<InformacoesModalRegularizacaoSucessoIO, InformacoesModalRegularizacaoSucessoProps>) {
  const { ContainerInformacoes, ContainerListaModal, Informacao, SubtituloInformacoes, TituloInformacoes, ContainerItensInformacoes } = _io.styles

  return (
    <Grid container>
      <ContainerListaModal xs={12} sm={12} md={12} lg={12} item alturaLista={_io.alturaContainerResumo}>
        <ItensRegularizacaoContaResumo
          corFundo={customTheme.colors.green01}
          descricaoBaixas={descricaoBaixas}
          contasResumos={validacaoPlanilhaResponse.resumos}
        />
      </ContainerListaModal>
      <ContainerInformacoes>
        <Grid container justifyContent="flex-start" alignItems="flex-start">
          <Grid xs={12} sm={12} md={12} lg={12} item>
            <TituloInformacoes>Total:</TituloInformacoes>
          </Grid>
          <Grid xs={12} sm={12} md={2} lg={2} item>
            <ContainerItensInformacoes>
              <SubtituloInformacoes>{descricaoBaixas}</SubtituloInformacoes>
              <Informacao>{validacaoPlanilhaResponse.total.quantidade}</Informacao>
            </ContainerItensInformacoes>
          </Grid>
          <Grid xs={12} sm={12} md={2} lg={2} item>
            <ContainerItensInformacoes>
              <SubtituloInformacoes>Contas contábeis</SubtituloInformacoes>
              <Informacao>{validacaoPlanilhaResponse.total.contasContabeis}</Informacao>
            </ContainerItensInformacoes>
          </Grid>
          <Grid xs={12} sm={12} md={2} lg={2} item>
            <ContainerItensInformacoes>
              <SubtituloInformacoes>Valor total</SubtituloInformacoes>
              <Informacao>{formateCurrency(validacaoPlanilhaResponse.total.valor)}</Informacao>
            </ContainerItensInformacoes>
          </Grid>
        </Grid>
      </ContainerInformacoes>
    </Grid>
  )
}
