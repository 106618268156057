import { useForm } from 'react-hook-form'
import { ModalRegularizarPerdasDebitoProps } from './ModalRegularizarPerdasDebito.View'
import debitosManager from '../../../../services/api/debitos/debitosManager'
import { EfetivarDebitoPerdaRequest } from '../../../../types/debitosTypes'
import { toast } from 'react-toastify'

export function modalRegularizarPerdasDebitoIO({ isClosed }: ModalRegularizarPerdasDebitoProps) {
  const {
    handleSubmit,
    control,
    setValue,
    register,
    formState: { isSubmitting, isDirty }
  } = useForm<{ mesLancamento: string }>({
    mode: 'onChange',
    defaultValues: {
      mesLancamento: ''
    }
  })

  const fecharModal = () => {
    isClosed()
    setValue('mesLancamento', '')
  }

  const handleEfetivarDebitosPerda = async (data: { mesLancamento: string }) => {
    const [anoSelecionado, mesSelecionado] = data.mesLancamento.split('-').map(Number)

    const request: EfetivarDebitoPerdaRequest = {
      mesSelecionado,
      anoSelecionado
    }

    await debitosManager
      .efetivarDebitoPerda(request)
      .then(() => fecharModal())
      .catch((error: Error) => toast.error('Erro ao chamar regularização de débitos perda: ' + error.message))
  }

  return {
    control,
    handleSubmit,
    fecharModal,
    handleEfetivarDebitosPerda,
    setValue,
    register,
    isSubmitting,
    isDirty
  }
}

export type ModalRegularizarPerdasDebitoIO = ReturnType<typeof modalRegularizarPerdasDebitoIO>
