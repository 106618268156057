import { IOProps } from 'react-compose-io'
import { TabelaControleComissaoPedidoIO } from './TabelaControleComissaoPedido.IO'
import { Checkbox, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@material-ui/core'
import { ReactNode, ChangeEvent } from 'react'
import { ComponentePaginacao } from '../Tabela/ComponentePaginacao'

export interface CabecalhoTabelaControleComissaoPedido {
  descricao: string
  abrirPopover?: (event: React.MouseEvent<HTMLButtonElement>) => void
}

export type TabelaControleComissaoPedidoProps = {
  cabecalhoTabela: CabecalhoTabelaControleComissaoPedido[]
  checklistsSelecionados: string[]
  handleSelectAllCheckList: () => void
  children: ReactNode
  quantidadeTotalControleComissaoPedidos: number
  pagina: number
  linhasPorPagina: number
  handleMudarPagina: (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) => void
  handleMudarTamanhoPagina: (event: ChangeEvent<HTMLInputElement>) => void
}

export function TabelaControleComissaoPedidoView({
  _io,
  cabecalhoTabela,
  handleSelectAllCheckList,
  quantidadeTotalControleComissaoPedidos,
  handleMudarPagina,
  handleMudarTamanhoPagina,
  pagina,
  linhasPorPagina,
  children
}: IOProps<TabelaControleComissaoPedidoIO, TabelaControleComissaoPedidoProps>) {
  const { ContainerTabela } = _io.styles

  return (
    <ContainerTabela>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center" size="small"></TableCell>
              <TableCell align="center" size="small">
                <Checkbox onChange={handleSelectAllCheckList} checked={_io.checkListTotalChecked} indeterminate={_io.checkListTotalIndeterminado} />
              </TableCell>
              {cabecalhoTabela.map(_io.renderCabecalho)}
            </TableRow>
          </TableHead>
          <TableBody>{children}</TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component={'div'}
        rowsPerPageOptions={[5, 10, 25]}
        count={quantidadeTotalControleComissaoPedidos}
        rowsPerPage={linhasPorPagina}
        page={pagina}
        onPageChange={handleMudarPagina}
        onRowsPerPageChange={handleMudarTamanhoPagina}
        ActionsComponent={ComponentePaginacao}
      />
    </ContainerTabela>
  )
}
