import { Grid } from '@material-ui/core'
import styled from 'styled-components'
import Banner from '../../assets/images/login/banner.svg'
import { Tipografia } from './../../components/Tipografia/index'

const ImageStyled = styled.img`
  height: 400px;
`
const GridContainer = styled(Grid)`
  height: 100%;
`

export default function Dashboard() {
  return (
    <GridContainer container justifyContent="center" direction="column" alignItems="center">
      <ImageStyled src={Banner} alt="Banner" loading="lazy" />
      <Tipografia variant="h6">Disponível em breve</Tipografia>
    </GridContainer>
  )
}
