import styled from 'styled-components'
import { customTheme } from '../../../../styles/customTheme'
import { Typography } from '@material-ui/core'

export const itemListaParceirosStyles = {
  TituloParceiro: styled(Typography).attrs({
    variant: 'body2'
  })`
    font-style: normal;
    font-weight: 400;
    line-height: 23px;
    color: ${customTheme.colors.black};
  `,
  SubtituloParceiro: styled(Typography).attrs({
    variant: 'body2'
  })`
    font-style: normal;
    font-weight: 400;
    line-height: 23px;
    color: ${customTheme.colors.black};
    margin: 0 12px;
  `,
  ContainerLinha: styled.div`
    display: flex;
  `,
  ContainerColuna: styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
  `,
  ContainerColunaAlinhado: styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: start;
  `,
  ContainerDadosParceiro: styled.div`
    display: flex;
    margin-top: 12px;
    align-items: center;
    margin-left: 20px;
  `,
  TituloCard: styled(Typography).attrs({
    variant: 'body2'
  })`
    font-style: normal;
    font-weight: 500;
    line-height: 23px;
    color: ${customTheme.colors.black};
  `,
  ValorData: styled(Typography).attrs({
    variant: 'caption'
  })`
    font-weight: 400;
    line-height: 23px;
    color: ${customTheme.colors.grey03};
  `,
  SubtituloItem: styled(Typography).attrs({
    variant: 'caption'
  })`
    font-style: normal;
    font-weight: 400;
    font-size: ${customTheme.fontSize[10]};
    line-height: 23px;
    color: ${customTheme.colors.black};
  `
}
