import React, { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import msgConstants from '../../constants/msgConstants'
import { ORDEM_CRESCENTE } from '../../constants/utilConstants'
import parceiroManager from '../../services/api/parceiro/parceiroManager'
import { loadingReset, loadingUpdate } from '../../store/actions/loadingAction'
import { TipoLancamentoParceiro } from '../../types/parceiroApiTypes'
import { Order } from '../../types/tabelaTypes'
import { handleRequestSort, tratarMudancaLinhaPorPagina, tratarMudancaPagina } from '../../utils/tabelaUtils'
import { lancamentoAssociacaoStyles } from './LancamentoAssociacao.style'

interface IStateForm {
  descricao: string
  tipoContabilizacaoId: string
  sigla: string
}

export function lancamentoAssociacaoIO() {
  const dispatch = useDispatch()
  const historico = useHistory<number | string | undefined | any>()
  const [novosLancamentosList, setNovosLancamentosList] = useState<any>({})
  const [tipoLancamentoList, setTipoLancamentoList] = useState<TipoLancamentoParceiro[]>([])
  const [pagina, setPagina] = useState(0)
  const [linhasPorPagina, setLinhasPorPagina] = useState(5)
  const { parceiroId } = useParams<{ parceiroId: string }>()
  const [orderBy, setOrderBy] = React.useState<keyof never>('tipoLancamentoId')
  const [order, setOrder] = React.useState<Order>(ORDEM_CRESCENTE)
  const [change, setChange] = useState('')
  const { handleSubmit, control, register, reset, watch, setValue } = useForm<IStateForm>({
    defaultValues: {
      descricao: '',
      tipoContabilizacaoId: '',
      sigla: ''
    }
  })

  const valorPadraoTipoLancamento = watch('tipoContabilizacaoId')

  useEffect(() => {
    if (change == valorPadraoTipoLancamento) return
    if (valorPadraoTipoLancamento == 'V') {
      setChange(valorPadraoTipoLancamento)
      return setValue('sigla', 'MAN')
    }
    setChange(valorPadraoTipoLancamento)
    return setValue('sigla', '')
  }, [valorPadraoTipoLancamento, change, setValue])

  const fetchData = useCallback(async () => {
    if (parceiroId) {
      dispatch(loadingUpdate(true))
      const response: TipoLancamentoParceiro[] = await parceiroManager.buscarTipoLancamentoParceiro(parceiroId)
      setTipoLancamentoList(response)
      dispatch(loadingReset())
    }
  }, [dispatch, parceiroId])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  useEffect(() => {
    const { item } = historico.location.state

    if (item) setNovosLancamentosList(item)
  }, [historico.location.state])

  async function salvarTipoLancamento(item: any) {
    const { parceiro } = historico.location.state
    if (parceiro) {
      await parceiroManager
        .cadastrarTipolancamentoParceiros(parceiro, item)
        .then(() => {
          toast.success(msgConstants.USO_GENERICO.cadastro_sucesso)
          return setTipoLancamentoList([...tipoLancamentoList, item])
        })
        .catch((error: Error) => {
          dispatch(loadingReset())
          toast.error(error.message, { type: 'error' })
        })
        .finally(() => {
          dispatch(loadingReset())
        })
      reset()
    }
  }

  const handleUpdateStatus = () => fetchData()

  return {
    styles: lancamentoAssociacaoStyles,
    novosLancamentosList,
    register,
    control,
    tipoLancamentoList,
    pagina,
    linhasPorPagina,
    handleUpdateStatus,
    onMudancaPagina: (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
      tratarMudancaPagina(event, newPage, setPagina)
    },
    onMudancaLinhasPorPagina: (event: React.ChangeEvent<HTMLInputElement>) => {
      tratarMudancaLinhaPorPagina(event, setPagina, setLinhasPorPagina)
    },
    handleSubmit,
    salvarTipoLancamento,
    onRequestSort: handleRequestSort(orderBy, order, setOrder, setOrderBy),
    orderBy,
    order
  }
}
export type LancamentoAssociacaoIO = ReturnType<typeof lancamentoAssociacaoIO>
