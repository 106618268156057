import { Grid } from '@material-ui/core'
import styled from 'styled-components'
import { ContainerSombra } from '../../../../components/ContainerSombra'
import { customTheme } from '../../../../styles/customTheme'

export const lancamentoVariaveisStyles = {
  Container: styled(ContainerSombra)`
    width: 100%;
    margin-top: 19px;
    background-color: ${customTheme.colors.red00};
    flex-grow: 1;
    padding: 18px;
  `,
  GridLine: styled(Grid).attrs({
    item: true,
    xs: 12,
    sm: 12,
    display: 'flex',
    justifyContent: 'space-between'
  })`
    display: flex;
    justify-content: space-between;
    align-items: end;
  `,
  GridHeader: styled(Grid).attrs({
    container: true,
    spacing: 2,
    sm: 12,
    display: 'flex',
    justifyContent: 'space-between'
  })`
    display: flex;
    justify-content: space-between;
  `,
  SubtitleTable: styled.div`
    color: ${customTheme.colors.grey04};
    font-weight: 400;
    font-size: ${customTheme.fontSize[14]};
    line-height: 16px;
    opacity: 0.8;
    margin-top: 8px;
  `,
  ContainerBotao: styled.div`
    display: flex;
    flex-direction: 'row';
    gap: 12px;
  `
}
