import { IOProps } from 'react-compose-io'
import { ControleClassificacoesIO } from './ControleClassificacoes.IO'
import { corParceiroId } from '../../styles/customTheme'
import { Cabecalho } from './components/Cabecalho'
import { AntTabs } from './../../components/AntTabs/AntTabs.styles'
import { AntTab } from '../../components/AntTab/AntTab.style'
import TabPanel from '../../components/TabPainel/TabPanel'
import { CircularProgress, Grid } from '@material-ui/core'
import { VALOR_ESCALA_GRAFICO } from '../../constants/utilConstants'
import { GraficoValorQuantidadeClassificacoes } from './components/GraficoValorQuantidadeClassificacoes/index'
import { GraficoDonutClassificacoes } from './components/GraficoDonutClassificacoes'
import { Botao } from '../../components/Botao'
import { ModalClassificacoesPorMes } from './components/ModalClassificacoesPorMes'
import { NavLink } from 'react-router-dom'

export type ControleClassificacoesProps = {}

export function ControleClassificacoesView({ _io }: IOProps<ControleClassificacoesIO, ControleClassificacoesProps>) {
  const { Container, ContainerGridGraficos } = _io.styles

  return (
    <>
      <Cabecalho parceiroSelecionado={_io.parceiroSelecionado} handleClickParceiro={_io.handleClickParceiro} handleClickMes={_io.handleClickMes} />
      <Container cor={corParceiroId.get(_io.parceiroSelecionado)}>
        <AntTabs value={_io.abaIndex} onChange={_io.handleChangeAba} aria-label="abas classificacao perdas">
          <AntTab label="Débitos" />
          <AntTab label="Inadimplência" />
        </AntTabs>
        <TabPanel value={_io.abaIndex} index={_io.abaIndex}>
          {_io.carregando ? (
            <ContainerGridGraficos container justifyContent="center">
              <CircularProgress />
            </ContainerGridGraficos>
          ) : (
            <ContainerGridGraficos container spacing={2}>
              <Grid item sm={12}>
                <GraficoValorQuantidadeClassificacoes
                  titulo={'Pedidos classificados'}
                  tituloGrafico={`Valor de pedidos classificados`}
                  valoresMensais={_io.dadosGraficoBarraClassificados.valor}
                  legendaMeses={_io.dadosGraficoBarraClassificados.nomeMeses}
                  legendaEixoY=""
                  divisorLegenda={VALOR_ESCALA_GRAFICO.UM}
                  descricaoValor={`Valor total classificado`}
                  valor={_io.dadosGraficoBarraClassificados.valor.reduce((total, valor) => total + valor, 0)}
                  descricaoQuantidade={`Quantidade total de pedidos classificados`}
                  quantidade={_io.dadosGraficoBarraClassificados.quantidade.reduce((total, quantidade) => total + quantidade, 0)}
                  handleClickColuna={(mes: number) => _io.handleAbrirModalResumoMes(mes)}
                />
              </Grid>

              <Grid item sm={12}>
                <GraficoValorQuantidadeClassificacoes
                  titulo={'Pedidos não classificados'}
                  tituloGrafico={`Valor de pedidos não classificados`}
                  valoresMensais={_io.dadosGraficoBarraNaoClassificados.valor}
                  legendaMeses={_io.dadosGraficoBarraNaoClassificados.nomeMeses}
                  legendaEixoY=""
                  divisorLegenda={VALOR_ESCALA_GRAFICO.UM}
                  descricaoValor={`Valor total não classificado`}
                  valor={_io.dadosGraficoBarraNaoClassificados.valor.reduce((total, valor) => total + valor, 0)}
                  descricaoQuantidade={`Quantidade total de pedidos não classificados`}
                  quantidade={_io.dadosGraficoBarraNaoClassificados.quantidade.reduce((total, quantidade) => total + quantidade, 0)}
                  handleClickColuna={(mes: number) => _io.handleAbrirModalResumoMes(mes)}
                />
              </Grid>

              <Grid item sm={12}>
                <GraficoDonutClassificacoes
                  tituloGrafico={'Quantidade de Classificações'}
                  quantidades={_io.dadosGraficoDonut.quantidade}
                  classificacoes={_io.dadosGraficoDonut.classificacoes}
                  valores={_io.dadosGraficoDonut.valor}
                  tamanhoGrafico={'174px'}
                />
              </Grid>

              <Grid item>
                <NavLink to={_io.handleDetalhesPedidosBotao}>
                  <Botao textoBotao="Ver detalhes dos pedidos" estilo="contained" />
                </NavLink>
              </Grid>
            </ContainerGridGraficos>
          )}
        </TabPanel>
      </Container>

      <ModalClassificacoesPorMes
        isAberto={_io.isModalResumoMesAberto}
        handleFecharModal={_io.handleFecharModalResumoMes}
        dadosPedidosClassificados={_io.dadosGraficoBarraClassificados}
        dadosPedidosNaoClassificados={_io.dadosGraficoBarraNaoClassificados}
        dadosGraficoDonut={_io.dadosGraficoDonutModal}
        mesSelecionadoIndex={_io.mesGraficoSelecionadoIndex}
      />
    </>
  )
}
