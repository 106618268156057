import { Typography } from '@material-ui/core'
import styled from 'styled-components'

export const tabStatus = {
  Container: styled.div`
    height: 100%;
    width: 100%;
  `,
  DivTab: styled.div`
    width: 100%;
    padding: 16px;
  `,
  Table: styled.table`
    width: 100%;
  `,
  Paper: styled.div`
    width: 100%;
    margin-bottom: 45px;
    padding: 15px;
  `,
  Pagination: styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
  `,
  TextoSemLancamento: styled(Typography).attrs({
    variant: 'h6'
  })`
    display: flex;
    text-align: center;
    justify-content: center;
    font-weight: bold;
  `
}
