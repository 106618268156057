import IconButton from '@material-ui/core/IconButton'
import { Theme, withStyles } from '@material-ui/core/styles'
import spacingInPixels from '../../utils/materialUtils'

export const useStyles = {
  IconButtonPaddingSpacing1: withStyles((theme: Theme) => ({
    root: {
      padding: spacingInPixels(theme, 1)
    }
  }))(IconButton)
}
