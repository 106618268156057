import { PreviewItemAssociacao, RegraAssociacao, RegraAssociacaoForm } from '../../../types/associacaoApiTypes'
import { Acao, Condicao } from '../../../types/regraApiType'
import instance from '../../axiosInstance'

const buscarCondicoes = async (): Promise<Condicao[]> => {
  const URL_BUSCAR_CONDICOES = '/Associacao/Regras/Operacoes/Condicoes'
  const response = await instance.conectarGet<Condicao[]>(URL_BUSCAR_CONDICOES)
  return response.data
}

const buscarAcoes = async (): Promise<Acao[]> => {
  const URL_BUSCAR_ACOES = '/Associacao/Regras/Operacoes/Acoes'
  const response = await instance.conectarGet<Acao[]>(URL_BUSCAR_ACOES)
  return response.data
}

const cadastrarRegra = async (body: RegraAssociacaoForm, parceiroId?: string | number): Promise<string> => {
  const URL_CADASTRAR_REGRA = `/Associacao/Parceiros/${parceiroId}/Regras`
  const response = await instance.conectarPost<string>(URL_CADASTRAR_REGRA, body)
  return response.data
}

const previewRegra = async (body: RegraAssociacaoForm, parceiroId?: string | number): Promise<PreviewItemAssociacao> => {
  const URL_PREVIEW_REGRA = `/Associacao/Parceiros/${parceiroId}/Preview`
  const response = await instance.conectarPost<PreviewItemAssociacao>(URL_PREVIEW_REGRA, body)
  return response.data
}

const editarRegra = async (body: RegraAssociacaoForm, parceiroId?: string | number, regraId?: number | string): Promise<string> => {
  const URL_EDITAR_REGRA = `/Associacao/Parceiros/${parceiroId}/Regras/${regraId}`
  const response = await instance.conectarPut<string>(URL_EDITAR_REGRA, body)
  return response.data
}

const aplicarAssociacao = async (parceiroId: number, arquivoId: string): Promise<string> => {
  const URL_APLICAR_ASSOCIACAO = `/Associacao/Parceiros/${parceiroId}/Arquivo/${arquivoId}/Aplicar`
  const response = await instance.conectarGet<string>(URL_APLICAR_ASSOCIACAO)
  return response.data
}

const buscarRegras = async (parceiroId?: string | number): Promise<RegraAssociacao[]> => {
  const URL_BUSCAR_REGRAS = `/Associacao/Parceiros/${parceiroId}/Regras`
  const response = await instance.conectarGet<RegraAssociacao[]>(URL_BUSCAR_REGRAS)
  return response.data
}

const buscarRegra = async (parceiroId: string | number, regraId: string | number): Promise<RegraAssociacao> => {
  const URL_BUSCAR_REGRA = `/Associacao/Parceiros/${parceiroId}/Regras/${regraId}`
  const response = await instance.conectarGet<RegraAssociacao>(URL_BUSCAR_REGRA)
  return response.data
}

const enviarArquivo = async (body: string[]): Promise<string> => {
  const URL_ENVIAR_ARQUIVO = `/Arquivo`
  const response = await instance.conectarPost<string>(URL_ENVIAR_ARQUIVO, body)
  return response.data
}

const aplicarAssociacaoCiclo = async (cicloId: string): Promise<string> => {
  const URL_ENVIAR_ARQUIVO = `/Associacao/Ciclos/${cicloId}`
  const response = await instance.conectarPost<string>(URL_ENVIAR_ARQUIVO)
  return response.data
}

export default {
  buscarCondicoes,
  buscarAcoes,
  cadastrarRegra,
  previewRegra,
  editarRegra,
  aplicarAssociacao,
  buscarRegras,
  enviarArquivo,
  aplicarAssociacaoCiclo,
  buscarRegra
}
