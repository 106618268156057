import { IconButton, TableCell, TableRow, Checkbox } from '@material-ui/core'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import { IOProps } from 'react-compose-io'
import { formateCurrency, formateDate } from '../../../../utils/stringUtils'
import { ItemControleComissaoPedidoIO } from './ItemControleComissaoPedido.IO'
import { corClassificacaoLetra, corClassificacaoChip } from '../../../../styles/customTheme'
import { ChipClassificacaoPerda } from './../../../../components/ChipClassificacaoPerda/ChipClassificacaoPerda'
import { ControleComissaoPedidoResponse } from '../../../../types/controleComissaoTypes'
import { DetalheControleComissaoPedido } from '../DetalheControleComissaoPedido'

export type ItemControleComissaoPedidoProps = {
  item: ControleComissaoPedidoResponse
  chave: string
}

export function ItemControleComissaoPedidoView({ _io, item, chave }: IOProps<ItemControleComissaoPedidoIO, ItemControleComissaoPedidoProps>) {
  const { ValorRecebido, TipografiaInline } = _io.styles
  return (
    <>
      <TableRow>
        <TableCell align="center" size="small">
          <IconButton size="small" onClick={_io.handleAbrirPedidoToggle}>
            {_io.pedidoAberto ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="center" size="small">
          <Checkbox checked={_io.isCheckListSelecionado(chave)} onChange={() => _io.handleClickCheckList(item.detalhes)} />
        </TableCell>
        <TableCell align="center" size="small">
          {item.parceiro}
        </TableCell>
        <TableCell align="center" size="small">
          {item.pedidoPlanilhaId}
        </TableCell>
        <TableCell align="center" size="small">
          <ValorRecebido valorpedido={item.valorTotalPedido} valorrecebido={item.valorTotalRecebido}>
            {formateCurrency(item.valorTotalRecebido)}
          </ValorRecebido>
          <TipografiaInline variant="inherit" fontWeight="500">{`  / ${formateCurrency(item.valorTotalPedido)}`}</TipografiaInline>
        </TableCell>
        <TableCell align="center" size="small">
          {formateCurrency(item.comissaoCobradaTotal)}
        </TableCell>
        <TableCell align="center" size="small">
          {formateCurrency(item.comissaoDevidaTotal)}
        </TableCell>
        <TableCell align="center" size="small">
          {formateCurrency(item.diferencaTotal)}
        </TableCell>
        <TableCell align="center" size="small">
          <ChipClassificacaoPerda
            label={item.classificacaoGeral}
            bgcolor={corClassificacaoChip.get(item.classificacaoGeral)}
            fontcolor={corClassificacaoLetra.get(item.classificacaoGeral)}
          />
        </TableCell>
        <TableCell align="center" size="small">
          {formateDate(item.dataEmissao)}
        </TableCell>
      </TableRow>
      <DetalheControleComissaoPedido controleComissaoPedidoChave={chave} detalhesControleComissaoPedido={item.detalhes} pedidoAberto={_io.pedidoAberto} />
    </>
  )
}
