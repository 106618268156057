import { popoverFiltroPedidoComissaoClassificacoesStyles } from './PopoverFiltroPedidoComissaoClassificacoes.styles'
import { PopoverFiltroPedidoComissaoClassificacoesProps } from './PopoverFiltroPedidoComissaoClassificacoes.View'
import { CheckListItem } from '../CheckListItem'
import { ClassificacaoControleComissaoPedidos } from '../../types/controleComissaoTypes'
export function popoverFiltroPedidoComissaoClassificacoesIO({
  handleCheckClassificacao,
  isClassificacaoChecked
}: PopoverFiltroPedidoComissaoClassificacoesProps) {
  const renderClassificacoes = (classificacao: ClassificacaoControleComissaoPedidos) => (
    <CheckListItem
      key={classificacao.value}
      id={classificacao.value}
      texto={classificacao.name}
      handleCheck={() => handleCheckClassificacao(classificacao.name)}
      isChecked={isClassificacaoChecked(classificacao.name)}
      isChip
      isPedidoControleComissao
    />
  )

  return {
    styles: popoverFiltroPedidoComissaoClassificacoesStyles,
    renderClassificacoes
  }
}

export type PopoverFiltroPedidoComissaoClassificacoesIO = ReturnType<typeof popoverFiltroPedidoComissaoClassificacoesIO>
