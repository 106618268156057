import { ResponsePaginado } from '../../../types/genericoType'
import { GerarRelatorioOPsEmAbertoResponse, ObterOPsEmAbertoQuery, OpsEmAberto, OpsEmAbertoResumo } from '../../../types/regularizacaoOpsTypes'
import { HistoricoRegularizacao, ValidacaoPlanilhaResponse } from '../../../types/regularizacaoTypes'
import { Paginacao } from '../../../types/tabelaTypes'
import RegularizacaoOPsService from './regularizacaoOPsService'

const efetivarRegularizacaoOPs = async (arquivoId: string): Promise<boolean> => RegularizacaoOPsService.efetivarRegularizacaoOPs(arquivoId)

const validarPlanilhaRegularizacaoOPs = async (arquivo: FormData): Promise<ValidacaoPlanilhaResponse> =>
  RegularizacaoOPsService.validarPlanilhaRegularizacaoOPs(arquivo)

const buscarHistoricoRegularizacaoOPs = async (paginacao: Paginacao): Promise<ResponsePaginado<HistoricoRegularizacao>> =>
  RegularizacaoOPsService.buscarHistoricoRegularizacaoOPs(paginacao)

const buscarOPsEmAberto = async (paginacao: Paginacao, query: ObterOPsEmAbertoQuery): Promise<ResponsePaginado<OpsEmAberto>> =>
  RegularizacaoOPsService.buscarOPsEmAberto(paginacao, query)

const buscarOPsEmAbertoResumo = async (): Promise<OpsEmAbertoResumo> => RegularizacaoOPsService.buscarOPsEmAbertoResumo()

const gerarRelatorioOPsEmAberto = async (query: ObterOPsEmAbertoQuery): Promise<GerarRelatorioOPsEmAbertoResponse> =>
  RegularizacaoOPsService.gerarRelatorioOPsEmAberto(query)

export default {
  buscarHistoricoRegularizacaoOPs,
  validarPlanilhaRegularizacaoOPs,
  efetivarRegularizacaoOPs,
  buscarOPsEmAberto,
  buscarOPsEmAbertoResumo,
  gerarRelatorioOPsEmAberto
}
