import { Collapse, Grid, IconButton, Menu, MenuItem, TableCell, TableRow, Typography } from '@material-ui/core'
import EditOutlinedIcon from '@material-ui/icons/EditOutlined'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import { DescriptionOutlined } from '@material-ui/icons'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { IOProps } from 'react-compose-io'
import BotaoExportarIcone from '../../../../assets/icons/external-link.svg'
import IconeButton from '../../../../components/IconeButton/IconeButton'
import { ControleComissao, ControleComissaoStatusMap } from '../../../../types/controleComissaoTypes'
import { cabecalhoControleComissao } from '../../../../types/tabelaTypes'
import { formateCurrency, formateDate, validarTexto } from '../../../../utils/stringUtils'
import { ModalControleComissaoStatus } from '../ModalControleComissaoStatus'
import { ItemControleComissaoIO } from './ItemControleComissao.IO'
import { ModalChamados } from '../ModalChamados'
import { corFundoStatus, corTextoStatus } from '../../ControleComissao.IO'
import { Tipografia } from '../../../../components/Tipografia'

export type ItemControleComissaoProps = {
  item: ControleComissao
  atualizarLista: () => void
}

export function ItemControleComissaoView({ _io, item, atualizarLista }: IOProps<ItemControleComissaoIO, ItemControleComissaoProps>) {
  const { TableRowHistorico, TableCellHistorico, BoxStatus, BoxHistorico } = _io.styles

  function ItemControleComissaoMenu() {
    return (
      <div>
        <IconeButton color="inherit" descricao="ações" icone={<MoreVertIcon />} acao={_io.handleAlterarIconeAncora} />
        <Menu id="simple-menu" anchorEl={_io.abrirMenu} keepMounted open={Boolean(_io.abrirMenu)} onClose={_io.handleAnularIconeAncora}>
          <MenuItem onClick={() => _io.handleAbrirModalChamados()}>
            <Grid container spacing={1}>
              <Grid item>
                <DescriptionOutlined />
              </Grid>
              <Grid item>Chamados</Grid>
            </Grid>
          </MenuItem>
          <MenuItem onClick={() => _io.abrirModalEditarStatus()}>
            <Grid container spacing={1}>
              <Grid item>
                <EditOutlinedIcon />
              </Grid>
              <Grid item>Alterar status</Grid>
            </Grid>
          </MenuItem>
          <MenuItem onClick={() => _io.exportarControleComissao()} disabled={item.urlRelatorio == null}>
            <Grid container spacing={1}>
              <Grid item>
                <img src={BotaoExportarIcone} />
              </Grid>
              <Grid item>Exportar</Grid>
            </Grid>
          </MenuItem>
        </Menu>
      </div>
    )
  }

  return (
    <>
      <ModalControleComissaoStatus
        handleConfirmarEdicao={_io.handleConfirmarEdicao}
        isModalAberto={_io.abrirModalEditar}
        setAbrirModal={_io.setAbrirModalEditar}
        dadosControleComissao={item}
      />
      <ModalChamados
        isCarregandoChamados={_io.isCarregandoChamados}
        isModalAberto={_io.abrirModalChamados}
        setAbrirModal={_io.setAbrirModalChamados}
        chamados={_io.chamados}
        controleComissaoCicloId={item.id}
        buscarChamados={_io.buscarChamados}
        atualizarListaControleComissao={atualizarLista}
      />
      <TableRow>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={_io.handleAbrirHistoricoToggle}>
            {_io.historicoAberto ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{validarTexto(item.parceiro?.descricao, '-')}</TableCell>
        <TableCell>{item.cicloId.toLocaleString('pt-BR')}</TableCell>
        <TableCell>{formateCurrency(item.comissaoCobrada)}</TableCell>
        <TableCell>{formateCurrency(item.comissaoDevida)}</TableCell>
        <TableCell>{formateCurrency(item.diferenca)}</TableCell>
        <TableCell>
          <BoxStatus background={corFundoStatus(item.statusAtual)} color={corTextoStatus(item.statusAtual)}>
            <Tipografia fontWeight="600" fontSize="14px">
              {ControleComissaoStatusMap.get(item.statusAtual) ?? '-'}
            </Tipografia>
          </BoxStatus>
        </TableCell>
        <TableCell align="center">{ItemControleComissaoMenu()}</TableCell>
      </TableRow>
      <TableRowHistorico>
        <TableCellHistorico colSpan={cabecalhoControleComissao.length}>
          <Collapse in={_io.historicoAberto} timeout="auto" unmountOnExit>
            <BoxHistorico>
              <Typography variant="subtitle2" gutterBottom component="div">
                Histórico
              </Typography>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  {item.listaStatus.map((status, index) => {
                    return (
                      <>
                        <Grid item xs={12} key={index}>
                          <Typography variant="body2">
                            <b>Data Alteração:</b> {formateDate(status.dataAlteracao, true)} | <b>Usuário: </b>
                            {validarTexto(status.funcionario?.nome, '-')} | <b>Status: </b>
                            {ControleComissaoStatusMap.get(status.status)}
                          </Typography>
                        </Grid>
                      </>
                    )
                  })}
                </Grid>
              </Grid>
            </BoxHistorico>
          </Collapse>
        </TableCellHistorico>
      </TableRowHistorico>
    </>
  )
}
