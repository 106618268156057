import styled from 'styled-components'
import { Grid } from '@material-ui/core'

export const formularioPedidoStyles = {
  GridCenter: styled(Grid)`
    flex-grow: 1;
  `,
  GridButton: styled(Grid)`
    flex: none;
  `
}
