import { ValorGerados, ValorGeralTitulosAbertos, ValorLiquidados } from '../../../types/titulosTypes'
import TitulosService from './titulosService'

const buscarTitulosAbertos = async (dataSelecionada: string, attManual = false): Promise<ValorGeralTitulosAbertos> =>
  TitulosService.buscarTitulosAbertos(dataSelecionada, attManual)

const exportarTitulosAbertos = async (dataSelecionada: string, parceiroId?: number): Promise<void> =>
  TitulosService.exportarTitulosAbertos(dataSelecionada, parceiroId)

const exportarTitulosLiquidados = async (dataSelecionada: string, parceiroId?: number, dataFim?: string): Promise<void> =>
  TitulosService.exportarTitulosLiquidados(dataSelecionada, parceiroId, dataFim)

const buscarTitulosLiquidados = async (dataInicio: string, dataFim: string, attManual = false): Promise<ValorLiquidados> =>
  TitulosService.buscarTitulosLiquidados(dataInicio, dataFim, attManual)

const buscarTitulosGerados = async (dataInicio: string, dataFim: string, attManual = false): Promise<ValorGerados> =>
  TitulosService.buscarTitulosGerados(dataInicio, dataFim, attManual)

const exportarTitulosGerados = async (dataInicio: string, parceiroId?: number, dataFim?: string): Promise<void> =>
  TitulosService.exportarTitulosGerados(dataInicio, parceiroId, dataFim)

export default {
  buscarTitulosAbertos,
  exportarTitulosAbertos,
  buscarTitulosLiquidados,
  exportarTitulosLiquidados,
  buscarTitulosGerados,
  exportarTitulosGerados
}
