import { Acao, Condicao } from '../../../types/regraApiType'
import {
  GrupoRegra,
  GrupoRegraCadastro,
  PreviewItemTransformacao,
  PriorizacaoRegras,
  RegraTransformacao,
  RegraTransformacaoForm
} from '../../../types/transformacaoApiTypes'
import TransformacaoService from './transformacaoService'

const buscarCondicoes = async (): Promise<Condicao[]> => TransformacaoService.buscarCondicoes()

const buscarAcoes = async (): Promise<Acao[]> => TransformacaoService.buscarAcoes()

const cadastrarRegra = async (body: RegraTransformacaoForm, parceiroId?: string | number): Promise<string> =>
  TransformacaoService.cadastrarRegra(body, parceiroId)

const editarRegra = async (body: RegraTransformacaoForm, parceiroId?: string | number, regraId?: string | number): Promise<string> =>
  TransformacaoService.editarRegra(body, parceiroId, regraId)

const aplicarTransformacao = async (parceiroId: number, arquivoId: string): Promise<string> => TransformacaoService.aplicarTransformacao(parceiroId, arquivoId)

const buscarRegras = async (parceiroId?: string | number): Promise<RegraTransformacao[]> => TransformacaoService.buscarRegras(parceiroId)

const buscarRegra = async (parceiroId?: string | number, regraId?: string | number): Promise<RegraTransformacao> =>
  TransformacaoService.buscarRegra(parceiroId, regraId)

const enviarArquivo = async (body: string[]): Promise<string> => TransformacaoService.enviarArquivo(body)

const previewRegra = async (body: RegraTransformacaoForm, arquivoId: string): Promise<PreviewItemTransformacao> =>
  TransformacaoService.previewRegra(body, arquivoId)

const transformarPlanilha = async (cicloId: string): Promise<string> => TransformacaoService.transformarPlanilha(cicloId)

const tratarPlanilha = async (cicloId: string, numerosLinhas: number[] | string[], tipoTratamento: string): Promise<string> =>
  TransformacaoService.tratarPlanilha(cicloId, numerosLinhas, tipoTratamento)

const previewRegraCiclo = async (body: RegraTransformacaoForm, cicloId: string, numeroLinha: number | string): Promise<PreviewItemTransformacao> =>
  TransformacaoService.previewRegraCiclo(body, cicloId, numeroLinha)

const cadastrarGrupoRegra = async (body: GrupoRegraCadastro, parceiroId: string | number) => TransformacaoService.cadastrarGrupoRegra(body, parceiroId)

const editarGrupoRegra = async (body: GrupoRegra, parceiroId: string | number) => TransformacaoService.editarGrupoRegra(body, parceiroId)

const buscarGruposRegras = async (parceiroId: string | number): Promise<GrupoRegra[]> => TransformacaoService.buscarGruposRegras(parceiroId)

const editarTabelaErroProcessamento = async (
  cicloId: string,
  itemLancamento: string[],
  indexEditado: number
): Promise<{ cicloId: string; itemLancamento: string[]; indexEditado: number }> =>
  TransformacaoService.editarTabelaErroProcessamento(cicloId, itemLancamento, indexEditado)

const reordenarPrioridade = async (parceiroId: string | number, body: PriorizacaoRegras[]): Promise<boolean> =>
  TransformacaoService.reordenarPrioridade(parceiroId, body)

export default {
  buscarCondicoes,
  buscarAcoes,
  cadastrarRegra,
  editarRegra,
  aplicarTransformacao,
  buscarRegras,
  enviarArquivo,
  previewRegra,
  transformarPlanilha,
  tratarPlanilha,
  previewRegraCiclo,
  cadastrarGrupoRegra,
  editarGrupoRegra,
  buscarGruposRegras,
  editarTabelaErroProcessamento,
  reordenarPrioridade,
  buscarRegra
}
