import { useState } from 'react'
import { itemConfiguracoesGeraisStyles } from './ItemConfiguracoesGerais.style'

export function itemConfiguracoesGeraisIO() {
  const [abrirModal, setAbrirModal] = useState(false)

  return {
    abrirModal,
    setAbrirModal,
    styles: itemConfiguracoesGeraisStyles,
    fecharModal: () => setAbrirModal(!abrirModal)
  }
}
export type ItemConfiguracoesGeraisIO = ReturnType<typeof itemConfiguracoesGeraisIO>
