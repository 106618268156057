/* eslint-disable sonarjs/cognitive-complexity */
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { ORDEM_CRESCENTE } from '../../constants/utilConstants'
import configuracoesManager from '../../services/api/configuracoes/configuracoesManager'
import { loadingReset, loadingUpdate } from '../../store/actions/loadingAction'
import { ConfiguracaoGeral } from '../../types/configuracoesApiType'
import { Order } from '../../types/tabelaTypes'
import { handleRequestSort } from '../../utils/tabelaUtils'
import { configuracoesGeraisStyles } from './ConfiguracoesGerais.style'

const linhaPadrao = {
  siglaSistema: '-',
  unidadeNegocioDescricao: '-',
  eventoDescricao: '-',
  fatoDescricao: '-',
  dataCadastro: '-',
  ativo: false,
  usuarioCadastro: '-',
  dataDesativacao: '-',
  usuarioDesativacao: '-'
}

export function configuracoesGeraisIO() {
  const dispatch = useDispatch()
  const [configuracoesLista, setConfiguracoesLista] = useState<ConfiguracaoGeral[]>([])
  const [abrirModal, setAbrirModal] = React.useState(false)
  const [ordem, setOrdem] = useState<Order>(ORDEM_CRESCENTE)
  const [ordemBy, setOrdemBy] = useState<keyof never>('configuracoesGerais')

  const handleAbrirModal = () => {
    setAbrirModal(true)
  }

  const handleFecharModal = () => {
    setAbrirModal(false)
  }

  const handleSalvarConfiguracoesGerais = () => {
    setAbrirModal(false)
    buscarConfiguracoes()
  }

  const buscarConfiguracoes = async () => {
    dispatch(loadingUpdate(true))
    const response: ConfiguracaoGeral[] = await configuracoesManager.buscarConfiguracoesCadastradas()
    setConfiguracoesLista(response)
    dispatch(loadingReset())
  }

  useEffect(() => {
    buscarConfiguracoes()
  }, [dispatch])

  return {
    styles: configuracoesGeraisStyles,
    abrirModal,
    handleAbrirModal,
    configuracoesLista,
    linhaPadrao,
    handleFecharModal,
    handleSalvarConfiguracoesGerais,
    ordem,
    setOrdem,
    ordemBy,
    onRequestSort: handleRequestSort(ordemBy, ordem, setOrdem, setOrdemBy)
  }
}

export type ConfiguracoesGeraisIO = ReturnType<typeof configuracoesGeraisIO>
