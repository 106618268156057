import { IOProps } from 'react-compose-io'
import { ControleDebitosRecebiveisIO } from './ControleDebitosRecebiveis.IO'
import { corParceiroId } from '../../styles/customTheme'
import { CabecalhoDebitos } from '../../components/Debitos/CabecalhoDebitos'
import { Grid, CircularProgress } from '@material-ui/core'
import { StatusProcessamento } from '../../types/genericoType'
import { RecuperacaoBox } from './components/RecuperacaoBox'
import { TransitoBox } from './components/TransitoBox/index'
import { SemOcorrenciaBox } from './components/SemOcorrenciaBox/index'

export type ControleDebitosRecebiveisProps = {}

export function ControleDebitosRecebiveisView({ _io }: IOProps<ControleDebitosRecebiveisIO, ControleDebitosRecebiveisProps>) {
  const { Container, Titulo } = _io.styles

  const Carregamento = _io.carregando && (
    <Grid container justifyContent="center">
      <CircularProgress />
    </Grid>
  )

  return (
    <>
      <Titulo>Débitos Recebíveis</Titulo>
      {_io.controleDebitosResponse.processamento && (
        <Container cor={corParceiroId.get(_io.parceiroSelecionado)}>
          <CabecalhoDebitos
            parceiroSelecionado={_io.parceiroSelecionado}
            desabilitarBotoes={_io.carregando}
            handleAtualizar={() => _io.gerarDadosControleDebitos()}
            handleClickParceiro={_io.handleClickParceiro}
            setParceiroTransportadoras={_io.setParceiroTransportadoras}
            dataUltimaAtualizacao={_io.controleDebitosResponse.dataAtualizacao}
          />

          {_io.controleDebitosResponse.processamento.status !== StatusProcessamento.CONCLUIDO ? (
            <>{Carregamento}</>
          ) : (
            <>
              <RecuperacaoBox
                totalPorcentagem={_io.recuperacao.totalPorcentagem}
                totalEmTransito={_io.recuperacao.totalEmTransito}
                maiorRecuperacao={_io.recuperacao.maiorRecuperacao}
                menorRecuperacao={_io.recuperacao.menorRecuperacao}
                recuperacaoGrafico={_io.recuperacao.recuperacaoGrafico}
              />

              <TransitoBox
                urlRelatorio={_io.emTransito.urlRelatorio}
                atendimento={_io.emTransito.atendimento}
                logisticaMartins={_io.emTransito.logisticaMartins}
                logisticaParceiro={_io.emTransito.logisticaParceiro}
                parceiroId={_io.parceiroSelecionado}
              />

              <SemOcorrenciaBox
                urlRelatorio={_io.semOcorrencia.urlRelatorio}
                perda={_io.semOcorrencia.perda}
                emAnalise={_io.semOcorrencia.emAnalise}
                parceiroId={_io.parceiroSelecionado}
              />
            </>
          )}
        </Container>
      )}
    </>
  )
}
