import { useState } from 'react'
import { useDispatch } from 'react-redux'
import transformacaoManager from '../../../../services/api/transformacao/transformacaoManager'
import { loadingReset, loadingUpdate } from '../../../../store/actions/loadingAction'
import { GrupoRegra } from '../../../../types/transformacaoApiTypes'
import { ItemGrupoRegraProps } from './ItemGrupoRegra.View'

export function itemGrupoRegraIO({ parceiroId, atualizarDados }: ItemGrupoRegraProps) {
  const [modalAberto, setModalAberto] = useState(false)
  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleEdit = () => {
    abrirModal()
    fecharMenu()
  }

  const handleStatus = async (dataGrupoRegra: GrupoRegra) => {
    dispatch(loadingUpdate(true))
    const dataEdit = { ...dataGrupoRegra }
    dataEdit.ativo = !dataEdit.ativo

    await transformacaoManager
      .editarGrupoRegra(dataEdit, parceiroId)
      .then(() => {
        atualizarDados()
      })
      .finally(() => {
        fecharModal()
        fecharMenu()
        dispatch(loadingReset())
      })
  }

  function abrirModal() {
    setModalAberto(true)
  }

  function fecharModal() {
    setModalAberto(false)
  }

  const fecharMenu = () => {
    setAnchorEl(null)
  }

  const abrirMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  return {
    modalAberto,
    fecharModal,
    anchorEl,
    fecharMenu,
    abrirMenu,
    handleEdit,
    handleStatus
  }
}

export type ItemGrupoRegraIO = ReturnType<typeof itemGrupoRegraIO>
