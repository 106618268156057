import { useState, useEffect } from 'react'
import { appMenuItemStyles } from './AppMenuItem.style'
import { AppMenuItemProps } from './AppMenuItem.View'
import { useDrawerContext } from '../../contexts/drawer-context'

export function appMenuItemIO({ items }: AppMenuItemProps) {
  const expandirMenu = items && items.length > 0
  const [abrirMenu, setAbrirMenu] = useState(false)
  const { isOpened } = useDrawerContext()

  function handleAbrirMenuLateral() {
    setAbrirMenu(!abrirMenu)
  }

  useEffect(() => {
    if (!isOpened) {
      setAbrirMenu(false)
    }
  }, [isOpened])

  return {
    styles: appMenuItemStyles,
    handleAbrirMenuLateral,
    expandirMenu,
    abrirMenu,
    isDrawerOpen: isOpened
  }
}
export type AppMenuItemIO = ReturnType<typeof appMenuItemIO>
