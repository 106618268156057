import {
  ITipoLancamentoParceiroForm,
  ObterListaStatusParceiroResponse,
  ParametrizacaoCampos,
  ParametrizacaoCamposParceiro,
  Parceiro,
  ParceiroRequest,
  StatusParceiroRequest,
  TipoLancamentoParceiro
} from '../../../types/parceiroApiTypes'
import parceiroService from './parceiroService'

const cadastrarDadosParceiros = async (cadastrarParceiro: ParceiroRequest): Promise<void> => parceiroService.cadastrarDadosParceiros(cadastrarParceiro)

const buscarDadosParceiros = async (ativos: boolean | null): Promise<Parceiro[]> => parceiroService.buscarDadosParceiros(ativos)

const buscarDadoParceiro = async (parceiroId: string | number): Promise<Parceiro> => parceiroService.buscarDadoParceiro(parceiroId)

const cadastrarTipolancamentoParceiros = async (
  parceiroId: string | number | undefined,
  body: ITipoLancamentoParceiroForm
): Promise<ITipoLancamentoParceiroForm> => parceiroService.cadastrarTipolancamentoParceiros(parceiroId, body)

const editarTipolancamentoParceiros = async (parceiroId: string | number | undefined, tipoLancamentoId: number, body: TipoLancamentoParceiro): Promise<void> =>
  parceiroService.editarTipolancamentoParceiros(parceiroId, tipoLancamentoId, body)

const buscarTipoLancamentoParceiro = async (parceiroId?: string | number, ativos?: boolean, tipoContabilizacao?: string): Promise<TipoLancamentoParceiro[]> =>
  parceiroService.buscarTipoLancamentoParceiro(parceiroId, ativos, tipoContabilizacao)

const buscarParametrosCicloPedido = async (parceiroId: string | number): Promise<ParametrizacaoCamposParceiro> =>
  parceiroService.buscarParametrosCicloPedido(parceiroId)

const editarParametrosCicloPedido = async (parceiroId: string | number, body: ParametrizacaoCampos): Promise<boolean> =>
  parceiroService.editarParametrosCicloPedido(parceiroId, body)

const listarStatusParceiro = async (parceiroId: string | number): Promise<ObterListaStatusParceiroResponse[]> =>
  parceiroService.listarStatusParceiro(parceiroId)

const cadastrarStatusParceiro = async (parceiroId: string | number, body: StatusParceiroRequest): Promise<boolean> =>
  parceiroService.cadastrarStatusParceiro(parceiroId, body)

const editarStatusParceiro = async (parceiroId: string | number, statusId: number, body: StatusParceiroRequest): Promise<boolean> =>
  parceiroService.editarStatusParceiro(parceiroId, statusId, body)

export default {
  buscarDadosParceiros,
  buscarDadoParceiro,
  cadastrarDadosParceiros,
  cadastrarTipolancamentoParceiros,
  editarTipolancamentoParceiros,
  buscarTipoLancamentoParceiro,
  buscarParametrosCicloPedido,
  editarParametrosCicloPedido,
  listarStatusParceiro,
  cadastrarStatusParceiro,
  editarStatusParceiro
}
