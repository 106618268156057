import { Divider, Grid, ListItemText } from '@material-ui/core'
import styled from 'styled-components'
import { ContainerSombra } from '../../../../components/ContainerSombra'
import { customTheme } from '../../../../styles/customTheme'
import { Tipografia } from './../../../../components/Tipografia/index'

export const resumoConciliacaoStyles = {
  Container: styled(ContainerSombra)`
    margin-top: 19px;
    padding: 16px 20px;
    flex-grow: 1;
    padding: 18;
  `,
  HeaderCabecalho: styled.div`
    margin-bottom: 15px;
    display: 'flex';
    align-items: 'center';
    justify-content: 'space-between';
  `,
  TextCabecalho: styled(Tipografia)`
    margin: 24px 0;
  `,
  ListValue: styled(ListItemText).attrs({
    style: {
      flex: '0.1 0.5 150px',
      color: `${customTheme.colors.grey03}`,
      fontFamily: `${customTheme.fontFamily.rubik400}`,
      fontSize: `${customTheme.fontSize[14]}`
    }
  })``,
  DividerList: styled(Divider).attrs({
    border: 'none',
    borderTop: `1px dashed ${customTheme.colors.grey03}`,
    color: `${customTheme.colors.grey03}`,
    backgroundColor: `${customTheme.colors.white01}`,
    height: 1
  })``,
  VerticalDivider: styled(Divider)`
    margin: 0px 27px 0px 10px;
  `,
  ContainerGrid: styled(Grid)`
    justify-content: flex-start;
    margin-top: 40px;
  `,
  ContainerListaResumo: styled(Grid).attrs({
    style: {
      display: 'flex',
      flexDirection: 'row',
      textAlign: 'start',
      alignItems: 'center'
    }
  })``
}
