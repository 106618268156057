import { IconButton, TableCell } from '@material-ui/core'
import { tabelaDetalhesPerdasStyles } from './TabelaDetalhesPerdas.styles'
import { CabecalhoTabelaDetalhesPerdas, TabelaDetalhesPerdasProps } from './TabelaDetalhesPerdas.View'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'

export function tabelaDetalhesPerdasIO({ checklistsSelecionados }: TabelaDetalhesPerdasProps) {
  const { CelulaComFiltro } = tabelaDetalhesPerdasStyles

  const renderCabecalho = ({ descricao, abrirPopover }: CabecalhoTabelaDetalhesPerdas) => {
    return (
      <TableCell key={descricao} align="center">
        {abrirPopover ? (
          <CelulaComFiltro>
            {descricao}
            <IconButton size="small" onClick={abrirPopover}>
              <KeyboardArrowDownIcon color="primary" />
            </IconButton>
          </CelulaComFiltro>
        ) : (
          descricao
        )}
      </TableCell>
    )
  }

  return {
    styles: tabelaDetalhesPerdasStyles,
    renderCabecalho,
    checkListTotalChecked: checklistsSelecionados.length > 0,
    checkListTotalIndeterminado: checklistsSelecionados.length === 0
  }
}

export type TabelaDetalhesPerdasIO = ReturnType<typeof tabelaDetalhesPerdasIO>
