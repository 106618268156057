import { TextField } from '@material-ui/core'
import styled from 'styled-components'

export const modalConfiguracoesGeraisStyles = {
  TextoFormulario: styled(TextField).attrs({
    variant: 'outlined',
    size: 'small'
  })``,
  Formulario: styled.form``
}
