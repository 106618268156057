import {
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  Typography
} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import { IOProps } from 'react-compose-io'
import { EnhancedTableHead } from '../../components/EnhancedTableHead/index'
import { GridTypography } from '../../components/GridTypography'
import { ComponentePaginacao } from '../../components/Tabela/ComponentePaginacao/index'
import { cabecalhoVisualizarContabilizacao } from '../../types/tabelaTypes'
import { formateCurrency, validarTexto } from '../../utils/stringUtils'
import { getComparator, ordenarCabecalho } from '../../utils/tabelaUtils'
import { VisualizarContabilizacaoIO } from './VisualizarContabilizacao.IO'
import { Tipografia } from '../../components/Tipografia'

export type VisualizarContabilizacaoProps = {}

export function VisualizarContabilizacaoView({ _io }: IOProps<VisualizarContabilizacaoIO, VisualizarContabilizacaoProps>) {
  const { Container, GridCenter, GridContainer, TableCellNegativo, TableCellPositivo } = _io.styles

  return (
    <>
      <Container>
        <Tipografia variant="h6">Contabilização</Tipografia>
        <GridContainer>
          <GridTypography titulo={'Tipo Lançamento'} valor={validarTexto(_io.contabilizacao?.tipoLancamento, '-')} />
          <GridTypography titulo={'Sequência'} valor={validarTexto(_io.contabilizacao?.sequencia, '-')} />
          <GridTypography titulo={'Conta'} valor={_io.contabilizacao ? _io.contabilizacao.conta : 0} />
          <GridTypography titulo={'Natureza'} valor={validarTexto(_io.contabilizacao?.natureza, '-')} />
          <Grid item>
            <Typography variant={'subtitle2'}>{_io.contabilizacao ? formateCurrency(_io.contabilizacao.valor) : 0}</Typography>
          </Grid>

          <GridCenter item />
          <Grid item>
            <FormControl fullWidth variant="outlined" size="small">
              <InputLabel htmlFor="filled-search">Buscar</InputLabel>
              <OutlinedInput
                id="filled-search"
                value={_io.buscarValores}
                onChange={(event) => _io.setBuscarValores(event.target.value)}
                onKeyPress={(e) => _io.searchEnter(e)}
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                }
                labelWidth={60}
              />
            </FormControl>
          </Grid>
        </GridContainer>

        {_io.detalhesContabilizacaoList != null && _io.detalhesContabilizacaoList != undefined && (
          <>
            <Table aria-labelledby="tableTitle" aria-label="enhanced table">
              <EnhancedTableHead
                alignCenter={false}
                headCells={cabecalhoVisualizarContabilizacao}
                order={_io.ordem}
                orderBy={_io.ordemBy}
                onRequestSort={_io.onRequestSort}
              />
              <TableBody>
                {ordenarCabecalho(_io.detalhesContabilizacaoList.detalhes, getComparator(_io.ordem, _io.ordemBy)).map((row, index) => {
                  return (
                    <TableRow tabIndex={-1} key={index}>
                      <TableCell component="th" scope="row">
                        {row.historicoPadrao}
                      </TableCell>
                      {row.valor < 0 ? (
                        <TableCellNegativo>{formateCurrency(row.valor)}</TableCellNegativo>
                      ) : (
                        <TableCellPositivo>{formateCurrency(row.valor)}</TableCellPositivo>
                      )}
                      <TableCell>{row.parcela}</TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={_io.linhasPorPaginaOpcao}
              component="div"
              count={_io.totalLinhas}
              rowsPerPage={_io.linhasPorPagina}
              page={_io.pagina}
              onPageChange={_io.onMudancaPagina}
              onRowsPerPageChange={_io.onMudancaLinhasPorPagina}
              ActionsComponent={ComponentePaginacao}
            />
          </>
        )}
      </Container>
    </>
  )
}
