import { useHistory } from 'react-router-dom'
import { opsEmAbertoResumoStyle } from './OpsEmAbertoResumo.style'
import { PAGES } from './../../../../router/routes'
import { useEffect, useState } from 'react'
import regularizacaoOPsManager from '../../../../services/api/regularizacaoOPs/regularizacaoOPsManager'
import { toast } from 'react-toastify'
import msgConstants from '../../../../constants/msgConstants'
import { OpsEmAbertoResumo } from '../../../../types/regularizacaoOpsTypes'

export function opsEmAbertoResumoIO() {
  const router = useHistory()
  const [opsEmAbertoResumo, setOpsEmAbertoResumo] = useState<OpsEmAbertoResumo>()

  useEffect(() => {
    obterOpsEmAbertoResumo()
  }, [])

  const obterOpsEmAbertoResumo = async () => {
    await regularizacaoOPsManager
      .buscarOPsEmAbertoResumo()
      .then((response: OpsEmAbertoResumo) => {
        setOpsEmAbertoResumo(response)
      })
      .catch(() => {
        toast.error(msgConstants.USO_GENERICO.erroBusca)
      })
  }

  const onClickDetalhesOP = () => router.push(PAGES.opsEmAbertoDetalhes)

  return {
    styles: opsEmAbertoResumoStyle,
    onClickDetalhesOP,
    opsEmAbertoResumo
  }
}

export type OpsEmAbertoResumoIO = ReturnType<typeof opsEmAbertoResumoIO>
