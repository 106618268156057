import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import msgConstants from '../../../../constants/msgConstants'
import controleComissaoManager from '../../../../services/api/controleComissao/controleComissaoManager'
import { AdicionarChamadoForm } from '../../../../types/controleComissaoTypes'
import { adicionarChamadoFormStyles } from './AdicionarChamadoForm.styles'
import { AdicionarChamadoFormProps } from './AdicionarChamadoForm.View'

export function adicionarChamadoFormIO({
  setMostrarAdicionarChamadoForm,
  buscarChamados,
  controleComissaoCicloId,
  atualizarListaControleComissao,
  isPrimeiroChamado
}: AdicionarChamadoFormProps) {
  const { handleSubmit, control, setValue } = useForm<AdicionarChamadoForm>({
    defaultValues: {
      numeroChamado: '',
      descricao: ''
    }
  })

  const handleSalvar = async (dataForm: AdicionarChamadoForm) => {
    await controleComissaoManager
      .adicionarNovoChamado(controleComissaoCicloId, dataForm)
      .then(() => {
        setMostrarAdicionarChamadoForm(false)
        buscarChamados()

        if (isPrimeiroChamado) atualizarListaControleComissao()
      })
      .catch(() => toast.error(msgConstants.CONTROLE_COMISSAO.erroAdicionarChamado))
  }

  const handleCancelar = () => {
    setValue('numeroChamado', '')
    setValue('descricao', '')
    setMostrarAdicionarChamadoForm(false)
  }

  return {
    styles: adicionarChamadoFormStyles,
    control,
    handleSubmit,
    handleSalvar,
    handleCancelar
  }
}

export type AdicionarChamadoFormIO = ReturnType<typeof adicionarChamadoFormIO>
