import { ItemRegraAcaoAssociacao, ItemRegraAcaoAssociacaoForm, ItemRegraCondicaoAssociacao, ItemRegraCondicaoAssociacaoForm } from '../types/associacaoApiTypes'
import {
  GrupoRegra,
  GrupoRegraSelect,
  ItemRegraTransformacaoAcao,
  ItemRegraTransformacaoAcaoForm,
  ItemRegraTransformacaoCondicao,
  ItemRegraTransformacaoCondicaoForm
} from '../types/transformacaoApiTypes'

export const converterToAcaoAssociacaoForm = (acaoRetraAssociacao: ItemRegraAcaoAssociacao[]) => {
  const acoesRegraAssociacaoFormulario: ItemRegraAcaoAssociacaoForm[] = []

  acaoRetraAssociacao.forEach((acaoRegra) => {
    acoesRegraAssociacaoFormulario.push({
      ...acaoRegra,
      parametro: {
        parametro: acaoRegra.parametro.parametro,
        tipoParametro: acaoRegra.parametro.tipoParametro.tipoParametroId
      }
    })
  })

  return acoesRegraAssociacaoFormulario
}

export const converterToCondicaoAssociacaoForm = (condicaoRegraAssociacao: ItemRegraCondicaoAssociacao[]) => {
  const condicoesRegraAssociacaoFormulario: ItemRegraCondicaoAssociacaoForm[] = []

  condicaoRegraAssociacao.forEach((condicaoRegra) => {
    condicoesRegraAssociacaoFormulario.push({
      ...condicaoRegra,
      parametro: {
        parametro: condicaoRegra.parametro.parametro,
        tipoParametro: condicaoRegra.parametro.tipoParametro.tipoParametroId
      }
    })
  })

  return condicoesRegraAssociacaoFormulario
}

export const converterToAcaoTransformacaoForm = (acoesRegraTransformacao: ItemRegraTransformacaoAcao[]) => {
  const acoesRegrasTransformacaoFormulario: ItemRegraTransformacaoAcaoForm[] = []

  acoesRegraTransformacao.forEach((acaoTransformacao) => {
    acoesRegrasTransformacaoFormulario.push({
      ...acaoTransformacao,
      parametro: {
        parametro: acaoTransformacao.parametro.parametro,
        tipoParametro: acaoTransformacao.parametro.tipoParametro.tipoParametroId
      }
    })
  })

  return acoesRegrasTransformacaoFormulario
}

export const converterToCondicaoTransformacaoForm = (condicoesRegraTransformacao: ItemRegraTransformacaoCondicao[]) => {
  const condicoesRegraTransformacaoFormulario: ItemRegraTransformacaoCondicaoForm[] = []

  condicoesRegraTransformacao.forEach((condicoesTransformacao) => {
    condicoesRegraTransformacaoFormulario.push({
      ...condicoesTransformacao,
      parametro: {
        parametro: condicoesTransformacao.parametro.parametro,
        tipoParametro: condicoesTransformacao.parametro.tipoParametro.tipoParametroId
      }
    })
  })

  return condicoesRegraTransformacaoFormulario
}

export const converterToGrupoRegraSelect = (gruposRegraTransformacao: GrupoRegra[]) => {
  const gruposFormulario: GrupoRegraSelect[] = []

  gruposRegraTransformacao.forEach((grupo) => {
    gruposFormulario.push({
      label: grupo.nome,
      value: grupo.grupoId
    })
  })

  return gruposFormulario
}
