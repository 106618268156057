import { TableCell } from '@material-ui/core'
import { tabelaDetalheControleComissaoPedidoStyles } from './TabelaDetalheControleComissaoPedido.styles'
import { CabecalhoTabelaDetalheControleComissaoPedido } from './TabelaDetalheControleComissaoPedido.View'

export function tabelaDetalheControleComissaoPedidoIO() {
  const renderCabecalho = ({ descricao }: CabecalhoTabelaDetalheControleComissaoPedido) => {
    return (
      <TableCell key={descricao} align="center">
        {descricao}
      </TableCell>
    )
  }

  return {
    styles: tabelaDetalheControleComissaoPedidoStyles,
    renderCabecalho
  }
}

export type TabelaDetalheControleComissaoPedidoIO = ReturnType<typeof tabelaDetalheControleComissaoPedidoIO>
