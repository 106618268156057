import { useHistory } from 'react-router-dom'
import { semOcorrenciaBoxStyles } from './SemOcorrenciaBox.styles'
import { PAGES } from '../../../../router/routes'
import { SemOcorrenciaBoxProps } from './SemOcorrenciaBox.View'
import { INADIMPLENCIA } from '../../../../types/controleInadimplenciaTypes'

export function semOcorrenciaBoxIO({ parceiroId }: SemOcorrenciaBoxProps) {
  const history = useHistory()

  return {
    styles: semOcorrenciaBoxStyles,
    onClickDetalhesPedidosBotaoSemOcorrencia: () => history.push(PAGES.detalheDebito(INADIMPLENCIA.SEM_OCORRENCIA_EM_ANALISE, parceiroId)),
    onClickDetalhesPedidosBotaoPerdas: () => history.push(PAGES.detalheDebito(INADIMPLENCIA.PERDA, parceiroId))
  }
}

export type SemOcorrenciaBoxIO = ReturnType<typeof semOcorrenciaBoxIO>
