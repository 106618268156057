import { Typography } from '@material-ui/core'
import styled from 'styled-components'
import { customTheme } from '../../styles/customTheme'

export const modalValidacaoPlanilhaRegularizacaoStyles = {
  SubtituloContainer: styled(Typography).attrs({
    variant: 'subtitle1'
  })`
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    color: ${customTheme.colors.black};
  `,
  ContainerModal: styled.div`
    background: ${customTheme.colors.white01};
  `,
  ContainerTituloModal: styled.div`
    display: flex;
    align-items: start;
    flex-direction: column;
    margin-bottom: 5px;
  `
}
