import { ChangeEvent, useEffect, useState } from 'react'
import { popoverFiltroParceiroStyles } from './PopoverFiltroParceiro.styles'
import { PopoverFiltroParceiroProps } from './PopoverFiltroParceiro.View'
import { CheckListItem } from './../CheckListItem/index'
import { Parceiro } from '../../types/parceiroApiTypes'
import parceiroManager from '../../services/api/parceiro/parceiroManager'

export function popoverFiltroParceiroIO({ isParceiroChecked, handleCheckParceiro }: PopoverFiltroParceiroProps) {
  const [parceirosAtivos, setParceirosAtivos] = useState<Parceiro[]>([])
  const [filtroParceiroInput, setFiltroParceiroInput] = useState('')

  useEffect(() => {
    buscarParceirosAtivos()
  }, [])

  const buscarParceirosAtivos = async () => {
    const parceirosResponse: Parceiro[] = await parceiroManager.buscarDadosParceiros(true)
    setParceirosAtivos(parceirosResponse)
  }

  const handleChangeParceiroInput = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFiltroParceiroInput(e.target.value)
  }

  const filtrarParceiros = ({ nomeFantasia }: Parceiro) => nomeFantasia.toUpperCase().includes(filtroParceiroInput.toUpperCase())

  const renderParceirosFiltradas = ({ parceiroId, nomeFantasia }: Parceiro) => (
    <CheckListItem
      key={parceiroId}
      id={parceiroId}
      texto={nomeFantasia}
      handleCheck={() => handleCheckParceiro(parceiroId)}
      isChecked={isParceiroChecked(parceiroId)}
    />
  )

  return {
    styles: popoverFiltroParceiroStyles,
    parceirosAtivos,
    handleChangeParceiroInput,
    filtrarParceiros,
    renderParceirosFiltradas
  }
}

export type PopoverFiltroParceiroIO = ReturnType<typeof popoverFiltroParceiroIO>
