import { useEffect, useState } from 'react'
import { StatusProcessamento } from '../../../../types/genericoType'
import { formateCurrency } from '../../../../utils/stringUtils'
import { itemListaParceirosStyles } from './ItemListaParceiros.styles'
import { ItemListaParceirosProps } from './ItemListaParceiros.View'

export function itemListaParceirosIO({ tituloParceiro, data }: ItemListaParceirosProps) {
  const [relatorioDisponivel, setRelatorioDisponivel] = useState(false)
  const [carregandoRelatorio, setCarregandoRelatorio] = useState(false)

  useEffect(() => {
    if (tituloParceiro) {
      if (tituloParceiro.processamentoRelatorio?.status == null) {
        setCarregandoRelatorio(false)
        setRelatorioDisponivel(false)
        return
      }
      if (tituloParceiro.processamentoRelatorio.status == StatusProcessamento.PROCESSANDO) {
        setCarregandoRelatorio(true)
        setRelatorioDisponivel(false)
        return
      }
      if (tituloParceiro.processamentoRelatorio.status == StatusProcessamento.CONCLUIDO) {
        setCarregandoRelatorio(false)
        setRelatorioDisponivel(true)
        return
      }
    }
  }, [tituloParceiro, data])

  const itemListaParceiros = () => {
    if (tituloParceiro) {
      return tituloParceiro.informacoes?.map((informacao) => {
        return {
          titulo: informacao.nome,
          valor: formateCurrency(informacao.valor),
          quantidade: informacao.quantidade,
          status: tituloParceiro.processamentoRelatorio?.status
        }
      })
    }
    return []
  }

  return {
    styles: itemListaParceirosStyles,
    relatorioDisponivel,
    carregandoRelatorio,
    itemListaParceiros,
    dataFormatada: `(${data.split('-').reverse().join('/')})`
  }
}
export type ItemListaParceirosIO = ReturnType<typeof itemListaParceirosIO>
