import { FormControl, Grid, InputLabel, MenuItem, Select } from '@material-ui/core'
import React from 'react'
import { IOProps } from 'react-compose-io'
import { Control, Controller, DeepMap, FieldError } from 'react-hook-form'
import { FormValuesSequencias } from '../../../../types/iniciarNovoCicloTypes'
import { Sequencias } from '../../../../types/tipoLancamentoApiTypes'
import { DadosSequenciaisIO } from './DadosSequenciais.IO'
import { Botao } from './../../../../components/Botao/index'

export type DadosSequenciaisProps = {
  sequencias: Sequencias[]
  handleClose: () => void
  handleSubmitForm: () => void
  controlForm?: Control<FormValuesSequencias>
  handleChangeForm: (
    event: React.ChangeEvent<{
      name?: string | number
      value: unknown
    }>
  ) => void
  handleErros: DeepMap<FormValuesSequencias, FieldError>
}

function DadosSequenciaisView({
  controlForm,
  handleChangeForm,
  handleClose,
  handleSubmitForm,
  sequencias
}: IOProps<DadosSequenciaisIO, DadosSequenciaisProps>) {
  return (
    <form onSubmit={handleSubmitForm} className="form">
      <Grid container spacing={2}>
        <Grid item xs={12} sm={8}>
          <Controller
            render={({ field }) => (
              <FormControl variant="outlined" size="small" fullWidth>
                <InputLabel id="numeroSequencia">Sequência</InputLabel>
                <Select {...field} labelId="numeroSequencia" id="sequencia" label="Sequencia" onChange={handleChangeForm}>
                  {sequencias.map((item: Sequencias, index) => (
                    <MenuItem key={index} value={item.numeroSequencia}>
                      {item.sequencia}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            name="numeroSequenciaId"
            control={controlForm}
          />
        </Grid>

        <Grid item xs={12} sm={4} container direction="row" justifyContent="flex-end" spacing={2}>
          <Grid item>
            <Botao estilo="text" textoBotao="Cancelar" cor="primaria" onClick={handleClose} />
          </Grid>
          <Grid item>
            <Botao estilo="rounded" textoBotao="Confirmar" cor="primaria" type="submit" />
          </Grid>
        </Grid>
      </Grid>
    </form>
  )
}
export default DadosSequenciaisView
