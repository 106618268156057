import { EmTransitoInadimplenciaIO } from './EmTransitoInadimplencia.IO'
import { IOProps } from 'react-compose-io'
import { INADIMPLENCIA, Relatorio, TipoAnomaliaEnum } from '../../../../types/controleInadimplenciaTypes'
import { InadimplenciaAnomalia } from '../InadimplenciaAnomalia'
import { TooltipComponent } from '../../../../components/Tooltip/Tooltip'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import AtendimentoIcon from '../../../../assets/icons/atendimentoIcone.svg'
import LogisticaMartins from '../../../../assets/icons/logisticaIcone.svg'
import LogisticaParceiro from '../../../../assets/icons/logisticaParceiro.svg'
import { DadosMeses } from '../../../../utils/tratarDadosMesesUtils'

export type EmTransitoInadimplenciaProps = {
  mesesSelecionados: number
  inadimplenciasAtendimento: DadosMeses[]
  inadimplenciasLogisticaMartins: DadosMeses[]
  inadimplenciasLogisticaParceiro: DadosMeses[]
  relatoriosInadimplencia: Relatorio[]
  parceiroSelecionado: number
}

export function EmTransitoInadimplenciaView({
  _io,
  inadimplenciasAtendimento,
  inadimplenciasLogisticaMartins,
  inadimplenciasLogisticaParceiro,
  mesesSelecionados,
  relatoriosInadimplencia,
  parceiroSelecionado
}: IOProps<EmTransitoInadimplenciaIO, EmTransitoInadimplenciaProps>) {
  const { Box, Titulo, Divider } = _io.styles

  return (
    <Box>
      <Titulo>
        {'Em trânsito'}
        <TooltipComponent
          texto={`O painel Em Trânsito aponta inadimplências que estão em análise e tem possibilidade de recuperação pela área responsável`}
          Icone={HelpOutlineIcon}
          color={'action'}
        />
      </Titulo>
      <InadimplenciaAnomalia
        dadosMensais={inadimplenciasAtendimento}
        tituloAnomalia={'Backoffice'}
        iconeAnomalia={AtendimentoIcon}
        mesesSelecionados={mesesSelecionados}
        tipoAnomalia={INADIMPLENCIA.BACKOFFICE}
        urlRelatorio={relatoriosInadimplencia.filter((x) => x.tipo === TipoAnomaliaEnum.EmTransitoBackoffice)[0]?.urlRelatorio}
        parceiroSelecionado={parceiroSelecionado}
      />
      <Divider />
      <InadimplenciaAnomalia
        dadosMensais={inadimplenciasLogisticaMartins}
        mesesSelecionados={mesesSelecionados}
        tituloAnomalia={'Logística - Frota própria ou terceirizada'}
        iconeAnomalia={LogisticaMartins}
        tipoAnomalia={INADIMPLENCIA.EM_TRANSITO_LOGISTICA_MARTINS}
        urlRelatorio={relatoriosInadimplencia.filter((x) => x.tipo === TipoAnomaliaEnum.EmTransitoLogisticaMartins)[0]?.urlRelatorio}
        parceiroSelecionado={parceiroSelecionado}
      />
      <Divider />
      <InadimplenciaAnomalia
        dadosMensais={inadimplenciasLogisticaParceiro}
        mesesSelecionados={mesesSelecionados}
        tituloAnomalia={'Logística - Transportadora dos parceiros'}
        iconeAnomalia={LogisticaParceiro}
        tipoAnomalia={INADIMPLENCIA.EM_TRANSITO_LOGISTICA_PARCEIRO}
        urlRelatorio={relatoriosInadimplencia.filter((x) => x.tipo === TipoAnomaliaEnum.EmTransitoLogisticaParceiro)[0]?.urlRelatorio}
        parceiroSelecionado={parceiroSelecionado}
      />
    </Box>
  )
}
