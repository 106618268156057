import { IOProps } from 'react-compose-io'
import { ContainerAvatar } from '../../../../components/ContainerAvatar'
import { ObterOpsTituloPorPedidoResponse } from '../../../../types/regularizacaoOPsTitulosTypes'
import { formateCurrency } from '../../../../utils/stringUtils'
import { InformacoesIniciaisPedidoIO } from './InformacoesIniciaisPedido.IO'

export type InformacoesIniciaisPedidoProps = {
  informacoesPedido: ObterOpsTituloPorPedidoResponse
}

export function InformacoesIniciaisPedidoView({ _io, informacoesPedido }: IOProps<InformacoesIniciaisPedidoIO, InformacoesIniciaisPedidoProps>) {
  const { ContainerColuna, TextoDescritivo, ContainerLinha, SubtituloDescritivo } = _io.styles

  return (
    <ContainerLinha>
      <ContainerColuna>
        <TextoDescritivo>Parceiro</TextoDescritivo>
        <ContainerAvatar avatarDescricao={informacoesPedido.parceiro.parceiroNome} avatarSrc={informacoesPedido.parceiro.logoParceiroUrl} tamanhoImagem={24} />
      </ContainerColuna>
      <ContainerColuna>
        <TextoDescritivo>Cód. do cliente</TextoDescritivo>
        <SubtituloDescritivo>{`${informacoesPedido.codigoCliente} - ${informacoesPedido.cliente}`}</SubtituloDescritivo>
      </ContainerColuna>
      <ContainerColuna>
        <TextoDescritivo>Valor saldo</TextoDescritivo>
        <SubtituloDescritivo>{formateCurrency(informacoesPedido.valorSaldo)}</SubtituloDescritivo>
      </ContainerColuna>
    </ContainerLinha>
  )
}
