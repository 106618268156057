import styled from 'styled-components'
import { ContainerSombra } from '../../../../components/ContainerSombra'

export const validarCiclosStyle = {
  Container: styled(ContainerSombra)`
    width: 100%;
    margin-top: 19px;
    flex-grow: 1;
    padding: 18px;
  `,
  ContainerHeader: styled.div`
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  `
}
