import { Box, Grid, IconButton, InputLabel, MenuItem, Select } from '@material-ui/core'
import { Cancel } from '@material-ui/icons'
import React from 'react'
import { IOProps } from 'react-compose-io'
import { Controller } from 'react-hook-form'
import { TRANSFORMACAO_INFO } from '../../../constants/transformacao'
import { FormFieldsProps } from '../../../types/regraApiType'
import { TooltipComponent } from '../../Tooltip/Tooltip'
import { FieldsTipoParametro } from './../FieldsTipoParametro'
import { FieldsCondicaoIO } from './FieldsCondicao.IO'
import { Botao } from './../../Botao/index'

export type FieldsCondicaoProps = {
  cabecalhos: string[]
  itensCampo: FormFieldsProps[]
}

export function FieldsCondicaoView({ _io, cabecalhos, itensCampo }: IOProps<FieldsCondicaoIO, FieldsCondicaoProps>) {
  const { FormSelectControl, FormControl, FormGroup, FormTitle, FormLabel, FormSubtitle, BoxRegra, SelectContainer } = _io.styles

  return (
    <BoxRegra>
      <Box>
        <FormTitle>Condição</FormTitle>
        <FormSubtitle>Adicione condições para essa regra, se houver.</FormSubtitle>
      </Box>
      {_io.condicoes.fields.map((condicao, indexCondicao) => (
        <Grid key={condicao.id} container spacing={1}>
          <Grid item sm={12} md={3}>
            <FormGroup>
              {indexCondicao < 1 ? (
                <FormLabel>Se</FormLabel>
              ) : (
                <SelectContainer>
                  <Controller
                    render={({ field }) => (
                      <FormControl>
                        <InputLabel>Condição</InputLabel>
                        <FormSelectControl {...field} defaultValue={`E`} label="Tipo de condição">
                          <MenuItem value={`E`}>E</MenuItem>
                          <MenuItem value={`Ou`}>OU</MenuItem>
                        </FormSelectControl>
                      </FormControl>
                    )}
                    name={`condicoes.${indexCondicao}.tipoCondicao` as `condicoes.0.tipoCondicao`}
                    control={_io.control}
                  />
                </SelectContainer>
              )}
              <Controller
                render={({ field }) => (
                  <FormControl>
                    <InputLabel>Coluna</InputLabel>
                    <Select {...field} id="to" label="Coluna">
                      {cabecalhos.map((cabecalho: string, cabecalhoIndex: number) => (
                        <MenuItem key={cabecalhoIndex} value={cabecalho}>
                          {cabecalho}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
                name={`condicoes.${indexCondicao}.dado` as `condicoes.0.dado`}
                control={_io.control}
              />
              <TooltipComponent texto={TRANSFORMACAO_INFO.condicao.tipoCondicao} />
            </FormGroup>
          </Grid>
          <Grid item xs={12} sm={12} md={2}>
            <FormGroup>
              <Controller
                render={({ field }) => (
                  <FormControl>
                    <InputLabel>Operação</InputLabel>
                    <Select {...field} label="Operação">
                      {itensCampo.map((operacao, operacaoIndex) => (
                        <MenuItem key={operacaoIndex} value={operacao.operacaoId}>
                          {operacao.nome}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
                name={`condicoes.${indexCondicao}.operacaoId` as `condicoes.0.operacaoId`}
                control={_io.control}
              />
              <TooltipComponent texto={TRANSFORMACAO_INFO.condicao.tipoOperacaoCondicao} />
            </FormGroup>
          </Grid>
          <Grid item sm={12} md={2}>
            <FormGroup>
              <Controller
                render={({ field }) => (
                  <FormControl>
                    <InputLabel>Operador</InputLabel>
                    <Select {...field} label="Operador">
                      {itensCampo
                        .filter((filtroOperador) => filtroOperador.operacaoId === _io.condicaoSelecionada[indexCondicao]?.operacaoId)[0]
                        ?.operadores.map((operador: string, operadorIndex: React.Key | number) => (
                          <MenuItem key={operadorIndex} value={operador}>
                            {operador}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                )}
                name={`condicoes.${indexCondicao}.operador` as `condicoes.0.operador`}
                control={_io.control}
              />
            </FormGroup>
          </Grid>
          <FieldsTipoParametro
            indexItensRegraForm={indexCondicao}
            itensRegraTransformacaoForm={_io.condicaoSelecionada}
            cabecalhos={cabecalhos}
            control={_io.control}
            itensCampo={itensCampo}
            isCondicao
          />
          <Grid item sm={12} md={1}>
            {_io.condicoes.fields.length > 0 && (
              <IconButton onClick={() => _io.condicoes.remove(indexCondicao)}>
                <Cancel />
              </IconButton>
            )}
          </Grid>
        </Grid>
      ))}
      <Botao onClick={_io.adicionarCondicao} textoBotao="+ Adicionar condição" cor="primaria" estilo="text" tamanho="pequeno" />
    </BoxRegra>
  )
}
