import {
  AtualizarPedidoInadimplenciaRequest,
  ClassificacaoInadimplencia,
  ControleInadimplenciasResponse,
  ObterPedidosInadimplenciaQuery,
  ObterPedidosInadimplenciaResponse,
  InadimplenciaPerdasEfetivadas
} from '../../../types/controleInadimplenciaTypes'
import { PossiveisTratativas, ResponsePaginadoCargo, ResumoPedidosPerdasClassificacao } from '../../../types/perdasTypes'
import { concatenarFiltrosQuery } from '../../../utils/stringUtils'
import api from '../../axiosInstance'

const buscarControleInadimplencias = async (): Promise<ControleInadimplenciasResponse> => {
  const URL_CONTROLE_INADIMPLENCIAS = `ControleInadimplencias/ObterDados`
  const response = await api.conectarGet<ControleInadimplenciasResponse>(URL_CONTROLE_INADIMPLENCIAS)
  return response.data
}

const gerarDadosControleInadimplencias = async (): Promise<boolean> => {
  const URL_CONTROLE_INADIMPLENCIAS = `ControleInadimplencias/Resumos`
  const response = await api.conectarPost<boolean>(URL_CONTROLE_INADIMPLENCIAS)
  return response.data
}

const buscarPedidosInadimplencia = async (query: ObterPedidosInadimplenciaQuery): Promise<ResponsePaginadoCargo<ObterPedidosInadimplenciaResponse>> => {
  const URL_CONTROLE_INADIMPLENCIAS = `ControleInadimplencias/Pedidos${concatenarFiltrosQuery(query)}`
  const response = await api.conectarGet<ResponsePaginadoCargo<ObterPedidosInadimplenciaResponse>>(URL_CONTROLE_INADIMPLENCIAS)

  return response.data
}

const buscarTratativasInadimplencia = async (): Promise<PossiveisTratativas> => {
  const URL_TRATATIVAS_INADIMPLENCIAS = `ControleInadimplencias/Tratativas`
  const response = await api.conectarGet<PossiveisTratativas>(URL_TRATATIVAS_INADIMPLENCIAS)

  return response.data
}

const atualizarPedidosInadimplencia = async (body: AtualizarPedidoInadimplenciaRequest[]): Promise<boolean> => {
  const URL_ATUALIZAR_PEDIDOS_INADIMPLENCIA = `ControleInadimplencias/Pedidos`
  const response = await api.conectarPatch<boolean>(URL_ATUALIZAR_PEDIDOS_INADIMPLENCIA, body)
  return response.data
}

const buscarClassificacoesInadimplencia = async (): Promise<ClassificacaoInadimplencia[]> => {
  const URL_CLASIFICACOES_INADIMPLENCIA = `ControleInadimplencias/Classificacoes`
  const response = await api.conectarGet<ClassificacaoInadimplencia[]>(URL_CLASIFICACOES_INADIMPLENCIA)
  return response.data
}

const buscarHistoricoInadimplenciaEfetivadas = async (): Promise<InadimplenciaPerdasEfetivadas[]> => {
  const URL_HISTORICO_INADIMPLENCIA_EFETIVADAS = `ControleInadimplencias/PerdasEfetivadas`
  const response = await api.conectarGet<InadimplenciaPerdasEfetivadas[]>(URL_HISTORICO_INADIMPLENCIA_EFETIVADAS)
  return response.data
}

const buscarResumoPedidosClassificadosInadimplencia = async (): Promise<ResumoPedidosPerdasClassificacao[]> => {
  const URL_RESUMO_PEDIDOS_CLASSIFICADOS_INADIMPLENCIA = `ControleInadimplencias/Pedidos/Resumo`
  const response = await api.conectarGet<ResumoPedidosPerdasClassificacao[]>(URL_RESUMO_PEDIDOS_CLASSIFICADOS_INADIMPLENCIA)
  return response.data
}

export default {
  buscarControleInadimplencias,
  gerarDadosControleInadimplencias,
  buscarPedidosInadimplencia,
  buscarTratativasInadimplencia,
  atualizarPedidosInadimplencia,
  buscarClassificacoesInadimplencia,
  buscarHistoricoInadimplenciaEfetivadas,
  buscarResumoPedidosClassificadosInadimplencia
}
