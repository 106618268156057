import { useLocation } from 'react-router-dom'
import { VAZIO } from '../constants/utilConstants'
import { RegraAssociacaoForm } from '../types/associacaoApiTypes'
import { TiposLancamentosData } from '../types/tipoLancamentoApiTypes'
import { hexToRgb } from '@material-ui/core'
import { customTheme } from '../styles/customTheme'

export const peridiocidadeBaseOperador = (peridiocidade?: string | number, tipoPeridiocidade?: string) => {
  const plural = peridiocidade && Number(peridiocidade) > 1

  switch (tipoPeridiocidade) {
    case 'H':
      return plural ? 'Horas' : 'Hora'
    case 'D':
      return plural ? 'Dias' : 'Dia'
    case 'S':
      return plural ? 'Semanas' : 'Semana'
    case 'M':
      return plural ? 'Meses' : 'Mês'
    default:
      return ''
  }
}

export const formateDate = (data: string | Date | null | undefined, hours?: boolean) => {
  if (data && dateIsValid(data)) {
    return hours ? new Date(data).toLocaleString('pt-BR') : new Date(data).toLocaleDateString('pt-BR')
  }
  return ' - '
}

export const formateHoras = (data: string | undefined) => {
  if (data) {
    const minutosValidos = new Date(data).getMinutes()
    return `${new Date(data).getHours()}:${minutosValidos < 10 ? '0' + minutosValidos : minutosValidos}`
  }
  return ' --:-- '
}

const dateIsValid = (data: string | Date) => {
  const validAno = new Date(1, 0).getFullYear()
  const ano = new Date(data).getFullYear()
  return ano > validAno
}

export const getDataAtualFormatada = () => String(formatarData(new Date().toISOString(), '-'))

export const formateCurrency = (value: number | string) =>
  new Intl.NumberFormat('pt-br', {
    style: 'currency',
    currency: 'BRL'
  })
    .format(typeof value == 'string' ? parseFloat(value) : value)
    .replace('-', '- ')

export const formatarData = (date?: string | Date | null, divisaoData?: string) => {
  const textoDivisaoData = divisaoData ? divisaoData : '/'
  if (date && dateIsValid(date)) {
    const ultimoRepasse = new Date(date)
    const year = ultimoRepasse.getFullYear()
    const monthWithOffset = ultimoRepasse.getUTCMonth() + 1
    const month = monthWithOffset.toString().length < 2 ? `0${monthWithOffset}` : monthWithOffset
    const day = ultimoRepasse.getUTCDate().toString().length < 2 ? `0${ultimoRepasse.getUTCDate()}` : ultimoRepasse.getUTCDate()
    return `${year}${textoDivisaoData}${month}${textoDivisaoData}${day}`
  } else {
    return date
  }
}

export const estaVazio = (texto: string | number | Date | null | unknown): boolean => {
  return texto === VAZIO
}

export const validarTexto = (data: string | null | undefined | number, defaultData: string) => {
  if (data && !estaVazio(data)) return data
  else return defaultData
}

export const checarStatus = (ativo: boolean) => (ativo ? 'Ativo' : 'Inativo')

export const quebrarStringPelaPosicao = (nome: string, numero: number) => Number(nome.split(' ')[numero])

export const valorMenorZero = (valor: number, retornoTrue: string, retornoFalse: string) => (valor < 0 ? retornoTrue : retornoFalse)

export const concatenarFiltrosQuery = (objeto: any): string => {
  return (
    '?' +
    Object.keys(objeto)
      .map((key) => {
        if (objeto[key]) {
          return objeto[key].length >= 0 && typeof objeto[key] != 'string'
            ? objeto[key].map((valor: any) => key + '=' + valor).join('&')
            : key + '=' + objeto[key]
        }
        return undefined
      })
      .filter((valor) => valor !== undefined && valor !== '')
      .join('&')
  )
}

export function dataInicialMes() {
  const mesReferencia = new Date().getMonth() + 1
  const month = mesReferencia.toString().length < 2 ? `0${mesReferencia}` : mesReferencia
  const anoReferencia = new Date().getFullYear()
  return `${anoReferencia}-${month}-01`
}

export function pegarParametrosQuery() {
  return new URLSearchParams(useLocation().search)
}

export function gerarNomeRegraAssociacao(data: RegraAssociacaoForm, tipoLancamentoList: TiposLancamentosData[]) {
  return (
    data.condicoes.map((condicao) => condicao.parametro.parametro).join('-') +
    '_' +
    tipoLancamentoList.find((tipolancamento) => tipolancamento.tipoLancamentosId.toString() == data.acoes[0].parametro.parametro)?.nome
  )
}

export const porcentagem = (valor: number) => `${(valor * 100).toFixed(2)}%`

export const porcentagemNaoMultiplicada = (valor: number) => `${valor.toFixed(2)}%`

export function handleHexToRgba(opacidadeBotao = 0.2, cor?: string) {
  const corValida = cor ? cor : `${customTheme.colors.cinza02}`
  return hexToRgb(corValida).split(')').join(`, ${opacidadeBotao})`)
}

export const dataAtualPadrao = String(formatarData(new Date().toISOString(), '-'))

export const stringIsNullOrEmpty = (value: string): boolean => value === null || value === '' || value === undefined
