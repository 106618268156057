import { IOProps } from 'react-compose-io'
import { ControleCicloPedidoIO } from './ControleCicloPedido.IO'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { Grid, IconButton, CircularProgress, Typography } from '@material-ui/core'
import { customTheme } from './../../styles/customTheme'
import { UploadPlanilhaCicloPedido } from './components/UploadPlanilhaCicloPedido/index'
import { PedidosDivergentesResumo } from './components/PedidosDivergentesResumo'
import { PAGES } from '../../router/routes'
import { Tipografia } from './../../components/Tipografia/index'
import { Botao } from '../../components/Botao'
import RefreshIcon from '@material-ui/icons/Refresh'
import { SelecionarParceiro } from '../../components/SelecionarParceiro'

export type ControleCicloPedidoProps = {}

export function ControleCicloPedidoView({ _io }: IOProps<ControleCicloPedidoIO, ControleCicloPedidoProps>) {
  const { Container, Avatar, CabecalhoContainer, LimitedBackdrop, ContainerButtons } = _io.styles

  return (
    <>
      <Typography variant="h5">Controle de ciclo de pedido</Typography>
      {_io.parceiroSelecionado == 0 ? (
        <SelecionarParceiro
          handleConfirmar={_io.handleConfirmar}
          parceiros={_io.parceiros}
          subtitulo="Selecione o parceiro para visualizar o Controle de ciclo de pedido."
        />
      ) : (
        <Container>
          <LimitedBackdrop open={_io.carregando}>
            <CircularProgress color="primary" />
          </LimitedBackdrop>
          <Grid container alignItems="center" justifyContent="space-between" spacing={2}>
            <Grid item>
              <CabecalhoContainer>
                <IconButton onClick={_io.handleIconeVoltar}>
                  <ArrowBackIcon color="disabled" />
                </IconButton>
                <Avatar src={_io.parceiroLogo} />
                <Tipografia variant="h6" fontWeight="500" colorText={customTheme.colors.grey23}>
                  {_io.parceiroNome}
                </Tipografia>
              </CabecalhoContainer>
            </Grid>
            <ContainerButtons>
              <Grid item>
                <Botao
                  onClick={() => _io.router.push(PAGES.parametrosCicloPedido(_io.parceiroSelecionado))}
                  textoBotao="Editar parâmetros"
                  cor="primaria"
                  estilo="outlined"
                  width="280px"
                />
              </Grid>
              <Grid item>
                <Botao
                  textoBotao="Atualizar dados"
                  cor="primaria"
                  type="submit"
                  estilo="outlined"
                  startIcon={<RefreshIcon />}
                  width="280px"
                  onClick={_io.handleAtualizarParceiro}
                />
                <Tipografia variant="body2" colorText={customTheme.colors.cinza07}>
                  {`Última atualização: ${_io.dataAtualizacao}`}
                </Tipografia>
              </Grid>
            </ContainerButtons>
          </Grid>

          <UploadPlanilhaCicloPedido
            dataUpload={_io.dataUpload}
            usuarioUltimoUpload={_io.divergencias?.usuarioUltimoUpload}
            parceiroSelecionado={_io.parceiroSelecionado}
            atualizarDivergencias={_io.obterDivergencias}
            setCarregando={_io.setCarregando}
          />

          {_io.divergencias && <PedidosDivergentesResumo divergencias={_io.divergencias} parceiroSelecionado={_io.parceiroSelecionado} />}
        </Container>
      )}
    </>
  )
}
