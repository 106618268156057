import { PAGES } from '../router/routes'
import { DrawerItem } from '../types/appMenuItemTypes'
import {
  UilChart,
  UilClipboardBlank,
  UilCog,
  UilCommentAltChartLines,
  UilEstate,
  UilFileCopyAlt,
  UilFolder,
  UilFolderCheck,
  UilLayerGroup,
  UilUsersAlt,
  UilBill
} from '@iconscout/react-unicons'

export const DRAWER_LIST: DrawerItem[] = [
  {
    name: 'Início',
    link: PAGES.inicio,
    Icon: UilEstate
  },
  {
    link: PAGES.ciclos,
    name: 'Ciclos',
    Icon: UilLayerGroup
  },
  {
    name: 'Controle de Comissão',
    Icon: UilBill,
    items: [
      {
        name: 'Ciclos',
        link: PAGES.comissao
      },
      {
        name: 'Pedidos',
        link: PAGES.comissaoPedidos
      }
    ]
  },
  {
    link: PAGES.titulos,
    name: 'Controle de Títulos',
    Icon: UilFolder
  },
  {
    link: PAGES.relatorioConciliacao,
    name: 'Relatórios',
    Icon: UilCommentAltChartLines
  },
  {
    name: 'Controle de Perdas',
    Icon: UilChart,
    items: [
      {
        name: 'Débitos',
        link: PAGES.debitos
      },
      {
        name: 'Inadimplências',
        link: PAGES.inadimplencias
      },
      {
        name: 'Controle de Classificações',
        link: PAGES.controleClassificacoes
      },
      {
        name: 'Histórico',
        link: PAGES.historicoPerdas
      }
    ]
  },
  {
    link: PAGES.controleCicloPedido,
    name: 'Controle de Ciclo de Pedido',
    Icon: UilFileCopyAlt
  },
  {
    link: PAGES.pedidos,
    name: 'Pedidos',
    Icon: UilClipboardBlank
  },
  {
    name: 'Parceiros',
    link: PAGES.parceiros,
    Icon: UilUsersAlt
  },
  {
    name: 'Regularização',
    Icon: UilFolderCheck,
    items: [
      {
        name: 'Ciclos',
        link: PAGES.regularizacaoCiclos
      },
      {
        name: `OP's`,
        link: PAGES.ops
      },
      {
        name: `Pesquisa`,
        link: PAGES.informacaoId(null, null, null, null)
      },
      {
        name: `Perdas`,
        link: PAGES.perdas
      },
      {
        name: `Títulos`,
        link: PAGES.regularizacaoTitulos
      }
    ]
  },
  {
    name: 'Configurações',
    Icon: UilCog,
    items: [
      {
        name: 'Configurações Gerais',
        link: PAGES.configuracoesGerais
      },
      {
        name: 'Tipos Lançamentos',
        link: PAGES.tiposLancamentos
      }
    ]
  }
]
