import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useDrawerContext } from '../../contexts/drawer-context'
import { customDrawerStyle } from './CustomDrawer.style'

export function customDrawerIO() {
  const theme = useTheme()
  const { isOpened, toggleIsOpened } = useDrawerContext()
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'))
  const { Drawer } = customDrawerStyle

  return {
    styles: customDrawerStyle,
    isOpened,
    toggleIsOpened,
    isLargeScreen,
    Drawer
  }
}

export type CustomDrawerIO = ReturnType<typeof customDrawerIO>
