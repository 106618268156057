import { Typography } from '@material-ui/core'
import styled from 'styled-components'
import { ContainerSombra } from '../../components/ContainerSombra'

export const adicionarNovoParametroStyles = {
  Titulo: styled(Typography)`
    margin-bottom: 24px;
  `,
  Container: styled(ContainerSombra)`
    padding: 24px;
    margin-bottom: 19px;
  `
}
