import { IOProps } from 'react-compose-io'
import { Dispatch, SetStateAction } from 'react'
import { AdicionarChamadoFormIO } from './AdicionarChamadoForm.IO'
import { Typography, Grid, TextField } from '@material-ui/core'
import { Controller } from 'react-hook-form'
import { Botao } from '../../../../components/Botao'

export type AdicionarChamadoFormProps = {
  setMostrarAdicionarChamadoForm: Dispatch<SetStateAction<boolean>>
  buscarChamados: () => Promise<void>
  controleComissaoCicloId: string
  atualizarListaControleComissao: () => void
  isPrimeiroChamado: boolean
}

export default function AdicionarChamadoFormView({ _io }: IOProps<AdicionarChamadoFormIO, AdicionarChamadoFormProps>) {
  const { Container, ContainerButtons } = _io.styles

  return (
    <Container>
      <Typography variant="h6">Novo Chamado</Typography>
      <form>
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Controller
                render={({ field }) => <TextField {...field} label="Número do Chamado" variant="outlined" size="small" fullWidth required />}
                name="numeroChamado"
                control={_io.control}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Controller
                render={({ field }) => <TextField {...field} label="Observações" variant="outlined" size="small" fullWidth />}
                name="descricao"
                control={_io.control}
              />
            </Grid>
          </Grid>
        </Container>
      </form>
      <ContainerButtons>
        <Botao onClick={() => _io.handleCancelar()} textoBotao="Cancelar" cor="primaria" estilo="text" />
        <Botao onClick={_io.handleSubmit(_io.handleSalvar)} textoBotao="Salvar" cor="primaria" estilo="outlined" />
      </ContainerButtons>
    </Container>
  )
}
