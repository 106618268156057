import { IconeProps } from './icon'
import { customTheme } from '../../styles/customTheme'

export const ExportIcon = ({ color = customTheme.colors.blue13, disabled = false, width = 24, height = 20 }: IconeProps) => (
  <svg width={width} height={height} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d={`M13.9974 8.01699C13.7764 8.01699 13.5644 8.10479 13.4081 8.26107C13.2519
      8.41735 13.1641 8.62931 13.1641 8.85033V14.8337C13.1641 15.0547 13.0763 15.2666
      12.92 15.4229C12.7637 15.5792 12.5517 15.667 12.3307 15.667H3.16406C2.94305
      15.667 2.73109 15.5792 2.57481 15.4229C2.41853 15.2666 2.33073 15.0547 2.33073
      14.8337V5.66699C2.33073 5.44598 2.41853 5.23402 2.57481 5.07774C2.73109 4.92146
      2.94305 4.83366 3.16406 4.83366H9.1474C9.36841 4.83366 9.58037 4.74586 9.73665
      4.58958C9.89293 4.4333 9.98073 4.22134 9.98073 4.00033C9.98073 3.77931 9.89293
      3.56735 9.73665 3.41107C9.58037 3.25479 9.36841 3.16699 9.1474 3.16699H3.16406C2.50102
      3.16699 1.86514 3.43038 1.3963 3.89923C0.927455 4.36807 0.664063 5.00395 0.664062
      5.66699V14.8337C0.664063 15.4967 0.927455 16.1326 1.3963 16.6014C1.86514 17.0703
      2.50102 17.3337 3.16406 17.3337H12.3307C12.9938 17.3337 13.6297 17.0703 14.0985
      16.6014C14.5673 16.1326 14.8307 15.4967 14.8307 14.8337V8.85033C14.8307 8.62931
      14.7429 8.41735 14.5867 8.26107C14.4304 8.10479 14.2184 8.01699 13.9974 8.01699ZM17.2641
      1.18366C17.1795 0.980034 17.0177 0.818221 16.8141 0.733659C16.7139 0.690958 16.6063 0.668309
      16.4974 0.666992H11.4974C11.2764 0.666992 11.0644 0.75479 10.9081 0.91107C10.7519 1.06735
      10.6641 1.27931 10.6641 1.50033C10.6641 1.72134 10.7519 1.9333 10.9081 2.08958C11.0644
      2.24586 11.2764 2.33366 11.4974 2.33366H14.4891L5.90573 10.9087C5.82762 10.9861 5.76563
      11.0783 5.72332 11.1798C5.68101 11.2814 5.65923 11.3903 5.65923 11.5003C5.65923 11.6103
      5.68101 11.7193 5.72332 11.8208C5.76563 11.9224 5.82762 12.0145 5.90573 12.092C5.9832
      12.1701 6.07537 12.2321 6.17692 12.2744C6.27846 12.3167 6.38739 12.3385 6.4974
      12.3385C6.60741 12.3385 6.71633 12.3167 6.81788 12.2744C6.91943 12.2321 7.01159
      12.1701 7.08906 12.092L15.6641 3.50866V6.50033C15.6641 6.72134 15.7519 6.9333 15.9081
      7.08958C16.0644 7.24586 16.2764 7.33366 16.4974 7.33366C16.7184 7.33366 16.9304
      7.24586 17.0867 7.08958C17.2429 6.9333 17.3307 6.72134 17.3307 6.50033V1.50033C17.3294
      1.39143 17.3068 1.28384 17.2641 1.18366Z`}
      fill={disabled ? customTheme.colors.cinza04 : color}
    />
  </svg>
)
