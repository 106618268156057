import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import React from 'react'
import { confirmarDialogStyles } from './ConfirmarDialog.style'
import { Botao } from '../Botao'

interface ConfirmarDialogProps {
  open: boolean
  setOpen: (value: boolean | ((prevVar: boolean) => boolean)) => void
  handleConfirm: any
  confirmarButtonLabel: string
  cancelarButtonLabel: string
  dialogTitle?: string
  children: React.ReactNode
  ocultarBotoes?: boolean
  tamanhoModal?: 'xl' | 'xs' | 'sm' | 'md' | 'lg'
}

export default function ConfirmarDialog({
  open,
  setOpen,
  handleConfirm,
  confirmarButtonLabel,
  cancelarButtonLabel,
  dialogTitle,
  children,
  ocultarBotoes,
  tamanhoModal
}: ConfirmarDialogProps) {
  const { CloseIcon, Dialog, DialogWithOutTitle, Box, DialogActions, BoxSemTitulo } = confirmarDialogStyles

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <Dialog open={open} keepMounted onClose={handleClose} maxWidth={tamanhoModal ? tamanhoModal : 'xs'}>
        {dialogTitle ? (
          <DialogTitle>
            <Box>
              {dialogTitle}
              <CloseIcon onClick={handleClose} />
            </Box>
          </DialogTitle>
        ) : (
          <DialogWithOutTitle>
            <BoxSemTitulo>
              <CloseIcon onClick={handleClose} />
            </BoxSemTitulo>
          </DialogWithOutTitle>
        )}
        <DialogContent>{children}</DialogContent>
        {!ocultarBotoes && (
          <DialogActions>
            <Botao textoBotao={cancelarButtonLabel} onClick={handleClose} estilo="text" cor="primaria" width="150px" />
            <Botao textoBotao={confirmarButtonLabel} onClick={handleConfirm} estilo="rounded" cor="primaria" width="150px" />
          </DialogActions>
        )}
      </Dialog>
    </>
  )
}
