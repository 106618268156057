import { useState } from 'react'
import { ItemInadimplenciaProps } from './ItemInadimplencia.View'

export function itemInadimplenciaIO({ item, checklistsSelecionados }: ItemInadimplenciaProps) {
  const [historicoAberto, setHistoricoAberto] = useState(false)

  const isCheckListSelecionado = () => {
    return checklistsSelecionados.includes(item.pedidoMkt)
  }

  return {
    historicoAberto,
    handleAbrirHistoricoToggle: () => setHistoricoAberto(!historicoAberto),
    isCheckListSelecionado,
    isPedidoComSugestaoPendente: !item.aprovado && item.classificacao != '',
    isPedidoAprovado: item.aprovado && item.classificacao != ''
  }
}

export type ItemInadimplenciaIO = ReturnType<typeof itemInadimplenciaIO>
