import { IOProps } from 'react-compose-io'
import { FormFieldsProps } from '../../../types/regraApiType'
import { FieldsTipoParametro } from '../FieldsTipoParametro'
import { FieldsTipoLancamentoIO } from './FieldsTipoLancamento.IO'

export type FieldsTipoLancamentoProps = {
  indexItensRegraForm: number
  itensCampo: FormFieldsProps[]
  cabecalhos: string[]
}
export function FieldsTipoLancamentoView({ _io, indexItensRegraForm, itensCampo, cabecalhos }: IOProps<FieldsTipoLancamentoIO, FieldsTipoLancamentoProps>) {
  return (
    <>
      {_io.acaoSelecionada[indexItensRegraForm]?.operacaoId === 'TipoLancamentoOperacao' && (
        <FieldsTipoParametro
          itensCampo={itensCampo}
          control={_io.control}
          cabecalhos={cabecalhos}
          indexItensRegraForm={indexItensRegraForm}
          itensRegraTransformacaoForm={_io.acaoSelecionada}
        />
      )}
    </>
  )
}
