import { Typography } from '@material-ui/core'
import styled from 'styled-components'
import { customTheme } from '../../../../styles/customTheme'

export const graficoTitulosParceiroStyles = {
  Container: styled.div`
    width: 100%;
    border-radius: 8px;
    background: ${customTheme.colors.white05};
    border-radius: 8px;
    padding: 10px;
    margin-bottom: 12px;
  `,
  ContainerGrafico: styled.div`
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  Conteudo: styled.div`
    margin: 0;
    padding: 0;
  `,
  ValorData: styled(Typography).attrs({
    variant: 'subtitle1'
  })`
    font-weight: 400;
    line-height: 23px;
    margin-left: 12px;
    color: ${customTheme.colors.grey03};
    align-items: center;
  `
}
