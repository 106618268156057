export enum DetalhePerdasActionsTypes {
  PREENCHER_POSSIVEIS_TRATATIVAS = 'PREENCHER_POSSIVEIS_TRATATIVAS',
  RESTAURAR_VALORES_APOS_CLASSIFICAR_PEDIDOS = 'RESTAURAR_VALORES_APOS_CLASSIFICAR_PEDIDOS',
  REINICIAR_PAGINACAO = 'REINICIAR_PAGINACAO',
  LIMPAR_FILTROS_DE_BUSCA = 'LIMPAR_FILTROS_DE_BUSCA',
  APROVAR_SUGESTAO_DE_CLASSIFICACAO = 'APROVAR_SUGESTAO_DE_CLASSIFICACAO',
  ABRIR_MODAL_CLASSIFICACAO_PEDIDOS = 'ABRIR_MODAL_CLASSIFICACAO_PEDIDOS',
  FECHAR_MODAL_CLASSIFICACAO_PEDIDOS = 'FECHAR_MODAL_CLASSIFICACAO_PEDIDOS',
  SELECIONAR_CHECK_GERAL = 'SELECIONAR_CHECK_GERAL',
  LIMPAR_CHECK_GERAL = 'LIMPAR_CHECK_GERAL',
  FECHAR_FILTRO = 'FECHAR_FILTRO',
  SELECIONAR_CHECK_FILTRO_PARCEIROS = 'SELECIONAR_CHECK_FILTRO_PARCEIROS',
  SELECIONAR_CHECK_FILTRO_TRATATIVAS = 'SELECIONAR_CHECK_FILTRO_TRATATIVAS',
  SELECIONAR_CHECK_FILTRO_CLASSIFICACOES = 'SELECIONAR_CHECK_FILTRO_CLASSIFICACOES',
  MUDAR_PAGINA = 'MUDAR_PAGINA',
  MUDAR_TAMANHO_PAGINA = 'MUDAR_TAMANHO_PAGINA',
  ABRIR_FILTRO_PEDIDOS = 'ABRIR_FILTRO_PEDIDOS',
  ABRIR_FILTRO_PARCEIROS = 'ABRIR_FILTRO_PARCEIROS',
  ABRIR_FILTRO_CLASSIFICACOES = 'ABRIR_FILTRO_CLASSIFICACOES',
  ABRIR_FILTRO_TRATATIVAS = 'ABRIR_FILTRO_TRATATIVAS'
}

export function preencherPossiveisTratativas(tratativas: string[]) {
  return {
    type: DetalhePerdasActionsTypes.PREENCHER_POSSIVEIS_TRATATIVAS,
    payload: {
      tratativas
    }
  }
}

export function restaurarValoresAposClassificarPedidos() {
  return {
    type: DetalhePerdasActionsTypes.RESTAURAR_VALORES_APOS_CLASSIFICAR_PEDIDOS
  }
}

export function reiniciarPaginacao() {
  return {
    type: DetalhePerdasActionsTypes.REINICIAR_PAGINACAO
  }
}

export function limparFiltros() {
  return {
    type: DetalhePerdasActionsTypes.LIMPAR_FILTROS_DE_BUSCA
  }
}

export function aprovarSugestaoClassificacao(pedidoMkt: string, classificacao: number) {
  return {
    type: DetalhePerdasActionsTypes.APROVAR_SUGESTAO_DE_CLASSIFICACAO,
    payload: {
      pedidoMkt,
      classificacao
    }
  }
}

export function abrirModalClassificacaoPedidos() {
  return {
    type: DetalhePerdasActionsTypes.ABRIR_MODAL_CLASSIFICACAO_PEDIDOS
  }
}

export function fecharModalClassificacaoPedidos() {
  return {
    type: DetalhePerdasActionsTypes.FECHAR_MODAL_CLASSIFICACAO_PEDIDOS
  }
}

export function selecionarCheckboxGeral(pedidoMkt: string) {
  return {
    type: DetalhePerdasActionsTypes.SELECIONAR_CHECK_GERAL,
    payload: {
      pedidoMkt
    }
  }
}

export function limparCheckboxGeral() {
  return {
    type: DetalhePerdasActionsTypes.LIMPAR_CHECK_GERAL
  }
}

export function fecharFiltro() {
  return {
    type: DetalhePerdasActionsTypes.FECHAR_FILTRO
  }
}

export function selecionarCheckboxFiltroParceiros(parceiroId: number) {
  return {
    type: DetalhePerdasActionsTypes.SELECIONAR_CHECK_FILTRO_PARCEIROS,
    payload: {
      parceiroId
    }
  }
}

export function selecionarCheckboxFiltroTratativas(tratativa: string) {
  return {
    type: DetalhePerdasActionsTypes.SELECIONAR_CHECK_FILTRO_TRATATIVAS,
    payload: {
      tratativa
    }
  }
}

export function selecionarCheckboxFiltroClassificacoes(classificacao: string) {
  return {
    type: DetalhePerdasActionsTypes.SELECIONAR_CHECK_FILTRO_CLASSIFICACOES,
    payload: {
      classificacao
    }
  }
}

export function mudarPagina(novaPagina: number) {
  return {
    type: DetalhePerdasActionsTypes.MUDAR_PAGINA,
    payload: {
      novaPagina
    }
  }
}

export function mudarTamanhoPagina(novoTamanhoPagina: number) {
  return {
    type: DetalhePerdasActionsTypes.MUDAR_TAMANHO_PAGINA,
    payload: {
      novoTamanhoPagina
    }
  }
}

export function abrirFiltroClassificacoes(currentTarget: EventTarget & HTMLButtonElement) {
  return {
    type: DetalhePerdasActionsTypes.ABRIR_FILTRO_CLASSIFICACOES,
    payload: {
      target: currentTarget
    }
  }
}

export function abrirFiltroParceiros(currentTarget: EventTarget & HTMLButtonElement) {
  return {
    type: DetalhePerdasActionsTypes.ABRIR_FILTRO_PARCEIROS,
    payload: {
      target: currentTarget
    }
  }
}

export function abrirFiltroPedidos(currentTarget: EventTarget & HTMLButtonElement) {
  return {
    type: DetalhePerdasActionsTypes.ABRIR_FILTRO_PEDIDOS,
    payload: {
      target: currentTarget
    }
  }
}

export function abrirFiltroTratativas(currentTarget: EventTarget & HTMLButtonElement) {
  return {
    type: DetalhePerdasActionsTypes.ABRIR_FILTRO_TRATATIVAS,
    payload: {
      target: currentTarget
    }
  }
}
