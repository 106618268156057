import { ConfiguracaoGeral, ParametroContabel, Sistemas, UnidadeNegocio, Evento, Fato } from '../../../types/configuracoesApiType'
import configuracoesService from './configuracoesService'

const buscarConfiguracoesCadastradas = async (): Promise<ConfiguracaoGeral[]> => configuracoesService.buscarConfiguracoesCadastradas()

const cadastrarConfiguracoes = async (request: ParametroContabel): Promise<void> => configuracoesService.cadastrarConfiguracoes(request)

const buscarSistemas = async (): Promise<Sistemas[]> => configuracoesService.buscarSistemas()

const buscarUnidadesNegocios = async (siglaSistema: string): Promise<UnidadeNegocio[]> => configuracoesService.buscarUnidadesNegocios(siglaSistema)

const buscarEventos = async (siglaSistema: string, unidadeId: number): Promise<Evento[]> => configuracoesService.buscarEventos(siglaSistema, unidadeId)

const buscarFatos = async (siglaSistema: string, unidadeId: number, eventoId: number): Promise<Fato[]> =>
  configuracoesService.buscarFatos(siglaSistema, unidadeId, eventoId)

export default {
  buscarConfiguracoesCadastradas,
  cadastrarConfiguracoes,
  buscarSistemas,
  buscarUnidadesNegocios,
  buscarEventos,
  buscarFatos
}
