import { useState } from 'react'
import { debitoInicialTransportadoraBoxStyles } from './DebitoInicialTransportadoraBox.styles'

export function debitoInicialTransportadoraBoxIO() {
  const [expandirDebitoInicial, setExpandirDebitoInicial] = useState(true)

  return {
    styles: debitoInicialTransportadoraBoxStyles,
    expandirDebitoInicial,
    setExpandirDebitoInicial
  }
}

export type DebitoInicialTransportadoraBoxIO = ReturnType<typeof debitoInicialTransportadoraBoxIO>
