import { Grid, Typography } from '@material-ui/core'
import styled from 'styled-components'
import { customTheme } from '../../styles/customTheme'
import { ContainerSombra } from '../ContainerSombra'

export const uploadPlanilhaRegularizacaoStyle = {
  Container: styled(ContainerSombra)`
    flex-grow: 1;
    padding: 24px;
  `,
  TemplateContainer: styled.div`
    flex-grow: 1;
    background-color: ${customTheme.colors.white05};
    border-radius: 4px;
    padding: 6px;
    margin-bottom: 18px;
  `,
  TypographyTemplateBody: styled(Typography)`
    color: ${customTheme.colors.grey03};
  `,
  DropContainer: styled.div.attrs({
    className: 'dropzone'
  })`
    border: 1px dashed ${customTheme.colors.grey00};
    padding: 2%;
    background-color: ${customTheme.colors.white06};
    border-radius: 10px;
    cursor: pointer;
    transition: height 0.2s ease;
    margin-bottom: 5px;
  `,
  DropSuccess: styled.div.attrs({
    className: 'dropzone-end'
  })`
    padding: 1%;
    background-color: ${customTheme.colors.grey08};
    border-radius: 10px;
    transition: height 0.2s ease;
    cursor: auto;
  `,
  SpanTitle: styled.span.attrs({
    className: 'span-title'
  })`
    color: white;
    font-size: ${customTheme.fontSize[12]};
    line-height: 1.2;
    text-overflow: ellipsis;
  `,
  SpanSize: styled.span.attrs({
    className: 'span-size'
  })`
    color: white;
    font-size: ${customTheme.fontSize[10]};
    text-overflow: ellipsis;
  `,
  UploadMessage: styled.p`
    display: flex;
    color: ${customTheme.colors.grey14};
    justify-content: center;
    align-items: center;
    margin: 5px;
  `,
  UploadButton: styled.span.attrs({
    className: 'filepond--label-action'
  })`
    background-color: ${customTheme.colors.blue06};
    color: white;
    padding: 5px 10px;
    border-radius: 18px;
    text-decoration: none;
  `,
  GridUploadInfo: styled(Grid)`
    display: flex;
    flex-direction: column;
  `,
  GridUploadInfoIndicador: styled(Grid)`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-right: 0px !important;
  `,
  DownloadButton: styled.a`
    background-color: ${customTheme.colors.blue09};
    cursor: pointer;
    width: 40px;
    height: 40px;
    display: flex;
    padding: 7px;
    border-radius: 4px;

    &:hover {
      opacity: 0.75;
    }
  `,
  Subtitulo: styled(Typography)`
    margin-top: 8px;
    margin-bottom: 24px;
  `
}
