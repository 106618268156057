import { SemOcorrenciaInadimplenciaIO } from './SemOcorrenciaInadimplencia.IO'
import { IOProps } from 'react-compose-io'
import { INADIMPLENCIA, Relatorio, TipoAnomaliaEnum } from '../../../../types/controleInadimplenciaTypes'
import { InadimplenciaAnomalia } from '../InadimplenciaAnomalia'
import { TooltipComponent } from '../../../../components/Tooltip/Tooltip'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import PerdaIcone from '../../../../assets/icons/perdaIcone.svg'
import AnaliseIcone from '../../../../assets/icons/analiseIcone.svg'
import { DadosMeses } from '../../../../utils/tratarDadosMesesUtils'

export type SemOcorrenciaInadimplenciaProps = {
  mesesSelecionados: number
  inadimplenciasEmAnalise: DadosMeses[]
  inadimplenciasPerda: DadosMeses[]
  relatoriosInadimplencia: Relatorio[]
  parceiroSelecionado: number
}

export function SemOcorrenciaInadimplenciaView({
  _io,
  inadimplenciasEmAnalise,
  inadimplenciasPerda,
  mesesSelecionados,
  relatoriosInadimplencia,
  parceiroSelecionado
}: IOProps<SemOcorrenciaInadimplenciaIO, SemOcorrenciaInadimplenciaProps>) {
  const { Box, Titulo, Divider } = _io.styles

  return (
    <Box>
      <Titulo>
        {'Sem ocorrência'}
        <TooltipComponent
          texto={`O painel Sem Ocorrência indica inadimplências efetuadoas pelos parceiros referente a títulos que não possuem ocorrências`}
          Icone={HelpOutlineIcon}
          color={'action'}
        />
      </Titulo>
      <InadimplenciaAnomalia
        dadosMensais={inadimplenciasEmAnalise}
        mesesSelecionados={mesesSelecionados}
        tituloAnomalia={'Em análise'}
        iconeAnomalia={AnaliseIcone}
        tipoAnomalia={INADIMPLENCIA.SEM_OCORRENCIA_EM_ANALISE}
        urlRelatorio={relatoriosInadimplencia.filter((x) => x.tipo === TipoAnomaliaEnum.SemOcorrenciaEmAnalise)[0]?.urlRelatorio}
        parceiroSelecionado={parceiroSelecionado}
      />
      <Divider />
      <InadimplenciaAnomalia
        dadosMensais={inadimplenciasPerda}
        mesesSelecionados={mesesSelecionados}
        tituloAnomalia={'Perda'}
        iconeAnomalia={PerdaIcone}
        tipoAnomalia={INADIMPLENCIA.PERDA}
        urlRelatorio={relatoriosInadimplencia.filter((x) => x.tipo === TipoAnomaliaEnum.SemOcorrenciaPerda)[0]?.urlRelatorio}
        parceiroSelecionado={parceiroSelecionado}
      />
    </Box>
  )
}
