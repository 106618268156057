import { Popover, Step, StepIconProps } from '@material-ui/core'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import { BadgeMessages } from '../../../../components/BadgeMessages/BadgeMessages'
import { StatusProgress } from '../../../../components/StatusProgress/StatusProgress'
import { CicloStatusGeral } from '../../../../types/ciclosApiType'
import { formateDate } from '../../../../utils/stringUtils'
import { IOProps } from 'react-compose-io'
import { CicloEtapaIO } from './CicloEtapa.IO'
import { Tipografia } from '../../../../components/Tipografia'
import { customTheme } from '../../../../styles/customTheme'

export type CicloEtapaProps = {
  statusCiclo: CicloStatusGeral
}

export default function CicloEtapaView({ _io }: IOProps<CicloEtapaIO, CicloEtapaProps>) {
  const { Container, StepLabel, ContainerHistorico, StepLine, Circle, Box, IconContainer, Stepper, FiberManualRecordIconAtivo } = _io.styles

  function HistoricoDados(): JSX.Element {
    return (
      <ContainerHistorico>
        <Tipografia fontWeight="500" fontSize={customTheme.fontSize[16]} colorText={customTheme.colors.grey04}>
          Histórico
        </Tipografia>
        {_io.historico?.map((item) => {
          return (
            <>
              <Tipografia colorText={customTheme.colors.grey04} fontSize={customTheme.fontSize[16]}>
                {item.observacao}
              </Tipografia>
              <Tipografia colorText={customTheme.colors.grey03} fontSize={customTheme.fontSize[16]}>
                {`${formateDate(item.dataAlteracao, true)} ${item.funcionario ? item.funcionario : ''}`}
              </Tipografia>
            </>
          )
        })}
      </ContainerHistorico>
    )
  }

  const renderIconStep = (currentStep: number, completed?: boolean) => {
    if (_io.isStepFailed(currentStep) || _io.isStepWarning(currentStep)) {
      return <FiberManualRecordIcon />
    } else if (completed) {
      return <FiberManualRecordIconAtivo />
    } else {
      return (
        <IconContainer>
          <Circle />
        </IconContainer>
      )
    }
  }

  function StepIcon(iconProps: StepIconProps) {
    const { completed, icon } = iconProps
    const stepToIcon = Number(icon) - 1
    return renderIconStep(stepToIcon, completed)
  }

  const renderEtapa = (index: number) => {
    if (_io.isStepFailed(index)) {
      return 'ERROR'
    } else if (_io.isStepWarning(index)) {
      return 'WARNING'
    } else {
      return 'DEFAULT'
    }
  }

  return (
    <Container>
      <Stepper activeStep={_io.activeStep} alternativeLabel connector={<StepLine />}>
        {_io.steps.map((label, index) => {
          return (
            <Step key={label}>
              <StepLabel data-step={index} StepIconComponent={StepIcon} onClick={index <= _io.activeStep ? _io.handleTooltipOpen : undefined}>
                {label}
                {index <= _io.activeStep ? (
                  <Box
                    mt={1}
                    sx={{
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      flexDirection: 'column'
                    }}
                  >
                    <BadgeMessages
                      descricao={(_io.descricaoHistorico && _io.descricaoHistorico[index + 1].textoCicloAvaliado) ?? ''}
                      etapa={renderEtapa(index)}
                    />
                    {_io.getMessage(index)}
                    {_io.isStepWarning(index) && (
                      <StatusProgress
                        etapaState={_io.etapa && _io.etapa?.statusTipo}
                        progress={_io.etapa && _io.etapa.progresso}
                        valueState={_io.etapa && _io.etapa.progresso}
                      />
                    )}
                  </Box>
                ) : null}
              </StepLabel>
            </Step>
          )
        })}
      </Stepper>

      <Popover
        id={_io.id}
        open={_io.open}
        anchorEl={_io.anchorEl}
        onClose={_io.handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
      >
        <HistoricoDados />
      </Popover>
    </Container>
  )
}
