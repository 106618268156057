import { useState } from 'react'
import { ORDEM_CRESCENTE, ORDEM_DECRESCENTE } from '../../constants/utilConstants'
import { Order } from '../../types/tabelaTypes'
import { handleRequestSort, tratarMudancaLinhaPorPagina, tratarMudancaPagina } from '../../utils/tabelaUtils'
import { tabelaStyles } from './Tabela.style'
import { TabelaProps } from './Tabela.View'

export function tabelaIO({ orderCampoPadrao, linhasPorPaginaPadrao, buscarItensTabela, ordenacaoPadrao }: TabelaProps) {
  const [ordemBy, setOrdemBy] = useState<Order>(ordenacaoPadrao ? ordenacaoPadrao : ORDEM_CRESCENTE)
  const [ordemCampo, setOrdemCampo] = useState<keyof never>(orderCampoPadrao)
  const [pagina, setPagina] = useState(0)
  const [linhasPorPagina, setLinhasPorPagina] = useState(linhasPorPaginaPadrao)

  const onMudancaPagina = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
    if (buscarItensTabela != undefined) {
      setPagina(newPage)
      buscarItensTabela({ pageNumber: newPage + 1, pageSize: linhasPorPagina, orderBy: ordemBy, orderField: ordemCampo })
      return
    }
    tratarMudancaPagina(event, newPage, setPagina)
  }

  const onMudancaLinhasPorPagina = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (buscarItensTabela != undefined) {
      setPagina(0)
      setLinhasPorPagina(parseInt(event.target.value, 10))
      buscarItensTabela({ pageNumber: 1, pageSize: parseInt(event.target.value, 10), orderBy: ordemBy, orderField: ordemCampo })
      return
    }

    tratarMudancaLinhaPorPagina(event, setPagina, setLinhasPorPagina)
  }

  const handleApiSort = (event: React.MouseEvent<unknown>, property: keyof any) => {
    if (buscarItensTabela) {
      const isAsc = ordemBy === ORDEM_CRESCENTE
      setOrdemBy(isAsc ? ORDEM_DECRESCENTE : ORDEM_CRESCENTE)
      setOrdemCampo(property)
      buscarItensTabela({ pageNumber: pagina + 1, pageSize: linhasPorPagina, orderBy: isAsc ? ORDEM_DECRESCENTE : ORDEM_CRESCENTE, orderField: property })
    }
  }

  return {
    styles: tabelaStyles,
    ordemBy,
    ordemCampo,
    pagina,
    linhasPorPagina,
    onMudancaPagina,
    onMudancaLinhasPorPagina,
    onRequestSort: buscarItensTabela ? handleApiSort : handleRequestSort(ordemCampo, ordemBy, setOrdemBy, setOrdemCampo)
  }
}

export type TabelaIO = ReturnType<typeof tabelaIO>
