import { Checkbox, TableHead, TableCell, TableSortLabel } from '@material-ui/core'
import styled from 'styled-components'
import { customTheme } from '../../styles/customTheme'

export const enhancedTableHeadStyles = {
  TableHead: styled(TableHead)`
    flex-grow: 1;
  `,
  Checkbox: styled(Checkbox).attrs((props: { colorcheckbox: string }) => props)`
    color: ${(props) => (props.colorcheckbox ? props.colorcheckbox : customTheme.colors.grey08)};
  `,
  TableCellAlingned: styled(TableCell).attrs((props: { aligncenter: number }) => props)`
    text-align: ${(props) => (props.aligncenter == 1 ? 'center' : 'left')};
  `,
  TableSortLabel: styled(TableSortLabel).attrs((props: { colortext: string }) => props)`
    color: ${(props) => (props.colortext ? props.colortext : customTheme.colors.grey08)};
  `
}
