import { MESES_ABREVIADOS_2_MESES } from '../../../../constants/utilConstants'
import { resumoInadimplenciaStyles } from './ResumoInadimplencia.style'
import { ResumoInadimplenciaProps } from './ResumoInadimplencia.View'

export function resumoInadimplenciaIO({ valoresGrafico, legendaGrafico, valorTotal }: ResumoInadimplenciaProps) {
  const gerarPorcentagensMensais = valoresGrafico.map((num) => (num / valorTotal) * 100).reverse()

  const gerarLegendasMensais = () => {
    const legendasMensais: string[] = []

    legendaGrafico.forEach((legenda) => {
      legendasMensais.push(`${MESES_ABREVIADOS_2_MESES.get(legenda[0])}`)
    })

    return legendasMensais.reverse()
  }

  return {
    styles: resumoInadimplenciaStyles,
    gerarPorcentagensMensais,
    gerarLegendasMensais: gerarLegendasMensais()
  }
}

export type ResumoInadimplenciaIO = ReturnType<typeof resumoInadimplenciaIO>
