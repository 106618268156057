import { PropTypes } from '@material-ui/core'
import Tooltip from '@material-ui/core/Tooltip'
import React, { ReactNode } from 'react'
import { useStyles } from './IconeButton.style'

export type IconeButtonProps = {
  descricao: string
  icone: ReactNode
  color?: PropTypes.Color
  acao?: (event: React.MouseEvent<HTMLButtonElement>) => void
}

const IconeButton = ({ color = 'primary', descricao, icone, acao }: IconeButtonProps): JSX.Element => {
  const { IconButtonPaddingSpacing1 } = useStyles

  return (
    <Tooltip title={descricao}>
      <IconButtonPaddingSpacing1 aria-label={descricao} onClick={acao} color={color}>
        {icone}
      </IconButtonPaddingSpacing1>
    </Tooltip>
  )
}

export default IconeButton
