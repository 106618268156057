import { Grid } from '@material-ui/core'
import { IOProps } from 'react-compose-io'
import { cabecalhoTransformacaoRegras } from '../../../../types/tabelaTypes'
import { AbaTabela } from './../AbaTabela'
import { TabTransformacaoIO } from './TabTransformacao.IO'
import { ItemTransformacao } from './../ItemTransformacao/index'
import { ModalPriorizar } from '../ModalPriorizar'
import { Botao } from './../../../../components/Botao/index'

export type TabTransformacaoProps = {}

export default function TabTransformacaoView({ _io }: IOProps<TabTransformacaoIO, TabTransformacaoProps>) {
  const { Container } = _io.styles

  function linhaTabela(props: { item: any; index: number }) {
    const { item, index } = props

    return <ItemTransformacao key={index} linhaItemTransformacao={item} index={index} atualizarDados={_io.buscarRegrasTransformacao} />
  }

  return (
    <>
      <Container>
        <Grid container direction="row" spacing={2} justifyContent="flex-end">
          <Grid item>
            <Botao onClick={_io.handlePriorizar} textoBotao="Priorizar" cor="primaria" estilo="outlined" />
          </Grid>
          <Grid item>
            <Botao onClick={_io.handleAdicionar} textoBotao="Adicionar" cor="primaria" estilo="contained" />
          </Grid>
        </Grid>
        <AbaTabela orderId={''} listItem={_io.regrasList} headCells={cabecalhoTransformacaoRegras} LinhaTabela={linhaTabela}></AbaTabela>
      </Container>
      <ModalPriorizar
        isShow={_io.modalAberto}
        isClosed={_io.fecharModal}
        atualizarDados={_io.buscarRegrasTransformacao}
        campos={_io.getCampos()}
        regrasList={_io.regrasList}
        parceiroId={_io.parceiroId}
      />
    </>
  )
}
