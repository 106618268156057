import { IOProps } from 'react-compose-io'
import { HistoricoRegularizacao } from '../../components/HistoricoRegularizacao'
import { ModalValidacaoPlanilhaRegularizacao } from '../../components/ModalValidacaoPlanilhaRegularizacao'
import { UploadPlanilhaRegularizacao } from '../../components/UploadPlanilhaRegularizacao'
import { RegularizacaoPerdasIO } from './RegularizacaoPerdas.IO'
import { Tipografia } from '../../components/Tipografia'

export type RegularizacaoPerdasProps = {}

export function RegularizacaoPerdasView({ _io }: IOProps<RegularizacaoPerdasIO, RegularizacaoPerdasProps>) {
  return (
    <>
      <Tipografia variant="h5">Regularização de perdas</Tipografia>
      <UploadPlanilhaRegularizacao
        validarPlanilha={_io.validarPlanilha}
        titulo={'Upload planilha de baixa de perdas'}
        subtitulo="Faça o upload da planilha para fazer a baixa de perdas."
        planilhaPadraoTexto={'Baixe a planilha padronizada antes de começar a importação. A lista de perdas deve estar no formato da planilha padrão.'}
        planilhaPadraoUrl={process.env.REACT_APP_PLANILHA_PADRAO_REGULARIZACAO_PERDAS}
        setArquivoId={_io.setArquivoId}
      />
      <ModalValidacaoPlanilhaRegularizacao
        abrirModalValidarPlanilha={_io.abrirModalValidarPlanilha}
        handleFecharModalValidarPlanilha={_io.handleFecharModalValidarPlanilha}
        handleConfirmarDados={() => _io.efetivarRegularizacaoPerdas()}
        itemListaValidado={_io.informacaoValidadaModal}
        mensagemErroUploadPlanilha={_io.mensagemErroUploadPlanilha}
        descricaoBaixas={'Perdas:'}
      />
      <HistoricoRegularizacao
        historicosRegularizacao={_io.historicosRegularizacao.itens}
        totalItens={_io.totalLinhas}
        buscarHistorico={_io.atualizarHistoricoRegularizacaoPerdas}
        valorSetTimeOut={_io.valorSetTimeOut}
        descricaoBaixas={'Perdas baixadas:'}
      />
    </>
  )
}
