import { customTheme } from '../../../../styles/customTheme'
import { Divider, Typography, Grid } from '@material-ui/core'
import styled from 'styled-components'
import { ContainerSombra } from '../../../../components/ContainerSombra'

export const debitoBoxStyles = {
  Box: styled(ContainerSombra).attrs((props: { transportadora: number }) => props)`
    margin: auto;
    margin-bottom: 16px;

    ${(props) => boxStyle(props)}
  `,
  BoxLegenda: styled(ContainerSombra)`
    padding: 20px 36px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 360px;
  `,
  Divider: styled(Divider)`
    width: -webkit-fill-available;
    margin: 8px 0px;
  `,
  SubTitulo: styled(Typography).attrs({
    variant: 'subtitle1'
  })`
    line-height: 19px;
    margin: 10px 0px;
    font-weight: bold;
  `,
  TypographyPorcentagem: styled(Typography).attrs({
    variant: 'h4'
  })`
    background-color: ${customTheme.colors.blue15};
    padding: 8px;
    border-radius: 10px;
    font-weight: bold;
  `,
  GridWidthLimitada: styled(Grid)`
    max-width: 475px;
  `
}

function boxStyle(props: { transportadora: number }) {
  return props.transportadora == 1
    ? `
          width: 1000px;
          background-color: ${customTheme.colors.grey20};
          padding: 24px;
          margin-bottom: 16px;
        `
    : `padding: 16px;`
}
