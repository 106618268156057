import { Grid } from '@material-ui/core'
import styled from 'styled-components'
import Img404 from '../../assets/images/404.jpg'

const IMG = styled.img`
  height: 100%;
  width: 100%;
`
const PaginaNaoEncontrada = () => {
  return (
    <Grid container justifyContent="center" direction="column">
      <IMG src={Img404} alt="Error 404" />
    </Grid>
  )
}

export default PaginaNaoEncontrada
