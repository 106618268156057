import { IOProps } from 'react-compose-io'
import { StatusProgress } from '../../../../components/StatusProgress/StatusProgress'
import { Ciclos } from '../../../../types/ciclosApiType'
import { formateCurrency, formateDate, validarTexto } from '../../../../utils/stringUtils'
import { LinhaTabelaCicloIO } from './LinhaTabelaCiclo.IO'
import { BadgeMessages } from '../../../../components/BadgeMessages/BadgeMessages'
import { Tipografia } from './../../../../components/Tipografia/index'
import { Grid } from '@material-ui/core'

export type LinhaTabelaCicloProps = {
  item: Ciclos
}

export function LinhaTabelaCicloView({ _io, item }: IOProps<LinhaTabelaCicloIO, LinhaTabelaCicloProps>) {
  const { TableRow, TableCell, TableCellStatus } = _io.styles

  return (
    <TableRow onClick={_io.verificarCiclo}>
      <TableCell>{validarTexto(item.cicloId?.toLocaleString('pt-BR'), '-')}</TableCell>
      <TableCell>{formateDate(item.dataEfetivacao)}</TableCell>
      <TableCell>{item.valorTotalRepasse ? formateCurrency(item.valorTotalRepasse) : '-'}</TableCell>
      <TableCell>
        <Grid container direction="row">
          <Tipografia fontWeight="600" variant="body2">
            Data inicial: &nbsp;
          </Tipografia>
          <Tipografia fontWeight="400" variant="body2">
            {formateDate(item.dataInicialParceiro)}
          </Tipografia>
        </Grid>
        <Grid container direction="row">
          <Tipografia fontWeight="600" variant="body2">
            Data final: &nbsp;
          </Tipografia>
          <Tipografia fontWeight="400" variant="body2">
            {formateDate(item.dataFinalPrevistaParceiro)}
          </Tipografia>
        </Grid>
        {item.comentariosCicloParceiro && (
          <Grid container direction="row">
            <Tipografia fontWeight="600" variant="body2">
              Comentários: &nbsp;
            </Tipografia>
            <Tipografia fontWeight="400" variant="body2">
              {validarTexto(item.comentariosCicloParceiro, '-')}
            </Tipografia>
          </Grid>
        )}
      </TableCell>
      <TableCellStatus>
        <BadgeMessages etapa={item.statusTipo} descricao={item.status} />
      </TableCellStatus>
      <TableCell>
        <StatusProgress etapaState={`${item.statusTipo}`} progress={item?.progressoCiclo} valueState={item?.progressoCiclo} />
      </TableCell>
    </TableRow>
  )
}
