import styled from 'styled-components'
import { customTheme } from '../../../../styles/customTheme'

export const tabelaResultadosOPSStyles = {
  CicloVinculadoContainer: styled.div`
    color: ${customTheme.colors.cinza04};
    border: 1px solid ${customTheme.colors.cinza04};
    border-radius: 4px;
    font-size: 1rem;
    font-weight: 500;
  `
}
