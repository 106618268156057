import { Collapse, IconButton, TableCell, TableRow, Checkbox, Grid } from '@material-ui/core'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import { IOProps } from 'react-compose-io'
import { DivergenciaPedidoMktResponse } from '../../../../types/cicloPedidosTypes'
import { cabecalhoControleComissao } from '../../../../types/tabelaTypes'
import { formateDate, formateHoras } from '../../../../utils/stringUtils'
import { ItemCicloPedidoIO } from './ItemCicloPedido.IO'
import { Tipografia } from './../../../../components/Tipografia/index'
import { customTheme } from '../../../../styles/customTheme'
import { Dispatch, SetStateAction } from 'react'

export type ItemCicloPedidoProps = {
  item: DivergenciaPedidoMktResponse
  checklistsSelecionados: string[]
  setChecklistsSelecionados: Dispatch<SetStateAction<string[]>>
}

export function ItemCicloPedidoView({ _io, item }: IOProps<ItemCicloPedidoIO, ItemCicloPedidoProps>) {
  const { TableRowHistorico, TableCellHistorico, ContainerHistorico, BoxHistorico } = _io.styles

  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={_io.handleAbrirHistoricoToggle}>
            {_io.historicoAberto ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>
          <Checkbox checked={_io.isCheckListSelecionado()} onChange={_io.handleCheckList} />
        </TableCell>
        <TableCell>{item.pedidoMkt}</TableCell>
        <TableCell>{item.pedidoEfacil}</TableCell>
        <TableCell>{item.statusParceiro}</TableCell>
        <TableCell>{item.status}</TableCell>
        <TableCell>{formateDate(item.dataEmissaoPedido)}</TableCell>
      </TableRow>
      <TableRowHistorico>
        <TableCellHistorico colSpan={cabecalhoControleComissao.length}>
          <Collapse in={_io.historicoAberto} timeout="auto" unmountOnExit>
            <BoxHistorico>
              <Tipografia variant="subtitle1" fontWeight="600">
                Observações
              </Tipografia>
              <ContainerHistorico>
                {item.historicos.length <= 1 ? (
                  <Tipografia fontWeight="500" colorText={customTheme.colors.grey21}>
                    Este pedido não possui nenhuma observação registrada.
                  </Tipografia>
                ) : (
                  <Grid container spacing={2} direction="column">
                    {item.historicos.slice(0, -1).map((historico, id) => {
                      return (
                        <Grid item key={id}>
                          <Tipografia fontWeight="600" colorText={customTheme.colors.grey21} variant="body2">
                            {historico.observacao}
                          </Tipografia>
                          <Tipografia variant="body2">
                            {`${formateDate(historico.data)} às ${formateHoras(historico.data)} | Usuário: ${historico.usuario}`}
                          </Tipografia>
                        </Grid>
                      )
                    })}
                  </Grid>
                )}
              </ContainerHistorico>
            </BoxHistorico>
          </Collapse>
        </TableCellHistorico>
      </TableRowHistorico>
    </>
  )
}
