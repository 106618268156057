import { Divider } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import styled from 'styled-components'
import { ContainerSombra } from '../../../../components/ContainerSombra'
import { Tipografia } from '../../../../components/Tipografia'
import { customTheme } from '../../../../styles/customTheme'

export const inadimplenciaAnomaliaStyles = {
  GridContainer: styled(Grid)`
    margin-top: 16px;
  `,
  GridItem: styled(Grid)`
    display: flex;
    align-items: center;
  `,
  Image: styled.img`
    width: 80px;
    margin-right: 16px;
  `,
  TipografiaMes: styled(Tipografia)`
    display: block;
    height: 80px;
    width: 80px;
    line-height: 80px;
    -moz-border-radius: 60px;
    border-radius: 60px;
    background-color: ${customTheme.colors.grey01};
    text-align: center;
    margin-right: 16px;
  `,
  Divider: styled(Divider)`
    height: 80px;
  `,
  BoxResumo: styled(ContainerSombra)`
    margin: auto;
    padding: 16px;
    margin-top: 24px;
    margin-bottom: 24px;
    max-width: fit-content;
  `,
  Container: styled.div`
    max-width: 1002px;
    margin: auto;
  `
}
