import { DetalhesPedidoMovimentoContabil, ResumoPedidosInternalizacao } from '../../../types/pedidosTypes'
import pedidosService from './pedidosService'

const buscarPedidoInternalizado = async (pedidoId: string): Promise<ResumoPedidosInternalizacao> => pedidosService.buscarPedidoInternalizado(pedidoId)

const buscarPedidoMovimentoContabil = async (pedidoId: string): Promise<DetalhesPedidoMovimentoContabil[]> =>
  pedidosService.buscarPedidoMovimentoContabil(pedidoId)

export default {
  buscarPedidoInternalizado,
  buscarPedidoMovimentoContabil
}
