/* eslint-disable sonarjs/cognitive-complexity */
import { Dispatch, useEffect, useState, SetStateAction } from 'react'
import { CicloStatus, CicloStatusGeral } from '../../../../types/ciclosApiType'
import { formateDate } from '../../../../utils/stringUtils'
import { stylesCicloEtapa } from './CicloEtapa.style'
import { CicloEtapaProps } from './CicloEtapa.View'

export type DescricaoHistorico = {
  textoCicloAvaliado?: string
  dataCicloAvaliado?: string | Date
  funcionarioCicloAvaliado?: string
  detalheObservacao?: string
}

function verificarTipo(
  tipo: string | undefined,
  setFailStep: Dispatch<SetStateAction<number>>,
  activeIndex: number,
  setWarningStep: Dispatch<SetStateAction<number>>
) {
  if (tipo === 'ERROR') {
    setFailStep(activeIndex)
  } else if (tipo === 'WARNING') {
    setWarningStep(activeIndex)
  }
}

function setarHistoricoEtapa(idStep: number, setHistorico: Dispatch<SetStateAction<CicloStatus[] | undefined>>, statusCiclo: CicloStatusGeral) {
  if (idStep == 0) {
    setHistorico(statusCiclo.internalizacao)
  } else if (idStep == 1) {
    setHistorico(statusCiclo.ops)
  } else if (idStep == 2) {
    setHistorico(statusCiclo.validacao)
  } else if (idStep == 3) {
    setHistorico(statusCiclo.conciliacao)
  }
}

function ipPopover(open: boolean) {
  return open ? 'simple-popover' : undefined
}

export function cicloEtapaIO({ statusCiclo }: CicloEtapaProps) {
  const [activeStep, setActiveStep] = useState(-1)
  const [failStep, setFailStep] = useState(-1)
  const [warningStep, setWarningStep] = useState(-1)
  const [descricaoHistorico, setDescricaoHistorico] = useState<DescricaoHistorico[]>()
  const steps = ['Internalizar', 'Vincular OP`s', 'Validação', 'Conciliação financeira']
  const [historico, setHistorico] = useState<CicloStatus[]>()
  const [anchorEl, setAnchorEl] = useState<any>(null)
  const [etapa, setEtapa] = useState<CicloStatus | undefined>()

  const handleTooltipOpen = (event: any) => {
    const idStep: number = event.currentTarget.getAttribute('data-step')

    setarHistoricoEtapa(idStep, setHistorico, statusCiclo)
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = ipPopover(open)

  useEffect(() => {
    let ultimoHistorico: CicloStatus = {
      dataAlteracao: '1',
      etapaAntiga: '1',
      etapaAntigaId: '1',
      etapaNova: '1',
      etapaNovaId: '1',
      observacao: '1',
      funcionario: '1',
      funcionarioId: 1,
      progresso: 0,
      detalheObservacao: ''
    }
    let indexUltimoHistorico = -1

    const msg: DescricaoHistorico[] = [{}]

    let textoUltimoHistorico = ''
    let dataUltimoHistorico = ''
    let funcionarioUltimoHistorico = ''

    if (statusCiclo?.internalizacao.length > 0) {
      ultimoHistorico = statusCiclo.internalizacao[0]
      indexUltimoHistorico = 0
      textoUltimoHistorico = ultimoHistorico.observacao
      dataUltimoHistorico = formateDate(ultimoHistorico.dataAlteracao, true)
      msg.push({
        textoCicloAvaliado: textoUltimoHistorico,
        dataCicloAvaliado: dataUltimoHistorico,
        detalheObservacao: ultimoHistorico.detalheObservacao
      })
      setEtapa(ultimoHistorico)
    }

    if (statusCiclo?.ops.length > 0) {
      ultimoHistorico = statusCiclo.ops[0]
      indexUltimoHistorico = 1
      textoUltimoHistorico = ultimoHistorico.observacao
      dataUltimoHistorico = formateDate(ultimoHistorico.dataAlteracao, true)
      funcionarioUltimoHistorico = ultimoHistorico.funcionario ? ultimoHistorico.funcionario?.split(' ')[0] : ''
      msg.push({
        textoCicloAvaliado: textoUltimoHistorico,
        dataCicloAvaliado: dataUltimoHistorico,
        funcionarioCicloAvaliado: funcionarioUltimoHistorico,
        detalheObservacao: ultimoHistorico.detalheObservacao
      })
      setEtapa(ultimoHistorico)
    }

    if (statusCiclo?.validacao.length > 0) {
      ultimoHistorico = statusCiclo.validacao[0]
      indexUltimoHistorico = 2
      textoUltimoHistorico = ultimoHistorico.observacao
      dataUltimoHistorico = formateDate(ultimoHistorico.dataAlteracao, true)
      funcionarioUltimoHistorico = ultimoHistorico.funcionario ? ultimoHistorico.funcionario?.split(' ')[0] : ''

      msg.push({
        textoCicloAvaliado: textoUltimoHistorico,
        dataCicloAvaliado: dataUltimoHistorico,
        funcionarioCicloAvaliado: funcionarioUltimoHistorico,
        detalheObservacao: ultimoHistorico.detalheObservacao
      })
      setEtapa(ultimoHistorico)
    }

    if (statusCiclo?.conciliacao.length > 0) {
      ultimoHistorico = statusCiclo.conciliacao[0]
      indexUltimoHistorico = 3
      if (ultimoHistorico.statusTipo == 'SUCCESS') indexUltimoHistorico = 4
      textoUltimoHistorico = ultimoHistorico.observacao
      dataUltimoHistorico = formateDate(ultimoHistorico.dataAlteracao, true)
      msg.push({ textoCicloAvaliado: textoUltimoHistorico, dataCicloAvaliado: dataUltimoHistorico, detalheObservacao: ultimoHistorico.detalheObservacao })
    }
    setDescricaoHistorico({ ...msg })

    handleSteps(indexUltimoHistorico, ultimoHistorico.statusTipo)
    setEtapa(ultimoHistorico)
  }, [statusCiclo, etapa])

  const handleSteps = (activeIndex: number, tipo?: string) => {
    setActiveStep(activeIndex)
    setWarningStep(-1)
    setFailStep(-1)

    verificarTipo(tipo, setFailStep, activeIndex, setWarningStep)
  }

  const isStepFailed = (stepFail: number) => {
    return stepFail === failStep
  }

  const isStepWarning = (stepWarning: number) => {
    return stepWarning === warningStep
  }

  const getMessage = (index: number) => {
    if (!(isStepFailed(index) || isStepWarning(index)) && descricaoHistorico) {
      return `${descricaoHistorico[index + 1].funcionarioCicloAvaliado ?? ''} ${descricaoHistorico[index + 1].dataCicloAvaliado}`
    } else {
      return (descricaoHistorico && descricaoHistorico[index + 1].detalheObservacao) ?? ''
    }
  }

  return {
    styles: stylesCicloEtapa,
    historico,
    isStepFailed,
    isStepWarning,
    descricaoHistorico,
    activeStep,
    steps,
    handleTooltipOpen,
    etapa,
    id,
    open,
    anchorEl,
    handlePopoverClose,
    getMessage
  }
}

export type CicloEtapaIO = ReturnType<typeof cicloEtapaIO>
