import { TableCell } from '@material-ui/core'
import { IOProps } from 'react-compose-io'
import { Tabela } from '../../components/Tabela/index'
import { DetalhesRegularizacaoPendente } from '../../types/regularizacaoOPsTitulosTypes'
import { cabecalhoRegularizacaoPendente } from '../../types/tabelaTypes'
import { formateCurrency, formateDate, validarTexto } from '../../utils/stringUtils'
import { ContainerPesquisa } from './components/ContainerPesquisa'
import { RegularizacaoCiclosInformacoesIO } from './RegularizacaoCiclosInformacoes.IO'
import { Tipografia } from './../../components/Tipografia/index'

export type PendenciasRegularizacaoProps = {}

export function RegularizacaoCiclosInformacoesView({ _io }: IOProps<RegularizacaoCiclosInformacoesIO, PendenciasRegularizacaoProps>) {
  const { ContainerTable, TableRow } = _io.styles

  function linhaTabela(props: { item: DetalhesRegularizacaoPendente }) {
    const { item } = props

    return (
      <TableRow hover onClick={(event) => _io.handleDirecionarRegularizacao(event, item.pedidoMkp)}>
        <TableCell>{item.ciclo.toLocaleString('pt-BR')}</TableCell>
        <TableCell>{validarTexto(item.parceiro, '-')}</TableCell>
        <TableCell>{item.pedidoMkp}</TableCell>
        <TableCell>{validarTexto(item.clienteId, '-')}</TableCell>
        <TableCell>{item.parcela}</TableCell>
        <TableCell>{formateCurrency(item.valorSaldo)}</TableCell>
        <TableCell>{formateDate(item.dataPagamento)}</TableCell>
      </TableRow>
    )
  }

  return (
    <>
      <Tipografia variant="h5">Pesquisa</Tipografia>
      <ContainerPesquisa
        listaParceirosAtivos={_io.parceirosAtivos}
        control={_io.control}
        limparFormularioPesquisa={_io.limparFormularioPesquisa}
        aplicarFiltro={_io.handleSubmit(_io.buscarValorPesquisado)}
        itemFormularioPreenchido={_io.formularioPreenchido}
      />
      <>
        {_io.pendenciaRegularizacao && _io.pendenciaRegularizacao.itens.length > 0 && (
          <>
            <ContainerTable>
              <Tipografia variant="h6">Resultados</Tipografia>
              <Tabela
                cabecalho={cabecalhoRegularizacaoPendente}
                itensLista={_io.pendenciaRegularizacao.itens}
                LinhaTabela={linhaTabela}
                orderCampoPadrao={'ciclo'}
                rowsPerPageOptions={[25, 50, 100]}
                linhasPorPaginaPadrao={25}
                maxHeight={'none'}
                alinharTextoCentro={false}
                totalItens={_io.pendenciaRegularizacao.totalItens}
                buscarItensTabela={_io.aplicarFiltro}
              />
            </ContainerTable>
          </>
        )}
      </>
    </>
  )
}
