import { Grid, Popover, Typography } from '@material-ui/core'
import { IOProps } from 'react-compose-io'
import FilterIcon from '../../../../assets/icons/filter-icon.svg'
import { GridCheckList } from '../../../../components/GridCheckList'
import { ControleComissaoStatus, ControleComissaoStatusMap } from '../../../../types/controleComissaoTypes'
import { Parceiro } from '../../../../types/parceiroApiTypes'
import { PopoverFiltroControleComissaoIO } from './PopoverFiltroControleComissao.IO'
import { Botao } from './../../../../components/Botao/index'

export type PopoverFiltroControleComissaoProps = {
  parceirosAtivos: Parceiro[]
  handleAplicarFiltro: () => void
  setFiltroParceirosLista: (value: React.SetStateAction<number[]>) => void
  setFiltroStatusLista: (value: React.SetStateAction<number[]>) => void
  filtroParceirosLista: number[]
  filtroStatusLista: number[]
}

export function PopoverFiltroControleComissaoView({ _io, parceirosAtivos }: IOProps<PopoverFiltroControleComissaoIO, PopoverFiltroControleComissaoProps>) {
  const { ContainerBotao, ContainerFiltro } = _io.styles

  return (
    <>
      <ContainerBotao>
        <img src={FilterIcon} onClick={_io.handleFiltroIconeAncora} />
      </ContainerBotao>
      <Popover keepMounted anchorEl={_io.abrirFiltro} open={Boolean(_io.abrirFiltro)} onClose={_io.handleAnularIconeAncora}>
        <ContainerFiltro>
          <Typography variant="h6">Filtros</Typography>
          <Grid container direction="column">
            <Typography variant="subtitle1">Status</Typography>
            <GridCheckList
              valorId={ControleComissaoStatus.Concluido}
              handleCheckList={_io.handleCheckListStatus}
              label={ControleComissaoStatusMap.get(ControleComissaoStatus.Concluido)}
              valorChecked={_io.isStatusSelecionado(ControleComissaoStatus.Concluido)}
            />
            <GridCheckList
              valorId={ControleComissaoStatus.EmAndamento}
              handleCheckList={_io.handleCheckListStatus}
              label={ControleComissaoStatusMap.get(ControleComissaoStatus.EmAndamento)}
              valorChecked={_io.isStatusSelecionado(ControleComissaoStatus.EmAndamento)}
            />
            <GridCheckList
              valorId={ControleComissaoStatus.Pendente}
              handleCheckList={_io.handleCheckListStatus}
              label={ControleComissaoStatusMap.get(ControleComissaoStatus.Pendente)}
              valorChecked={_io.isStatusSelecionado(ControleComissaoStatus.Pendente)}
            />
            <Typography variant="subtitle1">Parceiros</Typography>
            {parceirosAtivos.map((parceiro) => {
              return (
                <GridCheckList
                  key={parceiro.parceiroId}
                  valorId={parceiro.parceiroId}
                  handleCheckList={_io.handleCheckListParceiro}
                  label={parceiro.nomeFantasia}
                  valorChecked={_io.isParceiroSelecionado(parceiro.parceiroId)}
                />
              )
            })}
          </Grid>
          <Grid container justifyContent="center" spacing={2}>
            <Grid item>
              <Botao onClick={_io.handleLimpar} textoBotao="Limpar" cor="primaria" estilo="text" />
            </Grid>
            <Grid item>
              <Botao onClick={_io.aplicarFiltro} textoBotao="Aplicar" cor="primaria" estilo="rounded" />
            </Grid>
          </Grid>
        </ContainerFiltro>
      </Popover>
    </>
  )
}
