import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import msgConstants from '../../../../constants/msgConstants'
import { PAGES } from '../../../../router/routes'
import TransformacaoManager from '../../../../services/api/transformacao/transformacaoManager'
import { loadingReset, loadingUpdate } from '../../../../store/actions/loadingAction'
import { GrupoRegra, RegraTransformacao } from '../../../../types/transformacaoApiTypes'
import { converterToAcaoTransformacaoForm, converterToCondicaoTransformacaoForm } from '../../../../utils/regraFormsUtils'
import { itemTransformacaoStyles } from './ItemTransformacao.style'
import { ItemTransformacaoProps } from './ItemTransformacao.View'

export function itemTransformacaoIO({ atualizarDados }: ItemTransformacaoProps) {
  const dispatch = useDispatch()
  const router = useHistory()
  const { parceiroId } = useParams<{ parceiroId: string }>()
  const [abrir, setAbrir] = useState(false)
  const [ancoraAtiva, setAncoraAtiva] = useState<null | HTMLElement>(null)

  const handleAlterarIconeAncora = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAncoraAtiva(event.currentTarget)
  }

  const handleAnularIconeAncora = () => {
    setAncoraAtiva(null)
  }

  const desativarRegraTransformacao = (data: RegraTransformacao) => {
    dispatch(loadingUpdate(true))
    const dataEdit = {
      ...data,
      ativo: !data.ativo,
      grupos: data.grupos.map((item: GrupoRegra) => item.grupoId),
      acoes: converterToAcaoTransformacaoForm(data.acoes),
      condicoes: converterToCondicaoTransformacaoForm(data.condicoes)
    }

    TransformacaoManager.editarRegra(dataEdit, parceiroId, dataEdit.regraId)
      .then(() => {
        toast.success(msgConstants.USO_GENERICO.desativar_sucesso)
        atualizarDados()
      })
      .catch((error: Error) => {
        toast.error(error.message)
      })
    dispatch(loadingReset())
  }

  const editarRegraTransformacao = (regraSelecionada: RegraTransformacao) => {
    router.push(PAGES.regraTransformacaoFormEditar(parceiroId, String(regraSelecionada.regraId)))
  }

  return {
    styles: itemTransformacaoStyles,
    abrir,
    handleAbrirToogle: () => setAbrir(!abrir),
    ancoraAtiva,
    handleAlterarIconeAncora,
    handleAnularIconeAncora,
    editarRegraTransformacao,
    desativarRegraTransformacao,
    parceiroId
  }
}

export type ItemTransformacaoIO = ReturnType<typeof itemTransformacaoIO>
