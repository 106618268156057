import { useEffect, useState } from 'react'
import { StatusProcessamento } from '../../types/genericoType'
import { cardValorQuantidadeStyles } from './CardValorQuantidade.styles'
import { CardValorQuantidadeProps } from './CardValorQuantidade.View'

export function cardValorQuantidadeIO({ status }: CardValorQuantidadeProps) {
  const [relatorioDisponivel, setRelatorioDisponivel] = useState(false)
  const [carregandoRelatorio, setCarregandoRelatorio] = useState(false)

  useEffect(() => {
    if (status == null) {
      setCarregandoRelatorio(false)
      setRelatorioDisponivel(false)
      return
    }
    if (status == StatusProcessamento.PROCESSANDO) {
      setCarregandoRelatorio(true)
      setRelatorioDisponivel(false)
    }
    if (status == StatusProcessamento.CONCLUIDO) {
      setCarregandoRelatorio(false)
      setRelatorioDisponivel(true)
    }
  }, [status])

  return {
    styles: cardValorQuantidadeStyles,
    relatorioDisponivel,
    carregandoRelatorio
  }
}
export type CardValorQuantidadeIO = ReturnType<typeof cardValorQuantidadeIO>
