import { customTheme } from '../../styles/customTheme'
import { formateCurrency } from '../../utils/stringUtils'
import { MoedaColoridaProps } from './MoedaColorida.View'

export function moedaColoridaIO({ valor }: MoedaColoridaProps) {
  const valorCurrency = formateCurrency(valor)
  const adicionaMais = !valorCurrency.includes('-')
  const newValorCurrency = `${adicionaMais ? '+ ' : ''}${valorCurrency}`
  const sinal = newValorCurrency.slice(0, 1)
  const valorFormatado = newValorCurrency.slice(1)
  const cor = adicionaMais ? customTheme.colors.verde05 : customTheme.colors.vermelho05
  return {
    sinal,
    valorFormatado,
    cor
  }
}

export type MoedaColoridaIO = ReturnType<typeof moedaColoridaIO>
