import { Box, TableRow } from '@material-ui/core'
import styled from 'styled-components'
import { customTheme } from '../../../../styles/customTheme'

export const itemAssociacaoStyles = {
  TableRowHistorico: styled(TableRow)`
    background-color: ${customTheme.colors.blue01};
  `,
  BoxHistorico: styled(Box)`
    padding: 8px;
  `
}
