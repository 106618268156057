import { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import msgConstants from '../../constants/msgConstants'
import { TIPO_PARAMETRO } from '../../constants/utilConstants'
import { useConciliacaoContext } from '../../contexts/conciliacao-context'
import { PAGES } from '../../router/routes'
import ciclosManager from '../../services/api/ciclos/ciclosManager'
import TransformacaoManager from '../../services/api/transformacao/transformacaoManager'
import { loadingReset, loadingUpdate } from '../../store/actions/loadingAction'
import { ErrosProcessamento } from '../../types/ciclosApiType'
import { Acao, Condicao } from '../../types/regraApiType'
import { GrupoRegra, GrupoRegraSelect, PreviewItemTransformacao, RegraTransformacaoForm } from '../../types/transformacaoApiTypes'
import * as Styles from './AdicionarNovoParametro.style'

const defaultValues: RegraTransformacaoForm = {
  nomeRegra: '',
  campo: 'TipoLancamento',
  condicoes: [
    {
      operacaoId: '',
      dado: '',
      operador: '',
      parametro: {
        tipoParametro: TIPO_PARAMETRO.criterio,
        parametro: ''
      },
      tipoCondicao: 'E'
    }
  ],
  acoes: [
    {
      operacaoId: '',
      dado: '',
      operador: '',
      parametro: {
        tipoParametro: TIPO_PARAMETRO.criterio,
        parametro: ''
      }
    }
  ],
  grupos: []
}

export function adicionarNovoParametroIO() {
  const linhaPadrao: ErrosProcessamento = {
    pageNumber: 1,
    pageSize: 1,
    totalItens: 1,
    totalPages: 1,
    itens: [],
    headers: [],
    isLinhasTratadas: false
  }

  const [erroProcessamentoLista, setErroProcessamentoLista] = useState<ErrosProcessamento>(linhaPadrao)
  const dispatch = useDispatch()
  const { headersData, selectedsErroProcessamento, setSelectedsErroProcessamento, getHeadersPlanilha } = useConciliacaoContext()
  const [grupoRegraLista, setGrupoRegraLista] = useState<GrupoRegra[]>([])
  const [condicaoLista, setCondicaoLista] = useState<Condicao[]>([])
  const [acaoLista, setAcaoLista] = useState<Acao[]>([])
  const [abrirModal, setAbrirModal] = useState(false)
  const [preVisualizar, setpreVisualizar] = useState<PreviewItemTransformacao>()
  const [salvarDados, setSalvarDados] = useState(false)
  const router = useHistory()

  const campoDados = headersData || []

  const { parceiroId, cicloId } = useParams<{ parceiroId: string; cicloId: string }>()

  const metodosFormulario = useForm<RegraTransformacaoForm>({
    defaultValues
  })

  useEffect(() => {
    async function buscarCondicoes() {
      const response: Condicao[] = await TransformacaoManager.buscarCondicoes()
      setCondicaoLista(response)
    }
    async function buscarAcoes() {
      const response: Acao[] = await TransformacaoManager.buscarAcoes()
      setAcaoLista(response)
    }
    async function buscarGrupoRegra() {
      const response: GrupoRegra[] = await TransformacaoManager.buscarGruposRegras(parceiroId)
      setGrupoRegraLista(response)
      dispatch(loadingReset())
    }
    dispatch(loadingUpdate(true))
    buscarCondicoes()
    buscarAcoes()
    buscarGrupoRegra()
  }, [dispatch, parceiroId])

  const validarCondicao = (regra: RegraTransformacaoForm) => {
    if (regra.condicoes[0].operacaoId == null) {
      regra.condicoes.splice(0, 1)
    }
  }

  const salvarRegraTransformacao = (data: RegraTransformacaoForm) => {
    const dataForm = { ...data, grupos: data.grupos.map((item: GrupoRegraSelect) => item.value) }

    async function cadastrarRegra() {
      await TransformacaoManager.cadastrarRegra(dataForm, parceiroId)
        .then(() => {
          toast.success(msgConstants.USO_GENERICO.cadastro_sucesso)
        })
        .catch(() => {
          toast.error(msgConstants.USO_GENERICO.cadastro_erro)
        })

      await TransformacaoManager.tratarPlanilha(cicloId, selectedsErroProcessamento, 'C')
        .then(() => {
          if (salvarDados) metodosFormulario.reset()
          else {
            router.push(PAGES.iniciarCiclo(cicloId))
            setSelectedsErroProcessamento([])
          }
        })
        .finally(() => {
          dispatch(loadingReset())
        })
    }
    dispatch(loadingUpdate(true))
    validarCondicao(dataForm)
    cadastrarRegra()
  }

  const visualizarRegraTransformacao = async () => {
    dispatch(loadingUpdate(true))
    const regraForm: RegraTransformacaoForm = metodosFormulario.getValues()
    regraForm.grupos = regraForm.grupos.map((item: GrupoRegraSelect) => item.value)

    validarCondicao(regraForm)

    await TransformacaoManager.previewRegraCiclo(regraForm, cicloId, selectedsErroProcessamento[0])
      .then((response: PreviewItemTransformacao) => {
        setpreVisualizar(response)
        setAbrirModal(true)
      })
      .catch(() => {
        toast.error(msgConstants.ERRO_PRE_VISUALIZACAO)
      })
      .finally(() => {
        dispatch(loadingReset())
      })
  }

  const encontrarLinha = useCallback((): number => {
    return erroProcessamentoLista.itens.findIndex(function (item) {
      return item[item.length - 1] == selectedsErroProcessamento[0]
    })
  }, [erroProcessamentoLista.itens, selectedsErroProcessamento])

  useEffect(() => {
    async function buscarErrosProcessamento() {
      await ciclosManager
        .buscarErrosProcessamento(cicloId, 1, 100)
        .then((response: ErrosProcessamento) => {
          setErroProcessamentoLista(response)
          getHeadersPlanilha(response.headers.slice(0, -2))
        })
        .catch(() => {
          toast.error(msgConstants.BUSCAR_ERROS_PROCESSAMENTO_ERRO)
        })
        .finally(() => {
          dispatch(loadingReset())
        })
    }

    dispatch(loadingUpdate(true))
    buscarErrosProcessamento()
  }, [cicloId, dispatch, getHeadersPlanilha])

  const handleCancelar = () => router.goBack()

  return {
    styles: Styles.adicionarNovoParametroStyles,
    erroProcessamentoLista,
    encontrarLinha: encontrarLinha(),
    metodosFormulario,
    salvarRegraTransformacao,
    condicaoLista,
    acaoLista,
    grupoRegraLista,
    campoDados,
    visualizarRegraTransformacao,
    handleCancelar,
    setSalvarDados,
    dadosSalvosSucesso: () => setSalvarDados(true),
    erroSalvarDados: () => setSalvarDados(false),
    abrirModal,
    handleFecharModal: () => {
      setAbrirModal(false)
    },
    preVisualizar
  }
}
export type AdicionarNovoParametroIO = ReturnType<typeof adicionarNovoParametroIO>
