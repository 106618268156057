import { Grid } from '@material-ui/core'
import { GraficoValorQuantidadeMultiploIO } from './GraficoValorQuantidadeMultiplo.IO'
import { formateCurrency } from '../../utils/stringUtils'
import { BoxValor } from '../BoxValor'
import { GraficoBarras } from '../GraficoBarras'
import { IOProps } from 'react-compose-io'
import { TIPO_VALOR_GRAFICO, VALOR_ESCALA_GRAFICO } from '../../constants/utilConstants'
import { Botao } from '../Botao'
import { corBarraQuantidadePedidos, corBarraValorPedidos } from '../../utils/controleComissaoUtils'
import CifraoIcone from '../../assets/icons/cifrao.svg'
import CuboIcone from '../../assets/icons/cubo.svg'
import { customTheme } from '../../styles/customTheme'

export type GraficoValorQuantidadeMultiploProps = {
  valoresMensais: number[]
  quantidadesMensais: number[]
  legendaMeses: string[][]
  legendaEixoYValor: string
  legendaEixoYQuantidade: string
  descricaoValor: string
  valor: number
  descricaoQuantidade: string
  quantidade: number
  width?: string
  handleVerMaisDetalhesPedidoBotao?: () => void
}

export function GraficoValorQuantidadeMultiploView({
  _io,
  valoresMensais,
  quantidadesMensais,
  legendaMeses,
  legendaEixoYValor,
  legendaEixoYQuantidade,
  descricaoValor,
  valor,
  descricaoQuantidade,
  quantidade,
  width,
  handleVerMaisDetalhesPedidoBotao
}: IOProps<GraficoValorQuantidadeMultiploIO, GraficoValorQuantidadeMultiploProps>) {
  const { GridWidthLimitada, GridHeightLimitada, GridContainer } = _io.styles

  return (
    <GridContainer container spacing={2} direction="row" justifyContent="space-evenly" alignContent="center" width={width}>
      <GridWidthLimitada item sm={7} md={7} lg={6}>
        <GridHeightLimitada item>
          <GraficoBarras
            dadosColunas={quantidadesMensais}
            descricaoColunas={legendaMeses}
            legendaEixoY={legendaEixoYQuantidade}
            valorDivididoLegenda={VALOR_ESCALA_GRAFICO.UM}
            corBarra={corBarraQuantidadePedidos}
            tipoValor={TIPO_VALOR_GRAFICO.UNIDADE}
          />
        </GridHeightLimitada>
        <GridHeightLimitada item>
          <GraficoBarras
            dadosColunas={valoresMensais}
            descricaoColunas={legendaMeses}
            legendaEixoY={legendaEixoYValor}
            valorDivididoLegenda={VALOR_ESCALA_GRAFICO.MIL}
            corBarra={corBarraValorPedidos}
            tipoValor={TIPO_VALOR_GRAFICO.MOEDA}
            legend={{
              show: true,
              showForSingleSeries: true,
              customLegendItems: ['Valores cobrados a mais', 'Valores cobrados a menos'],
              markers: {
                fillColors: [customTheme.colors.cinza08, customTheme.colors.vermelho05]
              }
            }}
          />
        </GridHeightLimitada>
      </GridWidthLimitada>
      <GridWidthLimitada item container justifyContent="center" alignContent="center" direction="column" spacing={2} sm={12} md={4} lg={6}>
        <Grid item>
          <BoxValor icone={CifraoIcone} descricao={descricaoValor} valor={formateCurrency(valor)}></BoxValor>
        </Grid>
        <Grid item>
          <BoxValor icone={CuboIcone} descricao={descricaoQuantidade} valor={quantidade.toLocaleString('pt-BR')} />
        </Grid>
        {handleVerMaisDetalhesPedidoBotao && (
          <Grid item>
            <Botao
              cor="primaria"
              estilo="contained"
              type="submit"
              textoBotao="Ver detalhes dos pedidos"
              onClick={handleVerMaisDetalhesPedidoBotao}
              width="360px"
              tamanho="grande"
            />
          </Grid>
        )}
      </GridWidthLimitada>
    </GridContainer>
  )
}
