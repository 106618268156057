/* eslint-disable @typescript-eslint/no-explicit-any */
export interface TiposLancamentosData {
  tipoLancamentosId: number
  nome: string
  sequencia: string
  tipoConciliacaoId: number
  dataAlteracao: Date
  dataCadastro: Date
  nomeCriacao: string
  nomeAlteracao: string
  tipoContabilizacaoId: string
  tipoReferenciaId: string
  ativo: boolean
}

export interface Sequencias {
  sequencia: string
  contaContabilId: number | string
  fato: string
  numeroSequencia: number
  tipoAgrupamentoContabil: string
  tipoNaturezaContabil: string
}

export interface Config {
  ativo: boolean
  dataCadastro: Date
  dataDesativacao: Date
  eventoDescricao: string
  eventoId: number
  fatoDescricao: string
  fatoId: number
  siglaSistema: string
  sistemaDescricao: string
  unidadeNegocioDescricao: string
  unidadeNegocioId: number
  usuarioCadastro: string
  usuarioDesativacao: string
}

export interface TipoLancamentoDataApi {
  descricao: string
  sequenciaId: number | null
  ativo: boolean
  tipoReferenciaId: string
}

export interface TipoLancamento {
  id?: number
  descricao: string
  sequencia: string
  conta: string
  natureza: string
  tipo: string
  status: string
  dataAlteracao: Date | null
  dataCadastro: Date | null
  nomeCriacao: string
  nomeAlteracao: string
  referencia: string
}

export type TipoLancamentoForm = {
  id?: number
  descricao: string
  sequencia: string | any
  conta: string
  natureza: string
  tipo: string
  status: string
  dataAlteracao: Date | null
  dataCadastro: Date | null
  nomeCriacao: string
  nomeAlteracao: string
}

export type HistoricoPadrao = {
  historicoPadraoId: number | string
  historicoPadrao: string
  variaveis: Variavel[]
  referencias: Referencias
}

export type Referencias = {
  posicaoReferencia: number
  posicaoInicialVariavel: number
  textoAnterior: string
  textoPosterior: string
  variavel: TipoVariavel
}[]

export type TipoVariavel = {
  tipoVariavelId?: number
  nome: string
  descricao: string
  variavel: string
}

export type Variavel = {
  variavelId: number
  variavel: string
  posicao: number
  posicaoInicial: number
  tamanho: number
}

export type ReferenciasForm = {
  referencias: Referencias
}

export type FormularioTipoLancamento = {
  descricao: string
  sequencia: string
  conta: string
  natureza: string
  tipo: string
  referencia: string
}

export const defaultHistoricoPadrao: HistoricoPadrao = {
  historicoPadraoId: '',
  historicoPadrao: '-',
  variaveis: [],
  referencias: []
}

export const defaultFormularioTipoLancamento: TipoLancamento = {
  descricao: '',
  sequencia: '',
  conta: '',
  natureza: '',
  tipo: '',
  referencia: '',
  status: '',
  dataAlteracao: null,
  dataCadastro: null,
  nomeCriacao: '',
  nomeAlteracao: ''
}

export const semSequencia: Sequencias = {
  sequencia: 'SEM SEQUÊNCIA',
  contaContabilId: '-',
  fato: '-',
  numeroSequencia: 1,
  tipoAgrupamentoContabil: '-',
  tipoNaturezaContabil: '-'
}
