import { Login, LoginResponse } from '../../../types/loginApiTypes'
import loginService from './loginService'

const autenticacaoLogin = async (Request: Login): Promise<string> => loginService.autenticacaoLogin(Request)

const refreshToken = async (): Promise<LoginResponse> => loginService.refreshToken()

const logout = async (): Promise<void> => loginService.logout()

const obterAcessosMenu = async (): Promise<string[]> => loginService.obterAcessosMenu()

export default { refreshToken, autenticacaoLogin, logout, obterAcessosMenu }
