import { IOProps } from 'react-compose-io'
import { OpsEmAbertoResumoIO } from './OpsEmAbertoResumo.IO'
import { Tipografia } from '../../../../components/Tipografia'
import Grid from '@material-ui/core/Grid/Grid'
import { Botao } from '../../../../components/Botao'
import { Divider } from '@material-ui/core'
import CuboIcone from '../../../../assets/icons/cubo.svg'
import CifraoIcone from '../../../../assets/icons/cifrao.svg'
import { customTheme } from './../../../../styles/customTheme'
import { formateCurrency } from '../../../../utils/stringUtils'
import { OpsEmAbertoPorParceiro } from './../OpsEmAbertoPorParceiro/index'
import { OpsEmAbertoResumoPorParceiro } from '../../../../types/regularizacaoOpsTypes'

export type OpsEmAbertoResumoProps = {}

export function OpsEmAbertoResumoView({ _io }: IOProps<OpsEmAbertoResumoIO, OpsEmAbertoResumoProps>) {
  const { Container, ContainerValorQuantidade } = _io.styles

  return (
    <Container>
      <Grid container justifyContent="space-between">
        <Grid item>
          <Tipografia variant="h6">{"Resumo das OP's para análise"}</Tipografia>
        </Grid>
        <Grid item>
          <Botao estilo="contained" cor="primaria" textoBotao="Visualizar detalhes" onClick={_io.onClickDetalhesOP} />
        </Grid>
      </Grid>

      {_io.opsEmAbertoResumo && (
        <>
          <Grid container justifyContent="space-evenly">
            <Grid item>
              <ContainerValorQuantidade>
                <img src={CifraoIcone} />
                <Tipografia fontSize={customTheme.fontSize[18]} fontWeight="500">
                  {"Valor total das OP's"}
                </Tipografia>
                <Tipografia fontSize={customTheme.fontSize[24]} fontWeight="500">
                  {formateCurrency(_io.opsEmAbertoResumo.valorTotal)}
                </Tipografia>
              </ContainerValorQuantidade>
            </Grid>
            <Grid item>
              <Divider orientation="vertical" />
            </Grid>
            <Grid item>
              <ContainerValorQuantidade>
                <img src={CuboIcone} />
                <Tipografia fontSize={customTheme.fontSize[18]} fontWeight="500">
                  {"Quantidade total das OP's"}
                </Tipografia>
                <Tipografia fontSize={customTheme.fontSize[24]} fontWeight="500">
                  {_io.opsEmAbertoResumo.quantidadeTotal.toLocaleString('pt-BR')}
                </Tipografia>
              </ContainerValorQuantidade>
            </Grid>
          </Grid>

          <Tipografia variant="h6">{"OP's por parceiro"}</Tipografia>

          <Grid container justifyContent="center">
            {_io.opsEmAbertoResumo.ordemPagamentoPorParceiro.map((opParceiro: OpsEmAbertoResumoPorParceiro) => (
              <OpsEmAbertoPorParceiro
                key={opParceiro.parceiro}
                parceiro={opParceiro.parceiro}
                urlLogo={opParceiro.urlLogo}
                valorTotal={opParceiro.valorTotal}
                quantidadeTotal={opParceiro.quantidadeTotal}
              />
            ))}
          </Grid>
        </>
      )}
    </Container>
  )
}
