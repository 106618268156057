import { Dispatch, SetStateAction } from 'react'
import { IOProps } from 'react-compose-io'
import { CustomDialog } from '../../../../components/CustomDialog/CustomDialog'
import { Config, HistoricoPadrao } from '../../../../types/tipoLancamentoApiTypes'
import { estaVazio } from '../../../../utils/stringUtils'
import { DadosTiposLancamentos } from '../DadosTiposLancamentos/index'
import { ParametroContabilDetalhes } from '../ParametroContabilDetalhes/index'
import { ModalEditarIO } from './ModalEditar.IO'
import { Botao } from './../../../../components/Botao/index'

export type ModalEditarProps = {
  configurarTipoLancamento: Config
  historicoPadrao?: HistoricoPadrao
  abrirModal: boolean
  setAbrirModal: Dispatch<SetStateAction<boolean>>
}

export function ModalEditarView({ _io, abrirModal, configurarTipoLancamento, historicoPadrao }: IOProps<ModalEditarIO, ModalEditarProps>) {
  const { Container } = _io.styles

  return (
    <CustomDialog
      titulo={'Editar tipo de lançamento eFácil'}
      id={'modalEditar'}
      habilitarAcoes
      fecharHabilitado
      actions={
        <Container>
          <Botao textoBotao="Cancelar" estilo="text" cor="primaria" onClick={_io.fecharModalEdicao} width="150px" />
          <Botao textoBotao="Salvar" estilo="rounded" cor="primaria" onClick={_io.handleSubmit(_io.submeterDadosFormulario)} width="150px" />
        </Container>
      }
      open={abrirModal}
      onClose={_io.fecharModalEdicao}
      fecharModal={_io.fecharModalEdicao}
      closeAfterTransition
      fullWidth={true}
      maxWidth={'md'}
    >
      <DadosTiposLancamentos
        telaMenor={false}
        submeterTipoLancamento={_io.handleSubmit(_io.submeterDadosFormulario)}
        controleFormulario={_io.control}
        erroPreenchimento={_io.errors}
        clicarSequenciaFormulario={_io.selecionarSequenciaFormulario}
      />

      <ParametroContabilDetalhes
        config={estaVazio(_io.tipoLancamento.sequencia) ? undefined : configurarTipoLancamento}
        sequencia={estaVazio(_io.tipoLancamento.sequencia) ? '-' : _io.tipoLancamento.sequencia}
        historicoPadrao={historicoPadrao}
        parametrosContabeisLabel={'Detalhes'}
        historicoPadraoLabel={'Histórico Padrão'}
      ></ParametroContabilDetalhes>
    </CustomDialog>
  )
}
