import { Filter } from 'material-table'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import msgConstants from '../../../../constants/msgConstants'
import { ORDEM_CRESCENTE } from '../../../../constants/utilConstants'
import CiclosManager from '../../../../services/api/ciclos/ciclosManager'
import parceiroManager from '../../../../services/api/parceiro/parceiroManager'
import TipoLancamentoManager from '../../../../services/api/tipoLancamento/tipoLancamentoManager'
import { loadingReset, loadingUpdate } from '../../../../store/actions/loadingAction'
import { ItemLancamentoNaoAssociado, LancamentosVariaveisApi } from '../../../../types/ciclosApiType'
import { FormValuesSequencias } from '../../../../types/iniciarNovoCicloTypes'
import { TipoLancamentoParceiro } from '../../../../types/parceiroApiTypes'
import { cabecalhoLancamentosVariaveis, Order } from '../../../../types/tabelaTypes'
import { Sequencias } from '../../../../types/tipoLancamentoApiTypes'
import { lancamentoVariaveisStyles } from './LancamentoVariaveis.style'
import { LancamentoVariaveisProps } from './LancamentoVariaveis.View'

export function lancamentoVariaveisIO({ cicloId, setEnableConfirmar, parceiroId }: LancamentoVariaveisProps) {
  const dispatch = useDispatch()
  const [ordem, setOrdem] = useState<Order>(ORDEM_CRESCENTE)
  const [ordemBy, setOrdemBy] = useState<string>('')
  const [checkboxSelecionado, setCheckboxSelecionado] = useState<string[]>([])
  const [pagina, setPagina] = useState(0)
  const [linhasPorPagina, setLinhasPorPagina] = useState(10)
  const [linhasLancamentosVariaveis, setLinhasLancamentosVariaveis] = useState<ItemLancamentoNaoAssociado[]>([])
  const [abrirModal, setAbrirModal] = useState(false)
  const [modalTipoLancamento, setModalTipoLancamento] = useState(false)
  const [totalLinhas, setTotalLinhas] = useState(0)
  const [arraySequencias, setArraySequencias] = useState([] as Sequencias[])
  const [tipoLancamentoLista, setTipoLancamentoLista] = useState<TipoLancamentoParceiro[]>([])
  const [tiposLancamentosFiltro, setTiposLancamentosFiltro] = useState<string[]>([])
  const [filtroTiposLancamentosSelecionados, setFiltroTiposLancamentosSelecionados] = useState<string[]>([])

  useEffect(() => {
    buscarSequencias()
    buscarTipoLancamentoParceiro()
    buscarLancamentosDistintos()
  }, [parceiroId])

  async function buscarLancamentosDistintos() {
    await CiclosManager.obterLancamentosDinstintos(cicloId).then((response: string[]) => {
      response.forEach((tipoLancamentoDistinto) => (tiposLancamentosFiltro[tipoLancamentoDistinto as any] = tipoLancamentoDistinto))
      setTiposLancamentosFiltro(tiposLancamentosFiltro)
    })
  }

  async function buscarTipoLancamentoParceiro() {
    const response: TipoLancamentoParceiro[] = await parceiroManager.buscarTipoLancamentoParceiro(parceiroId)
    setTipoLancamentoLista(response)
  }

  async function buscarSequencias() {
    const response: Sequencias[] = await TipoLancamentoManager.buscarSequencias('')
    setArraySequencias(response)
  }

  const exibirTextoSequenciaTabela = (sequenciaExistente: Sequencias | undefined, itemVariavel: ItemLancamentoNaoAssociado) => {
    if (sequenciaExistente) {
      itemVariavel.sequencia = sequenciaExistente.sequencia
    }
  }

  const fetchLancamentosVariaveis = useCallback(async () => {
    dispatch(loadingUpdate(true))
    await CiclosManager.buscarLancamentosVariaveis(cicloId, pagina + 1, linhasPorPagina, ordem, filtroTiposLancamentosSelecionados, ordemBy)
      .then((response: LancamentosVariaveisApi) => {
        const lancamentosApi = response.itens
        lancamentosApi.forEach((itemVariavel: ItemLancamentoNaoAssociado) => {
          if (itemVariavel.sequenciaContabilId != null) {
            const sequenciaExistente = arraySequencias
              .filter((lancamentoSequencia: Sequencias) => lancamentoSequencia.numeroSequencia == itemVariavel.sequenciaContabilId)
              .find((lancamentoSequencias) => lancamentoSequencias)
            exibirTextoSequenciaTabela(sequenciaExistente, itemVariavel)
          }
        })

        setLinhasLancamentosVariaveis(response.itens)
        setTotalLinhas(response.totalItens)
        setEnableConfirmar(response.totalItensTratados)
        dispatch(loadingReset())
      })
      .catch(() => {
        toast.error(msgConstants.LANCAMENTO_VARIAVEIS.erroBuscarLancamentos)
        dispatch(loadingReset())
      })
  }, [cicloId, pagina, linhasPorPagina, arraySequencias, ordemBy, ordem, setEnableConfirmar, filtroTiposLancamentosSelecionados])

  useEffect(() => {
    if (arraySequencias.length != 0) {
      fetchLancamentosVariaveis()
    }
  }, [fetchLancamentosVariaveis, arraySequencias])

  const editarLancamentoVariaveisItem = async (itemLancamento: ItemLancamentoNaoAssociado[]) => {
    CiclosManager.editarItemLancamentoVariaveis(cicloId, itemLancamento).then(() => {
      setCheckboxSelecionado([])
      fetchLancamentosVariaveis()
    })
  }

  const handleChangeSequencia = (seqSelected: FormValuesSequencias) => {
    const itemSelecionadoCheckBox = linhasLancamentosVariaveis.filter((itemFiltrado) => {
      return checkboxSelecionado.includes(itemFiltrado.numero.toString())
    })
    itemSelecionadoCheckBox.forEach((lancamento) => {
      lancamento.sequenciaContabilId = seqSelected.numeroSequenciaId
    })
    editarLancamentoVariaveisItem(itemSelecionadoCheckBox)
    setAbrirModal(false)
  }

  const handleTipoLancamentoChange = (itemLancamentoParceiro: TipoLancamentoParceiro) => {
    const itemSelecionadoCheckBox = linhasLancamentosVariaveis.filter((itemFiltrado) => {
      return checkboxSelecionado.includes(itemFiltrado.numero.toString())
    })
    itemSelecionadoCheckBox.forEach((lancamento) => {
      lancamento.tipoLancamentoNovo = itemLancamentoParceiro.descricao
    })
    editarLancamentoVariaveisItem(itemSelecionadoCheckBox)
    setModalTipoLancamento(false)
  }

  return {
    style: lancamentoVariaveisStyles,
    modalTipoLancamento,
    abrirModal,
    arraySequencias,
    tipoLancamentoLista,
    handleChangeSequencia,
    handleTipoLancamentoChange,
    handleModalTipoLancamento: () => {
      setModalTipoLancamento(true)
    },
    handleFecharModalTipoLancamento: () => {
      setModalTipoLancamento(false)
    },
    handleModal: () => {
      setAbrirModal(true)
    },
    handleCloseModal: () => {
      setAbrirModal(false)
    },
    checkBoxDesabilitado: Boolean(!checkboxSelecionado.length),
    linhasLancamentosVariaveis,
    pagina,
    setCheckboxSelecionado,
    rowsPerPageOptions: [10, 25, 50, 100],
    totalLinhas,
    onMudancaLinhasPorPagina: (numeroLinhaPorPagina: number) => {
      setPagina(0)
      setLinhasPorPagina(numeroLinhaPorPagina)
    },
    onMudancaPagina: (novaPagina: number, tamanhoPagina: number) => {
      setLinhasPorPagina(tamanhoPagina)
      setPagina(novaPagina)
    },
    tiposLancamentosFiltro,
    ordernarTabelaEditavel: (colunaSelecionada: number, tipoOrdenacao: Order) => {
      const nomeColuna = cabecalhoLancamentosVariaveis(tiposLancamentosFiltro)[colunaSelecionada].field
      setOrdem(tipoOrdenacao)
      setOrdemBy(nomeColuna)
    },
    editarLancamentoVariaveisItem,
    onFiltroSelecionado: (filters: Filter<ItemLancamentoNaoAssociado>[]) => {
      setFiltroTiposLancamentosSelecionados(filters[0].value)
    }
  }
}
export type LancamentoVariaveisIO = ReturnType<typeof lancamentoVariaveisIO>
