import styled from 'styled-components'

export const modalEditarStyles = {
  Container: styled.div`
    display: flex;
    justify-content: center;
    padding: 8px 16px;
    gap: 8px;
  `
}
