import { ApexOptions } from 'apexcharts'
import { TIPO_VALOR_GRAFICO } from '../../constants/utilConstants'
import { customTheme } from '../../styles/customTheme'
import { formateCurrency } from '../../utils/stringUtils'
import { graficoBarrasStyles } from './GraficoBarras.style'
import { GraficoBarrasProps } from './GraficoBarras.View'

export function graficoBarrasIO({
  dadosColunas,
  descricaoColunas,
  legendaEixoY,
  valorDivididoLegenda,
  corBarra,
  tipoValor,
  handleClickColuna,
  legend = {
    show: false
  }
}: GraficoBarrasProps) {
  return {
    styles: graficoBarrasStyles,
    options: {
      chart: {
        events: {
          dataPointSelection: (event: MouseEvent, chartContext: any, config: any) => {
            handleClickColuna && handleClickColuna(config.dataPointIndex)
          }
        },
        type: 'bar',
        toolbar: {
          show: false,
          tools: {
            download: false
          }
        },
        animations: {
          enabled: true
        },
        redrawOnWindowResize: false
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          columnWidth: '45%'
        }
      },
      legend: legend,
      xaxis: {
        categories: descricaoColunas,
        labels: {
          style: {
            fontSize: '16px'
          }
        }
      },
      yaxis: {
        labels: {
          formatter: function (value: number) {
            const valorConvertido = (value / valorDivididoLegenda).toFixed(0)

            if (tipoValor === TIPO_VALOR_GRAFICO.MOEDA) return 'R$ ' + valorConvertido

            return Number(valorConvertido).toLocaleString('pt-BR')
          },
          style: {
            fontSize: '16px',
            fontFamily: customTheme.fontFamily.rubik400
          }
        },
        title: {
          text: legendaEixoY,
          offsetX: -7,
          style: {
            fontSize: '14px',
            fontFamily: customTheme.fontFamily.rubik400,
            fontWeight: 'none'
          }
        }
      },
      fill: {
        opacity: 1
      },
      colors: [corBarra],
      tooltip: {
        y: {
          formatter: function (val: number) {
            if (tipoValor === TIPO_VALOR_GRAFICO.MOEDA) return `${formateCurrency(val)}`

            return val.toLocaleString('pt-BR')
          }
        }
      },
      dataLabels: {
        enabled: false
      }
    } as ApexOptions,
    series: [
      {
        name: '',
        data: dadosColunas
      }
    ]
  }
}

export type GraficoBarrasIO = ReturnType<typeof graficoBarrasIO>
