import { Box, Grid, IconButton, InputLabel, MenuItem } from '@material-ui/core'
import { Cancel } from '@material-ui/icons'
import { IOProps } from 'react-compose-io'
import { Controller } from 'react-hook-form'
import { TRANSFORMACAO_INFO } from '../../../constants/transformacao'
import { FormFieldsProps } from '../../../types/regraApiType'
import { TooltipComponent } from '../../Tooltip/Tooltip'
import { FieldsAritmetica } from '../FieldsAritmetica/index'
import { FieldsBuscarLinha } from '../FieldsBuscarLinha'
import { FieldsConcatenar } from '../FieldsConcatenar/index'
import { FieldsConsiderar } from '../FieldsConsiderar/index'
import { FieldsSeparador } from '../FieldsSeparador/index'
import { FieldsSubstituir } from '../FieldsSubstituir/index'
import { FieldsTipoLancamento } from '../FieldsTipoLancamento/index'

import { FieldsAcaoIO } from './FieldsAcao.IO'
import { Botao } from './../../Botao/index'

export type FieldsAcaoProps = {
  itensCampo: FormFieldsProps[]
  cabecalhos: string[]
}

export function FieldsAcaoView({ _io, cabecalhos, itensCampo }: IOProps<FieldsAcaoIO, FieldsAcaoProps>) {
  const { ContainerGridCenter, FormControl, FormGroup, FormTitle, FormLabel, FormSubtitle, BoxRegra, SelectOperacao } = _io.styles

  return (
    <BoxRegra>
      <Box>
        <FormTitle>Ação</FormTitle>
        <FormSubtitle>Adicione a ação que deseja para a regra.</FormSubtitle>
      </Box>
      {_io.acoes.fields.map((campoAcao, campoAcaoIndex: number) => {
        return (
          <ContainerGridCenter key={campoAcao.id} item sm={12} md={12} spacing={2}>
            <Grid item sm={12} md={3}>
              <FormGroup>
                {campoAcaoIndex < 1 ? <FormLabel>Então</FormLabel> : <FormLabel>e</FormLabel>}
                <Controller
                  render={({ field }) => (
                    <FormControl>
                      <InputLabel>Selecione a operação</InputLabel>
                      <SelectOperacao {...field} label="Selecione a operação">
                        {itensCampo.map((itemCampoOperacoes, itemCampoIndex) => (
                          <MenuItem key={itemCampoIndex} value={itemCampoOperacoes.operacaoId}>
                            {itemCampoOperacoes.nome}
                          </MenuItem>
                        ))}
                      </SelectOperacao>
                    </FormControl>
                  )}
                  name={`acoes.${campoAcaoIndex}.operacaoId` as `acoes.0.operacaoId`}
                  control={_io.control}
                />
                <TooltipComponent texto={TRANSFORMACAO_INFO.acao.tipoOperacaoAcao} />
              </FormGroup>
            </Grid>
            <>
              <FieldsAritmetica indexItensRegraForm={campoAcaoIndex} itensCampo={itensCampo} cabecalhos={cabecalhos} />
              <FieldsConcatenar indexItensRegraForm={campoAcaoIndex} itensCampo={itensCampo} cabecalhos={cabecalhos} />
              <FieldsConsiderar indexItensRegraForm={campoAcaoIndex} itensCampo={itensCampo} cabecalhos={cabecalhos} />
              <FieldsSeparador indexItensRegraForm={campoAcaoIndex} itensCampo={itensCampo} cabecalhos={cabecalhos} />
              <FieldsSubstituir indexItensRegraForm={campoAcaoIndex} itensCampo={itensCampo} cabecalhos={cabecalhos} />
              <FieldsTipoLancamento indexItensRegraForm={campoAcaoIndex} itensCampo={itensCampo} cabecalhos={cabecalhos} />
              <FieldsBuscarLinha indexItensRegraForm={campoAcaoIndex} itensCampo={itensCampo} cabecalhos={cabecalhos} />
              <Grid item sm={12} md={1}>
                {_io.acoes.fields.length > 0 && (
                  <IconButton onClick={() => _io.acoes.remove(campoAcaoIndex)}>
                    <Cancel />
                  </IconButton>
                )}
              </Grid>
            </>
          </ContainerGridCenter>
        )
      })}
      <Botao textoBotao="+ Adicionar ação" cor="primaria" estilo="text" tamanho="pequeno" onClick={_io.submeterAcao} />
    </BoxRegra>
  )
}
