import { useState } from 'react'
import { toast } from 'react-toastify'
import msgConstants from '../../../../constants/msgConstants'
import controleComissaoManager from '../../../../services/api/controleComissao/controleComissaoManager'
import { ControleComissaoChamados, ControleComissaoStatusForm } from '../../../../types/controleComissaoTypes'
import { itemControleComissaoStyles } from './ItemControleComissao.styles'
import { ItemControleComissaoProps } from './ItemControleComissao.View'

export function itemControleComissaoIO({ item, atualizarLista }: ItemControleComissaoProps) {
  const [historicoAberto, setHistoricoAberto] = useState(false)
  const [abrirMenu, setAbrirMenu] = useState<null | HTMLElement>(null)
  const [abrirModalEditar, setAbrirModalEditar] = useState(false)
  const [abrirModalChamados, setAbrirModalChamados] = useState(false)
  const [chamados, setChamados] = useState<ControleComissaoChamados[]>([])
  const [isCarregandoChamados, setIsCarregandoChamados] = useState(false)

  const handleAlterarIconeAncora = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAbrirMenu(event.currentTarget)
  }

  const handleAnularIconeAncora = () => {
    setAbrirMenu(null)
  }

  const exportarControleComissao = () => {
    window.location.assign(item.urlRelatorio)
    setAbrirMenu(null)
  }

  const abrirModalEditarStatus = () => {
    setAbrirModalEditar(true)
    setAbrirMenu(null)
  }

  const handleAbrirModalChamados = () => {
    buscarChamados()
    setAbrirModalChamados(true)
    setAbrirMenu(null)
  }

  const buscarChamados = async () => {
    setIsCarregandoChamados(true)
    await controleComissaoManager
      .buscarControleComissaoChamados(item.id)
      .then((chamadosResponse: ControleComissaoChamados[]) => {
        setChamados(chamadosResponse)
      })
      .catch(() => toast.error(msgConstants.CONTROLE_COMISSAO.erroBuscarChamdos))
      .finally(() => setIsCarregandoChamados(false))
  }

  const handleConfirmarEdicao = async (dataFormulario: ControleComissaoStatusForm) => {
    await controleComissaoManager
      .editarControleComissaoResumo(item.id, dataFormulario)
      .then(() => {
        setAbrirModalEditar(false)
        atualizarLista()
      })
      .catch(() => toast.error(msgConstants.CONTROLE_COMISSAO.erroAtualizarStatus))
  }

  return {
    styles: itemControleComissaoStyles,
    historicoAberto,
    handleAbrirHistoricoToggle: () => setHistoricoAberto(!historicoAberto),
    abrirMenu,
    handleAlterarIconeAncora,
    handleAnularIconeAncora,
    abrirModalEditarStatus,
    exportarControleComissao,
    handleAbrirModalChamados,
    setAbrirModalEditar,
    abrirModalEditar,
    setAbrirModalChamados,
    abrirModalChamados,
    handleConfirmarEdicao,
    chamados,
    isCarregandoChamados,
    buscarChamados
  }
}

export type ItemControleComissaoIO = ReturnType<typeof itemControleComissaoIO>
