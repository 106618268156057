import { IOProps } from 'react-compose-io'
import { DetalhesOpsEmAbertoIO } from './DetalhesOpsEmAberto.IO'
import { Tipografia } from './../../components/Tipografia/index'
import { FormControl, Grid, InputLabel, MenuItem, Select, TableCell, TableRow, TextField } from '@material-ui/core'
import { Controller } from 'react-hook-form'
import { Tabela } from '../../components/Tabela'
import { detalhesOpsEmAberto } from '../../types/tabelaTypes'
import { Botao } from '../../components/Botao'
import { formateCurrency, formateDate, validarTexto } from '../../utils/stringUtils'
import { OpsEmAberto } from '../../types/regularizacaoOpsTypes'
import { BotaoIconeSvg } from '../../components/BotaoIconeSvg'
import { ExportIcon } from './../../assets/icons/ExportIcon'
import { customTheme } from '../../styles/customTheme'

export type DetalhesOpsEmAbertoProps = {}

export function DetalhesOpsEmAbertoView({ _io }: IOProps<DetalhesOpsEmAbertoIO, DetalhesOpsEmAbertoProps>) {
  const { Container, ContainerFormDataButton, TextFieldDataFim, ContainerExportarIcone } = _io.styles

  function linhaTabela(props: { item: OpsEmAberto }) {
    const { item } = props

    return (
      <TableRow>
        <TableCell>{validarTexto(formateDate(item.dataEmissao), '-')}</TableCell>
        <TableCell>{validarTexto(item.bancoId, '-')}</TableCell>
        <TableCell>{validarTexto(item.ordemPagamentoId, '-')}</TableCell>
        <TableCell>{validarTexto(formateCurrency(item.valorPagamento), '-')}</TableCell>
        <TableCell>{validarTexto(item.pedidoMkt, '-')}</TableCell>
        <TableCell>{validarTexto(item.clienteId, '-')}</TableCell>
        <TableCell>{validarTexto(_io.parceiros.find((x) => x.parceiroId === item.parceiroId)?.nomeFantasia, '-')}</TableCell>
        <TableCell>{validarTexto(item.cicloId, '-')}</TableCell>
      </TableRow>
    )
  }

  return (
    <>
      <Tipografia variant="h5" fontWeight="500">
        {"Detalhes das OP's"}
      </Tipografia>
      <Container>
        <form>
          <Grid container spacing={2}>
            <Grid item>
              <ContainerFormDataButton>
                <Controller
                  render={({ field }) => (
                    <TextField
                      {..._io.register('dataInicioEmissao')}
                      {...field}
                      label="Data de emissão"
                      variant="outlined"
                      size="small"
                      type="date"
                      fullWidth
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  )}
                  name="dataInicioEmissao"
                  control={_io.control}
                />
                <Tipografia>até</Tipografia>
                <Controller
                  render={({ field }) => (
                    <TextFieldDataFim
                      {..._io.register('dataFimEmissao')}
                      {...field}
                      label=""
                      variant="outlined"
                      size="small"
                      type="date"
                      fullWidth
                      error={_io.errors?.dataFimEmissao ? true : false}
                      helperText={_io.errors.dataFimEmissao?.message}
                    />
                  )}
                  name="dataFimEmissao"
                  control={_io.control}
                />
              </ContainerFormDataButton>
            </Grid>
            <Grid item xs={2}>
              <Controller
                render={({ field }) => (
                  <FormControl size="small" variant="outlined" fullWidth>
                    <InputLabel>Parceiro</InputLabel>
                    <Select {...field} defaultValue={'0'} label="Parceiro">
                      {_io.parceiros.map((parceiroAtivo) => (
                        <MenuItem key={parceiroAtivo.parceiroId} value={parceiroAtivo.parceiroId}>
                          {parceiroAtivo.nomeFantasia}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
                name="parceiroId"
                control={_io.control}
              />
            </Grid>

            <Grid item>
              <ContainerFormDataButton>
                <Botao textoBotao="Limpar" estilo="text" cor="primaria" onClick={_io.handleLimpar} />
                <Botao onClick={_io.handleSubmit(_io.handleAplicar)} textoBotao="Aplicar" cor="primaria" estilo="contained" />
              </ContainerFormDataButton>
            </Grid>

            <ContainerExportarIcone item>
              <BotaoIconeSvg onClick={() => _io.gerarRelatorioOpsEmAberto()} icone={<ExportIcon width={24} height={24} color={customTheme.colors.azul06} />} />
            </ContainerExportarIcone>
          </Grid>
        </form>

        {_io.detalhesOpsEmAbertoList && (
          <Tabela
            cabecalho={detalhesOpsEmAberto}
            itensLista={_io.detalhesOpsEmAbertoList.itens}
            LinhaTabela={linhaTabela}
            orderCampoPadrao={''}
            rowsPerPageOptions={[10, 25, 50]}
            linhasPorPaginaPadrao={10}
            maxHeight={'none'}
            alinharTextoCentro={false}
            buscarItensTabela={_io.obterOpsEmAberto}
            totalItens={_io.detalhesOpsEmAbertoList.totalItens}
          />
        )}
      </Container>
    </>
  )
}
