import { InadimplenciaAnomaliaIO } from './InadimplenciaAnomalia.IO'
import { Grid } from '@material-ui/core'
import { IOProps } from 'react-compose-io'
import CifraoIcone from '../../../../assets/icons/cifrao.svg'
import CuboIcone from '../../../../assets/icons/cubo.svg'
import { Tipografia } from './../../../../components/Tipografia/index'
import { customTheme } from '../../../../styles/customTheme'
import { CabecalhoIconeExportarRelatorio } from '../../../../components/CabecalhoIconeExportarRelatorio'
import { GraficoBarras } from '../../../../components/GraficoBarras'
import { CollapseInadimplenciaTable } from '../CollapseInadimplenciaTable'
import { Botao } from '../../../../components/Botao'
import { PAGES } from '../../../../router/routes'
import { DadosMeses } from '../../../../utils/tratarDadosMesesUtils'
import { TIPO_VALOR_GRAFICO, VALOR_ESCALA_GRAFICO } from '../../../../constants/utilConstants'

export type InadimplenciaAnomaliaProps = {
  dadosMensais: DadosMeses[]
  mesesSelecionados: number
  tituloAnomalia: string
  iconeAnomalia: string
  tipoAnomalia: string
  urlRelatorio?: string
  parceiroSelecionado: number
}

export function InadimplenciaAnomaliaView({
  _io,
  dadosMensais,
  tituloAnomalia,
  iconeAnomalia,
  tipoAnomalia,
  urlRelatorio,
  parceiroSelecionado
}: IOProps<InadimplenciaAnomaliaIO, InadimplenciaAnomaliaProps>) {
  const { GridContainer, BoxResumo, GridItem, Divider, Image, TipografiaMes, Container } = _io.styles

  return (
    <>
      <Container>
        <CabecalhoIconeExportarRelatorio url={urlRelatorio ? urlRelatorio : ''} icone={iconeAnomalia} descricao={tituloAnomalia} />
        <GridContainer container justifyContent="space-between">
          <Grid item>
            <GraficoBarras
              tituloGrafico=""
              descricaoColunas={_io.dadosGrafico.nomeMeses}
              dadosColunas={_io.dadosGrafico.valor}
              legendaEixoY="Milhares (R$)"
              valorDivididoLegenda={VALOR_ESCALA_GRAFICO.MIL}
              corBarra={() => customTheme.colors.azul03}
              height="220px"
              width="502px"
              tipoValor={TIPO_VALOR_GRAFICO.MOEDA}
            />
          </Grid>
          <Grid item>
            <CollapseInadimplenciaTable inadimplencias={dadosMensais} />
            <Botao
              textoBotao="Ver mais detalhes"
              estilo="contained"
              cor="primaria"
              onClick={() => _io.history.push(PAGES.detalheInadimplencia(parceiroSelecionado, tipoAnomalia))}
              width="285px"
              margin="32px 0 0 0"
            />
          </Grid>
        </GridContainer>
      </Container>

      <BoxResumo>
        <Grid container alignItems="center" justifyContent="center" direction="row" spacing={1}>
          <GridItem item>
            <Image src={CifraoIcone} />
            <Grid>
              <Tipografia fontWeight="700" variant="subtitle1" colorText={customTheme.colors.grey04} lineHeight={'1.25'}>
                Valor total de títulos
              </Tipografia>
              <Tipografia fontWeight="700" variant="subtitle1" colorText={customTheme.colors.grey04} lineHeight={'1.25'}>
                inadimplentes
              </Tipografia>
              <Tipografia fontWeight="600" variant="h4" colorText={customTheme.colors.grey09}>
                {_io.valorTotal}
              </Tipografia>
            </Grid>
          </GridItem>
          <Divider orientation="vertical" variant="middle" />
          <GridItem item>
            <Image src={CuboIcone} />
            <Grid>
              <Tipografia fontWeight="700" variant="subtitle1" colorText={customTheme.colors.grey04} lineHeight={'1.25'}>
                Quantidade total de títulos
              </Tipografia>
              <Tipografia fontWeight="700" variant="subtitle1" colorText={customTheme.colors.grey04} lineHeight={'1.25'}>
                inadimplentes
              </Tipografia>
              <Tipografia fontWeight="600" variant="h4" colorText={customTheme.colors.grey09}>
                {_io.quantidadeTotal}
              </Tipografia>
            </Grid>
          </GridItem>
          <Divider orientation="vertical" variant="middle" />
          <GridItem item>
            <TipografiaMes fontWeight="700" variant="subtitle1" colorText={customTheme.colors.grey09}>
              {_io.getNomeMesComMaiorValor(_io.dadosGrafico)}
            </TipografiaMes>
            <Grid>
              <Tipografia fontWeight="700" variant="subtitle1" colorText={customTheme.colors.grey04} lineHeight={'1.25'}>
                Mês com mais títulos
              </Tipografia>
              <Tipografia fontWeight="700" variant="subtitle1" colorText={customTheme.colors.grey04} lineHeight={'1.25'}>
                inadimplentes
              </Tipografia>
            </Grid>
          </GridItem>
        </Grid>
      </BoxResumo>
    </>
  )
}
