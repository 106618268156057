import { IOProps } from 'react-compose-io'
import { Controller } from 'react-hook-form'
import { PeriodoPendenciasRegularizacaoCicloForm } from '../../../../types/regularizacaoOPsTitulosTypes'
import { Botao } from './../../../../components/Botao/index'
import { Tipografia } from './../../../../components/Tipografia/index'
import { CabecalhoIO } from './Cabecalho.IO'

export type CabecalhoProps = {
  handleAplicar: (data: PeriodoPendenciasRegularizacaoCicloForm) => void
}

export function CabecalhoView({ _io, handleAplicar }: IOProps<CabecalhoIO, CabecalhoProps>) {
  const { InputData, ContainerCabecalho, ContainerSubtitulo, ContainerError, TextoErroPreenchimento, ContainerTextoErroPreenchimento } = _io.styles

  return (
    <>
      <form onSubmit={_io.handleSubmit(handleAplicar)}>
        <ContainerCabecalho>
          <Tipografia variant="h5">Regularização de ciclos</Tipografia>
        </ContainerCabecalho>
        <ContainerSubtitulo>
          <Tipografia variant="h6">Pendências</Tipografia>
          <ContainerSubtitulo>
            <ContainerTextoErroPreenchimento>
              {_io.errors != undefined && _io.errors.dataInicial && <TextoErroPreenchimento>{_io.errors.dataInicial.message}</TextoErroPreenchimento>}
              <Controller
                name="dataInicial"
                control={_io.control}
                render={({ field }) => (
                  <InputData
                    label={'Data inicial'}
                    {...field}
                    error={_io.verificarErros(_io.errors, _io.errors?.dataInicial)}
                    inputProps={{
                      max: _io.dataAtual
                    }}
                  />
                )}
              />
            </ContainerTextoErroPreenchimento>
            <ContainerTextoErroPreenchimento>
              {_io.errors != undefined && _io.errors.dataFinal && <TextoErroPreenchimento>{_io.errors.dataFinal.message}</TextoErroPreenchimento>}
              <Controller
                name="dataFinal"
                control={_io.control}
                render={({ field }) => (
                  <ContainerError>
                    <InputData
                      label={'Data final'}
                      {...field}
                      error={_io.verificarErros(_io.errors, _io.errors?.dataFinal)}
                      inputProps={{
                        max: _io.dataAtual
                      }}
                    />
                  </ContainerError>
                )}
              />
            </ContainerTextoErroPreenchimento>
            <Botao textoBotao={'Aplicar'} cor="primaria" estilo="contained" type="submit" margin="0 0 0 10px" />
          </ContainerSubtitulo>
        </ContainerSubtitulo>
      </form>
    </>
  )
}
