import { CircularProgress, TableBody, TableCell, TableContainer, TablePagination, Typography } from '@material-ui/core'
import { IOProps } from 'react-compose-io'
import { EnhancedTableHead } from '../../../../components/EnhancedTableHead/index'
import { ComponentePaginacao } from '../../../../components/Tabela/ComponentePaginacao/index'
import { customTheme } from '../../../../styles/customTheme'
import { CicloStatusGeral } from '../../../../types/ciclosApiType'
import { cabecalhoLancamentoNaoAssociado } from '../../../../types/tabelaTypes'
import { formateCurrency } from '../../../../utils/stringUtils'
import { getComparator, ordenarCabecalho } from '../../../../utils/tabelaUtils'
import { LancamentoNaoAssociadoIO } from './LancamentoNaoAssociado.IO'
import { Tipografia } from './../../../../components/Tipografia/index'
import { Botao } from '../../../../components/Botao'

export type LancamentonAssociadoProps = {
  parceiroId: number
  cicloId: string
  setHabilitarConfirmar(enable: boolean): void
  cicloStatusGeral: CicloStatusGeral
}

export function LancamentoNaoAssociadoView({ _io }: IOProps<LancamentoNaoAssociadoIO, LancamentonAssociadoProps>) {
  const { Tabela, Box, ContainerCabecalho, SubtitleCabecalho, Container, TableRow, BoxAssociada, TableCellBody } = _io.styles

  const tabelaLancamentoNaoAssociado = () => {
    return !_io.loading ? (
      <TableContainer>
        <Tabela>
          <EnhancedTableHead
            alignCenter={false}
            headCells={cabecalhoLancamentoNaoAssociado}
            order={_io.ordem}
            orderBy={_io.ordemBy}
            onRequestSort={_io.onRequestSort}
            corTextoCabecalho={customTheme.colors.red06}
          />
          <TableBody>
            {ordenarCabecalho(_io.naoAssociado?.itens, getComparator(_io.ordem, _io.ordemBy)).map((row, index) => (
              <TableRow key={index}>
                <TableCellBody>{row.identificadorPedido}</TableCellBody>
                <TableCellBody>{row.loja}</TableCellBody>
                <TableCellBody>{row.tipoLancamento}</TableCellBody>
                <TableCellBody>{row.lancamento}</TableCellBody>
                <TableCellBody>{formateCurrency(row.valor)}</TableCellBody>
                <TableCellBody>{row.parcela}</TableCellBody>
                <TableCell>
                  {row?.tratada ? (
                    <BoxAssociada>
                      <Typography variant="body2">Associado</Typography>
                    </BoxAssociada>
                  ) : (
                    <Botao
                      onClick={() => _io.onSubmitLancamento(index, row)}
                      textoBotao="Associar Lançamento"
                      estilo="contained"
                      cor="erro"
                      width="230px"
                      tamanho="pequeno"
                    />
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Tabela>
        <TablePagination
          component="div"
          count={_io.totalLinhas}
          rowsPerPage={_io.linhasPorPagina}
          page={_io.pagina}
          onPageChange={_io.onMudancaPagina}
          onRowsPerPageChange={_io.onMudancaLinhasPorPagina}
          labelRowsPerPage="Itens por página"
          ActionsComponent={ComponentePaginacao}
        />
      </TableContainer>
    ) : (
      <Box>
        <TableRow tabIndex={-1}>
          <TableCell align="center">
            <CircularProgress />
          </TableCell>
        </TableRow>
      </Box>
    )
  }

  return (
    <>
      {_io.naoAssociado?.itens && _io.naoAssociado?.itens?.length > 0 ? (
        <Container>
          <ContainerCabecalho>
            <Tipografia variant="h6" colorText={customTheme.colors.red06}>
              Lançamentos não associados
            </Tipografia>
            <SubtitleCabecalho>
              Foram localizados novos tipos de lançamentos de parceiros que não estão associados a nenhum tipo de lançamento eFácil.
            </SubtitleCabecalho>
          </ContainerCabecalho>
          {tabelaLancamentoNaoAssociado()}
        </Container>
      ) : null}
    </>
  )
}
