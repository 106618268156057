import { StatusTracking } from '../../../types/statusTrackingTypes'
import api from '../../axiosInstance'

const buscarStatusTracking = async (): Promise<StatusTracking[]> => {
  const URL_BUSCAR_STATUS_TRACKING = `/StatusTracking`
  const response = await api.conectarGet<StatusTracking[]>(URL_BUSCAR_STATUS_TRACKING)
  return response.data
}

export default {
  buscarStatusTracking
}
