import { IOProps } from 'react-compose-io'
import setaBaixaPorcentagem from '../../assets/icons/setaBaixaVermelha2.svg'
import setaAltaPorcentagem from '../../assets/icons/setaCimaVerde.svg'
import { customTheme } from '../../styles/customTheme'
import { formateCurrency, valorMenorZero } from '../../utils/stringUtils'
import { DownloadRelatorio } from '../DownloadRelatorio/index'
import { CardValorQuantidadeIO } from './CardValorQuantidade.IO'

export type CardValorQuantidadeProps = {
  tituloCard: string
  valorTotal: number | string
  quantidade: number
  quantidadeTexto: string
  dataPeriodo: string
  valorPesquisado: boolean
  status: number
  somenteQuantidade: boolean
  textoTooltip?: string
  porcentagem?: string | number
  exportarRelatorioParceiro?: (data: string) => void
  dataComparacao?: string
  exibirBotaoExportar?: boolean
  url?: string
  fonteMargemTituloGerado?: string
}

export function CardValorQuantidadeView({
  _io,
  quantidade,
  quantidadeTexto,
  valorTotal,
  porcentagem,
  textoTooltip,
  dataPeriodo,
  valorPesquisado,
  dataComparacao,
  tituloCard,
  exibirBotaoExportar,
  url,
  exportarRelatorioParceiro,
  somenteQuantidade,
  fonteMargemTituloGerado
}: IOProps<CardValorQuantidadeIO, CardValorQuantidadeProps>) {
  const {
    Dividers,
    Container,
    TituloCard,
    ContainerTituloCard,
    ContainerTextoCard,
    ValorTotal,
    SubtituloTexto,
    TituloValor,
    ContainerSubtitulo,
    Img,
    ValorData,
    CotainerColuna,
    SubtituloItem
  } = _io.styles

  return (
    <Container>
      <ContainerTituloCard>
        <TituloCard fonteMargemTituloGerado={fonteMargemTituloGerado ? fonteMargemTituloGerado : '0'}>{tituloCard}</TituloCard>
        <ValorData>{dataPeriodo}</ValorData>
      </ContainerTituloCard>
      <ContainerTextoCard>
        {somenteQuantidade ? null : (
          <>
            <CotainerColuna>
              <SubtituloItem>Valor total</SubtituloItem>
              <ValorTotal>{formateCurrency(valorTotal)}</ValorTotal>
            </CotainerColuna>
            <Dividers></Dividers>
          </>
        )}
        <CotainerColuna>
          <SubtituloItem>{quantidadeTexto}</SubtituloItem>
          <ValorTotal>{quantidade?.toLocaleString('pt-BR')}</ValorTotal>
        </CotainerColuna>
        {exibirBotaoExportar && exportarRelatorioParceiro ? (
          <DownloadRelatorio
            url={url}
            textoTooltip={textoTooltip ?? ''}
            relatorioDisponivel={_io.relatorioDisponivel}
            iconeCarregandoRelatorio={_io.carregandoRelatorio}
            gerarRelatorio={() => (valorPesquisado && dataComparacao ? exportarRelatorioParceiro(dataComparacao) : exportarRelatorioParceiro(dataPeriodo))}
          />
        ) : null}
      </ContainerTextoCard>
      {valorPesquisado && (
        <ContainerSubtitulo>
          <Img
            alt="Porcentagem"
            src={valorMenorZero(Number(porcentagem), setaBaixaPorcentagem, setaAltaPorcentagem)}
            corIcone={valorMenorZero(Number(porcentagem), customTheme.colors.red00, customTheme.colors.green03)}
          />
          <TituloValor corTexto={valorMenorZero(Number(porcentagem), customTheme.colors.red06, customTheme.colors.green06)}>{porcentagem}%</TituloValor>
          <SubtituloTexto> do que em {dataComparacao}</SubtituloTexto>
        </ContainerSubtitulo>
      )}
    </Container>
  )
}
