import styled from 'styled-components'
import { customTheme } from '../../../../styles/customTheme'
import { Box } from '@material-ui/core'

export const popoverFiltroControleComissaoStyles = {
  ContainerBotao: styled.div`
    background: ${customTheme.colors.white01};
    padding: 10px 10px 6px 10px;
    margin-left: 16px;
    border-radius: 4px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  `,
  ContainerFiltro: styled(Box)`
    margin: 12px;
    padding-left: 8px;
  `
}
