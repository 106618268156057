import {
  ControleComissaoStatusForm,
  ControleComissaoPaginado,
  ControleComissaoPaginadoRequest,
  ControleComissaoStatusResumo,
  AdicionarChamadoForm,
  EditarChamadoForm,
  ControleComissaoChamados,
  ObterControleComissaoPedidosResponse,
  ObterControleComissaoPedidosQuery,
  AtualizaPedidosComissaoRequest,
  ControleComissaoPedidoResponse,
  ResumosPedidoComissaoParceiro
} from '../../../types/controleComissaoTypes'
import { concatenarFiltrosQuery } from '../../../utils/stringUtils'
import api from '../../axiosInstance'

const buscarControleComissaoStatusResumo = async (): Promise<ControleComissaoStatusResumo[]> => {
  const URL_BUSCAR_STATUS_RESUMO_CONTROLE_COMISSAO = `ControleComissao/Status/Resumos`
  const response = await api.conectarGet<ControleComissaoStatusResumo[]>(URL_BUSCAR_STATUS_RESUMO_CONTROLE_COMISSAO)

  return response.data
}

const buscarControleComissaoResumo = async (controleComissaoPaginadoRequest: ControleComissaoPaginadoRequest): Promise<ControleComissaoPaginado> => {
  const URL_BUSCAR_RESUMO_CONTROLE_COMISSAO = `ControleComissao/Resumos${concatenarFiltrosQuery(controleComissaoPaginadoRequest)}`

  const response = await api.conectarGet<ControleComissaoPaginado>(URL_BUSCAR_RESUMO_CONTROLE_COMISSAO)

  return response.data
}

const editarControleComissaoResumo = async (id: string, body: ControleComissaoStatusForm): Promise<boolean> => {
  const URL_EDITAR_CONTROLE_COMISSAO = `ControleComissao/Resumos/${id}`
  const response = await api.conectarPut<boolean>(URL_EDITAR_CONTROLE_COMISSAO, body)

  return response.data
}

const buscarControleComissaoChamados = async (documentId: string): Promise<ControleComissaoChamados[]> => {
  const URL_BUSCAR_CHAMADOS = `ControleComissao/${documentId}/Chamados`

  const response = await api.conectarGet<ControleComissaoChamados[]>(URL_BUSCAR_CHAMADOS)

  return response.data
}

const adicionarNovoChamado = async (documentId: string, body: AdicionarChamadoForm): Promise<boolean> => {
  const URL_ADICIONAR_CHAMADO = `ControleComissao/${documentId}/chamados`
  const response = await api.conectarPost<boolean>(URL_ADICIONAR_CHAMADO, body)

  return response.data
}

const editarChamado = async (documentId: string, chamadoId: string, body: EditarChamadoForm): Promise<boolean> => {
  const URL_EDITAR_CHAMADO = `ControleComissao/${documentId}/Chamados/${chamadoId}`
  const response = await api.conectarPut<boolean>(URL_EDITAR_CHAMADO, body)

  return response.data
}

const obterControleComissaoPedidos = async (
  obterControleComissaoPedidosQuery: ObterControleComissaoPedidosQuery,
  parceiroId: number
): Promise<ObterControleComissaoPedidosResponse> => {
  const URL_OBTER_CONTROLE_COMISSAO_PEDIDOS = `ControleComissao/Pedidos/${parceiroId}${concatenarFiltrosQuery(obterControleComissaoPedidosQuery)}`

  const response = await api.conectarGet<ObterControleComissaoPedidosResponse>(URL_OBTER_CONTROLE_COMISSAO_PEDIDOS)
  return response.data
}

const obterResumosPedidoComissaoParceiro = async (parceiroId: number): Promise<ResumosPedidoComissaoParceiro> => {
  const URL_OBTER_CONTROLE_COMISSAO_PEDIDOS_RESUMO = `ControleComissao/Pedidos/Resumo/${parceiroId}`

  const response = await api.conectarGet<ResumosPedidoComissaoParceiro>(URL_OBTER_CONTROLE_COMISSAO_PEDIDOS_RESUMO)

  return response.data ?? null
}

const atualizaPedidosComissao = async (body: AtualizaPedidosComissaoRequest[]): Promise<boolean> => {
  const URL_ATUALIZA_PEDIDO_COMISSAO = `ControleComissao/Pedidos`
  const response = await api.conectarPatch<boolean>(URL_ATUALIZA_PEDIDO_COMISSAO, body)
  return response.data
}

const gerarResumosControleComissaoPedidosTela = async (body: ControleComissaoPedidoResponse[]): Promise<string> => {
  const URL_GERA_RESUMO_PEDIDO_COMISSAO_TELA = `ControleComissao/Pedidos/ResumoTela`
  const response = await api.conectarPost<string>(URL_GERA_RESUMO_PEDIDO_COMISSAO_TELA, body)
  return response.data
}

const atualizaResumosPedidosComissao = async (parceiroId: number): Promise<number> => {
  const URL_GERAR_CONTROLE_COMISSAO_PEDIDOS_RESUMO = `ControleComissao/Pedidos/Resumo/${parceiroId}`

  const response = await api.conectarPost<number>(URL_GERAR_CONTROLE_COMISSAO_PEDIDOS_RESUMO)
  return response.status
}

export default {
  buscarControleComissaoResumo,
  editarControleComissaoResumo,
  buscarControleComissaoStatusResumo,
  buscarControleComissaoChamados,
  adicionarNovoChamado,
  editarChamado,
  obterControleComissaoPedidos,
  atualizaPedidosComissao,
  gerarResumosControleComissaoPedidosTela,
  obterResumosPedidoComissaoParceiro,
  atualizaResumosPedidosComissao
}
