import MaterialTable from 'material-table'
import React from 'react'
import { IOProps } from 'react-compose-io'
import { tableIcons } from '../../../../components/TabelaEditavel/icons'
import { customTheme } from '../../../../styles/customTheme'
import { Order } from '../../../../types/tabelaTypes'
import { TabelaEditavelErroProcessamentoIO } from './TabelaEditavelErroProcessamento.IO'

export type TabelaEditavelErroProcessamentoProps = {
  corpoTabela: string[][]
  cabecalhoTabela: string[]
  setCheckboxSelecionado: React.Dispatch<React.SetStateAction<number[]>>
  pagina: number
  opcoesNumerosPaginacao: number[]
  onMudancaLinhasPorPagina: (numero: number) => void
  onMudancaPorPagina: (newPage: number, pageSize: number) => void
  totalLinhas?: number
  linhasPorPagina: number
  onOrdernarTabela: (order: number, tipoOrdernacao: Order) => void | undefined
  editarItensErroProcessamento: (itemErroProcessamento: string[], index: number) => Promise<void>
}

export function TabelaEditavelErroProcessamentoView({
  _io,
  pagina,
  opcoesNumerosPaginacao,
  onMudancaLinhasPorPagina,
  onMudancaPorPagina,
  totalLinhas,
  onOrdernarTabela,
  linhasPorPagina
}: IOProps<TabelaEditavelErroProcessamentoIO, TabelaEditavelErroProcessamentoProps>) {
  return (
    <MaterialTable
      title=""
      style={{
        backgroundColor: `${customTheme.colors.red00}`,
        boxShadow: 'none',
        color: `${customTheme.colors.red06}`
      }}
      columns={_io.colunaErroProcessamento}
      data={_io.corpoTabelaErroProcessamento}
      icons={tableIcons}
      totalCount={totalLinhas}
      options={{
        selection: true,
        search: false,
        filtering: false,
        rowStyle: {
          backgroundColor: `${customTheme.colors.red00}`,
          color: `${customTheme.colors.red06}`,

          whiteSpace: 'nowrap'
        },
        headerStyle: {
          backgroundColor: `${customTheme.colors.red00}`,
          color: `${customTheme.colors.red06}`,
          textAlign: 'start',
          padding: '16px',
          fontFamily: `${customTheme.fontFamily.rubik500}`,
          fontWeight: 500,
          fontSize: `${customTheme.fontSize[15]}`,
          lineHeight: '20px'
        },
        actionsCellStyle: {
          color: `${customTheme.colors.grey09}`,
          backgroundColor: `${customTheme.colors.red00}`
        },
        toolbar: false,
        showTitle: false,
        actionsColumnIndex: 0,
        pageSizeOptions: opcoesNumerosPaginacao,
        pageSize: linhasPorPagina,
        paging: true,
        emptyRowsWhenPaging: false,
        paginationType: 'normal',
        showSelectAllCheckbox: true
      }}
      localization={{
        header: {
          actions: ''
        },
        pagination: {
          labelDisplayedRows: '{from}-{to} de {count}',
          labelRowsSelect: 'linhas por página:'
        }
      }}
      onSelectionChange={(erroProcessamento) => {
        _io.onCheckBoxSelecionado(erroProcessamento)
      }}
      onChangeRowsPerPage={onMudancaLinhasPorPagina}
      onChangePage={onMudancaPorPagina}
      onOrderChange={onOrdernarTabela}
      page={pagina}
      editable={{
        onRowUpdate: (novoItemlinhaErroProcessamento, itemlinhaErroProcessamento) => {
          return _io.editarElementosTabela(itemlinhaErroProcessamento, novoItemlinhaErroProcessamento)
        }
      }}
    />
  )
}
