import { Backdrop, Grid } from '@material-ui/core'
import { IOProps } from 'react-compose-io'
import { ModalDesativarIO } from './ModalDesativar.IO'
import { Botao } from '../../../../components/Botao'

export type ModalDesativarProps = {}

export function ModalDesativarView({ _io }: IOProps<ModalDesativarIO, ModalDesativarProps>) {
  const { FadeContainer, TextoSpan, Paper, CloseIcon, TextoSimplesSpan, Container, ContainerModal } = _io.styles

  return (
    <ContainerModal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={_io.abrirModal}
      onClose={_io.fecharModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
    >
      <FadeContainer in={_io.abrirModal}>
        <Paper>
          <Container>
            <CloseIcon onClick={_io.fecharModal} />
            <TextoSimplesSpan>
              Tem certeza que deseja desativar {_io.descricao ? <TextoSpan>{`"${_io.descricao}"`}</TextoSpan> : ''} dos tipos de lançamentos eFacil
            </TextoSimplesSpan>
          </Container>
          <Grid container justifyContent="center" spacing={2}>
            <Grid item>
              <Botao textoBotao="Cancelar" estilo="text" cor="primaria" onClick={_io.handleCancelar} />
            </Grid>
            <Grid item>
              <Botao textoBotao="Confirmar" estilo="rounded" cor="primaria" onClick={_io.handleDesativar} />
            </Grid>
          </Grid>
        </Paper>
      </FadeContainer>
    </ContainerModal>
  )
}
