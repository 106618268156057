import { useState } from 'react'
import { modalChamadosStyles } from './ModalChamados.style'

export function modalChamadosIO() {
  const [mostrarAdicionarChamadoForm, setMostrarAdicionarChamadoForm] = useState(false)
  const [isEditandoChamado, setIsEditandoChamado] = useState(false)

  return {
    styles: modalChamadosStyles,
    mostrarAdicionarChamadoForm,
    setMostrarAdicionarChamadoForm,
    isEditandoChamado,
    setIsEditandoChamado
  }
}

export type ModalChamadosIO = ReturnType<typeof modalChamadosIO>
