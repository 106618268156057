import { Grid } from '@material-ui/core'
import styled from 'styled-components'
import { ContainerSombra } from '../../../../components/ContainerSombra'
import { customTheme } from './../../../../styles/customTheme'

export const uploadPlanilhaCicloPedidoStyles = {
  BoxUploadPlanilha: styled(ContainerSombra)`
    margin-top: 24px;
    padding: 28px 40px;
  `,
  DropContainer: styled.div.attrs({
    className: 'dropzone'
  })`
    border: 1px dashed ${customTheme.colors.grey00};
    padding: 2%;
    background-color: ${customTheme.colors.white01};
    border-radius: 4px;
    cursor: pointer;
    transition: height 0.2s ease;
    margin: 24px 0;
  `,
  DropSuccess: styled.div.attrs({
    className: 'dropzone-end'
  })`
    padding: 1%;
    background-color: ${customTheme.colors.grey08};
    border-radius: 10px;
    transition: height 0.2s ease;
    cursor: auto;
  `,
  SpanTitle: styled.span.attrs({
    className: 'span-title'
  })`
    color: ${customTheme.colors.white01};
    font-size: ${customTheme.fontSize[12]};
    line-height: 1.2;
    text-overflow: ellipsis;
  `,
  SpanSize: styled.span.attrs({
    className: 'span-size'
  })`
    color: ${customTheme.colors.white01};
    font-size: ${customTheme.fontSize[10]};
    text-overflow: ellipsis;
  `,
  UploadMessage: styled.p`
    display: flex;
    color: ${customTheme.colors.grey14};
    justify-content: center;
    align-items: center;
    margin: 5px;
    font-size: ${customTheme.fontSize[16]};
  `,
  UploadButton: styled.span.attrs({
    className: 'filepond--label-action'
  })`
    background-color: ${customTheme.colors.white01};
    color: ${customTheme.colors.blue06};
    padding: 5px 10px;
    border-radius: 18px;
    border: 1px solid ${customTheme.colors.blue06};
    text-decoration: none;
  `,
  GridUploadInfo: styled(Grid)`
    display: flex;
    flex-direction: column;
  `,
  GridUploadInfoIndicador: styled(Grid)`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-right: 0px;
  `
}
