import { Grid, TableCell } from '@material-ui/core'
import styled from 'styled-components'
import { ContainerSombra } from '../../components/ContainerSombra'
import { customTheme } from '../../styles/customTheme'

export const visualizarContabilizacaoStyle = {
  Container: styled(ContainerSombra)`
    padding: 24px;
  `,
  GridCenter: styled(Grid)`
    flex-grow: 1;
  `,
  GridContainer: styled(Grid).attrs({
    container: true,
    direction: 'row',
    spacing: 3,
    justifyContent: 'flex-start',
    alignItems: 'center'
  })`
    padding-top: 16px;
  `,
  TableCellNegativo: styled(TableCell)`
    font-weight: bold;
    color: ${customTheme.colors.red06};
  `,
  TableCellPositivo: styled(TableCell)`
    font-weight: bold;
    color: ${customTheme.colors.blue05};
  `
}

export default visualizarContabilizacaoStyle
