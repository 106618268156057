import { MenuItem, InputLabel, ListItemIcon, ListItemText, Typography, Grid } from '@material-ui/core'
import { Dispatch } from 'react'
import { IOProps } from 'react-compose-io'
import { Controller } from 'react-hook-form'
import { Transportadora } from '../../types/transportadoras'
import { ModalFiltroParceiroIO } from './ModalFiltroParceiro.IO'
import { CustomDialog } from './../CustomDialog/CustomDialog'
import { Parceiro } from '../../types/parceiroApiTypes'
import { Botao } from '../Botao'

export type ModalFiltroParceiroProps = {
  descricaoModal: string
  isShow: boolean
  isClosed: () => void
  handleClickParceiro: (parceiroId: number) => void
  setParceiroTransportadoras?: Dispatch<Transportadora[]>
  parceiros: Parceiro[]
}

export default function ModalFiltroParceiroView({ _io, isShow, descricaoModal, parceiros }: IOProps<ModalFiltroParceiroIO, ModalFiltroParceiroProps>) {
  const { Avatar, MenuItemContainer, Select, FormControl } = _io.styles

  return (
    <CustomDialog
      open={isShow}
      onClose={_io.fecharModal}
      id="modal-filtrar-parceiros"
      titulo="Filtrar por parceiro"
      habilitarAcoes
      fecharHabilitado
      fecharModal={_io.fecharModal}
      actions={
        <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center">
          <Grid item>
            <Botao textoBotao="Cancelar" estilo="text" cor="primaria" onClick={_io.fecharModal} />
          </Grid>
          <Grid item>
            <Botao textoBotao="Confirmar" estilo="rounded" cor="primaria" onClick={_io.handleSubmit(_io.handleConfirmar)} />
          </Grid>
        </Grid>
      }
    >
      <Typography variant="body2"> {descricaoModal} </Typography>
      <Typography variant="body2"> Qual você deseja visualizar?</Typography>
      <form>
        <Controller
          render={({ field }) => (
            <FormControl variant="outlined" size="small" fullWidth>
              <InputLabel>Parceiro</InputLabel>

              <Select {...field} label="Parceiro">
                {parceiros.map((parceiro) => (
                  <MenuItem key={parceiro.parceiroId} value={parceiro.parceiroId}>
                    <MenuItemContainer>
                      <ListItemIcon>
                        <Avatar src={parceiro.logoUrl} />
                      </ListItemIcon>
                      <ListItemText primary={parceiro.nomeFantasia} />
                    </MenuItemContainer>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          name="parceiroId"
          control={_io.control}
        />
      </form>
    </CustomDialog>
  )
}
