import { Dialog, DialogActions, DialogTitle, DialogContent } from '@material-ui/core'
import styled from 'styled-components'
import { customTheme } from '../../../../styles/customTheme'
import { AddCircleRounded } from '@material-ui/icons'

export const modalChamadosStyles = {
  Dialog: styled(Dialog)`
    color: ${customTheme.colors.grey01};
  `,
  DialogActions: styled(DialogActions)`
    justify-content: center;
  `,
  DialogTitle: styled(DialogTitle)`
    text-align: center;
  `,
  DialogContent: styled(DialogContent)`
    width: 500px;
  `,
  AddCircleRounded: styled(AddCircleRounded).attrs({
    color: 'primary',
    fontSize: 'large'
  })`
    margin-bottom: 15px;
    color: ${customTheme.colors.azul06};

    :hover {
      cursor: pointer;
      opacity: 0.75;
    }
  `,
  AddCircleRoundedDisabled: styled(AddCircleRounded).attrs({
    fontSize: 'large'
  })`
    margin-bottom: 15px;
    color: ${customTheme.colors.grey02};
  `
}
