import { ContaContabil, RelatorioConciliacaoFiltros } from '../../../types/relatorioConciliacaoTypes'
import { concatenarFiltrosQuery } from '../../../utils/stringUtils'
import api from '../../axiosInstance'

const exportarRelatorioConciliacao = async (filtros?: RelatorioConciliacaoFiltros, arquivo?: FormData | null): Promise<string> => {
  const URL_BUSCAR_HISTORICO = `/RelatorioGeralConciliacao/RelatorioGeral${concatenarFiltrosQuery({
    contaContabilId: filtros?.contaContabilId,
    dataInicio: filtros?.dataInicio,
    dataFim: filtros?.dataFim,
    pedidoMKT: filtros?.pedidoMKT,
    parceiroId: filtros?.parceiroId
  })}`
  const response = await api.conectarPost<string>(URL_BUSCAR_HISTORICO, arquivo, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })

  return response.data
}

const buscarContaContabil = async (): Promise<ContaContabil[]> => {
  const URL_BUSCAR_CONTA_CONTABIL = `/ObterContas`
  const response = await api.conectarGet<ContaContabil[]>(URL_BUSCAR_CONTA_CONTABIL)
  return response.data
}

export default {
  exportarRelatorioConciliacao,
  buscarContaContabil
}
