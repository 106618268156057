import { IOProps } from 'react-compose-io'
import { RegraTransformacaoForm } from './components/RegraTransformacaoForm'
import { RegraTransformacaoIO } from './RegraTransformacao.IO'

export type RegraTransformacaoProps = {}

export function RegraTransformacaoView({ _io }: IOProps<RegraTransformacaoIO, RegraTransformacaoProps>) {
  function RegraTransformacaoEdicao() {
    return <>{_io.regraTransformacaoEditar ? <RegraTransformacaoForm regraTransformacaoEditar={_io.regraTransformacaoEditar} /> : null}</>
  }

  return _io.regraId ? RegraTransformacaoEdicao() : <RegraTransformacaoForm />
}
