import { Box, Grid, TableCell, TableRow, Typography } from '@material-ui/core'
import styled from 'styled-components'
import { ContainerSombra } from '../../../../components/ContainerSombra'
import { customTheme } from '../../../../styles/customTheme'

export const erroProcessamentoStyles = {
  Container: styled.main`
    display: inline-grid;
    width: -webkit-fill-available;
  `,
  ContainerStyled: styled(ContainerSombra)`
    margin-top: 19px;
    background-color: ${customTheme.colors.red00};
    flex-grow: 1;
    padding: 18px;
    overflow: auto;
  `,
  ContainerTitle: styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
  `,
  ContainerCabecalho: styled(Grid).attrs({ container: true, justifyContent: 'space-between', alignItems: 'center' })`
    display: flex;
    justify-content: 'space-between';
    align-items: 'center';
  `,
  SubtitleCabecalho: styled(Typography).attrs({
    variant: 'subtitle1'
  })`
    margin-top: 8px;
    margin-bottom: 24px;
  `,
  TableRow: styled(TableRow).attrs({
    style: {
      borderBottomStyle: 'solid',
      borderColor: `${customTheme.colors.red06}`,
      borderWidth: '1.1px'
    },
    MuiTableCell: {
      root: {
        backgroundColor: (color: string) => color
      }
    }
  })`
    border: '1px solid red';
  `,
  DividerCabecalho: styled(TableCell).attrs({
    style: {
      color: `${customTheme.colors.grey06}`,
      textalign: 'center'
    }
  })``,
  Box: styled(Box)`
    display: flex;
    justify-content: center;
  `,
  GridBotao: styled(Grid)`
    display: flex;
    gap: 8px;
  `
}
