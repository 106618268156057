import { Grid } from '@material-ui/core'
import { IOProps } from 'react-compose-io'
import { FormProvider } from 'react-hook-form'
import { CustomActionsButtons } from '../../components/CustomActionsButtons'
import { FormularioRegra } from '../../components/CustomFormControl/FormularioRegra/index'
import { CustomSimpleTable } from '../../components/CustomSimpleTable'
import Modal from '../../components/Modal/Modal'
import { PreVisualizarParametro } from '../../components/PreVisualizarParametro'
import { TabelaErroProcessamento } from './components/TabelaErroProcessamento/index'
import { AdicionarNovoParametroIO } from './AdicionarNovoParametro.IO'

export type AdicionarNovoParametroProps = {}

export function AdicionarNovoParametroView({ _io }: IOProps<AdicionarNovoParametroIO, AdicionarNovoParametroProps>) {
  const { Titulo, Container } = _io.styles

  return (
    <>
      <Container>
        <Titulo variant="h6">Planilha parceiro</Titulo>
        {_io.erroProcessamentoLista.itens[_io.encontrarLinha] && (
          <TabelaErroProcessamento
            header={_io.erroProcessamentoLista.headers.slice(0, -2)}
            itens={_io.erroProcessamentoLista.itens[_io.encontrarLinha].slice(0, -2)}
          />
        )}
      </Container>
      <Container>
        <Titulo variant="h6">Adicionar novo parâmetro</Titulo>
        <FormProvider {..._io.metodosFormulario}>
          <form onSubmit={_io.metodosFormulario.handleSubmit(_io.salvarRegraTransformacao)}>
            <FormularioRegra
              condicaoLista={_io.condicaoLista}
              acaoLista={_io.acaoLista}
              grupoRegraLista={_io.grupoRegraLista}
              cabecalhosPlanilha={_io.campoDados}
            />
            <CustomActionsButtons
              hasPreview
              hasCancel
              hasSave
              hasSaveContinue
              handlePreview={_io.visualizarRegraTransformacao}
              handleCancelar={_io.handleCancelar}
              handleSave={_io.erroSalvarDados}
              handleSaveContinue={_io.dadosSalvosSucesso}
            />
          </form>
        </FormProvider>
      </Container>
      <Modal
        title="Pré visualização do novo parâmetro"
        openModal={_io.abrirModal}
        fullWidth={false}
        maxWidth={false}
        onClose={_io.handleFecharModal}
        onAction={_io.handleFecharModal}
        hiddenButton
      >
        <Grid item sm={12}>
          <CustomSimpleTable cabecalhos={['Identificador Pedido', 'Loja', 'Tipo Lançamento', 'Lançamento', 'Valor', 'Parcela']}>
            {_io.preVisualizar != undefined ? <PreVisualizarParametro row={_io.preVisualizar} /> : null}
          </CustomSimpleTable>
        </Grid>
      </Modal>
    </>
  )
}

export default AdicionarNovoParametroView
