import React from 'react'
import { useHistory } from 'react-router-dom'
import { PAGES } from '../../../../router/routes'
import { customTheme } from '../../../../styles/customTheme'
import { Parceiro } from '../../../../types/parceiroApiTypes'
import { linhaTabelaClicavelNovaGuia } from '../../../../utils/tabelaUtils'
import { itemParceiroStyles } from './ItemParceiro.style'

export function itemParceiroIO() {
  const rota = useHistory()

  const corFundoStatus = (status: string) => {
    return status == 'Novo Parceiro' ? customTheme.colors.red00 : customTheme.colors.green03
  }

  const corTextoStatus = (status: string) => {
    return status == 'Novo Parceiro' ? customTheme.colors.red06 : customTheme.colors.green05
  }

  const getParceiroAtivo = (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>, parceiro: Parceiro) => {
    if (parceiro.status !== 'Ativo') {
      linhaTabelaClicavelNovaGuia(event, PAGES.editarParceiroId(parceiro.parceiroId), rota)
    } else {
      linhaTabelaClicavelNovaGuia(event, PAGES.selecionarParametrizacao(parceiro.parceiroId), rota)
    }
  }

  return {
    styles: itemParceiroStyles,
    corFundoStatus,
    corTextoStatus,
    getParceiroAtivo
  }
}

export type ItemParceiroIO = ReturnType<typeof itemParceiroIO>
