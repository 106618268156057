import { useForm } from 'react-hook-form'
import { ControleComissaoStatusForm } from '../../../../types/controleComissaoTypes'
import { modalControleComissaoStatusStyles } from './ModalControleComissaoStatus.style'
import { ModalControleComissaoStatusProps } from './ModalControleComissaoStatus.View'

export function modalControleComissaoStatusIO({ dadosControleComissao }: ModalControleComissaoStatusProps) {
  const { handleSubmit, control } = useForm<ControleComissaoStatusForm>({
    defaultValues: {
      status: dadosControleComissao.statusAtual
    }
  })

  return {
    styles: modalControleComissaoStatusStyles,
    control,
    handleSubmit
  }
}

export type ModalControleComissaoStatusIO = ReturnType<typeof modalControleComissaoStatusIO>
