import { DadosHistoricoIO } from './DadosHistorico.IO'
import { IOProps } from 'react-compose-io'
import Grid from '@material-ui/core/Grid'
import { Tipografia } from '../../../../components/Tipografia'
import { customTheme } from '../../../../styles/customTheme'
import { GraficoBarras } from '../../../../components/GraficoBarras'

export type DadosHistoricoProps = {
  icone: string
  tituloGrafico: string
  valoresGrafico: number[]
  legendaGrafico: string[][]
  valor: string
  valorDescricao: string
  legendaEixoYGrafico: string
  tipoValorGrafico: 'moeda' | 'unidade'
  corBarraGrafico: string
}

export function DadosHistoricoView({
  _io,
  valoresGrafico,
  legendaGrafico,
  tituloGrafico,
  icone,
  valor,
  valorDescricao,
  legendaEixoYGrafico,
  tipoValorGrafico,
  corBarraGrafico
}: IOProps<DadosHistoricoIO, DadosHistoricoProps>) {
  const { GridContainer, Imagem } = _io.styles

  return (
    <div>
      <GridContainer container justifyContent="space-evenly" alignItems="center">
        <Grid item>
          <GraficoBarras
            tituloGrafico={tituloGrafico}
            descricaoColunas={legendaGrafico}
            dadosColunas={valoresGrafico}
            legendaEixoY={legendaEixoYGrafico}
            valorDivididoLegenda={_io.valorDivididoLegenda}
            corBarra={() => corBarraGrafico}
            height="220px"
            width={_io.widthGrafico}
            tipoValor={tipoValorGrafico}
          />
        </Grid>
        <Grid item>
          <Grid container alignItems="center" direction="column">
            <Grid item>
              <Imagem src={icone} />
            </Grid>
            <Grid item>
              <Tipografia fontWeight="500" fontSize={customTheme.fontSize[18]}>
                {valorDescricao}
              </Tipografia>
            </Grid>
            <Grid item>
              <Tipografia fontWeight="600" fontSize={customTheme.fontSize[32]}>
                {valor}
              </Tipografia>
            </Grid>
          </Grid>
        </Grid>
      </GridContainer>
    </div>
  )
}
