import { collapseInadimplenciaItemStyles } from './CollapseInadimplenciaItem.style'
import { CollapseInadimplenciaItemProps } from './CollapseInadimplenciaItem.View'

export function collapseInadimplenciaItemIO({ controleCollapse, setControleCollapse, inadimplenciasPorAno }: CollapseInadimplenciaItemProps) {
  const handleToogleDetalhes = () => {
    if (isDetalhesOpen()) {
      setControleCollapse([])
    } else {
      setControleCollapse([inadimplenciasPorAno.ano])
    }
  }

  const isDetalhesOpen = () => controleCollapse.some((x) => x == inadimplenciasPorAno.ano)

  return {
    styles: collapseInadimplenciaItemStyles,
    isDetalhesOpen: isDetalhesOpen(),
    handleToogleDetalhes
  }
}

export type CollapseInadimplenciaItemIO = ReturnType<typeof collapseInadimplenciaItemIO>
