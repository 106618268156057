import { Table, TableBody, TableContainer, TableHead, TableRow } from '@material-ui/core'
import { IOProps } from 'react-compose-io'
import { TabelaErroProcessamentoIO } from './TabelaErroProcessamento.IO'

export type TabelaErroProcessamentoProps = {
  header?: string[]
  itens: string[]
}

export function TabelaErroProcessamentoView({ _io, itens, header }: IOProps<TabelaErroProcessamentoIO, TabelaErroProcessamentoProps>) {
  const { ContainerSombra, TableCell } = _io.styles

  return (
    <ContainerSombra>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>{header && header.map((row, index) => <TableCell key={index}>{row}</TableCell>)}</TableRow>
          </TableHead>
          <TableBody>
            <TableRow>{itens.length > 0 && itens.map((row, index) => <TableCell key={index}>{row}</TableCell>)}</TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </ContainerSombra>
  )
}
