import { Table, TableBody, TableContainer, TablePagination } from '@material-ui/core'
import { IOProps } from 'react-compose-io'
import { HistoricoRegularizacao } from '../../types/regularizacaoTypes'
import { Paginacao } from '../../types/tabelaTypes'
import { ItemHistoricoRegularizacao } from '../ItemHistoricoRegularizacao/index'
import { ComponentePaginacao } from '../Tabela/ComponentePaginacao/index'
import { HistoricoRegularizacaoIO } from './HistoricoRegularizacao.IO'

export type HistoricoRegularizacaoProps = {
  historicosRegularizacao: HistoricoRegularizacao[]
  totalItens: number
  buscarHistorico: (paginacao: Paginacao) => void
  valorSetTimeOut: number
  descricaoBaixas: string
}

export function HistoricoRegularizacaoView({
  _io,
  historicosRegularizacao,
  totalItens,
  descricaoBaixas
}: IOProps<HistoricoRegularizacaoIO, HistoricoRegularizacaoProps>) {
  const { TextoTabela, ContainerTabela } = _io.styles

  return (
    <>
      {historicosRegularizacao.length > 0 && (
        <>
          <ContainerTabela>
            <TextoTabela>Histórico</TextoTabela>
            <TableContainer>
              <Table>
                <TableBody>
                  {historicosRegularizacao.map((historicoRegularizacao, historicoRegularizacaoIndex) => (
                    <ItemHistoricoRegularizacao descricaoBaixas={descricaoBaixas} historico={historicoRegularizacao} key={historicoRegularizacaoIndex} />
                  ))}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={_io.rowsPerPageOptions}
                component="div"
                count={totalItens}
                rowsPerPage={_io.linhasPorPagina}
                page={_io.pagina}
                onPageChange={_io.onMudancaPagina}
                onRowsPerPageChange={_io.onMudancaLinhasPorPagina}
                labelRowsPerPage="Itens por página"
                ActionsComponent={ComponentePaginacao}
              />
            </TableContainer>
          </ContainerTabela>
        </>
      )}
    </>
  )
}
