export type ResponsePaginado<T> = {
  itens: T[]
  pageNumber: number
  pageSize: number
  totalItens: number
  totalPages: number
}

export type Processamento = {
  dataExpiracao?: string
  status: StatusProcessamento
}

export enum StatusProcessamento {
  NOVO = 0,
  PROCESSANDO = 1,
  CONCLUIDO = 2
}

export type ParceiroResumo = {
  parceiroId: number
  parceiroNome: string
  logoParceiroUrl: string
}

export type BaseClassificacao = {
  name: string
  value: number
}
