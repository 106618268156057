import { Grid, Typography } from '@material-ui/core'
import EditarIcone from '../../assets/icons/edit.svg'
import { IOProps } from 'react-compose-io'
import IconeButton from '../IconeButton/IconeButton'
import { Tipografia } from '../Tipografia'
import { customTheme } from '../../styles/customTheme'
import { formateDate, peridiocidadeBaseOperador, validarTexto } from '../../utils/stringUtils'
import { InformacaoParceiroIO } from './InformacaoParceiro.IO'

export type InformacaoParceiroProps = {}

export function InformacaoParceiroView({ _io }: IOProps<InformacaoParceiroIO, InformacaoParceiroProps>) {
  const { Container } = _io.styles

  return (
    <Container>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography variant="h6">{`${_io.parceiro?.nomeFantasia || '-'}`}</Typography>
        </Grid>
        <Grid item>
          <IconeButton color="inherit" descricao="Editar" icone={<img src={EditarIcone} />} acao={_io.editarInformacoesParceiro} />
        </Grid>
      </Grid>
      <Grid container direction="row" spacing={4}>
        <Grid item xs={6} sm={3}>
          <Grid item xs={12} sm={12}>
            <Tipografia colorText={customTheme.colors.grey03} variant="subtitle1">
              Código do parceiro
            </Tipografia>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Tipografia colorText={customTheme.colors.grey04} variant="subtitle2">
              {_io.parceiro?.parceiroId || '-'}
            </Tipografia>
          </Grid>
        </Grid>

        <Grid item xs={6} sm={3}>
          <Grid item xs={12} sm={12}>
            <Tipografia colorText={customTheme.colors.grey03} variant="subtitle1">
              Razão Social
            </Tipografia>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Tipografia colorText={customTheme.colors.grey04} variant="subtitle2">
              {validarTexto(_io.parceiro?.razaoSocial || _io.parceiro?.nomeFantasia, '-')}
            </Tipografia>
          </Grid>
        </Grid>
        <Grid item xs={6} sm={3} md={6}>
          <Grid item xs={12} sm={12}>
            <Tipografia colorText={customTheme.colors.grey03} variant="subtitle1">
              Transportadora exclusiva
            </Tipografia>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Tipografia colorText={customTheme.colors.grey04} variant="subtitle2">
              {_io.parceiro?.transportadoras.map((transportadora) => transportadora.nome).join(', ') || '-'}
            </Tipografia>
          </Grid>
        </Grid>
      </Grid>
      <Grid container direction="row" spacing={5}>
        <Grid item xs={6} sm={3}>
          <Grid item xs={12} sm={12}>
            <Tipografia colorText={customTheme.colors.grey03} variant="subtitle1">
              Periodicidade
            </Tipografia>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Tipografia colorText={customTheme.colors.grey04} variant="subtitle2">
              {(_io.parceiro?.periodicidade || '-') + ' ' + peridiocidadeBaseOperador(_io.parceiro?.periodicidade, _io.parceiro?.tipoPeriodicidadeId)}
            </Tipografia>
          </Grid>
        </Grid>

        <Grid item xs={6} sm={3}>
          <Grid item xs={12} sm={12}>
            <Tipografia colorText={customTheme.colors.grey03} variant="subtitle1">
              Último repasse
            </Tipografia>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Tipografia colorText={customTheme.colors.grey04} variant="subtitle2">
              {formateDate(_io.parceiro?.ultimoRepasse) ?? 'Sem previsão do próximo repasse!'}
            </Tipografia>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  )
}
