import Cookies from 'js-cookie'
import React from 'react'
import { Redirect, RouteProps, Route } from 'react-router-dom'

interface IRouteProps extends RouteProps {
  isAuth: boolean
}

const ProtectedRoute: React.FC<IRouteProps> = ({ isAuth, ...rest }) => {
  const user = Cookies.get('username')

  if (!user && isAuth) {
    return <Redirect to="/login" />
  }

  return <Route {...rest} />
}

export default ProtectedRoute
