import { Typography } from '@material-ui/core'
import styled from 'styled-components'
import { customTheme } from '../../../../styles/customTheme'

export const tabelaRegularizacaoEfetivacaoStyles = {
  Container: styled.div`
    margin-top: 33px;
  `,
  TituloTabela: styled(Typography).attrs({
    variant: 'subtitle1'
  })`
    font-size: ${customTheme.fontSize[18]};
    font-style: normal;
    font-weight: 600;
  `,
  ContainerTabela: styled.div`
    background: ${customTheme.colors.white05};
    padding: 12px;
    align-self: stretch;
    border-radius: 8px;
    margin-top: 22px;
  `,
  TituloValorTotal: styled(Typography).attrs({
    variant: 'subtitle1'
  })`
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    display: flex;
    align-items: center;
    letter-spacing: 0.4px;
    font-size: ${customTheme.fontSize[14]};
    color: ${customTheme.colors.black};
  `,
  TextoValorTotal: styled(Typography).attrs({
    variant: 'subtitle2'
  })`
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    display: flex;
    align-items: center;
    letter-spacing: 0.4px;
    color: ${customTheme.colors.black};
  `,
  ContainerValorTotal: styled.div`
    background: ${customTheme.colors.blue01};
    border-radius: 4px;
    display: flex;
    width: 290px;
    height: 32px;
    padding: 6px;
    align-items: center;
    justify-content: space-between;
    margin-top: 8px;
  `,
  ContainerAlinhamento: styled.div`
    display: flex;
    align-items: end;
    flex-direction: column;
  `
}
