import { createContext, useContext } from 'react'
import useAuth from '../hooks/useAuth'
import { useCabecalhoPlanilha } from '../hooks/useCabecalhoPlanilha'
import { useSelectedErroProscessamento } from '../hooks/useSelectedErroProscessamento'
import { usePendenciasOpsTitulos } from '../hooks/usePendenciasOpsTitulos'
import { ResponseRegularizacaoPendente } from '../types/regularizacaoOPsTitulosTypes'
import useComissaoPedido from '../hooks/useComissaoPedido'

type AuthContextProviderProps = {
  children: React.ReactNode
}

type ConciliacaoContextType = {
  autenticado: boolean
  handleLogin: (token: string) => void

  selectedsErroProcessamento: number[]
  setSelectedsErroProcessamento: () => void

  getHeadersPlanilha: (header: []) => void
  headersData: string[]

  pendenciasOpsTitulos: ResponseRegularizacaoPendente | null
  SalvarPendenciasOpsTitulos: (cicloStatus: ResponseRegularizacaoPendente) => void
  AtualizarPendenciasOpsTitulos: (pedidoMkt: string) => void
}

const ConciliacaoContext = createContext<ConciliacaoContextType | any>(undefined)

function ConciliacaoProvider({ children }: AuthContextProviderProps) {
  const { autenticado, handleLogin } = useAuth()
  const { selectedsErroProcessamento, setSelectedsErroProcessamento } = useSelectedErroProscessamento()
  const { getHeadersPlanilha, headersData } = useCabecalhoPlanilha()
  const { pendenciasOpsTitulos, SalvarPendenciasOpsTitulos, AtualizarPendenciasOpsTitulos } = usePendenciasOpsTitulos()
  const {
    checkListSelecionados,
    handleClickCheckList,
    handleCheckClickListExterior,
    handleCleanAllCheckList,
    isCheckListSelecionado,
    isCheckListExteriorSelecionado
  } = useComissaoPedido()

  return (
    <ConciliacaoContext.Provider
      value={{
        autenticado,
        handleLogin,
        selectedsErroProcessamento,
        setSelectedsErroProcessamento,
        getHeadersPlanilha,
        headersData,
        pendenciasOpsTitulos,
        SalvarPendenciasOpsTitulos,
        AtualizarPendenciasOpsTitulos,
        checkListSelecionados,
        handleClickCheckList,
        handleCheckClickListExterior,
        handleCleanAllCheckList,
        isCheckListSelecionado,
        isCheckListExteriorSelecionado
      }}
    >
      {children}
    </ConciliacaoContext.Provider>
  )
}

const useConciliacaoContext = () => {
  const context = useContext(ConciliacaoContext)
  if (context === undefined) {
    throw new Error('useConciliacaoContext must be used within a ConciliacaoContextProvider')
  }
  return context
}

export { ConciliacaoProvider, useConciliacaoContext }
