import { IOProps } from 'react-compose-io'
import { GraficoLinhasIO } from './GraficoLinhas.IO'
import Chart from 'react-apexcharts'
import { Tipografia } from './../Tipografia/index'

export type GraficoLinhasProps = {
  tituloGrafico: string
  dadosLinhas: { name: string; data: number[] }[]
  descricaoLinhas: string[][]
}

export function GraficoLinhasView({ _io, tituloGrafico }: IOProps<GraficoLinhasIO, GraficoLinhasProps>) {
  return (
    <>
      <Tipografia variant="subtitle1" fontWeight="500">
        {tituloGrafico}
      </Tipografia>
      <Chart options={_io.options} series={_io.series} type="line" width="472" />
    </>
  )
}
