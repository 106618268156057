import { linhaTabelaClicavelNovaGuia } from './../../utils/tabelaUtils'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import msgConstants from '../../constants/msgConstants'
import parceiroManager from '../../services/api/parceiro/parceiroManager'
import RegularizacaoOPsTitulosManager from '../../services/api/regularizacaoOpsTitulos/regularizacaoOPsTitulosManager'
import { loadingReset, loadingUpdate } from '../../store/actions/loadingAction'
import { Parceiro } from '../../types/parceiroApiTypes'
import { RegularizacaoPendenteForm, ResponseRegularizacaoPendente, valorPadraoRegularizacaoPendencia } from '../../types/regularizacaoOPsTitulosTypes'
import { Paginacao } from '../../types/tabelaTypes'
import { pegarParametrosQuery } from '../../utils/stringUtils'

import { regularizacaoCiclosInformacoesStyles } from './RegularizacaoCiclosInformacoes.styles'
import { PAGES } from '../../router/routes'
import { useConciliacaoContext } from '../../contexts/conciliacao-context'

export function regularizacaoCiclosInformacoesIO() {
  const { pendenciasOpsTitulos, SalvarPendenciasOpsTitulos } = useConciliacaoContext()
  const [pendenciaRegularizacao, setPendenciaRegularizacao] = useState<ResponseRegularizacaoPendente>(pendenciasOpsTitulos || valorPadraoRegularizacaoPendencia)
  const [parceirosAtivos, setParceirosAtivos] = useState<Parceiro[]>([])
  const [formularioPreenchido, setFormularioPreenchido] = useState<boolean>(true)
  const query = pegarParametrosQuery()
  const rotas = useHistory()
  const dispatch = useDispatch()
  const cicloIdQuery = query.get('cicloId')
  const parceiroIdQuery = query.get('parceiroId')

  useEffect(() => {
    async function fetchData() {
      const response: Parceiro[] = await parceiroManager.buscarDadosParceiros(true)
      setParceirosAtivos(response)
      dispatch(loadingReset())
    }

    dispatch(loadingUpdate(true))
    fetchData()
  }, [dispatch])

  const { handleSubmit, control, setValue, watch } = useForm<RegularizacaoPendenteForm>({
    defaultValues: {
      pedidoPlanilhaId: '',
      clienteId: '',
      cicloId: '',
      parceiroId: ''
    }
  })

  const pedidoHabilitado = watch('pedidoPlanilhaId')
  const clienteHabilitado = watch('clienteId')
  const cicloHabilitado = watch('cicloId')
  const parceiroHabilitado = watch('parceiroId')

  useEffect(() => {
    if (pedidoHabilitado || clienteHabilitado || cicloHabilitado || parceiroHabilitado) {
      setFormularioPreenchido(false)
    } else {
      setFormularioPreenchido(true)
    }
  }, [pedidoHabilitado, clienteHabilitado, cicloHabilitado, parceiroHabilitado])

  const limparFormularioPesquisa = () => {
    rotas.push(PAGES.informacaoId(null, null, null, null))
    setValue('cicloId', '')
    setValue('clienteId', '')
    setValue('parceiroId', '')
    setValue('pedidoPlanilhaId', '')
    setPendenciaRegularizacao(valorPadraoRegularizacaoPendencia)
  }

  useEffect(() => {
    if (cicloIdQuery && cicloIdQuery !== '0') {
      setValue('cicloId', cicloIdQuery)
    } else if (pendenciasOpsTitulos != null) {
      setValue('cicloId', pendenciasOpsTitulos.itens[0].ciclo)
    }
  }, [cicloIdQuery])

  useEffect(() => {
    if (parceiroIdQuery && parceiroIdQuery !== '0') {
      setValue('parceiroId', parceiroIdQuery)
    }
  }, [parceiroIdQuery, setValue])

  async function aplicarFiltro(paginacao: Paginacao) {
    dispatch(loadingUpdate(true))

    RegularizacaoOPsTitulosManager.buscarRegularizacoesPendentes({
      pedidoPlanilhaId: pedidoHabilitado,
      clienteId: clienteHabilitado,
      parceiroId: parceiroHabilitado,
      cicloId: cicloHabilitado,
      pageNumber: paginacao.pageNumber,
      pageSize: paginacao.pageSize,
      orderBy: paginacao.orderBy,
      orderField: paginacao.orderField
    })
      .then((response: ResponseRegularizacaoPendente) => {
        if (response.itens.length < 1) {
          toast.warning(msgConstants.REGULARIZACAO.semInformacoesConsulta)
        }
        setPendenciaRegularizacao(response)
        SalvarPendenciasOpsTitulos(response)
      })
      .catch(() => {
        toast.error(msgConstants.REGULARIZACAO.erroAoBuscarPendencias)
      })
      .finally(() => {
        dispatch(loadingReset())
      })
  }

  const buscarValorPesquisado = (filtro?: RegularizacaoPendenteForm) => {
    aplicarFiltro({ pageNumber: 1, pageSize: 25 })
    rotas.push(PAGES.informacaoId(filtro?.cicloId, filtro?.parceiroId, filtro?.pedidoPlanilhaId, filtro?.clienteId))
  }

  const handleDirecionarRegularizacao = (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>, pedidoId: string) => {
    const clienteId = clienteHabilitado ? clienteHabilitado : '0'

    linhaTabelaClicavelNovaGuia(event, PAGES.regularizacaoCiclosEfetivacaoId(pedidoId, clienteId), rotas)
  }

  return {
    styles: regularizacaoCiclosInformacoesStyles,
    control,
    pendenciaRegularizacao,
    limparFormularioPesquisa,
    handleSubmit,
    aplicarFiltro,
    parceirosAtivos,
    buscarValorPesquisado,
    formularioPreenchido,
    handleDirecionarRegularizacao
  }
}

export type RegularizacaoCiclosInformacoesIO = ReturnType<typeof regularizacaoCiclosInformacoesIO>
