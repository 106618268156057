import { PreviewItemAssociacao, RegraAssociacao, RegraAssociacaoForm } from '../../../types/associacaoApiTypes'
import { Acao, Condicao } from '../../../types/regraApiType'
import AssociacaoService from './associacaoService'

const buscarCondicoes = async (): Promise<Condicao[]> => AssociacaoService.buscarCondicoes()

const buscarAcoes = async (): Promise<Acao[]> => AssociacaoService.buscarAcoes()

const cadastrarRegra = async (body: RegraAssociacaoForm, parceiroId?: string | number): Promise<string> => AssociacaoService.cadastrarRegra(body, parceiroId)

const previewRegra = async (body: RegraAssociacaoForm, parceiroId?: string | number): Promise<PreviewItemAssociacao> =>
  AssociacaoService.previewRegra(body, parceiroId)

const editarRegra = async (body: RegraAssociacaoForm, parceiroId?: string | number, regraId?: number | string): Promise<string> =>
  AssociacaoService.editarRegra(body, parceiroId, regraId)

const aplicarAssociacao = async (parceiroId: number, arquivoId: string): Promise<string> => AssociacaoService.aplicarAssociacao(parceiroId, arquivoId)

const buscarRegras = async (parceiroId?: string | number): Promise<RegraAssociacao[]> => AssociacaoService.buscarRegras(parceiroId)

const buscarRegra = async (parceiroId: string | number, regraId: string | number): Promise<RegraAssociacao> =>
  AssociacaoService.buscarRegra(parceiroId, regraId)

const enviarArquivo = async (body: string[]): Promise<string> => AssociacaoService.enviarArquivo(body)

const aplicarAssociacaoCiclo = async (cicloId: string): Promise<string> => AssociacaoService.aplicarAssociacaoCiclo(cicloId)

export default {
  buscarCondicoes,
  buscarAcoes,
  cadastrarRegra,
  previewRegra,
  editarRegra,
  aplicarAssociacao,
  buscarRegras,
  enviarArquivo,
  aplicarAssociacaoCiclo,
  buscarRegra
}
