import { CircularProgress, Grid, TextField } from '@material-ui/core'
import { IOProps } from 'react-compose-io'
import { Controller } from 'react-hook-form'
import { ModalRegularizarPerdasDebitoIO } from './ModalRegularizarPerdasDebito.IO'
import { Botao } from '../../../../components/Botao'
import { Tipografia } from '../../../../components/Tipografia'
import { CustomDialog } from '../../../../components/CustomDialog/CustomDialog'

export type ModalRegularizarPerdasDebitoProps = {
  isShow: boolean
  isClosed: () => void
}

export default function ModalRegularizarPerdasDebitoView({ _io, isShow }: IOProps<ModalRegularizarPerdasDebitoIO, ModalRegularizarPerdasDebitoProps>) {
  return (
    <CustomDialog
      open={isShow}
      onClose={_io.fecharModal}
      id="modal-regularizar-debitos"
      titulo="Regularização de débitos perda"
      habilitarAcoes
      fecharHabilitado
      fecharModal={_io.fecharModal}
      actions={
        <Grid container spacing={2} alignContent="center" justifyContent="center">
          <Grid item>
            <Botao textoBotao="Cancelar" estilo="text" onClick={_io.fecharModal} cor="primaria" />
          </Grid>
          <Grid item>
            <Botao
              textoBotao="Confirmar"
              onClick={_io.handleSubmit(_io.handleEfetivarDebitosPerda)}
              estilo="rounded"
              cor="primaria"
              disabled={_io.isSubmitting || !_io.isDirty}
              icone={_io.isSubmitting ? <CircularProgress color="inherit" size={12} /> : undefined}
            />
          </Grid>
        </Grid>
      }
    >
      <Tipografia>Selecione o mês referente a data de lançamentos dos débitos que deseja lançar como perda</Tipografia>
      <br />
      <form>
        <Controller
          render={({ field }) => (
            <TextField
              {..._io.register('mesLancamento', { required: true })}
              {...field}
              label="Data de lançamento"
              variant="outlined"
              type="month"
              InputLabelProps={{
                shrink: true
              }}
            />
          )}
          name="mesLancamento"
          control={_io.control}
        />
      </form>
    </CustomDialog>
  )
}
