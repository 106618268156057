import Grid from '@material-ui/core/Grid'
import { IOProps } from 'react-compose-io'
import { Tipografia } from '../../../../components/Tipografia'
import { customTheme } from '../../../../styles/customTheme'
import { formateCurrency } from '../../../../utils/stringUtils'
import { ResumoItemIO } from './ResumoItem.IO'

export type ResumoItemProps = {
  label: string
  value: number
}

export function ResumoItemView({ _io, label, value }: IOProps<ResumoItemIO, ResumoItemProps>) {
  return (
    <>
      <Grid container direction="column" item sm={12} lg={4}>
        <Tipografia fontSize={customTheme.fontSize[14]} colorText={customTheme.colors.grey11}>
          {label}
        </Tipografia>
        <Tipografia fontSize={customTheme.fontSize[16]} fontWeight="500" colorText={_io.verifyStyle(value)}>
          {formateCurrency(value)}
        </Tipografia>
      </Grid>
    </>
  )
}
