import { Table, TableBody, TablePagination } from '@material-ui/core'
import { IOProps } from 'react-compose-io'
import { AntTab } from '../../components/AntTab/AntTab.style'
import { AntTabs } from '../../components/AntTabs/AntTabs.styles'
import { ContainerSombra } from '../../components/ContainerSombra'
import { EnhancedTableHead } from '../../components/EnhancedTableHead'
import { ComponentePaginacao } from '../../components/Tabela/ComponentePaginacao/index'
import { cabecalhoParceiros } from '../../types/tabelaTypes'
import { getComparator, ordenarCabecalho } from '../../utils/tabelaUtils'
import { ItemParceiro } from './components/ItemParceiro'
import { ParceirosIO } from './Parceiros.IO'

export type ParceirosProps = {}

export default function ParceirosView({ _io }: IOProps<ParceirosIO, ParceirosProps>) {
  const { TableContainer, Titulo } = _io.styles

  return (
    <>
      <Titulo variant="h5">Parceiros</Titulo>
      <ContainerSombra>
        <AntTabs value={_io.abaId} onChange={_io.alterarAba} aria-label="abas filtro de parceiros">
          <AntTab label="Ativos" />
          <AntTab label="Novos" />
          <AntTab label="Todos os parceiros" />
        </AntTabs>
        <TableContainer>
          <TableContainer>
            <Table aria-labelledby="tableTitle" size="medium" aria-label="enhanced table">
              <EnhancedTableHead alignCenter={true} headCells={cabecalhoParceiros} order={_io.ordem} orderBy={_io.ordemBy} onRequestSort={_io.onRequestSort} />
              <TableBody>
                {ordenarCabecalho(_io.parceiroList, getComparator(_io.ordem, _io.ordemBy))
                  .slice(_io.pagina * _io.linhasPorPagina, _io.pagina * _io.linhasPorPagina + _io.linhasPorPagina)
                  .map((parceiro) => {
                    return <ItemParceiro parceiro={parceiro} key={parceiro.parceiroId} />
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={_io.opcoesLinhasPorPaginas}
            component="div"
            count={_io.parceiroList.length}
            rowsPerPage={_io.linhasPorPagina}
            page={_io.pagina}
            onPageChange={_io.onMudancaPagina}
            onRowsPerPageChange={_io.onMudancaLinhasPorPagina}
            labelRowsPerPage="Parceiros por página"
            ActionsComponent={ComponentePaginacao}
          />
        </TableContainer>
      </ContainerSombra>
    </>
  )
}
