import { Grid } from '@material-ui/core'
import { IOProps } from 'react-compose-io'
import { AntTab } from '../../components/AntTab/AntTab.style'
import { AntTabs } from '../../components/AntTabs/AntTabs.styles'
import TabPanel from '../../components/TabPainel/TabPanel'
import { TabAssociacao } from './components/TabAssociacao'
import { TabGrupoRegra } from './components/TabGrupoRegra'
import { TabTipoLancamentoParceiro } from './components/TabTipoLancamentoParceiro/index'
import { TabTransformacao } from './components/TabTransformacao'
import { ParametrosRepasseIO } from './ParametrosRepasse.IO'
import { InformacaoParceiro } from '../../components/InformacaoParceiro'
import FolderOpenIcon from '@material-ui/icons/FolderOpen'
import { TooltipComponent } from '../../components/Tooltip/Tooltip'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import { ContainerSombra } from '../../components/ContainerSombra'

export type ParametrosRepasseProps = {}

export default function ParametrosRepasseView({ _io }: IOProps<ParametrosRepasseIO, ParametrosRepasseProps>) {
  const { ContainerCircle, ContainerCabecalho, Titulo } = _io.styles

  return (
    <>
      <InformacaoParceiro />

      <ContainerCabecalho>
        <Grid container direction="row" alignItems="center" spacing={2}>
          <Grid item>
            <ContainerCircle>
              <FolderOpenIcon color="primary" fontSize="large" />
            </ContainerCircle>
          </Grid>
          <Grid item>
            <Titulo>Parâmetros - Repasse do parceiro</Titulo>
          </Grid>
          <Grid item>
            <TooltipComponent
              texto="Os parâmetros a seguir definem como o sistema processará o repasse desse parceiro."
              Icone={HelpOutlineIcon}
              color={'action'}
            />
          </Grid>
        </Grid>
      </ContainerCabecalho>

      <ContainerSombra>
        <AntTabs value={_io.aba} onChange={_io.handleChange} aria-label="abas filtro de parceiros">
          <AntTab label="Tipos de lançamentos" />
          <AntTab label="Transformar" />
          <AntTab label="Associar" />
          <AntTab label="Grupos de Regras" />
        </AntTabs>
        <TabPanel value={_io.aba} index={0}>
          <TabTipoLancamentoParceiro />
        </TabPanel>
        <TabPanel value={_io.aba} index={1}>
          <TabTransformacao />
        </TabPanel>
        <TabPanel value={_io.aba} index={2}>
          <TabAssociacao />
        </TabPanel>
        <TabPanel value={_io.aba} index={3}>
          <TabGrupoRegra />
        </TabPanel>
      </ContainerSombra>
    </>
  )
}
