import { useState } from 'react'
import { ORDEM_CRESCENTE } from '../../../../constants/utilConstants'
import { Order } from '../../../../types/tabelaTypes'
import { handleRequestSort, tratarMudancaLinhaPorPagina, tratarMudancaPagina } from '../../../../utils/tabelaUtils'
import { AbaTabelaProps } from './AbaTabela.View'

export function abaTabelaIO({ orderId }: AbaTabelaProps) {
  const [order, setOrder] = useState<Order>(ORDEM_CRESCENTE)
  const [orderBy, setOrderBy] = useState<string | number | symbol>(orderId)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(25)

  return {
    order,
    setOrder,
    orderBy,
    setOrderBy,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    onPageChange: (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
      tratarMudancaPagina(event, newPage, setPage)
    },
    onRowsPerPageChange: (event: React.ChangeEvent<HTMLInputElement>) => {
      tratarMudancaLinhaPorPagina(event, setPage, setRowsPerPage)
    },
    onRequestSort: handleRequestSort(orderBy, order, setOrder, setOrderBy),
    rowsPerPageOptions: [5, 10, 25]
  }
}

export type AbaTabelaIO = ReturnType<typeof abaTabelaIO>
