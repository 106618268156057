import api from '../../axiosInstance'
import { StatusEfacil } from '../../../types/statusEfacilTypes'

const buscarStatusEfacil = async (): Promise<StatusEfacil[]> => {
  const URL_BUSCAR_STATUS_TRACKING = `/StatusEfacil`
  const response = await api.conectarGet<StatusEfacil[]>(URL_BUSCAR_STATUS_TRACKING)
  return response.data
}

export default {
  buscarStatusEfacil
}
