import { Box, Grid, Collapse, CircularProgress, IconButton, InputAdornment } from '@material-ui/core'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { IOProps } from 'react-compose-io'
import IconeButton from '../../../../components/IconeButton/IconeButton'
import { customTheme } from '../../../../styles/customTheme'
import { Ciclos, ParceiroCiclo } from '../../../../types/ciclosApiType'
import { formateDate } from '../../../../utils/stringUtils'
import { CardExpansivelIO } from './CardExpansivel.IO'
import { cabecalhoTabelaCiclosParceiro } from '../../../../types/tabelaTypes'
import { Tabela } from '../../../../components/Tabela'
import { LinhaTabelaCiclo } from '../LinhaTabelaCiclo'
import { ModalNovoCiclo } from '../ModalNovoCiclo'
import { Controller } from 'react-hook-form'
import SearchIcon from '@material-ui/icons/Search'
import { Tipografia } from '../../../../components/Tipografia'
import { Botao } from '../../../../components/Botao'

export type CardExpansivelProps = {
  parceiroComCiclos: ParceiroCiclo
  pagina: number
}

function linhaTabela(props: { item: Ciclos }) {
  const { item } = props

  return <LinhaTabelaCiclo item={item} />
}

export function CardExpansivelView({ _io, parceiroComCiclos }: IOProps<CardExpansivelIO, CardExpansivelProps>) {
  const { Paper, TagStyled, DividerVertical, TabelaContainer, InputNumerico, LimitedBackdrop } = _io.styles

  return (
    <>
      <Paper>
        <Grid container alignItems="center">
          <Grid item md={3}>
            <Box>
              <Tipografia variant="h6">{`${parceiroComCiclos.parceiroId} | ${parceiroComCiclos.nome}`}</Tipografia>
              <Tipografia variant="subtitle1">Previsão do próximo repasse</Tipografia>
              <Tipografia variant="body2" fontWeight="500" colorText={customTheme.colors.grey23}>
                {formateDate(parceiroComCiclos?.previsaoRepasse)}
              </Tipografia>
            </Box>
          </Grid>
          <Grid item md={1}>
            <DividerVertical />
          </Grid>
          <Grid item container md={3} alignItems="center" justifyContent="flex-start" direction="row" spacing={2}>
            {parceiroComCiclos.ciclosComErro > 0 && (
              <TagStyled color={customTheme.colors.red06} bgcolor={customTheme.colors.red00}>
                <p>{parceiroComCiclos.ciclosComErro} ciclos pendentes</p>
              </TagStyled>
            )}
            {parceiroComCiclos.ciclosComWarning > 0 && (
              <TagStyled color={customTheme.colors.yellow01} bgcolor={customTheme.colors.white02}>
                <p>{parceiroComCiclos.ciclosComWarning} ciclos em processamento</p>
              </TagStyled>
            )}
          </Grid>
          <Grid item md={2}>
            {_io.isCardExpandido && (
              <Controller
                render={({ field }) => (
                  <InputNumerico
                    {...field}
                    size="small"
                    label="Buscar Ciclo"
                    variant="outlined"
                    fullWidth
                    type="number"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton edge="end">
                            <SearchIcon onClick={_io.handleSubmit(_io.buscarCicloIdParceiro)} />
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                    onKeyUp={_io.buscarCicloParceiroEnter}
                  />
                )}
                name="cicloId"
                control={_io.control}
              />
            )}
          </Grid>
          <Grid container item md={3} alignItems="center" justifyContent="flex-end" direction="row" spacing={2}>
            <Grid item>
              <Botao textoBotao="Iniciar novo ciclo" estilo="contained" cor="primaria" onClick={() => _io.setModalAberto(true)} />
            </Grid>
            <Grid item>
              <IconeButton color="inherit" descricao="expandir" icone={_io.isCardExpandido ? <ExpandLessIcon /> : <ExpandMoreIcon />} acao={_io.expandirCard} />
            </Grid>
          </Grid>
        </Grid>
        <Collapse in={_io.isCardExpandido}>
          <TabelaContainer>
            <LimitedBackdrop open={_io.carregando}>
              <CircularProgress color="primary" />
            </LimitedBackdrop>
            <Tabela
              cabecalho={cabecalhoTabelaCiclosParceiro}
              itensLista={_io.ciclos}
              LinhaTabela={linhaTabela}
              orderCampoPadrao={'CicloId'}
              rowsPerPageOptions={[5, 10, 25]}
              linhasPorPaginaPadrao={5}
              maxHeight={'none'}
              alinharTextoCentro={false}
              buscarItensTabela={_io.buscarCiclosParceiro}
              totalItens={_io.totalLinhas}
              ordenacaoPadrao={'desc'}
            />
          </TabelaContainer>
        </Collapse>
      </Paper>
      <ModalNovoCiclo isShow={_io.modalAberto} isClosed={() => _io.setModalAberto(false)} handleConfirmar={_io.iniciarCiclo} />
    </>
  )
}
