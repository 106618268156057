import { tabTransformacaoStyles } from './TabTransformacao.styles'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import TransformacaoManager from '../../../../services/api/transformacao/transformacaoManager'
import { loadingReset, loadingUpdate } from '../../../../store/actions/loadingAction'
import { RegraTransformacao } from '../../../../types/transformacaoApiTypes'
import { PAGES } from '../../../../router/routes'

export function tabTransformacaoIO() {
  const dispatch = useDispatch()
  const router = useHistory()
  const { parceiroId } = useParams<{ parceiroId: string }>()
  const [regrasList, setRegrasList] = useState<RegraTransformacao[]>([])
  const [modalAberto, setModalAberto] = useState(false)

  const buscarRegrasTransformacao = React.useCallback(async () => {
    dispatch(loadingUpdate(true))
    const response: RegraTransformacao[] = await TransformacaoManager.buscarRegras(parceiroId)
    setRegrasList(response)
    dispatch(loadingReset())
  }, [dispatch, parceiroId])

  const handleAdicionar = () => {
    router.push(PAGES.regraTransformacaoFormCadastro(parceiroId))
  }

  function fecharModal() {
    setModalAberto(false)
  }

  const handlePriorizar = () => {
    setModalAberto(true)
  }

  const getCampos = () => {
    return regrasList.map((item) => item.campo).filter((value, index, self) => self.indexOf(value) === index)
  }

  React.useEffect(() => {
    buscarRegrasTransformacao()
  }, [buscarRegrasTransformacao, dispatch, parceiroId])

  return {
    styles: tabTransformacaoStyles,
    handleAdicionar,
    regrasList,
    buscarRegrasTransformacao,
    modalAberto,
    fecharModal,
    handlePriorizar,
    getCampos,
    parceiroId
  }
}

export type TabTransformacaoIO = ReturnType<typeof tabTransformacaoIO>
