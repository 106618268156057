import { linhaTabelaClicavelNovaGuia } from './../../../../utils/tabelaUtils'
import React from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { loadingUpdate } from '../../../../store/actions/loadingAction'
import { tabelaContabilizacaoStyles } from './TabelaContabilizacao.style'
import { TabelaContabilizacaoProps } from './TabelaContabilizacao.View'
import { PAGES } from '../../../../router/routes'

export function tabelaContabilizacaoIO({ cicloId, buscarContabilizacoes }: TabelaContabilizacaoProps) {
  const dispatch = useDispatch()
  const router = useHistory()

  const handlePushRotaTabelaContabilizacao = (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>, tipoLancamento: string, sequenciaId: number) => {
    linhaTabelaClicavelNovaGuia(event, PAGES.visualizarContabilizacaoId(cicloId, tipoLancamento, sequenciaId), router)
  }

  React.useEffect(() => {
    dispatch(loadingUpdate(true))
    buscarContabilizacoes()
  }, [cicloId, dispatch])

  return {
    styles: tabelaContabilizacaoStyles,
    handlePushRotaTabelaContabilizacao
  }
}
export type TabelaContabilizacaoIO = ReturnType<typeof tabelaContabilizacaoIO>
