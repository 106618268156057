import { ResponsePaginado } from '../../../types/genericoType'
import { HistoricoRegularizacao, ValidacaoPlanilhaResponse } from '../../../types/regularizacaoTypes'
import { Paginacao } from '../../../types/tabelaTypes'
import RegularizacaoTitulosService from './regularizacaoTitulosService'

const efetivarRegularizacaoTitulos = async (arquivoId: string): Promise<boolean> => RegularizacaoTitulosService.efetivarRegularizacaoTitulos(arquivoId)

const validarPlanilhaRegularizacaoTitulos = async (arquivo: FormData): Promise<ValidacaoPlanilhaResponse> =>
  RegularizacaoTitulosService.validarPlanilhaRegularizacaoTitulos(arquivo)

const buscarHistoricoRegularizacaoTitulos = async (paginacao: Paginacao): Promise<ResponsePaginado<HistoricoRegularizacao>> =>
  RegularizacaoTitulosService.buscarHistoricoRegularizacaoTitulos(paginacao)

export default {
  buscarHistoricoRegularizacaoTitulos,
  validarPlanilhaRegularizacaoTitulos,
  efetivarRegularizacaoTitulos
}
