import Button from '@material-ui/core/Button'
import styled from 'styled-components'
import { customTheme } from '../../styles/customTheme'
import { CoresIconeProps } from './BotaoIconeSvg.View'

export const botaoIconeSvg = {
  Container: styled.a`
    :hover {
      cursor: pointer;
      background: ${(props: { coricone?: CoresIconeProps }) => getCorHoverIcone(props.coricone).hover};
    }
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 32px;
    width: 40px;
    height: 40px;
  `,
  Button: styled(Button)`
    :hover {
      background: none;
    }
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 32px;
    width: 40px;
    height: 40px;
  `,
  Icon: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 32px;
    width: 24px;
    height: 24px;
  `
}

const getCorHoverIcone = (corIcone?: CoresIconeProps) => {
  switch (corIcone) {
    case 'verde':
      return { cor: customTheme.colors.verde05, hover: customTheme.colors.verde01 }
    case 'vermelho':
      return { cor: customTheme.colors.vermelho04, hover: customTheme.colors.vermelho01 }
    default:
      return { cor: customTheme.colors.azul06, hover: customTheme.colors.azul01 }
  }
}
