import { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import msgConstants from '../../../../constants/msgConstants'
import ciclosManager from '../../../../services/api/ciclos/ciclosManager'
import { CicloTabela, CicloTabelaItem } from '../../../../types/ciclosApiType'
import { Paginacao } from '../../../../types/tabelaTypes'
import { tabelaCicloStyles } from './TabelaCiclo.style'
import { TabelaCicloProps } from './TabelaCiclo.View'

export function tabelaCicloIO({ cicloId, updateTable }: TabelaCicloProps) {
  const [linhas, setLinhas] = useState<CicloTabelaItem[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [totalLinhas, setTotalLinhas] = useState(0)
  const [valorPesquisa, setValorPesquisa] = useState('')
  const { handleSubmit, control, getValues } = useForm<{ pedidoId: string }>({
    defaultValues: {
      pedidoId: ''
    }
  })

  const buscarTabelaCiclo = useCallback(
    (paginacao: Paginacao) => {
      setLoading(true)
      ciclosManager
        .buscarTabelaCiclo(paginacao, cicloId, getValues('pedidoId'))
        .then((response: CicloTabela) => {
          response.itens.forEach((item) => (item.valor = typeof item.valor == 'string' ? parseFloat(item.valor) : item.valor))
          setLinhas(response.itens)
          setTotalLinhas(response.totalItens)
        })
        .catch(() => {
          toast.error(msgConstants.CICLO_FLUXO.erroBuscarTabelaCiclo)
        })
        .finally(() => {
          setLoading(false)
        })
    },
    [cicloId, getValues]
  )

  const pesquisarPedido = () => {
    buscarTabelaCiclo({ pageNumber: 1, pageSize: 25 })
  }

  useEffect(() => {
    buscarTabelaCiclo({ pageNumber: 1, pageSize: 25 })
  }, [buscarTabelaCiclo, updateTable])

  return {
    styles: tabelaCicloStyles,
    linhas,
    totalLinhas,
    valorPesquisa,
    pesquisarPedido,
    loading,
    setValorPesquisa,
    buscarTabelaCiclo,
    handleSubmit,
    control
  }
}

export type TabelaCicloIO = ReturnType<typeof tabelaCicloIO>
