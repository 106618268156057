import { Grid, MenuItem, Select, InputLabel, FormControl, TextField } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import { FormEventHandler } from 'react'
import { IOProps } from 'react-compose-io'
import { Control, Controller } from 'react-hook-form'
import { Parceiro } from '../../../../types/parceiroApiTypes'
import { RegularizacaoPendenteForm } from '../../../../types/regularizacaoOPsTitulosTypes'
import { ContainerPesquisaIO } from './ContainerPesquisa.IO'
import { Tipografia } from './../../../../components/Tipografia/index'
import { Botao } from './../../../../components/Botao/index'

export type ContainerPesquisaProps = {
  control: Control<RegularizacaoPendenteForm>
  limparFormularioPesquisa: () => void
  aplicarFiltro: FormEventHandler<HTMLFormElement>
  listaParceirosAtivos: Parceiro[]
  itemFormularioPreenchido: boolean
}

export function ContainerPesquisaView({
  _io,
  control,
  limparFormularioPesquisa,
  aplicarFiltro,
  listaParceirosAtivos,
  itemFormularioPreenchido
}: IOProps<ContainerPesquisaIO, ContainerPesquisaProps>) {
  const { Container, GridContainer, ContainerLinha } = _io.styles

  return (
    <Container>
      <Tipografia variant="h6">Pesquisar pedido</Tipografia>
      <form onSubmit={aplicarFiltro}>
        <GridContainer spacing={1} container alignItems="center">
          <Grid xs={12} sm={12} md={2} lg={2} item>
            <Controller
              render={({ field }) => <TextField size="small" variant="outlined" fullWidth {...field} label="N° do pedido" />}
              name="pedidoPlanilhaId"
              control={control}
            />
          </Grid>
          <Grid xs={12} sm={12} md={2} lg={2} item>
            <Controller
              render={({ field }) => <TextField size="small" variant="outlined" fullWidth {...field} label="Cliente" />}
              name="clienteId"
              control={control}
            />
          </Grid>
          <Grid xs={12} sm={12} md={2} lg={2} item>
            <Controller
              render={({ field }) => (
                <FormControl size="small" variant="outlined" fullWidth>
                  <InputLabel>Parceiro</InputLabel>
                  <Select {...field} defaultValue={'0'} label="Parceiro">
                    {listaParceirosAtivos.map((parceiroAtivo) => (
                      <MenuItem key={parceiroAtivo.parceiroId} value={parceiroAtivo.parceiroId}>
                        {parceiroAtivo.nomeFantasia}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
              name="parceiroId"
              control={control}
            />
          </Grid>
          <Grid xs={12} sm={12} md={2} lg={2} item>
            <Controller
              render={({ field }) => <TextField size="small" variant="outlined" fullWidth {...field} label="Ciclo" />}
              name="cicloId"
              control={control}
            />
          </Grid>
          <Grid xs={12} sm={12} md={1} lg={1} item></Grid>
          <Grid xs={12} sm={12} md={3} lg={3} item>
            <ContainerLinha>
              <Botao textoBotao={'Limpar'} cor="primaria" estilo="outlined" onClick={limparFormularioPesquisa} />
              <Botao
                disabled={itemFormularioPreenchido}
                textoBotao={'Pesquisar'}
                cor="primaria"
                estilo="contained"
                type="submit"
                startIcon={<SearchIcon fontSize={'small'} />}
              />
            </ContainerLinha>
          </Grid>
        </GridContainer>
      </form>
    </Container>
  )
}
