import { Table, TableBody, TableContainer, TablePagination, TableRow } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import { IOProps } from 'react-compose-io'
import DataEmpty from '../../../../components/DataEmpty/DataEmpty'
import { EnhancedTableHead } from '../../../../components/EnhancedTableHead/index'
import { ComponentePaginacao } from '../../../../components/Tabela/ComponentePaginacao/index'
import { IOpsVinculadas } from '../../../../types/ciclosApiType'
import { cabecalhoTabelaResultadoOPS } from '../../../../types/tabelaTypes'
import { formateCurrency } from '../../../../utils/stringUtils'
import { getComparator, handleSelecionarCheckboxTabela, ordenarCabecalho } from '../../../../utils/tabelaUtils'
import { TabelaOPSIO } from './TabelaOPS.IO'
import { Botao } from './../../../../components/Botao/index'

export type TabelaOPSProps = {
  data: IOpsVinculadas[]
  cicloId: string
  atualizarLista: () => void
}

function TabelaOPSView({ _io }: IOProps<TabelaOPSIO, TabelaOPSProps>) {
  const { TableCellItem } = _io.classes

  return (
    <>
      <TableContainer>
        <Table>
          {_io.loading && (
            <TableRow tabIndex={-1}>
              <TableCellItem>
                <CircularProgress />
              </TableCellItem>
            </TableRow>
          )}
          {_io.linhas.length > 0 ? (
            <>
              <EnhancedTableHead
                alignCenter={true}
                order={_io.ordem}
                onRequestSort={_io.onRequestSort}
                rowCount={_io.totalLinhas || 0}
                headCells={cabecalhoTabelaResultadoOPS}
              />
              <TableBody>
                {ordenarCabecalho(_io.linhas, getComparator(_io.ordem, _io.ordemBy))
                  .slice(_io.pagina * _io.linhasPorPagina, _io.pagina * _io.linhasPorPagina + _io.linhasPorPagina)
                  .map((row, index) => {
                    return (
                      <>
                        <TableRow tabIndex={-1} key={index} onClick={() => handleSelecionarCheckboxTabela(row.ordemPagamentoId, _io.setSelected, _io.selected)}>
                          <TableCellItem>{row.ordemPagamentoId}</TableCellItem>
                          <TableCellItem>{formateCurrency(row.valorPagamento)}</TableCellItem>
                          <TableCellItem>{new Date(row.dataEmissao).toLocaleDateString()}</TableCellItem>
                          <TableCellItem>{row.emitente}</TableCellItem>
                          <TableCellItem>{row.bancoId}</TableCellItem>
                          <TableCellItem>{row.descricao}</TableCellItem>
                          <TableCellItem>
                            <Botao onClick={() => _io.excluirOPS(row)} textoBotao="Excluir" cor="primaria" estilo="text" width="91px" />
                          </TableCellItem>
                        </TableRow>
                      </>
                    )
                  })}
              </TableBody>
            </>
          ) : (
            <DataEmpty descricao="Nenhum Dado!" />
          )}
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={_io.linhas.length}
          rowsPerPage={_io.linhasPorPagina}
          page={_io.pagina}
          onPageChange={_io.onMudancaPagina}
          onRowsPerPageChange={_io.onMudancaLinhasPorPagina}
          ActionsComponent={ComponentePaginacao}
        />
      </TableContainer>
    </>
  )
}
export default TabelaOPSView
