import { Grid } from '@material-ui/core'
import styled from 'styled-components'
import { ContainerSombra } from '../../../../components/ContainerSombra'

export const containerPesquisaStyles = {
  Container: styled(ContainerSombra)`
    padding: 16px;
  `,
  GridContainer: styled(Grid)`
    margin-top: 12px;
  `,
  ContainerLinha: styled.div`
    display: flex;
    align-items: end;
    flex-direction: row;
    justify-content: flex-end;
    gap: 8px;
  `
}
