import { CabecalhoInadimplenciaIO } from './CabecalhoInadimplencia.IO'
import { FormControl, Grid, MenuItem, Select } from '@material-ui/core'
import { IOProps } from 'react-compose-io'
import CloseIcon from '@material-ui/icons/Close'
import RefreshIcon from '@material-ui/icons/Refresh'
import FilterIcon from '../../../../assets/icons/filter-icon-branco.svg'
import { Dispatch, SetStateAction } from 'react'
import { Tipografia } from './../../../../components/Tipografia/index'
import { corParceiroId, customTheme } from '../../../../styles/customTheme'
import { ModalFiltroParceiro } from '../../../../components/ModalFiltroParceiro'
import { Controller } from 'react-hook-form'
import { Botao } from './../../../../components/Botao/index'
import { formateDate, formateHoras } from '../../../../utils/stringUtils'

export type CabecalhoInadimplenciaProps = {
  parceiroSelecionado: number
  desabilitarBotoes: boolean
  handleAtualizar: () => void
  handleClickParceiro: (parceiroId: number) => void
  setMesesSelecionado: Dispatch<SetStateAction<3 | 6>>
  dataUltimaAtualizacao: string
}

export function CabecalhoInadimplenciaView({
  _io,
  parceiroSelecionado,
  desabilitarBotoes,
  handleAtualizar,
  handleClickParceiro,
  dataUltimaAtualizacao
}: IOProps<CabecalhoInadimplenciaIO, CabecalhoInadimplenciaProps>) {
  const { Avatar, Chip, ContainerFormTitulo, ContainerButtons, Form } = _io.styles

  return (
    <>
      <Grid container spacing={2} justifyContent="space-between" alignItems="center" direction="row">
        <Grid item>
          <ContainerFormTitulo>
            <Tipografia variant="h6">Controle de Inadimplência</Tipografia>
            {parceiroSelecionado !== 0 && _io.parceiros.length > 0 ? (
              <Chip
                icon={<Avatar src={_io.parceiros.filter((x) => x.parceiroId === parceiroSelecionado)[0].logoUrl} />}
                label={_io.parceiros.filter((x) => x.parceiroId === parceiroSelecionado)[0].razaoSocial}
                onDelete={() => _io.handleFecharChip()}
                deleteIcon={<CloseIcon />}
                variant="outlined"
                cor={corParceiroId.get(parceiroSelecionado)}
              />
            ) : (
              <Tipografia variant="h6">&nbsp;Total</Tipografia>
            )}
            <Form>
              <Controller
                render={({ field }) => (
                  <FormControl variant="standard" size="small" fullWidth>
                    <Select {...field} label="meses">
                      <MenuItem value={3}>Últimos 3 meses</MenuItem>
                      <MenuItem value={6}>Últimos 6 meses</MenuItem>
                    </Select>
                  </FormControl>
                )}
                name="meses"
                control={_io.control}
              />
            </Form>
          </ContainerFormTitulo>
        </Grid>
        <Grid item>
          <ContainerButtons>
            <Botao
              disabled={desabilitarBotoes}
              startIcon={<RefreshIcon />}
              textoBotao={'Atualizar dados'}
              cor="primaria"
              estilo="outlined"
              type="submit"
              width="200px"
              onClick={handleAtualizar}
            />
            <Botao
              disabled={desabilitarBotoes}
              textoBotao={'Filtrar por parceiro'}
              cor="primaria"
              estilo="contained"
              type="submit"
              startIcon={<img src={FilterIcon} />}
              width="230px"
              onClick={() => _io.setAbrirModalFiltroParceiros(true)}
            />
          </ContainerButtons>
          <Tipografia variant="body2" colorText={customTheme.colors.cinza07}>
            {`Última atualização: ${formateDate(dataUltimaAtualizacao)} às ${formateHoras(dataUltimaAtualizacao)}`}
          </Tipografia>
        </Grid>
      </Grid>
      <ModalFiltroParceiro
        descricaoModal={`O controle de inadimplência pode ser feito por parceiro.`}
        isShow={_io.abrirModalFiltroParceiros}
        isClosed={() => _io.setAbrirModalFiltroParceiros(false)}
        handleClickParceiro={handleClickParceiro}
        parceiros={_io.parceiros}
      />
    </>
  )
}
