import { useState } from 'react'
import { ROUTES } from '../../router/routes'
import { breadCrumbsStyles } from './CustomBreadcrumbs.style'
import { Dashboard } from '../../pages'

export function customBreadcrumbsIO({ match }: any) {
  const crumbs = () => {
    const rotasMapeadas = ROUTES.filter(({ path }) => match.path.includes(path)).map(({ path, ...rest }) => ({
      path: Object.keys(match.params).length
        ? Object.keys(match.params).reduce((caminho, param) => caminho.replace(`:${param}`, match.params[param]), path)
        : path,
      ...rest
    }))

    if (rotasMapeadas[0].name !== 'Início') {
      rotasMapeadas.splice(0, 0, { path: '/inicio', name: 'Início', Component: Dashboard })
    }

    return rotasMapeadas
  }

  const [rotas] = useState(crumbs)

  return {
    styles: breadCrumbsStyles,
    rotas
  }
}
export type CustomBreadcrumbsIO = ReturnType<typeof customBreadcrumbsIO>
