import { Grid } from '@material-ui/core'
import { IOProps } from 'react-compose-io'
import { RepasseBoxIO } from './RepasseBox.IO'
import CifraoIcone from '../../../../assets/icons/cifrao.svg'
import CuboIcone from '../../../../assets/icons/cubo.svg'
import { BoxValor } from '../../../../components/BoxValor'
import { GraficoBarras } from '../../../../components/GraficoBarras'
import { formateCurrency } from '../../../../utils/stringUtils'
import { ValoresMensais } from '../../../../types/debitosTypes'
import { CabecalhoExportarRelatorio } from '../../../../components/CabecalhoExportarRelatorio'
import { corBarra } from '../../../../utils/debitoGraficos'
import { TIPO_VALOR_GRAFICO, VALOR_ESCALA_GRAFICO } from '../../../../constants/utilConstants'

export type RepasseBoxProps = {
  quantidadeRepasse: number
  valorRepasse: number
  valoresMensais: ValoresMensais[]
  url: string
}

export function RepasseBoxView({ _io, valorRepasse, quantidadeRepasse, url }: IOProps<RepasseBoxIO, RepasseBoxProps>) {
  const { Box, GridWidthLimitada } = _io.styles

  return (
    <Box>
      <CabecalhoExportarRelatorio url={url} textoTooltip={`O painel de Repasse indica a soma do repasses de todos os parceiros.`} tituloCabecalho={'Repasse'} />
      <Grid container spacing={2} direction="row" justifyContent="center" alignContent="center">
        <GridWidthLimitada item container justifyContent="center" alignContent="center" direction="column" spacing={2} sm={12} md={5} lg={6}>
          <Grid item>
            <BoxValor icone={CifraoIcone} descricao={'Valor total do repasse'} valor={formateCurrency(valorRepasse)}></BoxValor>
          </Grid>
          <Grid item>
            <BoxValor icone={CuboIcone} descricao={'Quantidade total de pedidos do repasse'} valor={quantidadeRepasse.toLocaleString('pt-BR')}></BoxValor>
          </Grid>
        </GridWidthLimitada>
        <GridWidthLimitada item sm={7} md={7} lg={6}>
          <GraficoBarras
            legendaEixoY="Milhões"
            valorDivididoLegenda={VALOR_ESCALA_GRAFICO.MILHAO}
            tituloGrafico="Valor de repasse mensal"
            dadosColunas={_io.valorRepasseTotal}
            descricaoColunas={_io.legendaGrafico}
            corBarra={corBarra}
            tipoValor={TIPO_VALOR_GRAFICO.MOEDA}
          />
        </GridWidthLimitada>
      </Grid>
    </Box>
  )
}
