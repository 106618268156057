import { IOProps } from 'react-compose-io'
import { CardTitulosParceiro } from '../../../../types/titulosTypes'
import { GraficoDonutIO } from './GraficoDonut.IO'
import Chart from 'react-apexcharts'
import { Tipografia } from './../../../../components/Tipografia/index'

export type GraficoDonutProps = {
  listaTitulosParceiros: CardTitulosParceiro[]
  dataGrafico: string
  textoValorTotal: string
}

export function GraficoDonutView({ _io, dataGrafico }: IOProps<GraficoDonutIO, GraficoDonutProps>) {
  const { ContainerGrafico, Conteudo, Container, ValorData } = _io.styles
  return (
    <Container>
      <Tipografia variant="h6">Gráfico</Tipografia>
      <ValorData>{dataGrafico}</ValorData>
      <ContainerGrafico>
        <Conteudo>
          <Chart options={_io.options} series={_io.series} type="donut" width="450" />
        </Conteudo>
      </ContainerGrafico>
    </Container>
  )
}
