import styled from 'styled-components'
import { CustomDialog } from '../../../../components/CustomDialog/CustomDialog'
import { Grid } from '@material-ui/core'
import { Tipografia } from '../../../../components/Tipografia'

export const modalClassificacoesPorMesStyles = {
  CustomDialogCustomWidth: styled(CustomDialog)`
    .MuiDialog-paperWidthSm {
      max-width: 800px;
    }
  `,
  TituloModal: styled(Tipografia)`
    margin: 0.5rem 1.5rem;
  `,
  GridWidthLimitada: styled(Grid)`
    max-width: 750px;
    margin: 1rem 0rem;
  `
}
