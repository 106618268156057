import { IOProps } from 'react-compose-io'
import { CustomDialog } from '../../../../components/CustomDialog/CustomDialog'
import { FormularioTipoLancamentoParceiro } from '../../../../components/FormularioTipoLancamentoParceiro/index'
import { TipoLancamentoParceiro } from '../../../../types/parceiroApiTypes'
import { ModalTipoLancamentoParceiroIO } from './ModalTipoLancamentoParceiro.IO'

export type ModalTipoLancamentoParceiroProps = {
  isShow: boolean
  isClosed: () => void
  atualizarDados: () => void
  dadosLinha?: TipoLancamentoParceiro
  tituloModal: string
}

export default function ModalTipoLancamentoParceiroView({
  _io,
  isShow,
  isClosed,
  tituloModal
}: IOProps<ModalTipoLancamentoParceiroIO, ModalTipoLancamentoParceiroProps>) {
  return (
    <CustomDialog open={isShow} onClose={isClosed} id="modal-add-tipo-lancamento" titulo={tituloModal} fecharHabilitado fecharModal={isClosed}>
      <FormularioTipoLancamentoParceiro
        isClosed={isClosed}
        isModal={true}
        cadastrarTipoLancamentoParceiro={_io.salvarTipoLancamentoParceiro}
        larguraDescricao={12}
        larguraSiglaContabilizacao={12}
        dadosLinha={_io.dadosLinha}
      />
    </CustomDialog>
  )
}
