import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import * as yup from 'yup'
import msgConstants from '../../../../constants/msgConstants'
import transformacaoManager from '../../../../services/api/transformacao/transformacaoManager'
import { loadingReset, loadingUpdate } from '../../../../store/actions/loadingAction'
import { GrupoRegra, GrupoRegraCadastro } from '../../../../types/transformacaoApiTypes'
import { modalGrupoRegraStyles } from './ModalGrupoRegra.styles'
import { ModalGrupoRegraProps } from './ModalGrupoRegra.View'

export function modalGrupoRegraIO({ atualizarDados, isClosed, formData }: ModalGrupoRegraProps) {
  const { parceiroId } = useParams<{ parceiroId: string }>()

  const schema = yup
    .object({
      nome: yup.string().required('Campo nome é obrigatório'),
      descricao: yup.string().required('Campo descrição é obrigatório')
    })
    .required()

  const dispatch = useDispatch()

  const {
    handleSubmit,
    control,
    formState: { errors },
    register,
    setValue
  } = useForm<GrupoRegraCadastro>({
    defaultValues: {
      nome: '',
      descricao: ''
    },
    resolver: yupResolver(schema)
  })

  useEffect(() => {
    if (formData) {
      setValue('nome', formData.nome)
      setValue('descricao', formData.descricao)
    }
  }, [formData, setValue])

  function salvarGrupoRegra(grupoRegra: GrupoRegraCadastro) {
    dispatch(loadingUpdate(true))

    formData ? editarGrupoRegra(grupoRegra, formData) : cadastrarGrupoRegra(grupoRegra)
  }

  function calculateCode() {
    atualizarDados()
    fecharModal()
    dispatch(loadingReset())
  }

  async function cadastrarGrupoRegra(grupoRegra: GrupoRegraCadastro) {
    dispatch(loadingUpdate(true))
    await transformacaoManager
      .cadastrarGrupoRegra(grupoRegra, parceiroId)
      .then(() => {
        toast.success(msgConstants.USO_GENERICO.cadastro_sucesso)
      })
      .catch((error: Error) => {
        toast.error(msgConstants.USO_GENERICO.cadastro_erro + error.message)
      })
      .finally(() => {
        calculateCode()
      })
  }

  async function editarGrupoRegra(grupoRegraAlterado: GrupoRegraCadastro, grupoRegra: GrupoRegra) {
    grupoRegra.nome = grupoRegraAlterado.nome
    grupoRegra.descricao = grupoRegraAlterado.descricao

    await transformacaoManager
      .editarGrupoRegra(grupoRegra, parceiroId)
      .then(() => {
        toast.success(msgConstants.USO_GENERICO.edicao_sucesso)
      })
      .catch((error: Error) => {
        toast.error(msgConstants.USO_GENERICO.edicao_erro + error.message)
      })
      .finally(() => {
        calculateCode()
      })
  }

  const fecharModal = () => {
    isClosed()
    setValue('nome', '')
    setValue('descricao', '')
  }

  return {
    styles: modalGrupoRegraStyles,
    schema,
    register,
    control,
    salvarGrupoRegra,
    handleSubmit,
    errors,
    fecharModal
  }
}

export type ModalGrupoRegraIO = ReturnType<typeof modalGrupoRegraIO>
