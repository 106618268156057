import {
  AtualizarPedidoDebitoRequest,
  ClassificacaoDebito,
  DebitosPerdasEfetivados,
  EfetivarDebitoPerdaRequest,
  ObterPedidosDebitosQuery,
  ObterPedidosDebitosResponse,
  ResponseDebitos,
  ResponsePedidosDebitoDetalhado
} from '../../../types/debitosTypes'
import { PossiveisTratativas, ResumoPedidosPerdasClassificacao } from '../../../types/perdasTypes'
import { concatenarFiltrosQuery } from '../../../utils/stringUtils'
import api from '../../axiosInstance'

const buscarControleDebitos = async (): Promise<ResponseDebitos> => {
  const URL_CONTROLE_DEBITOS = `Debitos/ObterDados`
  const response = await api.conectarGet<ResponseDebitos>(URL_CONTROLE_DEBITOS)
  return response.data
}

const gerarDadosControleDebitos = async (): Promise<boolean> => {
  const URL_CONTROLE_DEBITOS = `Debitos/GerarDados`
  const response = await api.conectarPost<boolean>(URL_CONTROLE_DEBITOS)
  return response.data
}

const buscarHistoricoDebitosEfetivados = async (): Promise<DebitosPerdasEfetivados[]> => {
  const URL_HISTORICO_DEBITOS_EFETIVADOS = `Debitos/PerdasEfetivadas`
  const response = await api.conectarGet<DebitosPerdasEfetivados[]>(URL_HISTORICO_DEBITOS_EFETIVADOS)
  return response.data
}

const buscarClassificacoesDebitos = async (): Promise<ClassificacaoDebito[]> => {
  const URL_CLASSIFICACOES_DEBITOS = `Debitos/Classificacoes`
  const response = await api.conectarGet<ClassificacaoDebito[]>(URL_CLASSIFICACOES_DEBITOS)
  return response.data
}

const atualizarClassificacaoPedidoDebito = async (body: AtualizarPedidoDebitoRequest[]): Promise<boolean> => {
  const URL_ATUALIZAR_PEDIDOS_DEBITO = `Debitos/Pedidos`
  const response = await api.conectarPatch<boolean>(URL_ATUALIZAR_PEDIDOS_DEBITO, body)
  return response.data
}

const buscarPedidosDebitos = async (query: ObterPedidosDebitosQuery): Promise<ResponsePedidosDebitoDetalhado<ObterPedidosDebitosResponse>> => {
  const URL_PEDIDOS_DEBITOS = `Debitos/Pedidos${concatenarFiltrosQuery(query)}`
  const response = await api.conectarGet<ResponsePedidosDebitoDetalhado<ObterPedidosDebitosResponse>>(URL_PEDIDOS_DEBITOS)
  return response.data
}

const buscarPossiveisTratativasDebitos = async (): Promise<PossiveisTratativas> => {
  const URL_POSSIVEIS_TRATATIVAS = `Debitos/Tratativas`
  const response = await api.conectarGet<PossiveisTratativas>(URL_POSSIVEIS_TRATATIVAS)
  return response.data
}

const buscarResumoPedidosClassificadosDebitos = async (): Promise<ResumoPedidosPerdasClassificacao[]> => {
  const URL_RESUMO_PEDIDOS_CLASSIFICADOS_DEBITOS = `Debitos/Pedidos/Resumo` //Debitos/Pedidos/Resumo
  const response = await api.conectarGet<ResumoPedidosPerdasClassificacao[]>(URL_RESUMO_PEDIDOS_CLASSIFICADOS_DEBITOS)
  return response.data
}

const efetivarDebitoPerda = async (body: EfetivarDebitoPerdaRequest): Promise<boolean> => {
  const URL_EFETIVAR_DEBITOS = `Debitos/EfetivarDebitoPerda`
  const response = await api.conectarPost<boolean>(URL_EFETIVAR_DEBITOS, body)
  return response.data
}

export default {
  buscarControleDebitos,
  gerarDadosControleDebitos,
  buscarHistoricoDebitosEfetivados,
  buscarClassificacoesDebitos,
  atualizarClassificacaoPedidoDebito,
  buscarPedidosDebitos,
  buscarPossiveisTratativasDebitos,
  buscarResumoPedidosClassificadosDebitos,
  efetivarDebitoPerda
}
