import { useHistory } from 'react-router-dom'
import { PAGES } from '../../../../router/routes'
import { DEBITO, ValoresMensais } from '../../../../types/debitosTypes'
import { legendaMesGrafico } from '../../../../utils/debitoGraficos'
import { debitoBoxStyles } from './DebitoBox.styles'
import { DebitoBoxProps } from './DebitoBox.View'

export function debitoBoxIO({
  valoresMensais,
  valoresPorcentagemAcumulada,
  valoresPorcentagemMensal,
  porcentagemAcumulada,
  valorMensalConsiderado,
  tipoDebito,
  parceiroId = 0
}: DebitoBoxProps) {
  const history = useHistory()

  const valorDebitoMensal = valoresMensais.map((valorMes: ValoresMensais) => {
    if (valorMensalConsiderado == 'valorTransportadoraParceira') return valorMes.valorTransportadoraParceira
    if (valorMensalConsiderado == 'valorTransportadoraMartins') return valorMes.valorTransportadoraMartins
    return valorMes.valor
  })

  const legendaDebitoMensal = legendaMesGrafico(valoresMensais)

  const valorPorcentagemMensal = valoresPorcentagemMensal.map((valorTotal) => valorTotal.valor)
  const legendaPorcentagemDebito = valoresPorcentagemMensal.map((legenda) => {
    return [legenda.mes]
  })
  const valorPorcentagemAcumulada = valoresPorcentagemAcumulada.map((valorTotal) => valorTotal.valor)

  const dadosLinhas = [
    {
      name: `Débito ${tipoDebito} acumulado`,
      data: valorPorcentagemAcumulada
    },
    {
      name: `Débito ${tipoDebito}`,
      data: valorPorcentagemMensal
    }
  ]

  return {
    styles: debitoBoxStyles,
    valorDebitoMensal,
    legendaDebitoMensal,
    legendaPorcentagemDebito,
    dadosLinhas,
    porcentagemAcumulada: (porcentagemAcumulada * 100).toFixed(2),
    onClickDetalhesPedidosBotao: () => history.push(PAGES.detalheDebito(DEBITO.FINAL, parceiroId))
  }
}

export type DebitoBoxIO = ReturnType<typeof debitoBoxIO>
