import { useFormContext, useWatch } from 'react-hook-form'
import { stylesFieldForms } from '../styles'

export function fieldsAritmeticaIO() {
  const { control } = useFormContext()

  const acaoSelecionada = useWatch({
    name: 'acoes',
    control
  })

  return {
    styles: stylesFieldForms,
    acaoSelecionada,
    control
  }
}
export type FieldsAritmeticaIO = ReturnType<typeof fieldsAritmeticaIO>
