import { Grid, InputLabel, MenuItem, Select, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { IOProps } from 'react-compose-io'
import { Controller } from 'react-hook-form'
import CustomCard from '../../components/CustomCard/CustomCard'
import { Transportadora } from '../../types/transportadoras'
import { CadastrarNovoParceiroIO } from './CadastrarNovoParceiro.IO'
import { HistoricoParceiro } from './components/HistoricoParceiro/index'
import { Botao } from './../../components/Botao/index'

export type CadastrarNovoParceiroProps = {}

export function CadastrarNovoParceiroView({ _io }: IOProps<CadastrarNovoParceiroIO, CadastrarNovoParceiroProps>) {
  const { ContainerForm, TextoFormularioInativo, TextoFormulario, FormularioControle, GridButtons } = _io.styles

  return (
    <CustomCard
      isTitle
      title="Editar parceiro"
      isActionFooter
      actionFooter={
        <GridButtons container spacing={1} justifyContent="flex-end">
          <Grid item>
            <Botao cor="primaria" estilo="outlined" textoBotao={'Cancelar'} onClick={_io.cancelarCadastro} width="150px" />
          </Grid>
          <Grid item>
            <Botao cor="primaria" estilo="contained" textoBotao={'Salvar'} onClick={_io.handleSubmit(_io.salvarDadosParceiro)} width="150px" />
          </Grid>
        </GridButtons>
      }
    >
      <ContainerForm>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={2} lg={2}>
            <Controller render={({ field }) => <TextoFormularioInativo {...field} label="Código" />} name="parceiroId" control={_io.control} />
          </Grid>
          <Grid item xs={12} sm={12} md={5} lg={5}>
            <Controller render={({ field }) => <TextoFormularioInativo {...field} label="Razão Social" />} name="razaoSocial" control={_io.control} />
          </Grid>
          <Grid item xs={12} sm={12} md={5} lg={5}>
            <Controller render={({ field }) => <TextoFormularioInativo {...field} label="Nome Fantasia" />} name="nomeFantasia" control={_io.control} />
          </Grid>
          <Grid item xs={12} sm={12} md={2} lg={2}>
            <Controller render={({ field }) => <TextoFormulario type="number" {...field} label="Periodicidade" />} name="periodicidade" control={_io.control} />
          </Grid>
          <Grid item xs={12} sm={12} md={2} lg={2}>
            <Controller
              render={({ field }) => (
                <FormularioControle>
                  <InputLabel>Tipo de periodicidade</InputLabel>
                  <Select {...field} label="Tipo de periodicidade">
                    <MenuItem value={'H'}>Horas</MenuItem>
                    <MenuItem value={'D'}>Dias</MenuItem>
                    <MenuItem value={'S'}>Semanas</MenuItem>
                    <MenuItem value={'M'}>Meses</MenuItem>
                  </Select>
                </FormularioControle>
              )}
              name="tipoPeriodicidadeId"
              defaultValue={_io.parceiro?.tipoPeriodicidadeId}
              control={_io.control}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <Controller
              render={({ field }) => (
                <TextoFormulario
                  InputLabelProps={{
                    shrink: true
                  }}
                  {...field}
                  label="Data do último repasse"
                  type="date"
                />
              )}
              name="ultimoRepasse"
              control={_io.control}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={5} lg={5}>
            {_io.parceiro.transportadoras && (
              <Controller
                render={({ field }) => (
                  <Autocomplete
                    multiple
                    options={_io.transportadoras}
                    getOptionLabel={(option: Transportadora) => option.nome}
                    filterSelectedOptions
                    defaultValue={_io.parceiro.transportadoras}
                    onChange={(option, value) =>
                      _io.setValue(
                        'transportadorasId',
                        value.map((item) => item.transportadoraId)
                      )
                    }
                    size="small"
                    renderInput={(params) => <TextField {...params} {...field} variant="outlined" label="Tipo de transportadora" size="small" />}
                  />
                )}
                name="transportadorasId"
                control={_io.control}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Controller render={({ field }) => <TextoFormulario type="url" {...field} label="URL do arquivo de repasse" />} name="url" control={_io.control} />
          </Grid>
        </Grid>
      </ContainerForm>
      <HistoricoParceiro parceiroData={_io.parceiro} />
    </CustomCard>
  )
}
