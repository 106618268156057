import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import msgConstants from '../../../../constants/msgConstants'
import conciliacaoManager from '../../../../services/api/conciliacao/conciliacaoManager'
import { loadingReset, loadingUpdate } from '../../../../store/actions/loadingAction'
import { ResumoContabilizacao } from '../../../../types/conciliacaoApiTypes'
import { formateCurrency, validarTexto } from '../../../../utils/stringUtils'
import { resumoConciliacaoStyles } from './ResumoConciliacao.style'
import { ResumoConciliacaoProps } from './ResumoConciliacao.View'

const defaultResumo: ResumoContabilizacao = {
  valorDoCiclo: 0,
  valorDoRepasse: 0,
  totalDeOPsGeradas: 0,
  titulosLiquidados: 0,
  creditoNaoIdentificado: 0,
  comissoesCredito: 0,
  jurosRecebidos: 0,
  descontoConcedido: 0,
  comissoesDebito: 0,
  devolucoes: 0,
  impostoDeRenda: 0,
  valorCreditoIgnorado: 0,
  valorDebitoIgnorado: 0,
  totalItensIgnorados: 0,
  quantidadeFaturado: 0,
  valorFaturado: 0
}

export function resumoConciliacaoIO({ cicloId }: ResumoConciliacaoProps) {
  const dispatch = useDispatch()
  const [resumo, setResumo] = useState<ResumoContabilizacao>(defaultResumo)

  useEffect(() => {
    dispatch(loadingUpdate(true))

    conciliacaoManager
      .buscarResumoContabilizacao(cicloId)
      .then((response: ResumoContabilizacao) => {
        setResumo(response)
        dispatch(loadingReset())
      })
      .catch(() => {
        toast.error(msgConstants.INICIAR_CICLO.erro_buscar_resumo_contabilizacao)
      })
  }, [cicloId, dispatch])

  const listaResumoConciliacaoFinanceira = [
    {
      primeiroTituloLinha: 'Valor do ciclo',
      primeiroValorLinha: formateCurrency(resumo.valorDoCiclo),
      segundoTituloLinha: 'Valor faturado',
      segundoValorLinha: resumo.valorFaturado ? formateCurrency(resumo.valorFaturado) : '-'
    },
    {
      primeiroTituloLinha: 'Valor do repasse',
      primeiroValorLinha: formateCurrency(resumo.valorDoRepasse),
      segundoTituloLinha: 'Quant. faturados',
      segundoValorLinha: validarTexto(resumo.quantidadeFaturado?.toLocaleString('pt-BR'), '-')
    },
    {
      primeiroTituloLinha: 'Total de OP`s geradas',
      primeiroValorLinha: resumo.totalDeOPsGeradas?.toLocaleString('pt-BR')
    }
  ]

  return {
    styles: resumoConciliacaoStyles,
    resumo,
    listaResumoConciliacaoFinanceira
  }
}

export type ResumoConciliacaoIO = ReturnType<typeof resumoConciliacaoIO>
