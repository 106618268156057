import BotaoExportarIcone from '../../assets/icons/compartilharListaParceiros.svg'
import BotaoExportarAzul from '../../assets/icons/compartilharListaParceirosAzul.svg'
import { downloadRelatorioStyles } from './DownloadRelatorio.style'
import { DownloadRelatorioProps } from './DownloadRelatorio.View'

export function downloadRelatorioIO({ relatorioDisponivel, gerarRelatorio: gerarRelatorio }: DownloadRelatorioProps) {
  return {
    styles: downloadRelatorioStyles,
    iconeTooltip: relatorioDisponivel ? BotaoExportarAzul : BotaoExportarIcone,
    onClickDownloadRelatorio: () => {
      if (relatorioDisponivel) return
      gerarRelatorio()
    }
  }
}

export type DownloadRelatorioIO = ReturnType<typeof downloadRelatorioIO>
