import { Grid } from '@material-ui/core'
import styled from 'styled-components'
import { ContainerSombra } from '../../../../components/ContainerSombra'

export const repasseBoxStyles = {
  Box: styled(ContainerSombra)`
    padding: 16px;
    margin-bottom: 16px;
    margin-top: 24px;
  `,
  GridWidthLimitada: styled(Grid)`
    max-width: 500px;
  `
}
