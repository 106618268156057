import { Box } from '@material-ui/core'
import styled from 'styled-components'
import { customTheme } from '../../styles/customTheme'

const colorComponent = (estapaStatus: string) => {
  if (estapaStatus === 'WARNING') {
    return customTheme.colors.white02
  } else if (estapaStatus === 'SUCCESS') {
    return customTheme.colors.suport.success
  } else if (estapaStatus === 'ERROR') {
    return customTheme.colors.red00
  } else if (estapaStatus === 'DEFAULT') {
    return 'transparent'
  } else {
    return customTheme.colors.blue01
  }
}

const paragraphComponent = (estapaStatus: string) => {
  if (estapaStatus === 'WARNING') {
    return customTheme.colors.yellow01
  } else if (estapaStatus === 'SUCCESS') {
    return customTheme.colors.green04
  } else if (estapaStatus === 'ERROR') {
    return customTheme.colors.red06
  } else if (estapaStatus === 'DEFAULT') {
    return customTheme.colors.grey00
  } else {
    return customTheme.colors.blue04
  }
}

export const styles = {
  Container: styled(({ ...rest }) => <Box {...rest} />)`
    padding: 5px;
    height: 100%;
    width: 100%;
    border-radius: 10px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${(props) => colorComponent(props.estapaStatus)};
    p {
      color: ${(props) => paragraphComponent(props.estapaStatus)};
    }
  `
}
