import { IOProps } from 'react-compose-io'
import { HistoricoControleComissaoPedidoIO } from './HistoricoControleComissaoPedidoIO'
import { Box, Collapse, Grid } from '@material-ui/core'
import { HistoricoControleComissaoPedidoResponse } from '../../../../types/controleComissaoTypes'
import { customTheme } from '../../../../styles/customTheme'
import { Tipografia } from '../../../../components/Tipografia'
import { formateDate, formateHoras } from '../../../../utils/stringUtils'

export type HistoricoControleComissaoPedidoProps = {
  items: HistoricoControleComissaoPedidoResponse[]
  historicoAberto: boolean
}

export function HistoricoControleComissaoPedidoView({
  _io,
  items,
  historicoAberto
}: IOProps<HistoricoControleComissaoPedidoIO, HistoricoControleComissaoPedidoProps>) {
  const { TableRow, TableCell, ContainerGrid, TituloDescricao, TipografiaInline } = _io.styles

  return (
    <TableRow>
      <TableCell colSpan={1000}>
        <Collapse in={historicoAberto} timeout="auto" unmountOnExit>
          <Box>
            <TituloDescricao variant="subtitle1" fontWeight="600">
              Observações da Ordem
            </TituloDescricao>

            {items.length > 0 ? (
              <>
                {items.map((historico, index) => (
                  <ContainerGrid alignItems="center" container key={index}>
                    <Grid item xs={12} sm={6}>
                      <TipografiaInline fontWeight="500" variant="subtitle1">
                        {'Usuário: '}
                      </TipografiaInline>
                      <TipografiaInline variant="body2">{historico.nomeFuncionario}</TipografiaInline>
                      <Tipografia fontWeight="500" variant="subtitle1">{`${formateDate(historico.dataAlteracao)} às ${formateHoras(
                        historico.dataAlteracao
                      )}`}</Tipografia>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Tipografia textAlignment="justifyContent" colorText={customTheme.colors.grey21} variant="subtitle1">
                        {historico.observacao}
                      </Tipografia>
                    </Grid>
                  </ContainerGrid>
                ))}
              </>
            ) : (
              <ContainerGrid spacing={2} direction="column">
                <Grid item>
                  <Tipografia fontWeight="400" colorText={customTheme.colors.grey21} variant="subtitle1">
                    Este pedido não possui nenhuma observação registrada.
                  </Tipografia>
                </Grid>
              </ContainerGrid>
            )}
          </Box>
        </Collapse>
      </TableCell>
    </TableRow>
  )
}
